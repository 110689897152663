import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
    countryMenuItems,
    genderMenuItems,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { isBlank, isUrl } from 'src/components/misc/validations';

class MainCompanyBranchDataForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                {/* Company */}
                <legend className="legend">{t('main_company_branch_data.company')}</legend>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="name" label={`${t('main_company_branch_data.name')} *`} component={renderInput} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="registrationNumber"
                            label={`${t('main_company_branch_data.registration_number')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="uidNumber"
                            label={`${t('main_company_branch_data.uid')}`}
                            component={renderInput}
                        />
                    </div>
                </div>

                {/*Opening Hours are not used*/}
                {/*<div className="row">*/}
                {/*    <div className="col-md-12">*/}
                {/*        <Field*/}
                {/*            name="openingHours"*/}
                {/*            label={`${t('main_company_branch_data.opening_hours')} *`}*/}
                {/*            component={renderInput}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Address */}
                <legend className="legend voffset">{t('main_company_branch_data.address')}</legend>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.country"
                            label={`${t('contact_data.country')}`}
                            component={renderSelectField}>
                            {countryMenuItems()}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field name="contactData.city" label={`${t('contact_data.city')}`} component={renderInput} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.postCode"
                            label={`${t('contact_data.post_code')}`}
                            hintText={t('contact_data.post_code_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="contactData.street"
                            label={`${t('contact_data.street')}`}
                            component={renderInput}
                        />
                    </div>
                </div>

                {/* Contact */}
                <legend className="legend voffset">{t('main_company_branch_data.contact')}</legend>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.homepage"
                            label={`${t('contact_data.homepage')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.email"
                            label={`${t('main_company_branch_data.office_email')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="invoiceEmail"
                            label={`${t('main_company_branch_data.invoice_email')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerPhone"
                            label={`${t('main_company_branch_data.customer_phone')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="customerEmail"
                            label={`${t('main_company_branch_data.customer_email')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.phone"
                            label={`${t('main_company_branch_data.country_phone_at')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="countryPhone.DE"
                            label={`${t('main_company_branch_data.country_phone_de')}`}
                            component={renderInput}
                        />
                    </div>
                </div>

                {/* Owner */}
                <legend className="legend voffset">{t('main_company_branch_data.owner')}</legend>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="contactData.gender"
                            label={`${t('contact_data.gender')}`}
                            component={renderSelectField}>
                            {genderMenuItems(t)}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.firstName"
                            label={`${t('contact_data.first_name')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="contactData.lastName"
                            label={`${t('contact_data.last_name')}`}
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        contactData: {},
    };

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (isBlank(values.invoiceEmail)) errors.invoiceEmail = t('error_missing.fill_in');
    if (isBlank(values.customerEmail)) errors.customerEmail = t('error_missing.fill_in');
    if (isBlank(values.customerPhone)) errors.customerPhone = t('error_missing.fill_in');

    if (isBlank(values.contactData.homepage)) errors.contactData.homepage = t('error_missing.fill_in');
    if (!isUrl(values.contactData.homepage)) errors.contactData.homepage = t('error_missing.fill_in_valid_url');

    return errors;
};

MainCompanyBranchDataForm = reduxForm({
    form: 'MainCompanyBranchDataForm',
    validate,
})(MainCompanyBranchDataForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, mainCompanyBranchData } = ownProps;

    let initialValues;

    if (!isCreate && mainCompanyBranchData)
        initialValues = {
            id: mainCompanyBranchData.id,
            name: mainCompanyBranchData.name,
            contactData: mainCompanyBranchData.contactData,
            invoiceEmail: mainCompanyBranchData.invoiceEmail,
            uidNumber: mainCompanyBranchData.uidNumber,
            registrationNumber: mainCompanyBranchData.registrationNumber,
            countryPhone: mainCompanyBranchData.countryPhone,
            customerEmail: mainCompanyBranchData.customerEmail,
            customerPhone: mainCompanyBranchData.customerPhone,
            openingHours: mainCompanyBranchData.openingHours,
        };
    else initialValues = {};

    return {
        initialValues: initialValues,
    };
};

MainCompanyBranchDataForm = connect(mapStateToProps, null, null, { withRef: true })(MainCompanyBranchDataForm);

export default MainCompanyBranchDataForm;
