import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import MenuItem from 'material-ui/MenuItem';

class CustomerDiscountGroupEditForm extends Component {
    render() {
        const { handleSubmit, discountGroups, isImportRatioCustomerModuleEnabled, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="email"
                            label={t('contact_data.username')}
                            hintText={t('contact_data.username')}
                            component={renderInput}
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="discountGroupId"
                            label={t('administration.discount_group')}
                            hintText={t('administration.discount_group')}
                            component={renderSelectField}>
                            {[<MenuItem key={-1} value="" primaryText="-" />].concat(
                                discountGroups.map(discountGroup => (
                                    <MenuItem
                                        key={discountGroup.id}
                                        value={discountGroup.id}
                                        primaryText={discountGroup.name}
                                    />
                                )),
                            )}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="transferNumber"
                            label={t('contact_data.transfer_number')}
                            hintText={t('contact_data.transfer_number')}
                            component={renderInput}
                        />
                    </div>
                    {isImportRatioCustomerModuleEnabled && (
                        <div className="col-md-6">
                            <Field
                                name="ratioCustomerId"
                                label={t('contact_data.ratio_customer_id')}
                                hintText={t('contact_data.ratio_customer_id')}
                                component={renderInput}
                                type="number"
                            />
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'customerDiscountGroupEditForm',
})(CustomerDiscountGroupEditForm);
