import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';

import ReadOnlyField from 'src/components/misc/ReadOnlyField';
import { renderCheckbox, renderInput, renderRichTextEditor } from 'src/components/misc/redux-form-helpers';
import { isBlank, isEmailInvalid } from '../misc/validations';

class PrintTaskForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            showCustomer: false,
        };
    }

    render() {
        const { handleSubmit, bus, firstDriver, secondDriver, t } = this.props;

        const maySend = bus !== undefined && (firstDriver !== undefined || secondDriver !== undefined);

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="sendMail"
                            label={t('print.send_email_to_driver')}
                            component={renderCheckbox}
                            customOnCheckCallback={value => this.setState({ showCustomer: value })}
                            disabled={!maySend}
                            title={maySend ? '' : t('print.nothing_assigned')}
                        />
                    </div>
                </div>
                {this.state.showCustomer && (
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <ReadOnlyField
                                    value={
                                        firstDriver
                                            ? `${firstDriver.contactData.firstName} ${firstDriver.contactData.lastName}`
                                            : t('print.no_driver_assigned')
                                    }
                                    label={t('print.first_driver')}
                                />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    name="firstDriverEmail"
                                    label={t('contact_data.email_address')}
                                    component={renderInput}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ReadOnlyField
                                    value={
                                        secondDriver
                                            ? `${secondDriver.contactData.firstName} ${secondDriver.contactData.lastName}`
                                            : t('print.no_driver_assigned')
                                    }
                                    label={t('print.second_driver')}
                                />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    name="secondDriverEmail"
                                    label={t('contact_data.email_address')}
                                    component={renderInput}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row voffset">
                    <div className="col-md-12">
                        <label>{t('print.hints_driver')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="printNotes"
                            placeholder={t('print.hints_driver')}
                            noLinks={true}
                            component={renderRichTextEditor}
                            t={t}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        firstDriverEmail: undefined,
        secondDriverEmail: undefined,
    };

    if (isEmailInvalid(values.firstDriverEmail) && !isBlank(values.firstDriverEmail))
        errors.firstDriverEmail = t('error_hint.error_invalid_email');

    if (isEmailInvalid(values.secondDriverEmail) && !isBlank(values.secondDriverEmail))
        errors.secondDriverEmail = t('error_hint.error_invalid_email');

    if (isBlank(values.firstDriverEmail)) errors.firstDriverEmail = t('error_missing.fill_in_username');

    return errors;
};

export default reduxForm({
    form: 'printTaskForm',
    validate,
})(PrintTaskForm);
