import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';

import { Entities, isInteger, isMoneyAmount, setMoneyCurrency, TOMCAT_URL, validatePrice } from 'src/common/index';

import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';

import DatePicker from 'src/components/misc/DatePicker';
import { isBlank } from 'src/components/misc/validations';

class TicketCollectionForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="category"
                            label={`${t('common_phrases.category')} *`}
                            hintText={t('ticket.category_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field name="validUntil" label={`${t('ticket.valid_until')} *`} component={DatePicker} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="quantity"
                            label={`${t('ticket.quantity_sum')} *`}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="buyPrice.amount"
                            label={`${t('ticket.buy_price_piece')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="sellPrice.amount"
                            label={`${t('ticket.sell_price_piece')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="internSellPrice.amount"
                            label={`${t('ticket.internal_sell_price_piece')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Field name="description" label={t('common_phrases.description')} component={renderInput} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Field name="conditions" label={t('ticket.terms')} component={renderInput} />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        buyPrice: {
            amount: undefined,
        },
        sellPrice: {
            amount: undefined,
        },
        internSellPrice: {
            amount: undefined,
        },
    };

    if (isBlank(values.category)) errors.category = t('error_missing.fill_in_category');

    if (!values.validUntil) errors.validUntil = t('error_missing.fill_in_date');
    else if (moment(values.validUntil, 'DD.MM.YYYY').isBefore(moment().startOf('day')))
        errors.validUntil = t('error_hint.error_date');
    else if (moment(props.event.beginDateTime).isBefore(moment(values.validUntil, 'DD.MM.YYYY').startOf('day')))
        errors.validUntil = t('ticket.event_starts') + moment(props.event.beginDateTime).format('DD.MM.YYYY HH:mm');

    if (!isMoneyAmount(values.buyPrice)) errors.buyPrice.amount = t('error_missing.fill_in_buy_price');
    else {
        if (values.buyPrice.amount < 0) errors.buyPrice.amount = t('error_hint.error_negative');
        else if (validatePrice(values.buyPrice.amount))
            errors.buyPrice.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
    }

    if (!isMoneyAmount(values.sellPrice)) errors.sellPrice.amount = t('error_missing.fill_in_sell_price');
    else {
        if (values.sellPrice.amount < 0) errors.sellPrice.amount = t('error_hint.error_negative');
        else if (validatePrice(values.sellPrice.amount))
            errors.sellPrice.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
    }

    if (!isMoneyAmount(values.internSellPrice))
        errors.internSellPrice.amount = t('error_missing.fill_in_internal_sell_price');
    else {
        if (values.internSellPrice.amount < 0) errors.internSellPrice.amount = t('error_hint.error_negative');
        else if (validatePrice(values.internSellPrice.amount))
            errors.internSellPrice.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
    }

    if (values.quantity == null) errors.quantity = t('error_missing.fill_in_quantity_sum');
    else if (!isInteger(values.quantity)) errors.quantity = t('error_hint.error_integer');
    else if (values.quantity < 1) errors.quantity = t('error_hint.error_at_least_one_ticket');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${
            Entities.TICKET_COLLECTION.repository
        }/search/findByCategoryAndEventId?category=${encodeURIComponent(values.category)}&eventId=${props.event.id}`,
        values.id,
        'category',
    );
};

TicketCollectionForm = reduxForm({
    form: 'ticketCollectionForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['category'],
})(TicketCollectionForm);

const onSubmit = (data, submit) => {
    data.validUntil = moment(data.validUntil, 'DD.MM.YYYY').format('YYYY-MM-DD');
    setMoneyCurrency(data.buyPrice, 'EUR');
    setMoneyCurrency(data.sellPrice, 'EUR');
    setMoneyCurrency(data.internSellPrice, 'EUR');
    submit(data);
};

const mapStateToProps = (state, ownProps) => {
    const { isCreate, event, ticketCollection } = ownProps;

    let initialValues;

    // if form is used to edit a ticket collection -> load existing values
    if (!isCreate && ticketCollection)
        initialValues = {
            id: ticketCollection.id,
            category: ticketCollection.category,
            buyPrice: ticketCollection.buyPrice,
            sellPrice: ticketCollection.sellPrice,
            internSellPrice: ticketCollection.internSellPrice,
            validUntil: moment(ticketCollection.validUntil).format('DD.MM.YYYY'),
            quantity: ticketCollection.quantity,
            countSold: ticketCollection.countSold,
            description: ticketCollection.description,
            conditions: ticketCollection['conditions'],
            event: event._links.self.href,
        };
    // initialize create values
    else
        initialValues = {
            event: event._links.self.href,
            validUntil: moment(event.beginDateTime).format('DD.MM.YYYY'),
        };

    return {
        onSubmit: data => onSubmit(data, ownProps.onSubmit),
        initialValues: initialValues,
    };
};

TicketCollectionForm = connect(mapStateToProps, null, null, { withRef: true })(TicketCollectionForm);

export default TicketCollectionForm;
