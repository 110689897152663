import _ from 'lodash';
import { TripEventResource, TripEventType } from 'src/selectors/trip-calendar-events/trip-calendar-events';

export const addAvailabilityEvents = events => {
    const [bookingEvents, contingentEvents] = events.reduce(
        (result, element) => {
            if (element.resourceId === TripEventResource.BOOKING) result[0].push(element);
            else if (element.resourceId === TripEventResource.CONTINGENT) result[1].push(element);
            return result;
        },
        [[], []],
    );

    return bookingEvents.concat(getAvailabilityEvents(bookingEvents, contingentEvents)).concat(contingentEvents);
};

export const getAvailabilityEvents = (bookingEvents, contingentEvents) => {
    const availabilityEvents = [];

    contingentEvents.forEach(contingentEvent => {
        const bookingEventsInRange = bookingEvents.filter(
            bookingEvent =>
                contingentEvent.start.isSameOrBefore(bookingEvent.end) &&
                contingentEvent.end.isSameOrAfter(bookingEvent.start),
        );
        const timeSegments = getTimeSegments(bookingEventsInRange, contingentEvent);

        for (let i = 0; i < timeSegments.length - 1; i++) {
            const start = timeSegments[i];
            const end = timeSegments[i + 1];

            if (start.isSame(end, 'minute')) continue;

            const bookingEventsInSegment = bookingEventsInRange.filter(
                bookingEvent =>
                    start.isBefore(bookingEvent.end) && end.isAfter(bookingEvent.start) && !bookingEvent.cancelled,
            );

            // calculate available pax
            let availablePax = contingentEvent.pax;
            for (let j = 0; j < bookingEventsInSegment.length; j++) {
                const bookingEvent = bookingEventsInSegment[j];

                if (bookingEvent.fullBlock) {
                    availablePax = 0;
                    break;
                } else {
                    availablePax -= bookingEvent.pax;
                }
            }

            availabilityEvents.push(
                createAvailabilityEvent(start, end, Math.max(0, availablePax), contingentEvent.pax),
            );
        }
    });

    return availabilityEvents;
};

const getTimeSegments = (bookingEvents, contingentEvent) => {
    return _.flattenDeep(
        bookingEvents.map(bookingEvent => {
            const segments = [];

            if (bookingEvent.start.isBetween(contingentEvent.start, contingentEvent.end))
                segments.push(bookingEvent.start);

            if (bookingEvent.end.isBetween(contingentEvent.start, contingentEvent.end)) segments.push(bookingEvent.end);

            return segments;
        }),
    )
        .concat([contingentEvent.start, contingentEvent.end])
        .sort();
};

const createAvailabilityEvent = (start, end, availablePax, maxPax) => {
    return {
        id: `a-${start.unix()}`,
        title: `${availablePax}`,
        resourceId: TripEventResource.AVAILABILITY,
        start: start,
        end: end,
        color: getAvailabilityColor(availablePax, maxPax),
        borderColor: 'lightgrey',
        type: TripEventType.AVAILABILITY,
        pax: availablePax,
    };
};

const getAvailabilityColor = (availablePax, maxPax) => {
    if (availablePax === 0) return '#e64a19';
    if (availablePax === maxPax) return '#8bc34a';
    return '#ffbe3f';
};
