import Gateway from 'src/common/entity/basic-types/Gateway';
import CircularProgress from 'material-ui/CircularProgress';
import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { renderCustomerDetails, statusFormatter } from 'src/components/admin/accounts/admin-customer-administration';
import { isLoggedInWithRole } from 'src/components/account/login';
import ParentForm from 'src/components/misc/parent-form';
import ChangeTaskContactDataForm from 'src/components/admin/booked-tasks/change-task-contactData-form';
import { asyncRequest, Entities, PaymentMethod, Projection, TOMCAT_URL, UserRole } from 'src/common/index';
import moment from 'moment';
import { getBookedPrice } from 'src/components/operatingtool/price';
import { InjectedTranslateProps } from 'src/types';
import { ModalProps } from 'src/utils/modal-action-wrapper';
import PriceLogButton from 'src/components/operatingtool/price-log-button';
import PaymentLogsButton from 'src/components/operatingtool/payment-logs-button';
import LocalBusButton from 'src/components/operatingtool/local-bus-button';
import { DriverRooms } from 'src/components/operatingtool/DriverRooms';

interface OwnProps {
    selectedBookedTask: any;
    account: any;
    handleCloseDrawer: () => void;
    openModal: (props: ModalProps) => void;
}

interface State {
    isFetching: boolean;
    task?: any;
    error?: any;
}

type Props = OwnProps & InjectedTranslateProps;

class AdminSelectedBookedTaskDetails extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isFetching: true,
        };
    }

    componentWillMount() {
        const { selectedBookedTask } = this.props;

        asyncRequest(
            `${TOMCAT_URL}api/${Entities.TASK.repository}/${selectedBookedTask.id}?projection=${Projection.FULL}`,
        )
            .then(response => {
                const task = response.json;
                this.setState({
                    isFetching: false,
                    task,
                });
            })
            .catch(error => {
                this.setState({
                    isFetching: false,
                    error,
                });
            });
    }

    render() {
        const { selectedBookedTask, account, t } = this.props;

        if (this.state.isFetching) return <CircularProgress />;

        if (this.state.error) return <span>{this.state.error}</span>;

        const task = this.state.task;

        const customer = task.customer;
        const customerContactData = task.customerContactData;
        const reviewEntries = selectedBookedTask.reviewEntries ? _.toPairs(selectedBookedTask.reviewEntries) : [];

        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="calculactionsdetails-header">
                            <h5>{t('task_details.details')}</h5>
                        </div>

                        <table className="table table-striped voffset">
                            <thead>
                                <tr>
                                    <th>{t('common_phrases.common')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-md-5">{t('booked_tasks.booking_number')}</td>
                                    <td className="col-md-7">{task.bookingIdentifier}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('booked_tasks.task_number')}</td>
                                    <td className="col-md-7">{task.identifier}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('task_details.booked_at')}</td>
                                    <td className="col-md-7">{moment(task.bookedAt).format()}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('common_phrases.company')}</td>
                                    <td className="col-md-7">{selectedBookedTask.companyName}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('task_details.pax')}</td>
                                    <td className="col-md-7">{task.passengers}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('task_details.price')}</td>
                                    <td className="col-md-7">
                                        {getBookedPrice(task, t)}
                                        {task.priceLog !== null && (
                                            <PriceLogButton
                                                priceLog={task.priceLog}
                                                t={t}
                                                openModal={this.props.openModal}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-md-5">{t('task_details.cancelled')}</td>
                                    <td className="col-md-7">{statusFormatter(task.cancelled)}</td>
                                </tr>
                                {!task.cancelled && task.freeCancellationDate && (
                                    <tr>
                                        <td className="col-md-5">{t('task_details.free_cancelling_until')}</td>
                                        <td className="col-md-7">
                                            {moment(task.freeCancellationDate).format('DD.MM.YYYY')}
                                        </td>
                                    </tr>
                                )}
                                {task.driverRoomsNeeded && (
                                    <tr>
                                        <td className="col-md-5">{t('task_details.drivers_room.title')}</td>
                                        <td className="col-md-7">
                                            <DriverRooms
                                                driverRoomsNeeded={task.driverRoomsNeeded}
                                                companyProvidesDriverRooms={task.companyProvidesDriverRooms}
                                                openModal={this.props.openModal}
                                                t={t}
                                                variant="button"
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {this.renderPaymentInfo(task)}

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{t('task_details.route')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-md-9">
                                        <p>
                                            {moment(task.taskFrom.time).format()} {task.taskFrom.location.address}
                                        </p>
                                        {task.intermediates &&
                                            task.intermediates.length > 0 &&
                                            task.intermediates.map((value: any, key: any) => (
                                                <p key={key}>
                                                    {moment(value.time).format()} {value.location.address}{' '}
                                                    {value.localBus && (
                                                        <LocalBusButton
                                                            t={t}
                                                            openModal={this.props.openModal}
                                                            stop={value}
                                                        />
                                                    )}
                                                </p>
                                            ))}
                                        <p>
                                            {moment(task.taskTo.time).format()} {task.taskTo.location.address}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        {renderCustomerDetails(
                            t,
                            customer,
                            customerContactData,
                            isLoggedInWithRole(account, [UserRole.SUPER_ADMIN, UserRole.ADMIN])
                                ? () => {
                                      this.props.openModal({
                                          component: ParentForm,
                                          componentProps: {
                                              isCreate: false,
                                              projection: Projection.FULL,
                                              mainEntityProps: {
                                                  entity: Entities.TASK,
                                                  id: task.id,
                                              },
                                              childForm: {
                                                  component: ChangeTaskContactDataForm,
                                              },
                                              closeDrawer: this.props.handleCloseDrawer,
                                          },
                                          title: t('task_details.booking_account') + customer.email,
                                      });
                                  }
                                : undefined,
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="calculactionsdetails-header">
                            <h5>{t('common_phrases.rating')}</h5>
                        </div>

                        <div>
                            <table className="table table-striped voffset">
                                <thead>
                                    <tr>
                                        <th>{t('common_phrases.category')}</th>
                                        <th>{t('task_details.points')}</th>
                                    </tr>
                                </thead>
                                {reviewEntries.length > 0 ? (
                                    <tbody>
                                        {reviewEntries.map((reviewEntry: any, key: any) => (
                                            <tr key={key}>
                                                <td>{reviewEntry[0]}</td>
                                                <td>{reviewEntry[1]}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="success">
                                                <strong>{t('ticket.sum')}</strong>
                                            </td>
                                            <td className="success">
                                                <strong>{selectedBookedTask.reviewScore}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>{selectedBookedTask.reviewComment}</td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td>{t('task_details.no_ratings')}</td>
                                            <td />
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderPaymentInfo = (task: any) => {
        const { t } = this.props;
        const latestOnlinePaymentLog = task.latestOnlinePaymentLog;

        let paymentMethod: React.ReactNode = t('priceDetails.price_options.invoice');
        if (latestOnlinePaymentLog) {
            if (latestOnlinePaymentLog.paymentMethod) {
                const gateway = new Gateway({
                    id: latestOnlinePaymentLog.paymentMethod,
                    description: latestOnlinePaymentLog.paymentMethod,
                });
                const imageHeight = gateway.id === PaymentMethod.VISA ? 20 : 30;
                paymentMethod = (
                    <img
                        height={imageHeight}
                        src={gateway.logo}
                        title={gateway.description}
                        alt={gateway.description}
                    />
                );
            } else {
                paymentMethod = '-';
            }
        }

        return (
            <table className="table table-striped voffset">
                <thead>
                    <tr>
                        <th className="col-md-5">{t('payment.title')}</th>
                        <th className="col-md-7">
                            <PaymentLogsButton
                                className="pull-right"
                                paymentLogs={task.paymentLogs}
                                t={t}
                                openModal={this.props.openModal}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="col-md-5">{t('payment.method')}</td>
                        <td className="col-md-7">{paymentMethod}</td>
                    </tr>
                    {latestOnlinePaymentLog && (
                        <>
                            <tr>
                                <td className="col-md-5">{t('payment.status')}</td>
                                <td className="col-md-7">
                                    {statusFormatter(latestOnlinePaymentLog.status === 'completed')}{' '}
                                    {latestOnlinePaymentLog.status}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-md-5">{t('payment.modified_at')}</td>
                                <td className="col-md-7">{moment(latestOnlinePaymentLog.modifiedAt).format()}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        );
    };
}

export default withTranslation()(AdminSelectedBookedTaskDetails);
