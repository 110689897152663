import Cookies from 'js-cookie';
import Drawer from 'material-ui/Drawer';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';
import busPNG from 'static/bus.png';
import {
    ButtonColors,
    CalendarResourceGroup,
    CalendarResourceType,
    EventType,
    ReleaseMode,
    TaskType,
} from 'src/utils/constants';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import EventModal from 'src/components/events/event-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import SidebarContent from 'src/components/settings/settingscontent';
import EventRules from 'src/components/operatingtool/event-rules';
import { withTranslation } from 'react-i18next';

class Calendar extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        // event modal definition
        this.state = {
            settings: {
                open: false,
            },
        };

        // holds the actual data of the calendar
        this.calendarState = {
            events: [],
            resources: [],
            busCompany: props.busCompany,
        };
    }

    componentDidMount() {
        const { selectedCompany, resources, t } = this.props;

        this.props.fetchCalendarRelatedEntities(selectedCompany);

        // initial resource setting (is needed when we switch back to calendar view because of _.isEqual on resources)
        this.calendarState.resources = resources;

        this.populateCalendar(t);
    }

    componentWillReceiveProps(nextProps) {
        const calendar = this.calendar;
        const { events, resources, busCompany } = nextProps;

        if (events && events.action) {
            // if no errors process calendar events
            if (
                !events.error &&
                !_.isEqual(this.props.events, events) &&
                events.action !== 'REQUEST' &&
                events.action !== 'FAILURE'
            ) {
                if (events.action === 'FETCH') {
                    console.time('fetch');
                    this.calendarState.events = events.items;
                    this.calendarState.busCompany = busCompany;
                    console.timeEnd('fetch');
                } else if (events.action === 'UPDATE') {
                    console.time('update');
                    this.calendarState.events[
                        this.calendarState.events.findIndex(event => event.id === events.updatedEvent.id)
                    ] = events.updatedEvent;
                    console.timeEnd('update');
                    nextProps.selectEvent(events.updatedEvent);
                } else if (events.action === 'SAVE') {
                    console.time('save');
                    this.calendarState.events.push(events.updatedEvent);
                    nextProps.selectEvent(events.updatedEvent);
                    console.timeEnd('save');
                } else if (events.action === 'DELETE') {
                    console.time('delete');
                    _.remove(this.calendarState.events, event => event.id === events.deletedEvent);
                    console.timeEnd('delete');
                }

                $(calendar).fullCalendar('refetchEvents');
            }
        }

        // re-fetching resources if they changed in props
        if (!_.isEqual(this.props.resources, resources)) {
            this.calendarState.resources = resources;

            $(calendar).fullCalendar('refetchResources');
        }
    }

    componentWillUnmount() {
        const calendar = this.calendar;

        $(calendar).fullCalendar('destroy');

        this.props.selectEvent('');

        this.state = null;
        this.calendarState = null;
    }

    render() {
        let settingsSidebar;
        if (this.state.settings.open) {
            settingsSidebar = (
                <div id="sidebarcontent-main">
                    <SidebarContent handleClose={this.handleCloseSettingsSideBar} openModal={this.props.openModal} />
                </div>
            );
        }

        return (
            <div>
                {/*Settings Sidebar*/}
                <Drawer
                    openSecondary={true}
                    docked={false}
                    width={400}
                    containerClassName="sidebar"
                    onRequestChange={this.handleCloseSettingsSideBar}
                    open={this.state.settings.open}>
                    {settingsSidebar}
                </Drawer>

                {/*Calendar*/}
                <div ref={ref => (this.calendar = ref)} />
            </div>
        );
    }

    handleCloseSettingsSideBar() {
        this.setState({
            settings: {
                open: false,
            },
        });
    }

    onProceedMoveTaskInSameRow(task) {
        this.props.patch(
            this.updateAndCorrectTaskInSameRow(task),
            Entities.TASK,
            this.calendarState.busCompany.entity.id,
        );
    }

    populateCalendar(t) {
        const { selectEvent, i18n } = this.props;
        const calendar = this.calendar;
        let resourceIdDragStart;

        $(calendar).fullCalendar({
            locale: i18n.language,
            navLinks: true,
            nowIndicator: true,
            header: {
                left: 'prev,next,today',
                center: 'title',
                right: 'timelineDay,timelineWeek,timelineMonth,listWeek, settings',
            },
            buttonText: {
                today: t('calendar.today'),
                day: t('calendar.day'),
                week: t('calendar.week'),
                month: t('calendar.month'),
                list: t('calendar.list'),
            },
            customButtons: {
                settings: {
                    text: t('global_settings.settings'),
                    icon: 'settingsicon transparent fa fa-cog',
                    click: () => {
                        this.setState({
                            settings: { open: !this.state.settings.open },
                        });
                    },
                },
            },
            firstDay: 1,
            slotDuration: '00:15:00', // the default
            timeFormat: 'HH:mm',
            views: {
                timelineDay: {
                    slotDuration: '01:00:00',
                    snapDuration: '00:15:00',
                },
                timelineWeek: {
                    slotDuration: '24:00:00',
                },
                timelineMonth: {
                    slotDuration: '24:00:00',
                },
            },
            resourceOrder: 'position',
            resourceAreaWidth: () => {
                const resourceWidth = Cookies.get('calendarResourceWidth');
                return resourceWidth ? resourceWidth + 'px' : '15%';
            },
            contentHeight: () => window.innerHeight - 390,
            windowResize: () => {
                const width = $('.fc-time-area').width();
                if (width > 0) $('.detailscontainer-calendar').css({ width: width });
            },
            resourceColumns: [
                {
                    group: true,
                    field: 'groups',
                    render: function (resource, el) {
                        resource.css({
                            '-ms-transform': 'rotate(-90deg)',
                            transform: 'rotate(-90deg)',
                            overflow: 'visible',
                            visibility: 'visible',
                            margin: '0px 0 0 0',
                        });
                        resource.addClass('header_group');
                    },
                    text: title => {
                        return t(`calendar_resources.${title}`);
                    },
                },
                {
                    field: 'title',
                    text: resource => {
                        if (
                            resource.id !== CalendarResourceType.VEHICLE_INACTIVE &&
                            (resource.groups === CalendarResourceGroup.VEHICLES ||
                                (resource.parent && resource.parent.groups === CalendarResourceGroup.VEHICLES))
                        )
                            return resource.title;
                        return t(`calendar_resources.${resource.title}`);
                    },
                },
            ],
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //TODO: replace with commercial license key
            resourceGroupField: 'groupId',
            resources: callback => {
                callback(this.calendarState.resources);
            },
            events: (start, end, timezone, callback) => {
                callback(this.calendarState.events);
            },
            eventAfterAllRender: () => {
                const eventDetails = this.props.eventDetails;

                if (eventDetails && eventDetails.event) {
                    $('.fc-event').removeClass('selected-event');
                    $(`.fc-event#event${eventDetails.event.id}`).addClass('selected-event');

                    $('.fc-list-item').removeClass('selected-list-item');
                    $(`.fc-list-item#event${eventDetails.event.id}`).addClass('selected-list-item');
                }

                $('.fc-icon-down-triangle').each((i, icon) => {
                    //find all the triangle symbols
                    if (Cookies.get(`calendarResource-${icon.id}`) === 'close') $(icon).click(); //send a click event and let FC handle it
                });
            },
            eventLimit: true,
            defaultView: Cookies.get('calendarDefaultView') || 'timelineMonth',
            defaultDate: Cookies.get('calendarCurrentDate') || null,
            editable: true,
            selectable: true,
            select: (start, end, jsEvent, view, resource) => {
                end.subtract(1, 'minutes');

                if (resource.parent && resource.parent.groups === CalendarResourceGroup.VEHICLES) {
                    const busAvailableSeats =
                        resource.seatsFacingFront + resource.seatsFacingBack + resource.seatsForGuides;

                    if (resource.releaseMode === ReleaseMode.BLOCKED)
                        this.openEventModal({
                            eventType: EventType.RELEASE,
                            busId: resource.id,
                            busAvailableSeats: busAvailableSeats,
                            start: start,
                            end: end,
                            title: t('events.new_event'),
                            name: t('events.release'),
                            isCreate: true,
                        });
                    else if (resource.releaseMode === ReleaseMode.RELEASED)
                        this.openEventModal({
                            eventType: EventType.BLOCKAGE,
                            busId: resource.id,
                            busAvailableSeats: busAvailableSeats,
                            start: start,
                            end: end,
                            title: t('events.new_event'),
                            name: t('events.blockage'),
                            isCreate: true,
                        });
                } else if (resource.groups === CalendarResourceGroup.TASKS) {
                    if (resource.id === CalendarResourceType.TASK_INTERN) {
                        this.openEventModal({
                            eventType: EventType.TASK,
                            start: start,
                            end: end,
                            title: t('events.new_event'),
                            name: t('events.task'),
                            isCreate: true,
                        });
                    }
                }
            },
            eventClick: (event, jsEvent) => {
                selectEvent(event);
            },
            eventResize: (event, delta, revertFunc) => {
                if (!EventRules.isTask(event))
                    this.patchBlockageRelease({
                        revertFunc: revertFunc,
                        event: event,
                    });
                else if (EventRules.allowResizeWithModal(event)) {
                    const resource = $(calendar).fullCalendar('getResourceById', event.resourceId);
                    const busAvailableSeats =
                        resource.seatsFacingFront + resource.seatsFacingBack + resource.seatsForGuides;

                    this.props.openModal({
                        component: EventModal,
                        componentProps: {
                            id: event.id,
                            eventType: EventType.TASK,
                            isCreate: false,
                            busAvailableSeats: busAvailableSeats,
                        },
                        title: event.title,
                        mandatoryFields: true,
                        cancelCallback: revertFunc,
                    });
                } else revertFunc();
            },
            eventDragStart: event => {
                resourceIdDragStart = event.resourceId;
            },
            eventDrop: (event, delta, revertFunc) =>
                this.handleEventDrop(calendar, event, resourceIdDragStart, revertFunc),
            eventRender: (event, eventElement, view) => {
                const releasePercentage = event.percentage;

                if (!EventRules.isTask(event) || EventRules.isTaskEditableCalendar(event)) {
                    const eventType = EventRules.isTask(event) ? EventType.TASK : event.type;

                    eventElement.bind('dblclick', () => {
                        this.openEventModal({
                            title: event.title,
                            id: event.id,
                            eventType: eventType,
                            isCreate: false,
                        });
                    });
                }

                // event title as a tooltip
                eventElement.attr('title', event.title);
                eventElement.attr('id', `event${event.id}`);

                if (event.type === EventType.RELEASE) {
                    eventElement.find('.fc-event-title').attr('contentEditable', 'true').focus();
                    eventElement.find('.fc-content').append(
                        $('<div />', {
                            id: 'divReleasePercentage' + event.id,
                            class: 'releasePercentage',
                            on: {
                                mouseover: function () {
                                    $('#divReleasePercentage' + event.id).css('color', 'white');
                                },
                                mouseout: function () {
                                    $('#divReleasePercentage' + event.id).css('color', '#343434');
                                },
                            },
                        }).append(
                            $('<span />', {
                                text: releasePercentage,
                                id: 'releaseId' + event.id,
                                on: {
                                    click: () => {
                                        $('#releaseId' + event.id)
                                            .attr('contentEditable', 'true')
                                            .focus()
                                            .on('keydown', e => {
                                                const updatedPercentage = e.currentTarget.innerText;
                                                if (e.which === 13 && e.shiftKey === false) {
                                                    $('#releaseId' + event.id).attr('contentEditable', 'false');

                                                    this.patchBlockageRelease({
                                                        event: event,
                                                        percentage: updatedPercentage,
                                                    });
                                                }
                                            });
                                    },
                                },
                            }),
                            $('<span />', {
                                text: '%',
                            }),
                        ),
                    );
                }
                if (event.type !== EventType.BLOCKAGE && event.type !== EventType.RELEASE) {
                    if (
                        event.resourceId !== CalendarResourceType.TASK_INTERN &&
                        event.resourceId !== CalendarResourceType.TASK_EXTERN_OWN &&
                        event.resourceId !== CalendarResourceType.TASK_EXTERN_FOREIGN
                    ) {
                        if (event.detailsSentToDrivers) {
                            eventElement.find('.fc-title').prepend(
                                $('<span />', {
                                    id: 'driver-email-status',
                                }).append(
                                    $('<i />', {
                                        class: 'glyphicon glyphicon-send',
                                    }),
                                ),
                            );
                        }
                        if (event.secondDriver) {
                            const secondDriver = this.props.drivers.items.find(
                                driver => driver.id === event.secondDriver,
                            );
                            eventElement.find('.fc-title').prepend(
                                $('<span />', {
                                    id: 'event-title-seconddriver',
                                    class: 'fa-stack',
                                }).append(
                                    $('<i />', {
                                        class: 'fa fa-user',
                                        title: secondDriver
                                            ? secondDriver.contactData.fullName
                                            : t('driver.second_driver'),
                                    }),
                                ),
                            );
                        }
                        if (event.firstDriver) {
                            const firstDriver = this.props.drivers.items.find(
                                driver => driver.id === event.firstDriver,
                            );
                            eventElement.find('.fc-title').prepend(
                                $('<span />', {
                                    id: 'event-title-firstdriver',
                                    class: 'fa-stack',
                                }).append(
                                    $('<i />', {
                                        class: 'fa fa-user',
                                        title: firstDriver
                                            ? firstDriver.contactData.fullName
                                            : t('driver.first_driver'),
                                    }),
                                ),
                            );
                        }
                    } else {
                        const pax = event.passengers;
                        eventElement.find('.fc-title').prepend(
                            $('<span />', {
                                id: 'event-title-pax',
                                class: 'fa fa-bus',
                            }).append(
                                $('<span />', {
                                    text: pax,
                                }),
                            ),
                        );
                    }
                }
            },
            // add buttons to resource column
            resourceRender: (resourceObj, labelTds, bodyTds) => {
                if (
                    resourceObj.groups === CalendarResourceGroup.VEHICLES &&
                    resourceObj.id !== CalendarResourceType.VEHICLE_EXTERN
                ) {
                    labelTds.css({
                        'background-color': 'lightgrey',
                        'font-size': '10px',
                    });
                    bodyTds.css({ 'background-color': 'lightgrey' });
                    labelTds.find('.fc-icon').attr('id', 'ICON_' + resourceObj.id);
                    labelTds.find('.fc-icon').on('click', event => {
                        if ($(event.currentTarget).hasClass('fc-icon-down-triangle'))
                            Cookies.set(`calendarResource-${event.currentTarget.id}`, 'close', {
                                expires: 10 * 365,
                                secure: true,
                                sameSite: 'none',
                            });
                        else
                            Cookies.set(`calendarResource-${event.currentTarget.id}`, 'open', {
                                expires: 10 * 365,
                                secure: true,
                                sameSite: 'none',
                            });
                    });
                } else if (
                    resourceObj.parent &&
                    resourceObj.parent.groups === CalendarResourceGroup.VEHICLES &&
                    resourceObj.parent.id !== CalendarResourceType.VEHICLE_EXTERN
                ) {
                    const { busAvatar, seatsFacingFront, seatsFacingBack, seatsForGuides, regularDriver } = resourceObj;
                    const avatarURL = busAvatar ? `${TOMCAT_URL}public/documents/${busAvatar.filename}` : `${busPNG}`;

                    // set tooltip with available seats in bus
                    labelTds.attr(
                        'title',
                        t('bus.seats_info', {
                            seatsFacingFront: resourceObj.seatsFacingFront,
                            seatsFacingBack: resourceObj.seatsFacingBack,
                            seatsForGuides: resourceObj.seatsForGuides,
                        }),
                    );

                    labelTds.find('.fc-icon').remove();
                    labelTds.find('.fc-cell-text').prepend(
                        $('<span />', {
                            class: 'resource-img',
                        }).append(
                            $('<img />', {
                                src: avatarURL,
                                height: '14',
                                width: '25',
                            }),
                        ),
                    );
                    labelTds.find('.fc-cell-text').append(
                        $('<span />', {
                            text: ` (${seatsFacingFront + seatsFacingBack + seatsForGuides}) `,
                        }),
                    );

                    if (regularDriver) {
                        labelTds.find('.fc-cell-text').append(
                            $('<i />', {
                                class: 'fa fa-user',
                                title: regularDriver.contactData.fullName,
                            }),
                        );
                    }

                    if (resourceObj.releaseMode === ReleaseMode.BLOCKED)
                        bodyTds.css({
                            'background-color': 'hsla(0,100%,50%,0.1)',
                        });

                    if (resourceObj.parent.id === CalendarResourceType.VEHICLE_INACTIVE)
                        bodyTds.css({
                            'background-color': 'hsla(328, 7%, 44%, 0.39)',
                        });

                    /*else if (resourceObj.releaseMode === ReleaseMode.RELEASED)
                     bodyTds.css({'background-color': 'hsla(120, 66%, 42%, 0.65)'});*/
                } else if (resourceObj.groups === CalendarResourceGroup.TASKS) {
                    if (resourceObj.id === CalendarResourceType.TASK_INTERN) {
                        labelTds.find('.fc-cell-content').append(
                            $('<span />', {
                                class: 'glyphicon glyphicon-plus pull-right',
                                id: 'new_task',
                                on: {
                                    click: () => {
                                        this.openEventModal({
                                            eventType: EventType.TASK,
                                            start: moment().startOf('day'),
                                            end: moment().startOf('day').hour(23).minute(59),
                                            title: t('events.new_task'),
                                            name: t('events.task'),
                                            isCreate: true,
                                        });
                                    },
                                },
                            }),
                        );
                    }
                }
            },
            viewRender: (view, element) => {
                if (view.type === 'listWeek') $('.fc-settings-button').css({ display: 'none' });
                else $('.fc-settings-button').css({ display: 'block' });

                Cookies.set('calendarDefaultView', view.name, {
                    expires: 10 * 365,
                    secure: true,
                    sameSite: 'none',
                });
                Cookies.set('calendarCurrentDate', view.intervalStart.format(), { secure: true, sameSite: 'none' });

                element.find('.fc-col-resizer').on('mousedown', function () {
                    $(document).on('mouseup', function () {
                        const headerWidth = $('.header_group').first().parent().css('width');
                        const resourceWidth = element.find('.fc-resource-area').width();

                        $('.busdrivercontainer').css({
                            width: resourceWidth + 'px',
                        });
                        $('.busdrivertable').innerWidth(resourceWidth - headerWidth + 'px');
                        $('#bus-driverlist').innerWidth(resourceWidth - headerWidth + 'px');

                        Cookies.set('calendarResourceWidth', resourceWidth, {
                            expires: 10 * 365,
                            secure: true,
                            sameSite: 'none',
                        });
                        if (element.find('.fc-time-area').width() > 0) {
                            $('.detailscontainer-calendar').css({
                                width: element.find('.fc-time-area').width(),
                            });
                        }

                        // Change busfahrer-width according to calendar
                        $('#header_buslenker').parent().css({ width: headerWidth });

                        $(document).on('mouseup', function () {});
                    });
                });

                // Change busfahrer-width according to calendar
                $('#header_buslenker')
                    .parent()
                    .css({
                        width: $('.header_group').first().parent().css('width'),
                    });

                $('.busdrivercontainer').css({
                    width: element.find('.fc-resource-area').width() + 'px',
                });
                if (element.find('.fc-time-area').width() > 0) {
                    $('.detailscontainer-calendar').css({
                        width: element.find('.fc-time-area').width(),
                    });
                }
            },
        });
    }

    onReceiveDriverState(response, parameter, t) {
        const { addNotification } = this.props;
        const { drivers, actionType } = parameter;

        let allDriversAreAvailable = true;

        if (drivers.length > 0) {
            drivers.forEach(driver => {
                const driverInRange = response.json.find(d => d.id === driver.id);
                if (driverInRange) {
                    if (actionType === 'MOVE_TASK_RANGE') {
                        if (driverInRange.state === 'SHORT_BREAK')
                            addNotification(
                                t('warning'),
                                t('driver.error_too_short_pause', { driver: driver.contactData.firstName }),
                                'warning',
                                'tr',
                            );
                        else if (driverInRange.state === 'NOT_AVAILABLE') {
                            addNotification(
                                t('attention'),
                                t('driver.error_too_short_pause_9', { driver: driver.contactData.firstName }),
                                'error',
                                'tr',
                            );
                            allDriversAreAvailable = false;
                            parameter.revertFunc();
                        }
                    } else if (actionType === 'ASSIGN_REGULAR_DRIVER') {
                        if (driverInRange.state === 'SHORT_BREAK')
                            addNotification(
                                t('warning'),
                                t('driver.error_too_short_pause_regular_driver', {
                                    driver: driver.contactData.firstName,
                                    bus: parameter.patchProps.resource.title,
                                }),
                                'warning',
                                'tr',
                            );
                        else if (driverInRange.state === 'NOT_AVAILABLE') {
                            addNotification(
                                t('attention'),
                                t('driver.error_too_short_pause_9_regular_driver', {
                                    driver: driver.contactData.firstName,
                                    bus: parameter.patchProps.resource.title,
                                }),
                                'error',
                                'tr',
                            );
                            allDriversAreAvailable = false;
                        }
                    }
                }
            });
        }

        if (actionType === 'MOVE_TASK_RANGE' && allDriversAreAvailable) this.openTaskMoveModal(parameter);
        else if (actionType === 'ASSIGN_REGULAR_DRIVER')
            this.handleRegularDriverAssign(allDriversAreAvailable, parameter, drivers, addNotification);
    }

    handleRegularDriverAssign(allDriversAreAvailable, parameter, drivers, addNotification) {
        const { t } = this.props;
        if (allDriversAreAvailable) {
            if (
                parameter.resourceIdDragStart === CalendarResourceType.TASK_INTERN &&
                parameter.patchProps.event.firstDriver
            ) {
                this.props.openModal({
                    component: ConfirmDialog,
                    componentProps: {
                        event: parameter.patchProps,
                        onProceedDialog: patchProps => {
                            const regularDriver = drivers[0];

                            addNotification(
                                t('driver.driver_assigned'),
                                t('driver.assigned_regular_driver', {
                                    driver: regularDriver.contactData.firstName,
                                    bus: patchProps.resource.title,
                                }),
                                'success',
                                'tr',
                            );

                            patchProps.updateEvent.firstDriver = `${TOMCAT_URL}api/drivers/${regularDriver.id}`;

                            setTimeout(() => this.patchTaskOnBus(patchProps), 100);
                        },
                        onCancelDialog: patchProps => {
                            setTimeout(() => this.patchTaskOnBus(patchProps), 100);
                        },
                        bodyText: t('driver.confirm_assigning'),
                        proceedText: t('common_phrases.yes'),
                        cancelText: t('common_phrases.no'),
                        cancelButtonColor: ButtonColors.NOTIFY,
                        proceedButtonColor: ButtonColors.SUCCESS,
                    },
                    title: parameter.patchProps.event.name,
                    noButtons: true,
                });
            } else {
                const regularDriver = drivers[0];
                addNotification(
                    t('driver.driver_assigned'),
                    t('driver.assigned_regular_driver', {
                        driver: regularDriver.contactData.firstName,
                        bus: parameter.patchProps.resource.title,
                    }),
                    'success',
                    'tr',
                );

                parameter.patchProps.updateEvent.firstDriver = `${TOMCAT_URL}api/drivers/${regularDriver.id}`;

                this.patchTaskOnBus(parameter.patchProps);
            }
        } else this.patchTaskOnBus(parameter.patchProps);
    }

    openTaskMoveModal(parameter) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: parameter.event,
                onProceedDialog: this.onProceedMoveTaskInSameRow,
                onCancelDialog: () => parameter.revertFunc(),
                bodyText: this.getMoveTaskInSameRowDialogBody(parameter.event),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
                dialogStyle: {
                    width: '90%',
                    maxWidth: 'none',
                },
            },
            title: parameter.event.title,
            noButtons: true,
        });
    }

    patchBlockageRelease(props) {
        const { t } = this.props;
        const calendar = this.calendar;
        const { event, revertFunc, percentage } = props;
        const resource = $(calendar).fullCalendar('getResourceById', event.resourceId);

        // check that release or blockage is not dropped in own mode or on column group
        if (!EventRules.allowBlockageReleaseDrop(event, resource)) {
            revertFunc();
            return;
        }

        const releaseBlockagePatch = {
            id: event.id,
            from: {
                time: moment(event.start).format('YYYY-MM-DD[T]HH:mm'),
                location: event.location.from,
            },
            to: {
                time: moment(event.end).format('YYYY-MM-DD[T]HH:mm'),
                location: event.location.to,
            },
            bus: resource.selfLink ? resource.selfLink : null,
            percentage: percentage,
        };

        if (event.type === EventType.RELEASE)
            this.props.patch(releaseBlockagePatch, Entities.RELEASE, this.calendarState.busCompany.entity.id);
        else if (event.type === EventType.BLOCKAGE)
            this.props.patch(releaseBlockagePatch, Entities.BLOCKAGE, this.calendarState.busCompany.entity.id);
    }

    handleEventDrop(calendar, event, resourceIdDragStart, revertFunc) {
        const { selectedCompany, drivers, t } = this.props;
        const resource = $(calendar).fullCalendar('getResourceById', event.resourceId);

        if (EventRules.isTask(event)) {
            if (EventRules.allowPatchEvent(event, resource, resourceIdDragStart)) {
                // task has been dropped in different row (preserve time range)
                if (resourceIdDragStart !== event.resourceId) {
                    this.patchTask({
                        revertFunc: revertFunc,
                        event: event,
                        resource: resource,
                        resourceIdDragStart: resourceIdDragStart,
                        t: t,
                    });
                }

                // task has been dropped in same row and needs special update to correct routes
                else {
                    const fromTime = moment(event.start).format('YYYY-MM-DD[T]HH:mm');
                    const toTime = moment(event.end).format('YYYY-MM-DD[T]HH:mm');
                    asyncRequest(
                        `${TOMCAT_URL}api/${Entities.DRIVER.repository}/range?fromTime=${fromTime}&toTime=${toTime}&taskId=${event.id}&companyId=${selectedCompany}`,
                    ).then(response =>
                        this.onReceiveDriverState(
                            response,
                            {
                                actionType: 'MOVE_TASK_RANGE',
                                event: event,
                                drivers: drivers.items.filter(
                                    driver => driver.id === event.firstDriver || driver.id === event.secondDriver,
                                ),
                                revertFunc: revertFunc,
                            },
                            t,
                        ),
                    );
                }
            } else revertFunc();
        }

        // event is release or blockage
        else {
            this.patchBlockageRelease({
                event: event,
                revertFunc: revertFunc,
            });
        }
    }

    getMoveTaskInSameRowDialogBody(task) {
        const { t } = this.props;
        const newTaskTimes = this.updateAndCorrectTaskInSameRow(task);

        return (
            <div className="moveTaskDetails">
                <div className="panel panel-primary">
                    <div className="panel-heading">{t('time.old_period')}</div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>{t('booked_tasks.from')}</th>
                                <th>{t('booked_tasks.to')}</th>
                                <th>{t('task_details.routes')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    {t('administration.time', { time: moment(task.start._i).format() })} &nbsp;{' '}
                                    {task.location.from.address}
                                </td>
                                <td>
                                    {t('administration.time', { time: moment(task.end._i).format() })} &nbsp;{' '}
                                    {task.location.to.address}
                                </td>
                                <td>
                                    <p>
                                        {t('administration.time', { time: moment(task.taskFrom.time).format() })} &nbsp;
                                        {task.taskFrom.location.address}
                                    </p>
                                    {task.intermediates &&
                                        task.intermediates.length > 0 &&
                                        task.intermediates.map((intermediate, index) => {
                                            return (
                                                <p key={index}>
                                                    {moment(intermediate.time).format()}
                                                    Uhr &nbsp; {intermediate.location.address}
                                                </p>
                                            );
                                        })}
                                    <p>
                                        {t('administration.time', { time: moment(task.taskTo.time).format() })} &nbsp;
                                        {task.taskTo.location.address}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-primary">
                    <div className="panel-heading">{t('time.new_period')}</div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>{t('booked_tasks.from')}</th>
                                <th>{t('booked_tasks.to')}</th>
                                <th>{t('task_details.routes')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    {t('administration.time', { time: moment(newTaskTimes.from.time).format() })} &nbsp;
                                    {newTaskTimes.from.location.address}
                                </td>
                                <td>
                                    {t('administration.time', { time: moment(newTaskTimes.to.time).format() })} &nbsp;
                                    {newTaskTimes.to.location.address}
                                </td>
                                <td>
                                    <p>
                                        {t('administration.time', {
                                            time: moment(newTaskTimes.taskFrom.time).format(),
                                        })}{' '}
                                        &nbsp;
                                        {newTaskTimes.taskFrom.location.address}
                                    </p>
                                    {newTaskTimes.intermediates &&
                                        newTaskTimes.intermediates.length > 0 &&
                                        newTaskTimes.intermediates.map((intermediate, index) => {
                                            return (
                                                <p key={index}>
                                                    {t('administration.time', {
                                                        time: moment(intermediate.time).format(),
                                                    })}{' '}
                                                    &nbsp;
                                                    {intermediate.location.address}
                                                </p>
                                            );
                                        })}
                                    <p>
                                        {t('administration.time', { time: moment(newTaskTimes.taskTo.time).format() })}{' '}
                                        &nbsp;
                                        {newTaskTimes.taskTo.location.address}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    patchTask(props) {
        const { resource, revertFunc, event, resourceIdDragStart, t } = props;
        const { selectedCompany } = this.props;

        const dropTaskStart = moment(event.start._i).format('YYYY-MM-DD[T]HH:mm');
        const dropTaskEnd = moment(event.end._i).format('YYYY-MM-DD[T]HH:mm');

        const updateEvent = {
            id: event.id,
            from: {
                time: dropTaskStart,
                location: event.location.from,
            },
            to: {
                time: dropTaskEnd,
                location: event.location.to,
            },
            bus: resource.selfLink ? resource.selfLink : null,
        };

        // task has been dropped on bus
        if (resource.parent && resource.parent.groups === CalendarResourceGroup.VEHICLES) {
            // check if bus has enough seats for task
            if (!EventRules.busHasEnoughSeatsForTask(event, resource)) {
                this.props.addNotification(
                    t('warning'),
                    t('bus.too_less_seats', { bus: resource.title, event: event.title }),
                    'warning',
                    'tr',
                );

                revertFunc();
            } else {
                const patchProps = {
                    resource: resource,
                    event: event,
                    updateEvent: updateEvent,
                };

                // bus has regular driver -> so check if he is available
                if (resource.regularDriver) {
                    asyncRequest(
                        `${TOMCAT_URL}api/${Entities.DRIVER.repository}/range?fromTime=${dropTaskStart}&toTime=${dropTaskEnd}&taskId=${event.id}&companyId=${selectedCompany}`,
                    ).then(response =>
                        this.onReceiveDriverState(
                            response,
                            {
                                actionType: 'ASSIGN_REGULAR_DRIVER',
                                drivers: [resource.regularDriver],
                                revertFunc: revertFunc,
                                resourceIdDragStart: resourceIdDragStart,
                                patchProps: patchProps,
                            },
                            t,
                        ),
                    );

                    // bus has no regular driver
                } else {
                    // if task has been dropped from another bus -> remove driver
                    if (props.resourceIdDragStart !== CalendarResourceType.TASK_INTERN && event.firstDriver) {
                        this.props.addNotification(t('attention'), t('driver.error_no_driver'), 'error', 'tr');

                        updateEvent.firstDriver = null;
                    }

                    this.patchTaskOnBus(patchProps);
                }
            }

            // task has been dropped on resource without a bus
        } else {
            if (
                event.type === TaskType.OPEN_OFFER ||
                event.type === TaskType.DECLINED_OFFER ||
                event.type === TaskType.DELETED_OFFER
            ) {
                this.props.addNotification(
                    t('common_phrases.error'),
                    t('booked_tasks.error_event_needs_bus_offer', { event: event.title }),
                    'error',
                    'tr',
                );

                revertFunc();
            } else if (event.customer != null) {
                this.props.addNotification(
                    t('common_phrases.error'),
                    t('booked_tasks.error_event_needs_bus', { event: event.title }),
                    'error',
                    'tr',
                );

                revertFunc();
            } else this.props.patch(updateEvent, Entities.TASK, selectedCompany);
        }
    }

    patchTaskOnBus(props) {
        const { selectedCompany, t } = this.props;

        props.updateEvent.from.location = props.resource.homeBase;
        props.updateEvent.to.location = props.resource.homeBase;

        setTimeout(() => {
            this.props.addNotification(
                t('task_details.added_location'),
                t('booked_tasks.info_start_end', { position: props.resource.title }),
                'success',
                'tr',
            );
        }, 100);

        this.props.patch(props.updateEvent, Entities.TASK, selectedCompany);
    }

    /**
     * Correcting all routes of the task if task has been dropped in same row
     * @param event task of interest
     * @returns {{}}
     */
    updateAndCorrectTaskInSameRow(event) {
        const updatedTask = {
            id: event.id,
        };

        // format date to prevent timezone offset
        const startDate = moment(event.start).format('YYYY-MM-DD[T]HH:mm');
        const endDate = moment(event.end).format('YYYY-MM-DD[T]HH:mm');

        // calculate difference
        const difference = moment.duration(moment(startDate).diff(moment(event.start._i)));

        // update dates according to difference
        updatedTask.from = {
            time: startDate,
            location: event.location.from,
        };

        updatedTask.to = {
            time: endDate,
            location: event.location.to,
        };

        updatedTask.taskFrom = {
            time: this.calculateDateDifference(event.taskFrom.time, difference),
            location: event.taskFrom.location,
        };

        updatedTask.taskTo = {
            time: this.calculateDateDifference(event.taskTo.time, difference),
            location: event.taskTo.location,
        };

        if (event.intermediates && event.intermediates.length > 0) {
            updatedTask.intermediates = [];

            event.intermediates.forEach(intermediate => {
                updatedTask.intermediates.push({
                    time: this.calculateDateDifference(intermediate.time, difference),
                    location: intermediate.location,
                });
            });
        }

        return updatedTask;
    }

    calculateDateDifference(date, difference) {
        return moment(date)
            .add(difference.days(), 'days')
            .add(difference.hours(), 'hours')
            .add(difference.minutes(), 'minutes')
            .format('YYYY-MM-DD[T]HH:mm');
    }

    openEventModal(props) {
        this.props.openModal({
            component: EventModal,
            componentProps: {
                id: props.id,
                eventType: props.eventType,
                isCreate: props.isCreate,
                isOffer: props.isOffer,
                initialValuesOnCreate: {
                    start: props.start,
                    end: props.end,
                    fromLocation: this.calendarState.busCompany.entity.location,
                    busId: props.busId,
                    name: props.name,
                },
            },
            title: props.title,
            mandatoryFields: true,
        });
    }
}

export default withTranslation()(Calendar);
