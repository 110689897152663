import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TableHelper from 'src/utils/table-helper';
import { Entities, Projection } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import DiscountGroupEditModal from 'src/components/administration/discount-group-edit-modal';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

class DiscountGroupAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchByCompanyId(Entities.DISCOUNT_GROUP, this.props.selectedCompany, Projection.FULL);
        this.props.fetchByCompanyId(Entities.BUS, this.props.selectedCompany, Projection.FULL);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { discountGroups, buses } = nextProps;

        if (discountGroups.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
        this.tableHelper.processPagination(discountGroups.items.length);

        if (!_.isEqual(discountGroups, this.props.discountGroups)) {
            this.setState({ discountGroups: nextProps.discountGroups });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.discountGroups,
                this.props.discountGroups,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { discountGroups, t, buses } = this.props;
        const finishFetching = this.state.startedFetching && !discountGroups.isFetching;
        const tableData = [];

        if (finishFetching && discountGroups.items.length > 0) {
            discountGroups.items.map(discountGroup => {
                tableData.push({
                    id: discountGroup.id,
                    name: discountGroup.name,
                    discount: discountGroup.discount,
                    calculations: discountGroup.calculations,
                    buses: discountGroup.busDiscountCalculations.length + `/${buses.items.length}`,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('administration.discount_groups')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: DiscountGroupEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('administration.new_discount_group'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('administration.new_discount_group')}
                    </button>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (discountGroups.error) return <ErrorMessage object={discountGroups} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="discount" dataSort={true}>
                                    {t('coupons.discount')}
                                </TableHeaderColumn>
                                {this.props.busCompany.entity.busDiscountGroupEnabled && (
                                    <TableHeaderColumn columnTitle={true} dataField="buses" dataSort={true}>
                                        {t('companies.buses')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, discountGroup) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() =>
                        this.props.openModal({
                            component: DiscountGroupEditModal,
                            componentProps: {
                                id: discountGroup.id,
                                isCreate: false,
                            },
                            title: discountGroup.name,
                            mandatoryFields: true,
                        })
                    }>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(DiscountGroupAdministration));
