import moment from 'moment';
import { getDayOfWeek } from '../../utils/daysOfWeek';
import { TripEventResource, TripEventType } from 'src/selectors/trip-calendar-events/trip-calendar-events';

/**
 * Generates contingent events for the given seasons and the selected calendar date range
 */
export const getContingentEvents = (seasons, calendarDateRange) => {
    const days = moment.duration(moment(calendarDateRange.end).diff(moment(calendarDateRange.start))).asDays();

    const events = [];
    const singleSeason = seasons.length === 1;

    // iterate over all days of the selected period and create opening hour events
    for (let i = 0; i < days; i++) {
        const currentDay = moment(calendarDateRange.start).add(i, 'days');

        // determine season for the current day
        const season = singleSeason
            ? seasons[0]
            : seasons.find(
                  season =>
                      currentDay.isSameOrAfter(season.periodFrom, 'day') &&
                      currentDay.isSameOrBefore(season.periodTill, 'day'),
              );

        if (season) {
            // get opening hours of the current day of the week
            const dailyOpeningHours = season.openingHours[getDayOfWeek(currentDay.isoWeekday())];

            // create and add contingent events
            if (dailyOpeningHours) events.push(...getContingentEventsOfDay(currentDay, dailyOpeningHours));
        }
    }

    return events;
};

/**
 * Generates contingent events for the given day and opening hours
 */
const getContingentEventsOfDay = (currentDay, openingHours) => {
    // check if this day has opening hours -> otherwise return an empty array (no events available)
    if (openingHours.first || openingHours.second) {
        // no first opening hours available -> only create events for second opening hours
        if (!openingHours.first) return [createOpeningHourEvent(currentDay, openingHours, false)];

        // no second opening hours available -> only create events for first opening hours
        if (!openingHours.second) return [createOpeningHourEvent(currentDay, openingHours, true)];

        // both opening hours available -> create events for them
        return [
            createOpeningHourEvent(currentDay, openingHours, true),
            createOpeningHourEvent(currentDay, openingHours, false),
        ];
    }

    return [];
};

/**
 * Creates a single opening hour contingent event for the full calendar
 */
const createOpeningHourEvent = (currentDay, openingHour, first) => {
    const currentDayString = currentDay.format('YYYY-MM-DD');
    const specificOpeningHour = first ? openingHour.first : openingHour.second;

    const start = moment(`${currentDayString}T${specificOpeningHour.from}`);

    return {
        id: start.unix(),
        title: `${specificOpeningHour.pax} PAX`,
        resourceId: TripEventResource.CONTINGENT,
        start: moment(`${currentDayString}T${specificOpeningHour.from}`),
        end: moment(`${currentDayString}T${specificOpeningHour.to}`),
        color: '#8bc34a',
        borderColor: 'lightgrey',
        type: TripEventType.CONTINGENT,
        pax: specificOpeningHour.pax,
    };
};
