import { TFunction } from 'i18next';
import { asyncCheckDuplicate } from 'src/components/misc/redux-form-helpers';
import { TOMCAT_URL } from 'src/common/index';

const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/i;
// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isEmailInvalid = (value: string): boolean => !emailRegex.test(value);
export const notValidTime = (value: any): boolean => value && !timeRegex.test(value);
export const validTime = (value: any): boolean => value && timeRegex.test(value);
export const isBlank = (value: any): boolean =>
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0);

/** Require http or https and forbid whitespace, and forbid special chars at the start */
const regUrl = /^https?:\/\/[^\s/\\$.?#]\S*$/;

export const isUrl = (value: any): boolean => value == null || value === '' || regUrl.test(value);

export const integrationAsyncValidate = (t: TFunction, repository: string, values: any, blurredField?: string) => {
    if (blurredField === 'name')
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${repository}/search/findByName?name=${encodeURIComponent(values.name)}`,
            values.id,
            'name',
        );

    if (blurredField === 'url')
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${repository}/search/findByUrl?url=${encodeURIComponent(values.url)}`,
            values.id,
            'url',
            t('error_hint.url_already_exists'),
        );

    return asyncCheckDuplicate(
        t,
        `${TOMCAT_URL}api/${repository}/search/findByName?name=${encodeURIComponent(values.name)}`,
        values.id,
        'name',
    ).then(res => {
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${repository}/search/findByUrl?url=${encodeURIComponent(values.url)}`,
            values.id,
            'url',
            t('error_hint.url_already_exists'),
        );
    });
};
