import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { FilterToggle } from './filter-toggle';

class LegendStats extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        const stats = {
            companies: 0,
            buses: 0,
        };

        props.companiesWithBuses.forEach(company => {
            stats.companies++;
            stats.buses += company.buses.filter(bus => bus.active).length;
        });

        this.state = { stats: stats };
    }

    render() {
        const { t } = this.props;

        return (
            <legend className="map-legend">
                <span>
                    {t('bus.visible_companies')} {this.state.stats.companies}
                </span>

                <span>
                    {t('bus.active_buses')}: {this.state.stats.buses}
                </span>

                <span>
                    {t('bus.bus_locations')}: {this.props.busLocationCount ? this.props.busLocationCount : '-'}
                </span>

                <span>
                    {t('bus.company_locations')}:{' '}
                    {this.props.companyLocationCount ? this.props.companyLocationCount : '-'}
                </span>

                <div style={{ display: 'flex', gap: 30 }} className="pull-right">
                    <FilterToggle
                        // TODO: BUF-1135: translate
                        label="Buses"
                        toggled={this.props.showBuses}
                        onToggle={this.props.toggleShowBuses}
                    />
                    <FilterToggle
                        // TODO: BUF-1135: translate
                        label="Companies"
                        toggled={this.props.showCompanies}
                        onToggle={this.props.toggleShowCompanies}
                    />
                    <FilterToggle
                        // TODO: BUF-1135: translate
                        label="Clustering"
                        toggled={this.props.clustering}
                        onToggle={this.props.toggleClustering}
                    />
                </div>
            </legend>
        );
    }
}

export default LegendStats;
