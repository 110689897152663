import { connect } from 'react-redux';
import { addNotification } from 'src/actions/notifications';
import { deleteById, Entities, patch } from 'src/common/index';
import { deleteReview, fetchBookedTasks } from 'src/actions/tasks';
import { getOwnAccount } from 'src/selectors/accounts';
import { getBookedTasks } from 'src/selectors/tasks';
import AdminBookedTasksAdministration from 'src/components/admin/booked-tasks/admin-booked-tasks-administration';

const mapStateToProps = state => {
    return {
        tasks: getBookedTasks(state),
        reviews: state.entities[Entities.REVIEW.repository],
        account: getOwnAccount(state),
    };
};

const AdminBookedTasksAdministrationContainer = connect(mapStateToProps, {
    fetchBookedTasks,
    patch,
    deleteById,
    deleteReview,
    addNotification,
})(AdminBookedTasksAdministration);

export default AdminBookedTasksAdministrationContainer;
