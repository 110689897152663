import { List, ListItem, makeSelectable } from 'material-ui/List';
import { blue700 } from 'material-ui/styles/colors';
import EuroIcon from 'material-ui/svg-icons/action/euro-symbol';
import HomeIcon from 'material-ui/svg-icons/action/home';
import CategoryIcon from 'material-ui/svg-icons/av/library-books';
import TextFormatIcon from 'material-ui/svg-icons/content/text-format';
import BusIcon from 'material-ui/svg-icons/maps/directions-bus';
import PersonIcon from 'material-ui/svg-icons/social/person';
import CustomerIcon from 'material-ui/svg-icons/social/person';
import SunIcon from 'material-ui/svg-icons/image/wb-sunny';
import CountryIcon from 'material-ui/svg-icons/image/panorama';
import CustomerGroupIcon from 'material-ui/svg-icons/social/group';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import IntegrationIcon from 'material-ui/svg-icons/hardware/desktop-windows';
import InvoiceIcon from 'material-ui/svg-icons/action/receipt';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withTranslation } from 'react-i18next';

let SelectableList = makeSelectable(List);

const items = [
    '/administration/company',
    '/administration/categories',
    '/administration/seasons',
    '/administration/countries',
    '/administration/calculation',
    '/administration/buses',
    '/administration/drivers',
    '/administration/customers',
    '/administration/customer-group-settings',
    '/administration/customer-groups',
    '/administration/discount-groups',
    '/administration/templates',
    '/administration/settings',
    '/administration/integration',
    '/administration/invoices',
    '/administration/referrals',
];

class Administration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            selectedIndex: Math.max(items.indexOf(window.location.pathname), 0),
        };
    }

    render() {
        const { push, children, t } = this.props;

        return (
            <div className="row">
                <div id="administration" className="col-md-2 sidebar-listview">
                    <SelectableList
                        value={this.state.selectedIndex}
                        onChange={(event, index) => this.setState({ selectedIndex: index })}>
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={0}
                            value={0}
                            primaryText={t('companies.company_data')}
                            leftIcon={<HomeIcon color={blue700} />}
                            onClick={() => push('/administration/company')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={1}
                            value={1}
                            primaryText={t('common_phrases.categories')}
                            leftIcon={<CategoryIcon color={blue700} />}
                            onClick={() => push('/administration/categories')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={2}
                            value={2}
                            primaryText={t('administration.season_calculation')}
                            leftIcon={<SunIcon color={blue700} />}
                            onClick={() => push('/administration/seasons')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={3}
                            value={3}
                            primaryText={t('administration.country_calculation')}
                            leftIcon={<CountryIcon color={blue700} />}
                            onClick={() => push('/administration/countries')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={4}
                            value={4}
                            primaryText={t('administration.calculation')}
                            leftIcon={<EuroIcon color={blue700} />}
                            onClick={() => push('/administration/calculation')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={5}
                            value={5}
                            primaryText={t('companies.buses')}
                            leftIcon={<BusIcon color={blue700} />}
                            onClick={() => push('/administration/buses')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={6}
                            value={6}
                            primaryText={t('administration.driver')}
                            leftIcon={<PersonIcon color={blue700} />}
                            onClick={() => push('/administration/drivers')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={7}
                            value={7}
                            primaryText={t('customer_details.customer')}
                            leftIcon={<CustomerIcon color={blue700} />}
                            onClick={() => push('/administration/customers')}
                        />
                        <ListItem
                            style={{ fontSize: '13px' }}
                            key={8}
                            value={8}
                            primaryText={t('customer_group.group_settings')}
                            nestedItems={[
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={9}
                                    value={9}
                                    primaryText={t('customer_group.group_discounts')}
                                    leftIcon={<CustomerGroupIcon color={blue700} />}
                                    onClick={() => push('/administration/customer-groups')}
                                />,
                            ]}
                            // leftIcon={<CustomerGroupIcon color={blue700} />}
                            leftIcon={<SettingsIcon color={blue700} />}
                            onClick={() => push('/administration/customer-group-settings')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={10}
                            value={10}
                            primaryText={t('administration.discount_groups')}
                            leftIcon={<CustomerGroupIcon color={blue700} />}
                            onClick={() => push('/administration/discount-groups')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={11}
                            value={11}
                            primaryText={t('administration.text_pattern')}
                            leftIcon={<TextFormatIcon color={blue700} />}
                            onClick={() => push('/administration/templates')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={12}
                            value={12}
                            primaryText={t('global_settings.settings')}
                            leftIcon={<SettingsIcon color={blue700} />}
                            onClick={() => push('/administration/settings')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={13}
                            value={13}
                            primaryText={t('administration.statistics_tool')}
                            leftIcon={<IntegrationIcon color={blue700} />}
                            onClick={() => push('/administration/integration')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={14}
                            value={14}
                            primaryText={t('invoice.invoices')}
                            leftIcon={<InvoiceIcon color={blue700} />}
                            onClick={() => push('/administration/invoices')}
                        />
                    </SelectableList>
                </div>
                <div className="col-md-10">{children}</div>
            </div>
        );
    }
}

export default withTranslation()(connect(null, { push })(Administration));
