import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import { SELECT_EVENT } from 'src/actions/events';
import { SET_SLIDER_INDEX } from 'src/actions/form';
import { FETCH_METHOD } from 'src/actions/tasks';
import { Entities } from 'src/common/index';
import entitiesReducer from 'src/reducers/entities';
import notificationsReducer from 'src/reducers/notifications';
import documentsReducer from 'src/reducers/documents';
import formSubmitReducer from 'src/reducers/form-submit';
import { SET_CALENDAR_DATE_RANGE } from 'src/actions/calendar';
import operatingToolIntegrationReducer from 'src/reducers/integrations';

function adminNews(state = {}, action) {
    switch (action.type) {
        case 'SET_ADMIN_NEWS':
            return action.payload;
        default:
            return state;
    }
}

function selectedCompany(state = {}, action) {
    switch (action.type) {
        case `SUCCESS_FETCH_${Entities.ACCOUNT.action}`:
            if (action.payload.company) return action.payload.company;
            else return state;
        default:
            return state;
    }
}

function selectedEvent(state = '', action) {
    switch (action.type) {
        case SELECT_EVENT:
            return action.event;
        default:
            return state;
    }
}

function setCalendarDateRange(
    state = {
        start: moment(),
        end: moment(),
    },
    action,
) {
    switch (action.type) {
        case SET_CALENDAR_DATE_RANGE:
            return action.payload;
        default:
            return state;
    }
}

function selectedSliderIndex(state = 0, action) {
    if (action.type === SET_SLIDER_INDEX) return action.index;
    else return state;
}

function fetchMethodOnError(state = {}, action) {
    if (action.type === FETCH_METHOD) return action.fetchMethod;
    else return state;
}

/**
 * this generic reducer function processes all actions dependent on action type
 * @param state redux state
 * @param action current action
 * @param reducerActions all actions of calling reducer
 * @returns new state with mutation depending on action
 */
export function processReducer(
    state = {
        isFetching: false,
        action: {
            type: 'NONE',
        },
        result: [],
    },
    action,
    reducerActions,
) {
    switch (action.type) {
        case reducerActions.REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: undefined,
                action: {
                    type: 'REQUEST',
                    payload: undefined,
                },
            });
        case reducerActions.SUCCESS_FETCH:
            return Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.payload.receivedAt,
                result: action.payload.result,
                error: undefined,
                action: {
                    type: 'FETCH',
                    payload: action.payload.result,
                },
            });
        case reducerActions.FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.payload.receivedAt,
                error: action.payload.error,
                action: {
                    type: 'FAILURE',
                    payload: undefined,
                },
            });
        case reducerActions.SUCCESS_SAVE:
            return Object.assign({}, state, {
                isFetching: false,
                result: [...state.result, action.payload.result],
                lastUpdated: action.payload.receivedAt,
                error: undefined,
                action: {
                    type: 'SAVE',
                    payload: action.payload.result,
                },
            });
        case reducerActions.SUCCESS_UPDATE:
            if (action.payload.result) {
                return Object.assign({}, state, {
                    isFetching: false,
                    lastUpdated: action.payload.receivedAt,
                    updatedEntity: action.payload.result,
                    error: undefined,
                    action: {
                        type: 'UPDATE',
                        payload: action.payload.result,
                    },
                });
            }
            return Object.assign({}, state, {
                isFetching: false,
                error: undefined,
                lastUpdated: action.payload.receivedAt,
                action: {
                    type: 'UPDATE',
                    payload: undefined,
                },
            });
        case reducerActions.SUCCESS_DELETE: {
            let newResult;
            if (Array.isArray(action.payload.entityValue))
                newResult = _.without(state.result, ...action.payload.entityValue);
            else newResult = _.without(state.result, action.payload.entityValue);

            return Object.assign({}, state, {
                isFetching: false,
                result: newResult,
                error: undefined,
                lastUpdated: action.payload.receivedAt,
                action: {
                    type: 'DELETE',
                    payload: action.payload.entityValue,
                },
            });
        }
        default:
            return state;
    }
}

function createReducers() {
    const reducers = [];

    _.forEach(Entities, value => {
        reducers[value.reducer] = (state = {}, action) => {
            const actions = {
                REQUEST: `REQUEST_${value.action}`,
                SUCCESS_FETCH: `SUCCESS_FETCH_${value.action}`,
                SUCCESS_SAVE: `SUCCESS_SAVE_${value.action}`,
                SUCCESS_UPDATE: `SUCCESS_UPDATE_${value.action}`,
                SUCCESS_DELETE: `SUCCESS_DELETE_${value.action}`,
                FAILURE: `FAILURE_${value.action}`,
            };

            switch (action.type) {
                case actions.REQUEST:
                case actions.SUCCESS_FETCH:
                case actions.SUCCESS_SAVE:
                case actions.SUCCESS_UPDATE:
                case actions.SUCCESS_DELETE:
                case actions.FAILURE:
                    return Object.assign({}, state, {
                        [action.payload.reducerIndex]: processReducer(
                            state[action.payload.reducerIndex],
                            action,
                            actions,
                        ),
                    });
                default:
                    return state;
            }
        };
    });

    return reducers;
}

const rootReducer = combineReducers({
    form: formReducer,
    routing: routerReducer,
    selectedCompany,
    adminNews,
    selectedEvent,
    calendarDateRange: setCalendarDateRange,
    selectedSliderIndex,
    fetchMethodOnError,
    documents: documentsReducer,
    entities: entitiesReducer,
    notifications: notificationsReducer,
    formSubmit: formSubmitReducer,
    integration: operatingToolIntegrationReducer,
    ...createReducers(), // dynamic reducer creation from Entities
});

export default rootReducer;
