import * as Sentry from '@sentry/react';

if (Config.PROFILE !== 'development') {
    const sentryRelease = Config.OPERATINGTOOL_VERSION
        // replace first - with a plus (to treat as metadata instead of prerelease)
        .replace(/-(.+)$/, '+$1')
        // replace v with bus@
        .replace(/^v?(.+)$/, 'bus@$1');

    console.log('Init Sentry:', sentryRelease);

    // Sentry options
    // https://docs.sentry.io/platforms/javascript/guides/react/
    Sentry.init({
        dsn: Config.SENTRY_DSN,
        environment: Config.PROFILE,
        release: sentryRelease,
    });
}
