import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, Projection } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import InternalRatingForm from 'src/components/admin/companies/internal-rating-form';

class InternalRatingModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id } = this.props;

        this.props.fetchById(id, Entities.BUS_COMPANY, 0, Projection.FULL);
    }

    componentWillReceiveProps(nextProps) {
        const { busCompany } = nextProps;

        if (!this.state.startedFetching && busCompany.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { busCompany, t } = this.props;

        const finishFetching = this.state.startedFetching && !busCompany.isFetching;

        if (finishFetching) {
            if (busCompany.error) return <ErrorMessage object={busCompany} />;
            else {
                const initialValues = {
                    id: busCompany.content.id,
                    internalRating: busCompany.content.internalRating,
                };

                return (
                    <InternalRatingForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, patch } = this.props;

        patch(data, Entities.BUS_COMPANY, 0, Projection.FULL);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        busCompany: getUpdatedEntity(state, Entities.BUS_COMPANY),
    };
};

export default connect(mapStateToProps, { fetchById, patch }, null, { withRef: true })(InternalRatingModal);
