import { UserRole } from 'src/common/index';

/**
 * State of task in exchange
 * @type {{ACCEPTED: string, PENDING: string, DECLINED: string, CLOSED: string}}
 */
export const TaskExchangeState = {
    ACCEPTED: 'ACCEPTED',
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
    CLOSED: 'CLOSED',
};

/**
 * Defines all different Offer statuses
 * @type {{OPEN: string, ACCEPTED: string, DECLINED: string, DELETED: string}}
 */
export const OfferStatus = {
    OPEN: 'OPEN',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    DELETED: 'DELETED',
};

/**
 * Defines all different task types
 * @type {{MY_OPEN_TASK: string, MY_ASSIGNED_TASK: string, MY_OPEN_EXCHANGED_TASK_PENDING: string, MY_OPEN_EXCHANGED_TASK_ACCEPTED: string, MY_ASSIGNED_EXCHANGED_TASK: string, FOREIGN_OPEN_EXCHANGED_TASK_PENDING: string, FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED: string, FOREIGN_ASSIGNED_EXCHANGED_TASK: string, BOOKED_BY_CUSTOMER_OPEN: string, BOOKED_BY_CUSTOMER_ASSIGNED: string, OPEN_OFFER: string, DECLINED_OFFER: string, DELETED_OFFER: string, EXTERNAL_TASK: string}}
 */
export const TaskType = {
    // own open task (no bus assigned and not in task-exchange)
    MY_OPEN_TASK: 'MY_OPEN_TASK',

    // own assigned task (assigned to bus but not in task-exchange)
    MY_ASSIGNED_TASK: 'MY_ASSIGNED_TASK',

    // task released by own company but no other company accepted it (pending) -> task-exchange
    MY_OPEN_EXCHANGED_TASK_PENDING: 'MY_OPEN_EXCHANGED_TASK_PENDING',

    // task released by own company and already accepted by foreign company (accepted) -> task-exchange
    MY_OPEN_EXCHANGED_TASK_ACCEPTED: 'MY_OPEN_EXCHANGED_TASK_ACCEPTED',

    // task released by own company and already accepted by foreign company. foreign company already assigned a bus to this task (assigned) -> task-exchange
    MY_ASSIGNED_EXCHANGED_TASK: 'MY_ASSIGNED_EXCHANGED_TASK',

    // task released by foreign company but no company accepted it (pending) -> task-exchange
    FOREIGN_OPEN_EXCHANGED_TASK_PENDING: 'FOREIGN_OPEN_EXCHANGED_TASK_PENDING',

    // task released by foreign company and already accepted by own company (accepted) -> task-exchange
    FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED: 'FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED',

    // task released by foreign company and already accepted by own company. own company already assigned a bus to this task (assigned) -> task-exchange
    FOREIGN_ASSIGNED_EXCHANGED_TASK: 'FOREIGN_ASSIGNED_EXCHANGED_TASK',

    // task booked from frontend by extern customer and no bus assigned
    BOOKED_BY_CUSTOMER_OPEN: 'BOOKED_BY_CUSTOMER_OPEN',

    // task booked from frontend by extern customer and bus assigned
    BOOKED_BY_CUSTOMER_ASSIGNED: 'BOOKED_BY_CUSTOMER_ASSIGNED',

    // own open offer (a task that has an offer with state = open)
    OPEN_OFFER: 'OPEN_OFFER',

    DECLINED_OFFER: 'DECLINED_OFFER',

    DELETED_OFFER: 'DELETED_OFFER',

    EXTERNAL_TASK: 'EXTERNAL_TASK',
};

/**
 * Determines the event type (regardless of task type)
 * @type {{TASK: string, RELEASE: string, BLOCKAGE: string}}
 */
export const EventType = {
    TASK: 'TASK',
    RELEASE: 'RELEASE',
    BLOCKAGE: 'BLOCKAGE',
};

/**
 * Defines all available calendar resource types (left columns in operating tool calendar)
 * @type {{TASK_INTERN: string, TASK_EXTERN_OWN: string, TASK_EXTERN_FOREIGN: string, OFFER: string, VEHICLE_EXTERN: string}}
 */
export const CalendarResourceType = {
    TASK_INTERN: 'task_internal',
    TASK_EXTERN_OWN: 'task_external_own',
    TASK_EXTERN_FOREIGN: 'task_external_foreign',
    OFFER: 'offer',
    VEHICLE_EXTERN: 'vehicle_external',
    VEHICLE_INACTIVE: 'vehicle_inactive',
};

export const CalendarResourceGroup = {
    VEHICLES: 'vehicles',
    TASKS: 'tasks',
};

/**
 * Type of TaskExchange. Determines if the company who released the task
 * is only searching for a bus or a bus + driver
 * @type {{BUS: string, BOTH: string}}
 */
export const TaskExchangeType = {
    BUS: 'BUS',
    BOTH: 'BOTH',
};

/**
 * Determines the release mode of a bus
 * BLOCKED: customer cannot book this bus (if no release available)
 * RELEASED: customer can book this bus (if no blockage is defined)
 * @type {{BLOCKED: string, RELEASED: string}}
 */
export const ReleaseMode = {
    BLOCKED: 'BLOCKED',
    RELEASED: 'RELEASED',
};

// TODO: BUF-1135: translate
export const roleName = role => {
    if (!role) return '';
    if (role === UserRole.SUPER_ADMIN) return 'Super-Admin';
    if (role === UserRole.ADMIN) return 'Admin';
    if (role === UserRole.OPERATOR) return 'Unternehmer';
    if (role === UserRole.DISPATCHER) return 'Disponent';
    if (role === UserRole.DRIVER) return 'Fahrer';
    if (role === UserRole.CUSTOMER) return 'Kunde';
};

/**
 * Defines the color of cleared tasks in calendar view
 * @type {string}
 */
export const CLEARED_TASK_COLOR = '#871347';

/**
 * Defines the color of cancelled tasks in calendar view
 * @type {string}
 */
export const CANCELLED_TASK_COLOR = '#aaaaaa';

/**
 * Define the button colors
 * @type {{SUCCESS: string, DANGER: string, NOTIFY: string}}
 */
export const ButtonColors = {
    SUCCESS: '#5cb85c',
    DANGER: '#c9302c',
    NOTIFY: '#337ab7',
};

/**
 * Holds the item types for HTML drag'n'drop
 * @type {{DRIVER: string}}
 */
export const ItemTypes = {
    DRIVER: 'DRIVER',
};

/**
 * Explicitly define and update operation for backend api calls
 * @type {{UPDATE: string}}
 */
export const OperationType = {
    UPDATE: 'UPDATE',
};

export const Gender = {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    OTHER: 'OTHER',
};

export const genderName = gender => {
    if (!gender) return '';
    if (gender === Gender.FEMALE) return 'Frau';
    if (gender === Gender.MALE) return 'Herr';
    if (gender === Gender.OTHER) return 'Herr/Frau';
};

export const CouponType = {
    ABSOLUTE: 'ABSOLUTE',
    RELATIVE: 'RELATIVE',
};

export const CustomerGroupType = {
    PRIVATE: 'PRIVATE',
    BUSINESS: 'BUSINESS',
    SCHOOL: 'SCHOOL',
    ASSOCIATION: 'ASSOCIATION',
    RESELLER: 'RESELLER',
};

export const IntegrationFeeType = {
    PROVISION: 'PROVISION',
    PRICE_PER_REQUEST: 'PRICE_PER_REQUEST',
};
