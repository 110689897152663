import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import DriverSelectionFields from 'src/components/taskadministration/senddriverdetails/DriverSelectionFields';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';
import { DriverSelectionType } from 'src/components/taskadministration/senddriverdetails/DriverSelectionType';
import { renderRichTextEditor } from 'src/components/misc/redux-form-helpers';

class SendDriverDetailsToCustomerForm extends Component {
    constructor(props) {
        super(props);

        const { drivers, firstDriver, secondDriver } = props;
        const { driver1MenuItems, driver2MenuItems } = this.getDriverMenuItems(drivers, firstDriver, secondDriver);

        this.state = {
            driver1MenuItems,
            driver2MenuItems,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { drivers, firstDriver, secondDriver, t } = nextProps;

        if (
            drivers &&
            (!_.isEqual(drivers, this.props.drivers) ||
                firstDriver !== this.props.firstDriver ||
                secondDriver !== this.props.secondDriver)
        ) {
            const { driver1MenuItems, driver2MenuItems } = this.getDriverMenuItems(drivers, firstDriver, secondDriver);
            this.setState({ driver1MenuItems, driver2MenuItems });

            if (firstDriver !== this.props.firstDriver)
                nextProps.change('firstDriverPhone', this.getDriverPhone(drivers, firstDriver));

            if (secondDriver !== this.props.secondDriver)
                nextProps.change('secondDriverPhone', this.getDriverPhone(drivers, secondDriver));
        }
    }

    render() {
        const { handleSubmit, firstDriverType, secondDriverType, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <legend className="legend legend-event-form ">1. {t('administration.driver')}</legend>
                <DriverSelectionFields
                    t={t}
                    isFirstDriver={true}
                    driverType={firstDriverType}
                    driverMenuItems={this.state.driver1MenuItems}
                    change={this.props.change}
                />

                <legend className="legend legend-event-form ">2. {t('administration.driver')}</legend>
                <DriverSelectionFields
                    t={t}
                    isFirstDriver={false}
                    driverType={secondDriverType}
                    driverMenuItems={this.state.driver2MenuItems}
                    change={this.props.change}
                />

                <legend className="legend legend-event-form ">{t('booked_tasks.information')}</legend>
                <div className="row">
                    <div className="col-md-12">
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <label>{t('task_details.additional_information')}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="notes"
                                    placeholder={t('task_details.additional_information')}
                                    component={renderRichTextEditor}
                                    t={t}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    getDriverMenuItems = (drivers, firstDriver, secondDriver) => {
        const driver1MenuItems = [<MenuItem key={-1} value="" primaryText="-" />];
        const driver2MenuItems = [<MenuItem key={-1} value="" primaryText="-" />];

        drivers.forEach((driver, index) => {
            if (firstDriver !== driver['_links']['self']['href'])
                driver2MenuItems.push(
                    <MenuItem
                        key={driver.id}
                        value={driver['_links']['self']['href']}
                        primaryText={driver.contactData.firstName}
                    />,
                );

            if (secondDriver !== driver['_links']['self']['href'])
                driver1MenuItems.push(
                    <MenuItem
                        key={driver.id}
                        value={driver['_links']['self']['href']}
                        primaryText={driver.contactData.firstName}
                    />,
                );
        });

        return {
            driver1MenuItems,
            driver2MenuItems,
        };
    };

    getDriverPhone = (drivers, selectedDriver) => {
        const driver = drivers.find(d => d._links.self.href === selectedDriver);

        if (!driver) return undefined;

        return driver.contactData.phone;
    };
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (values.firstDriverType === DriverSelectionType.NEW) {
        if (!values.firstDriverName) errors.firstDriverName = t('error_missing.fill_in_name');
        if (!values.firstDriverPhone) errors.firstDriverPhone = t('error_missing.fill_in_tel');
    }

    if (values.secondDriverType === DriverSelectionType.NEW) {
        if (!values.secondDriverName) errors.secondDriverName = t('error_missing.fill_in_name');
        if (!values.secondDriverPhone) errors.secondDriverPhone = t('error_missing.fill_in_tel');
    }

    if (values.firstDriverType === DriverSelectionType.EXISTING) {
        if (!values.firstDriver) errors.firstDriver = t('error_missing.fill_in_driver');
        if (!values.firstDriverPhone) errors.firstDriverPhone = t('error_missing.fill_in_tel');
    }

    if (values.secondDriverType === DriverSelectionType.EXISTING) {
        if (!values.secondDriver) errors.secondDriver = t('error_missing.fill_in_driver');
        if (!values.secondDriverPhone) errors.secondDriverPhone = t('error_missing.fill_in_tel');
    }

    return errors;
};

SendDriverDetailsToCustomerForm = reduxForm({
    form: 'sendDriverDetailsToCustomerForm',
    validate,
})(SendDriverDetailsToCustomerForm);

const selector = formValueSelector('sendDriverDetailsToCustomerForm');

const mapStateToProps = (state, ownProps) => {
    const firstDriver = selector(state, 'firstDriver');
    const secondDriver = selector(state, 'secondDriver');
    const firstDriverType = selector(state, 'firstDriverType');
    const secondDriverType = selector(state, 'secondDriverType');

    return {
        firstDriver,
        firstDriverType,
        secondDriver,
        secondDriverType,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: (field, value) => {
            dispatch(change('sendDriverDetailsToCustomerForm', field, value));
        },
    };
};

SendDriverDetailsToCustomerForm = connect(mapStateToProps, null, null, { withRef: true })(
    SendDriverDetailsToCustomerForm,
);

export default SendDriverDetailsToCustomerForm;
