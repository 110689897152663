import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Entities } from 'src/common/index';
import MenuItem from 'material-ui/MenuItem';
import ErrorMessage from 'src/components/misc/error-message';
import SelectField from 'material-ui/SelectField';
import ModuleTableContainer from 'src/components/superadmin/modules/modulel-table-container';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

export function sortByCompanyName(items) {
    return _.sortBy(items, company => _.deburr(company.companyName.toLowerCase()));
}

class ModuleAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
            selectedCompany: undefined,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BUS_COMPANY, 0, 'foreign');
    }

    componentWillReceiveProps(nextProps) {
        const { busCompanies } = nextProps;

        if (busCompanies.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        if (this.props.busCompanies.isFetching && !busCompanies.isFetching && busCompanies.items.length > 0)
            this.setState({
                selectedCompany: sortByCompanyName(busCompanies.items)[0].id,
            });
    }

    render() {
        const { busCompanies, t } = this.props;
        const finishFetching = this.state.startedFetching && !busCompanies.isFetching;

        return (
            <div>
                <legend className="legend">{t('modules')}</legend>

                {(() => {
                    if (!finishFetching) return <div />;
                    if (busCompanies.error) return <ErrorMessage object={busCompanies} />;
                    else if (busCompanies.items.length === 0 || this.state.selectedCompany === undefined)
                        return <span>{t('companies.no_company_found')}</span>;
                    else
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-md-4 col-md-offset-8">
                                        <SelectField
                                            value={this.state.selectedCompany}
                                            onChange={(event, index, value) => {
                                                this.setState({
                                                    selectedCompany: value,
                                                });
                                            }}
                                            floatingLabelText={t('common_phrases.company')}
                                            fullWidth={true}
                                            hintText={t('error_missing.select_company')}
                                            floatingLabelFixed={true}>
                                            {this.getCompanyMenuItems()}
                                        </SelectField>
                                    </div>
                                </div>

                                <ModuleTableContainer selectedCompany={this.state.selectedCompany} />
                            </div>
                        );
                })()}
            </div>
        );
    }

    getCompanyMenuItems() {
        return sortByCompanyName(this.props.busCompanies.items).map(busCompany => {
            return <MenuItem key={busCompany.id} value={busCompany.id} primaryText={busCompany.companyName} />;
        });
    }
}

export default withTranslation()(ModuleAdministration);
