import React, { Component } from 'react';
import { connect } from 'react-redux';
import BlockageDetails from 'src/components/trips/calendar/details/BlockageDetails';
import { TripEventType } from 'src/selectors/trip-calendar-events/trip-calendar-events';
import { deleteById, patch } from 'src/common/index';
import ContingentDetails from 'src/components/trips/calendar/details/ContingentDetails';
import AvailabilityDetails from 'src/components/trips/calendar/details/AvailabilityDetails';
import BookingDetails from 'src/components/trips/calendar/details/BookingDetails';

class TripDetails extends Component {
    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const event = nextProps.event;

        if (event) {
            $('.fc-event').removeClass('selected-event');
            $(`.fc-event#event${event.id}`).addClass('selected-event');

            $('.fc-list-item').removeClass('selected-list-item');
            $(`.fc-list-item#event${event.id}`).addClass('selected-list-item');
        }
    }

    render() {
        const { event } = this.props;

        if (!event) return null;

        return (
            <div className="details">
                <div className="panel panel-default">
                    <div className="panel-body">{this.renderTableByEventType()}</div>
                </div>
            </div>
        );
    }

    renderTableByEventType() {
        const { event, selectedCompany, t } = this.props;

        if (!event) return null;

        switch (event.type) {
            case TripEventType.BLOCKAGE:
                return (
                    <BlockageDetails
                        event={event}
                        selectedCompany={selectedCompany}
                        openModal={this.props.openModal}
                        deleteById={this.props.deleteById}
                        t={t}
                    />
                );
            case TripEventType.BOOKING:
                return (
                    <BookingDetails
                        event={event}
                        selectedCompany={selectedCompany}
                        openModal={this.props.openModal}
                        patch={this.props.patch}
                        t={t}
                    />
                );
            case TripEventType.CONTINGENT:
                return (
                    <ContingentDetails
                        event={event}
                        selectedCompany={selectedCompany}
                        openModal={this.props.openModal}
                        t={t}
                    />
                );
            case TripEventType.AVAILABILITY:
                return <AvailabilityDetails event={event} t={t} />;
            default:
                return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        event: state.selectedEvent,
    };
};

export default connect(mapStateToProps, { deleteById, patch })(TripDetails);
