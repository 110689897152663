import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { asyncCheckDuplicate, renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL, TripCategoryGroup } from 'src/common/index';
import { MenuItem } from 'material-ui';
import _ from 'lodash';
import { isBlank } from 'src/components/misc/validations';

class TripCategoryForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            component={renderInput}
                            props={{
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="group"
                            label={`${t('group')} *`}
                            component={renderSelectField}
                            props={{
                                disabled: true,
                            }}>
                            {_.values(TripCategoryGroup).map(group => (
                                <MenuItem key={group} value={group} primaryText={group} />
                            ))}
                        </Field>
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.group) errors.group = t('error_missing.fill_in_group');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    const { t } = props;
    return asyncCheckDuplicate(
        t,
        `${TOMCAT_URL}api/${Entities.TRIP_CATEGORY.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

TripCategoryForm = reduxForm({
    form: 'TripCategoryForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(TripCategoryForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, tripCategory } = ownProps;

    let initialValues = {};

    if (!isCreate && tripCategory)
        initialValues = {
            id: tripCategory.id,
            name: tripCategory.name,
            visible: tripCategory.visible,
            group: tripCategory.group,
        };

    return {
        initialValues: initialValues,
    };
};

TripCategoryForm = connect(mapStateToProps, null, null, { withRef: true })(TripCategoryForm);

export default TripCategoryForm;
