import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TextField from 'material-ui/TextField';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank, isEmailInvalid } from 'src/components/misc/validations';

class AssociationEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, association, currentActiveBusPoints, isCreate, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    {!isCreate && (
                        <div className="col-md-6">
                            <TextField
                                type="text"
                                value={(currentActiveBusPoints || 0) + association.nonActiveBusPoints}
                                fullWidth={true}
                                disabled={true}
                                title={t('schools.points_availability')}
                                floatingLabelText={t('schools.sum_points')}
                            />
                        </div>
                    )}
                    <div className="col-md-6">
                        <Field
                            name="busPoints"
                            label={`${t('schools.available_points')} *`}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="associationName"
                            label={`${t('associations.association_name')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="registrationNumber"
                            label={`${t('associations.registration_number')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="contactData.email" label={`${t('contact_data.email')}`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="contactData.phone"
                            label={`${t('contact_data.tel_number')}`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.street"
                            label={`${t('contact_data.street')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="contactData.postCode"
                            label={`${t('contact_data.post_code')}`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="contactData.city" label={`${t('contact_data.city')}`} component={renderInput} />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        contactData: {},
    };

    if (values.busPoints == null) errors.busPoints = t('schools.fill_in_bus_points');
    else if (values.busPoints < 0) errors.busPoints = t('schools.error_negative_points');

    if (isBlank(values.associationName)) errors.associationName = t('associations.fill_in_name');
    if (isBlank(values.registrationNumber)) errors.registrationNumber = t('associations.fill_in_registration_number');
    if (values.contactData) {
        if (isEmailInvalid(values.contactData.email) && !isBlank(values.contactData.email))
            errors.contactData.email = t('error_hint.error_invalid_email');
    }

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.ASSOCIATION.repository}/search/findByAssociationName?associationName=${encodeURIComponent(
            values.associationName,
        )}`,
        values.id,
        'associationName',
    );
};

AssociationEditForm = reduxForm({
    form: 'associationEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['associationName'],
})(AssociationEditForm);

const selector = formValueSelector('associationEditForm');

const mapStateToProps = (state, ownProps) => {
    const { association, isCreate } = ownProps;

    const currentActiveBusPoints = Number(selector(state, 'busPoints'));

    let initialValues = {};

    if (!isCreate && association) {
        initialValues = {
            id: association.id,
            busPoints: association.busPoints,
            associationName: association.associationName,
            registrationNumber: association.registrationNumber,
            contactData: {
                email: association.contactData.email,
                phone: association.contactData.phone,
                street: association.contactData.street,
                postCode: association.contactData.postCode,
                city: association.contactData.city,
            },
        };
    }

    return {
        initialValues,
        currentActiveBusPoints,
    };
};

AssociationEditForm = connect(mapStateToProps, null, null, { withRef: true })(AssociationEditForm);

export default AssociationEditForm;
