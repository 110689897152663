import React, { Component } from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Entities } from 'src/common/index';

import composeModalActions from 'src/utils/modal-action-wrapper';
import { get21x9Image } from 'src/utils/helpers';
import { dateTimeFormatter, expiredFormatter, priceFormatter, validFormatter } from 'src/utils/formatters';
import { calculateAvailableTickets } from 'src/components/event-tickets/calculations';
import { renderEventInformation, renderSelectedTicketCollection } from 'src/components/event-tickets/rendering';

import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { withTranslation } from 'react-i18next';

class TicketCollectionAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({
            onRowClick: this.onSelectTicketCollection,
        });
        this.state = {
            startedFetching: false,
            selectedTicketCollectionIndex: -1,
        };

        this.eventId = props.params.id;
    }

    onSelectTicketCollection(ticketCollection) {
        this.tableHelper.setSelectedRow(ticketCollection.id);
        this.setState({
            selectedTicketCollectionIndex: this.props.ticketCollections.items.findIndex(
                tc => tc.id === ticketCollection.id,
            ),
        });
    }

    componentWillMount() {
        const { company } = this.props;

        const ticketProjection = company.entity.partner ? 'partner' : 'no-partner';

        this.props.fetchTicketCollectionsByEventId(this.eventId, ticketProjection);
        this.props.fetchById(this.eventId, Entities.TICKET_EVENT);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { ticketCollections } = nextProps;

        if (ticketCollections.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(ticketCollections.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.ticketCollections,
                this.props.ticketCollections,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { ticketCollections, company, event, t } = this.props;
        const finishFetching = this.state.startedFetching && !ticketCollections.isFetching && !event.isFetching;

        if (!finishFetching) return <span>Fetching</span>;

        if (ticketCollections.error) return <ErrorMessage object={ticketCollections} />;

        const tableData = [];
        let totalTickets = 0,
            soldTickets = 0,
            expiredTickets = 0;
        if (ticketCollections.items.length > 0) {
            ticketCollections.items.map(ticketCollection => {
                totalTickets += ticketCollection.quantity;
                soldTickets += ticketCollection.countSold;

                let expiredTicketsOfSingleCollection = 0;
                if (!ticketCollection.valid) {
                    expiredTicketsOfSingleCollection = ticketCollection.quantity - ticketCollection.countSold;
                    expiredTickets += expiredTicketsOfSingleCollection;
                }

                tableData.push({
                    id: ticketCollection.id,
                    category: ticketCollection.category,
                    validUntil: ticketCollection.validUntil,
                    valid: ticketCollection.valid,
                    sellPrice: ticketCollection.sellPrice,
                    internSellPrice: ticketCollection.internSellPrice,
                    description: ticketCollection.description,
                    conditions: ticketCollection.conditions,
                    availableTickets: calculateAvailableTickets(
                        ticketCollection.quantity,
                        ticketCollection.countSold,
                        expiredTicketsOfSingleCollection,
                    ),
                });
            });
        }

        return (
            <div id="ticketCollectionAdministration">
                <legend className="legend">
                    {event.content.name}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.props.push('/event-tickets/events');
                        }}>
                        <span className="glyphicon glyphicon-circle-arrow-left" />
                        &nbsp;{t('common_phrases.back')}
                    </button>
                </legend>

                {this.renderEventSummary(totalTickets, soldTickets, expiredTickets)}

                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={tableData}
                    exportCSV={true}
                    striped={true}
                    hover={true}
                    condensed={true}
                    pagination={true}
                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                    searchPlaceholder={t('common_phrases.search')}
                    search={true}>
                    <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true}>
                        {t('common_phrases.id')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="category" dataSort={true}>
                        {t('common_phrases.category')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="validUntil"
                        dataFormat={dateTimeFormatter}
                        dataSort={true}>
                        {t('ticket.valid_until')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="valid"
                        width="80"
                        dataAlign="center"
                        dataFormat={cell => validFormatter(t, cell)}
                        dataSort={true}>
                        {t('ticket.valid')}
                    </TableHeaderColumn>
                    {company.entity.partner ? (
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="internSellPrice"
                            width="150"
                            dataAlign="right"
                            dataFormat={priceFormatter}
                            csvFormat={priceFormatter}
                            dataSort={true}>
                            {t('ticket.price')}
                        </TableHeaderColumn>
                    ) : (
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="sellPrice"
                            width="150"
                            dataAlign="right"
                            dataFormat={priceFormatter}
                            csvFormat={priceFormatter}
                            dataSort={true}>
                            {t('ticket.price')}
                        </TableHeaderColumn>
                    )}

                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="availableTickets"
                        dataAlign="right"
                        width="200"
                        dataSort={true}>
                        {t('ticket.available_tickets')}
                    </TableHeaderColumn>
                </BootstrapTable>

                {this.state.selectedTicketCollectionIndex !== -1 &&
                    renderSelectedTicketCollection(
                        t,
                        ticketCollections.items[this.state.selectedTicketCollectionIndex],
                    )}
            </div>
        );
    }

    renderEventSummary(totalTickets, soldTickets, expiredTickets) {
        const { event, t } = this.props;

        return (
            <div id="eventTicketSummary" className="row">
                <div className="col-md-4">
                    <img alt={event.name} src={get21x9Image(event.content.logo)} width="100%" />
                </div>
                <div className="col-md-3">
                    {/*TODO: BUF-1135: translate*/}
                    {renderEventInformation('Status', expiredFormatter(t, event.content.expired))}
                    {renderEventInformation('Start', dateTimeFormatter(event.content.beginDateTime))}
                    {renderEventInformation('Standort', event.content.location.address)}
                    <hr />
                    {renderEventInformation(
                        'Tickets',
                        <span>
                            {totalTickets - soldTickets - expiredTickets} {t('ticket.pieces')}
                        </span>,
                    )}

                    <div className="row">
                        <h5>
                            <div className="col-md-12">
                                <a
                                    href={t('ticket.mail_link', {
                                        eventName: event.content.name,
                                        time: moment(event.content.beginDateTime).format('DD.MM.YYYY HH:mm'),
                                    })}>
                                    {t('ticket.inquiry')}
                                </a>
                            </div>
                        </h5>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-md-11">
                            <h5>
                                <strong>{t('common_phrases.description')}:</strong>
                            </h5>
                            {event.content.description ? event.content.description : t('ticket.no_description')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(TicketCollectionAdministration));
