import React, { Component } from 'react';

import composeModalActions from 'src/utils/modal-action-wrapper';
import TripCalendar from 'src/components/trips/calendar/TripCalendar';
import TripDetails from 'src/components/trips/calendar/details/TripDetails';
import { withTranslation } from 'react-i18next';

class TripOperatingTool extends Component {
    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        const { t, i18n } = this.props;

        return (
            <div className="operatingwrapper">
                {/* Calendar */}
                <div id="tripCalendarcontainer">
                    <TripCalendar i18n={i18n} t={t} openModal={this.props.openModal} modal={this.props.modal} />
                </div>

                {/* Details */}
                <div className="tripdetailscontainer-calendar">
                    <TripDetails t={t} openModal={this.props.openModal} modal={this.props.modal} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(TripOperatingTool));
