import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities } from 'src/common/index';
import AdminBrandAdministration from 'src/components/admin/brands/admin-brand-administration';

const mapStateToProps = state => {
    return {
        brands: getEntities(state, Entities.BUS_BRAND),
    };
};

const AdminBrandAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    deleteById,
})(AdminBrandAdministration);

export default AdminBrandAdministrationContainer;
