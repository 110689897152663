import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import Help from 'src/components/help/help';

const mapStateToProps = state => {
    return {
        helps: getEntities(state, Entities.HELP),
        helpCategories: state.entities[Entities.HELP_CATEGORY.repository],
    };
};

const HelpContainer = connect(mapStateToProps, { fetchAll })(Help);

export default HelpContainer;
