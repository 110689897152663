import React, { Component } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, getEntityLinkById, Projection, TOMCAT_URL } from 'src/common/index';
import { Tab, Tabs } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { DayOfWeek } from 'src/utils/daysOfWeek';
import _ from 'lodash';
import { isBlank, notValidTime, validTime } from 'src/components/misc/validations';
import moment from 'moment';
import DatePicker from 'src/components/misc/DatePicker';

class SeasonForm extends Component {
    render() {
        const { handleSubmit, selectedSliderIndex, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                    <Tab label={t('calculation.opening_hours_pax')} value={1} />
                </Tabs>
                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    {/* General Tab */}
                    <div className="view">
                        <div className="row">
                            <div className="col-md-6">
                                <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Field name="periodFrom" label={`${t('ticket.beginning')} *`} component={DatePicker} />
                            </div>
                            <div className="col-md-6">
                                <Field name="periodTill" label={`${t('calculation.end')} *`} component={DatePicker} />
                            </div>
                        </div>
                    </div>

                    {/* Opening Hours */}
                    <div className="view">
                        {Object.keys(DayOfWeek).map(day => this.renderOpeningHours(day, DayOfWeek[day]))}
                    </div>
                </SwipeableViews>
            </form>
        );
    }

    renderOpeningHours = (dayKey, dayValue) => {
        const { t } = this.props;
        return (
            <React.Fragment key={dayKey}>
                <legend className="voffset">{dayValue}</legend>
                <div className="row">
                    <div className="col-md-4">
                        <Field
                            name={`openingHours.${dayKey}.first.from`}
                            label={t('booked_tasks.from')}
                            hintText="HH:mm"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-4">
                        <Field
                            name={`openingHours.${dayKey}.first.to`}
                            label={`${t('booked_tasks.to')} *`}
                            hintText="HH:mm"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            name={`openingHours.${dayKey}.first.pax`}
                            label={t('task_details.pax')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-1 voffset40">
                        <button
                            type="button"
                            className="btn transparent"
                            title={t('task_details.remove')}
                            onClick={() => this.clearOpeningHour(dayKey, true)}>
                            <span className="glyphicon glyphicon-remove text-danger" />
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Field
                            name={`openingHours.${dayKey}.second.from`}
                            label={t('booked_tasks.from')}
                            hintText="HH:mm"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-4">
                        <Field
                            name={`openingHours.${dayKey}.second.to`}
                            label={t('booked_tasks.to')}
                            hintText="HH:mm"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            name={`openingHours.${dayKey}.second.pax`}
                            label={t('task_details.pax')}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-1 voffset40">
                        <button
                            type="button"
                            className="btn transparent"
                            title={t('task_details.remove')}
                            onClick={() => this.clearOpeningHour(dayKey, false)}>
                            <span className="glyphicon glyphicon-remove text-danger" />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    clearOpeningHour = (dayKey, isFirst) => {
        const field = `openingHours.${dayKey}.${isFirst ? 'first' : 'second'}`;
        this.props.change(`${field}.from`, null);
        this.props.change(`${field}.to`, null);
        this.props.change(`${field}.pax`, null);
    };
}

const validate = (values, props) => {
    const { t } = props;
    // populate empty error object for nested opening hours
    const errors = {
        openingHours: _.zipObject(
            Object.keys(DayOfWeek),
            Array(Object.keys(DayOfWeek).length).fill({
                first: {},
                second: {},
            }),
        ),
    };

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.periodFrom) errors.periodFrom = t('calculation.fill_in_season_start');
    else if (!values.periodTill) errors.periodTill = t('calculation.fill_in_season_end');
    else if (moment(values.periodFrom, 'DD.MM.YYYY').isAfter(moment(values.periodTill, 'DD.MM.YYYY'))) {
        errors.periodFrom = t('calculation.error_start_after_end');
        errors.periodTill = t('calculation.error_end_before_start');
    }

    // validate opening hours
    Object.keys(DayOfWeek).forEach(day => {
        if (values.openingHours[day]) errors.openingHours[day] = validateDailyOpeningHours(t, values.openingHours[day]);
    });

    return errors;
};

const validateDailyOpeningHours = (t, dailyOpeningHours) => {
    const dailyOpeningHourErrors = {
        first: validateOpeningHours(t, dailyOpeningHours.first),
        second: validateOpeningHours(t, dailyOpeningHours.second),
    };

    // check overlapping of first and second opening hours
    if (
        validTime(dailyOpeningHours.first.to) &&
        validTime(dailyOpeningHours.second.from) &&
        moment(dailyOpeningHours.first.to, 'HH:mm').isSameOrAfter(moment(dailyOpeningHours.second.from, 'HH:mm'))
    ) {
        dailyOpeningHourErrors.first.to = t('calculation.error_end_before_second');
        dailyOpeningHourErrors.second.from = t('calculation.error_start_after_first');
    }

    return dailyOpeningHourErrors;
};

const validateOpeningHours = (t, openingHours) => {
    const openingHourErrors = {};

    // validate liability of fields
    if (
        !(
            (!openingHours.from && !openingHours.to && !openingHours.pax) ||
            (openingHours.from && openingHours.to && openingHours.pax)
        )
    ) {
        if (!openingHours.from) openingHourErrors.from = t('error_missing.fill_in');
        if (!openingHours.to) openingHourErrors.to = t('error_missing.fill_in');
        if (!openingHours.pax) openingHourErrors.pax = t('error_missing.fill_in');
    }

    // validate formats
    if (notValidTime(openingHours.from)) openingHourErrors.from = `${t('error_hint.error_invalid_format')} (HH:mm)`;
    if (notValidTime(openingHours.to)) openingHourErrors.to = `${t('error_hint.error_invalid_format')} (HH:mm)`;
    if (openingHours.pax && openingHours.pax < 0) openingHourErrors.pax = t('error_hint.error_negative');

    // validate time ranges
    if (
        validTime(openingHours.from) &&
        validTime(openingHours.to) &&
        moment(openingHours.from, 'HH:mm').isSameOrAfter(moment(openingHours.to, 'HH:mm'))
    ) {
        openingHourErrors.from = t('calculation.error_hint_from_before_to');
        openingHourErrors.to = t('calculation.error_hint_from_before_to');
    }

    return openingHourErrors;
};

const asyncValidate = (values, dispatch, props, blurredField) => {
    const { t } = props;
    if (blurredField === 'name')
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.SEASON.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
                values.name,
            )}&companyId=${props.companyId}&projection=${Projection.DEFAULT}`,
            values.id,
            'name',
        );

    if ((blurredField === 'periodFrom' || blurredField === 'periodTill') && values.periodFrom && values.periodTill)
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.SEASON.repository}/checkOverlappingPeriod?periodFrom=${moment(
                values.periodFrom,
                'DD.MM.YYYY',
            ).format('YYYY-MM-DD')}&periodTill=${moment(values.periodTill, 'DD.MM.YYYY').format(
                'YYYY-MM-DD',
            )}&companyId=${props.companyId}${values.id ? `&id=${values.id}` : ''}&projection=${Projection.DEFAULT}`,
            values.id,
            'periodFrom',
            t('calculation.already_existing_season'),
        );

    if (blurredField === undefined)
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.SEASON.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
                values.name,
            )}&companyId=${props.companyId}&projection=${Projection.DEFAULT}`,
            values.id,
            'name',
        ).then(() =>
            asyncCheckDuplicate(
                t,
                `${TOMCAT_URL}api/${Entities.SEASON.repository}/checkOverlappingPeriod?periodFrom=${moment(
                    values.periodFrom,
                    'DD.MM.YYYY',
                ).format('YYYY-MM-DD')}&periodTill=${moment(values.periodTill, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD',
                )}&companyId=${props.companyId}${values.id ? `&id=${values.id}` : ''}&projection=${Projection.DEFAULT}`,
                values.id,
                'periodFrom',
                t('calculation.already_existing_season'),
            ),
        );

    return Promise.resolve();
};

const onSubmitFail = (errors, dispatch) => {
    // TODO: jump to tab with error
    //
    // if (errors.paxPrices)
    //     dispatch(setSliderIndex(1));
    // else {
    //     dispatch(setSliderIndex(0));
    // }
};

const onSubmit = (data, submit) => {
    // transform opening hours for backend
    Object.keys(data.openingHours).forEach(day => {
        // remove opening hours first property if contained values are null
        // otherwise make sure the format is HH:mm and not H:mm (moment is smart enough to parse 8:00 with HH:mm, but Jackson isn't)
        if (data.openingHours[day].first) {
            const first = data.openingHours[day].first;
            if (!first.from || !first.to || !first.pax) data.openingHours[day].first = null;
            else {
                data.openingHours[day].first.from = moment(data.openingHours[day].first.from, 'HH:mm').format('HH:mm');
                data.openingHours[day].first.to = moment(data.openingHours[day].first.to, 'HH:mm').format('HH:mm');
            }
        }

        // remove opening hours second property if contained values are null
        // otherwise make sure the format is HH:mm and not H:mm (moment is smart enough to parse 8:00 with HH:mm, but Jackson isn't)
        if (data.openingHours[day].second) {
            const second = data.openingHours[day].second;
            if (!second.from || !second.to || !second.pax) data.openingHours[day].second = null;
            else {
                data.openingHours[day].second.from = moment(data.openingHours[day].second.from, 'HH:mm').format(
                    'HH:mm',
                );
                data.openingHours[day].second.to = moment(data.openingHours[day].second.to, 'HH:mm').format('HH:mm');
            }
        }

        // remove whole day if first and second opening hours are empty
        if (data.openingHours[day].first == null && data.openingHours[day].second == null)
            data.openingHours[day] = null;
    });

    // transform date for backend
    data.periodFrom = moment(data.periodFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
    data.periodTill = moment(data.periodTill, 'DD.MM.YYYY').format('YYYY-MM-DD');

    submit(data);
};

SeasonForm = reduxForm({
    form: 'SeasonForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['periodFrom', 'periodTill', 'name'],
})(SeasonForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, season, companyId } = ownProps;

    const companyLink = getEntityLinkById(Entities.TRIP_COMPANY, companyId);

    let initialValues;

    if (!isCreate && season) {
        // populate empty weekly opening hours for form displaying
        Object.keys(DayOfWeek).forEach(day => {
            // if day is not available populate it empty
            if (!season.openingHours[day]) {
                season.openingHours[day] = {
                    first: null,
                    second: null,
                };
            }

            // if first opening hours of current day is not available, populate it
            if (season.openingHours[day].first == null)
                season.openingHours[day].first = { from: null, to: null, pax: null };

            // if second opening hours of current day is not available, populate it
            if (season.openingHours[day].second == null)
                season.openingHours[day].second = { from: null, to: null, pax: null };

            if (season.openingHours[day].first) {
                if (season.openingHours[day].first.from)
                    season.openingHours[day].first.from = moment(
                        season.openingHours[day].first.from,
                        'HH:mm:ss',
                    ).format('HH:mm');
                if (season.openingHours[day].first.to)
                    season.openingHours[day].first.to = moment(season.openingHours[day].first.to, 'HH:mm:ss').format(
                        'HH:mm',
                    );
            }

            if (season.openingHours[day].second) {
                if (season.openingHours[day].second.from)
                    season.openingHours[day].second.from = moment(
                        season.openingHours[day].second.from,
                        'HH:mm:ss',
                    ).format('HH:mm');
                if (season.openingHours[day].second.to)
                    season.openingHours[day].second.to = moment(season.openingHours[day].second.to, 'HH:mm:ss').format(
                        'HH:mm',
                    );
            }
        });

        initialValues = {
            id: season.id,
            name: season.name,
            periodFrom: moment(season.periodFrom).format('DD.MM.YYYY'),
            periodTill: moment(season.periodTill).format('DD.MM.YYYY'),
            openingHours: season.openingHours,
            company: companyLink,
        };
    } else
        initialValues = {
            company: companyLink,
            periodFrom: moment().format('DD.MM.YYYY'),
            periodTill: moment().format('DD.MM.YYYY'),

            // populate empty opening hours
            openingHours: _.zipObject(
                Object.keys(DayOfWeek),
                Array(Object.keys(DayOfWeek).length).fill({
                    first: { from: null, to: null, pax: null },
                    second: { from: null, to: null, pax: null },
                }),
            ),
        };

    return {
        onSubmit: data => onSubmit(data, ownProps.onSubmit),
        initialValues: initialValues,
    };
};

SeasonForm = connect(mapStateToProps, { change }, null, { withRef: true })(SeasonForm);

export default SeasonForm;
