import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminReviewCategoryAdministration from 'src/components/admin/reviewcategories/admin-review-categories-administration';

const mapStateToProps = state => {
    return {
        reviewCategories: getEntities(state, Entities.REVIEW_CATEGORY),
    };
};

const AdminReviewCategoryAdministrationContainer = connect(mapStateToProps, { fetchAll, patch })(
    AdminReviewCategoryAdministration,
);

export default AdminReviewCategoryAdministrationContainer;
