import countriesDE from './countries/countries_de.json';
import countriesEN from './countries/countries_en.json';
import countriesFR from './countries/countries_fr.json';
import countriesIT from './countries/countries_it.json';
import countriesNL from './countries/countries_nl.json';
import countriesSE from './countries/countries_se.json';
import countriesSK from './countries/countries_sk.json';
import countriesPL from './countries/countries_pl.json';
import countriesCZ from './countries/countries_cz.json';

export const countries = {
    de: countriesDE,
    en: countriesEN,
    fr: countriesFR,
    it: countriesIT,
    nl: countriesNL,
    se: countriesSE,
    sk: countriesSK,
    pl: countriesPL,
    cz: countriesCZ,
};

/**
 * Countries to show on top in dropdowns, in reverse order ('AT' is on top).
 */
export const countriesOnTop = ['SE', 'LU', 'NL', 'CH', 'DE', 'AT'];

export * from './utils';
export * from './Entities';
export * from './common-constants';
export * from './abstract-selector';
export * from './async-request';
export * from './auth';
export * from './SanitizedInnerHTML';
export * from './abstract-repository';
export * from './entity';

// Base URLs
const LOCAL_TOMCAT = 'https://localhost:8443/';
export const EBA_URL = 'https://www.bus-alliance.com/busfinder/';
export const BUSFINDER_UI_URL =
    window.location.hostname.indexOf('localhost') != -1
        ? 'https://localhost:3000'
        : window.location.hostname.indexOf('busworld24') != -1
          ? 'https://www.bus.busworld24.com/'
          : 'https://www.busfinder.com/';
export const UI_URL = window.location.origin + '/';
export const TOMCAT_URL =
    window.location.hostname.indexOf('localhost') != -1
        ? LOCAL_TOMCAT
        : window.location.origin.replace('www.', 'app.') + '/tomcat/';

console.log('UI_URL', UI_URL);
console.log('TOMCAT_URL', TOMCAT_URL);
