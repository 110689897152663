import { createSelector } from 'reselect';

import { CalendarResourceGroup, CalendarResourceType } from 'src/utils/constants';
import { defaultState, Entities, getEntities } from 'src/common/index';
import { getBusesInternResourcesCategorized } from 'src/selectors/calendar-helpers';

const categories = state => getEntities(state, Entities.BUS_CATEGORY, state.selectedCompany);
const getStateOfBusesByCompany = state => state[Entities.BUS.reducer][state.selectedCompany] || defaultState();
const getBusEntities = state => state.entities[Entities.BUS.repository];
const getDriverEntities = state => state.entities[Entities.DRIVER.repository] || [];

export const getInterBusResources = createSelector(
    [getStateOfBusesByCompany, getBusEntities, categories, getDriverEntities],
    (busesByCompany, buses, categories, drivers) => {
        return getBusesInternResourcesCategorized(categories.items, busesByCompany.result, buses, drivers);
    },
);

export const getInternOpenTaskResources = createSelector([], () => {
    return [
        {
            groups: CalendarResourceGroup.TASKS,
            title: CalendarResourceType.TASK_INTERN,
            id: CalendarResourceType.TASK_INTERN,
        },
    ];
});

export const getExternOpenTaskFromMeResources = createSelector([], () => {
    return [
        {
            groups: CalendarResourceGroup.TASKS,
            title: CalendarResourceType.TASK_EXTERN_OWN,
            id: CalendarResourceType.TASK_EXTERN_OWN,
        },
    ];
});

export const getExternOpenTaskFromForeignResources = createSelector([], () => {
    return [
        {
            groups: CalendarResourceGroup.TASKS,
            title: CalendarResourceType.TASK_EXTERN_FOREIGN,
            id: CalendarResourceType.TASK_EXTERN_FOREIGN,
        },
    ];
});

export const getOfferResource = createSelector([], () => {
    return [
        {
            groups: CalendarResourceGroup.TASKS,
            title: CalendarResourceType.OFFER,
            id: CalendarResourceType.OFFER,
        },
    ];
});

export const getAllResourcesForCalendar = createSelector(
    [
        getInterBusResources,
        getInternOpenTaskResources,
        getExternOpenTaskFromMeResources,
        getExternOpenTaskFromForeignResources,
        getOfferResource,
    ],
    (internBusResources, internTaskResources, myExternTaskResources, foreignExternTaskResources, offerResources) => {
        return internBusResources
            .concat(internTaskResources)
            .concat(myExternTaskResources)
            .concat(foreignExternTaskResources)
            .concat(offerResources);
    },
);
