import { List, ListItem, makeSelectable } from 'material-ui/List';
import { blue700, green700, orange700, red700 } from 'material-ui/styles/colors';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import AcceptedIcon from 'material-ui/svg-icons/action/done';
import ListIcon from 'material-ui/svg-icons/action/list';
import BanIcon from 'material-ui/svg-icons/av/not-interested';
import OpenFolderIcon from 'material-ui/svg-icons/file/folder-open';
import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Details from 'src/components/operatingtool/details';
import TaskList from 'src/components/taskadministration/task-list';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { Entities } from 'src/common/index';
import { withTranslation } from 'react-i18next';

let SelectableList = makeSelectable(List);

/**
 * Offer Administration displays all available offers
 * with details to the selected one
 */
class OfferAdministration extends Component {
    constructor(props) {
        const { t } = props;
        super(props);
        autoBind(this);

        this.state = {
            filter: 'OPEN_OFFER',
            tableTitle: t('offers.open_offers'),
            showExpiredTasks: false,
        };
    }

    componentDidMount() {
        const { selectedCompany } = this.props;

        this.props.setFetchMethodOnError(this.fetchOnError);

        this.props.fetchByCompanyId(Entities.BUS, selectedCompany);
        this.props.fetchOwnBusCompany(selectedCompany);
        this.props.fetchOffersByFilter(selectedCompany, false, this.state.filter);
    }

    componentDidUpdate(prevProps) {
        const { selectedCompany, tasks } = this.props;
        if (tasks.action.type === 'UPDATE') {
            this.props.fetchOffersByFilter(selectedCompany, false, this.state.filter);
        }
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        const { selectedCompany, t } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-2 sidebar-listview">
                        <div>
                            <Toggle
                                label={t('offers.past')}
                                toggled={this.state.showExpiredTasks === true}
                                onToggle={() => {
                                    this.setState({
                                        showExpiredTasks: !this.state.showExpiredTasks,
                                    });
                                    this.props.fetchOffersByFilter(
                                        selectedCompany,
                                        !this.state.showExpiredTasks,
                                        this.state.filter,
                                    );
                                }}
                            />
                        </div>
                        <SelectableList value={this.state.filter} onChange={() => this}>
                            <ListItem
                                style={{ fontSize: '14px' }}
                                key={0}
                                value="OPEN_OFFER"
                                primaryText={t('offers.open')}
                                leftIcon={<OpenFolderIcon color={blue700} />}
                                onClick={() => this.changeFilter('OPEN_OFFER', t('offers.open_offers'))}
                            />
                            <ListItem
                                style={{ fontSize: '14px' }}
                                key={1}
                                value="ACCEPTED_OFFER"
                                primaryText={t('offers.accepted')}
                                leftIcon={<AcceptedIcon color={green700} />}
                                onClick={() => this.changeFilter('ACCEPTED_OFFER', t('offers.accepted_offers'))}
                            />
                            <ListItem
                                style={{ fontSize: '14px' }}
                                key={2}
                                value="DECLINED_OFFER"
                                primaryText={t('offers.declined')}
                                leftIcon={<BanIcon color={orange700} />}
                                onClick={() => this.changeFilter('DECLINED_OFFER', t('offers.declined_offers'))}
                            />
                            <ListItem
                                style={{ fontSize: '14px' }}
                                key={3}
                                value="DELETED_OFFER"
                                primaryText={t('offers.deleted')}
                                leftIcon={<DeleteIcon color={red700} />}
                                onClick={() => this.changeFilter('DELETED_OFFER', t('offers.deleted_offers'))}
                            />
                            <ListItem
                                style={{ fontSize: '14px' }}
                                key={3}
                                value="ALL_OFFERS"
                                primaryText={t('common_phrases.all')}
                                leftIcon={<ListIcon color={blue700} />}
                                onClick={() => this.changeFilter('ALL_OFFERS', t('offers.all_offers'))}
                            />
                        </SelectableList>
                    </div>

                    <div id="offer-tasklist-panel" className="col-md-10">
                        <legend className="legend">{this.state.tableTitle}</legend>

                        <TaskList
                            t={t}
                            isOfferView={true}
                            showExpiredTasks={this.state.showExpiredTasks}
                            taskFilter={this.state.filter}
                            selectedCompany={this.props.selectedCompany}
                            tasks={this.props.tasks}
                            buses={this.props.buses}
                            busCompany={this.props.busCompany}
                            selectEvent={this.props.selectEvent}
                            patch={this.props.patch}
                            deleteById={this.props.deleteById}
                            addNotification={this.props.addNotification}
                            openModal={this.props.openModal}
                        />

                        <div className="row">
                            <div className="col-md-12 detailscontainer">
                                <Details
                                    eventDetails={this.props.eventDetails}
                                    busCompany={this.props.busCompany}
                                    patch={this.props.patch}
                                    deleteById={this.props.deleteById}
                                    addNotification={this.props.addNotification}
                                    openModal={this.props.openModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeFilter(filter, title) {
        const { selectedCompany } = this.props;

        this.props.fetchOffersByFilter(selectedCompany, this.state.showExpiredTasks, filter);
        this.setState({
            filter: filter,
            tableTitle: title,
        });
    }

    fetchOnError() {
        this.props.fetchOffersByFilter(this.props.selectedCompany, this.state.showExpiredTasks, this.state.filter);
    }
}

export default withTranslation()(composeModalActions(OfferAdministration));
