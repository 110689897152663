import BigNumber from 'bignumber.js';
import Cookies from 'js-cookie';
import Money from 'src/common/entity/basic-types/Money';

export const convertCurrency = (amount: BigNumber, fromCurrency: string, toCurrency: string): BigNumber => {
    const exchangeRatesCookie = Cookies.get('exchangeRates');
    if (!exchangeRatesCookie) {
        console.error('Invalid exchangeRates');
        return amount;
    }
    const exchangeRates: Record<string, number> = JSON.parse(exchangeRatesCookie);

    // Nothing to convert
    if (fromCurrency === toCurrency) return amount;

    let convertedAmount = amount.dividedBy(exchangeRates[fromCurrency]);

    if (toCurrency !== 'EUR') convertedAmount = convertedAmount.multipliedBy(exchangeRates[toCurrency]);

    return convertedAmount.decimalPlaces(2);
};

// Used in the Tables to sort columns with Money values
export const sortMoney = (a: any, b: any, order: 'asc' | 'desc', sortField: string) => {
    const convertToEUR = (item: any) => {
        const amount =
            item[sortField].amount instanceof BigNumber
                ? item[sortField].amount
                : new BigNumber(item[sortField].amount);

        const amountInEUR = convertCurrency(amount, item[sortField].currency, 'EUR');

        return Money.fromJson({
            amount: amountInEUR,
            currency: 'EUR',
        });
    };

    return Money.sort(convertToEUR(a), convertToEUR(b), order);
};

export const saveExchangeRates = (data: any) => {
    const entries: Record<string, string>[] = data.Cube.Cube.Cube;

    const rates: Record<string, number> = { EUR: 1 };

    entries.forEach(entry => {
        rates[entry.currency] = Number.parseFloat(entry.rate);
    });

    Cookies.set('exchangeRates', JSON.stringify(rates), { secure: true, sameSite: 'none', expires: 0.5 });
};
