import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { asyncRequest, deleteById, Entities, fetchByCompanyId, getEntities, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { dateFormatter } from 'src/utils/formatters';
import ParentForm from 'src/components/misc/parent-form';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import SeasonForm from 'src/components/trips/administration/season/SeasonForm';
import { withTranslation } from 'react-i18next';

class SeasonAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({ onRowClick: this.onSelectSeason });
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchByCompanyId(Entities.SEASON, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { seasons } = nextProps;

        if (!this.state.startedFetching && seasons.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(seasons.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.seasons, this.props.seasons, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { seasons, selectedCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !seasons.isFetching;

        const tableData = [];

        if (finishFetching && seasons.items.length > 0) {
            seasons.items.map(season => {
                tableData.push({
                    id: season.id,
                    name: season.name,
                    periodFrom: season.periodFrom,
                    periodTill: season.periodTill,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('calculation.seasons')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: ParentForm,
                                componentProps: {
                                    reducerIndex: selectedCompany,
                                    isCreate: true,
                                    mainEntityProps: {
                                        entity: Entities.SEASON,
                                    },
                                    childForm: {
                                        component: SeasonForm,
                                        props: {
                                            companyId: selectedCompany,
                                        },
                                    },
                                },
                                title: t('calculation.new_season'),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('calculation.new_season')}
                    </button>
                </legend>

                {(() => {
                    if (seasons.error) return <ErrorMessage object={seasons} />;
                    else
                        return (
                            <div className="clickableTable">
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                        {t('common_phrases.name')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="periodFrom"
                                        width="160"
                                        dataFormat={dateFormatter}
                                        csvFormat={dateFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.from')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="periodTill"
                                        width="160"
                                        dataFormat={dateFormatter}
                                        csvFormat={dateFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.to')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, season) {
        const { selectedCompany, t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                reducerIndex: selectedCompany,
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.SEASON,
                                    id: season.id,
                                },
                                childForm: {
                                    component: SeasonForm,
                                    props: {
                                        companyId: selectedCompany,
                                    },
                                },
                            },
                            title: season.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.SEASON.repository}/${season.id}/deletable`)
                            .then(response => this.openConfirmDeleteDialog(season))
                            .catch(error => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }

    onProceedDelete = season => {
        this.props.deleteById(season.id, Entities.SEASON, this.props.selectedCompany);
    };

    openConfirmDeleteDialog = season => {
        const { t } = this.props;

        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: season,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('calculation.confirm_delete_season'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: season.name,
            noButtons: true,
        });
    };

    openCannotDeleteDialog = () => {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('calculation.error_delete_products'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    };

    onSelectSeason(season, col) {
        if (col === 0) this.props.push('/trips/administration/seasons/' + season.id);
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        seasons: getEntities(state, Entities.SEASON, state.selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        fetchByCompanyId,
        deleteById,
        push,
    })(composeModalActions(SeasonAdministration)),
);
