import React, { Component } from 'react';
import autoBind from 'react-autobind/src/autoBind';
import { withTranslation } from 'react-i18next';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { Entities } from 'src/common';
import TableHelper from 'src/utils/table-helper';
import CircularProgress from 'material-ui/CircularProgress';
import ErrorMessage from 'src/components/misc/error-message';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class AdminReferralAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.REFERRALS);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.referrals, this.props.referrals, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    componentWillReceiveProps(nextProps) {
        const { referrals } = nextProps;

        if (referrals.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(referrals.items.length);
    }

    render() {
        const { referrals, t } = this.props;
        console.log(referrals);
        const finishFetching = this.state.startedFetching && !referrals.isFetching;
        const tableData = [];
        //

        if (finishFetching && referrals.items.length > 0) {
            referrals.items.map(referral => {
                tableData.push({
                    id: referral.id,
                    invitedEmail: referral.invitedEmail,
                    referredBy: referral.referredBy.email,
                    token: referral.token,
                    modifiedAt: new Date(referral.modifiedAt).toLocaleDateString('de-DE'),
                    state: referral.referralStatus,
                    createdAt: new Date(referral.createdAt).toLocaleDateString('de-DE'),
                    redeemedCoupons:
                        referral.relatedCoupons.filter(coupon => coupon.valid == false).length +
                        '/' +
                        referral.relatedCoupons.length,
                });
            });
        }
        return (
            <div>
                <legend className="legend">{t('referrals.referrals')}</legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (referrals.error) return <ErrorMessage object={referrals} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    width={'50'}
                                    isKey={true}
                                    export={true}>
                                    {' '}
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    export={true}
                                    columnTitle={true}
                                    dataField="createdAt"
                                    dataSort={true}>
                                    {t('referrals.createdAt')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    export={true}
                                    columnTitle={true}
                                    dataField="modifiedAt"
                                    dataSort={true}>
                                    {t('referrals.modifiedAt')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    export={true}
                                    columnTitle={true}
                                    dataField="invitedEmail"
                                    dataSort={true}>
                                    {t('referrals.invitedEmail')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    export={true}
                                    columnTitle={true}
                                    dataField="referredBy"
                                    dataSort={true}>
                                    {t('referrals.referredBy')}
                                </TableHeaderColumn>
                                <TableHeaderColumn export={true} columnTitle={true} dataField="state" dataSort={true}>
                                    {t('referrals.state')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    export={true}
                                    columnTitle={true}
                                    dataField="redeemedCoupons"
                                    dataSort={true}>
                                    {t('referrals.redeemedCoupons')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminReferralAdministration));
