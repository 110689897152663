import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';

import { ButtonColors } from 'src/utils/constants';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import HelpModal from 'src/components/admin/help/entries/help-modal';
import { withTranslation } from 'react-i18next';

class HelpEntryAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.HELP);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { helps } = nextProps;

        if (!this.state.startedFetching && helps.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(helps.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.helps, this.props.helps, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { helps, helpCategories, t } = this.props;
        const finishFetching = this.state.startedFetching && !helps.isFetching;

        const tableData = [];

        if (finishFetching && helps.items.length > 0 && helpCategories) {
            helps.items.map(questionAndAnswer => {
                tableData.push({
                    id: questionAndAnswer.id,
                    question: questionAndAnswer.question,
                    category: helpCategories[questionAndAnswer.category]
                        ? helpCategories[questionAndAnswer.category].name
                        : null,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('help.support')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            if (!helpCategories || _.size(helpCategories) < 1) {
                                this.props.openModal({
                                    component: ConfirmDialog,
                                    componentProps: {
                                        bodyText: t('help.at_least_one_category'),
                                        proceedText: t('common_phrases.ok'),
                                        proceedButtonColor: ButtonColors.NOTIFY,
                                        isNotification: true,
                                    },
                                    title: t('common_phrases.attention'),
                                    noButtons: true,
                                });
                            } else {
                                this.table.reset();
                                this.props.openModal({
                                    component: HelpModal,
                                    componentProps: {
                                        isCreate: true,
                                    },
                                    title: t('help.new_support'),
                                });
                            }
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('help.new_support')}
                    </button>
                </legend>

                {(() => {
                    if (helps.error) return <ErrorMessage object={helps} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="question" dataSort={true}>
                                    {t('help.question')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="category" width="250" dataSort={true}>
                                    {t('common_phrases.category')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, help) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: HelpModal,
                            componentProps: {
                                id: help.id,
                                isCreate: false,
                            },
                            title: t('user_functionality.edit'),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: help,
                                onProceedDialog: help => this.props.deleteById(help.id, Entities.HELP),
                                bodyText: t('help.confirm_delete_entry'),
                                proceedText: t('user_functionality.delete'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: help.question,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(HelpEntryAdministration));
