import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    return (
        <div className="navbar navbar-fixed-bottom">
            <div className="container-fluid">
                <div className="navbar-right footer">
                    &copy; EBA Bus Alliance GmbH &nbsp;
                    <span
                        className="glyphicon glyphicon-info-sign"
                        title={t('footer_text', {
                            operatingToolVersion: Config.OPERATINGTOOL_VERSION,
                            apiVersion: Config.API_VERSION,
                        })}
                    />
                </div>
            </div>
        </div>
    );
}

export default Footer;
