import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import AdminCustomerVerificationForm from 'src/components/admin/accounts/admin-customer-verification-form';

class AdminCustomerVerificationModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.CUSTOMER, 0, 'verification');
    }

    componentWillReceiveProps(nextProps) {
        const { account } = nextProps;

        if (!this.state.startedFetching && account.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { account, t } = this.props;

        const finishFetching = this.state.startedFetching && !account.isFetching;

        if (finishFetching) {
            if (account.error) return <ErrorMessage object={account} />;
            else {
                const initialValues = {
                    id: account.content.id,
                };

                return (
                    <AdminCustomerVerificationForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { patch, handleClose, closeDrawer } = this.props;

        patch(data, Entities.CUSTOMER, 0, 'verification', undefined, 'verify');

        if (closeDrawer) closeDrawer();

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        account: getUpdatedEntity(state, Entities.CUSTOMER),
    };
};

export default connect(mapStateToProps, { fetchById, patch }, null, { withRef: true })(AdminCustomerVerificationModal);
