import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import IntegrationEditModal from 'src/components/admin/integration/booking/integration-edit-modal';
import AddCompaniesModal from 'src/components/admin/integration/booking/add-companies.modal';
import Toggle from 'material-ui/Toggle';
import { withTranslation } from 'react-i18next';

class IntegrationAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BOOKING_INTEGRATION);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { integrations } = nextProps;

        if (integrations.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(integrations.items.length);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.integrations, this.props.integrations, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { integrations, t } = this.props;
        const finishFetching = this.state.startedFetching && !integrations.isFetching;

        const tableData = [];
        if (finishFetching && integrations.items.length > 0) {
            integrations.items.map(integration => {
                tableData.push({
                    id: integration.id,
                    name: integration.name,
                    url: integration.url,
                    companyName: integration.company.companyName,
                    companyId: integration.company.id,
                    feeType: integration.feeType,
                    active: integration.active,
                    ignoreArrivalAndReturnKm: integration.ignoreArrivalAndReturnKm,
                    formTourEnabled: integration.formTourEnabled,
                    resultFilterEnabled: integration.resultFilterEnabled,
                    reservationEnabled: integration.reservationEnabled,
                    countSubCompanies: integration.allCompanies
                        ? t('common_phrases.all')
                        : integration.subCompanies.length,
                    applicableSchool: integration.applicableSchool,
                    languageSwitcherEnabled: integration.languageSwitcherEnabled,
                    entity: integration,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('booked_tasks.integrations')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: IntegrationEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('booked_tasks.new_integration'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('booked_tasks.new_integration')}
                    </button>
                </legend>

                {(() => {
                    if (integrations.error) return <ErrorMessage object={integrations} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                className="clickableTable"
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="url" dataSort={true}>
                                    {t('booked_tasks.partner_url')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="companyName" dataSort={true}>
                                    {t('booked_tasks.owner')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="feeType"
                                    width="140"
                                    dataSort={true}
                                    dataFormat={(cell, row) => t(`integration.fee_type_option.${cell.toLowerCase()}`)}>
                                    {t('integration.fee_type')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="active"
                                    width="80"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'active')}>
                                    {t('common_phrases.active')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onRowClick(row, col) {
        if ([0, 1, 2, 3].includes(col)) this.props.push(`/admin/integrations/booking/${row.id}`);
    }

    toggleFormatter(cell, integration, field) {
        return (
            <Toggle
                toggled={integration[field]}
                onToggle={() => {
                    this.props.patch(
                        { id: integration.id, [field]: !integration[field] },
                        Entities.BOOKING_INTEGRATION,
                    );
                }}
            />
        );
    }

    tableActionsFormatter(cell, integration) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: IntegrationEditModal,
                            componentProps: {
                                id: integration.id,
                                isCreate: false,
                            },
                            title: integration.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span title={t('user_functionality.edit')} className="glyphicon glyphicon-pencil text-info" />
                </button>
                {!integration.entity.allCompanies && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        onClick={() => {
                            this.props.openModal({
                                component: AddCompaniesModal,
                                componentProps: {
                                    integration: integration.entity,
                                    dialogStyle: {
                                        width: '60vw',
                                        maxWidth: 'none',
                                    },
                                },
                                title: integration.name,
                                mandatoryFields: true,
                            });
                        }}>
                        <span
                            title={t('booked_tasks.operate_companies')}
                            className="glyphicon glyphicon-transfer text-warning"
                        />
                    </button>
                )}
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(IntegrationAdministration));
