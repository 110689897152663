import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import { List, ListItem } from 'material-ui/List';
import { blue500, green500, pink500, red500 } from 'material-ui/styles/colors';
import Subheader from 'material-ui/Subheader';
import ActionAssignment from 'material-ui/svg-icons/action/assignment';
import ActionDone from 'material-ui/svg-icons/action/done';
import ActionInfo from 'material-ui/svg-icons/action/info';
import ContentBlock from 'material-ui/svg-icons/content/block';
import moment from 'moment';
import _ from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { getTaskNewsSinceLastLogin } from 'src/selectors/tasks';
import { ButtonColors, genderName } from 'src/utils/constants';
import { dashIfNull, Entities, entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import composeTaskActions from 'src/utils/task-action-wrapper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { getBookedPrice } from 'src/components/operatingtool/price';

class TaskNews extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.clickedRightIconAction = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modal.open === false && this.clickedRightIconAction === true) this.clickedRightIconAction = false;
    }

    render() {
        const { newTasksSinceLastLogin, t } = this.props;

        const offeredTasks = newTasksSinceLastLogin.items.offeredToOwnCompany;
        const acceptedTasks = newTasksSinceLastLogin.items.acceptedByOtherCompany;
        const bookedTasks = newTasksSinceLastLogin.items.bookedTasks;

        if (offeredTasks.length === 0 && acceptedTasks.length === 0 && bookedTasks.length === 0)
            return <div>{t('booked_tasks.no_news')}</div>;
        else
            return (
                <div className="row">
                    <div className="form-group">
                        {bookedTasks.length > 0 && (
                            <List>
                                <Subheader>{t('booked_tasks.booked_tasks')}</Subheader>
                                {bookedTasks.map((task, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            leftAvatar={
                                                <Avatar icon={<ActionAssignment />} backgroundColor={blue500} />
                                            }
                                            onClick={() => !this.clickedRightIconAction && this.showTaskDetails(task)}
                                            primaryText={task.name}
                                            secondaryText={t('booked_tasks.customer_name', {
                                                name: task.customer.contactData.fullName,
                                            })}
                                        />
                                    );
                                })}
                            </List>
                        )}

                        {offeredTasks.length > 0 && (
                            <List>
                                <Subheader>{t('booked_tasks.released_for_you')}</Subheader>
                                {offeredTasks.map((task, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            leftAvatar={
                                                <Avatar icon={<ActionAssignment />} backgroundColor={blue500} />
                                            }
                                            onClick={() => !this.clickedRightIconAction && this.showTaskDetails(task)}
                                            primaryText={task.name}
                                            rightIconButton={this.getPendingTaskActions(task)}
                                            secondaryText={t('tasks.company_time', {
                                                companyName: task.exchangedTasks[0].ownCompanyDetails.companyName,
                                                time: moment(task.exchangedTasks[0].modifiedAt).format(),
                                            })}
                                        />
                                    );
                                })}
                            </List>
                        )}

                        <Divider inset={true} />

                        {acceptedTasks.length > 0 && (
                            <List>
                                <Subheader>{t('booked_tasks.accepted_by_company')}</Subheader>
                                {acceptedTasks.map((task, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            leftAvatar={
                                                <Avatar icon={<ActionAssignment />} backgroundColor={pink500} />
                                            }
                                            primaryText={task.name}
                                            onClick={() => this.showTaskDetails(task)}
                                            rightIcon={<ActionInfo />}
                                            secondaryText={t('tasks.company_time', {
                                                companyName: task.exchangedTasks[0].foreignCompanyDetails.companyName,
                                                time: moment(task.exchangedTasks[0].modifiedAt).format(),
                                            })}
                                        />
                                    );
                                })}
                            </List>
                        )}
                    </div>
                </div>
            );
    }

    getPendingTaskActions(task) {
        const { t } = this.props;
        return (
            <div>
                <IconButton
                    tooltip={t('user_functionality.accept')}
                    onClick={() => {
                        this.clickedRightIconAction = true;
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: task,
                                onProceedDialog: this.props.onProceedAcceptTask,
                                bodyText: t('booked_tasks.confirm_acceptance'),
                                proceedText: t('user_functionality.accept'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.SUCCESS,
                            },
                            title: task.name,
                            noButtons: true,
                        });
                    }}>
                    <ActionDone color={green500} />
                </IconButton>

                {task.exchangedTasks[0].foreignCompanyDetails.id !== -1 && (
                    <IconButton
                        tooltip={t('user_functionality.decline')}
                        onClick={() => {
                            this.clickedRightIconAction = true;
                            this.props.openModal({
                                component: ConfirmDialog,
                                componentProps: {
                                    event: task,
                                    onProceedDialog: this.props.onProceedDeclineTask,
                                    bodyText: t('booked_tasks.confirm_denial'),
                                    proceedText: t('common_phrases.yes'),
                                    cancelText: t('common_phrases.no'),
                                    cancelButtonColor: ButtonColors.NOTIFY,
                                    proceedButtonColor: ButtonColors.SUCCESS,
                                },
                                title: task.name,
                                noButtons: true,
                            });
                        }}>
                        <ContentBlock color={red500} />
                    </IconButton>
                )}
            </div>
        );
    }

    showTaskDetails(task) {
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: this.getTaskDetails(task),
                proceedText: this.props.t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: task.name,
            noButtons: true,
        });
    }

    getTaskDetails(task) {
        const { buses, brands, t } = this.props;
        const busOfTask = task.bus && _.size(buses) > 0 ? _.find(buses, bus => bus.id === task.bus) : undefined;

        return (
            <div>
                <div className="row">
                    <div className="form-group">
                        <div className="col-md-12">
                            <legend className="legend legend-event-form">{t('common_phrases.common')}</legend>
                        </div>

                        <div className="col-md-6">
                            {renderKeyValueRow(`${t('booked_tasks.price')} :`, getBookedPrice(task, t))}
                        </div>

                        <div className="col-md-6">
                            {renderKeyValueRow(`${t('task_details.pax')} :`, dashIfNull(task.passengers))}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <div className="col-md-12">
                            <legend className="legend legend-event-form">{t('booked_tasks.customer')}</legend>
                        </div>

                        <div className="col-md-6">
                            {renderKeyValueRow(
                                `${t('contact_data.gender')} :`,
                                dashIfNull(genderName(task.customerContactData.gender)),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.first_name')} :`,
                                dashIfNull(task.customerContactData.firstName),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.last_name')} :`,
                                dashIfNull(task.customerContactData.lastName),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.tel')} :`,
                                dashIfNull(task.customerContactData.phone),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.email')} :`,
                                dashIfNull(task.customerContactData.email),
                            )}
                        </div>

                        <div className="col-md-6">
                            {renderKeyValueRow(
                                `${t('contact_data.country')} :`,
                                dashIfNull(task.customerContactData.country),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.city')} :`,
                                dashIfNull(task.customerContactData.city),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.post_code')} :`,
                                dashIfNull(task.customerContactData.postCode),
                            )}
                            {renderKeyValueRow(
                                `${t('contact_data.street')} :`,
                                dashIfNull(task.customerContactData.street),
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <div className="col-md-12">
                            <legend className="legend legend-event-form">{t('bus.car')}</legend>
                        </div>

                        {busOfTask ? (
                            <div>
                                <div className="col-md-6">
                                    {renderKeyValueRow(`${t('common_phrases.name')} :`, busOfTask.name)}
                                    {renderKeyValueRow(`${t('common_phrases.type')} :`, busOfTask.type)}
                                    {renderKeyValueRow(`${t('brands.brand')} :`, brands[busOfTask.brand].name)}
                                </div>

                                <div className="col-md-6">
                                    {renderKeyValueRow(`${t('bus.seats')} :`, dashIfNull(busOfTask.seatsTotal))}
                                    {renderKeyValueRow(`${t('common_phrases.location')} :`, busOfTask.homeBase.address)}
                                    {renderKeyValueRow(`${t('bus.plate_number')} :`, busOfTask.plateNumber)}
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-12">{t('bus.no_car')}</div>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 moveTaskDetails">
                        <legend className="legend legend-event-form">{t('task_details.route')}</legend>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{t('task_details.bus_start')}</th>
                                    <th>{t('task_details.bus_end')}</th>
                                    <th>{t('task_details.clients_destination')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        {moment(task.from.time).format()} Uhr <br /> {task.from.location.address}
                                    </td>
                                    <td>
                                        {moment(task.to.time).format()} Uhr <br /> {task.to.location.address}
                                    </td>
                                    <td>
                                        <p>
                                            {moment(task.taskFrom.time).format()} Uhr <br />{' '}
                                            {task.taskFrom.location.address}
                                        </p>
                                        {task.intermediates &&
                                            task.intermediates.length > 0 &&
                                            task.intermediates.map((intermediate, index) => {
                                                return (
                                                    <p key={index}>
                                                        {t('administration.time', {
                                                            time: moment(intermediate.time).format(),
                                                        })}
                                                        <br /> {intermediate.location.address}
                                                    </p>
                                                );
                                            })}
                                        <p>
                                            {t('administration.time', { time: moment(task.taskTo.time).format() })}
                                            <br /> {task.taskTo.location.address}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        newTasksSinceLastLogin: getTaskNewsSinceLastLogin(state),
        buses: entities(state, Entities.BUS),
        brands: entities(state, Entities.BUS_BRAND),
    };
};

export default composeModalActions(composeTaskActions(connect(mapStateToProps)(TaskNews)));

const renderKeyValueRow = (key, value) => {
    return (
        <div className="row">
            <div className="col-md-4">
                <strong>{key}</strong>
            </div>
            <div className="col-md-8">
                <span>{value}</span>
            </div>
        </div>
    );
};
