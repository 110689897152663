import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { asyncRequest, fetchAll, setMoneyCurrency, TOMCAT_URL } from 'src/common/index';
import { disableFormSubmit } from 'src/actions/form-submit';
import { addNotification } from 'src/actions/notifications';
import { releaseTask } from 'src/actions/tasks';
import { TaskExchangeState, TaskExchangeType } from 'src/utils/constants';
import SpecificSearchForm from 'src/components/taskadministration/taskrelease/specific-search-form';
import TaskDetails from 'src/components/taskadministration/taskrelease/task-details';
import { getOwnCompany } from 'src/selectors/bus-companies';

class TaskExchangeModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            type: undefined,
            foreignCompanies: undefined,
        };
    }

    componentWillMount() {
        asyncRequest(`${TOMCAT_URL}api/foreign-bus-companies`).then(response =>
            this.setState({
                foreignCompanies: response.json,
            }),
        );

        this.props.disableFormSubmit(true);
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return true;
    }

    render() {
        const { task, selectedCompany, t } = this.props;

        const initialValues = {
            type: TaskExchangeType.BUS,
            state: TaskExchangeState.PENDING,
            ownCompany: selectedCompany,
            task: task.id,
            price: task.price ? task.price : 0,
            foreignCompanies: [],
        };

        return (
            <div>
                <TaskDetails
                    t={t}
                    task={task}
                    onGlobalExchange={this.handleGlobalExchange}
                    onSpecificExchange={this.handleSpecificExchange}
                />

                {(() => {
                    switch (this.state.type) {
                        case 'specific':
                            if (this.state.foreignCompanies !== undefined)
                                return (
                                    <SpecificSearchForm
                                        t={t}
                                        formType={this.state.type}
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        busCompanies={this.state.foreignCompanies}
                                        disableFormSubmit={this.props.disableFormSubmit}
                                        selectedCompany={selectedCompany}
                                        task={task}
                                        ref={ref => (this.childForm = ref)}
                                    />
                                );
                            break;
                        case 'global':
                            return (
                                <SpecificSearchForm
                                    t={t}
                                    formType={this.state.type}
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    selectedCompany={selectedCompany}
                                    task={task}
                                    ref={ref => (this.childForm = ref)}
                                />
                            );
                        default:
                            return null;
                    }
                })()}
            </div>
        );
    }

    handleSubmit(data) {
        const { handleClose, ownCompany } = this.props;

        if (this.state.type === 'global') data.foreignCompanies = [];

        setMoneyCurrency(data.price, ownCompany.entity.currency);

        this.props.releaseTask(data, this.props.selectedCompany, this.processSuccessReleaseTask);

        handleClose();
    }

    processSuccessReleaseTask() {
        const { t } = this.props;
        this.props.addNotification(t('common_phrases.success'), t('task_details.task_successfully'), 'success', 'tr');
    }

    handleGlobalExchange() {
        this.setState({
            type: 'global',
        });

        this.props.disableFormSubmit(false);
    }

    handleSpecificExchange() {
        this.setState({
            type: 'specific',
        });

        this.props.disableFormSubmit(true);
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        ownCompany: getOwnCompany(state),
    };
};

export default connect(mapStateToProps, { releaseTask, fetchAll, addNotification, disableFormSubmit }, null, {
    withRef: true,
})(TaskExchangeModal);
