// TODO: BUF-1135: translate
export enum DayOfWeek {
    MONDAY = 'Montag',
    TUESDAY = 'Dienstag',
    WEDNESDAY = 'Mittwoch',
    THURSDAY = 'Donnerstag',
    FRIDAY = 'Freitag',
    SATURDAY = 'Samstag',
    SUNDAY = 'Sonntag',
}

export const getDayOfWeek = (day: number) => {
    switch (day) {
        case 0:
            return 'SUNDAY';
        case 1:
            return 'MONDAY';
        case 2:
            return 'TUESDAY';
        case 3:
            return 'WEDNESDAY';
        case 4:
            return 'THURSDAY';
        case 5:
            return 'FRIDAY';
        case 6:
            return 'SATURDAY';
        default:
            return 'SUNDAY';
    }
};
