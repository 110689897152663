import FormData from 'form-data';
import { push } from 'react-router-redux';
import { asyncRequest } from './async-request';
import { TOMCAT_URL } from './index';

export const RESET = 'RESET';

export const AUTHENTICATION_TOKEN_HEADER = 'X-BUS-AUTHENTICATION-TOKEN';
export const AUTHENTICATION_TOKEN_STORAGE_KEY = 'BUSFINDER-AUTHENTICATION-TOKEN';

export function login(
    username: string,
    password: string,
    onSuccess: any,
    onFailure: any,
    noRedirectOnUnauthenticated: boolean,
) {
    return (dispatch: any) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        asyncRequest({
            endpoint: `${TOMCAT_URL}public/login`,
            method: 'POST',
            body: formData,
            noRedirectOnUnauthenticated: noRedirectOnUnauthenticated,
            headers: {
                [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY),
            },
        })
            .then((response: any) => {
                if (onSuccess) dispatch(onSuccess());
            })
            .catch((error: any) => {
                if (onFailure) onFailure(error.status);
                else dispatch(push(`/login?error=${error.status}`));
            });
    };
}

export function loginWithToken(token: string, onSuccess: any, onFailure: any, noRedirectOnUnauthenticated: boolean) {
    return (dispatch: any) => {
        asyncRequest({
            endpoint: `${TOMCAT_URL}public/sso/login-with-token`,
            method: 'POST',
            body: {
                token: token,
            },
            noRedirectOnUnauthenticated: noRedirectOnUnauthenticated,
            headers: {
                [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY),
            },
        })
            .then((response: any) => {
                if (onSuccess) dispatch(onSuccess());
            })
            .catch((error: any) => {
                if (onFailure) onFailure(error.status);
                else dispatch(push(`/login-with-token?error=${error.status}`));
            });
    };
}

export function logout() {
    return (dispatch: any) => {
        dispatch(reset())
            .then(dispatch(push(`/login?logout=true`)))
            .then(() => {
                asyncRequest({
                    endpoint: `${TOMCAT_URL}public/logout`,
                    method: 'POST',
                    headers: {
                        [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY),
                    },
                });
            });
    };
}

export function reset() {
    return {
        type: RESET,
    };
}
