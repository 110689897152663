import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminReferralAdministration from 'src/components/admin/referrals/admin-referral-administration';

const mapStateToProps = state => {
    return {
        referrals: getEntities(state, Entities.REFERRALS),
    };
};

const AdminReferralAdministrationContainer = connect(mapStateToProps, { fetchAll, patch })(AdminReferralAdministration);

export default AdminReferralAdministrationContainer;
