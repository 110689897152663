import React from 'react';
import CustomTooltip from 'src/components/misc/CustomTooltip';

export interface JumboStat {
    label: React.ReactNode;
    value: React.ReactNode;
    tooltip?: React.ReactNode;
}

type Size = 'large' | 'medium';
interface Props {
    values: JumboStat[];
    size?: Size;
}

const JumboStats: React.FC<Props> = props => {
    const { values, size = 'large' } = props;

    const pixelSizes = getSizeInPixel(size);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexGrow: 1 }}>
            {values.map((stat: JumboStat, index: number) => (
                <div
                    key={index}
                    className="status-item"
                    style={{ width: pixelSizes.container, height: pixelSizes.container }}>
                    <div style={{ display: 'flex' }}>
                        {stat.tooltip ? (
                            <CustomTooltip id={`tooltip-jumbo-${index}`} text={stat.tooltip} place="bottom">
                                {renderValue(stat.value, pixelSizes.value)}
                            </CustomTooltip>
                        ) : (
                            renderValue(stat.value, pixelSizes.value)
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span style={{ fontWeight: 'bold', fontSize: pixelSizes.label, textAlign: 'center' }}>
                            {stat.label}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

const renderValue = (value: React.ReactNode, size: number) => (
    <span style={{ fontWeight: 'bold', fontSize: size }}>{value}</span>
);

export default JumboStats;

const getSizeInPixel = (size: Size) => {
    switch (size) {
        case 'large':
            return {
                container: 150,
                label: 18,
                value: 37,
            };
        case 'medium':
            return {
                container: 90,
                label: 15,
                value: 22,
            };
        default:
            return {
                container: 150,
                label: 18,
                value: 37,
            };
    }
};
