import React from 'react';
import StarRatingComponent from 'react-star-rating-component';

const Stars = props => {
    const { t } = props;
    const rating = props.rating ? props.rating : 0;
    const stars = props.rating ? (props.rating / 100) * 5 : 0;
    return (
        <div
            className={props.className}
            title={t('star_rating.stars_title', { rating: rating.toFixed(2), stars: stars.toFixed(2) })}>
            <StarRatingComponent
                name="busrating"
                editing={false}
                starCount={5}
                // plus one needed for half icons
                value={stars ? stars : 0}
                // strictly greater instead of greater equals because of the plus one above
                renderStarIcon={(index, value) => <span className={index <= value ? 'fa fa-star' : 'fa fa-star-o'} />}
                // only render half icon if fraction >= 0.5
                renderStarIconHalf={(index, value) => (
                    <span className={index - value < 0.5 ? 'fa fa-star-half-full' : 'fa fa-star-o'} />
                )}
                starColor="#FFB400"
                emptyStarColor="#FFB400"
            />
            &nbsp;
        </div>
    );
};

export default Stars;
