import _ from 'lodash';
import { Divider, MenuItem } from 'material-ui';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { disableFormSubmit } from 'src/actions/form-submit';
import { searchRatioCustomers } from 'src/actions/ratio';
import { renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';

class CustomerAdministrationImportForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.props.disableFormSubmit(true);
    }

    componentWillUnmount() {
        this.props.disableFormSubmit(false);
    }

    componentDidUpdate() {
        /* Disable form if the operator did not select a customer and chose a customer group for said customer */
        if (this.props.selectedCustomer && this.props.customerGroupId) {
            this.props.disableFormSubmit(false);
        } else if (!this.props.selectedCustomer || !this.props.customerGroupId) {
            this.props.disableFormSubmit(true);
        }
    }

    handleSearchKeyword(keyword) {
        this.props.searchRatioCustomers(keyword);
    }

    render() {
        const { handleSubmit, searchRatioCustomers, keyword, t, customerGroups } = this.props;

        return (
            <form className="form-horizontal" onSubmit={e => e.preventDefault()}>
                <div className="row" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div className="col-md-5">
                        <Field name="keyword" label={t('common_phrases.keyword')} component={renderInput} />
                    </div>
                    <div className="col-md-2">
                        <button
                            className="btn btn-link"
                            onClick={() => {
                                this.handleSearchKeyword(keyword);
                            }}
                            type="button">
                            {t('common_phrases.search')}
                        </button>
                    </div>
                    <div className="alert alert-warning col-md-5" style={{ marginBottom: 0, fontSize: '14px' }}>
                        <p>{t('customer_details.import_hint')}</p>
                    </div>
                </div>
                <Divider style={{ marginTop: 20 }} />
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerGroupId"
                            label={`${t('customer_group.group')} *`}
                            component={renderSelectField}>
                            {this.getCustomerGroupMenuItems()}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="transferNumber"
                            label={t('contact_data.transfer_number')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }

    getCustomerGroupMenuItems() {
        const { t, customerGroups } = this.props;

        return _.map(customerGroups.items, customerGroup => (
            <MenuItem
                key={customerGroup.id}
                value={customerGroup.id}
                primaryText={t(`customer_groups.id.${customerGroup.id}`)}
            />
        ));
    }
}

const selector = formValueSelector('customerAdministrationImportForm');

export default connect(
    state => ({
        keyword: selector(state, 'keyword'),
        customerGroupId: selector(state, 'customerGroupId'),
    }),
    {
        searchRatioCustomers,
        disableFormSubmit,
    },
    null,
    { withRef: true },
)(
    reduxForm({
        form: 'customerAdministrationImportForm',
    })(CustomerAdministrationImportForm),
);
