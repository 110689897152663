import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { asyncRequest, TOMCAT_URL } from 'src/common/index';
import { withTranslation } from 'react-i18next';

class Logins extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            loginAttempts: undefined,
        };
    }

    componentDidMount() {
        asyncRequest(`${TOMCAT_URL}super/logins/login-attempts`).then(response =>
            this.setState({
                loginAttempts: response.json,
            }),
        );
    }

    unblock(row) {
        asyncRequest({
            method: 'POST',
            endpoint: `${TOMCAT_URL}super/logins/unblock`,
            body: {
                ip: row.ip,
                username: row.username ? row.username : undefined,
            },
        }).then(response =>
            this.setState({
                loginAttempts: response.json,
            }),
        );
    }

    render() {
        const { t } = this.props;
        const { loginAttempts } = this.state;
        const tableData = [];

        if (loginAttempts && loginAttempts.byIP) {
            loginAttempts.byIP.map((attemptInfo, index) => {
                tableData.push({
                    id: index,
                    ip: attemptInfo[0],
                    username: '',
                    attempts: attemptInfo[1],
                });
            });
        }
        if (loginAttempts && loginAttempts.byUsername) {
            loginAttempts.byUsername.map((attemptInfo, index) => {
                tableData.push({
                    id: index,
                    ip: '',
                    username: attemptInfo[0],
                    attempts: attemptInfo[1],
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('login_trials')}</legend>

                <div className="col-md-12">
                    <BootstrapTable
                        ref={ref => (this.table = ref)}
                        data={tableData}
                        exportCSV={true}
                        striped={true}
                        hover={true}
                        condensed={true}
                        pagination={true}
                        searchPlaceholder={t('common_phrases.search')}
                        options={{
                            sizePerPageList: [10, 50, 100, 500],
                            exportCSVText: t('common_phrases.export_list'),
                            noDataText: t('task_details.no'),
                            clearSearch: true,
                            sizePerPage: 50,
                            page: 1,
                        }}
                        search={true}>
                        <TableHeaderColumn columnTitle={true} dataField="id" isKey={true} export={true} hidden={true}>
                            {t('common_phrases.id')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="ip" dataSort={true}>
                            {t('task_details.ip')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="username" dataSort={true}>
                            {t('contact_data.username')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="attempts" dataSort={true}>
                            {t('task_details.attempts')}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="actions" dataFormat={this.tableActionsFormatter}>
                            {t('user_functionality.reset')}
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }

    tableActionsFormatter(cell, row) {
        return (
            <button type="button" className="btn btn-xs transparent" onClick={() => this.unblock(row)}>
                <span className="fa fa-eraser text-info" />
            </button>
        );
    }
}

export default withTranslation()(Logins);
