import i18next from 'i18next';
import Cookies from 'js-cookie';
import Backend from 'i18next-xhr-backend';
import de_translation from 'src/i18n/locales/de/translations.json';
import en_translation from 'src/i18n/locales/en/translations.json';
import cs_translation from 'src/i18n/locales/cs/translations.json';
import sk_translation from 'src/i18n/locales/sk/translations.json';
import moment from 'moment';

export interface Language {
    code: string;
    label: string;
}

/**
 * An array of all languages supported in the operating tool.
 */
export const LANGUAGES = [
    {
        code: 'de',
        label: 'Deutsch',
    },
    {
        code: 'en',
        label: 'English',
    },
    {
        code: 'cs',
        label: 'Český',
    },
    {
        code: 'sk',
        label: 'Slovensky',
    },
];

/**
 * An array of all languages supported in the busfinder frontend.
 */
export const FINDER_LANGUAGES = [
    {
        code: 'de',
        label: 'Deutsch',
    },
    {
        code: 'en',
        label: 'English',
    },
    {
        code: 'fr',
        label: 'Français',
    },
    {
        code: 'it',
        label: 'Italiano',
    },
    {
        code: 'nl',
        label: 'Nederlands',
    },
    {
        code: 'sv',
        label: 'Svenska',
    },
    {
        code: 'sk',
        label: 'Slovensky',
    },
    {
        code: 'pl',
        label: 'Polski',
    },
    {
        code: 'cs',
        label: 'Český',
    },
];

/**
 * An array of all regions supported in the busfinder frontend.
 */
export const FINDER_REGIONS = ['AT', 'DE', 'NL', 'FR', 'BE', 'CH', 'CZ', 'GB', 'IT', 'LI', 'LU', 'PL', 'SE', 'SK'];

/**
 * The language to use when the language cannot be determined, or a translation is missing.
 */
export const FALLBACK_LANGUAGE: string = 'de';

export const LOCALE_COOKIE = 'BUSFINDER_LOCALE';

/**
 * When the language changes:
 *
 * - save the language to the local storage
 * - change the moment locale
 *
 * https://www.i18next.com/overview/api#onlanguagechanged
 * https://momentjs.com/docs/#/i18n/changing-locale/
 */
export const onLanguageChanged = (locale: string): void => {
    const language: string = extractLanguage(locale);
    Cookies.set(LOCALE_COOKIE, language, { secure: true, sameSite: 'none' });
    moment.locale(language);
};

i18next
    .use(Backend)
    .init(
        // i18next Configuration
        // https://www.i18next.com/overview/configuration-options
        {
            load: 'languageOnly',
            fallbackLng: FALLBACK_LANGUAGE,
            lng: FALLBACK_LANGUAGE,
            ns: ['translations'],
            defaultNS: 'translations',

            debug: process.env.NODE_ENV !== 'production',

            react: {
                wait: true, // Wait until loaded in every translated HOC
                useSuspense: true,
            },
            interpolation: {
                escapeValue: false, // Don't escape values; we have no user-submitted translations
            },
            resources: {
                de: { translations: de_translation },
                en: { translations: en_translation },
                cs: { translations: cs_translation },
                sk: { translations: sk_translation },
            },
        },
    )
    .then(r => {});

i18next.on('languageChanged', onLanguageChanged);

/**
 * Extract the first language part from a locale string, e.g. returns 'en' for 'en-US'.
 */
export const extractLanguage = (locale: string): string => {
    if (locale === 'cimode') return locale;

    if (locale != null) {
        const language = locale.split('-')[0];
        if (LANGUAGES.find((lng: Language) => lng.code === language)) return language;
    }
    return FALLBACK_LANGUAGE;
};

/**
 * Get the current language part from i18next.
 */
export const currentLanguage = (): string => extractLanguage(i18next.language);

export default i18next;

/**
 * Returns the language label of the given code or {@link FALLBACK_LANGUAGE} if not found.
 * This methods searches all supported languages of the bus-einsatztool project
 * @param code 2 letter language code
 */
export const getSupportedLanguageLabel = (code: string): string => {
    const desiredLanguage = LANGUAGES.find((language: Language) => language.code === code);

    if (desiredLanguage) return desiredLanguage.label;

    return FALLBACK_LANGUAGE;
};

/**
 * Returns the language label of the given code or '-' if not found.
 * This methods searches all supported languages of the bus-finder project
 * @param code 2 letter language code
 */
export const getFinderLanguageLabel = (code: string): string => {
    const desiredLanguage = FINDER_LANGUAGES.find((language: Language) => language.code === code);

    if (desiredLanguage) return desiredLanguage.label;

    return '-';
};
