import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import { dateTimeFormatter, expiredFormatter, percentageFormatter } from 'src/utils/formatters';
import ErrorMessage from 'src/components/misc/error-message';
import TicketEventModal from 'src/components/admin/event-tickets/events/ticket-event-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import _ from 'lodash';
import { Entities } from 'src/common/index';
import {
    calculateAvailableTickets,
    calculateAvailableTicketsPercentages,
    getExpiredTickets,
} from 'src/components/event-tickets/calculations';
import { withTranslation } from 'react-i18next';

class TicketEventAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({ onRowClick: this.onSelectEvent });
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchTicketEventsJoinFetchTickets();
        this.props.fetchAll(Entities.TICKET_EVENT_CATEGORY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { ticketEvents } = nextProps;

        if (ticketEvents.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(ticketEvents.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.ticketEvents, this.props.ticketEvents, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { ticketEvents, eventCategories, t } = this.props;
        const finishFetching = this.state.startedFetching && !ticketEvents.isFetching;

        const tableData = [];
        if (finishFetching && ticketEvents.items.length > 0) {
            ticketEvents.items.map(ticketEvent => {
                let totalTickets = 0,
                    soldTickets = 0,
                    expiredTickets = 0;

                if (ticketEvent.ticketCollections && ticketEvent.ticketCollections.length > 0)
                    ticketEvent.ticketCollections.forEach(ticketCollection => {
                        totalTickets += ticketCollection.quantity;
                        soldTickets += ticketCollection.countSold;
                        expiredTickets += getExpiredTickets(ticketCollection);
                    });

                tableData.push({
                    id: ticketEvent.id,
                    name: ticketEvent.name,
                    category: ticketEvent.eventCategory.name,
                    beginDateTime: ticketEvent.beginDateTime,
                    isExpired: ticketEvent.expired,
                    location: ticketEvent.location.address,
                    totalTickets: totalTickets,
                    soldTickets: soldTickets,
                    expiredTickets: expiredTickets,
                    availableTickets: calculateAvailableTickets(totalTickets, soldTickets, expiredTickets),
                    availablePercentage: calculateAvailableTicketsPercentages(
                        totalTickets,
                        soldTickets,
                        expiredTickets,
                    ),
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('common_phrases.events')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            if (!eventCategories || _.size(eventCategories.items) < 1) {
                                this.props.openModal({
                                    component: ConfirmDialog,
                                    componentProps: {
                                        bodyText: t('events.error_category'),
                                        proceedText: t('common_phrases.ok'),
                                        proceedButtonColor: ButtonColors.NOTIFY,
                                        isNotification: true,
                                    },
                                    title: t('common_phrases.attention'),
                                    noButtons: true,
                                });
                            } else {
                                this.table.reset();
                                this.props.openModal({
                                    component: TicketEventModal,
                                    componentProps: {
                                        isCreate: true,
                                    },
                                    title: t('new_event'),
                                    mandatoryFields: true,
                                });
                            }
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('new_event')}
                    </button>
                </legend>

                {(() => {
                    if (ticketEvents.error) return <ErrorMessage object={ticketEvents} />;
                    else
                        return (
                            <div className="clickableTable">
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                        {t('common_phrases.name')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="beginDateTime"
                                        dataFormat={dateTimeFormatter}
                                        width="185"
                                        dataSort={true}>
                                        {t('events.beginning')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="isExpired"
                                        dataFormat={cell => expiredFormatter(t, cell)}
                                        width="90"
                                        dataAlign="center"
                                        dataSort={true}>
                                        {t('events.status')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="location" dataSort={true}>
                                        {t('events.location_event')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="category" dataSort={true}>
                                        {t('common_phrases.category')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalTickets"
                                        width="120"
                                        dataAlign="right"
                                        dataSort={true}>
                                        {t('events.tickets')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="soldTickets"
                                        width="120"
                                        dataAlign="right"
                                        dataSort={true}>
                                        {t('events.sold')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="expiredTickets"
                                        width="120"
                                        dataAlign="right"
                                        dataSort={true}>
                                        {t('events.expired')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="availableTickets"
                                        width="120"
                                        dataAlign="right"
                                        dataSort={true}>
                                        {t('events.available')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="availablePercentage"
                                        dataFormat={percentageFormatter}
                                        dataAlign="right"
                                        width="120"
                                        dataSort={true}>
                                        {t('events.available_percentage')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    onSelectEvent(event) {
        this.props.push('/admin/event-tickets/events/' + event.id);
    }
}

export default withTranslation()(composeModalActions(TicketEventAdministration));
