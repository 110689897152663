import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
    asyncCheckDuplicate,
    iconMenuItems,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class TripAttributeForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            component={renderInput}
                            props={{
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="icon"
                            label={`${t('symbol')} *`}
                            component={renderSelectField}
                            props={{
                                disabled: true,
                            }}>
                            {iconMenuItems()}
                        </Field>
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.icon) errors.icon = t('error_missing.fill_in_symbol');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.TRIP_ATTRIBUTE.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

TripAttributeForm = reduxForm({
    form: 'TripAttributeForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(TripAttributeForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, tripAttribute } = ownProps;

    let initialValues = {};

    if (!isCreate && tripAttribute)
        initialValues = {
            id: tripAttribute.id,
            name: tripAttribute.name,
            icon: tripAttribute.icon,
            visible: tripAttribute.visible,
        };

    return {
        initialValues: initialValues,
    };
};

TripAttributeForm = connect(mapStateToProps, null, null, { withRef: true })(TripAttributeForm);

export default TripAttributeForm;
