import { Entities, getEntities, search } from 'src/common/index';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import TableHelper from 'src/utils/table-helper';
import { genderName } from 'src/utils/constants';
import { dateFormatterShort, statusFormatter, verifiedFormatter } from '../accounts/admin-customer-administration';

class OrganizationUsersModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
    }

    componentDidMount() {
        const { organizationName, search } = this.props;
        search(Entities.CUSTOMER, `findByOrganizationName?organizationName=${organizationName}`, 0, 'verification');
    }

    render() {
        const { customers, t, customerGroups } = this.props;

        const tableData = customers.items.map(customer => ({
            id: customer.id,
            email: customer.email,
            fullName: customer.contactData.fullName,
            gender: customer.contactData.gender,
            address: customer.contactData.address,
            registered: customer.registered,
            verified: customer.verified,
            verifiedAt: customer.verifiedAt,
            verifiedBy: customer.verifiedBy,
            verificationComment: customer.verificationComment,
            subscribedToNewsletter: customer.subscribedToNewsletter,
            registeredAt: customer.registeredAt,
        }));

        return (
            <div className="row">
                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={tableData}
                    striped
                    hover
                    condensed
                    pagination
                    search
                    options={Object.assign(
                        {},
                        {
                            toolBar: this.createCustomToolBar,
                        },
                        this.tableHelper.getOptions(!customers.isFetching),
                    )}
                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}>
                    <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true} />
                    <TableHeaderColumn columnTitle={true} dataField="email" width="200" dataSort={true}>
                        {t('contact_data.username')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="gender"
                        dataSort={true}
                        width="70"
                        dataFormat={genderName}
                        csvFormat={genderName}>
                        {t('contact_data.gender')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="fullName" width="180" dataSort={true}>
                        {t('common_phrases.name')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                        {t('customer_details.address')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="registeredAt"
                        width="120"
                        dataSort={true}
                        dataFormat={(date, row) => (row.registered ? dateFormatterShort(date, t) : '')}
                        csvFormat={(date, row) => (row.registered ? dateFormatterShort(date, t) : '')}>
                        {t('contact_data.registration_date')}
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="registered" width="90" dataSort={true} dataFormat={statusFormatter}>
                        {t('customer_details.registered')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="verified"
                        width="90"
                        dataSort={true}
                        dataFormat={verifiedFormatter(t)}>
                        {t('customer_details.verified')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="subscribedToNewsletter"
                        width="90"
                        dataSort={true}
                        dataFormat={statusFormatter}>
                        {t('customer_details.newsletter')}
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    createCustomToolBar(props) {
        const { t } = this.props;
        return (
            <div className="col-md-12">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h4>
                        {t('customer_count', {
                            count: this.props.customers.items.length,
                        })}
                    </h4>
                    <div className="pull-right" style={{ width: 400 }}>
                        {props.components.searchPanel}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: getEntities(state, Entities.CUSTOMER),
    };
};

export default connect(mapStateToProps, { search })(OrganizationUsersModal);
