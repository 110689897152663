import React, { Component } from 'react';
import { dashIfNull } from 'src/common/index';

export default class PaymentLogsButton extends Component {
    render() {
        const { paymentLogs, t } = this.props;
        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                style={{ paddingLeft: '0' }}
                onClick={() => {
                    this.props.openModal({
                        component: () => (
                            <table style={{ width: '100%', fontFamily: 'monospace' }}>
                                <tr style={{ borderBottom: '1px solid gray' }}>
                                    <th>{t('tasks.payment_logs.modifiedAt')}</th>
                                    <th>{t('tasks.payment_logs.paymentMethod')}</th>
                                    <th>{t('tasks.payment_logs.status')}</th>
                                    <th>{t('tasks.payment_logs.transactionId')}</th>
                                </tr>
                                {paymentLogs.map(paymentLog => (
                                    <tr>
                                        <td>{paymentLog.modifiedAt}</td>
                                        <td>{dashIfNull(paymentLog.paymentMethod)}</td>
                                        <td>{dashIfNull(paymentLog.status)}</td>
                                        <td>{dashIfNull(paymentLog.transactionId)}</td>
                                    </tr>
                                ))}
                            </table>
                        ),
                        componentProps: {
                            dialogStyle: {
                                width: '110%',
                                maxWidth: 'none',
                            },
                        },
                        title: t('tasks.payment_logs.title'),
                        cancelButtonLabel: t('common_phrases.ok'),
                        singleButton: true,
                    });
                }}>
                <span className="glyphicon glyphicon-info-sign text-info" title={t('tasks.payment_logs.title')} />
                &nbsp;{t('tasks.payment_logs.title')}
            </button>
        );
    }
}
