import SearchBusesRequestStats from './SearchBusesRequestStats';

export default class AdminSearchBusesRequestStats {
    public all: SearchBusesRequestStats;
    public main: SearchBusesRequestStats;
    public integrations: SearchBusesRequestStats;

    constructor(json: any) {
        this.all = new SearchBusesRequestStats(json.all);
        this.main = new SearchBusesRequestStats(json.main);
        this.integrations = new SearchBusesRequestStats(json.integrations);
    }
}
