import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { ButtonColors } from 'src/utils/constants';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import EquipmentEditModal from 'src/components/admin/equipments/equipment-edit-modal';
import { withTranslation } from 'react-i18next';

class AdminEquipmentAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BUS_EQUIPMENT);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { equipments } = nextProps;

        if (equipments.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(equipments.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.equipments, this.props.equipments, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { equipments, t } = this.props;
        const finishFetching = this.state.startedFetching && !equipments.isFetching;

        const tableData = [];
        if (finishFetching && equipments.items.length > 0) {
            equipments.items.map(equipment => {
                tableData.push({
                    id: equipment.id,
                    name: equipment.name,
                    rating: equipment.rating,
                    bestOption: equipment.bestOption,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('equipment.equipment')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: EquipmentEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('equipment.new_equipment'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('equipment.new_equipment')}
                    </button>
                </legend>

                {(() => {
                    if (equipments.error) return <ErrorMessage object={equipments} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="rating" dataSort={true}>
                                    {t('common_phrases.rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="bestOption"
                                    dataSort={true}
                                    dataFormat={this.statusFormatter}>
                                    {t('equipment.best_option')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    statusFormatter(cell) {
        if (cell) return <span className="fa fa-check-circle-o text-success fa-lg" />;
        else return <span />;
    }

    onProceedDeleteBusEquipment(equipment) {
        this.props.deleteBusEquipment(equipment.id);
    }

    openConfirmDeleteDialog(equipment) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: equipment,
                onProceedDialog: this.onProceedDeleteBusEquipment,
                bodyText: t('equipment.confirm_delete'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: equipment.name,
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, equipment) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: EquipmentEditModal,
                            componentProps: {
                                id: equipment.id,
                                isCreate: false,
                            },
                            title: equipment.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => this.openConfirmDeleteDialog(equipment)}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminEquipmentAdministration));
