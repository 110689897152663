import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import CircularProgress from 'material-ui/CircularProgress';
import { renderSelectField } from 'src/components/misc/redux-form-helpers';
import MenuItem from 'material-ui/MenuItem';
import { assignFleetCompanies } from 'src/actions/fleets';

class FleetCompanyForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            companies: {
                isFetching: true,
                error: undefined,
                items: [],
            },
        };
    }

    componentDidMount() {
        asyncRequest(
            `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/search/findAvailableByFleet?fleetId=${this.props.fleet.id}`,
        )
            .then(response => {
                const json = response.json;
                if (!json || !json._embedded || !json._embedded['bus-companies'])
                    throw Error('Invalid Response not containing any companies');

                this.setState({
                    companies: {
                        isFetching: false,
                        error: undefined,
                        items: json._embedded['bus-companies'],
                    },
                });
            })
            .catch(error => {
                this.setState({
                    companies: {
                        isFetching: false,
                        error: error.message ? error.message : error,
                        items: [],
                    },
                });
            });
    }

    render() {
        const { handleSubmit, selectedCompanies, t } = this.props;

        if (this.state.companies.isFetching) return <CircularProgress />;

        const companyMenuItems = this.state.companies.items.map(value => {
            return (
                <MenuItem
                    key={value.id}
                    value={value.id}
                    checked={selectedCompanies && selectedCompanies.indexOf(value.id) > -1}
                    primaryText={value.companyName}
                />
            );
        });

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="alert alert-info">
                    <span>{t('fleets.info_companies')}</span>
                </div>
                {companyMenuItems.length === 0 ? (
                    <div className="alert alert-danger">
                        <span>{t('fleets.info_no_company')}</span>
                    </div>
                ) : (
                    <Field
                        name="companies"
                        label={t('common_phrases.company')}
                        multiple
                        selectionRenderer={this.companySelectionRender}
                        component={renderSelectField}>
                        {companyMenuItems}
                    </Field>
                )}
            </form>
        );
    }

    companySelectionRender(companyIds) {
        return (
            <span>
                {companyIds.length} {this.props.t('fleets.selected_company')}
            </span>
        );
    }
}

const validate = (values, props) => {
    const errors = {};

    return errors;
};

FleetCompanyForm = reduxForm({
    form: 'FleetCompanyForm',
    validate,
})(FleetCompanyForm);

const mapStateToProps = (state, ownProps) => {
    const { fleet } = ownProps;

    return {
        initialValues: {
            id: fleet.id,
            companies: fleet.companies.map(company => company.id),
        },
        selectedCompanies: selector(state, 'companies'),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: data => {
            dispatch(assignFleetCompanies(data.id, data.companies));
            ownProps.handleClose();
        },
    };
};

const selector = formValueSelector('FleetCompanyForm');
FleetCompanyForm = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FleetCompanyForm);

export default FleetCompanyForm;
