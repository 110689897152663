import React from 'react';
import ComponentDialog from 'src/components/misc/modal-callers';

export interface ModalProps {
    component?: React.ComponentClass;
    componentProps?: any;
    title?: React.ReactNode;
    successButtonLabel?: string;
    cancelButtonLabel?: string;
    cancelCallback?: (props: any) => void;
    singleButton?: boolean;
    noButtons?: boolean;
    mandatoryFields?: boolean;
}

export interface InjectedModalProps {
    openModal: (props: ModalProps) => void;
    modal: ModalComponentState;
}

interface ModalComponentState extends ModalProps {
    open: boolean;
}

interface State {
    modal: ModalComponentState;
}

export default function composeModalActions<P, S>(ChildComponent: React.ComponentClass<P & any, S>) {
    class ModalComponent extends React.Component<P, State> {
        constructor(props: P) {
            super(props);

            this.state = {
                modal: {
                    component: undefined,
                    componentProps: undefined,
                    title: undefined,
                    open: false,
                },
            };
        }

        public render() {
            return (
                <div>
                    {this.state.modal.open && <ComponentDialog handleClose={this.handleClose} {...this.state.modal} />}
                    <ChildComponent {...this.props} {...this.state} openModal={this.openModal} />
                </div>
            );
        }

        private openModal = (props: ModalProps) => {
            this.setState({
                modal: {
                    component: props.component,
                    componentProps: props.componentProps,
                    title: props.title,
                    successButtonLabel: props.successButtonLabel,
                    cancelButtonLabel: props.cancelButtonLabel,
                    cancelCallback: props.cancelCallback,
                    singleButton: props.singleButton,
                    noButtons: props.noButtons,
                    mandatoryFields: props.mandatoryFields,
                    open: true,
                },
            });
        };

        private handleClose = () => {
            this.setState({
                modal: {
                    component: undefined,
                    componentProps: undefined,
                    title: undefined,
                    open: false,
                },
            });
        };
    }

    return ModalComponent;
}
