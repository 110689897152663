import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { selectEvent } from 'src/actions/events';
import { addNotification } from 'src/actions/notifications';
import { duplicateTask, fetchTasksByCompany, setFetchMethodOnError } from 'src/actions/tasks';
import OperatingTool from 'src/components/operatingtool/operating-tool';
import { getOwnCompany, getTaskTypeColors } from 'src/selectors/bus-companies';
import { getActiveDrivers } from 'src/selectors/drivers';
import { getEvents } from 'src/selectors/calendar-events';
import { getAllResourcesForCalendar } from 'src/selectors/calendar-resources';
import { getDetails } from 'src/selectors/details';

const mapStateToProps = state => {
    return {
        // get all resources for calendar
        resources: getAllResourcesForCalendar(state),
        // all events with appropriate properties (blockages, releases, tasks) for the calendar
        events: getEvents(state),
        // get all tasks
        tasks: getEntities(state, Entities.BUS_COMPANY),
        selectedCompany: state.selectedCompany,
        busCompany: getOwnCompany(state),
        drivers: getActiveDrivers(state),
        eventDetails: getDetails(state),
        taskTypeColors: getTaskTypeColors(state),
        reviews: state.entities[Entities.REVIEW.repository],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCalendarRelatedEntities: selectedCompany => {
            dispatch(selectEvent(''));
            dispatch(fetchByCompanyId(Entities.BUS_CATEGORY, selectedCompany));
            dispatch(fetchByCompanyId(Entities.BUS, selectedCompany));
            dispatch(fetchByCompanyId(Entities.BLOCKAGE, selectedCompany));
            dispatch(fetchByCompanyId(Entities.RELEASE, selectedCompany));
            dispatch(fetchTasksByCompany(selectedCompany));
            dispatch(fetchOwnBusCompany(selectedCompany));
        },
        fetchOwnBusCompany: selectedCompany => {
            dispatch(fetchOwnBusCompany(selectedCompany));
        },
        fetchTasksByCompany: selectedCompany => {
            dispatch(selectEvent(''));
            dispatch(fetchTasksByCompany(selectedCompany));
        },
        fetchByCompanyId: (entity, selectedCompany) => {
            dispatch(fetchByCompanyId(entity, selectedCompany));
        },
        patch: (body, entity, reducerIndex) => {
            dispatch(patch(body, entity, reducerIndex));
        },
        deleteById: (id, entity, reducerIndex) => {
            dispatch(deleteById(id, entity, reducerIndex));
            dispatch(selectEvent(''));
        },
        duplicateTask: (taskId, busCompanyId) => {
            dispatch(duplicateTask(taskId, busCompanyId));
        },
        selectEvent: event => {
            dispatch(selectEvent(event));
        },
        setFetchMethodOnError: fetchMethod => {
            dispatch(setFetchMethodOnError(fetchMethod));
        },
        addNotification: (title, message, level, position) => {
            dispatch(addNotification(title, message, level, position));
        },
    };
};

const OperatingToolContainer = connect(mapStateToProps, mapDispatchToProps)(OperatingTool);

export default OperatingToolContainer;
