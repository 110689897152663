import { TOMCAT_URL } from 'src/common/index';
import _ from 'lodash';
import Divider from 'material-ui/Divider';
import { List, ListItem } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import CustomGoogleMap from 'src/components/maps/custom-google-map';
import busLogo from 'static/bus.png';

class BusLocationMap extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            busesByLocation: this.getUniqueLocationsWithBuses(props.companiesWithBuses),
            companiesByLocation: this.getUniqueLocationsForCompanies(props.companiesWithBuses),
        };
    }

    componentWillMount() {
        this.props.setBusLocationCount(this.state.busesByLocation.length);
        this.props.setCompanyLocationCount(this.state.companiesByLocation.length);
    }

    componentWillReceiveProps(nextProps) {
        const { companiesWithBuses } = nextProps;

        if (!_.isEqual(companiesWithBuses, this.props.companiesWithBuses))
            this.setState({
                busesByLocation: this.getUniqueLocationsWithBuses(companiesWithBuses),
                companiesByLocation: this.getUniqueLocationsForCompanies(companiesWithBuses),
            });
    }

    render() {
        return (
            <CustomGoogleMap
                containerElement={<div style={{ height: `450px` }} />}
                mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                clustering={this.props.clustering}
                markers={
                    this.props.showBuses
                        ? this.state.busesByLocation.map(marker => {
                              return {
                                  position: { lat: marker.location.lat, lng: marker.location.lng },
                                  infoWindowContent: this.getInfoWindowContent(marker),
                              };
                          })
                        : this.props.showCompanies
                          ? this.state.companiesByLocation.map(marker => {
                                return {
                                    position: { lat: marker.location.lat, lng: marker.location.lng },
                                    infoWindowContent: this.getInfoWindowContent(marker),
                                };
                            })
                          : []
                }
            />
        );
    }

    /**
     * renders the maps marker info window
     * with the count of buses by each company
     * @param marker
     * @returns {XML}
     */
    getInfoWindowContent(marker) {
        let sumBuses = 0;

        return (
            <div className="info-window">
                <List>
                    <Subheader>{marker.location.address}</Subheader>
                    {marker.companies.map(company => {
                        sumBuses += company.buses;

                        return (
                            <ListItem
                                key={company.id}
                                disabled={true}
                                primaryText={
                                    <div>
                                        <div className="col-md-10 text-overflow">{company.companyName}</div>
                                        <div className="col-md-2">{company.buses}</div>
                                    </div>
                                }
                                leftIcon={
                                    <img
                                        style={{ width: 'auto', height: '25px' }}
                                        src={
                                            company.logo
                                                ? `${TOMCAT_URL}public/documents/${company.logo.filename}`
                                                : busLogo
                                        }
                                        alt={`${company.companyName} Logo`}
                                    />
                                }
                                style={{
                                    paddingLeft: '70px',
                                    paddingRight: '0px',
                                    fontSize: '12px',
                                }}
                            />
                        );
                    })}
                </List>
                <br />
                <Divider />
                <List>
                    <ListItem
                        key="summary-bus-count"
                        disabled={true}
                        primaryText={<strong>{`Busse: ${sumBuses}`}</strong>}
                    />
                </List>
            </div>
        );
    }

    /**
     * Generates an array of unique bus locations as a key.
     * the value will be a list of companies with the related count of active buses
     * @param companiesWithBuses
     * @returns {Array}
     */
    getUniqueLocationsWithBuses(companiesWithBuses) {
        const busesByLocation = [];

        companiesWithBuses.forEach(company => {
            company.buses
                .filter(bus => bus.active)
                .filter(bus => (this.props.omniPlusON ? bus.externalBusId : true))
                .forEach(bus => {
                    const indexOfBusWithSameLocation = busesByLocation.findIndex(entity =>
                        _.isEqual(entity.location, bus.homeBase),
                    );

                    // add to existing location
                    if (indexOfBusWithSameLocation !== -1) {
                        const sameCompanyIndex = busesByLocation[indexOfBusWithSameLocation].companies.findIndex(
                            c => c.id === company.id,
                        );

                        // increase bus count of existing company
                        if (sameCompanyIndex !== -1) {
                            busesByLocation[indexOfBusWithSameLocation].companies[sameCompanyIndex].buses++;

                            // add new company with bus to existing location
                        } else {
                            busesByLocation[indexOfBusWithSameLocation].companies.push({
                                id: company.id,
                                companyName: company.companyName,
                                logo: company.logo,
                                buses: 1,
                            });
                        }
                    }

                    // add new location
                    else {
                        busesByLocation.push({
                            location: bus.homeBase,
                            companies: [
                                {
                                    id: company.id,
                                    companyName: company.companyName,
                                    logo: company.logo,
                                    buses: 1,
                                },
                            ],
                        });
                    }
                });
        });

        return busesByLocation;
    }

    getUniqueLocationsForCompanies(companiesWithBuses) {
        const companiesByLocation = [];

        companiesWithBuses.forEach(company => {
            const indexOfCompanyWithSameLocation = companiesByLocation.findIndex(entity =>
                _.isEqual(entity.location, company.location),
            );

            // add company to an already existing location
            if (indexOfCompanyWithSameLocation !== -1) {
                companiesByLocation[indexOfCompanyWithSameLocation].companies.push({
                    id: company.id,
                    companyName: company.companyName,
                    logo: company.logo,
                    buses: company.buses.length,
                    apis: company.apis,
                    onlinePaymentEnabled: company.onlinePaymentModuleEnabled,
                    onlinePaymentModuleEnabled: company.onlinePaymentModuleEnabled,
                });
            }

            // add new location
            else {
                companiesByLocation.push({
                    location: company.location,
                    companies: [
                        {
                            id: company.id,
                            companyName: company.companyName,
                            logo: company.logo,
                            buses: company.buses.length,
                            apis: company.apis,
                            onlinePaymentEnabled: company.onlinePaymentModuleEnabled,
                            onlinePaymentModuleEnabled: company.onlinePaymentModuleEnabled,
                        },
                    ],
                });
            }
        });

        return companiesByLocation;
    }
}

export default BusLocationMap;
