import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { SanitizedInnerHTML } from 'src/common/index';

export default class CustomTooltip extends Component {
    render() {
        const iconClassName = this.props.iconClassName ? this.props.iconClassName : 'fa fa-info-circle fa-lg';
        const iconColor = this.props.iconColor || 'rgba(10,113,186,0.6)';
        return (
            <span>
                {this.props.children ? (
                    <span id={'i-for-' + this.props.id} data-tip="" data-for={this.props.id}>
                        {this.props.children}
                    </span>
                ) : (
                    <span
                        className={`custom-tooltip float-left ${
                            this.props.tooltipClassName && this.props.tooltipClassName
                        }`}
                        id={'i-for-' + this.props.id}
                        data-tip=""
                        data-for={this.props.id}>
                        <em className={iconClassName} style={{ color: iconColor }} />
                    </span>
                )}

                <ReactTooltip
                    {...this.props}
                    id={this.props.id}
                    globalEventOff="click"
                    place={this.props.place ? this.props.place : 'top'}
                    type="dark"
                    delayShow={250}
                    effect="solid">
                    {this.props.text.type ? (
                        this.props.text
                    ) : (
                        <SanitizedInnerHTML element="span" html={this.props.text} />
                    )}
                </ReactTooltip>
            </span>
        );
    }
}
