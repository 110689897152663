import { createSelector } from 'reselect';

import { getOwnAccount } from 'src/selectors/accounts';
import { defaultState, Entities, UserRole } from 'src/common/index';

const getStateOfBusCompanies = state => state.busCompanies[0] || defaultState();
const getStateOfTripCompanies = state => state.tripCompanies[0] || defaultState();

export const getBusCompanyEntities = state => state.entities[Entities.BUS_COMPANY.repository];
export const getTripCompanyEntities = state => state.entities[Entities.TRIP_COMPANY.repository];

export const getSelectedCompany = state => state.selectedCompany;

export const getOwnCompany = createSelector(
    [getStateOfBusCompanies, getBusCompanyEntities, getStateOfTripCompanies, getTripCompanyEntities, getOwnAccount],
    (stateOfBusCompanyEntity, busCompanyEntities, stateOfTripCompanyEntity, tripCompanyEntities, ownAccount) => {
        if (!ownAccount.entity) {
            return {
                isFetching: false,
                action: 'NONE',
                lastUpdated: undefined,
                entity: undefined,
            };
        }

        const stateOfEntity =
            ownAccount.entity.role === UserRole.TRIP_OPERATOR ? stateOfTripCompanyEntity : stateOfBusCompanyEntity;
        const entities = ownAccount.entity.role === UserRole.TRIP_OPERATOR ? tripCompanyEntities : busCompanyEntities;

        return {
            isFetching: stateOfEntity.isFetching,
            action: stateOfEntity.action,
            lastUpdated: stateOfEntity.lastUpdated,
            entity: ownAccount && ownAccount.entity && entities ? entities[ownAccount.entity.company] : undefined,
        };
    },
);

export const getTaskTypeColors = createSelector([getOwnCompany], busCompany => {
    if (busCompany.entity) return busCompany.entity.taskTypeColors;
});
