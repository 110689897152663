import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import BrandEditModal from 'src/components/admin/brands/brand-edit-modal';
import { withTranslation } from 'react-i18next';

class AdminBrandAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BUS_BRAND);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { brands } = nextProps;

        if (brands.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(brands.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.brands, this.props.brands, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { brands, t } = this.props;
        const finishFetching = this.state.startedFetching && !brands.isFetching;

        const tableData = [];
        if (finishFetching && brands.items.length > 0) {
            brands.items.map(brand => {
                tableData.push({
                    id: brand.id,
                    name: brand.name,
                    rating: brand.rating,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('brands.brands')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: BrandEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('brands.new_brand'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('brands.new_brand')}
                    </button>
                </legend>

                {(() => {
                    if (brands.error) return <ErrorMessage object={brands} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('brands.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="rating" dataSort={true}>
                                    {t('common_phrases.rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, brand) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: BrandEditModal,
                            componentProps: {
                                id: brand.id,
                                isCreate: false,
                            },
                            title: brand.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminBrandAdministration));
