import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

class SpecificSearchForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            value: undefined,
            isDisabled: true,
            selectedCompanies: props.subCompanies || [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCompanies.length !== this.state.selectedCompanies.length) {
            if (this.state.selectedCompanies.length === 0) {
                // disable form submit button if no company is selected
                this.props.disableFormSubmit(true);
            } else if (prevState.selectedCompanies.length === 0) {
                // submit button is active because 1 or more companies are selected
                this.props.disableFormSubmit(false);
            }
        }
    }

    render() {
        const { handleSubmit, ownCompanyId, busCompanies, t } = this.props;

        const companyMenuItems = [];
        busCompanies.forEach((value, index) => {
            let disabled;
            if (this.state.selectedCompanies.find(company => company.id === value.id)) disabled = true;

            companyMenuItems.push(
                <MenuItem key={index} value={value.id} disabled={disabled} primaryText={value.companyName} />,
            );
        });

        return (
            <div className="details voffset40">
                <div className="panel panel-default">
                    <div className="panel-heading">{t('companies.release')}</div>
                    <div className="panel-body">
                        <div className="row">
                            <form
                                className="form-horizontal"
                                id="addCompaniesForm"
                                role="form"
                                onSubmit={handleSubmit}
                            />

                            <div className="col-md-6">
                                <div className="col-md-10">
                                    <SelectField
                                        value={this.state.value}
                                        onChange={(event, index, value) => {
                                            this.setState({
                                                value: value,
                                            });
                                        }}
                                        floatingLabelText={t('common_phrases.company')}
                                        fullWidth={true}
                                        hintText={t('companies.select_company')}
                                        floatingLabelFixed={true}>
                                        {companyMenuItems}
                                    </SelectField>
                                </div>
                                <div className="col-md-1 voffset40">
                                    <div className="row">
                                        <button
                                            type="button"
                                            className="btn btn-xs transparent"
                                            onClick={this.handleAddCompany}>
                                            {this.state.value ? (
                                                <span
                                                    className="fa fa-arrow-circle-right fa-2x text-success"
                                                    title={t('companies.add_company')}
                                                />
                                            ) : (
                                                <span
                                                    className="fa fa-arrow-circle-right fa-2x"
                                                    title={t('companies.error_first_select_company')}
                                                />
                                            )}
                                        </button>
                                    </div>
                                    <div className="row">
                                        <button
                                            type="button"
                                            className="btn btn-xs transparent"
                                            onClick={this.handleAddAllCompanies}>
                                            <span
                                                className="fa fa-globe fa-2x text-warning"
                                                title={t('companies.add_all_companies')}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 voffset">
                                <label className="control-label">{t('companies.selected_companies')}</label>
                                <ul className="list-group">
                                    {this.state.selectedCompanies.length === 0 ? (
                                        <span className="error">{t('companies.no_company_selected')}</span>
                                    ) : (
                                        <div>
                                            {this.state.selectedCompanies.map(company => {
                                                return (
                                                    <li key={company.id} className="list-group-item">
                                                        <span>
                                                            {company.label}
                                                            &nbsp;&nbsp;
                                                        </span>

                                                        <span className="glyphicon glyphicon-ok text-success" />

                                                        <button
                                                            type="button"
                                                            disabled={company.id === ownCompanyId}
                                                            title={
                                                                company.id === ownCompanyId &&
                                                                t('companies.company_with_integration')
                                                            }
                                                            className="btn btn-xs transparent pull-right"
                                                            onClick={() => this.handleDeleteCompany(company)}>
                                                            <span className="glyphicon glyphicon-trash" />
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleDeleteCompany(company) {
        // remove the deleted value from the subCompanies array
        this.props.array.remove(
            'subCompanies',
            this.state.selectedCompanies.findIndex(_company => _company.id === company.id),
        );

        if (this.props.initialValues.subCompanies.length === 0) {
            // remove the deleted item from the list and set submit button disabled if no company selected
            this.setState({
                selectedCompanies: _.without(this.state.selectedCompanies, company),
                isDisabled: true,
            });
        }
        // remove the deleted item from the list
        else
            this.setState({
                selectedCompanies: _.without(this.state.selectedCompanies, company),
            });
    }

    handleAddAllCompanies() {
        this.props.array.removeAll('subCompanies');
        this.props.busCompanies.forEach(busCompany => {
            this.props.array.push('subCompanies', busCompany.id);
        });

        this.setState({
            selectedCompanies: this.props.busCompanies.map(busCompany => {
                return {
                    id: busCompany.id,
                    label: busCompany.companyName,
                };
            }),
            value: undefined,
        });
    }

    handleAddCompany() {
        if (this.state.value) {
            const { busCompanies } = this.props;

            const currentSelectedCompany = busCompanies.find(busCompany => busCompany.id === this.state.value);

            if (currentSelectedCompany) {
                // add the selected company to the subCompanies array
                this.props.array.push('subCompanies', currentSelectedCompany.id);

                this.setState({
                    selectedCompanies: this.state.selectedCompanies.concat({
                        id: currentSelectedCompany.id,
                        label: currentSelectedCompany.companyName,
                    }),
                    value: undefined,
                });
            }
        }
    }
}

export default reduxForm({
    form: 'addCompaniesForm',
})(SpecificSearchForm);
