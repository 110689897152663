import AdminSearchBusesRequestStats from 'src/common/entity/request/AdminSearchBusesRequestStats';
import SearchBusesRequestStats from 'src/common/entity/request/SearchBusesRequestStats';
import { TFunction } from 'i18next';
import { CircularProgress } from 'material-ui';
import React, { Component } from 'react';
import { asyncRequest, SingleEntityState, TOMCAT_URL } from 'src/common/index';
import { withTranslation } from 'react-i18next';
import JumboStats from 'src/components/misc/JumboStats';
import ErrorMessage from 'src/components/misc/error-message';
import SearchRequestsTable from 'src/components/search-requests/SearchRequestsTable';
import FileSaver from 'file-saver';

interface State {
    searchRequestStats: SingleEntityState<AdminSearchBusesRequestStats>;
}

class SearchRequestsAdministration extends Component<any, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            searchRequestStats: {
                isFetching: true,
            },
        };
    }

    public componentDidMount() {
        asyncRequest(`${TOMCAT_URL}api/search-buses-requests/admin-stats`)
            .then(response =>
                this.setState({
                    searchRequestStats: {
                        isFetching: false,
                        content: new AdminSearchBusesRequestStats(response.json),
                    },
                }),
            )
            .catch(error =>
                this.setState({
                    searchRequestStats: {
                        isFetching: false,
                        error,
                    },
                }),
            );
    }

    public render() {
        const { t } = this.props;
        const { searchRequestStats } = this.state;
        const finishFetching = !searchRequestStats.isFetching;

        // fetching / error handling
        if (!finishFetching) return <CircularProgress />;
        if (searchRequestStats.error) return <ErrorMessage object={searchRequestStats} />;
        const searchRequestStatsContent = searchRequestStats.content;
        if (!searchRequestStatsContent)
            return <ErrorMessage object={{ error: { status: 500, statusText: t('search_requests.no_statistics') } }} />;

        return (
            <div>
                <legend className="legend">
                    {t('search_requests.title')}
                    <button
                        type="button"
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={this.downloadIntermediatesCSV}>
                        {t('user_functionality.export_intermediates_csv')}
                    </button>
                </legend>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    {/* Main Statistics */}
                    <JumboStats size="medium" values={this.getStats(searchRequestStatsContent.main, t)} />

                    {/* Main Arrow */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="blockhead blockhead--left">
                            <div className="blocktext">{t('title')}</div>
                        </div>
                    </div>

                    {/* Overall Statistics */}
                    <JumboStats values={this.getStats(searchRequestStatsContent.all, t)} />

                    {/* Integration Arrow */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="blockhead blockhead--right">
                            <span className="blocktext">{t('booked_tasks.integrations')}</span>
                        </div>
                    </div>

                    {/* Integration Statistics */}
                    <JumboStats size="medium" values={this.getStats(searchRequestStatsContent.integrations, t)} />
                </div>
                <SearchRequestsTable t={t} />
            </div>
        );
    }

    private getStats = (stats: SearchBusesRequestStats, t: TFunction) => [
        {
            label: t('search_requests.overall'),
            value: stats.sumRequests,
        },
        {
            label: t('search_requests.month'),
            value: stats.sumRequestsCurrentMonth,
        },
    ];

    downloadIntermediatesCSV() {
        asyncRequest({ endpoint: `${TOMCAT_URL}api/admin/intermediates.csv`, headers: { Accept: 'text/csv' } })
            .then(response => {
                response.blob().then((blob: Blob) => {
                    FileSaver.saveAs(blob, 'intermediates.csv');
                });
            })
            .catch(error => {
                console.error('Error downloading intermediates', error);
            });
    }
}

export default withTranslation()(SearchRequestsAdministration);
