import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Entities, fetchByCompanyId, getEntities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import TemplateEditModal from 'src/components/administration/template-edit-modal';
import { withTranslation } from 'react-i18next';

class TemplateAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchByCompanyId(Entities.TEMPLATE, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { templates } = nextProps;

        if (!this.state.startedFetching && templates.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(templates.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.templates, this.props.templates, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { templates, t } = this.props;
        const finishFetching = this.state.startedFetching && !templates.isFetching;

        const tableData = [];

        if (finishFetching && templates.items.length > 0) {
            templates.items.map(nameAndText => {
                tableData.push({
                    id: nameAndText.id,
                    name: nameAndText.name,
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('administration.text_pattern')}</legend>

                {(() => {
                    if (templates.error) return <ErrorMessage object={templates} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, template) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: TemplateEditModal,
                            componentProps: {
                                id: template.id,
                            },
                            title: template.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        templates: getEntities(state, Entities.TEMPLATE, state.selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, { fetchByCompanyId })(composeModalActions(TemplateAdministration)),
);
