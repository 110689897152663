import { Entities, Projection, TOMCAT_URL, UserRole } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';
import { getOwnAccount } from '../selectors/accounts';

export function fetchOwnBusCompany(busCompanyId, forceFetch) {
    return (dispatch, getState) => {
        const ownAccount = getOwnAccount(getState());

        if (busCompanyId && ownAccount.entity && (forceFetch || !getState().busCompanies[0])) {
            const entity =
                ownAccount.entity.role === UserRole.TRIP_OPERATOR ? Entities.TRIP_COMPANY : Entities.BUS_COMPANY;

            return dispatch(
                apiRequest({
                    endpoint: `${TOMCAT_URL}api/${entity.repository}/${busCompanyId}?projection=${Projection.FULL}`,
                    method: 'GET',
                    secondaryActionTypes: [
                        `REQUEST_${entity.action}`,
                        `SUCCESS_FETCH_${entity.action}`,
                        `FAILURE_${entity.action}`,
                    ],
                    reducerIndex: 0,
                    schema: entity.schema,
                }),
            );
        }
    };
}

export function setAndFetchDefaultTaskColorTypes(busCompanyId) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/setDefaultTaskTypeColors?companyId=${busCompanyId}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.BUS_COMPANY.action}`,
                    `SUCCESS_UPDATE_${Entities.BUS_COMPANY.action}`,
                    `FAILURE_${Entities.BUS_COMPANY.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.BUS_COMPANY.schema,
            }),
        );
    };
}
