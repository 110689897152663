import React, { Component } from 'react';

import composeModalActions from 'src/utils/modal-action-wrapper';
import Details from 'src/components/operatingtool/details';
import TaskList from 'src/components/taskadministration/task-list';
import { withTranslation } from 'react-i18next';

/**
 * TaskExchange displays all tasks available to accept from another company
 * with details to the selected task
 */
class TaskExchange extends Component {
    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.setFetchMethodOnError(this.fetchOnError);

        this.props.fetchOwnBusCompany(selectedCompany);
        this.props.fetchTasksReleasedForOwnCompany(selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div id="tasklist-panel" className="col-md-12">
                        <legend className="legend">{t('tasks.offered_tasks')}</legend>

                        <TaskList
                            t={t}
                            isTaskExchangeView={true}
                            taskFilter="FOREIGN_RELEASED_TASKS_PENDING"
                            selectedCompany={this.props.selectedCompany}
                            tasks={this.props.tasks}
                            busCompany={this.props.busCompany}
                            acceptExchangedTask={this.props.acceptExchangedTask}
                            selectEvent={this.props.selectEvent}
                            declineExchangedTask={this.props.declineExchangedTask}
                            fetchTasksReleasedForOwnCompany={this.props.fetchTasksReleasedForOwnCompany}
                            fetchOwnBusCompany={this.props.fetchOwnBusCompany}
                            addNotification={this.props.addNotification}
                            openModal={this.props.openModal}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 detailscontainer">
                        <Details
                            eventDetails={this.props.eventDetails}
                            busCompany={this.props.busCompany}
                            addNotification={this.props.addNotification}
                            openModal={this.props.openModal}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchOnError() {
        this.props.fetchTasksReleasedForOwnCompany(this.props.selectedCompany);
    }
}

export default withTranslation()(composeModalActions(TaskExchange));
