import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { Entities, patch, Projection } from 'src/common/index';
import { setAndFetchDefaultTaskColorTypes } from 'src/actions/bus-companies';
import { isNullOrUndefined } from 'src/utils/helpers';
import { getOwnCompany, getTaskTypeColors } from 'src/selectors/bus-companies';
import OtherSettings from 'src/components/settings/other';
import TasksSettings from 'src/components/settings/tasks';
import { withTranslation } from 'react-i18next';

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.companySettings = {
            id: props.selectedCompany,
            taskTypeColors: props.taskTypeColors,
            defaultTaskPostProcessingTime: props.busCompany.entity.defaultTaskPostProcessingTime,
            bookingLeadTime: props.busCompany.entity.bookingLeadTime,
        };
    }

    handleTaskSettings(taskTypeColors) {
        this.companySettings.taskTypeColors = taskTypeColors;
    }

    handleOtherSettings(settings) {
        this.companySettings.defaultTaskPostProcessingTime = settings.defaultTaskPostProcessingTime;
        this.companySettings.bookingLeadTime = settings.bookingLeadTime;
    }

    render() {
        const { taskTypeColors, selectedCompany, busCompany, t } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 sidebar-header">
                        <h5> {t('global_settings.settings')}</h5>
                    </div>

                    <div className="sidebar-content">
                        {/* Farben - Aufträge */}
                        <div className="sidebar-content-header" data-toggle="collapse" data-target="#tasks">
                            <span>
                                <em className="fa fa-toggle-on" />
                                &nbsp;{t('colors.colors')}
                            </span>
                        </div>
                        <div id="tasks" className="collapse">
                            <div className="well">
                                <TasksSettings
                                    t={t}
                                    saveTaskSettings={this.handleTaskSettings}
                                    setAndFetchDefaultTaskColorTypes={this.props.setAndFetchDefaultTaskColorTypes}
                                    taskTypeColors={taskTypeColors}
                                    selectedCompany={selectedCompany}
                                    handleClose={this.props.handleClose}
                                    openModal={this.props.openModal}
                                    externTasks={
                                        this.props.busCompany.entity &&
                                        !isNullOrUndefined(this.props.busCompany.entity.externTasks)
                                    }
                                />
                            </div>
                        </div>
                        <hr />

                        {/* sonstige Einstellungen*/}
                        <div className="sidebar-content-header" data-toggle="collapse" data-target="#other">
                            <span className="">
                                <em className="fa fa-toggle-on" />
                                &nbsp;{t('other')}
                            </span>
                        </div>
                        <div id="other" className="collapse">
                            <div className="well">
                                <OtherSettings
                                    t={t}
                                    updateSettings={this.handleOtherSettings}
                                    defaultTaskPostProcessingTime={busCompany.entity.defaultTaskPostProcessingTime}
                                    bookingLeadTime={busCompany.entity.bookingLeadTime}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="sidebar-footer">
                        <button
                            className="btn btn-sm btn-primary pull-right"
                            onClick={() => {
                                this.props.patch(this.companySettings, Entities.BUS_COMPANY, 0, Projection.FULL);
                                this.props.handleClose();
                            }}>
                            <span className="glyphicon glyphicon-save" />
                            &nbsp;{t('user_functionality.save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        taskTypeColors: getTaskTypeColors(state),
        busCompany: getOwnCompany(state),
    };
};

export default withTranslation()(connect(mapStateToProps, { setAndFetchDefaultTaskColorTypes, patch })(SidebarContent));
