import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
    Entities,
    fetchAll,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import HelpEditForm from 'src/components/admin/help/entries/help-edit-form';

class HelpModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        this.props.fetchAll(Entities.HELP_CATEGORY);

        if (!isCreate) this.props.fetchById(id, Entities.HELP);
    }

    componentWillReceiveProps(nextProps) {
        const { helpCategories } = nextProps;

        if (!this.state.startedFetching && helpCategories.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { isCreate, help, helpCategories, t } = this.props;

        const finishFetching = this.state.startedFetching && !help.isFetching && !helpCategories.isFetching;

        if (finishFetching) {
            // check for errors
            if (help.error) return <ErrorMessage object={help} />;
            else {
                let initialValues;

                if (!isCreate && help.content) {
                    initialValues = {
                        id: help.content.id,
                        question: help.content.question,
                        answer: help.content.answer,
                        category: `${TOMCAT_URL}api/${Entities.HELP_CATEGORY.repository}/${help.content.category}`,
                    };
                } else {
                    initialValues = {
                        category: helpCategories.items[_.findIndex(helpCategories.items)]._links.self.href || '',
                    };
                }

                return (
                    <HelpEditForm
                        initialValues={initialValues}
                        isCreate={isCreate}
                        helpCategories={helpCategories}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate } = this.props;

        data.answer = data.answer || '';

        if (!isCreate) this.props.patch(data, Entities.HELP);
        else this.props.save(data, Entities.HELP);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        help: getUpdatedEntity(state, Entities.HELP),
        helpCategories: getEntities(state, Entities.HELP_CATEGORY),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchAll,
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(HelpModal);
