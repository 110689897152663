import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class HelpCategoryEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            hintText={t('help.name_hint')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('help.fill_in_category');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.HELP_CATEGORY.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'helpCategoryEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(HelpCategoryEditForm);
