import { Entities, TOMCAT_URL } from 'src/common/index';
import MenuItem from 'material-ui/MenuItem';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PasswordField from 'src/components/misc/password-field';
import {
    asyncCheckConflict,
    countryMenuItems,
    genderMenuItems,
    languageMenuItems,
    renderInput,
    renderSelectField,
    renderToggle,
} from 'src/components/misc/redux-form-helpers';
import { isBlank, isEmailInvalid, isUrl } from 'src/components/misc/validations';
import { scrollToAnchor } from 'src/utils/helpers';

class AccountEditForm extends Component {
    render() {
        const { isAdmin, isCreate, handleSubmit, companies, t } = this.props;

        const companyMenuItems = [<MenuItem key={-1} value="" primaryText="-" />];

        if (isAdmin) {
            companies
                .sort((a, b) => a.companyName.localeCompare(b.companyName))
                .forEach((value, index) => {
                    companyMenuItems.push(
                        <MenuItem
                            key={index}
                            value={value['_links']['self']['href']}
                            primaryText={value.companyName}
                        />,
                    );
                });
        }

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div id="topSection" className="col-md-6">
                        <Field name="email" label={`${t('contact_data.username')} *`} component={renderInput} />
                        {isAdmin && isCreate && (
                            <Field
                                name="password"
                                label={`${t('contact_data.password')} *`}
                                component={PasswordField}
                                t={t}
                            />
                        )}
                        {isAdmin && isCreate && (
                            <Field
                                name="passwordConfirmation"
                                label={`${t('user_functionality.confirm_password')} *`}
                                type="password"
                                component={renderInput}
                            />
                        )}
                        <Field name="contactData.gender" label={t('contact_data.gender')} component={renderSelectField}>
                            {genderMenuItems(t)}
                        </Field>
                        <Field
                            name="contactData.firstName"
                            label={`${t('contact_data.first_name')} *`}
                            component={renderInput}
                        />
                        <Field
                            name="contactData.lastName"
                            label={t('contact_data.last_name')}
                            component={renderInput}
                        />
                        <Field name="contactData.email" label={t('contact_data.email')} component={renderInput} />
                        <Field name="contactData.homepage" label={t('contact_data.homepage')} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field name="contactData.street" label={t('contact_data.street')} component={renderInput} />
                        <Field
                            name="contactData.postCode"
                            label={t('contact_data.post_code')}
                            hintText={t('contact_data.post_code_hint')}
                            component={renderInput}
                        />
                        <Field name="contactData.city" label={t('contact_data.city')} component={renderInput} />
                        <Field
                            name="contactData.country"
                            label={t('contact_data.country')}
                            component={renderSelectField}>
                            {countryMenuItems()}
                        </Field>
                        <Field name="contactData.phone" label={t('contact_data.tel_number')} component={renderInput} />
                        <Field name="language" label={`${t('language')} *`} component={renderSelectField}>
                            {languageMenuItems()}
                        </Field>
                    </div>
                </div>
                {isAdmin && (
                    <div className="row voffset40">
                        <div className="col-md-12">
                            <legend className="legend">{t('companies.admin_settings')}</legend>
                        </div>
                        <div className="col-md-12">
                            <Field
                                name="company"
                                label={`${t('contact_data.company')} *`}
                                component={renderSelectField}>
                                {companyMenuItems}
                            </Field>
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="ssoOperator"
                                label={t('sso_operator')}
                                labelPosition="right"
                                component={renderToggle}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="apiOperator"
                                label={t('api_operator')}
                                labelPosition="right"
                                component={renderToggle}
                            />
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        contactData: {
            name: undefined,
        },
    };

    if (isBlank(values.email)) errors.email = t('error_missing.fill_in_username');

    if (!values.contactData || (isEmailInvalid(values.contactData.email) && !isBlank(values.contactData.email)))
        errors.contactData.email = t('error_hint.error_invalid_email');

    if (!values.contactData || (values.contactData && isBlank(values.contactData.firstName)))
        errors.contactData = { firstName: t('error_missing.fill_in_first_name') };

    if (values.contactData && !isUrl(values.contactData.homepage))
        errors.contactData = { homepage: t('error_missing.fill_in_homepage') };

    if (props.isAdmin && props.isCreate) {
        if (isBlank(values.password)) errors.password = t('error_missing.fill_in_password');
        else if (isBlank(values.passwordConfirmation)) errors.passwordConfirmation = t('error_hint.confirm_password');
        else if (values.password !== values.passwordConfirmation)
            errors.passwordConfirmation = t('error_hint.passwords_dont_fit');
    }

    if (props.isAdmin && !values.company) {
        errors.company = t('error_missing.fill_in_company');
    }

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    const { t } = props;
    const accountId = values.id || -1;
    return asyncCheckConflict(
        t,
        `${TOMCAT_URL}api/${Entities.ACCOUNT.repository}/checkDuplicateEmail?email=${encodeURIComponent(
            values.email,
        )}&id=${accountId}`,
        'email',
        t('error_hint.username_already_exists'),
    );
};

const onSubmitFail = errors => {
    if (errors.email) scrollToAnchor('topSection');
};

export default reduxForm({
    form: 'accountEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'contactData.firstName'],
})(AccountEditForm);
