import BasicType from './BasicType';
import PaxTimePeriod from './PaxTimePeriod';

export default class OpeningHours implements BasicType {
    public first: PaxTimePeriod;
    public second: PaxTimePeriod;

    constructor(first: PaxTimePeriod, second: PaxTimePeriod) {
        this.first = first;
        this.second = second;
    }

    public toString(): string {
        return `first =  ${this.first.toString()}, second = ${this.second.toString()}`;
    }

    equals(other: OpeningHours): boolean {
        return this.first.equals(other.second) && this.second.equals(other.second);
    }

    public static fromJson(json: any): OpeningHours {
        return new OpeningHours(PaxTimePeriod.fromJson(json.first), PaxTimePeriod.fromJson(json.second));
    }
}

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}
