import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TableHelper from 'src/utils/table-helper';
import { Entities, Projection } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import CustomerGroupDiscountEditModal from 'src/components/administration/customer-group-discount-edit-modal';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';

class CustomerGroupDiscountAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.CUSTOMER_GROUP);
        this.props.fetchByCompanyId(Entities.CUSTOMER_GROUP_DISCOUNT, this.props.selectedCompany, Projection.FULL);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { customerGroupDiscounts } = nextProps;

        if (customerGroupDiscounts.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(customerGroupDiscounts.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.customerGroupDiscounts,
                this.props.customerGroupDiscounts,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { customerGroupDiscounts, t } = this.props;
        const finishFetching = this.state.startedFetching && !customerGroupDiscounts.isFetching;

        const tableData = [];
        if (finishFetching && customerGroupDiscounts.items.length > 0) {
            customerGroupDiscounts.items.map(customerGroupDiscount => {
                tableData.push({
                    id: customerGroupDiscount.id,
                    customerGroup: customerGroupDiscount.customerGroup,
                    customerGroupName: t(`customer_groups.id.${customerGroupDiscount.customerGroup.id}`),
                    discount: customerGroupDiscount.discount,
                    effectiveFrom: customerGroupDiscount.effectiveFrom,
                    effectiveUntil: customerGroupDiscount.effectiveUntil,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('customer_group.discounts')}
                    <div className="pull-right">
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                this.table.reset();
                                this.props.openModal({
                                    component: CustomerGroupDiscountEditModal,
                                    componentProps: {
                                        isCreate: true,
                                    },
                                    title: t('customer_group.new_discount'),
                                    mandatoryFields: true,
                                });
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('customer_group.new_discount')}
                        </button>
                    </div>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (customerGroupDiscounts.error) return <ErrorMessage object={customerGroupDiscounts} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn columnTitle={true} dataField="customerGroupName" dataSort={true}>
                                    {t('customer_group.group')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="discount" dataSort={true}>
                                    {t('customer_group.discount')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="effectiveFrom"
                                    dataFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('customer_group.effectiveFrom')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="effectiveUntil"
                                    dataFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('customer_group.effectiveUntil')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onProceedDelete(customerGroupDiscount) {
        if (customerGroupDiscount)
            this.props.deleteById(
                customerGroupDiscount.id,
                Entities.CUSTOMER_GROUP_DISCOUNT,
                this.props.selectedCompany,
            );
    }

    openConfirmDeleteDialog(customerGroupDiscount) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: customerGroupDiscount,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('customer_group.confirm_delete_discount'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: customerGroupDiscount.name,
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, customerGroupDiscount) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() =>
                        this.props.openModal({
                            component: CustomerGroupDiscountEditModal,
                            componentProps: {
                                id: customerGroupDiscount.id,
                                isCreate: false,
                            },
                            title: customerGroupDiscount.name,
                            mandatoryFields: true,
                        })
                    }>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => this.openConfirmDeleteDialog(customerGroupDiscount)}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(CustomerGroupDiscountAdministration));
