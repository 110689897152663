import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';

class BrandEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('brands.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="rating"
                            label={t('common_phrases.rating')}
                            hintText={t('brands.rating_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isInteger(values.rating)) errors.rating = t('error_hint.error_integer');
    if (values.rating < 0 || values.rating > 100) errors.rating = t('brands.rating_validation_hint');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.BUS_BRAND.repository}/search/findByName?name=${encodeURIComponent(values.name)}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'brandEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(BrandEditForm);
