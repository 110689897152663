import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import AdminAgeRatingAdministration from 'src/components/admin/age-rating/admin-age-rating-administration';

const mapStateToProps = state => {
    return {
        ageRatings: getEntities(state, Entities.AGE_RATING),
    };
};

const AdminAgeRatingAdministrationContainer = connect(mapStateToProps, { fetchAll })(AdminAgeRatingAdministration);

export default AdminAgeRatingAdministrationContainer;
