import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save, setMoneyCurrency, TOMCAT_URL } from 'src/common/index';
import { setSliderIndex } from 'src/actions/form';
import ErrorMessage from 'src/components/misc/error-message';
import SeasonCalculationEditForm from 'src/components/administration/season-calculation-edit-form';
import _ from 'lodash';
import { getOwnCompany } from 'src/selectors/bus-companies';

class SeasonCalculationEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.SEASON_CALCULATION, selectedCompany);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { seasonCalculation } = nextProps;

        if (seasonCalculation.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { seasonCalculation, selectedCompany, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !seasonCalculation.isFetching;

        if (finishFetching) {
            if (seasonCalculation.error) return <ErrorMessage object={seasonCalculation} />;
            else {
                let initialValues;
                if (!isCreate && seasonCalculation.content) {
                    initialValues = {
                        id: seasonCalculation.content.id,
                        name: seasonCalculation.content.name,
                        mondaySurcharge: seasonCalculation.content.mondaySurcharge,
                        tuesdaySurcharge: seasonCalculation.content.tuesdaySurcharge,
                        wednesdaySurcharge: seasonCalculation.content.wednesdaySurcharge,
                        thursdaySurcharge: seasonCalculation.content.thursdaySurcharge,
                        fridaySurcharge: seasonCalculation.content.fridaySurcharge,
                        saturdaySurcharge: seasonCalculation.content.saturdaySurcharge,
                        sundaySurcharge: seasonCalculation.content.sundaySurcharge,
                        mondayRate: seasonCalculation.content.mondayRate,
                        tuesdayRate: seasonCalculation.content.tuesdayRate,
                        wednesdayRate: seasonCalculation.content.wednesdayRate,
                        thursdayRate: seasonCalculation.content.thursdayRate,
                        fridayRate: seasonCalculation.content.fridayRate,
                        saturdayRate: seasonCalculation.content.saturdayRate,
                        sundayRate: seasonCalculation.content.sundayRate,
                        sundayDriverSurcharge: seasonCalculation.content.sundayDriverSurcharge,
                        holidayDriverSurcharge: seasonCalculation.content.holidayDriverSurcharge,
                        seasonSurcharges: this.formatSurchargesForForm(seasonCalculation.content.seasonSurcharges),
                        specialSurcharges: this.formatSurchargesForForm(seasonCalculation.content.specialSurcharges),
                        holidays: this.formatHolidaysForForm(seasonCalculation.content.holidays),
                        nightStart: seasonCalculation.content.nightStart
                            ? moment(seasonCalculation.content.nightStart, 'HH:mm').format('HH:mm')
                            : undefined,
                        nightEnd: seasonCalculation.content.nightEnd
                            ? moment(seasonCalculation.content.nightEnd, 'HH:mm').format('HH:mm')
                            : undefined,
                        driverNightSurcharge: seasonCalculation.content.driverNightSurcharge,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                } else {
                    initialValues = {
                        seasonSurcharges: [],
                        specialSurcharges: [],
                        sundayDriverSurcharge: 0,
                        holidayDriverSurcharge: 0,
                        nightStart: '23:00',
                        nightEnd: '05:00',
                        driverNightSurcharge: 0,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }

                return (
                    <SeasonCalculationEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        seasonCalculation={seasonCalculation.content}
                        selectedCompany={selectedCompany}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate, selectedCompany, ownCompany } = this.props;

        this.formatDataForBackend(data, ownCompany);

        if (isCreate) this.props.save(data, Entities.SEASON_CALCULATION, selectedCompany);
        else this.props.patch(data, Entities.SEASON_CALCULATION, selectedCompany);

        handleClose();
    }

    formatHolidaysForForm(holidays) {
        if (!holidays) return undefined;
        else return _.sortBy(holidays.map(holiday => moment(holiday))).map(holiday => holiday.format('DD.MM.YYYY'));
    }

    formatSurchargesForForm(seasonSurcharges) {
        if (!seasonSurcharges) return undefined;
        else {
            return seasonSurcharges.map(seasonSurcharge => {
                return {
                    name: seasonSurcharge.name,
                    surcharge: seasonSurcharge.surcharge,
                    startDate: moment(seasonSurcharge.startDate).format('DD.MM.YYYY'),
                    endDate: moment(seasonSurcharge.endDate).format('DD.MM.YYYY'),
                    minimumRate: seasonSurcharge.minimumRate,
                };
            });
        }
    }

    formatDataForBackend(data, ownCompany) {
        setMoneyCurrency(data.mondayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.tuesdayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.wednesdayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.thursdayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.fridayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.saturdayRate, ownCompany.entity.currency);
        setMoneyCurrency(data.sundayRate, ownCompany.entity.currency);

        if (data.seasonSurcharges) {
            data.seasonSurcharges = data.seasonSurcharges.map(seasonSurcharge => {
                return {
                    name: seasonSurcharge.name,
                    surcharge: seasonSurcharge.surcharge,
                    startDate: moment(seasonSurcharge.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    endDate: moment(seasonSurcharge.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    minimumRate: seasonSurcharge.minimumRate,
                };
            });
        }
        if (data.specialSurcharges) {
            data.specialSurcharges = data.specialSurcharges.map(specialSurcharge => {
                return {
                    name: specialSurcharge.name,
                    surcharge: specialSurcharge.surcharge,
                    startDate: moment(specialSurcharge.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    endDate: moment(specialSurcharge.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                };
            });
        }
        if (data.holidays) {
            data.holidays = data.holidays
                .filter(holiday => typeof holiday === 'string' && holiday.length > 0)
                .map(holiday => moment(holiday, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        }

        data.nightStart = moment(data.nightStart, 'HH:mm').format('HH:mm');
        data.nightEnd = moment(data.nightEnd, 'HH:mm').format('HH:mm');
    }
}

const mapStateToProps = state => {
    return {
        seasonCalculation: getUpdatedEntity(state, Entities.SEASON_CALCULATION, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
        ownCompany: getOwnCompany(state),
    };
};

export default connect(mapStateToProps, { fetchById, patch, save, setSliderIndex }, null, { withRef: true })(
    SeasonCalculationEditModal,
);
