import _ from 'lodash';

import { API_FAILURE, API_SUCCESS_DELETE, API_SUCCESS_FETCH, API_SUCCESS_UPDATE } from 'src/actions/api/api';

export default function entitiesReducer(state = {}, action) {
    switch (action.type) {
        case API_SUCCESS_FETCH:
            if (action.payload && action.payload.entities) {
                _.forEach(action.payload.entities, function (value, key) {
                    // replace entity which has been fetched
                    if (key === action.payload.mainEntity)
                        state[key] = _.mergeWith(state[key], value, (objValue, srcValue) => srcValue);
                    // merge related entities
                    else state[key] = _.merge(state[key], value);
                });

                return state;
            }
            break;
        case API_SUCCESS_UPDATE:
            if (action.payload && action.payload.updatedEntity) {
                return Object.assign({}, state, {
                    [action.payload.entityName]: updateEntity(state[action.payload.entityName], action),
                });
            }
            break;
        case API_SUCCESS_DELETE:
            if (action.payload && action.payload.entityName && action.payload.entityValue) {
                return Object.assign({}, state, {
                    [action.payload.entityName]: _.omit(state[action.payload.entityName], action.payload.entityValue),
                });
            }
            break;
        case API_FAILURE:
            if (action.payload && action.payload.entityName && action.payload.entityValue) {
                return Object.assign({}, state, {
                    [action.payload.entityName]: _.omit(state[action.payload.entityName], action.payload.entityValue),
                });
            }
            break;
        default:
            return state;
    }
}

function updateEntity(state, action) {
    return Object.assign({}, state, {
        [action.payload.entityIndex]: action.payload.updatedEntity,
    });
}
