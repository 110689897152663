import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { change, Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { EventType } from 'src/utils/constants';
import {
    asyncRequest,
    Entities,
    isIntegerPercent,
    isMoneyAmount,
    setMoneyCurrency,
    TOMCAT_URL,
    validatePrice,
} from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import { scrollToAnchor } from 'src/utils/helpers';
import DateTimePicker from 'src/components/misc/DateTimePicker';
import {
    countryMenuItems,
    genderMenuItems,
    Location,
    renderInput,
    renderRichTextEditor,
    renderSelectField,
    validateLocation,
} from 'src/components/misc/redux-form-helpers';
import DriverRules from 'src/components/operatingtool/busdrivers/driver-rules';
import { isBlank } from 'src/components/misc/validations';
import { getOwnCompany } from 'src/selectors/bus-companies';

class EventForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            availableDrivers: [],
            firstDriver: undefined,
            secondDriver: undefined,
        };

        // ui state to determine if contactData is present
        if (props.initialValues.customerContactData)
            this.state = {
                clickedContactData: false,
                showContactData: true,
                contactDataIcon: <ArrowUpIcon />,
            };
        else
            this.state = {
                clickedContactData: false,
                showContactData: false,
                contactDataIcon: <ArrowDownIcon />,
            };

        // request available drivers
        const fromTime = moment(props.initialValues.from.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm:ss');
        const toTime = moment(props.initialValues.to.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm:ss');

        if (props.drivers && props.drivers.items)
            asyncRequest(
                `${TOMCAT_URL}api/${
                    Entities.DRIVER.repository
                }/range?fromTime=${fromTime}&toTime=${toTime}&taskId=${-1}&companyId=${props.selectedCompany}`,
            ).then(response =>
                this.onReceiveDriversInRange(
                    response.json,
                    props.drivers,
                    props.initialValues.firstDriver,
                    props.initialValues.secondDriver,
                ),
            );
    }

    componentDidUpdate() {
        // scroll to contactData after re-render if user clicked on contactData button
        if (this.state.clickedContactData && this.state.showContactData) {
            scrollToAnchor('contactData');
            this.setState({ clickedContactData: false });
        }
    }

    render() {
        const { handleSubmit, eventType, initialValues, t } = this.props;

        const driverMenuItems1 = [<MenuItem key={-1} value="" primaryText="-" />];
        const driverMenuItems2 = [<MenuItem key={-1} value="" primaryText="-" />];

        if (this.state.availableDrivers) {
            this.state.availableDrivers.forEach((driver, index) => {
                if (this.state.firstDriver !== driver['_links']['self']['href'])
                    driverMenuItems2.push(
                        <MenuItem
                            key={index}
                            value={driver['_links']['self']['href']}
                            primaryText={driver.contactData.firstName}
                        />,
                    );

                if (this.state.secondDriver !== driver['_links']['self']['href'])
                    driverMenuItems1.push(
                        <MenuItem
                            key={index}
                            value={driver['_links']['self']['href']}
                            primaryText={driver.contactData.firstName}
                        />,
                    );
            });
        }

        return (
            <form className="form-horizontal" role="form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div id="topSection" className="form-group">
                            <div className="col-md-6">
                                <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                            </div>

                            {eventType === EventType.TASK && (
                                <>
                                    <div className="col-md-6">
                                        <Field
                                            name="passengers"
                                            label={t('task_details.pax')}
                                            hintText={t('events.pax_hint')}
                                            component={renderInput}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="bookingIdentifier"
                                            label={t('task_details.booking_number')}
                                            component={renderInput}
                                            disabled={true}
                                            title={initialValues.bookedTask ? t('events.error_change_field') : ''}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="taskIdentifier"
                                            label={t('task_details.task_number')}
                                            component={renderInput}
                                            disabled={true}
                                            title={initialValues.bookedTask ? t('events.error_change_field') : ''}
                                        />
                                    </div>
                                    {initialValues.isRatioClient && (
                                        <div className="col-md-6">
                                            <Field
                                                name="ratioTransferNumber"
                                                label={t('tasks.ratio_transfer_number')}
                                                component={renderInput}
                                                disabled={true}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {eventType === EventType.RELEASE && (
                                <div className="col-md-6">
                                    <Field
                                        name="percentage"
                                        label={t('calculation.surcharge')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            )}
                            {eventType === EventType.TASK && (
                                <>
                                    <div className="col-md-6">
                                        <Field
                                            name="price.amount"
                                            label={t('events.price')}
                                            component={renderInput}
                                            disabled={initialValues.bookedTask}
                                            title={initialValues.bookedTask ? t('events.error_change_field') : ''}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="postProcessingTime"
                                            label={t('events.post_processing_time')}
                                            component={renderInput}
                                            type="number"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row voffset">
                            <div className="col-md-12">
                                <label>{t('events.notes')}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="notes"
                                    placeholder={t('events.notes')}
                                    noLinks={true}
                                    component={renderRichTextEditor}
                                    t={t}
                                />
                            </div>
                        </div>
                        {eventType === EventType.TASK && (
                            <legend className="legend legend-event-form ">{t('task_details.route')}</legend>
                        )}
                        <div id="from" className="form-group">
                            <div className="col-md-1 voffset40">
                                {eventType === EventType.TASK ? <label>Ab</label> : <label>Von</label>}
                            </div>
                            <div className="col-md-4 event-form-date-fields">
                                <Field name="from.time" label={`${t('events.date')} *`} component={DateTimePicker} />
                            </div>
                            <div className="col-md-7">
                                <Field
                                    name="from.location"
                                    component={Location}
                                    label={`${t('common_phrases.location')} *`}
                                />
                            </div>
                        </div>
                        {eventType === EventType.TASK && (
                            <div>
                                <div id="taskFrom" className="form-group">
                                    <div className="col-md-1 voffset40">
                                        <label>{t('booked_tasks.from')}</label>
                                    </div>
                                    <div className="col-md-4 event-form-date-fields">
                                        <Field
                                            name="taskFrom.time"
                                            label={`${t('events.date')} *`}
                                            component={DateTimePicker}
                                        />
                                    </div>
                                    <div className="col-md-7">
                                        <Field
                                            name="taskFrom.location"
                                            component={Location}
                                            label={`${t('common_phrases.location')} *`}
                                        />
                                    </div>
                                </div>

                                <div id="intermediates">
                                    <div className="row voffset">
                                        <FieldArray
                                            name="intermediates"
                                            component={renderIntermediates}
                                            minDate={this.props.taskFromDate}
                                            maxDate={this.props.taskToDate}
                                            intermediates={this.props.intermediates}
                                            t={t}
                                        />
                                    </div>
                                </div>

                                <div id="taskTo" className="form-group">
                                    <div className="col-md-1 voffset40">
                                        <label>{t('events.to')}</label>
                                    </div>
                                    <div className="col-md-4 event-form-date-fields">
                                        <Field
                                            name="taskTo.time"
                                            label={`${t('events.date')} *`}
                                            component={DateTimePicker}
                                        />
                                    </div>
                                    <div className="col-md-7">
                                        <Field
                                            name="taskTo.location"
                                            component={Location}
                                            label={`${t('common_phrases.location')} *`}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div id="to" className="form-group">
                            <div className="col-md-1 voffset40">
                                {eventType === EventType.TASK ? <label>Zu</label> : <label>Bis</label>}
                            </div>
                            <div className="col-md-4 event-form-date-fields">
                                <Field name="to.time" label={`${t('events.date')} *`} component={DateTimePicker} />
                            </div>
                            <div className="col-md-7">
                                <Field
                                    name="to.location"
                                    component={Location}
                                    label={`${t('common_phrases.location')} *`}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {eventType === EventType.TASK && (
                    <div>
                        <legend className="legend legend-event-form">Fahrer</legend>
                        <div className="row">
                            <div className="col-md-5">
                                <Field
                                    name="firstDriver"
                                    label={`${t('administration.driver')} 1`}
                                    customOnChangeCallback={this.driverChanged}
                                    component={renderSelectField}>
                                    {driverMenuItems1}
                                </Field>
                            </div>
                            <div className="col-md-5">
                                <Field
                                    name="secondDriver"
                                    label={`${t('administration.driver')} 2`}
                                    customOnChangeCallback={this.driverChanged}
                                    component={renderSelectField}>
                                    {driverMenuItems2}
                                </Field>
                            </div>
                        </div>

                        <br />
                        <br />
                        <RaisedButton
                            secondary={true}
                            label={t('customer_details.customer_data')}
                            icon={this.state.contactDataIcon}
                            onClick={() => {
                                this.setState({
                                    clickedContactData: true,
                                    showContactData: !this.state.showContactData,
                                    contactDataIcon:
                                        this.state.showContactData === true ? <ArrowDownIcon /> : <ArrowUpIcon />,
                                });
                            }}
                        />
                        {this.state.showContactData && (
                            <div>
                                <legend className="legend legend-event-form">
                                    {t('customer_details.customer_data')}
                                </legend>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.gender"
                                            label={t('contact_data.gender')}
                                            component={renderSelectField}>
                                            {genderMenuItems(t)}
                                        </Field>
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.country"
                                            label={t('contact_data.country')}
                                            component={renderSelectField}>
                                            {countryMenuItems()}
                                        </Field>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.firstName"
                                            label={t('contact_data.first_name')}
                                            component={renderInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field name="customerContactData.city" label="Stadt" component={renderInput} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.lastName"
                                            label={t('contact_data.last_name')}
                                            component={renderInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.postCode"
                                            label={t('contact_data.post_code')}
                                            hintText={t('contact_data.post_code_hint')}
                                            component={renderInput}
                                        />
                                    </div>
                                </div>
                                <div id="contactData" className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.email"
                                            label={t('contact_data.email_address')}
                                            component={renderInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.street"
                                            label={t('contact_data.street')}
                                            component={renderInput}
                                        />
                                    </div>
                                </div>
                                <div id="contactData" className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="customerContactData.phone"
                                            label={t('contact_data.tel_number')}
                                            component={renderInput}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {initialValues.organizationPoNumber && (
                                        <div className="col-md-6">
                                            <Field
                                                name="organizationPoNumber"
                                                label={t('task_details.organization_po_number')}
                                                component={renderInput}
                                                disabled={true}
                                            />
                                        </div>
                                    )}

                                    {initialValues.organizationCostCenter && (
                                        <div className="col-md-6">
                                            <Field
                                                name="organizationCostCenter"
                                                label={t('task_details.organization_cost_center')}
                                                component={renderInput}
                                                disabled={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </form>
        );
    }

    onReceiveDriversInRange(driversInRange, drivers, firstDriver, secondDriver) {
        this.setState({
            availableDrivers: DriverRules.getPopulatedDriverProps(drivers.items, driversInRange, [
                firstDriver,
                secondDriver,
            ]),
            firstDriver: firstDriver,
            secondDriver: secondDriver,
        });
    }

    driverChanged(name, value) {
        const { t } = this.props;
        if (name === 'firstDriver') {
            this.setState({
                firstDriver: value,
            });
        } else {
            this.setState({
                secondDriver: value,
            });
        }

        const currentSelectedDriver = this.state.availableDrivers.find(driver => driver._links.self.href === value);
        if (currentSelectedDriver && currentSelectedDriver.restedLessThan11Hours)
            this.props.addNotification(
                t('warning'),
                t('driver.error_too_short_pause', { driver: currentSelectedDriver.contactData.firstName }),
                'warning',
                'tr',
            );
    }
}

const renderIntermediates = ({ fields, t, ...props }) => (
    <div>
        <div className="row">
            <div className="col-md-12">
                <div className="col-md-2">
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        title={t('events.add_new_route')}
                        onClick={() => {
                            if (!props.intermediates || (props.intermediates && props.intermediates.length === 0))
                                fields.push({ time: props.minDate });
                            else
                                fields.push({
                                    time: props.intermediates[props.intermediates.length - 1].time,
                                });
                        }}>
                        <span className="glyphicon glyphicon-plus text-info" />
                        &nbsp;
                        <label>{t('events.new_destination_client')}</label>
                    </button>
                </div>
            </div>
        </div>

        {fields.map((intermediate, index) => (
            <div key={index} className="row">
                <div className="col-md-12">
                    <div className="col-md-4 col-md-offset-1 event-form-date-fields">
                        <Field
                            name={`${intermediate}.time`}
                            label={`${t('events.date')} *`}
                            component={DateTimePicker}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name={`${intermediate}.location`}
                            component={Location}
                            label={`${t('common_phrases.location')} *`}
                        />
                    </div>
                    <div className="col-md-1 voffset">
                        <button
                            type="button"
                            className="btn btn-md transparent"
                            title={t('user_functionality.delete')}
                            onClick={() => {
                                fields.remove(index);
                            }}>
                            <span className="glyphicon glyphicon-trash text-danger" />
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        from: {
            location: undefined,
            time: undefined,
        },
        to: {
            location: undefined,
            time: undefined,
        },
        taskFrom: {
            location: undefined,
            time: undefined,
        },
        taskTo: {
            location: undefined,
            time: undefined,
        },
        price: {
            amount: undefined,
        },
    };

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.from.location) errors.from.location = t('error_missing.fill_in_from_location');
    else if (validateLocation(values.from.location)) errors.from.location = t('bus.select_address');

    if (!values.to.location) errors.to.location = t('error_missing.fill_in_destination');
    else if (validateLocation(values.to.location)) errors.to.location = t('bus.select_address');

    if (!isIntegerPercent(values.percentage)) errors.percentage = t('calculation.fill_in_surcharge_criteria');

    // following validation is only for tasks
    if (props.eventType === EventType.TASK) {
        if (props.busAvailableSeats) {
            if (values.passengers > props.busAvailableSeats)
                errors.passengers = t('error_hint.too_less_seats', { seats: props.busAvailableSeats });
        }

        if (isMoneyAmount(values.price)) {
            if (values.price.amount < 0) errors.price.amount = t('error_hint.error_negative');
            else if (validatePrice(values.price.amount))
                errors.price.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
        }

        if (!values.from.time) errors.from.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.from.time, null, values.taskFrom.time))
            errors.from.time = t('error_hint.date_out_of_valid_area');

        if (!values.to.time) errors.to.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.to.time, values.taskTo.time, null))
            errors.to.time = t('error_hint.date_out_of_valid_area');

        if (!values.taskFrom.time) errors.taskFrom.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.taskFrom.time, values.from.time, values.taskTo.time))
            errors.taskFrom.time = t('error_hint.date_out_of_valid_area');

        if (!values.taskTo.time) errors.taskTo.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.taskTo.time, values.taskFrom.time, values.to.time))
            errors.taskTo.time = t('error_hint.date_out_of_valid_area');

        if (values.passengers && values.passengers < 0) errors.passengers = t('error_hint.error_negative');

        if (values.postProcessingTime && values.postProcessingTime < 0)
            errors.postProcessingTime = t('error_hint.error_negative');

        if (!values.taskFrom.location) errors.taskFrom.location = t('error_missing.fill_in_from_address');
        else if (validateLocation(values.taskFrom.location)) errors.taskFrom.location = t('bus.select_address');

        if (!values.taskTo.location) errors.taskTo.location = t('error_missing.fill_in_to_address');
        else if (validateLocation(values.taskTo.location)) errors.taskTo.location = t('bus.select_address');

        if (values.intermediates) errors.intermediates = validateIntermediates(values, props);
    } else {
        if (!values.from.time) errors.from.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.from.time, null, values.to.time))
            errors.from.time = t('error_hint.date_out_of_valid_area');

        if (!values.to.time) errors.to.time = t('error_missing.fill_in_date');
        else if (!dateValidation(values.to.time, values.from.time, null))
            errors.to.time = t('error_hint.date_out_of_valid_area');
    }

    if (
        values.from.time &&
        values.to.time &&
        moment(values.from.time, 'DD.MM.YYYY HH:mm').isSameOrAfter(moment(values.to.time, 'DD.MM.YYYY HH:mm'), 'minute')
    ) {
        errors.from.time = t('error_hint.date_before_until');
        errors.to.time = t('error_hint.date_after_from');
    }

    return errors;
};

const validateIntermediates = (values, props) => {
    const { t } = props;
    const errors = [];
    values.intermediates.forEach((intermediate, index) => {
        const error = {
            time: undefined,
            location: undefined,
        };

        if (!intermediate || !intermediate.time) {
            error.time = t('error_missing.fill_in_time');
            errors[index] = error;
        } else if (!validateIntermediateDateTime(intermediate.time, index, values)) {
            error.time = t('error_hint.date_out_of_valid_area');
            errors[index] = error;
        }

        if (!intermediate || !intermediate.location) {
            error.location = t('error_missing.fill_in_address');
            errors[index] = error;
        } else if (intermediate && intermediate.location && validateLocation(intermediate.location)) {
            error.location = t('bus.select_address');
            errors[index] = error;
        }

        return error;
    });

    if (errors.length > 0) return errors;
};

const validateIntermediateDateTime = (intermediate, index, values) => {
    if (index === 0) {
        if (moment(intermediate, 'DD.MM.YYYY HH:mm').isBefore(moment(values.taskFrom.time, 'DD.MM.YYYY HH:mm')))
            return false;
        else {
            if (
                values.intermediates.length > 1 &&
                moment(intermediate, 'DD.MM.YYYY HH:mm').isAfter(
                    moment(values.intermediates[1].time, 'DD.MM.YYYY HH:mm'),
                )
            )
                return false;
            else if (moment(intermediate, 'DD.MM.YYYY HH:mm').isAfter(moment(values.taskTo.time, 'DD.MM.YYYY HH:mm')))
                return false;

            return true;
        }
    } else if (index + 1 < values.intermediates.length) {
        return dateValidation(intermediate, values.intermediates[index - 1].time, values.intermediates[index + 1].time);
    } else {
        return dateValidation(intermediate, values.intermediates[index - 1].time, values.taskTo.time);
    }
};

const onSubmitFail = errors => {
    let field = 'topSection';

    if (errors.name || errors.passengers || (errors.price && errors.price.amount) || errors.postProcessingTime)
        field = 'topSection';
    else if (errors.from.time || errors.from.location) field = 'from';
    else if (errors.taskFrom.time || errors.taskFrom.location) field = 'taskFrom';
    else if (errors.intermediates) field = 'intermediates';
    else if (errors.taskTo.time || errors.taskTo.location) field = 'taskTo';
    else if (errors.to.time || errors.to.location) field = 'to';

    scrollToAnchor(field);
};

const dateValidation = (blurredDate, dateBefore, dateAfter) => {
    if (dateBefore && moment(blurredDate, 'DD.MM.YYYY HH:mm').isBefore(moment(dateBefore, 'DD.MM.YYYY HH:mm')))
        return false;
    else if (dateAfter && moment(blurredDate, 'DD.MM.YYYY HH:mm').isAfter(moment(dateAfter, 'DD.MM.YYYY HH:mm')))
        return false;

    return true;
};

const onSubmit = (data, ownProps, ownCompany) => {
    setMoneyCurrency(data.price, ownCompany.entity.currency);
    ownProps.onSubmit(data);
};

EventForm = reduxForm({
    form: 'eventForm',
    onSubmitFail,
    validate,
})(EventForm);

const mapStateToProps = (state, ownProps) => {
    const fromDate = selector(state, 'from.time');
    const taskFromDate = selector(state, 'taskFrom.time');
    const taskToDate = selector(state, 'taskTo.time');
    const intermediates = selector(state, 'intermediates');
    const firstDriver = selector(state, 'firstDriver');
    const secondDriver = selector(state, 'secondDriver');

    return {
        fromDate,
        intermediates,
        firstDriver,
        secondDriver,
        taskFromDate,
        taskToDate,
        onSubmit: data => onSubmit(data, ownProps, getOwnCompany(state)),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: (field, value) => {
            dispatch(change('eventForm', field, value));
        },
        addNotification: (title, message, level, position) => {
            dispatch(addNotification(title, message, level, position));
        },
    };
};

const selector = formValueSelector('eventForm');
EventForm = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(EventForm);

export default EventForm;
