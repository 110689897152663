import { Entities, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export function deleteBusEquipment(equipmentId) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.BUS_EQUIPMENT.repository}/removeBusEquipment?equipmentId=${equipmentId}`,
                method: 'DELETE',
                secondaryActionTypes: [
                    `REQUEST_${Entities.BUS_EQUIPMENT.action}`,
                    `SUCCESS_DELETE_${Entities.BUS_EQUIPMENT.action}`,
                    `FAILURE_${Entities.BUS_EQUIPMENT.action}`,
                ],
                schema: Entities.BUS_EQUIPMENT.schema,
                reducerIndex: 0,
                parameter: equipmentId,
            }),
        );
    };
}
