import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import DateTimePicker from 'src/components/misc/DateTimePicker';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import moment from 'moment';
import { CouponType } from 'src/utils/constants';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class CouponEditForm extends Component {
    render() {
        const { handleSubmit, coupon, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                {coupon && moment(coupon.validFrom).isBefore(moment())
                    ? this.renderCurrentlyValidCouponEditInputs(t)
                    : this.renderFullCouponEditInputs(t)}

                <br />
            </form>
        );
    }

    renderCurrentlyValidCouponEditInputs(t) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            hintText={t('coupons.name_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="validTo"
                            label={`${t('coupons.valid_until')} *`}
                            hintText={t('coupons.valid_until')}
                            component={DateTimePicker}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="quantity"
                            label={`${t('coupons.quantity')} *`}
                            type="number"
                            hintText={t('coupons.quantity_hint')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderFullCouponEditInputs(t) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="type"
                            label={`${t('common_phrases.type')} *`}
                            hintText={t('coupons.type_hint')}
                            defaultSelected={this.props.initialValues.type}
                            component={RadioButtonGroup}>
                            <RadioButton
                                style={{ display: 'inline-block', width: '200px' }}
                                value={CouponType.RELATIVE}
                                label={t('coupons.value_relative')}
                            />
                            <RadioButton
                                style={{ display: 'inline-block', width: '200px' }}
                                value={CouponType.ABSOLUTE}
                                label={t('coupons.value_absolute')}
                            />
                        </Field>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            hintText={t('coupons.name_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="code"
                            label={`${t('coupons.code')} *`}
                            hintText={t('coupons.code_hint')}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="validFrom"
                            label={`${t('coupons.valid_from')} *`}
                            hintText={t('coupons.valid_from')}
                            component={DateTimePicker}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="validTo"
                            label={`${t('coupons.valid_until')} *`}
                            hintText={t('coupons.valid_until')}
                            component={DateTimePicker}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="quantity"
                            label={`${t('coupons.quantity')} *`}
                            type="number"
                            hintText={t('coupons.quantity_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="discount"
                            label={`${t('coupons.discount')} *`}
                            type="number"
                            hintText={t('coupons.discount')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    // validate name
    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');
    else if (values.name.length > 50) errors.name = t('error_hint.error_name_length');

    // validate from date
    if (!values.validFrom) errors.validFrom = t('error_missing.fill_in_date_from');
    else if (moment(values.validFrom, 'DD.MM.YYYY HH:mm').isBefore(moment(), 'minute'))
        errors.validFrom = t('error_hint.error_date_in_past');

    // validate to date
    if (!values.validTo) errors.validTo = t('error_missing.fill_in_date_to');
    else if (
        values.validFrom &&
        moment(values.validTo, 'DD.MM.YYYY HH:mm').isBefore(moment(values.validFrom, 'DD.MM.YYYY HH:mm'))
    ) {
        errors.validFrom = t('error_hint.error_valid_from');
        errors.validTo = t('error_hint.error_valid_to');
    }

    // validate quantity
    if (values.quantity == null) errors.quantity = t('error_missing.fill_in_quantity_coupons');
    else if (!isInteger(values.quantity)) errors.quantity = t('error_hint.error_integer');
    else if (values.quantity < 1) errors.quantity = t('error_hint.error_at_least_one_coupon');
    else if (values.quantity > 1000000) errors.quantity = t('error_hint.error_one_million');
    else if (values.quantity < values.redeemCount)
        errors.quantity = t('error_hint.error_redeem_count', { redeemCount: values.redeemCount });

    // validate discount
    if (values.discount == null) errors.discount = t('error_missing.fill_in_discount');
    else if (!isInteger(values.discount)) errors.discount = t('error_hint.error_integer');
    else {
        if (values.type === CouponType.ABSOLUTE) {
            if (values.discount < 1) errors.discount = t('error_hint.error_at_least_euro');
            else if (values.discount > 50000) errors.discount = t('error_hint.error_max_euro');
        } else if (values.type === CouponType.RELATIVE && (values.discount < 1 || values.discount > 100))
            errors.discount = t('error_hint.error_invalid_percentage');
    }

    // validate code
    if (isBlank(values.code)) errors.code = t('error_missing.fill_in_code');
    else if (!/^[A-Z0-9]*$/.test(values.code)) errors.code = t('error_hint.error_code_syntax');
    else if (values.code.length < 3 || values.code.length > 15) errors.code = t('error_hint.error_code_length');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.COUPON.repository}/search/findByCode?code=${encodeURIComponent(values.code)}`,
        values.id,
        'code',
    );
};

export default reduxForm({
    form: 'couponEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['code'],
})(CouponEditForm);
