import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router';

class HelpAdministration extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <legend className="legend">{t('help.administration')}</legend>

                <ul className="nav">
                    <li>
                        <Link to="/admin/help-category-administration" className="nav-link" activeClassName="active">
                            <span className="fa fa-info-circle" />
                            &nbsp;&nbsp;{t('common_phrases.categories')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/help-entry-administration" className="nav-link" activeClassName="active">
                            <span className="fa fa-info-circle" />
                            &nbsp;&nbsp;{t('help.support')}
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withTranslation()(HelpAdministration);
