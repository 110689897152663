import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';

import { genderMenuItems, renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';

class ChangeTaskContactDataForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerContactData.gender"
                            label={t('contact_data.gender')}
                            component={renderSelectField}>
                            {genderMenuItems(t).slice(1)}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerContactData.firstName"
                            label={`${t('contact_data.first_name')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="customerContactData.lastName"
                            label={`${t('contact_data.last_name')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerContactData.email"
                            label={`${t('contact_data.email')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 voffset">
                        <div className="alert alert-warning">
                            <h4>{t('hint_message.attention')}</h4>
                            <span>{t('hint_message.editing')}</span>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        customerContactData: {},
    };

    if (isBlank(values.customerContactData.firstName))
        errors.customerContactData.firstName = t('error_missing.fill_in_first_name');

    if (isBlank(values.customerContactData.lastName))
        errors.customerContactData.lastName = t('error_missing.fill_in_last_name');

    return errors;
};

ChangeTaskContactDataForm = reduxForm({
    form: 'changeTaskContactDataForm',
    validate,
})(ChangeTaskContactDataForm);

const mapStateToProps = (state, ownProps) => {
    const { task } = ownProps;

    return {
        initialValues: {
            id: task.id,
            customerContactData: task.customerContactData,
        },
    };
};

ChangeTaskContactDataForm = connect(mapStateToProps, null, null, { withRef: true })(ChangeTaskContactDataForm);

export default ChangeTaskContactDataForm;
