import { Entities, SanitizedInnerHTML } from 'src/common/index';
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem, makeSelectable } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import trainingManual from 'static/documents/Schulungsunterlagen_für_Busunternehmen_04_2021.pdf';

let SelectableList = makeSelectable(List);

class Help extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
            selectedCategory: 0,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.HELP);
    }

    componentWillReceiveProps(nextProps) {
        const { helps } = nextProps;

        if (!this.state.startedFetching && helps.isFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { helps, helpCategories, t } = this.props;
        const finishFetching = this.state.startedFetching && !helps.isFetching;

        if (finishFetching) {
            const sortedCategories = _.sortBy(helpCategories, 'name');

            return (
                <div className="row">
                    <div className="col-md-2" id="help-side-nav">
                        <a className="nav-link" activeClassName="active" target="_blank" href={trainingManual}>
                            <span className="glyphicon glyphicon-book" />
                            &nbsp;&nbsp;{t('help.training_manual')}
                        </a>
                        <SelectableList value={this.state.selectedCategory} onChange={this.handleRequestChange}>
                            <Subheader>{t('common_phrases.categories')}</Subheader>
                            {_.map(sortedCategories, (value, key) => {
                                return (
                                    <ListItem
                                        key={key}
                                        value={key}
                                        primaryText={value.name}
                                        onClick={() => {
                                            //scrollToAnchor("cat" + value.id);
                                            location.href = '#cat' + value.id;
                                        }}
                                    />
                                );
                            })}
                        </SelectableList>
                    </div>
                    <div className="col-md-10" id="help-content">
                        {sortedCategories.map((helpCategory, index) => {
                            return (
                                <div key={index}>
                                    <legend id={'cat' + helpCategory.id} className="voffset legend" key={index}>
                                        {helpCategory.name}
                                    </legend>

                                    {_.sortBy(
                                        helps.items.filter(help => help.category === helpCategory.id),
                                        'question',
                                    ).map((help, helpIndex) => {
                                        return (
                                            <div key={helpIndex} className="voffset row">
                                                <div className="col-md-11 help-question">
                                                    <h4>{help.question}</h4>
                                                </div>
                                                <SanitizedInnerHTML
                                                    className="col-md-11 help-answer"
                                                    html={help.answer}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return <CircularProgress />;
        }
    }

    handleRequestChange(event, index) {
        this.setState({
            selectedCategory: index,
        });
    }
}

export default withTranslation()(Help);
