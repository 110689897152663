import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { SliderPicker } from 'react-color';

import { ButtonColors, TaskType } from 'src/utils/constants';
import ConfirmDialog from 'src/components/misc/confirm-dialog';

class TaskSettings extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            displayColorPicker: true,
            currentSliderColor: props.taskTypeColors.MY_OPEN_TASK,
            currentTaskType: TaskType.MY_OPEN_TASK,
            taskTypeColors: Object.assign({}, props.taskTypeColors),
            activeTaskType: Object.assign({}, TaskType.MY_OPEN_TASK),
        };
    }

    handleClick(e) {
        const taskTypeActive = this.state.activeTaskType;
        if (this.state.currentTaskType !== e.currentTarget.dataset.id) {
            taskTypeActive[this.state.currentTaskType] = false;
        }

        this.setState({
            displayColorPicker: true,
            currentSliderColor: e.currentTarget.style.background,
            currentTaskType: e.currentTarget.dataset.id,
        });

        taskTypeActive[e.currentTarget.dataset.id] = true;
    }

    handleClose() {
        this.setState({ displayColorPicker: false });
    }

    onProceedSetDefaultColors() {
        const { selectedCompany } = this.props;

        this.props.setAndFetchDefaultTaskColorTypes(selectedCompany);

        this.props.handleClose();
    }

    openConfirmSetDefaultTaskTypeColors(event) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: event,
                onProceedDialog: this.onProceedSetDefaultColors,
                bodyText: t('colors.confirm_reset_colors'),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
            },
            title: t('colors.reset_colors'),
            noButtons: true,
        });
    }

    handleOnChangeColor(color) {
        const { saveTaskSettings } = this.props;
        const taskTypeColors = this.state.taskTypeColors;

        taskTypeColors[this.state.currentTaskType] = color.hex;

        this.setState({
            taskTypeColors: taskTypeColors,
            currentSliderColor: color.hex,
        });

        saveTaskSettings(taskTypeColors);
    }

    render() {
        const { taskTypeColors, externTasks, t } = this.props;
        const taskTypeColorsFromUIState = this.state.taskTypeColors;
        const taskTypeActive = this.state.activeTaskType;

        if (this.state.currentTaskType === 'MY_OPEN_TASK') taskTypeActive[this.state.currentTaskType] = true;

        if (taskTypeColors) {
            const border = '3px solid ';
            const style = {
                MY_OPEN_TASK: {
                    background: taskTypeColorsFromUIState.MY_OPEN_TASK,
                    border:
                        taskTypeActive.MY_OPEN_TASK === true ? border + taskTypeColorsFromUIState.MY_OPEN_TASK : 'none',
                },
                BOOKED_BY_CUSTOMER_OPEN: {
                    background: taskTypeColorsFromUIState.BOOKED_BY_CUSTOMER_OPEN,
                    border:
                        taskTypeActive.BOOKED_BY_CUSTOMER_OPEN === true
                            ? border + taskTypeColorsFromUIState.BOOKED_BY_CUSTOMER_OPEN
                            : 'none',
                },
                BOOKED_BY_CUSTOMER_ASSIGNED: {
                    background: taskTypeColorsFromUIState.BOOKED_BY_CUSTOMER_ASSIGNED,
                    border:
                        taskTypeActive.BOOKED_BY_CUSTOMER_ASSIGNED === true
                            ? border + taskTypeColorsFromUIState.BOOKED_BY_CUSTOMER_ASSIGNED
                            : 'none',
                },
                MY_ASSIGNED_TASK: {
                    background: taskTypeColorsFromUIState.MY_ASSIGNED_TASK,
                    border:
                        taskTypeActive.MY_ASSIGNED_TASK === true
                            ? border + taskTypeColorsFromUIState.MY_ASSIGNED_TASK
                            : 'none',
                },
                MY_OPEN_EXCHANGED_TASK_PENDING: {
                    background: taskTypeColorsFromUIState.MY_OPEN_EXCHANGED_TASK_PENDING,
                    border:
                        taskTypeActive.MY_OPEN_EXCHANGED_TASK_PENDING === true
                            ? border + taskTypeColorsFromUIState.MY_OPEN_EXCHANGED_TASK_PENDING
                            : 'none',
                },
                MY_OPEN_EXCHANGED_TASK_ACCEPTED: {
                    background: taskTypeColorsFromUIState.MY_OPEN_EXCHANGED_TASK_ACCEPTED,
                    border:
                        taskTypeActive.MY_OPEN_EXCHANGED_TASK_ACCEPTED === true
                            ? border + taskTypeColorsFromUIState.MY_OPEN_EXCHANGED_TASK_ACCEPTED
                            : 'none',
                },
                MY_ASSIGNED_EXCHANGED_TASK: {
                    background: taskTypeColorsFromUIState.MY_ASSIGNED_EXCHANGED_TASK,
                    border:
                        taskTypeActive.MY_ASSIGNED_EXCHANGED_TASK === true
                            ? border + taskTypeColorsFromUIState.MY_ASSIGNED_EXCHANGED_TASK
                            : 'none',
                },
                FOREIGN_OPEN_EXCHANGED_TASK_PENDING: {
                    background: taskTypeColorsFromUIState.FOREIGN_OPEN_EXCHANGED_TASK_PENDING,
                    border:
                        taskTypeActive.FOREIGN_OPEN_EXCHANGED_TASK_PENDING === true
                            ? border + taskTypeColorsFromUIState.FOREIGN_OPEN_EXCHANGED_TASK_PENDING
                            : 'none',
                },
                FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED: {
                    background: taskTypeColorsFromUIState.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED,
                    border:
                        taskTypeActive.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED === true
                            ? border + taskTypeColorsFromUIState.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED
                            : 'none',
                },
                FOREIGN_ASSIGNED_EXCHANGED_TASK: {
                    background: taskTypeColorsFromUIState.FOREIGN_ASSIGNED_EXCHANGED_TASK,
                    border:
                        taskTypeActive.FOREIGN_ASSIGNED_EXCHANGED_TASK === true
                            ? border + taskTypeColorsFromUIState.FOREIGN_ASSIGNED_EXCHANGED_TASK
                            : 'none',
                },
                OPEN_OFFER: {
                    background: taskTypeColorsFromUIState.OPEN_OFFER,
                    border: taskTypeActive.OPEN_OFFER === true ? border + taskTypeColorsFromUIState.OPEN_OFFER : 'none',
                },
                DECLINED_OFFER: {
                    background: taskTypeColorsFromUIState.DECLINED_OFFER,
                    border:
                        taskTypeActive.DECLINED_OFFER === true
                            ? border + taskTypeColorsFromUIState.DECLINED_OFFER
                            : 'none',
                },
                EXTERNAL_TASK: {
                    background: taskTypeColorsFromUIState.EXTERNAL_TASK,
                    border:
                        taskTypeActive.EXTERNAL_TASK === true
                            ? border + taskTypeColorsFromUIState.EXTERNAL_TASK
                            : 'none',
                },
            };

            const Picker = (
                <SliderPicker color={this.state.currentSliderColor} onChangeComplete={this.handleOnChangeColor} />
            );

            return (
                <div>
                    {this.state.displayColorPicker && (
                        <div id="colortable-popover">
                            <div id="colortable-picker" onClick={this.handleClose} />
                            {Picker}
                            <br />
                            <button
                                className="btn btn-sm btn-primary pull-right"
                                onClick={this.openConfirmSetDefaultTaskTypeColors}>
                                {t('colors.default_colors')}
                            </button>
                            <br />
                        </div>
                    )}
                    <div className="tasks-settings">
                        <table className="table table-hover">
                            <tbody>
                                <tr>
                                    <th colSpan="2">
                                        <em className="glyphicon glyphicon-home" />
                                        &nbsp;{t('colors.own_tasks')}
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ borderLeft: style.MY_OPEN_TASK.border }}>
                                        <div
                                            data-id={TaskType.MY_OPEN_TASK}
                                            onClick={this.handleClick}
                                            style={{ background: style.MY_OPEN_TASK.background }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.open')}&nbsp;
                                            <em className="fa fa-folder-open-o" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ borderLeft: style.MY_ASSIGNED_TASK.border }}>
                                        <div
                                            data-id={TaskType.MY_ASSIGNED_TASK}
                                            onClick={this.handleClick}
                                            style={{ background: style.MY_ASSIGNED_TASK.background }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.assigned')}&nbsp;
                                            <em className="fa fa-folder-o" />
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th colSpan="2">
                                        <em className="fa fa-user" />
                                        &nbsp;{t('colors.booked_from_client')}
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ borderLeft: style.BOOKED_BY_CUSTOMER_OPEN.border }}>
                                        <div
                                            data-id={TaskType.BOOKED_BY_CUSTOMER_OPEN}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.BOOKED_BY_CUSTOMER_OPEN.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.open')}&nbsp;
                                            <em className="fa fa-folder-open-o" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.BOOKED_BY_CUSTOMER_ASSIGNED.border,
                                        }}>
                                        <div
                                            data-id={TaskType.BOOKED_BY_CUSTOMER_ASSIGNED}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.BOOKED_BY_CUSTOMER_ASSIGNED.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.assigned')}&nbsp;
                                            <em className="fa fa-folder-o" />
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th colSpan="2">
                                        <em className="glyphicon glyphicon-export" />
                                        &nbsp;{t('colors.released_own_tasks')}
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.MY_OPEN_EXCHANGED_TASK_PENDING.border,
                                        }}>
                                        <div
                                            data-id={TaskType.MY_OPEN_EXCHANGED_TASK_PENDING}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.MY_OPEN_EXCHANGED_TASK_PENDING.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.outstanding')}&nbsp;
                                            <em className="glyphicon glyphicon-refresh" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.MY_OPEN_EXCHANGED_TASK_ACCEPTED.border,
                                        }}>
                                        <div
                                            data-id={TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.MY_OPEN_EXCHANGED_TASK_ACCEPTED.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.accepted')}&nbsp;
                                            <em className="glyphicon glyphicon-ok" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.MY_ASSIGNED_EXCHANGED_TASK.border,
                                        }}>
                                        <div
                                            data-id={TaskType.MY_ASSIGNED_EXCHANGED_TASK}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.MY_ASSIGNED_EXCHANGED_TASK.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.assigned')}&nbsp;
                                            <em className="fa fa-folder-o" />
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th colSpan="2">
                                        <em className="glyphicon glyphicon-import" />
                                        &nbsp;{t('colors.released_extern_tasks')}
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.FOREIGN_OPEN_EXCHANGED_TASK_PENDING.border,
                                        }}>
                                        <div
                                            data-id={TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.FOREIGN_OPEN_EXCHANGED_TASK_PENDING.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.outstanding')}&nbsp;
                                            <em className="glyphicon glyphicon-refresh" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED.border,
                                        }}>
                                        <div
                                            data-id={TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.accepted')}&nbsp;
                                            <em className="glyphicon glyphicon-ok" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: style.FOREIGN_ASSIGNED_EXCHANGED_TASK.border,
                                        }}>
                                        <div
                                            data-id={TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK}
                                            onClick={this.handleClick}
                                            style={{
                                                background: style.FOREIGN_ASSIGNED_EXCHANGED_TASK.background,
                                            }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.assigned')}&nbsp;
                                            <em className="fa fa-folder-o" />
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th colSpan="2">
                                        <em className="glyphicon glyphicon-import" />
                                        &nbsp;{t('header_offer_administration')}
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ borderLeft: style.OPEN_OFFER.border }}>
                                        <div
                                            data-id={TaskType.OPEN_OFFER}
                                            onClick={this.handleClick}
                                            style={{ background: style.OPEN_OFFER.background }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.open')}&nbsp;
                                            <em className="fa fa-folder-open-o" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ borderLeft: style.DECLINED_OFFER.border }}>
                                        <div
                                            data-id={TaskType.DECLINED_OFFER}
                                            onClick={this.handleClick}
                                            style={{ background: style.DECLINED_OFFER.background }}
                                            className="colorselector"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {t('colors.declined')}&nbsp;
                                            <em className="fa fa-ban" />
                                        </span>
                                    </td>
                                </tr>

                                {externTasks && (
                                    <tr>
                                        <th colSpan="2">
                                            <em className="glyphicon glyphicon-export" />
                                            &nbsp;{t('colors.disposed_tasks')}
                                        </th>
                                    </tr>
                                )}

                                {externTasks && (
                                    <tr>
                                        <td style={{ borderLeft: style.EXTERNAL_TASK.border }}>
                                            <div
                                                data-id={TaskType.EXTERNAL_TASK}
                                                onClick={this.handleClick}
                                                style={{ background: style.EXTERNAL_TASK.background }}
                                                className="colorselector"
                                            />
                                        </td>
                                        <td>
                                            <span>
                                                {t('colors.disposed')}&nbsp;
                                                <em className="glyphicon glyphicon-ok" />
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else return null;
    }
}

export default TaskSettings;
