import { connect } from 'react-redux';

import { deleteById, Entities, getEntities, patch, search } from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import CustomerAdministration from 'src/components/administration/customer-administration';
import { getOwnCompany } from 'src/selectors/bus-companies';

const mapStateToProps = state => {
    return {
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
        customers: getEntities(state, Entities.CUSTOMER, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        busCompany: getOwnCompany(state),
    };
};

const CustomerAdministrationContainer = connect(mapStateToProps, { search, patch, deleteById, addNotification })(
    CustomerAdministration,
);

export default CustomerAdministrationContainer;
