import { amber500, blue500, green500, orange500, red500 } from 'material-ui/styles/colors';
import TextField from 'material-ui/TextField';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

const strengthText = t => [
    t('password.very_weak'),
    t('password.weak'),
    t('password.acceptable'),
    t('password.strong'),
    t('password.very_strong'),
];

const strengthColor = [red500, orange500, amber500, blue500, green500];

class PasswordField extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            errorText: '',
            errorStyle: { color: red500 },
            value: props.value,
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            errorText: props.meta.touched && props.meta.error ? props.meta.error : '',
            errorStyle: props.meta.touched && props.meta.error ? { color: red500 } : this.state.errorStyle,
        });
    }

    onError(value, color) {
        this.setState({
            errorText: value,
            errorStyle: { color: color || red500 },
        });
    }

    onChange(value, field, t) {
        if (value) {
            require.ensure([], () => {
                const zxcvbn = require('zxcvbn');
                let strength = zxcvbn(value).score;
                if (!strength || strength < 0) strength = 0;
                if (strength > 4) strength = 4;
                this.onError(strengthText(t)[strength], strengthColor[strength]);
            });
        }

        if (field.input.onChange) field.input.onChange(value);
    }

    render() {
        const { t, ...field } = this.props;
        return (
            <TextField
                {...field.input}
                type="password"
                fullWidth={true}
                disabled={field.disabled}
                hintText={field.hintText}
                floatingLabelText={field.label}
                errorText={this.state.errorText}
                errorStyle={this.state.errorStyle}
                onChange={(event, value) => this.onChange(value, field, t)}
            />
        );
    }
}

export default PasswordField;
