import _ from 'lodash';

export enum Projection {
    DEFAULT = 'default',
    ID = 'id',
    FULL = 'full',
    BOOKING = 'booking',
}

interface Instantiable {
    new (...args: any[]): any;
}

interface ProjectionTypeMap {
    [projection: string]: Instantiable;
}

/**
 * Holding detailed description of an Entity.
 *
 * The projectionsTypeMap holds key value pairs of supported projections
 */
export default class EntityDescription {
    public name: string;
    public repository: string;
    public reducer: string;
    public action: any;
    public projectionsTypeMap: ProjectionTypeMap;
    public parent?: EntityDescription;
    public children?: EntityDescription[];

    constructor(name: string, repository: string, children: EntityDescription[] = []) {
        this.name = name;
        this.repository = repository;
        this.reducer = _.camelCase(repository);
        this.projectionsTypeMap = {};
        this.children = children;
    }

    public setParent(parent: EntityDescription) {
        this.parent = parent;
    }
}
