import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Checkbox from 'material-ui/Checkbox';

import { renderInput } from 'src/components/misc/redux-form-helpers';
import { priceFormatter } from 'src/utils/formatters';
import { sellTickets } from 'src/actions/event-tickets';

class TicketSellForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isPartner: true,
        };
    }

    render() {
        const { handleSubmit, availableTickets, ticketCollection, t } = this.props;

        const pricePerTicket = this.state.isPartner ? ticketCollection.internSellPrice : ticketCollection.sellPrice;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <strong>{t('ticket.available_tickets')}:</strong> {availableTickets}
                    </div>
                    <div className="col-md-6">
                        <strong>{t('ticket.price')}:</strong> {priceFormatter(pricePerTicket)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 voffset40">
                        <Checkbox
                            style={{ marginTop: '22px' }}
                            label={t('ticket.partner')}
                            title={t('ticket.partner')}
                            checked={this.state.isPartner}
                            onCheck={(event, checked) => {
                                this.setState({ isPartner: !this.state.isPartner });
                            }}
                        />
                    </div>
                    <div className="col-md-6 voffset">
                        <Field
                            name="quantity"
                            label={`${t('ticket.quantity')} *`}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 voffset40">{this.renderTotalPrice(pricePerTicket)}</div>
                </div>
            </form>
        );
    }

    renderTotalPrice(pricePerTicket) {
        const { quantity, t } = this.props;

        if (quantity)
            return (
                <span>
                    <strong>{t('ticket.sum_price')}&nbsp;</strong>
                    {quantity} x&nbsp;
                    {priceFormatter(pricePerTicket)} = &nbsp;
                    {priceFormatter({ amount: quantity * pricePerTicket.amount, currency: pricePerTicket.currency })}
                </span>
            );

        return <strong>{t('ticket.sum')} -</strong>;
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (values.quantity == null) errors.quantity = t('error_missing.fill_in_quantity');
    else if (values.quantity < 1) errors.quantity = t('ticket.error_at_least_one_ticket');
    else if (values.quantity > props.availableTickets) errors.quantity = t('ticket.error_no_tickets');

    return errors;
};

TicketSellForm = reduxForm({
    form: 'ticketSellForm',
    validate,
})(TicketSellForm);

const mapStateToProps = (state, ownProps) => {
    const { ticketCollection } = ownProps;

    return {
        initialValues: {
            id: ticketCollection.id,
            quantity: 1,
        },
        availableTickets: ticketCollection.valid ? ticketCollection.quantity - ticketCollection.countSold : 0,
        quantity: selector(state, 'quantity'),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: data => {
            dispatch(sellTickets(data.id, data.quantity));
            ownProps.handleClose();
        },
    };
};

const selector = formValueSelector('ticketSellForm');
TicketSellForm = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(TicketSellForm);

export default TicketSellForm;
