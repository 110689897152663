import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderRichTextEditor } from 'src/components/misc/redux-form-helpers';

class GlobalTemplateForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <input type="hidden" name="name" />

                <div className="row">
                    <div className="col-md-12">
                        <label>{t('common_phrases.text')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="text" placeholder="Text" noLinks={true} component={renderRichTextEditor} t={t} />
                    </div>
                </div>
            </form>
        );
    }
}

GlobalTemplateForm = reduxForm({
    form: 'GlobalTemplateForm',
})(GlobalTemplateForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, globalTemplate } = ownProps;

    let initialValues;

    if (!isCreate && globalTemplate)
        initialValues = {
            id: globalTemplate.id,
            name: globalTemplate.name,
            text: globalTemplate.text,
        };
    else initialValues = {};

    return {
        initialValues: initialValues,
    };
};

GlobalTemplateForm = connect(mapStateToProps, null, null, { withRef: true })(GlobalTemplateForm);

export default GlobalTemplateForm;
