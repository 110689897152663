import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
    Entities,
    fetchByCompanyId,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getActiveDrivers } from 'src/selectors/drivers';
import { EventType } from 'src/utils/constants';
import ErrorMessage from 'src/components/misc/error-message';
import DynamicEventFormCaller from 'src/components/events/DynamicEventFormCaller';

// update eventType when user clicks the corresponding
// radio button to distinguish which action will be called
export let eventType = null;

export function updateEventType(updatedEventType) {
    eventType = updatedEventType;
}

class EventModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };

        // set current eventType because of dynamic form
        updateEventType(props.eventType);
    }

    componentWillMount() {
        const { selectedCompany, id, isCreate } = this.props;

        // if form is used for edit an event -> fetch specific event to prevent stale data
        if (!isCreate) {
            switch (eventType) {
                case EventType.RELEASE:
                    this.props.fetchById(id, Entities.RELEASE, selectedCompany);
                    break;
                case EventType.BLOCKAGE:
                    this.props.fetchById(id, Entities.BLOCKAGE, selectedCompany);
                    break;
                case EventType.TASK:
                    this.props.fetchById(id, Entities.TASK, selectedCompany);
                    break;
                default:
                    break;
            }
        }

        this.props.fetchByCompanyId(Entities.DRIVER, selectedCompany);
        this.props.fetchByCompanyId(Entities.TEMPLATE, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { drivers } = nextProps;

        if (!this.state.startedFetching && drivers.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().callSubmit();
    }

    didFormChange() {
        return this.childForm.getWrappedInstance().didFormChange();
    }

    render() {
        const {
            drivers,
            event,
            initialValuesOnCreate,
            isCreate,
            isOffer,
            selectedCompany,
            busCompany,
            buses,
            templates,
            t,
        } = this.props;

        const finishFetching =
            this.state.startedFetching && !drivers.isFetching && !event.isFetching && !templates.isFetching;

        if (finishFetching) {
            if (drivers.error) return <ErrorMessage object={drivers} />;
            else if (event.error) return <ErrorMessage object={event} />;
            else if (templates.error) return <ErrorMessage object={templates} />;
            else {
                let initialValues, busLink, busAvailableSeats, releaseMode, fromToLocation;

                // populate initial values to create a new event
                if (isCreate && initialValuesOnCreate) {
                    // if event is created on specific bus -> get link of bus and available seats
                    if (initialValuesOnCreate.busId) {
                        const busOfTask = buses[initialValuesOnCreate.busId];
                        busLink = `${TOMCAT_URL}api/${Entities.BUS.repository}/${initialValuesOnCreate.busId}`;
                        busAvailableSeats =
                            busOfTask.seatsFacingFront + busOfTask.seatsFacingBack + busOfTask.seatsForGuides;
                        releaseMode = busOfTask.releaseMode;
                        fromToLocation = buses[initialValuesOnCreate.busId].homeBase;
                    } else {
                        fromToLocation = initialValuesOnCreate.fromLocation;
                    }

                    initialValues = {
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        name: initialValuesOnCreate.name,
                        postProcessingTime: busCompany.entity.defaultTaskPostProcessingTime,
                        cleared: false,
                        cancelled: false,
                        bus: busLink,
                        from: {
                            location: fromToLocation,
                            time: initialValuesOnCreate.start.format('DD.MM.YYYY HH:mm'),
                        },
                        to: {
                            location: fromToLocation,
                            time: initialValuesOnCreate.end.format('DD.MM.YYYY HH:mm'),
                        },
                        taskFrom: {
                            location: undefined,
                            time: initialValuesOnCreate.start.format('DD.MM.YYYY HH:mm'),
                        },
                        taskTo: {
                            location: undefined,
                            time: initialValuesOnCreate.end.format('DD.MM.YYYY HH:mm'),
                        },
                        passengers: 0,
                        percentage: 0,
                        notes: '',
                    };

                    if (isOffer) {
                        const notesTemplate = _.find(templates.items, {
                            name: `${t('events.offer')}: ${t('events.notes')}`,
                        });

                        initialValues.notes = notesTemplate ? notesTemplate.text : '';
                    }

                    // populate initial values to edit an existing event
                } else if (event && event.content) {
                    // if event has already assigned a bus -> assign the link
                    if (event.content.bus) {
                        const busOfTask = buses[event.content.bus];
                        busLink = `${TOMCAT_URL}api/${Entities.BUS.repository}/${event.content.bus}`;
                        busAvailableSeats =
                            busOfTask.seatsFacingFront + busOfTask.seatsFacingBack + busOfTask.seatsForGuides;
                    }

                    let initialTaskFields;
                    if (eventType === EventType.TASK) initialTaskFields = this.getInitialTaskFields(event, drivers);

                    initialValues = {
                        id: event.content.id,
                        name: event.content.name,
                        createdAt: event.content.createdAt,
                        bookingIdentifier: event.content.bookingIdentifier,
                        taskIdentifier: event.content.identifier,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        bus: busLink,
                        from: {
                            location: event.content.from.location,
                            time: moment(event.content.from.time).format('DD.MM.YYYY HH:mm'),
                        },
                        to: {
                            location: event.content.to.location,
                            time: moment(event.content.to.time).format('DD.MM.YYYY HH:mm'),
                        },
                        ...initialTaskFields,
                        notes: event.content.notes,
                        percentage: event.content.percentage,
                        price: event.content.price,
                        priceLog: event.content.priceLog,
                        postProcessingTime: event.content.postProcessingTime,
                        bookedTask: event.content.bookedTask,
                        cleared: event.content.cleared,
                        cancelled: event.content.cancelled,
                        detailsSentToDrivers: event.content.detailsSentToDrivers,
                        csBusDateId: event.content.csBusDateId,
                        externTaskId: event.content.externTaskId,
                        externCustomerId: event.content.externCustomerId,
                        ratioTransferNumber: event.content.ratioTransferNumber,
                        isRatioClient: busCompany.entity.ratioApiKey && busCompany.entity.ratioClient,
                        organizationPoNumber: event.content.organizationPoNumber,
                        organizationCostCenter: event.content.organizationCostCenter,
                    };
                }

                return (
                    <DynamicEventFormCaller
                        initialValues={initialValues}
                        selectedCompany={selectedCompany}
                        isCreate={isCreate}
                        isOffer={isOffer}
                        eventType={this.props.eventType}
                        onSubmit={this.handleSubmit}
                        busAvailableSeats={busAvailableSeats}
                        releaseMode={releaseMode}
                        drivers={drivers}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    getInitialTaskFields(event, drivers) {
        // transform strings to dates
        const intermediates = this.formatIntermediatesForForm(event.content.intermediates);

        // check for already assigned drivers
        let firstDriver, secondDriver;
        if (event.content.firstDriver)
            firstDriver = drivers.items.find(driver => driver.id === event.content.firstDriver);
        if (event.content.secondDriver)
            secondDriver = drivers.items.find(driver => driver.id === event.content.secondDriver);

        return {
            taskFrom: {
                location: event.content.taskFrom.location,
                time: moment(event.content.taskFrom.time).format('DD.MM.YYYY HH:mm'),
            },
            taskTo: {
                location: event.content.taskTo.location,
                time: moment(event.content.taskTo.time).format('DD.MM.YYYY HH:mm'),
            },
            passengers: event.content.passengers,
            firstDriver: firstDriver ? firstDriver._links.self.href : undefined,
            secondDriver: secondDriver ? secondDriver._links.self.href : undefined,
            intermediates: intermediates,
            customerContactData: event.content.customerContactData,
            reservation: event.content.reservation,
            sentDriverDetailsToCustomer: event.content.sentDriverDetailsToCustomer
                ? moment(event.content.sentDriverDetailsToCustomer).format('DD.MM.YYYY HH:mm')
                : undefined,
            bookedTask: event.content.bookedTask,
        };
    }

    convertToBackendFormat(data) {
        data.from.time = moment(data.from.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');
        data.to.time = moment(data.to.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');

        if (eventType === EventType.TASK) {
            data.taskFrom.time = moment(data.taskFrom.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');
            data.taskTo.time = moment(data.taskTo.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');

            if (data.intermediates)
                data.intermediates = data.intermediates.map(intermediate => {
                    return {
                        location: intermediate.location,
                        time: moment(intermediate.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm'),
                    };
                });

            if (data.sentDriverDetailsToCustomer)
                data.sentDriverDetailsToCustomer = moment(data.sentDriverDetailsToCustomer, 'DD.MM.YYYY HH:mm').format(
                    'YYYY-MM-DD[T]HH:mm',
                );

            data.priceDetails = data.price;
        }
    }

    /**
     * submit form data and dispatch appropriate action sending it to backend
     * @param data
     */
    handleSubmit(data) {
        const { handleClose, isCreate, isOffer, selectedCompany } = this.props;

        this.convertToBackendFormat(data);

        // dispatch appropriate action depending on event type
        switch (eventType) {
            case EventType.RELEASE:
                if (isCreate) this.props.save(data, Entities.RELEASE, selectedCompany);
                else this.props.patch(data, Entities.RELEASE, selectedCompany);
                break;
            case EventType.BLOCKAGE:
                if (isCreate) this.props.save(data, Entities.BLOCKAGE, selectedCompany);
                else this.props.patch(data, Entities.BLOCKAGE, selectedCompany);
                break;
            case EventType.TASK:
                // offer cannot be created or edited in calendar anymore
                if (!isOffer) {
                    if (isCreate) this.props.save(data, Entities.TASK, selectedCompany);
                    else this.props.patch(data, Entities.TASK, selectedCompany);
                }
                break;
            default:
                break;
        }

        handleClose();
    }

    formatIntermediatesForForm(intermediates) {
        if (!intermediates) return undefined;
        else {
            return intermediates.map(intermediate => {
                return {
                    location: intermediate.location,
                    time: moment(intermediate.time).format('DD.MM.YYYY HH:mm'),
                };
            });
        }
    }
}

const mapStateToProps = state => {
    let event = {
        isFetching: false,
    };

    if (eventType === EventType.RELEASE) event = getUpdatedEntity(state, Entities.RELEASE, state.selectedCompany);
    else if (eventType === EventType.BLOCKAGE)
        event = getUpdatedEntity(state, Entities.BLOCKAGE, state.selectedCompany);
    else if (eventType === EventType.TASK) event = getUpdatedEntity(state, Entities.TASK, state.selectedCompany);

    return {
        event: event,
        drivers: getActiveDrivers(state),
        busCompany: getOwnCompany(state),
        templates: getEntities(state, Entities.TEMPLATE, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        buses: state.entities[Entities.BUS.repository],
    };
};

export default connect(
    mapStateToProps,
    {
        save,
        patch,
        fetchById,
        fetchByCompanyId,
    },
    null,
    { withRef: true },
)(EventModal);
