import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push, RouterAction } from 'react-router-redux';

import {
    Entities,
    EntityCollectionState,
    fetchById,
    FetchById,
    getEntities,
    getUpdatedEntity,
    Projection,
    SingleEntityState,
    TOMCAT_URL,
} from 'src/common/index';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { InjectedTranslateProps, StoreState } from 'src/types';
import OperatingToolIntegration from 'src/common/entity/integration/OperatingToolIntegration';
import ParentForm from 'src/components/misc/parent-form';
import OperatingToolIntegrationForm from 'src/components/admin/integration/operatingtool/OperatingToolIntegrationForm';
import { LocationDescriptor, LocationState } from 'history';
import {
    createReportForIntegration,
    fetchOperatingIntegrationReportsByIntegration,
} from 'src/actions/operating-tool-reports';
import OperatingToolDataImportReport from 'src/common/entity/report/OperatingToolDataImportReport';
import moment from 'moment';
import ImportCountType from 'src/common/entity/basic-types/ImportCountType';
import Document from 'src/common/entity/basic-types/Document';
import { ButtonColors } from 'src/utils/constants';
import ConfirmDialog from 'src/components/misc/confirm-dialog';

interface MapStateToProps {
    integration: SingleEntityState<OperatingToolIntegration>;
    reports: EntityCollectionState<OperatingToolDataImportReport>;
}

interface MapDispatchToProps extends FetchById {
    push: (location: LocationDescriptor, state?: LocationState) => RouterAction;
    fetchOperatingIntegrationReportsByIntegration: (integrationId: number, projection?: string) => void;
    createReportForIntegration: (integrationId: number, projection?: string) => void;
}

interface InjectedProps {
    params: any;
}

interface TableDataRow {
    id: number;
    createdAt: string;
    errors: number;
    warnings: number;
    updates: number;
    fleetsImportStats: ImportCountType;
    companiesImportStats: ImportCountType;
    accountsImportStats: ImportCountType;
    busesImportStats: ImportCountType;
    document: Document;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedProps & InjectedTranslateProps;

class OperatingToolIntegrationDetails extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    public componentWillMount() {
        const integrationId = this.props.params.id;
        this.props.fetchById(integrationId, Entities.OPERATINGTOOL_INTEGRATION);
        this.props.fetchOperatingIntegrationReportsByIntegration(integrationId);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { integration, reports } = nextProps;

        if (!this.state.startedFetching && integration.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(reports.items.length);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.reports, this.props.reports, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    public render() {
        const { integration, reports, t } = this.props;
        const finishFetching = this.state.startedFetching && !integration.isFetching && !reports.isFetching;

        if (integration.error) return <ErrorMessage object={integration} />;
        if (reports.error) return <ErrorMessage object={reports} />;

        const tableData: TableData = [];

        if (!integration.content) return null;

        if (finishFetching && reports.items.length > 0) {
            reports.items
                .sort((r1: OperatingToolDataImportReport, r2: OperatingToolDataImportReport) => {
                    if (r1.createdAt < r2.createdAt) return 1;
                    if (r1.createdAt > r2.createdAt) return -1;
                    return 0;
                })
                .map((report: OperatingToolDataImportReport) => {
                    tableData.push({
                        id: report.id,
                        createdAt: moment(report.createdAt).format('DD.MM.YYYY HH:mm:ss'),
                        errors: report.errors,
                        warnings: report.warnings,
                        updates: report.updates,
                        fleetsImportStats: report.fleetsImportStats,
                        companiesImportStats: report.companiesImportStats,
                        accountsImportStats: report.accountsImportStats,
                        busesImportStats: report.busesImportStats,
                        document: report.document,
                    });
                });
        }

        return (
            <div>
                {this.renderLegend(integration.content)}

                {(() => {
                    if (reports.error) return <ErrorMessage object={reports} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="createdAt" dataSort={true}>
                                    {t('task_details.generated_at')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={cell => this.formatCount(cell, 'error')}
                                    dataField="errors"
                                    dataAlign="center"
                                    dataSort={true}>
                                    {t('errors')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={cell => this.formatCount(cell, 'warning')}
                                    dataField="warnings"
                                    dataAlign="center"
                                    dataSort={true}>
                                    {t('warnings')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={cell => this.formatCount(cell, 'success')}
                                    dataField="updates"
                                    dataAlign="center"
                                    dataSort={true}>
                                    Updates
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText="Created / Updated / Missing"
                                    dataField="fleetsImportStats"
                                    dataAlign="center"
                                    dataFormat={this.statsCountFormatter}
                                    dataSort={true}>
                                    {t('fleets.fleets')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText="Created / Updated / Missing"
                                    dataField="companiesImportStats"
                                    dataAlign="center"
                                    dataFormat={this.statsCountFormatter}
                                    dataSort={true}>
                                    {t('common_phrases.company')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText="Created / Updated / Missing"
                                    dataField="accountsImportStats"
                                    dataAlign="center"
                                    dataFormat={this.statsCountFormatter}
                                    dataSort={true}>
                                    {t('fleets.accounts')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText="Created / Updated / Missing"
                                    dataField="busesImportStats"
                                    dataAlign="center"
                                    dataFormat={this.statsCountFormatter}
                                    dataSort={true}>
                                    {t('companies.buses')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="110"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    private statsCountFormatter = (countStats: ImportCountType) => {
        return (
            <div>
                {this.formatCount(countStats.created, 'success')}
                <span> / </span>
                {this.formatCount(countStats.updated, 'success')}
                <span> / </span>
                {this.formatCount(countStats.missing, 'warning')}
            </div>
        );
    };

    private formatCount = (count: number, level: string) => {
        if (count < 1) return <span>{count}</span>;

        let color = 'black';
        if (level === 'error') color = 'red';
        if (level === 'warning') color = '#FF652D';
        if (level === 'success') color = 'green';

        return <strong style={{ color }}>{count}</strong>;
    };

    private renderLegend = (integration: OperatingToolIntegration) => {
        const { t } = this.props;
        return (
            <legend className="legend">
                {t('task_details.imported_from')} {integration.name}
                <button
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.props.push('/admin/integrations/operatingtool');
                    }}>
                    <span className="glyphicon glyphicon-circle-arrow-left" />
                    &nbsp;{t('common_phrases.back')}
                </button>
                <button
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.OPERATINGTOOL_INTEGRATION,
                                    id: integration.id,
                                },
                                projection: Projection.FULL,
                                childForm: {
                                    component: OperatingToolIntegrationForm,
                                },
                            },
                            title: integration.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil" />
                    &nbsp;{t('user_functionality.edit_integration')}
                </button>
                <button
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: integration,
                                onProceedDialog: (integration: OperatingToolIntegration) =>
                                    this.props.createReportForIntegration(integration.id),
                                bodyText: t('task_details.hint_import'),
                                proceedText: t('user_functionality.start_import'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.SUCCESS,
                            },
                            title: t('data_import_for', { integrationName: integration.name }),
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-cloud-download" />
                    &nbsp;{t('user_functionality.import_data')}
                </button>
            </legend>
        );
    };

    private tableActionsFormatter = (cell: any, report: TableDataRow) => {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.open_report')}
                    onClick={() => {
                        const url = `${TOMCAT_URL}api/ot-integration-reports/${report.id}/printDocument.pdf`;
                        const win = window.open(url, '_blank');
                        if (win != null) win.focus();
                    }}>
                    <span className="glyphicon glyphicon-file text-danger" />
                </button>
            </div>
        );
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        integration: getUpdatedEntity(state, Entities.OPERATINGTOOL_INTEGRATION),
        reports: getEntities(state, Entities.OPERATING_TOOL_DATA_IMPORT_REPORT),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        push,
        fetchById,
        fetchOperatingIntegrationReportsByIntegration,
        createReportForIntegration,
    })(composeModalActions(OperatingToolIntegrationDetails)),
);
