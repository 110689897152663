import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminAccountAdministration from 'src/components/admin/accounts/admin-account-administration';

const mapStateToProps = state => {
    return {
        operators: getEntities(state, Entities.OPERATOR),
        dispatchers: getEntities(state, Entities.DISPATCHER),
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
    };
};

const AdminAccountAdministrationContainer = connect(mapStateToProps, { fetchAll, deleteById, patch })(
    AdminAccountAdministration,
);

export default AdminAccountAdministrationContainer;
