import { DOCUMENTS_FAILURE, DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS } from 'src/actions/documents';

export default function documentsReducer(state = {}, action) {
    switch (action.type) {
        case DOCUMENTS_REQUEST:
            return Object.assign({}, state, {
                uploading: true,
            });
        case DOCUMENTS_SUCCESS:
        case DOCUMENTS_FAILURE:
            return Object.assign({}, state, {
                uploading: false,
            });
        default:
            return state;
    }
}
