import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { EventType, ReleaseMode } from 'src/utils/constants';
import EventForm from 'src/components/events/event-form';
import { updateEventType } from 'src/components/events/event-modal';

class DynamicEventFormCaller extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            selectedType: props.eventType,
            transition: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { initialValues, t } = nextProps;

        // set name for current form type depending on state
        if (this.state.selectedType === EventType.TASK) initialValues.name = t('events.task');
        else {
            if (this.state.selectedType === EventType.BLOCKAGE) initialValues.name = t('events.blockage');
            else if (this.state.selectedType === EventType.RELEASE) initialValues.name = t('events.release');
        }
    }

    componentDidUpdate() {
        if (this.state.transition) this.setState({ transition: false });
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().submit();
    }

    didFormChange() {
        return !this.childForm.getWrappedInstance().pristine;
    }

    render() {
        const {
            eventType,
            isCreate,
            isOffer,
            initialValues,
            drivers,
            busAvailableSeats,
            selectedCompany,
            releaseMode,
            t,
        } = this.props;

        const radioButtonStyle = { display: 'inline-block', width: '150px' };
        const radioButtons = [
            <RadioButton
                key={EventType.TASK}
                value={EventType.TASK}
                label={t('events.task')}
                style={radioButtonStyle}
            />,
            <RadioButton
                key={EventType.RELEASE}
                value={EventType.RELEASE}
                label={t('events.release')}
                style={radioButtonStyle}
            />,
        ];

        if (releaseMode === ReleaseMode.RELEASED) {
            radioButtons.push(
                <RadioButton
                    key={EventType.BLOCKAGE}
                    value={EventType.BLOCKAGE}
                    label={t('events.blockage')}
                    style={radioButtonStyle}
                />,
            );
        }

        // if the form is used to edit an event -> display appropriate form
        if (!isCreate || eventType === EventType.TASK) {
            return (
                <EventForm
                    t={t}
                    initialValues={initialValues}
                    isBookedTask={this.props.isBookedTask}
                    selectedCompany={selectedCompany}
                    onSubmit={this.props.onSubmit}
                    drivers={drivers}
                    isOffer={isOffer}
                    eventType={eventType}
                    busAvailableSeats={busAvailableSeats}
                    ref={ref => (this.childForm = ref)}
                />
            );

            // else generate the form dynamic on users choice of event type
        } else {
            return (
                <div>
                    <div>
                        <br />
                        <RadioButtonGroup
                            name="eventType"
                            defaultSelected={this.state.selectedType}
                            onChange={(event, value) => {
                                if (value !== this.state.selectedType) {
                                    // update the current form type, and set transition=true to re-render the form
                                    this.setState({ selectedType: value, transition: true });
                                    updateEventType(value);
                                }
                            }}>
                            {radioButtons.map(radioButton => radioButton)}
                        </RadioButtonGroup>
                    </div>
                    <br />
                    {!this.state.transition && (
                        <EventForm
                            t={t}
                            initialValues={initialValues}
                            selectedCompany={selectedCompany}
                            onSubmit={this.props.onSubmit}
                            drivers={drivers}
                            isOffer={isOffer}
                            eventType={this.state.selectedType}
                            busAvailableSeats={busAvailableSeats}
                            ref={ref => (this.childForm = ref)}
                        />
                    )}
                </div>
            );
        }
    }
}

DynamicEventFormCaller.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default connect(null, { destroy }, null, { withRef: true })(DynamicEventFormCaller);
