import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import EngineEditModal from 'src/components/admin/engines/engine-edit-modal';
import { withTranslation } from 'react-i18next';

class AdminEngineAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BUS_ENGINE);
    }

    componentWillReceiveProps(nextProps) {
        const { engines } = nextProps;

        if (engines.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { engines, t } = this.props;

        const finishFetching = this.state.startedFetching && !engines.isFetching;

        // options for react bootstrap table
        const options = {
            exportCSVText: t('common_phrases.export_list'),
            noDataText: t('engines.no_euronorm_found'),
            clearSearch: true,
        };

        const tableData = [];

        if (finishFetching && engines.items.length > 0) {
            engines.items.map(engine => {
                tableData.push({
                    id: engine.id,
                    name: engine.name,
                    rank: engine.rank,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('engines.euronorm_other')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: EngineEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('engines.new_euronorm'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('engines.new_euronorm')}
                    </button>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (engines.error) return <ErrorMessage object={engines} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                options={options}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="rank" dataSort={true}>
                                    {t('engines.environmental_factor')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, engine) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: EngineEditModal,
                            componentProps: {
                                id: engine.id,
                                isCreate: false,
                            },
                            title: engine.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminEngineAdministration));
