import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderRichTextEditor } from 'src/components/misc/redux-form-helpers';

class TemplateEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <input type="hidden" name="name" />

                <div className="row">
                    <div className="col-md-12">
                        <label>{t('common_phrases.text')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="text" placeholder="Text" noLinks={true} component={renderRichTextEditor} t={t} />
                    </div>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'templateEditForm',
})(TemplateEditForm);
