import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    asyncRequest,
    DeleteById,
    Entities,
    EntityCollectionState,
    FetchBySearch,
    getEntities,
    Projection,
    search,
    TOMCAT_URL,
} from 'src/common/index';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { dateFormatter, periodFormatter, priceFormatter, sumFormatter } from 'src/utils/formatters';
import { InjectedTranslateProps, StoreState } from 'src/types';
import FileSaver from 'file-saver';
import { sortMoney } from 'src/utils/currency';

interface MapStateToProps {
    selectedCompany: number;
    invoices: EntityCollectionState<any>;
}

interface MapDispatchToProps extends FetchBySearch, DeleteById {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedTranslateProps;

class InvoiceAdministration extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    public componentWillMount() {
        const { selectedCompany } = this.props;
        this.props.search(
            Entities.INVOICE,
            `fetchByCompanyId?companyId=${selectedCompany}`,
            selectedCompany,
            Projection.DEFAULT,
            true,
        );
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { invoices } = nextProps;

        if (!this.state.startedFetching && invoices.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(invoices.items.length);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.invoices, this.props.invoices, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    public render() {
        const { invoices, t } = this.props;
        const finishFetching = this.state.startedFetching && !invoices.isFetching;

        let tableData: any = [];

        if (finishFetching && invoices.items.length > 0) {
            tableData = invoices.items;
        }

        return (
            <div>
                <legend className="legend">{t('invoice.invoices')}</legend>

                {(() => {
                    if (invoices.error) return <ErrorMessage object={invoices} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="invoiceDate"
                                    width="180"
                                    dataFormat={dateFormatter}
                                    dataSort={true}>
                                    {t('invoice.invoice_date')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="periodFrom"
                                    width="180"
                                    dataFormat={periodFormatter}
                                    dataSort={true}>
                                    {t('invoice.period')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="formattedInvoiceNumber"
                                    width="180"
                                    dataSort={true}>
                                    {t('invoice.invoice_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="totalNetPrice"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    sortFunc={sortMoney}
                                    dataAlign="right"
                                    width="150"
                                    dataSort={true}>
                                    {t('invoice.net')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="totalVATPrice"
                                    dataFormat={this.vatFormatter}
                                    csvFormat={this.vatFormatter}
                                    sortFunc={sortMoney}
                                    dataAlign="right"
                                    width="150"
                                    dataSort={true}>
                                    {t('invoice.vat')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="totalGrossPrice"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    sortFunc={sortMoney}
                                    dataAlign="right"
                                    width="150"
                                    dataSort={true}>
                                    {t('invoice.gross')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="totalRedeemedCouponValue"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    sortFunc={sortMoney}
                                    dataAlign="right"
                                    width="150"
                                    dataSort={true}>
                                    {t('coupons.coupons')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="totalReducedPrice"
                                    dataFormat={sumFormatter}
                                    csvFormat={priceFormatter}
                                    sortFunc={sortMoney}
                                    dataAlign="right"
                                    width="150"
                                    dataSort={true}>
                                    {t('invoice.sum')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    private tableActionsFormatter = (cell: any, invoice: any) => {
        const { t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.INVOICE.repository}/printInvoice?invoiceId=${invoice.id}`,
                        )
                            .then(response => {
                                response.blob().then((blob: any) => {
                                    FileSaver.saveAs(
                                        blob,
                                        `${invoice.formattedInvoiceNumber}_${invoice.company.companyName}_${moment(
                                            invoice.invoiceDate,
                                        ).format('DD-MM-YYYY')}.pdf`,
                                    );
                                });
                            })
                            .catch(error => {
                                console.error('download error', error);
                            });
                    }}>
                    <span className="glyphicon glyphicon-download-alt text-success" title={t('download_pdf')} />
                </button>
            </div>
        );
    };

    vatFormatter(vat: number, invoice: any) {
        if (invoice.reverseCharge) return '-';
        return priceFormatter(vat);
    }
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedCompany: state.selectedCompany,
        invoices: getEntities(state, Entities.INVOICE, state.selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        search,
    })(composeModalActions(InvoiceAdministration)),
);
