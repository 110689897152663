import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
    Entities,
    fetchByCompanyId,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    save,
    setMoneyCurrency,
    TOMCAT_URL,
} from 'src/common/index';
import { setSliderIndex } from 'src/actions/form';
import ErrorMessage from 'src/components/misc/error-message';
import CalculationEditForm from 'src/components/administration/calculation-edit-form';
import { getOwnCompany } from 'src/selectors/bus-companies';

class CalculationEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.CALCULATION, selectedCompany);
        else this.setState({ startedFetching: true });

        this.props.fetchByCompanyId(Entities.SEASON_CALCULATION, selectedCompany);
        this.props.fetchByCompanyId(Entities.COUNTRY_CALCULATION, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { calculation } = nextProps;

        if (calculation.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().submit();
    }

    didFormChange() {
        return !this.childForm.getWrappedInstance().pristine;
    }

    render() {
        const { calculation, seasonCalculations, countryCalculations, selectedCompany, isCreate, ownCompany, t } =
            this.props;

        const finishFetching =
            this.state.startedFetching &&
            !calculation.isFetching &&
            !seasonCalculations.isFetching &&
            !countryCalculations.isFetching;

        if (finishFetching) {
            if (calculation.error) return <ErrorMessage object={calculation} />;
            else {
                let initialValues;
                if (!isCreate && calculation.content) {
                    let seasonCalculation;
                    if (calculation.content.seasonCalculation)
                        seasonCalculation = seasonCalculations.items.find(
                            seasonCalculation => seasonCalculation.id === calculation.content.seasonCalculation,
                        );
                    let countryCalculation;
                    if (calculation.content.countryCalculation)
                        countryCalculation = countryCalculations.items.find(
                            countryCalculation => countryCalculation.id === calculation.content.countryCalculation,
                        );

                    initialValues = {
                        id: calculation.content.id,
                        name: calculation.content.name,
                        kilometreRate: calculation.content.kilometreRate,
                        driverRate: calculation.content.driverRate,
                        driverRoomPrice: calculation.content.driverRoomPrice,
                        extraKilometreRate: calculation.content.extraKilometreRate,
                        extraHourRate: calculation.content.extraHourRate,
                        arrivalBreakFee: calculation.content.arrivalBreakFee,
                        returnBreakFee: calculation.content.returnBreakFee,
                        splitOperation: calculation.content.splitOperation,
                        forbidSecondDriver: calculation.content.forbidSecondDriver,
                        arrivalReturnFlatRate: calculation.content.arrivalReturnFlatRate,
                        oneDayTripFlatRate: calculation.content.oneDayTripFlatRate,
                        oneDayTripMaxKilometres: calculation.content.oneDayTripMaxKilometres,
                        oneDayTripMaxHours: calculation.content.oneDayTripMaxHours,
                        halfDayTripFlatRate: calculation.content.halfDayTripFlatRate,
                        halfDayTripMaxKilometres: calculation.content.halfDayTripMaxKilometres,
                        halfDayTripMaxHours: calculation.content.halfDayTripMaxHours,
                        specialVehicle: calculation.content.specialVehicle,
                        specialVehicleMaxKilometres: calculation.content.specialVehicleMaxKilometres,
                        onlyForDailyTrips: calculation.content.onlyForDailyTrips,
                        onlyForDailyFlatRate: calculation.content.onlyForDailyFlatRate,
                        skipAvailabilityCheck: calculation.content.skipAvailabilityCheck,
                        arrivalFees: calculation.content.arrivalFees,
                        travelFees: calculation.content.travelFees,
                        occupancySurcharges: calculation.content.occupancySurcharges,
                        globalOccupancySurcharges: calculation.content.globalOccupancySurcharges,
                        standingHourRates: calculation.content.standingHourRates,
                        operatingHourRates: calculation.content.operatingHourRates,
                        secondDriverStandingRateFactor: calculation.content.secondDriverStandingRateFactor,
                        secondDriverOperatingRateFactor: calculation.content.secondDriverOperatingRateFactor,
                        seasonCalculation: seasonCalculation ? seasonCalculation._links.self.href : undefined,
                        countryCalculation: countryCalculation ? countryCalculation._links.self.href : undefined,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                } else {
                    initialValues = {
                        arrivalFees: [],
                        travelFees: [],
                        standingHourRates: [],
                        operatingHourRates: [],
                        occupancySurcharges: [],
                        globalOccupancySurcharges: [],
                        splitOperation: false,
                        forbidSecondDriver: false,
                        specialVehicle: false,
                        onlyForDailyTrips: false,
                        onlyForDailyFlatRate: false,
                        kilometreRate: { amount: 1.3 },
                        driverRate: { amount: 30 },
                        driverRoomPrice: { amount: 100 },
                        extraKilometreRate: { amount: 1.5 },
                        extraHourRate: { amount: 35 },
                        arrivalBreakFee: { amount: 100 },
                        returnBreakFee: { amount: 100 },
                        secondDriverStandingRateFactor: 0.75,
                        secondDriverOperatingRateFactor: 0.75,
                        arrivalReturnFlatRate: { amount: 0 },
                        halfDayTripFlatRate: { amount: 0 },
                        oneDayTripFlatRate: { amount: 0 },
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }

                return (
                    <CalculationEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        calculation={calculation.content}
                        seasonCalculations={seasonCalculations}
                        countryCalculations={countryCalculations}
                        selectedCompany={selectedCompany}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        arrivalReturnFlatRateCalculation={ownCompany.entity.arrivalReturnFlatRateCalculation}
                        calculationSkipAvailabilityCheck={ownCompany.entity.calculationSkipAvailabilityCheck}
                        occupancySurcharges={ownCompany.entity.occupancySurcharges}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate, selectedCompany, ownCompany } = this.props;

        this.formatDataForBackend(data, ownCompany);

        if (isCreate) this.props.save(data, Entities.CALCULATION, selectedCompany);
        else this.props.patch(data, Entities.CALCULATION, selectedCompany);

        handleClose();
    }

    formatDataForBackend(data, ownCompany) {
        setMoneyCurrency(data.kilometreRate, ownCompany.entity.currency);
        setMoneyCurrency(data.driverRate, ownCompany.entity.currency);
        setMoneyCurrency(data.driverRoomPrice, ownCompany.entity.currency);
        setMoneyCurrency(data.extraHourRate, ownCompany.entity.currency);
        setMoneyCurrency(data.extraKilometreRate, ownCompany.entity.currency);
        setMoneyCurrency(data.arrivalBreakFee, ownCompany.entity.currency);
        setMoneyCurrency(data.returnBreakFee, ownCompany.entity.currency);
        setMoneyCurrency(data.arrivalReturnFlatRate, ownCompany.entity.currency);
        setMoneyCurrency(data.halfDayTripFlatRate, ownCompany.entity.currency);
        setMoneyCurrency(data.oneDayTripFlatRate, ownCompany.entity.currency);

        Object.keys(data.arrivalFees).forEach(key => {
            setMoneyCurrency(data.arrivalFees[key].price, ownCompany.entity.currency);
        });
        Object.keys(data.travelFees).forEach(key => {
            setMoneyCurrency(data.travelFees[key].price, ownCompany.entity.currency);
        });
        Object.keys(data.standingHourRates).forEach(key => {
            setMoneyCurrency(data.standingHourRates[key].rate, ownCompany.entity.currency);
        });
        Object.keys(data.operatingHourRates).forEach(key => {
            setMoneyCurrency(data.operatingHourRates[key].rate, ownCompany.entity.currency);
        });
    }
}

const mapStateToProps = state => {
    return {
        calculation: getUpdatedEntity(state, Entities.CALCULATION, state.selectedCompany),
        seasonCalculations: getEntities(state, Entities.SEASON_CALCULATION, state.selectedCompany),
        countryCalculations: getEntities(state, Entities.COUNTRY_CALCULATION, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
        ownCompany: getOwnCompany(state),
    };
};

export default connect(mapStateToProps, { fetchById, fetchByCompanyId, patch, save, setSliderIndex }, null, {
    withRef: true,
})(CalculationEditModal);
