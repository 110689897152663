import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox } from 'src/components/misc/redux-form-helpers';

class MarkAsReceivedForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { onSubmit, t, task } = this.props;

        return (
            <form className="form-horizontal" onSubmit={onSubmit}>
                <div className="row ">
                    <div className="col-md-12">
                        <label>
                            {task.bookingIntegration
                                ? t('booked_tasks.received_information_send_mail_choice')
                                : t('booked_tasks.received_information')}
                        </label>
                    </div>
                </div>
                {task.bookingIntegration != null && (
                    <div className="row voffset">
                        <div className="col-md-12">
                            <Field name="sendMail" label={t('print.send_email_to_driver')} component={renderCheckbox} />
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

export default reduxForm({
    form: 'markAsReceivedForm',
})(MarkAsReceivedForm);
