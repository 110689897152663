import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { Entities, getEntities } from 'src/common/index';

import { fetchTicketEventsJoinFetchTickets } from 'src/actions/event-tickets';
import TicketEventAdministration from 'src/components/administration/event-tickets/events/ticket-event-administration';

const mapStateToProps = state => {
    return {
        ticketEvents: getEntities(state, Entities.TICKET_EVENT),
    };
};

const TicketEventAdministrationContainer = connect(mapStateToProps, {
    fetchTicketEventsJoinFetchTickets,
    push,
})(TicketEventAdministration);

export default TicketEventAdministrationContainer;
