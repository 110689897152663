import SearchBusesRequestStats from '../request/SearchBusesRequestStats';
import RequestFee from '../basic-types/RequestFee';
import BookingModule from '../module/BookingModule';
import { fromJsonArray, fromJsonArrayWith } from '../index';
import CompanyIdName from '../company/CompanyIdName';
import BaseEntity from '../BaseEntity';

export default class DetailsBookingIntegration extends BaseEntity {
    public name: string;
    public url: string;
    public active: boolean;
    public fee: number;
    public feeType: FeeType;
    public allCompanies: boolean;
    public companies: CompanyIdName[];
    public modules: BookingModule[];
    public requestFees: RequestFee[];
    public requestStats: SearchBusesRequestStats;

    constructor(json: any) {
        super(json);
        this.name = json.name;
        this.url = json.url;
        this.active = json.active;
        this.fee = json.fee;
        this.feeType = json.feeType as FeeType;
        this.allCompanies = json.allCompanies;
        this.companies = fromJsonArray(CompanyIdName, json.companies);
        this.modules = fromJsonArray(BookingModule, json.modules);
        this.requestFees = fromJsonArrayWith(RequestFee.fromJson, json.requestFees);
        this.requestStats = new SearchBusesRequestStats(json.requestStats);
    }

    public toString(): string {
        return `${this.name} - ${this.url}`;
    }
}

export enum FeeType {
    PROVISION = 'PROVISION',
    PRICE_PER_REQUEST = 'PRICE_PER_REQUEST',
}
