import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class ReviewCategoryEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="category" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="factor"
                            label={t('review_categories.factor')}
                            component={renderInput}
                            type="number"
                            step={0.1}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="position"
                            label={t('review_categories.position')}
                            hintText={t('review_categories.position_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.category)) errors.category = t('error_missing.fill_in_name');

    if (!isInteger(values.position)) errors.position = t('error_hint.error_integer');
    if (values.position < -100 || values.position > 100) errors.position = t('bus.error_order');

    if (values.factor < 0 || values.factor > 1) errors.factor = t('review_categories.fill_in_factor');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.REVIEW_CATEGORY.repository}/search/findByCategory?category=${encodeURIComponent(
            values.category,
        )}`,
        values.id,
        'category',
    );
};

export default reduxForm({
    form: 'reviewCategoryEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['category'],
})(ReviewCategoryEditForm);
