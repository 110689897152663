import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { isInteger } from 'src/common/index';
import { renderInput } from 'src/components/misc/redux-form-helpers';

class AgeRatingEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="rating"
                            label={t('common_phrases.rating')}
                            hintText={t('age_rating.rating_tag')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!isInteger(values.rating)) errors.rating = t('error_hint.error_integer');
    if (values.rating < 0 || values.rating > 100) errors.rating = t('age_rating.rating_hint');

    return errors;
};

export default reduxForm({
    form: 'ageRatingEditForm',
    validate,
})(AgeRatingEditForm);
