export const SET_CALENDAR_DATE_RANGE = 'SET_CALENDAR_DATE_RANGE';

export function setCalendarDateRange(start, end) {
    return {
        type: SET_CALENDAR_DATE_RANGE,
        payload: {
            start,
            end,
        },
    };
}
