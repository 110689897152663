import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { List, ListItem, makeSelectable } from 'material-ui/List';
import { push } from 'react-router-redux';
import { blue700 } from 'material-ui/styles/colors';
import EventIcon from 'material-ui/svg-icons/action/date-range';
import CategoryIcon from 'material-ui/svg-icons/action/list';
import { withTranslation } from 'react-i18next';

let SelectableList = makeSelectable(List);

const items = ['/admin/event-tickets/events', '/admin/event-tickets/categories'];

class EventTicketNavigation extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            selectedIndex: Math.max(items.indexOf(window.location.pathname + window.location.search), 0),
        };
    }

    render() {
        const { push, t } = this.props;

        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <SelectableList
                        value={this.state.selectedIndex}
                        onChange={(event, index) => this.setState({ selectedIndex: index })}>
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={0}
                            value={0}
                            primaryText={t('common_phrases.events')}
                            leftIcon={<EventIcon color={blue700} />}
                            onClick={() => push('/admin/event-tickets/events')}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={1}
                            value={1}
                            primaryText={t('common_phrases.categories')}
                            leftIcon={<CategoryIcon color={blue700} />}
                            onClick={() => push('/admin/event-tickets/categories')}
                        />
                    </SelectableList>
                </div>
                <div className="col-md-10">{this.props.children}</div>
            </div>
        );
    }
}

export default withTranslation()(connect(null, { push })(EventTicketNavigation));
