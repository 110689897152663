import 'isomorphic-fetch';
import Cookies from 'js-cookie';
import FormData from 'form-data';
import HttpStatus from 'http-status-codes';
import { AUTHENTICATION_TOKEN_HEADER, AUTHENTICATION_TOKEN_STORAGE_KEY, UI_URL } from './index';

const JSON_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

interface Headers {
    [name: string]: string;
}

interface FetchProps {
    credentials: RequestCredentials;
    method: string;
    headers: Headers;
    body?: any;
}

export function asyncRequest(props: any) {
    // accept single string parameter or object with endpoint property as endpoint
    const endpoint = typeof props === 'string' ? props : props.endpoint;

    if (!endpoint) throw 'Missing endpoint for asyncRequest';

    const isFormData = props.body instanceof FormData;

    const fetchProps: FetchProps = {
        credentials: 'include',
        method: props.method ? props.method : 'GET',
        headers: props.headers ? props.headers : isFormData ? {} : JSON_HEADERS,
    };

    fetchProps.headers[AUTHENTICATION_TOKEN_HEADER] = localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY) || '';

    if (props.body) fetchProps.body = isFormData ? props.body : JSON.stringify(props.body);

    console.log(fetchProps.method, endpoint, fetchProps);

    // returns the promise from fetch
    return fetch(endpoint, fetchProps)
        .then((response: any) => {
            // read the authentication token header and save it in local storage
            if (response.headers.get(AUTHENTICATION_TOKEN_HEADER.toLowerCase()) != null) {
                localStorage.setItem(
                    AUTHENTICATION_TOKEN_STORAGE_KEY,
                    response.headers.get(AUTHENTICATION_TOKEN_HEADER.toLowerCase()),
                );
            }

            // success
            if (response.ok) {
                // if the response has content type json, return the status and the response body parsed as json
                if (hasContentTypeJSON(response)) {
                    return response.json().then((json: any) => {
                        console.log(`${fetchProps.method} ${endpoint} success:`, response.status, 'json:', json);
                        return {
                            status: response.status,
                            json: json,
                            headers: response.headers,
                        };
                    });
                }
                // if the response has content type text, return the status and the response body parsed as text
                else if (hasContentTypeText(response)) {
                    return response.text().then((text: any) => {
                        console.log(`${fetchProps.method} ${endpoint} success:`, response.status, 'text:', text);
                        return {
                            status: response.status,
                            text: text,
                            headers: response.headers,
                        };
                    });
                }

                // otherwise return the response object
                else {
                    console.log(`${fetchProps.method} ${endpoint} success:`, response.status);
                    return response;
                }
            }

            // error
            else throw response;
        })
        .catch((error: any) => {
            console.warn(`${fetchProps.method} ${endpoint} error:`, error.status);

            if (error.status === HttpStatus.UNAUTHORIZED) {
                if (props.redirectURLAfterLogin)
                    Cookies.set('redirectURLAfterLogin', props.redirectURLAfterLogin, {
                        secure: true,
                        sameSite: 'none',
                    });

                if (!props.noRedirectOnUnauthenticated) window.location.replace(`${UI_URL}login?expired=true`);
            }

            // rethrow error in case the caller wants to handle it
            throw error;
        });
}

function hasContentTypeJSON(response: any): boolean {
    const contentType = response.headers.get('Content-Type');
    return (
        typeof contentType === 'string' &&
        (contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/hal+json') !== -1)
    );
}

function hasContentTypeText(response: any): boolean {
    const contentType = response.headers.get('Content-Type');
    return typeof contentType === 'string' && contentType.indexOf('text/plain') !== -1;
}
