import { connect } from 'react-redux';

import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { selectEvent } from 'src/actions/events';
import { addNotification } from 'src/actions/notifications';
import {
    acceptExchangedTask,
    declineExchangedTask,
    fetchTasksByFilter,
    setFetchMethodOnError,
} from 'src/actions/tasks';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getDetails } from 'src/selectors/details';
import { getAllFilteredTasks } from 'src/selectors/tasks';
import TaskExchange from 'src/components/taskexchange/task-exchange';

const mapStateToProps = state => {
    return {
        taskFilter: state.taskFilter,
        busCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
        tasks: getAllFilteredTasks(state),

        eventDetails: getDetails(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        acceptExchangedTask: (taskId, foreignCompanyId, busCompanyId) => {
            dispatch(acceptExchangedTask(taskId, foreignCompanyId, busCompanyId));
            dispatch(selectEvent(''));
        },
        declineExchangedTask: (taskId, busCompanyId) => {
            dispatch(declineExchangedTask(taskId, busCompanyId));
            dispatch(selectEvent(''));
        },
        selectEvent: event => {
            dispatch(selectEvent(event));
        },
        fetchTasksReleasedForOwnCompany: busCompanyId => {
            dispatch(fetchTasksByFilter(busCompanyId, false, false, 'FOREIGN_RELEASED_TASKS_PENDING'));
            dispatch(selectEvent(''));
        },
        fetchOwnBusCompany: selectedCompany => {
            dispatch(fetchOwnBusCompany(selectedCompany));
        },
        addNotification: (title, message, level, position) => {
            dispatch(addNotification(title, message, level, position));
        },
        setFetchMethodOnError: fetchMethod => {
            dispatch(setFetchMethodOnError(fetchMethod));
        },
    };
};

const TaskExchangeContainer = connect(mapStateToProps, mapDispatchToProps)(TaskExchange);

export default TaskExchangeContainer;
