import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminCustomerGroupAdministration from 'src/components/admin/customer-group/admin-customer-group-administration';

const mapStateToProps = state => {
    return {
        customerGroups: getEntities(state, Entities.CUSTOMER_GROUP),
        customerGroupDiscounts: getEntities(state, Entities.CUSTOMER_GROUP),
    };
};

const AdminCustomerGroupAdministrationContainer = connect(mapStateToProps, { fetchAll, patch, deleteById })(
    AdminCustomerGroupAdministration,
);

export default AdminCustomerGroupAdministrationContainer;
