import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import { upload } from 'src/actions/documents';
import { addNotification } from 'src/actions/notifications';

const IMAGE_EXT = '.jpg';
const IMAGE_TYPE = 'image/jpeg';
const IMAGE_QUALITY = 0.85;

function imageToCanvas(image) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    return canvas;
}

function convertImage(file) {
    return new Promise(function (resolve, reject) {
        const image = new Image();
        image.src = file.preview;
        image.onload = () => {
            const canvas = imageToCanvas(image);
            canvas.toBlob(
                blob => {
                    const convertedFilename = file.name.substr(0, file.name.lastIndexOf('.')) + IMAGE_EXT;
                    const convertedFile = new File([blob], convertedFilename, {
                        type: IMAGE_TYPE,
                    });
                    convertedFile.preview = file.preview;
                    resolve(convertedFile);
                },
                IMAGE_TYPE,
                IMAGE_QUALITY,
            );
        };
    });
}

export const ImageCropperTypes = {
    BUS: 'bus',
    COMPANY: 'company',
    DRIVER: 'driver',
    TRIP_COMPANY: 'trip-company',
};

class ImageCropper extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            files: [],
            imagePreview: false,
            imageURL: '',
        };
    }

    onDrop(files) {
        const { type, multiple } = this.props;

        console.log('Received files: ', files);

        // gallery (convert files to jpeg)
        if (multiple) {
            Promise.all(files.map(file => convertImage(file))).then(convertedFiles => {
                console.log('Converted files: ', convertedFiles);
                this.setState({
                    files: convertedFiles,
                    imagePreview: true,
                });
            });
        }

        // cropper (file is already converted)
        else {
            if (files.length === 1) {
                this.setState({
                    files: files,
                    imagePreview: true,
                    imageURL: files[0].preview,
                });
            }
        }
    }

    checkPayload(action) {
        if (action.error) throw action.payload;
        else return action.payload;
    }

    handleError(error) {
        const t = this.props.translationFunction;
        if (error.status === HttpStatus.PAYLOAD_TOO_LARGE)
            this.props.addNotification(t('images.limit_size'), t('images.use_smaller_picture'), 'error', 'tr');
        else
            this.props.addNotification(
                t('images.error_upload_picture'),
                t('error_message', { message: error.message }),
                'error',
                'tr',
            );
    }

    callSubmit() {
        if (this.props.multiple) this.uploadGalleryImages();
        else this.uploadCroppedImage();

        this.props.handleClose();
    }

    didFormChange() {
        return this.state.imagePreview;
    }

    uploadCroppedImage() {
        const { handleCroppedImage, upload, type } = this.props;

        if (this.state.imagePreview) {
            const canvas = this.cropper.getCroppedCanvas({ fillColor: '#fff' });
            canvas.toBlob(
                blob => {
                    upload([blob])
                        .then(this.checkPayload)
                        .then(response => handleCroppedImage(response))
                        .catch(this.handleError);
                },
                IMAGE_TYPE,
                IMAGE_QUALITY,
            );
        }
    }

    uploadGalleryImages() {
        const { handleGalleryImages, value, upload } = this.props;
        const files = this.state.files;

        if (this.state.imagePreview) {
            upload(files)
                .then(this.checkPayload)
                .then(response => handleGalleryImages(value.concat(response)))
                .catch(this.handleError);
        }
    }

    closeCropPreview(e) {
        e.preventDefault();
        this.setState({
            imagePreview: false,
            imageURL: '',
        });
    }

    render() {
        const { type, multiple } = this.props;
        const t = this.props.translationFunction;
        let ratio = null;
        let previewFurtherBusPictures;

        if (type === ImageCropperTypes.BUS) ratio = 16 / 9;
        else if (type === ImageCropperTypes.DRIVER) ratio = 3 / 4;
        else if (type === ImageCropperTypes.COMPANY) ratio = 21 / 9;
        else if (type === ImageCropperTypes.TRIP_COMPANY) ratio = 1;

        console.log('image cropper', this.props);

        if (multiple && this.state.files) {
            previewFurtherBusPictures = (
                <div id="preview-further-buses" className="image-gallery-thumbnails">
                    <div className="image-gallery-thumbnails-container">
                        <a className="image-gallery-thumbnail">
                            {this.state.files.map(file => (
                                <img key={file.name} src={file.preview} />
                            ))}
                        </a>
                    </div>
                </div>
            );
        }

        let $imagePreview = null;

        if (this.state.imagePreview && !multiple) {
            $imagePreview = (
                <div>
                    <button onClick={this.closeCropPreview} className="btn btn-sm transparent preview-delete-button">
                        <span className="glyphicon glyphicon-remove" />
                        &nbsp;
                        <span>{t('images.choose_different_picture')}</span>
                    </button>
                    <div id="crop-image">
                        <Cropper
                            style={{ height: 'inherit', width: '100%' }}
                            aspectRatio={ratio}
                            preview=".img-preview"
                            guides={false}
                            src={this.state.imageURL}
                            ref={ref => (this.cropper = ref)}
                            crop={this._crop}
                            cropBoxResizable={false}
                        />
                    </div>
                </div>
            );
        } else {
            $imagePreview = (
                <div>
                    <div id="dropzone">
                        <Dropzone
                            onDrop={this.onDrop}
                            multiple={multiple}
                            accept=".jpg, .jpeg, .png, image/jpeg, image/pjpeg, image/png"
                            className="file-input"
                            activeClassName="file-input-active">
                            <div className="jumbotron">
                                <div id="dropzone-icons">
                                    <div>
                                        <span className="glyphicon glyphicon-plus" />
                                        &nbsp; {t('images.from_storage')}
                                    </div>
                                    <div>
                                        <span className="glyphicon glyphicon-share-alt" />
                                        &nbsp; {t('images.drag_and_drop')}
                                    </div>
                                </div>
                            </div>
                        </Dropzone>
                    </div>
                    <br />
                    <div className="row">{previewFurtherBusPictures}</div>
                </div>
            );
        }
        return <div id="imagePreview">{$imagePreview}</div>;
    }
}

export default connect(null, { upload, addNotification }, null, { withRef: true })(ImageCropper);
