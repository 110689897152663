import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save, TOMCAT_URL } from 'src/common/index';
import { setSliderIndex } from 'src/actions/form';
import ErrorMessage from 'src/components/misc/error-message';
import CountryCalculationEditForm from 'src/components/administration/country-calculation-edit-form';
import { addNotification } from 'src/actions/notifications';

class CountryCalculationEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.COUNTRY_CALCULATION, selectedCompany);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { countryCalculation } = nextProps;

        if (countryCalculation.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { countryCalculation, selectedCompany, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !countryCalculation.isFetching;

        if (finishFetching) {
            if (countryCalculation.error) return <ErrorMessage object={countryCalculation} />;
            else {
                let initialValues;
                if (!isCreate && countryCalculation.content) {
                    initialValues = {
                        id: countryCalculation.content.id,
                        name: countryCalculation.content.name,
                        countrySurcharges: countryCalculation.content.countrySurcharges,
                        countryVatRates: countryCalculation.content.countryVatRates,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                } else {
                    initialValues = {
                        countrySurcharges: [],
                        countryVatRates: [],
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }

                return (
                    <CountryCalculationEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        countryCalculation={countryCalculation.content}
                        selectedCompany={selectedCompany}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate, selectedCompany } = this.props;

        if (isCreate) this.props.save(data, Entities.COUNTRY_CALCULATION, selectedCompany);
        else this.props.patch(data, Entities.COUNTRY_CALCULATION, selectedCompany);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        countryCalculation: getUpdatedEntity(state, Entities.COUNTRY_CALCULATION, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
        setSliderIndex,
        addNotification,
    },
    null,
    { withRef: true },
)(CountryCalculationEditModal);
