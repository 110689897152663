import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import ErrorMessage from 'src/components/misc/error-message';
import CompanySettingsForm from 'src/components/administration/company-settings/company-settings-form';
import { Entities, Projection } from 'src/common/index';
import { withTranslation } from 'react-i18next';

class CompanySettings extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.setSliderIndex(0);
        this.props.fetchOwnBusCompany(this.props.selectedCompany, true);
    }

    componentWillReceiveProps(nextProps) {
        const { company } = nextProps;

        if (!this.state.startedFetching && company.isFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { company, t } = this.props;

        const finishFetching = this.state.startedFetching && !company.isFetching;

        if (finishFetching) {
            if (company.error) return <ErrorMessage object={company} />;
            else {
                return (
                    <div>
                        <CompanySettingsForm
                            t={t}
                            initialValues={getInitialFormData(company.entity)}
                            onSubmit={this.handleSubmit}
                            company={company.entity}
                            selectedSliderIndex={this.props.selectedSliderIndex}
                            setSliderIndex={this.props.setSliderIndex}
                        />
                    </div>
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { patch } = this.props;

        console.log('submit data: ', data);

        patch(data, Entities.BUS_COMPANY, 0, Projection.FULL, this.successPatchSettings);
    }

    successPatchSettings() {
        const { t } = this.props;
        this.props.addNotification(t('common_phrases.success'), t('global_settings.saved_changes'), 'success', 'tr');
    }
}

export default withTranslation()(CompanySettings);

const getInitialFormData = company => {
    const {
        id,
        freeCancellation,
        freeCancellationDays,
        defaultTaskPostProcessingTime,
        bookingLeadTime,
        offerDefaultDaysValidBeforeStart,
        offerDefaultDaysRemindBeforeOfferEnds,
        sendDriverDetailsXDaysBeforeJourney,
        cashbackNoRefund,
        onlinePaymentSettings,
        yieldManagementEnabled,
    } = company;

    return {
        id,
        freeCancellation,
        freeCancellationDays,
        defaultTaskPostProcessingTime,
        bookingLeadTime,
        offerDefaultDaysValidBeforeStart,
        offerDefaultDaysRemindBeforeOfferEnds,
        sendDriverDetailsXDaysBeforeJourney,
        cashbackNoRefund,
        onlinePaymentSettings,
        yieldManagementEnabled,
    };
};
