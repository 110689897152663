import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import { renderInput } from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';

class AdminCustomerVerificationForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="verificationComment" label={`${t('comment')} *`} component={renderInput} />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.verificationComment) || values.verificationComment.indexOf('://') >= 0)
        errors.verificationComment = t('error_missing.fill_in_comment');

    return errors;
};

export default reduxForm({
    form: 'verificationForm',
    validate,
})(AdminCustomerVerificationForm);
