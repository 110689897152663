import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { deleteById, Entities, fetchByCompanyId, patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { selectEvent } from 'src/actions/events';
import { addNotification } from 'src/actions/notifications';
import { duplicateTask, fetchTasksByDriver, fetchTasksByFilter, setFetchMethodOnError } from 'src/actions/tasks';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getActiveDrivers } from 'src/selectors/drivers';
import { getDetails } from 'src/selectors/details';
import { getAllFilteredTasks } from 'src/selectors/tasks';
import TaskAdministration from 'src/components/taskadministration/task-administration';

const mapStateToProps = state => {
    return {
        reviews: state.entities[Entities.REVIEW.repository],
        buses: state.entities[Entities.BUS.repository],
        tasks: getAllFilteredTasks(state),
        drivers: getActiveDrivers(state),
        busCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
        eventDetails: getDetails(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTasksByFilter: (busCompanyId, pastTasks, cancelledTasks, filter) => {
            dispatch(fetchTasksByFilter(busCompanyId, pastTasks, cancelledTasks, filter));
            dispatch(selectEvent(''));
        },
        fetchTasksByDriver: (driverId, busCompanyId, pastTasks, cancelledTasks) => {
            dispatch(fetchTasksByDriver(driverId, busCompanyId, pastTasks, cancelledTasks));
            dispatch(selectEvent(''));
        },
        fetchOwnBusCompany: selectedCompany => {
            dispatch(fetchOwnBusCompany(selectedCompany));
        },
        fetchByCompanyId: (entity, busCompanyId) => {
            dispatch(fetchByCompanyId(entity, busCompanyId));
            dispatch(selectEvent(''));
        },
        patch: (body, entity, reducerIndex) => {
            dispatch(patch(body, entity, reducerIndex));
            dispatch(selectEvent(''));
        },
        deleteById: (id, entity, reducerIndex) => {
            dispatch(deleteById(id, entity, reducerIndex));
            dispatch(selectEvent(''));
        },
        duplicateTask: (taskId, busCompanyId) => {
            dispatch(duplicateTask(taskId, busCompanyId));
        },
        selectEvent: event => {
            dispatch(selectEvent(event));
        },
        addNotification: (title, message, level, position) => {
            dispatch(addNotification(title, message, level, position));
        },
        setFetchMethodOnError: fetchMethod => {
            dispatch(setFetchMethodOnError(fetchMethod));
        },
        push: (location, state) => {
            dispatch(push(location, state));
        },
    };
};

const TaskAdministrationContainer = connect(mapStateToProps, mapDispatchToProps)(TaskAdministration);

export default TaskAdministrationContainer;
