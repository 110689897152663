import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { fetchById, getUpdatedEntity, patch, save } from 'src/common/index';

import ErrorMessage from 'src/components/misc/error-message';
import { setSliderIndex } from 'src/actions/form';

class ParentForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, reducerIndex, projection, mainEntityProps } = this.props;

        this.props.setSliderIndex(0);

        if (!isCreate && mainEntityProps.id && mainEntityProps.entity)
            this.props.fetchById(
                mainEntityProps.id,
                mainEntityProps.entity,
                reducerIndex ? reducerIndex : undefined,
                projection ? projection : undefined,
            );
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { mainEntity } = nextProps;

        if (mainEntity.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().submit();
    }

    didFormChange() {
        return !this.childForm.getWrappedInstance().pristine;
    }

    render() {
        const { mainEntity, selectedSliderIndex, mainEntityProps, childForm, isCreate, handleClose, t } = this.props;

        const finishFetching = this.state.startedFetching && !mainEntity.isFetching;
        const Form = childForm.component;

        if (!finishFetching) return <CircularProgress />;

        if (mainEntity.error) return <ErrorMessage object={mainEntity} />;

        return (
            <Form
                {...childForm.props}
                {...{
                    [mainEntityProps.entity.name]: mainEntity.content,
                }}
                t={t}
                isCreate={isCreate}
                handleClose={handleClose}
                ref={ref => (this.childForm = ref)}
                setSliderIndex={this.props.setSliderIndex}
                selectedSliderIndex={selectedSliderIndex}
                onSubmit={this.handleSubmit}
            />
        );
    }

    handleSubmit(data) {
        const { handleClose, closeDrawer, reducerIndex, projection, mainEntityProps, isCreate } = this.props;

        console.log('Submit data: ', data);

        if (isCreate)
            this.props.save(
                data,
                mainEntityProps.entity,
                reducerIndex ? reducerIndex : undefined,
                projection ? projection : undefined,
            );
        else
            this.props.patch(
                data,
                mainEntityProps.entity,
                reducerIndex ? reducerIndex : undefined,
                projection ? projection : undefined,
            );

        handleClose();

        if (closeDrawer) closeDrawer();
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedSliderIndex: state.selectedSliderIndex,
        mainEntity: getUpdatedEntity(state, ownProps.mainEntityProps.entity, ownProps.reducerIndex),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        save,
        patch,
        setSliderIndex,
    },
    null,
    { withRef: true },
)(ParentForm);
