import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import CouponEditForm from 'src/components/admin/coupons/coupon-edit-form';
import moment from 'moment';
import { CouponType } from 'src/utils/constants';

class CouponEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.COUPON);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { coupon } = nextProps;

        if (!this.state.startedFetching && coupon.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { coupon, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !coupon.isFetching;

        if (finishFetching) {
            // check for errors
            if (coupon.error) return <ErrorMessage object={coupon} />;
            // if everything is ok populate form
            else {
                let initialValues = {};

                if (isCreate) {
                    initialValues = {
                        type: CouponType.RELATIVE,
                        validFrom: moment().add(1, 'days').format('DD.MM.YYYY HH:mm'),
                        validTo: moment().add(8, 'days').format('DD.MM.YYYY HH:mm'),
                    };
                }

                // populate form for editing an existing coupon
                else if (!isCreate && coupon.content)
                    initialValues = {
                        id: coupon.content.id,
                        name: coupon.content.name,
                        type: coupon.content.type,
                        code: coupon.content.code,
                        validFrom: moment(coupon.content.validFrom).format('DD.MM.YYYY HH:mm'),
                        validTo: moment(coupon.content.validTo).format('DD.MM.YYYY HH:mm'),
                        quantity: coupon.content.quantity,
                        redeemCount: coupon.content.redeemCount,
                        discount: coupon.content.discount,
                    };

                return (
                    <CouponEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        coupon={isCreate ? undefined : coupon.content}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose } = this.props;

        data.validFrom = moment(data.validFrom, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');
        data.validTo = moment(data.validTo, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');

        if (!isCreate) this.props.patch(data, Entities.COUPON);
        else this.props.save(data, Entities.COUPON);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        coupon: getUpdatedEntity(state, Entities.COUPON),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(CouponEditModal);
