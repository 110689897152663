import { Toggle } from 'material-ui';
import React from 'react';

export const FilterToggle = ({ toggled, onToggle, label, labelStyle }) => {
    return (
        <div>
            <Toggle
                label={label}
                labelStyle={{
                    ...labelStyle,
                    fontSize: '16px',
                    color: '#0c66ab',
                    paddingRight: '10px',
                }}
                toggled={toggled}
                onToggle={onToggle}
            />
        </div>
    );
};
