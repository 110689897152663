import { apiRequest } from 'src/actions/api/api';
import { Entities, Projection, TOMCAT_URL } from 'src/common/index';

export const fetchOperatingIntegrationReportsByIntegration = (integrationId, projection) => {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.repository
                }/search/findByIntegrationIdOrderByCreatedAtDesc?integrationId=${integrationId}&projection=${
                    projection ? projection : Projection.DEFAULT
                }`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                    `SUCCESS_FETCH_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                    `FAILURE_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.schema,
            }),
        );
    };
};

export function createReportForIntegration(integrationId) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.repository}/create-report`,
                method: 'POST',
                secondaryActionTypes: [
                    `REQUEST_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                    `SUCCESS_SAVE_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                    `FAILURE_${Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.action}`,
                ],
                schema: Entities.OPERATING_TOOL_DATA_IMPORT_REPORT.schema,
                reducerIndex: 0,
                body: integrationId,
            }),
        );
    };
}
