import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { isInteger } from 'src/common/index';
import { renderInput, renderToggle } from 'src/components/misc/redux-form-helpers';

class GlobalSettingsEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="brandRatingPercent"
                            label={t('global_settings.percent_brands')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="ageRatingPercent"
                            label={t('global_settings.percent_age')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="starRatingPercent"
                            label={t('global_settings.percent_stars')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="equipmentRatingPercent"
                            label={t('global_settings.percent_equipment')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="enableBasicSystemFeeAndAdvertisingSubsidyForInvoices"
                            label={t('global_settings.enable_basic_system_fee')}
                            component={renderToggle}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <RaisedButton
                            primary={true}
                            type="submit"
                            label={t('user_functionality.save')}
                            className="pull-right"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!isInteger(values.equipmentRatingPercent)) errors.equipmentRatingPercent = t('error_hint.error_integer');
    if (!isInteger(values.starRatingPercent)) errors.starRatingPercent = t('error_hint.error_integer');
    if (!isInteger(values.ageRatingPercent)) errors.ageRatingPercent = t('error_hint.error_integer');
    if (!isInteger(values.brandRatingPercent)) errors.brandRatingPercent = t('error_hint.error_integer');

    if (values.equipmentRatingPercent < 0 || values.equipmentRatingPercent > 100)
        errors.equipmentRatingPercent = t('global_settings.error_percent');
    if (values.starRatingPercent < 0 || values.starRatingPercent > 100)
        errors.starRatingPercent = t('global_settings.error_percent');
    if (values.ageRatingPercent < 0 || values.ageRatingPercent > 100)
        errors.ageRatingPercent = t('global_settings.error_percent');
    if (values.brandRatingPercent < 0 || values.brandRatingPercent > 100)
        errors.brandRatingPercent = t('global_settings.error_percent');

    return errors;
};

export default reduxForm({
    form: 'globalSettingsEditForm',
    persistentSubmitErrors: true,
    validate,
})(GlobalSettingsEditForm);
