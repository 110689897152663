import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class FleetForm extends Component {
    render() {
        const { handleSubmit } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label="Name *" component={renderInput} />
                    </div>
                    {/*<div className="col-md-6">*/}
                    {/*    <Field name="group" label="Gruppe *" component={renderSelectField}>*/}
                    {/*        {_.values(TripCategoryGroup).map(group => (*/}
                    {/*            <MenuItem key={group} value={group} primaryText={group} />*/}
                    {/*        ))}*/}
                    {/*    </Field>*/}
                    {/*</div>*/}
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.FLEET.repository}/search/findByName?name=${encodeURIComponent(values.name)}`,
        values.id,
        'name',
    );
};

FleetForm = reduxForm({
    form: 'TripCategoryForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(FleetForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, fleet } = ownProps;

    let initialValues = {};

    if (!isCreate && fleet)
        initialValues = {
            id: fleet.id,
            name: fleet.name,
        };

    return {
        initialValues: initialValues,
    };
};

FleetForm = connect(mapStateToProps, null, null, { withRef: true })(FleetForm);

export default FleetForm;
