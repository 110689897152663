import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Entities, fetchById, getUpdatedEntity } from 'src/common/index';
import { getOwnAccount } from 'src/selectors/accounts';
import { genderName } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import ReadOnlyField from 'src/components/misc/ReadOnlyField';
import AccountEditModal from 'src/components/account/account-edit-modal';
import PasswordEditModal from 'src/components/account/password-edit-modal';
import i18next, { getSupportedLanguageLabel, LOCALE_COOKIE } from 'src/i18n';
import Checkbox from 'material-ui/Checkbox';
import Cookies from 'js-cookie';

class Account extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { ownAccount, fetchById } = this.props;

        fetchById(ownAccount.entity.id, Entities[ownAccount.entity.role]);
    }

    componentWillReceiveProps(nextProps) {
        const { account } = nextProps;

        if (!this.state.startedFetching && account.isFetching) this.setState({ startedFetching: true });
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        const { t, account } = this.props;

        const finishFetching = this.state.startedFetching && !account.isFetching;

        if (finishFetching) {
            if (account.error) return <ErrorMessage object={account} />;
            else {
                return (
                    <div>
                        <legend className="legend">{t('account_header')}</legend>

                        <form className="form-horizontal">
                            <div className="row">
                                <div id="topSection" className="col-md-6">
                                    <ReadOnlyField value={account.content.email} label={t('contact_data.username')} />
                                    <ReadOnlyField
                                        value={genderName(account.content.contactData.gender)}
                                        label={t('contact_data.gender')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.firstName}
                                        label={t('contact_data.first_name')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.lastName}
                                        label={t('contact_data.last_name')}
                                    />
                                    <ReadOnlyField
                                        value={moment(account.content.registeredAt).format()}
                                        label={t('contact_data.registration_date')}
                                    />
                                    <ReadOnlyField
                                        value={moment(account.content.lastLogin).fromNow()}
                                        label={t('contact_data.last_login')}
                                    />
                                    <ReadOnlyField
                                        value={getSupportedLanguageLabel(account.content.language)}
                                        label={t('language')}
                                    />
                                    {(Config.PROFILE === 'development' || Config.PROFILE === 'staging') && (
                                        <Checkbox
                                            label={t('debug_mode')}
                                            checked={Cookies.get(LOCALE_COOKIE) === 'cimode'}
                                            onCheck={() => {
                                                if (Cookies.get(LOCALE_COOKIE) === 'cimode')
                                                    i18next.changeLanguage(account.content.language);
                                                else i18next.changeLanguage('cimode');
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <ReadOnlyField
                                        value={account.content.contactData.street}
                                        label={t('contact_data.street')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.postCode}
                                        label={t('contact_data.post_code')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.city}
                                        label={t('contact_data.city')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.country}
                                        label={t('contact_data.country')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.phone}
                                        label={t('contact_data.tel_number')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.email}
                                        label={t('contact_data.email')}
                                    />
                                    <ReadOnlyField
                                        value={account.content.contactData.homepage}
                                        label={t('contact_data.homepage')}
                                    />
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="pull-right">
                                    <RaisedButton
                                        secondary={true}
                                        label={t('user_functionality.change_password')}
                                        onClick={() =>
                                            this.props.openModal({
                                                component: PasswordEditModal,
                                                componentProps: {
                                                    id: account.content.id,
                                                    role: account.content.role,
                                                    isCreate: false,
                                                    isAdmin: false,
                                                },
                                                title: t('user_functionality.change_password'),
                                                mandatoryFields: true,
                                            })
                                        }
                                    />
                                    <RaisedButton
                                        primary={true}
                                        label={t('user_functionality.edit')}
                                        onClick={() =>
                                            this.props.openModal({
                                                component: AccountEditModal,
                                                componentProps: {
                                                    id: account.content.id,
                                                    role: account.content.role,
                                                    isCreate: false,
                                                    isAdmin: false,
                                                },
                                                title: `${account.content.contactData.firstName} ${account.content.contactData.lastName}`,
                                                mandatoryFields: true,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else return <CircularProgress />;
    }
}

const mapStateToProps = state => {
    const ownAccount = getOwnAccount(state);
    return {
        ownAccount: ownAccount,
        account: getUpdatedEntity(state, Entities[ownAccount.entity.role]),
    };
};

export default withTranslation()(composeModalActions(connect(mapStateToProps, { fetchById })(Account)));
