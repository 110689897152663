import { asyncRequest, TOMCAT_URL } from 'src/common/index';

export const ADMIN_NEWS_REQUEST = 'ADMIN_NEWS_REQUEST';
export const ADMIN_NEWS_SUCCESS = 'ADMIN_NEWS_SUCCESS';
export const ADMIN_NEWS_FAILURE = 'ADMIN_NEWS_FAILURE';
export const SET_ADMIN_NEWS = 'SET_ADMIN_NEWS';

export function setAdminNews(payload) {
    return {
        type: SET_ADMIN_NEWS,
        payload,
    };
}

export function fetchAdminNews() {
    return dispatch => {
        asyncRequest(`${TOMCAT_URL}api/admin/adminNews`)
            .then(response => {
                dispatch({
                    type: SET_ADMIN_NEWS,
                    payload: response.json,
                });
            })
            .catch(error => {
                console.error('error fetching admin news');
            });
    };
}
