import React, { Component } from 'react';
import { Field } from 'redux-form';
import { renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import { DriverSelectionType } from 'src/components/taskadministration/senddriverdetails/DriverSelectionType';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';

class DriverSelectionFields extends Component {
    componentWillReceiveProps(nextProps, nextContext) {
        const { change, driverType, isFirstDriver } = nextProps;

        if (this.props.driverType !== driverType && driverType !== DriverSelectionType.EXISTING) {
            if (isFirstDriver) change('firstDriver', null);
            else change('secondDriver', null);
        }
    }

    render() {
        const { isFirstDriver } = this.props;

        return (
            <>
                {/* Driver Type Selection */}
                <div className="row">
                    <div className="col-md-12 voffset">
                        {isFirstDriver ? this.renderFirstDriverTypeSelection() : this.renderSecondDriverTypeSelection()}
                    </div>
                </div>

                {/* Driver Input Fields */}
                <div className="row">{this.renderDriverFields()}</div>
            </>
        );
    }

    renderDriverFields = () => {
        const { driverType, isFirstDriver, driverMenuItems, t } = this.props;

        switch (driverType) {
            case DriverSelectionType.EXISTING:
                return (
                    <>
                        <div className="col-md-6">
                            <Field
                                name={isFirstDriver ? 'firstDriver' : 'secondDriver'}
                                label={
                                    isFirstDriver
                                        ? `${t('administration.driver')} 1`
                                        : `${t('administration.driver')} 2`
                                }
                                component={renderSelectField}>
                                {driverMenuItems}
                            </Field>
                        </div>
                        <div className="col-md-6">
                            <Field
                                name={isFirstDriver ? 'firstDriverPhone' : 'secondDriverPhone'}
                                label={`${t('contact_data.tel_number')} *`}
                                component={renderInput}
                            />
                        </div>
                    </>
                );
            case DriverSelectionType.NEW:
                return (
                    <>
                        <div className="col-md-6">
                            <Field
                                name={isFirstDriver ? 'firstDriverName' : 'secondDriverName'}
                                label={`${t('common_phrases.name')} *`}
                                component={renderInput}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name={isFirstDriver ? 'firstDriverPhone' : 'secondDriverPhone'}
                                label={`${t('contact_data.tel_number')} *`}
                                component={renderInput}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    renderFirstDriverTypeSelection = () => {
        const { t } = this.props;
        return (
            <Field
                name="firstDriverType"
                label={t('common_phrases.type')}
                hintText={t('administration.driver_selection')}
                component={RadioButtonGroup}>
                <RadioButton
                    style={{ display: 'inline-block', width: '260px' }}
                    value={DriverSelectionType.EXISTING}
                    label={t('administration.existing_driver')}
                />
                <RadioButton
                    style={{ display: 'inline-block', width: '260px' }}
                    value={DriverSelectionType.NEW}
                    label={t('administration.manual_input')}
                />
            </Field>
        );
    };

    renderSecondDriverTypeSelection = () => {
        const { t } = this.props;
        return (
            <Field
                name="secondDriverType"
                label={t('common_phrases.type')}
                hintText={t('administration.driver_selection')}
                component={RadioButtonGroup}>
                <RadioButton
                    style={{ display: 'inline-block', width: '130px' }}
                    value={DriverSelectionType.NONE}
                    label={t('administration.no')}
                />
                <RadioButton
                    style={{ display: 'inline-block', width: '260px' }}
                    value={DriverSelectionType.EXISTING}
                    label={t('administration.existing_driver')}
                />
                <RadioButton
                    style={{ display: 'inline-block', width: '260px' }}
                    value={DriverSelectionType.NEW}
                    label={t('administration.manual_input')}
                />
            </Field>
        );
    };
}

export default DriverSelectionFields;
