import EntityDescription from './EntityDescription';

export * from './EntityDescription';
export * from './projections';

/**
 * EntityDescriptions holding information about their name, reducer, repository and actions
 *
 * Every EntityDescription defined here will produce an appropriate reducer and all CRUD actions.
 */
export const EntityDescriptions = {
    PRODUCT: new EntityDescription('PRODUCT', 'products'),
};
