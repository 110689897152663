import React, { Component } from 'react';
import Stars from 'src/components/misc/stars';

class AdminCompanyStats extends Component {
    render() {
        const { stats, t } = this.props;
        return (
            <div>
                <div>
                    <strong>{t('companies.buses')}:</strong> {stats.buses}
                </div>
                <div>
                    <strong>{t('companies.released_buses')}</strong> {stats.releasedBuses}
                </div>
                <div>
                    <strong>{t('companies.blocked_buses')}</strong> {stats.blockedBuses}
                </div>
                <div>
                    <strong>{t('companies.avg_bus_rating')}</strong>{' '}
                    <Stars t={t} rating={stats.averageBusRating} className="inline-block-top" />
                </div>
            </div>
        );
    }
}

export default AdminCompanyStats;
