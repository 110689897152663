import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { SubmissionError } from 'redux-form';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import GlobalSettingsEditForm from 'src/components/admin/global-settings/global-settings-edit-form';
import { withTranslation } from 'react-i18next';

class AdminGlobalSettingsAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            changedAccount: false,
        };
    }

    handleSubmit(data) {
        const { t } = this.props;
        return new Promise(resolve => {
            const totalWeight =
                Number(data.equipmentRatingPercent) +
                Number(data.starRatingPercent) +
                Number(data.ageRatingPercent) +
                Number(data.brandRatingPercent);

            if (totalWeight !== 100) {
                const msg = t('global_settings.info_hundred');
                throw new SubmissionError({
                    equipmentRatingPercent: msg,
                    starRatingPercent: msg,
                    ageRatingPercent: msg,
                    brandRatingPercent: msg,
                });
            }

            this.props.patch(data, Entities.GLOBAL_SETTINGS);

            this.setState({ changedAccount: true });

            resolve();
        });
    }

    updateBusRatings() {
        const { addNotification, t } = this.props;

        asyncRequest({
            endpoint: `${TOMCAT_URL}api/${Entities.BUS.repository}/update-ratings`,
            method: 'PATCH',
        })
            .then(response =>
                addNotification(t('common_phrases.success'), t('global_settings.recalculated'), 'success', 'tr'),
            )
            .catch(error =>
                addNotification(t('common_phrases.error'), t('global_settings.error_recalculation'), 'error', 'tr'),
            );
    }

    componentWillMount() {
        this.props.fetchAll(Entities.GLOBAL_SETTINGS);
    }

    componentWillReceiveProps(nextProps) {
        const { globalSettings, addNotification, t } = nextProps;

        if (globalSettings.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        if (this.state.changedAccount) {
            if (!globalSettings.error) {
                addNotification(t('common_phrases.success'), t('global_settings.saved_changes'), 'success', 'tr');
            } else {
                addNotification(t('common_phrases.error'), `${globalSettings.error.statusText}`, 'danger', 'tr');
            }

            this.setState({ changedAccount: false });
        }
    }

    render() {
        const { globalSettings, t } = this.props;

        const finishFetching = this.state.startedFetching && !globalSettings.isFetching;

        if (!finishFetching) return <CircularProgress />;
        else if (globalSettings.error) return <ErrorMessage object={globalSettings} />;
        else {
            const initialValues = Object.assign({}, globalSettings.items[0]);
            return (
                <div>
                    <legend className="legend">
                        {t('global_settings.settings')}
                        <div className="pull-right">
                            <button className="btn btn-xs btn-link nav-link" onClick={this.updateBusRatings}>
                                <span className="glyphicon glyphicon-star" />
                                &nbsp;{t('global_settings.recalculating_rating')}
                            </button>
                        </div>
                    </legend>
                    <GlobalSettingsEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        globalSettings={globalSettings.items[0]}
                    />
                </div>
            );
        }
    }
}

export default withTranslation()(AdminGlobalSettingsAdministration);
