import HttpStatus from 'http-status-codes';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { addNotification } from 'src/actions/notifications';
import { Entities, patch, Projection } from 'src/common/index';
import MarkAsReceivedForm from './mark-as-received-form';

class MarkAsReceivedModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { t, task } = this.props;
        return (
            <MarkAsReceivedForm
                t={t}
                task={task}
                initialValues={{
                    sendMail: true,
                }}
                onSubmit={this.handleSubmit}
                ref={ref => (this.childForm = ref)}
            />
        );
    }

    async handleSubmit(formData) {
        const { handleClose, id, task, addNotification, t } = this.props;

        const data = {
            id: task.id,
            sendMail: formData.sendMail,
            received: true,
        };

        this.props
            .patch(data, Entities.TASK, this.props.selectedCompany, Projection.FULL, undefined, 'receivedBooking')
            .then(() => {
                this.props.addNotification(
                    t('common_phrases.success'),
                    t('notifications.task_success_received', {
                        task: task.identifier,
                        email: task.customerContactData.email,
                    }),
                    'success',
                    'tr',
                );
            })
            .catch(error => {
                if (error.status === HttpStatus.CONFLICT)
                    this.props.addNotification(
                        t('warning'),
                        t('notifications.task_already_received', {
                            task: task.identifier,
                            email: task.customerContactData.email,
                        }),
                        'warning',
                        'tr',
                    );
                else this.props.addNotification(t('common_phrases.error'), t('error_occurred'), 'error', 'tr');
            });

        handleClose();
    }
}

const mapStateToProps = state => ({
    selectedCompany: state.selectedCompany,
});

export default connect(
    mapStateToProps,
    {
        patch,
        addNotification,
    },
    null,
    { withRef: true },
)(MarkAsReceivedModal);
