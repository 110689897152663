import { ADD_ERROR_NOTIFICATION, ADD_NOTIFICATION } from 'src/actions/notifications';

export default function notifications(state = {}, action) {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return Object.assign({}, state, {
                title: action.payload.title,
                message: action.payload.message,
                level: action.payload.level,
                position: action.payload.position,
            });
        case ADD_ERROR_NOTIFICATION:
            return Object.assign({}, state, {
                error: action.payload.error,
            });

        default:
            return state;
    }
}
