import { FORM_SUBMIT_DISABLE } from 'src/actions/form-submit';

export default function formSubmitReducer(state = { disabled: false }, action) {
    switch (action.type) {
        case FORM_SUBMIT_DISABLE:
            if (action.payload)
                return Object.assign({}, state, {
                    disabled: action.payload.disabled,
                });
            else return state;
        default:
            return state;
    }
}
