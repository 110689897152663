import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, fetchByCompanyId, getEntities, Projection } from 'src/common/index';
import CustomerGroupDiscountAdministration from 'src/components/administration/customer-group-discount-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        customerGroups: state.entities[Entities.CUSTOMER_GROUP.repository],
        customerGroupDiscounts: getEntities(
            state,
            Entities.CUSTOMER_GROUP_DISCOUNT,
            state.selectedCompany,
            Projection.FULL,
        ),
    };
};

const CustomerGroupDiscountAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    fetchByCompanyId,
    deleteById,
})(CustomerGroupDiscountAdministration);

export default CustomerGroupDiscountAdministrationContainer;
