import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { asyncCheckDuplicate, renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isNonNegativeIntegerPercent, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';
import SelectField from 'material-ui/SelectField';
import autoBind from 'react-autobind';
import MenuItem from 'material-ui/MenuItem';

class DiscountGroupEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            busValue: undefined,
            calculationValue: undefined,
            isDisabled: true,
            selectedBuses: props.initialValues.selectedBuses || [],
            busDiscountCalculations: props.initialValues.busDiscountCalculations || [],
            yieldManagementForDiscountGroupDeactivated:
                this.props.initialValues.yieldManagementForDiscountGroupDeactivated,
        };
    }

    render() {
        const { handleSubmit, t, calculations, buses, isBusDiscountGroupEnabled, isGlobalYieldManagementEnabled } =
            this.props;
        const calculationMenuItems = [];
        const busMenuItems = [];

        this.state.busDiscountCalculations.forEach((busDiscountCalculation, i) => {
            const busCalc = this.state.busDiscountCalculations[i];
            if (busCalc.id) {
                const calculation = calculations.items.find(calculation => calculation.id === busCalc.id.calculationId);
                const bus = buses.find(bus => bus.id === busCalc.id.busId);
                busCalc.busName = bus.name;
                busCalc.calculationName = calculation.name;
                busCalc.busId = bus.id;
                busCalc.calculationId = calculation.id;
                busCalc.discountGroupId = busCalc.id.discountGroupId;
            }
        });

        calculations.items.forEach((value, index) => {
            calculationMenuItems.push(<MenuItem key={index} value={value.id} primaryText={value.name} />);
        });

        buses.forEach((value, index) => {
            if (this.state.busDiscountCalculations.find(busDiscount => value.id === busDiscount.busId)) {
                busMenuItems.push(<MenuItem key={index} value={value.id} disabled primaryText={value.name} />);
            } else {
                busMenuItems.push(<MenuItem key={index} value={value.id} primaryText={value.name} />);
            }
        });
        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6" style={{ fontSize: '14px' }}>
                        <Field
                            name="yieldManagementForDiscountGroupDeactivated"
                            value={'yieldManagementForDiscountGroupDeactivated'}
                            component={renderCheckbox}
                            hintText={t('yield_management.yield_management_info_text')}
                            onChange={() => {
                                this.setState({
                                    yieldManagementForDiscountGroupDeactivated:
                                        !this.state.yieldManagementForDiscountGroupDeactivated,
                                });
                            }}
                            label={
                                this.state.yieldManagementForDiscountGroupDeactivated
                                    ? t('yield_management.yield_management_discount_group_disabled')
                                    : t('yield_management.yield_management_discount_group_enabled')
                            }
                        />
                    </div>
                    <div className="col-md-6" style={{ fontSize: '14px' }}>
                        {isGlobalYieldManagementEnabled && (
                            <p>{t('yield_management.yield_management_global_enabled')}</p>
                        )}
                        {!isGlobalYieldManagementEnabled && (
                            <p>{t('yield_management.yield_management_global_disabled')}</p>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            hintText={t('administration.name_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="discount"
                            label={t('customer_group.discount_percent')}
                            hintText={t('customer_group.discount_percent')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                {isBusDiscountGroupEnabled && (
                    <div className="row bus-calculation-flex">
                        <div className="col-md-4">
                            <div className="">
                                <SelectField
                                    value={this.state.busValue}
                                    onChange={(event, index, value) => {
                                        this.setState({
                                            busValue: value,
                                        });
                                    }}
                                    floatingLabelText={t('common_phrases.bus')}
                                    fullWidth={true}
                                    hintText={t('bus.select_bus')}
                                    floatingLabelFixed={true}>
                                    {busMenuItems}
                                </SelectField>
                            </div>
                            <div className="">
                                <SelectField
                                    value={this.state.calculationValue}
                                    onChange={(event, index, value) => {
                                        this.setState({
                                            calculationValue: value,
                                        });
                                    }}
                                    floatingLabelText={t('bus.calculation')}
                                    fullWidth={true}
                                    hintText={t('bus.calculation')}
                                    floatingLabelFixed={true}>
                                    {calculationMenuItems}
                                </SelectField>
                            </div>
                        </div>
                        <div className="col-md-1 voffset">
                            <button type="button" className="btn btn-xs transparent" onClick={this.handleAddBus}>
                                {this.state.busValue &&
                                this.state.calculationValue &&
                                !this.state.busDiscountCalculations.find(calc => calc.busId === this.state.busValue) ? (
                                    <span
                                        className="fa fa-arrow-circle-right fa-2x text-success"
                                        title={t('busDiscountCalculation.add_bus_calculation')}
                                    />
                                ) : this.state.busDiscountCalculations.find(
                                      calc => calc.busId === this.state.busValue,
                                  ) ? (
                                    <span
                                        className="fa fa-arrow-circle-right fa-2x"
                                        title={t('busDiscountCalculation.error_add_bus_calculation')}
                                    />
                                ) : (
                                    <span
                                        className="fa fa-arrow-circle-right fa-2x"
                                        title={t('busDiscountCalculation.error_add_bus_calculation')}
                                    />
                                )}
                            </button>
                        </div>
                        <div className="col-md-7">
                            <label className="control-label">{t('bus.selected_buses')}</label>
                            <ul className="list-group" style={{ maxHeight: '180px', overflowY: 'auto' }}>
                                {this.state.busDiscountCalculations.length === 0 ? (
                                    <span className="error">{t('bus.no_bus_selected')}</span>
                                ) : (
                                    <div>
                                        {this.state.busDiscountCalculations.map(item => {
                                            return (
                                                <li key={item.busId} className="list-group-item">
                                                    <span>
                                                        {item.busName}
                                                        &nbsp; | &nbsp;
                                                        {item.calculationName} &nbsp;
                                                    </span>
                                                    <span className="glyphicon glyphicon-ok text-success" />
                                                    <button
                                                        type="button"
                                                        className="btn btn-xs transparent pull-right"
                                                        onClick={() => this.handleDeleteBus(item)}>
                                                        <span className="glyphicon glyphicon-trash" />
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
            </form>
        );
    }

    handleAddBus() {
        if (this.state.busValue && this.state.calculationValue) {
            const { buses, calculations, discountGroup } = this.props;

            const tmpCalculations = calculations.items;
            const currentSelectedBus = buses.find(bus => bus.id === this.state.busValue);
            const currentSelectedCalculation = tmpCalculations.find(calc => calc.id === this.state.calculationValue);

            if (currentSelectedBus && currentSelectedCalculation) {
                const combination = {
                    discountGroupId: discountGroup.content ? discountGroup.content.id : 0,
                    busId: currentSelectedBus.id,
                    calculationId: currentSelectedCalculation.id,
                };

                this.props.array.push('busDiscountCalculations', combination);
                this.setState({
                    busDiscountCalculations: this.state.busDiscountCalculations.concat({
                        busId: currentSelectedBus.id,
                        busName: currentSelectedBus.name,
                        calculationName: currentSelectedCalculation.name,
                        calculationId: currentSelectedCalculation.id,
                    }),
                    currentSelectedBus: undefined,
                    currentSelectedCalculation: undefined,
                    busValue: undefined,
                    calculationValue: undefined,
                });
            }
        }
    }

    handleDeleteBus(item) {
        this.props.array.remove(
            'busDiscountCalculations',
            this.state.busDiscountCalculations.findIndex(_item => _item.busId === item.busId),
        );

        if (
            this.props.initialValues.busDiscountCalculations &&
            this.props.initialValues.busDiscountCalculations.length === 0
        ) {
            this.setState({
                busDiscountCalculations: _.without(this.state.busDiscountCalculations, item),
                isDisabled: true,
            });
        } else {
            this.setState({
                busDiscountCalculations: _.without(this.state.busDiscountCalculations, item),
            });
        }
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isNonNegativeIntegerPercent(values.discount) || values.discount > 50)
        errors.discount = t('customer_group.error_discount');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.DISCOUNT_GROUP.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
            values.name,
        )}&companyId=${props.selectedCompany}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'discountGroupEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(DiscountGroupEditForm);
