import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import AdminCustomerAdministration from 'src/components/admin/accounts/admin-customer-administration';
import { addNotification } from 'src/actions/notifications';

const mapStateToProps = state => {
    return {
        customers: getEntities(state, Entities.CUSTOMER),
    };
};

const AdminCustomerAdministrationContainer = connect(mapStateToProps, { fetchAll, addNotification })(
    AdminCustomerAdministration,
);

export default AdminCustomerAdministrationContainer;
