import { SET_INTEGRATION } from 'src/actions/integrations';

export default function operatingToolIntegrationReducer(state = null, action: any) {
    switch (action.type) {
        case SET_INTEGRATION:
            return action.integration;
        default:
            return state;
    }
}
