import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { selectEvent } from 'src/actions/events';
import { addNotification } from 'src/actions/notifications';
import { fetchOffersByFilter, setFetchMethodOnError } from 'src/actions/tasks';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getDetails } from 'src/selectors/details';
import { getAllFilteredTasks } from 'src/selectors/tasks';
import OfferAdministration from 'src/components/offers/offer-administration';

const mapStateToProps = state => {
    return {
        taskFilter: state.taskFilter,
        busCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
        tasks: getAllFilteredTasks(state),

        buses: state.entities[Entities.BUS.repository],
        eventDetails: getDetails(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectEvent: event => {
            dispatch(selectEvent(event));
        },
        fetchOffersByFilter: (busCompanyId, pastOffers, filter) => {
            dispatch(fetchOffersByFilter(busCompanyId, pastOffers, filter));
            dispatch(selectEvent(''));
        },
        fetchOwnBusCompany: selectedCompany => {
            dispatch(fetchOwnBusCompany(selectedCompany));
        },
        addNotification: (title, message, level, position) => {
            dispatch(addNotification(title, message, level, position));
        },
        patch: (body, entity, reducerIndex) => {
            dispatch(patch(body, entity, reducerIndex));
            dispatch(selectEvent(''));
        },
        deleteById: (id, entity, reducerIndex) => {
            dispatch(deleteById(id, entity, reducerIndex));
            dispatch(selectEvent(''));
        },
        setFetchMethodOnError: fetchMethod => {
            dispatch(setFetchMethodOnError(fetchMethod));
        },
        fetchByCompanyId: (entity, companyId, projection) => {
            dispatch(fetchByCompanyId(entity, companyId, projection));
        },
    };
};

const OfferAdministrationContainer = connect(mapStateToProps, mapDispatchToProps)(OfferAdministration);

export default OfferAdministrationContainer;
