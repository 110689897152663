import React from 'react';

interface DriverRoomsProps {
    driverRoomsNeeded: boolean;
    companyProvidesDriverRooms: boolean;
    openModal: any;
    t: any;
    variant: 'full' | 'button';
}

export const DriverRooms: React.FC<DriverRoomsProps> = ({
    driverRoomsNeeded,
    companyProvidesDriverRooms,
    openModal,
    t,
    variant = 'full',
}) => {
    if (!driverRoomsNeeded) return null;

    const tKey = companyProvidesDriverRooms ? 'company_provides' : 'customer_provides';

    const ModalComponent = () => <p>{t(`task_details.drivers_room.${tKey}.long`)}</p>;

    const button = (
        <button
            type="button"
            className="btn btn-xs transparent"
            style={{ paddingLeft: '0' }}
            onClick={() => {
                openModal({
                    component: ModalComponent,
                    componentProps: {
                        dialogStyle: {
                            width: '40vw',
                        },
                    },
                    title: t('task_details.drivers_room.title'),
                    cancelButtonLabel: t('common_phrases.ok'),
                    singleButton: true,
                });
            }}>
            <span className="glyphicon glyphicon-info-sign text-info" title={t('tasks.payment_logs.title')} />
            &nbsp;{t(`task_details.drivers_room.${tKey}.short`)}
        </button>
    );

    if (variant === 'button') return button;

    return (
        <>
            <strong>{t('task_details.drivers_room.title')}:</strong>
            {button}
        </>
    );
};
