import moment from 'moment';
import { TripEventResource, TripEventType } from 'src/selectors/trip-calendar-events/trip-calendar-events';

/**
 * Creates trip booking events of the given bookings
 */
export const createBookingEvents = bookings => {
    return bookings.map(booking => createBookingEvent(booking));
};

/**
 * Creates a single trip booking calendar event with the given booking
 */
export const createBookingEvent = booking => {
    return {
        id: booking.id,
        title: `${booking.name} - ${booking.pax} PAX`,
        resourceId: TripEventResource.BOOKING,
        start: moment(booking.from),
        end: moment(booking.to),
        color: booking.cancelled ? '#a3a3a3' : '#8b73ff',
        borderColor: 'lightgrey',
        type: TripEventType.BOOKING,
        pax: booking.pax,
        cancelled: booking.cancelled,
        entity: booking,
    };
};
