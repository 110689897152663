import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import EventCategoryModal from 'src/components/admin/event-tickets/categories/event-category-modal';
import { withTranslation } from 'react-i18next';

class EventCategoryAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.TICKET_EVENT_CATEGORY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { eventCategories } = nextProps;

        if (eventCategories.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(eventCategories.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.eventCategories,
                this.props.eventCategories,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { eventCategories, t } = this.props;
        const finishFetching = this.state.startedFetching && !eventCategories.isFetching;

        const tableData = [];
        if (finishFetching && eventCategories.items.length > 0) {
            eventCategories.items.map(eventCategory => {
                tableData.push({
                    id: eventCategory.id,
                    name: eventCategory.name,
                    description: eventCategory.description,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('category.event_category')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: EventCategoryModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('category.new_event'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('category.new_category')}
                    </button>
                </legend>

                {(() => {
                    if (eventCategories.error) return <ErrorMessage object={eventCategories} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="description" dataSort={true}>
                                    {t('common_phrases.description')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, eventCategory) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: EventCategoryModal,
                            componentProps: {
                                id: eventCategory.id,
                                isCreate: false,
                            },
                            title: eventCategory.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(EventCategoryAdministration));
