import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import DatePicker from 'src/components/misc/DatePicker';
import { isNonNegativeIntegerPercent } from 'src/common/index';
import { MenuItem } from 'material-ui';
import _ from 'lodash';

class CustomerGroupDiscountEditForm extends Component {
    render() {
        const { customerGroups, handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="customerGroup"
                            label={`${t('customer_group.group')} *`}
                            component={renderSelectField}>
                            {this.getCustomerGroupMenuItems()}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="discount"
                            label={t('customer_group.discount_percent')}
                            hintText={t('customer_group.discount_percent')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="effectiveFrom" label={t('customer_group.effectiveFrom')} component={DatePicker} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="effectiveUntil"
                            label={t('customer_group.effectiveUntil')}
                            component={DatePicker}
                        />
                    </div>
                </div>
            </form>
        );
    }

    getCustomerGroupMenuItems() {
        const { t, customerGroups } = this.props;

        return _.map(customerGroups.items, customerGroup => (
            <MenuItem
                key={customerGroup.id}
                value={customerGroup['_links']['self']['href']}
                primaryText={t(`customer_groups.id.${customerGroup.id}`)}
            />
        ));
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!values.customerGroup) errors.customerGroup = t('customer_group.error_group');

    if (!isNonNegativeIntegerPercent(values.discount) || values.discount > 50)
        errors.discount = t('customer_group.error_discount');

    return errors;
};

export default reduxForm({
    form: 'customerGroupDiscountEditForm',
    validate,
})(CustomerGroupDiscountEditForm);
