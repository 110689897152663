import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save, TOMCAT_URL, UserRole } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import DriverEditForm from 'src/components/drivers/driver-edit-form';

class DriverEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, fetchById, selectedCompany } = this.props;

        if (!isCreate) fetchById(id, Entities.DRIVER, selectedCompany);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { driver } = nextProps;

        if (!this.state.startedFetching && driver.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { driver, selectedCompany, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !driver.isFetching;

        if (finishFetching) {
            if (driver.error) return <ErrorMessage object={driver} />;
            else {
                let initialValues;
                if (!isCreate && driver.content) {
                    initialValues = {
                        id: driver.content.id,
                        active: driver.content.active,
                        role: driver.content.role,
                        email: driver.content.email,
                        avatar: driver.content.avatar,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        contactData: driver.content.contactData,
                    };
                } else {
                    initialValues = {
                        role: UserRole.DRIVER,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        password: 'nvm',
                        contactData: {
                            name: undefined,
                        },
                    };
                }

                return (
                    <DriverEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        selectedCompany={selectedCompany}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, selectedCompany, isCreate, save, patch } = this.props;

        if (isCreate) save(data, Entities.DRIVER, selectedCompany);
        else patch(data, Entities.DRIVER, selectedCompany);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        driver: getUpdatedEntity(state, Entities.DRIVER, state.selectedCompany),
    };
};

export default connect(mapStateToProps, { patch, save, fetchById }, null, { withRef: true })(DriverEditModal);
