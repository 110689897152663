export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_ERROR_NOTIFICATION = 'ADD_ERROR_NOTIFICATION';

export function addNotification(title, message, level, position) {
    return {
        type: ADD_NOTIFICATION,
        payload: {
            title,
            message,
            level,
            position,
        },
    };
}

export function addErrorNotification(error) {
    return {
        type: ADD_ERROR_NOTIFICATION,
        payload: {
            error,
        },
    };
}
