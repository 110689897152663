import { createSelector } from 'reselect';

import { EventType } from 'src/utils/constants';
import { getBusCompanyEntities } from 'src/selectors/bus-companies';
import { getBlockageEntities, getReleaseEntities } from 'src/selectors/calendar-events';
import { getStateOfTasksByCompany, getTaskEntities } from 'src/selectors/tasks';
import { Entities } from 'src/common/index';
import { TaskType } from '../utils/constants';

const getSelectedEvent = state => state.selectedEvent;
const getBusEntities = state => state.entities[Entities.BUS.repository];
const getDriverEntities = state => state.entities[Entities.DRIVER.repository] || [];

export const getDetails = createSelector(
    [
        getStateOfTasksByCompany,
        getTaskEntities,
        getBusCompanyEntities,
        getBlockageEntities,
        getReleaseEntities,
        getBusEntities,
        getSelectedEvent,
        getDriverEntities,
    ],
    (stateOfTasksByCompany, tasks, busCompanies, blockages, releases, buses, selectedEvent, driverEntities) => {
        if (selectedEvent) {
            let event = {};
            let bus;

            if (selectedEvent.type === EventType.BLOCKAGE) {
                event = blockages[selectedEvent.id];
                event.type = selectedEvent.type;
            } else if (selectedEvent.type === EventType.RELEASE) {
                event = releases[selectedEvent.id];
                event.type = selectedEvent.type;
            } else {
                event = Object.assign({}, tasks[selectedEvent.id]);

                if (event.firstDriver) event.firstDriver = driverEntities[event.firstDriver];
                if (event.secondDriver) event.secondDriver = driverEntities[event.secondDriver];
            }

            if (!isNaN(selectedEvent.resourceId)) bus = buses[selectedEvent.resourceId];
            else if (selectedEvent.bus) bus = buses[selectedEvent.bus];
            else if (event.offerStatus != null && event.type === TaskType.OPEN_OFFER && event.bus != null)
                bus = buses[event.bus.id];

            return {
                bus: bus,
                event: event,
                type: event.type,
                isFetching: stateOfTasksByCompany.isFetching,
            };
        }
    },
);
