import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchByCompanyId, fetchById, getUpdatedEntity, patch } from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import { getActiveDrivers } from 'src/selectors/drivers';
import ErrorMessage from 'src/components/misc/error-message';
import SendDriverDetailsToCustomerForm from 'src/components/taskadministration/senddriverdetails/SendDriverDetailsToCustomerForm';
import { DriverSelectionType } from 'src/components/taskadministration/senddriverdetails/DriverSelectionType';
import { sendDriverDetailsToCustomer } from 'src/actions/tasks';

class SendDriverDetailsToCustomerModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany, fetchByCompanyId, fetchById } = this.props;

        fetchByCompanyId(Entities.DRIVER, selectedCompany);
        fetchById(id, Entities.TASK, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { task, drivers } = nextProps;

        if (!this.state.startedFetching && (drivers.isFetching || task.isFetching))
            this.setState({ startedFetching: true });
    }

    callSubmit = () => {
        return this.childForm.getWrappedInstance().submit();
    };

    didFormChange = () => {
        return this.childForm.getWrappedInstance().pristine;
    };

    render() {
        const { task, drivers, t } = this.props;

        const finishFetching = this.state.startedFetching && !drivers.isFetching && !task.isFetching;

        if (finishFetching) {
            if (drivers.error) return <ErrorMessage object={drivers} />;
            else if (task.error) return <ErrorMessage object={task} />;
            else {
                const firstDriver = task.content.firstDriver
                    ? drivers.items.find(driver => driver.id === task.content.firstDriver)
                    : undefined;
                const secondDriver = task.content.secondDriver
                    ? drivers.items.find(driver => driver.id === task.content.secondDriver)
                    : undefined;

                const initialValues = {
                    id: task.content.id,
                    firstDriver: firstDriver ? firstDriver._links.self.href : undefined,
                    secondDriver: secondDriver ? secondDriver._links.self.href : undefined,
                    firstDriverPhone: firstDriver ? firstDriver.contactData.phone : undefined,
                    secondDriverPhone: secondDriver ? secondDriver.contactData.phone : undefined,
                    firstDriverType: firstDriver ? DriverSelectionType.EXISTING : DriverSelectionType.NEW,
                    secondDriverType: secondDriver ? DriverSelectionType.EXISTING : DriverSelectionType.NONE,
                };

                return (
                    <SendDriverDetailsToCustomerForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        drivers={drivers.items || []}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit = data => {
        const { handleClose, id, selectedCompany } = this.props;

        if (
            data.firstDriverType === DriverSelectionType.EXISTING ||
            data.secondDriverType === DriverSelectionType.EXISTING
        ) {
            const patchTaskData = {
                id: id,
            };

            if (data.firstDriver) patchTaskData.firstDriver = data.firstDriver;
            if (data.secondDriver) patchTaskData.secondDriver = data.secondDriver;

            this.props.patch(patchTaskData, Entities.TASK, selectedCompany).then(() => {
                this.sendDriverDetailsEmailToCustomer(data);
            });
        } else this.sendDriverDetailsEmailToCustomer(data);

        handleClose();
    };

    sendDriverDetailsEmailToCustomer = data => {
        const { selectedCompany, task } = this.props;

        this.props.sendDriverDetailsToCustomer(
            task.content.id,
            this.getDriversContactFromFormData(data),
            selectedCompany,
            this.onErrorSendEmail,
            this.onSuccessSendEmail,
        );
    };

    onErrorSendEmail = () => {
        const { t } = this.props;
        this.props.addNotification(t('common_phrases.error'), t('sending.error_sending'), 'error', 'tr');
    };

    onSuccessSendEmail = () => {
        const { t } = this.props;
        this.props.addNotification(t('common_phrases.success'), t('sending.successfully_sent'), 'success', 'tr');
    };

    getDriversContactFromFormData = data => {
        const { drivers } = this.props;

        const driversContactData = {
            notes: data.notes,
        };

        driversContactData.firstDriver = {
            name:
                data.firstDriverType === DriverSelectionType.EXISTING
                    ? this.getDriverNameFromLink(data.firstDriver)
                    : data.firstDriverName,
            phone: data.firstDriverPhone,
        };

        driversContactData.secondDriver = {
            name:
                data.secondDriverType === DriverSelectionType.EXISTING
                    ? this.getDriverNameFromLink(data.secondDriver)
                    : data.secondDriverName,
            phone: data.secondDriverPhone,
        };

        return driversContactData;
    };

    getDriverNameFromLink = driverLink => {
        const { drivers } = this.props;

        const driver = drivers.items.find(d => d._links.self.href === driverLink);

        return `${driver.contactData.firstName} ${driver.contactData.lastName}`;
    };
}

const mapStateToProps = state => {
    return {
        task: getUpdatedEntity(state, Entities.TASK, state.selectedCompany),
        drivers: getActiveDrivers(state),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        fetchByCompanyId,
        patch,
        addNotification,
        sendDriverDetailsToCustomer,
    },
    null,
    { withRef: true },
)(SendDriverDetailsToCustomerModal);
