import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push, RouterAction } from 'react-router-redux';
import { Entities, EntityCollectionState, FetchAll, fetchAll, getEntities, Projection } from 'src/common/index';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { toggleFormatter } from 'src/utils/formatters';
import { InjectedTranslateProps, StoreState } from 'src/types';
import OperatingToolIntegration from 'src/common/entity/integration/OperatingToolIntegration';
import ParentForm from 'src/components/misc/parent-form';
import OperatingToolIntegrationForm from 'src/components/admin/integration/operatingtool/OperatingToolIntegrationForm';
import { LocationDescriptor, LocationState } from 'history';

interface MapStateToProps {
    integrations: EntityCollectionState<OperatingToolIntegration>;
}

interface MapDispatchToProps extends FetchAll {
    push: (location: LocationDescriptor, state?: LocationState) => RouterAction;
}

interface TableDataRow {
    id: number;
    name: string;
    url: string;
    partnerId: number;
    active: boolean;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedTranslateProps;

class OperatingToolAdministration extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper({ onRowClick: this.onSelectIntegration });
        this.state = {
            startedFetching: false,
        };
    }

    public componentWillMount() {
        this.props.fetchAll(Entities.OPERATINGTOOL_INTEGRATION);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { integrations } = nextProps;

        if (!this.state.startedFetching && integrations.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(integrations.items.length);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.integrations, this.props.integrations, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    public render() {
        const { integrations, t } = this.props;
        const finishFetching = this.state.startedFetching && !integrations.isFetching;

        const tableData: TableData = [];

        if (finishFetching && integrations.items.length > 0) {
            integrations.items.map((integration: OperatingToolIntegration) => {
                tableData.push({
                    id: integration.id,
                    name: integration.name,
                    url: integration.url,
                    partnerId: integration.partnerId,
                    active: integration.active,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('task_details.tool_integration')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: ParentForm,
                                componentProps: {
                                    isCreate: true,
                                    mainEntityProps: {
                                        entity: Entities.OPERATINGTOOL_INTEGRATION,
                                    },
                                    childForm: {
                                        component: OperatingToolIntegrationForm,
                                    },
                                },
                                title: t('task_details.new_tool_integration'),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('booked_tasks.new_integration')}
                    </button>
                </legend>

                {(() => {
                    if (integrations.error) return <ErrorMessage object={integrations} />;
                    else
                        return (
                            <div className="clickableTable">
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                        {t('common_phrases.name')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="url" dataSort={true}>
                                        {t('contact_data.url')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="partnerId" dataSort={true}>
                                        {t('task_details.partner_id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="active"
                                        width="110"
                                        dataSort={true}
                                        dataFormat={(cell, row) =>
                                            toggleFormatter(row, Entities.OPERATINGTOOL_INTEGRATION, 'active')
                                        }>
                                        {t('common_phrases.active')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    private tableActionsFormatter = (cell: any, integration: TableDataRow) => {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.OPERATINGTOOL_INTEGRATION,
                                    id: integration.id,
                                },
                                projection: Projection.FULL,
                                childForm: {
                                    component: OperatingToolIntegrationForm,
                                },
                            },
                            title: integration.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    };

    private onSelectIntegration = (integration: TableDataRow, col: number) => {
        if (integration.id && col < 3) {
            this.props.push('/admin/integrations/operatingtool/' + integration.id);
        }
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        integrations: getEntities(state, Entities.OPERATINGTOOL_INTEGRATION),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        fetchAll,
        push,
    })(composeModalActions(OperatingToolAdministration)),
);
