import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors } from 'src/utils/constants';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import HelpCategoryModal from 'src/components/admin/help/categories/help-category-modal';
import { withTranslation } from 'react-i18next';

class AdminHelpCategoryAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.HELP_CATEGORY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { helpCategories } = nextProps;

        if (!this.state.startedFetching && helpCategories.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(helpCategories.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.helpCategories,
                this.props.helpCategories,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { helpCategories, t } = this.props;
        const finishFetching = this.state.startedFetching && !helpCategories.isFetching;

        const tableData = [];

        if (finishFetching && helpCategories.items.length > 0) {
            helpCategories.items.map(helpCategory => {
                tableData.push({
                    id: helpCategory.id,
                    name: helpCategory.name,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('help.categories')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: HelpCategoryModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('category.new_category'),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('category.new_category')}
                    </button>
                </legend>

                {(() => {
                    if (helpCategories.error) return <ErrorMessage object={helpCategories} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.category')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    openConfirmDeleteDialog(helpCategory) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: helpCategory,
                onProceedDialog: helpCategory => this.props.deleteById(helpCategory.id, Entities.HELP_CATEGORY),
                bodyText: t('help.confirm_delete_category'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: helpCategory.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('help.error_category_has_entries'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, helpCategory) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: HelpCategoryModal,
                            componentProps: {
                                id: helpCategory.id,
                                isCreate: false,
                            },
                            title: t('user_functionality.edit'),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.HELP_CATEGORY.repository}/${helpCategory.id}/deletable`,
                        )
                            .then(() => this.openConfirmDeleteDialog(helpCategory))
                            .catch(() => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminHelpCategoryAdministration));
