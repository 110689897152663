import { Entities, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export const SET_INTEGRATION = 'SET_INTEGRATION';

export const REQUEST_BOOKING_INTEGRATIONS = 'REQUEST_BOOKING_INTEGRATIONS';
export const SUCCESS_FETCH_BOOKING_INTEGRATIONS = 'SUCCESS_FETCH_BOOKING_INTEGRATIONS';
export const FAILURE_BOOKING_INTEGRATIONS = 'FAILURE_BOOKING_INTEGRATIONS';

export function fetchOwnedBookingIntegration() {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.BOOKING_INTEGRATION.repository}/ownIntegration`,
                method: 'GET',
                secondaryActionTypes: [
                    REQUEST_BOOKING_INTEGRATIONS,
                    SUCCESS_FETCH_BOOKING_INTEGRATIONS,
                    FAILURE_BOOKING_INTEGRATIONS,
                ],
                reducerIndex: 0,
                schema: Entities.BOOKING_INTEGRATION.schema,
                errorCallback: () => {},
            }),
        );
    };
}

export function fetchOwnedBookingIntegrations() {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.BOOKING_INTEGRATION.repository}/ownIntegrations`,
                method: 'GET',
                secondaryActionTypes: [
                    REQUEST_BOOKING_INTEGRATIONS,
                    SUCCESS_FETCH_BOOKING_INTEGRATIONS,
                    FAILURE_BOOKING_INTEGRATIONS,
                ],
                reducerIndex: 0,
                schema: Entities.BOOKING_INTEGRATION.schema,
                errorCallback: () => {},
            }),
        );
    };
}
