import CompanyIdName from 'src/common/entity/company/CompanyIdName';
import DetailsBookingIntegration from 'src/common/entity/integration/DetailsBookingIntegration';
import BookingModule from 'src/common/entity/module/BookingModule';
import { LocationDescriptor, LocationState } from 'history';
import { CircularProgress } from 'material-ui';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push, RouterAction } from 'react-router-redux';
import HttpStatus from 'http-status-codes';
import {
    Entities,
    EntityCollectionState,
    FetchById,
    fetchById,
    getUpdatedEntity,
    SingleEntityState,
    UserRole,
} from 'src/common/index';
import { fetchOwnedBookingIntegrations } from 'src/actions/integrations';
import { isLoggedInWithRole } from 'src/components/account/login';
import JumboStats, { JumboStat } from 'src/components/misc/JumboStats';
import SearchRequestsTable from 'src/components/search-requests/SearchRequestsTable';
import { getOwnAccount } from 'src/selectors/accounts';
import { getOwnBookingIntegrations } from 'src/selectors/integrations';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import { InjectedTranslateProps, StoreState } from 'src/types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

interface MapStateToProps {
    integrations: EntityCollectionState<DetailsBookingIntegration>;
    account: SingleEntityState<any>;
}

interface MapDispatchToProps extends FetchById {
    fetchOwnedBookingIntegrations: () => void;
    push(location: LocationDescriptor, state?: LocationState): RouterAction;
}

interface InjectedProps {
    params: any;
}

interface State {
    startedFetching: boolean;
    selectedIntegration: DetailsBookingIntegration | undefined;
    integrationMenuItems: JSX.Element[];
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedProps & InjectedTranslateProps;

class BookingIntegrationDetails extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            startedFetching: false,
            selectedIntegration: undefined,
            integrationMenuItems: [],
        };
    }

    public componentWillMount() {
        const { account } = this.props;
        const integrationId = this.props.params.id;
        if (isLoggedInWithRole(account, [UserRole.ADMIN, UserRole.SUPER_ADMIN]))
            this.props.fetchById(integrationId, Entities.BOOKING_INTEGRATION, 0, 'details');
        else this.props.fetchOwnedBookingIntegrations();
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { integrations } = nextProps;
        if (!this.state.startedFetching && integrations.isFetching) this.setState({ startedFetching: true });
        if (this.state.startedFetching && !integrations.isFetching) {
            this.setState({ selectedIntegration: integrations.items[0] });

            // populate integration menu items for operator select field
            if (integrations.items.length > 0) {
                const menuItems = integrations.items.map(integration => {
                    return <MenuItem key={integration.id} value={integration} primaryText={integration.url} />;
                });

                this.setState({ integrationMenuItems: menuItems });
            }
        }
    }

    public render() {
        const { integrations, account, t } = this.props;
        const finishFetching = this.state.startedFetching && !integrations.isFetching;

        if (!finishFetching) return <CircularProgress />;

        if (integrations.error && integrations.error.status !== HttpStatus.NOT_FOUND) {
            if (integrations.error.status === HttpStatus.FORBIDDEN)
                return <legend className="legend">{t('search_requests.module_deactivated')}</legend>;

            return <ErrorMessage object={integrations} />;
        }

        const integration = this.state.selectedIntegration;

        if (!integration) return <legend className="legend">{t('search_requests.no_integration_available')}</legend>;

        // admin interface
        if (isLoggedInWithRole(account, [UserRole.ADMIN, UserRole.SUPER_ADMIN])) {
            return (
                <div>
                    <legend className="legend">
                        <a target="_blank" href={integration.url}>
                            {integration.url}
                        </a>
                        {isLoggedInWithRole(account, [UserRole.ADMIN, UserRole.SUPER_ADMIN]) && (
                            <button
                                className="btn btn-xs btn-link nav-link pull-right"
                                onClick={() => {
                                    this.props.push('/admin/integrations/booking');
                                }}>
                                <span className="glyphicon glyphicon-circle-arrow-left" />
                                &nbsp;{t('common_phrases.back')}
                            </button>
                        )}
                    </legend>
                    <JumboStats values={this.getStats(integration)} />
                    <SearchRequestsTable integrationId={integration.id} t={t} />
                </div>
            );
        }

        // operator interface
        return (
            <div>
                <legend className="legend">
                    <SelectField
                        floatingLabelStyle={{ top: 28 }}
                        menuStyle={{ marginTop: 5, width: '300px' }}
                        underlineStyle={{ display: 'none' }}
                        value={integration}
                        onChange={(_event: any, _index: number, value: DetailsBookingIntegration) => {
                            this.setState({ selectedIntegration: value });
                        }}
                        floatingLabelText={integration.url}
                        fullWidth={true}>
                        {this.state.integrationMenuItems}
                    </SelectField>
                </legend>

                <JumboStats values={this.getStats(integration)} />
                <SearchRequestsTable integrationId={integration.id} t={t} />
            </div>
        );
    }

    private getStats = (integration: DetailsBookingIntegration): JumboStat[] => {
        const { t } = this.props;
        const activeModules = integration.modules.filter((module: BookingModule) => module.active);

        // company stats
        const companiesValue = integration.allCompanies
            ? t('search_requests.stats.all_companies')
            : integration.companies.length;
        const companiesTooltip = !integration.allCompanies && (
            <ul>
                {integration.companies.map((company: CompanyIdName) => (
                    <li key={company.id}>{company.companyName}</li>
                ))}
            </ul>
        );

        const companyStats = {
            label: t('search_requests.stats.companies'),
            value: companiesValue,
            tooltip: companiesTooltip,
        };

        // module stats
        const modulesTooltip = activeModules.length > 0 && (
            <ul>
                {activeModules.map((module: BookingModule) => (
                    <li key={module.type}>{module.type}</li>
                ))}
            </ul>
        );

        const moduleStats = {
            label: t('search_requests.stats.modules'),
            value: activeModules.length,
            tooltip: modulesTooltip,
        };

        // sum request stats
        const sumRequestStats = {
            label: t('search_requests.stats.requests_all'),
            value: integration.requestStats.sumRequests,
        };

        // current month request stats
        const currentMonthRequestStats = {
            label: t('search_requests.stats.requests_month'),
            value: integration.requestStats.sumRequestsCurrentMonth,
        };

        return [companyStats, moduleStats, sumRequestStats, currentMonthRequestStats];
    };
}

const mapStateToProps = (state: StoreState) => {
    const account = getOwnAccount(state);

    let integrations;

    // admin views a single integration -> therefore we need to wrap it in an array to use the same shape
    if (isLoggedInWithRole(account, [UserRole.ADMIN, UserRole.SUPER_ADMIN])) {
        const integration = getUpdatedEntity(state, Entities.BOOKING_INTEGRATION);
        integrations = {
            ...integration,
            items: [integration.content],
        };
    } else {
        integrations = getOwnBookingIntegrations(state);
    }

    return {
        integrations,
        account,
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        push,
        fetchById,
        fetchOwnedBookingIntegrations,
    })(composeModalActions(BookingIntegrationDetails)),
);
