import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities } from 'src/common/index';
import HelpEntryAdministration from 'src/components/admin/help/entries/help-entry-administration';

const mapStateToProps = state => {
    return {
        helps: getEntities(state, Entities.HELP),
        helpCategories: state.entities[Entities.HELP_CATEGORY.repository],
    };
};

const HelpEntryAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    deleteById,
})(HelpEntryAdministration);

export default HelpEntryAdministrationContainer;
