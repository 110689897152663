import { connect } from 'react-redux';
import { addErrorNotification } from 'src/actions/notifications';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';

import AdminAssociationAdministration from 'src/components/admin/associations/admin-association-administration';

const mapStateToProps = state => {
    return {
        associations: getEntities(state, Entities.ASSOCIATION),
    };
};

const AdminAssociationAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    patch,
    addErrorNotification,
})(AdminAssociationAdministration);

export default AdminAssociationAdministrationContainer;
