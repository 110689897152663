import { Entities, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export function deleteTripAttribute(attributeId) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TRIP_ATTRIBUTE.repository}/removeTripAttribute?attributeId=${attributeId}`,
                method: 'DELETE',
                secondaryActionTypes: [
                    `REQUEST_${Entities.TRIP_ATTRIBUTE.action}`,
                    `SUCCESS_DELETE_${Entities.TRIP_ATTRIBUTE.action}`,
                    `FAILURE_${Entities.TRIP_ATTRIBUTE.action}`,
                ],
                schema: Entities.TRIP_ATTRIBUTE.schema,
                reducerIndex: 0,
                parameter: attributeId,
            }),
        );
    };
}
