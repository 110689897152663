import React, { Component } from 'react';
import moment from 'moment';
import { formatMinutes } from 'src/components/misc/redux-form-helpers';

class AvailabilityDetails extends Component {
    render() {
        const { event, t } = this.props;

        return (
            <table className="table table-details">
                <thead style={{ backgroundColor: event.color }}>
                    <tr
                        style={{
                            //white semitransparent overlay to match calendar event colors
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        }}>
                        <th>{t('calendar.availability')}</th>
                        <th>{t('booked_tasks.from')}</th>
                        <th>{t('booked_tasks.to')}</th>
                        <th>{t('calendar.duration')}</th>
                        <th>{t('task_details.pax')}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <p>{event.title}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.start.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.end.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{formatMinutes(t, moment.duration(event.end.diff(event.start)).asMinutes())}</p>
                        </td>
                        <td>
                            <p>{event.pax}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default AvailabilityDetails;
