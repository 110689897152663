import React, { Component } from 'react';
import { DragSource } from 'react-dnd';

import driverPNG from 'static/driver.png';
import { ItemTypes } from 'src/utils/constants';
import { Entities, TOMCAT_URL } from 'src/common/index';

const driverSource = {
    beginDrag(props) {
        const { addNotification, busdriver, t } = props;

        if (busdriver && busdriver.restedLessThan11Hours)
            addNotification(
                t('warning'),
                t('driver.error_too_short_pause', { driver: busdriver.contactData.firstName }),
                'warning',
                'tr',
            );

        return {
            driverLink: busdriver._links.self.href,
        };
    },

    endDrag(props, monitor) {
        const { selectedCompany, patch } = props;
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();

        if (dropResult) {
            if (dropResult.firstDriver)
                patch({ id: dropResult.taskId, firstDriver: item.driverLink }, Entities.TASK, selectedCompany);
            else patch({ id: dropResult.taskId, secondDriver: item.driverLink }, Entities.TASK, selectedCompany);
        }
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

class BusDriver extends Component {
    render() {
        const { busdriver, connectDragSource, t } = this.props;
        const avatarURL = busdriver.avatar
            ? `${TOMCAT_URL}public/documents/${busdriver.avatar.filename}`
            : `${driverPNG}`;
        const lastName = busdriver.contactData.lastName ? busdriver.contactData.lastName.charAt(0) + '.' : '';

        return connectDragSource(
            <div key={busdriver.id} className="row driverwrapper">
                <div className="col-md-2 driveravatar">
                    <img alt={busdriver.contactData.firstName} src={avatarURL} width="25" height="25" />
                </div>
                {busdriver.restedLessThan9Hours ? (
                    <div className="col-md-10 drivername">
                        <div className="row">
                            <div className="col-md-9">
                                <span>{`${busdriver.contactData.firstName} ${lastName}`}</span>
                            </div>
                            <div className="col-md-3">
                                <span
                                    title={t('driver.less_nine_hours')}
                                    className="glyphicon glyphicon-warning-sign text-warning"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-md-10 drivername">
                        <span>{`${busdriver.contactData.firstName} ${lastName}`}</span>
                    </div>
                )}
            </div>,
        );
    }
}

export default DragSource(ItemTypes.DRIVER, driverSource, collect)(BusDriver);
