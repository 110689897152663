import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderInput } from 'src/components/misc/redux-form-helpers';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import { Tab, Tabs } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { integrationAsyncValidate, isBlank, isUrl } from 'src/components/misc/validations';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

class OperatingToolIntegrationForm extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            connection: false,
            error: undefined,
            isFetching: false,
        };
    }

    componentDidMount() {
        const { url, partnerId, basicAuthUsername, basicAuthPassword } = this.props.initialValues;
        if (url && partnerId) this.testConnection(url, partnerId, basicAuthUsername, basicAuthPassword);
    }

    render() {
        const { handleSubmit, selectedSliderIndex, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                </Tabs>
                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    {/* General Tab */}
                    <div className="view">
                        <div className="row">
                            <div className="col-md-6">
                                <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    name="url"
                                    label={`${t('booked_tasks.partner_url')} *`}
                                    component={renderInput}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="partnerId"
                                    label={`${t('task_details.partner_id')} *`}
                                    component={renderInput}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="basicAuthUsername"
                                    label={t('booked_tasks.basic_auth_user')}
                                    component={renderInput}
                                />
                            </div>

                            <div className="col-md-6">
                                <Field
                                    name="basicAuthPassword"
                                    label={t('booked_tasks.basic_auth_password')}
                                    component={renderInput}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 voffset40">
                                <RaisedButton
                                    label={t('user_functionality.test_connection')}
                                    secondary={true}
                                    labelPosition="before"
                                    onClick={this.onClickTestConnection}
                                    disabled={this.state.isFetching}
                                    fullWidth={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 voffset">{this.renderConnectionStatus()}</div>
                        </div>
                    </div>
                </SwipeableViews>
            </form>
        );
    }

    onClickTestConnection() {
        const { partnerId, url, basicAuthUsername, basicAuthPassword } = this.props;
        this.testConnection(url, partnerId, basicAuthUsername, basicAuthPassword);
    }

    testConnection(url, partnerId, basicAuthUsername, basicAuthPassword) {
        this.setState({ isFetching: true });

        asyncRequest({
            method: 'POST',
            endpoint: `${TOMCAT_URL}api/${Entities.OPERATINGTOOL_INTEGRATION.repository}/test-connection`,
            body: {
                url: url,
                partnerId: partnerId,
                basicAuthUsername: basicAuthUsername,
                basicAuthPassword: basicAuthPassword,
            },
        })
            .then(response => {
                const json = response.json;
                if (json.error) throw json.error;

                this.setState({
                    connection: true,
                    isFetching: false,
                    error: undefined,
                });
            })
            .catch(error => {
                this.setState({
                    connection: false,
                    isFetching: false,
                    error: error,
                });
            });
    }

    renderConnectionStatus() {
        const { t } = this.props;
        if (this.state.isFetching) return <CircularProgress />;

        if (this.state.error) {
            const error = this.state.error.status ? this.state.error.status : this.state.error;
            return (
                <div className="alert alert-danger">
                    <h4>{t('task_details.connection_error')}</h4>
                    <span>{t('task_details.no_connection_with_integration')}</span>
                    <br />
                    <span>{`Error:  ${error}`}</span>
                </div>
            );
        }

        if (this.state.connection)
            return (
                <div className="alert alert-success">
                    <h4>{t('task_details.connection_success')}</h4>
                    <span>{t('task_details.connection_with_integration')}</span>
                </div>
            );

        return null;
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (isBlank(values.url)) errors.url = t('error_missing.fill_in_url');
    else if (!isUrl(values.url)) errors.url = t('error_missing.fill_in_valid_url');

    if (isBlank(values.partnerId)) errors.partnerId = t('error_missing.fill_in_partner_id');

    return errors;
};

const asyncValidate = (values, dispatch, props, blurredField) => {
    return integrationAsyncValidate(props.t, Entities.OPERATINGTOOL_INTEGRATION.repository, values, blurredField);
};

OperatingToolIntegrationForm = reduxForm({
    form: 'OperatingToolIntegrationForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name', 'url'],
})(OperatingToolIntegrationForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, operatingtoolIntegration } = ownProps;

    let initialValues;
    if (!isCreate && operatingtoolIntegration)
        initialValues = {
            id: operatingtoolIntegration.id,
            name: operatingtoolIntegration.name,
            url: operatingtoolIntegration.url,
            partnerId: operatingtoolIntegration.partnerId,
            active: operatingtoolIntegration.active,
            basicAuthUsername: operatingtoolIntegration.basicAuthUsername,
            basicAuthPassword: operatingtoolIntegration.basicAuthPassword,
        };
    else
        initialValues = {
            active: false,
        };

    return {
        initialValues: initialValues,
        url: selector(state, 'url'),
        partnerId: selector(state, 'partnerId'),
        basicAuthUsername: selector(state, 'basicAuthUsername'),
        basicAuthPassword: selector(state, 'basicAuthPassword'),
    };
};
const selector = formValueSelector('OperatingToolIntegrationForm');
OperatingToolIntegrationForm = connect(
    mapStateToProps,
    {
        change,
    },
    null,
    { withRef: true },
)(OperatingToolIntegrationForm);

export default OperatingToolIntegrationForm;
