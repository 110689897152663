import React, { Component } from 'react';
import moment from 'moment';

/**
 * Displays information about the LocalBus of a specific stop in a modal.
 */
export default class LocalBusButton extends Component {
    render() {
        const { stop, t } = this.props;
        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                style={{ paddingLeft: '0' }}
                onClick={() => {
                    this.props.openModal({
                        component: () => (
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-striped voffset">
                                        <tbody>
                                            <tr>
                                                <td className="col-md-3">{t('local_bus.location')}</td>
                                                <td className="col-md-9">{stop.location.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-3">{t('local_bus.time')}</td>
                                                <td className="col-md-9">{moment(stop.time).format()}</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-3">{t('local_bus.distance')}</td>
                                                <td className="col-md-9">{`${stop.localBus.distance}km`}</td>
                                            </tr>
                                            {stop.localBus.description && (
                                                <tr>
                                                    <td className="col-md-3">{t('local_bus.description')}</td>
                                                    <td className="col-md-9">{stop.localBus.description}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ),
                        componentProps: {
                            dialogStyle: {
                                width: '110%',
                                maxWidth: 'none',
                            },
                        },
                        title: t('local_bus.title'),
                        cancelButtonLabel: t('common_phrases.ok'),
                        singleButton: true,
                    });
                }}>
                <span className="glyphicon glyphicon-info-sign text-info" title={t('local_bus.hint')} />
            </button>
        );
    }
}
