import eps from '../../img/eps.png';
import maestro from '../../img/maestro.png';
import mastercard from '../../img/mastercard.png';
import sofort from '../../img/sofort-de.png';
import visa from '../../img/visa.png';

export enum PaymentMethod {
    EPS = 'EPS',
    MAESTRO = 'MAESTRO',
    MASTERCARD = 'MASTERCARD',
    DIRECTBANK = 'DIRECTBANK',
    VISA = 'VISA',
    INVOICE = 'INVOICE',
}

export default class Gateway {
    public id: PaymentMethod;
    public description: string;
    public logo?: any;

    constructor(json: any) {
        this.id = json.id;
        this.description = json.description;
        this.logo = logoById(json.id);
    }
}

const logoById = (id: PaymentMethod): any => {
    switch (id) {
        case PaymentMethod.DIRECTBANK:
            return sofort;
        case PaymentMethod.EPS:
            return eps;
        case PaymentMethod.MAESTRO:
            return maestro;
        case PaymentMethod.MASTERCARD:
            return mastercard;
        case PaymentMethod.VISA:
            return visa;
        default:
            return null;
    }
};
