import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    proceedDialog() {
        const { onProceedDialog, event } = this.props;

        if (onProceedDialog) onProceedDialog(event);

        this.close();
    }

    cancelDialog() {
        const { onCancelDialog, event } = this.props;

        if (onCancelDialog && event) onCancelDialog(event);

        this.close();
    }

    close() {
        const { handleClose, closeDrawer } = this.props;

        if (closeDrawer) closeDrawer();
        handleClose();
    }

    render() {
        const { bodyText, proceedText, cancelText, proceedButtonColor, cancelButtonColor, isNotification } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 voffset">{bodyText}</div>
                </div>

                <div className="row">
                    <div className="col-md-12 voffset">
                        <div className="form-group pull-right">
                            {!isNotification && (
                                <RaisedButton
                                    onClick={this.cancelDialog}
                                    label={cancelText}
                                    backgroundColor={cancelButtonColor}
                                    labelColor="#ffffff"
                                    className="confirm-dialog-2ndButton"
                                />
                            )}
                            <RaisedButton
                                onClick={this.proceedDialog}
                                label={proceedText}
                                backgroundColor={proceedButtonColor}
                                className="pull-right"
                                labelColor="#ffffff"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmDialog;
