import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import { loginWithToken } from 'src/common/index';
import { getOwnAccount } from 'src/selectors/accounts';
import { fetchOwnAccount } from 'src/actions/accounts';
import LoginForm from 'src/components/account/login-form';

const UUID_V4_REGEX = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

class LoginWithToken extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        const { push, location, loginWithToken } = this.props;

        this.redirectIfLoggedIn(this.props);

        const query = location.query;

        if (query != null && typeof query.token === 'string' && query.token.match(UUID_V4_REGEX))
            loginWithToken(query.token, fetchOwnAccount, undefined, true);
        else {
            console.error('Missing token, or token is not a UUID: ', query != null ? query.token : query);
            push('/login-with-token?error=token');
        }
    }

    componentWillReceiveProps(nextProps) {
        this.redirectIfLoggedIn(nextProps);
    }

    redirectIfLoggedIn(props) {
        const { ownAccount, push } = props;
        const loggedIn = ownAccount && ownAccount.entity && !ownAccount.isFetching;

        //redirect after successful login, or when already logged in
        if (loggedIn) {
            const redirectUrl = Cookies.get('redirectURLAfterLogin') ? Cookies.get('redirectURLAfterLogin') : '/';
            if (redirectUrl) push(redirectUrl);
            else push('/');
            Cookies.remove('redirectURLAfterLogin');
        }
    }

    componentWillMount() {
        $('body').addClass('background-image');
    }

    componentWillUnmount() {
        $('body').removeClass('background-image');
    }

    render() {
        const query = this.props.location.query;
        const hasError = query && query.error;
        const hasExpired = query && query.expired;
        const hasLogout = query && query.logout;

        return (
            <div id="login-div-outer">
                <div id="login-div-inner">
                    <LoginForm hasError={hasError} hasExpired={hasExpired} hasLogout={hasLogout} statusOnly={true} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ownAccount: getOwnAccount(state),
    };
};

export default connect(mapStateToProps, { loginWithToken, push })(LoginWithToken);
