import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { ButtonColors } from 'src/utils/constants';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import CategoryEditModal from 'src/components/administration/category-edit-modal';
import { toggleFormatter } from 'src/utils/formatters';
import { withTranslation } from 'react-i18next';

class CategoryAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchByCompanyId(Entities.BUS, this.props.selectedCompany);
        this.props.fetchByCompanyId(Entities.BUS_CATEGORY, this.props.selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { categories } = nextProps;

        if (categories.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(categories.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.categories, this.props.categories, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { categories, t } = this.props;
        const finishFetching = this.state.startedFetching && !categories.isFetching;

        const tableData = [];
        if (finishFetching && categories.items.length > 0) {
            categories.items.map(category => {
                tableData.push({
                    id: category.id,
                    name: category.name,
                    position: category.position,
                    group: category.group,
                    groupResultLimit: category.groupResultLimit,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('category.all')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CategoryEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('category.new_category'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('category.new_category')}
                    </button>
                </legend>

                {(() => {
                    if (categories.error) return <ErrorMessage object={categories} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="position" dataSort={true}>
                                    {t('review_categories.position')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="group"
                                    dataSort={true}
                                    dataFormat={(cell, row) =>
                                        toggleFormatter(row, Entities.BUS_CATEGORY, 'group', 0, undefined)
                                    }>
                                    {t('category.group')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="groupResultLimit" dataSort={true}>
                                    {t('category.group_result_limit')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onProceedDeleteBusCategory(category) {
        this.props.deleteById(category.id, Entities.BUS_CATEGORY, this.props.selectedCompany);
    }

    openConfirmDeleteDialog(category) {
        const { buses, t } = this.props;

        let categoryHasBuses = false;
        buses.items.forEach(bus => {
            if (bus.category === category.id) categoryHasBuses = true;
        });

        if (categoryHasBuses) {
            this.props.openModal({
                component: ConfirmDialog,
                componentProps: {
                    bodyText: t('category.error_category'),
                    proceedText: t('common_phrases.ok'),
                    proceedButtonColor: ButtonColors.NOTIFY,
                    isNotification: true,
                },
                title: t('common_phrases.attention'),
                noButtons: true,
            });
        } else {
            this.table.reset();
            this.props.openModal({
                component: ConfirmDialog,
                componentProps: {
                    event: category,
                    onProceedDialog: this.onProceedDeleteBusCategory,
                    bodyText: t('category.confirm_delete'),
                    proceedText: t('user_functionality.delete'),
                    cancelText: t('common_phrases.no'),
                    cancelButtonColor: ButtonColors.NOTIFY,
                    proceedButtonColor: ButtonColors.DANGER,
                },
                title: category.name,
                noButtons: true,
            });
        }
    }

    tableActionsFormatter(cell, category) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CategoryEditModal,
                            componentProps: {
                                isCreate: false,
                                id: category.id,
                            },
                            title: category.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => this.openConfirmDeleteDialog(category)}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(CategoryAdministration));
