import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors, genderName } from 'src/utils/constants';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import DriverEditModal from 'src/components/drivers/driver-edit-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import Cookies from 'js-cookie';
import Toggle from 'material-ui/Toggle';
import { withTranslation } from 'react-i18next';

class DriverAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            showDriverId: Cookies.get('showDriverId') ? Cookies.get('showDriverId') === 'true' : false,
        };
    }

    componentWillMount() {
        this.props.fetchByCompanyId(Entities.DRIVER, this.props.selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { drivers } = nextProps;

        if (drivers.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(drivers.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.drivers, this.props.drivers, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { drivers, t } = this.props;
        const finishFetching = this.state.startedFetching && !drivers.isFetching;

        const tableData = [];
        if (finishFetching && drivers.items.length > 0) {
            drivers.items.map(driver => {
                if (driver.active === true)
                    tableData.push({
                        id: driver.id,
                        email: driver.email,
                        firstName: driver.contactData.firstName,
                        lastName: driver.contactData.lastName,
                        gender: driver.contactData.gender,
                        phone: driver.contactData.phone,
                    });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('administration.all_drivers')}
                    <div className="pull-right">
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                this.table.reset();
                                this.props.openModal({
                                    component: DriverEditModal,
                                    componentProps: {
                                        isCreate: true,
                                    },
                                    title: t('administration.new_driver'),
                                    mandatoryFields: true,
                                });
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('administration.new_driver')}
                        </button>
                    </div>
                    <div className="pull-right">
                        <Toggle
                            label="ID"
                            labelStyle={{
                                fontSize: '16px',
                                color: '#0c66ab',
                                paddingRight: '5px',
                            }}
                            style={{
                                paddingRight: '20px',
                            }}
                            toggled={this.state.showDriverId}
                            onToggle={() => {
                                Cookies.set('showDriverId', !this.state.showDriverId, {
                                    expires: 10 * 365,
                                    secure: true,
                                    sameSite: 'none',
                                });
                                this.setState({ showDriverId: !this.state.showDriverId });
                            }}
                        />
                    </div>
                </legend>

                {(() => {
                    if (drivers.error) return <ErrorMessage object={drivers} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={!this.state.showDriverId}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="email" dataSort={true}>
                                    {t('contact_data.username')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="gender"
                                    dataSort={true}
                                    dataFormat={genderName}
                                    csvFormat={genderName}>
                                    {t('contact_data.gender')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="firstName" dataSort={true}>
                                    {t('contact_data.first_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="lastName" dataSort={true}>
                                    {t('contact_data.last_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="phone" dataSort={true}>
                                    {t('schools.telephone')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    openConfirmDeleteDialog(driver) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: driver,
                onProceedDialog: driver =>
                    this.props.deleteById(driver.id, Entities.DRIVER, this.props.selectedCompany, 'deactivate'),
                bodyText: t('administration.confirm_delete_driver'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: driver.firstName,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('administration.error_driver'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, driver) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: DriverEditModal,
                            componentProps: {
                                id: driver.id,
                                isCreate: false,
                            },
                            title: driver.firstName,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.DRIVER.repository}/${driver.id}/deletable`)
                            .then(response => this.openConfirmDeleteDialog(driver))
                            .catch(error => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(DriverAdministration));
