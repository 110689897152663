import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';

import PasswordField from 'src/components/misc/password-field';
import { renderInput } from 'src/components/misc/redux-form-helpers';

class PasswordEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="password"
                            label={`${t('contact_data.password')} *`}
                            component={PasswordField}
                            t={t}
                        />
                        <Field
                            name="passwordConfirmation"
                            label={`${t('error_hint.confirm_password')} *`}
                            type="password"
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (values.password && values.password !== values.passwordConfirmation)
        errors.passwordConfirmation = t('error_hint.passwords_dont_fit');

    return errors;
};

export default reduxForm({
    form: 'passwordEditForm',
    validate,
})(PasswordEditForm);
