import moment from 'moment';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
    CalendarResourceType,
    CANCELLED_TASK_COLOR,
    CLEARED_TASK_COLOR,
    OfferStatus,
    TaskExchangeState,
    TaskType,
} from 'src/utils/constants';
import { getOwnCompany, getSelectedCompany } from 'src/selectors/bus-companies';
import { defaultState, Entities, entitiesByResult } from 'src/common/index';
import { NEW_TASKS_REDUCER_INDEX } from 'src/actions/tasks';

const getStateOfBookedTasks = state => state[Entities.TASK.reducer][0] || defaultState();

export const getStateOfTasksByCompany = state => state[Entities.TASK.reducer][state.selectedCompany] || defaultState();

export const getStateOfNewTasks = state => state[Entities.TASK.reducer][NEW_TASKS_REDUCER_INDEX] || defaultState();

export const getTaskEntities = state => state.entities[Entities.TASK.repository];

/**
 * get tasks booked by customer
 */
export const getBookedTasks = createSelector([getStateOfBookedTasks, getTaskEntities], (stateOfBookedTasks, tasks) => {
    return {
        isFetching: stateOfBookedTasks.isFetching,
        lastUpdated: stateOfBookedTasks.lastUpdated,
        error: stateOfBookedTasks.error,
        action: stateOfBookedTasks.action,
        items: entitiesByResult(stateOfBookedTasks.result, tasks),
    };
});

/**
 * Get new tasks since last login from redux store
 */
export const getTaskNewsSinceLastLogin = createSelector(
    [getTaskEntities, getStateOfNewTasks, getSelectedCompany],
    (taskEntities, stateOfNewTasks, selectedCompany) => {
        const newTasks = entitiesByResult(stateOfNewTasks.result, taskEntities);
        let offeredToOwnCompany = [],
            acceptedByOtherCompany = [],
            bookedTasks = [];

        newTasks.forEach(task => {
            // exchanged task
            if (task.exchangedTasks && task.exchangedTasks.length > 0) {
                // task has been accepted from another company
                if (
                    task.exchangedTasks[0].state === TaskExchangeState.ACCEPTED &&
                    task.exchangedTasks[0].ownCompanyDetails.id === selectedCompany
                )
                    acceptedByOtherCompany.push(task);
                else {
                    // new global exchanged task pending for you
                    if (
                        task.exchangedTasks[0].state === TaskExchangeState.PENDING &&
                        task.exchangedTasks[0].foreignCompanyDetails.id === -1 &&
                        task.exchangedTasks[0].ownCompanyDetails.id !== selectedCompany
                    )
                        offeredToOwnCompany.push(task);
                    // new exchanged task pending for your company
                    else if (
                        task.exchangedTasks.find(
                            exchangedTask =>
                                exchangedTask.foreignCompanyDetails.id === selectedCompany &&
                                exchangedTask.state === TaskExchangeState.PENDING,
                        )
                    )
                        offeredToOwnCompany.push(task);
                }

                // booked task
            } else if (task.customer !== null && task.bookedTask) bookedTasks.push(task);
        });

        return {
            isFetching: stateOfNewTasks.isFetching,
            lastUpdated: stateOfNewTasks.lastUpdated,
            error: stateOfNewTasks.error,
            action: stateOfNewTasks.action,
            count: offeredToOwnCompany.length + acceptedByOtherCompany.length + bookedTasks.length,
            items: {
                offeredToOwnCompany: offeredToOwnCompany,
                acceptedByOtherCompany: acceptedByOtherCompany,
                bookedTasks: bookedTasks,
            },
        };
    },
);

/**
 * Overall task filtering process. Distinguish between 8 task types and assign appropriate properties to them
 */
export const getAllFilteredTasks = createSelector(
    [getTaskEntities, getStateOfTasksByCompany, getSelectedCompany, getOwnCompany],
    (taskEntities, stateOfTasksByCompany, selectedCompany, busCompany) => {
        const allTasks = [];
        let didCompanySettingsUpdate = false;

        if (taskEntities && busCompany && busCompany.entity) {
            const taskTypeColors = busCompany.entity.taskTypeColors;
            let tasksOfInterest = [];

            // check if update is related to company settings
            if (
                stateOfTasksByCompany.action.type !== 'REQUEST' &&
                (busCompany.action.type === 'REQUEST' ||
                    (busCompany.lastUpdated &&
                        stateOfTasksByCompany.lastUpdated &&
                        busCompany.lastUpdated > stateOfTasksByCompany.lastUpdated))
            )
                didCompanySettingsUpdate = true;

            // check type of action to process
            if (
                didCompanySettingsUpdate &&
                (busCompany.action.type === 'UPDATE' || busCompany.action.type === 'FETCH')
            ) {
                tasksOfInterest = taskEntities;
                //console.log("company update: ", tasksOfInterest);
            } else if (
                !didCompanySettingsUpdate &&
                (stateOfTasksByCompany.action.type === 'UPDATE' || stateOfTasksByCompany.action.type === 'SAVE')
            ) {
                tasksOfInterest.push(taskEntities[stateOfTasksByCompany.action.payload]);
                //console.log("task update/save : ", tasksOfInterest);
            } else if (!didCompanySettingsUpdate && stateOfTasksByCompany.action.type === 'FETCH') {
                tasksOfInterest = _.filter(taskEntities, task =>
                    stateOfTasksByCompany.action.payload.includes(task.id),
                );
                //console.log("tasks fetch: ", tasksOfInterest);
            } else if (stateOfTasksByCompany.action.type === 'DELETE') {
                allTasks.push(stateOfTasksByCompany.action.payload);
                //console.log("delete tasks: ", allTasks);

                return {
                    isFetching: stateOfTasksByCompany.isFetching,
                    error: stateOfTasksByCompany.error,
                    action: stateOfTasksByCompany.action,
                    lastUpdated: stateOfTasksByCompany.lastUpdated,
                    items: allTasks,
                };
            }

            // process tasks
            _.forEach(tasksOfInterest, function (value, key) {
                // sort intermediates
                if (value.intermediates && value.intermediates.length > 0) {
                    value.intermediates.sort(function (i1, i2) {
                        const i1Time = moment(i1.time);
                        const i2Time = moment(i2.time);

                        if (i1Time.isBefore(i2Time)) return -1;

                        if (i1Time.isAfter(i2Time)) return 1;

                        return 0;
                    });
                }

                // task is not in task-exchange
                if (!value.exchangedTasks || (value.exchangedTasks && value.exchangedTasks.length === 0)) {
                    // no bus assigned
                    if (value.bus === null) {
                        // task has been booked from frontend by customer
                        if (value.customer) {
                            value.type = TaskType.BOOKED_BY_CUSTOMER_OPEN;
                            addCalendarEventProps(
                                value,
                                CalendarResourceType.TASK_INTERN,
                                taskTypeColors[TaskType.BOOKED_BY_CUSTOMER_OPEN],
                            );
                        } else {
                            if (value.offerStatus) {
                                switch (value.offerStatus) {
                                    case OfferStatus.OPEN:
                                        value.type = TaskType.OPEN_OFFER;
                                        addCalendarEventProps(
                                            value,
                                            CalendarResourceType.OFFER,
                                            taskTypeColors[TaskType.OPEN_OFFER],
                                        );
                                        break;
                                    case OfferStatus.DECLINED:
                                        value.type = TaskType.DECLINED_OFFER;
                                        break;
                                    case OfferStatus.DELETED:
                                        value.type = TaskType.DELETED_OFFER;
                                        break;
                                    case OfferStatus.ACCEPTED:
                                        value.type = TaskType.MY_OPEN_TASK;
                                        addCalendarEventProps(
                                            value,
                                            CalendarResourceType.TASK_INTERN,
                                            taskTypeColors[TaskType.MY_OPEN_TASK],
                                        );
                                        break;
                                    default:
                                        console.error('Unsupported OfferStatus: ', value.offerStatus);
                                }
                            } else {
                                value.type = TaskType.MY_OPEN_TASK;
                                addCalendarEventProps(
                                    value,
                                    CalendarResourceType.TASK_INTERN,
                                    taskTypeColors[TaskType.MY_OPEN_TASK],
                                );
                            }
                        }
                    } else {
                        if (value.offerStatus === OfferStatus.DELETED) value.type = TaskType.DELETED_OFFER;
                        else {
                            if (value.offerStatus) {
                                switch (value.offerStatus) {
                                    case OfferStatus.OPEN:
                                        value.type = TaskType.OPEN_OFFER;
                                        addCalendarEventProps(value, value.bus, taskTypeColors[TaskType.OPEN_OFFER]);
                                        break;
                                    case OfferStatus.DECLINED:
                                        value.type = TaskType.DECLINED_OFFER;
                                        addCalendarEventProps(
                                            value,
                                            value.bus,
                                            taskTypeColors[TaskType.DECLINED_OFFER],
                                        );
                                        break;
                                    case OfferStatus.DELETED:
                                        value.type = TaskType.DELETED_OFFER;
                                        break;
                                    case OfferStatus.ACCEPTED:
                                        value.type = TaskType.BOOKED_BY_CUSTOMER_ASSIGNED;
                                        addCalendarEventProps(
                                            value,
                                            value.bus,
                                            taskTypeColors[TaskType.BOOKED_BY_CUSTOMER_ASSIGNED],
                                        );
                                        break;
                                    default:
                                        console.error('Unsupported OfferStatus: ', value.offerStatus);
                                }
                            } else if (value.customer) {
                                value.type = TaskType.BOOKED_BY_CUSTOMER_ASSIGNED;
                                addCalendarEventProps(
                                    value,
                                    value.bus,
                                    taskTypeColors[TaskType.BOOKED_BY_CUSTOMER_ASSIGNED],
                                );
                            } else {
                                value.type = TaskType.MY_ASSIGNED_TASK;
                                addCalendarEventProps(value, value.bus, taskTypeColors[TaskType.MY_ASSIGNED_TASK]);
                            }
                        }
                    }

                    // task is in task-exchange
                } else {
                    // set state of task-exchange to task
                    value.state = value.exchangedTasks[0].state;

                    // task is pending
                    if (
                        value.exchangedTasks[0].state === TaskExchangeState.PENDING ||
                        value.exchangedTasks[0].state === TaskExchangeState.DECLINED
                    ) {
                        // task has been released by own company
                        if (value.exchangedTasks[0].ownCompanyDetails.id === selectedCompany) {
                            value.type = TaskType.MY_OPEN_EXCHANGED_TASK_PENDING;
                            addCalendarEventProps(
                                value,
                                CalendarResourceType.TASK_INTERN,
                                taskTypeColors[TaskType.MY_OPEN_EXCHANGED_TASK_PENDING],
                            );
                        }

                        // task has been released by foreign company
                        else {
                            value.type = TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING;
                            addCalendarEventProps(
                                value,
                                CalendarResourceType.TASK_EXTERN_FOREIGN,
                                taskTypeColors[TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING],
                            );
                        }

                        // task is accepted
                    } else if (value.exchangedTasks[0].state === TaskExchangeState.ACCEPTED) {
                        // task has been released by foreign company
                        if (value.exchangedTasks[0].foreignCompanyDetails.id === selectedCompany) {
                            // no bus assigned
                            if (value.bus === null) {
                                value.type = TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED;
                                addCalendarEventProps(
                                    value,
                                    CalendarResourceType.TASK_INTERN,
                                    taskTypeColors[TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED],
                                );
                            }

                            // bus assigned
                            else {
                                value.type = TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK;
                                addCalendarEventProps(
                                    value,
                                    value.bus,
                                    taskTypeColors[TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK],
                                );
                            }
                        }

                        // task has been released by own company
                        else {
                            // no bus assigned
                            if (value.bus === null) {
                                value.type = TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED;
                                addCalendarEventProps(
                                    value,
                                    CalendarResourceType.TASK_EXTERN_OWN,
                                    taskTypeColors[TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED],
                                );
                            }
                            // bus assigned
                            else {
                                value.type = TaskType.MY_ASSIGNED_EXCHANGED_TASK;
                                addCalendarEventProps(
                                    value,
                                    CalendarResourceType.TASK_EXTERN_OWN,
                                    taskTypeColors[TaskType.MY_ASSIGNED_EXCHANGED_TASK],
                                );
                            }
                        }
                    }
                }

                // if task is external disposed we override the color
                if (
                    value.externTaskId !== null &&
                    value.externTaskId !== undefined &&
                    value.calendarProps &&
                    value.calendarProps.color
                )
                    value.calendarProps.color = taskTypeColors[TaskType.EXTERNAL_TASK];

                allTasks.push(value);
            });
        }

        return {
            isFetching: stateOfTasksByCompany.isFetching,
            error: stateOfTasksByCompany.error,
            action: stateOfTasksByCompany.action,
            lastUpdated: stateOfTasksByCompany.lastUpdated,
            didCompanySettingsUpdate: didCompanySettingsUpdate,
            items: allTasks,
        };
    },
);

/**
 * Add calendar event properties to task in order to distinguish events
 * @param task current task
 * @param resourceId resourceId (left nav bar)
 * @param color color for event
 */
function addCalendarEventProps(task, resourceId, color) {
    if (!resourceId || !color) task.calendarProps = undefined;
    else
        task.calendarProps = {
            resourceId: resourceId,
            color: task.cancelled ? CANCELLED_TASK_COLOR : task.cleared ? CLEARED_TASK_COLOR : color,
        };
}
