import { connect } from 'react-redux';

import { getActiveDrivers } from 'src/selectors/drivers';
import { deleteById, fetchByCompanyId } from 'src/common/index';
import DriverAdministration from 'src/components/administration/driver-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        drivers: getActiveDrivers(state),
    };
};

const DriverAdministrationContainer = connect(mapStateToProps, {
    fetchByCompanyId,
    deleteById,
})(DriverAdministration);

export default DriverAdministrationContainer;
