import React, { Component } from 'react';

export default class PriceLogButton extends Component {
    render() {
        const { priceLog, t } = this.props;
        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                style={{ paddingLeft: '0' }}
                onClick={() => {
                    this.props.openModal({
                        component: () => <pre style={{ textAlign: 'right' }}>{priceLog}</pre>,
                        componentProps: {
                            dialogStyle: {
                                width: '110%',
                                maxWidth: 'none',
                            },
                        },
                        title: t('task_details.price_log'),
                        cancelButtonLabel: t('common_phrases.ok'),
                        singleButton: true,
                    });
                }}>
                <span className="glyphicon glyphicon-info-sign text-info" title={t('task_details.price_log')} />
                &nbsp;{t('task_details.price_log')}
            </button>
        );
    }
}
