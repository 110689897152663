import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton } from 'material-ui/RadioButton';
import SelectField from 'material-ui/SelectField';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import { RadioButtonGroup } from 'redux-form-material-ui';
import _ from 'lodash';
import { renderInput } from 'src/components/misc/redux-form-helpers';
import { TaskExchangeType } from 'src/utils/constants';
import { asyncRequest, Entities, isMoneyAmount, TOMCAT_URL, validatePrice } from 'src/common/index';

class SpecificSearchForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            value: undefined,
            isDisabled: true,
            selectedCompanies: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formType === 'specific' && this.props.formType !== 'specific') {
            this.props.array.removeAll('foreignCompanies');

            this.setState({
                selectedCompanies: [],
                isDisabled: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCompanies.length !== this.state.selectedCompanies.length) {
            if (this.state.selectedCompanies.length === 0) {
                // disable form submit button if no company is selected
                this.props.disableFormSubmit(true);
            } else if (prevState.selectedCompanies.length === 0) {
                // submit button is active because 1 or more companies are selected
                this.props.disableFormSubmit(false);
            }
        }
    }

    render() {
        const { formType, handleSubmit, busCompanies, selectedCompany, task, t } = this.props;

        if (formType === 'global') {
            return (
                <div className="details">
                    <div className="panel panel-default">
                        <div className="panel-heading">{t('search.global_release')}</div>
                        <div className="panel-body">
                            <form className="form-horizontal" id="specificSearch" role="form" onSubmit={handleSubmit}>
                                <div className="col-md-6">
                                    <br />
                                    <Field
                                        name="type"
                                        defaultSelected={TaskExchangeType.BOTH}
                                        component={RadioButtonGroup}>
                                        <RadioButton
                                            style={{ display: 'inline-block', width: '150px' }}
                                            value={TaskExchangeType.BUS}
                                            label={t('search.bus')}
                                        />
                                        <RadioButton
                                            style={{ display: 'inline-block', width: '200px' }}
                                            value={TaskExchangeType.BOTH}
                                            label={t('search.bus_driver')}
                                        />
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="price.amount"
                                        label={t('search.original_price', {
                                            originalPrice: task.price
                                                ? t('search.price', { price: task.price })
                                                : t('search.not_specified'),
                                        })}
                                        component={renderInput}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else {
            const companyMenuItems = [];
            busCompanies.forEach((value, index) => {
                if (value.id !== selectedCompany) {
                    let disabled;
                    if (this.state.selectedCompanies.find(company => company.id === value.id)) disabled = true;

                    companyMenuItems.push(
                        <MenuItem
                            key={index}
                            value={value.id}
                            disabled={disabled}
                            primaryText={this.companyNameCityPostCode(value)}
                        />,
                    );
                }
            });

            return (
                <div className="details">
                    <div className="panel panel-default">
                        <div className="panel-heading">{t('companies.release')}</div>
                        <div className="panel-body">
                            <form className="form-horizontal" id="specificSearch" role="form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <br />
                                        <Field
                                            name="type"
                                            defaultSelected={TaskExchangeType.BOTH}
                                            component={RadioButtonGroup}>
                                            <RadioButton
                                                style={{ display: 'inline-block', width: '100px' }}
                                                value={TaskExchangeType.BUS}
                                                label={t('search.bus')}
                                            />
                                            <RadioButton
                                                style={{ display: 'inline-block', width: '200px' }}
                                                value={TaskExchangeType.BOTH}
                                                label={t('search.bus_driver')}
                                            />
                                        </Field>
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="price.amount"
                                            label={t('search.original_price', {
                                                originalPrice: task.price
                                                    ? t('search.price', { price: task.price })
                                                    : t('search.not_specified'),
                                            })}
                                            component={renderInput}
                                        />
                                    </div>
                                </div>
                            </form>
                            <br />
                            <br />
                            <div className="row">
                                <br />
                                <div className="col-md-6">
                                    <div className="col-md-10">
                                        <SelectField
                                            value={this.state.value}
                                            onChange={(event, index, value) => {
                                                this.setState({
                                                    value: value,
                                                });
                                            }}
                                            floatingLabelText={t('common_phrases.company')}
                                            fullWidth={true}
                                            hintText={t('companies.select_company')}
                                            floatingLabelFixed={true}>
                                            {companyMenuItems}
                                        </SelectField>
                                    </div>
                                    <div className="col-md-1 voffset">
                                        <button
                                            type="button"
                                            className="btn btn-xs transparent"
                                            onClick={this.handleAddCompany}>
                                            {this.state.value ? (
                                                <span
                                                    className="fa fa-arrow-circle-right fa-2x text-success"
                                                    title={t('companies.add_company')}
                                                />
                                            ) : (
                                                <span
                                                    className="fa fa-arrow-circle-right fa-2x"
                                                    title={t('companies.error_first_select_company')}
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6 voffset">
                                    <label className="control-label">{t('companies.selected_companies')}</label>
                                    <ul className="list-group">
                                        {this.state.selectedCompanies.length === 0 ? (
                                            <span className="error">{t('companies.no_company_selected')}</span>
                                        ) : (
                                            <div>
                                                {this.state.selectedCompanies.map(company => {
                                                    return (
                                                        <li key={company.id} className="list-group-item">
                                                            <span>
                                                                {company.label}
                                                                &nbsp;&nbsp;
                                                            </span>

                                                            {(() => {
                                                                switch (company.status) {
                                                                    case 'FETCHING':
                                                                        return <CircularProgress />;
                                                                    case 'FOUND_BUS':
                                                                        return (
                                                                            <span
                                                                                title={t('search.bus_available')}
                                                                                className="glyphicon glyphicon-ok text-success"
                                                                            />
                                                                        );
                                                                    case 'NO_BUS':
                                                                        return (
                                                                            <span
                                                                                title={t('search.no_bus_available')}
                                                                                className="glyphicon glyphicon-remove text-danger"
                                                                            />
                                                                        );
                                                                    default:
                                                                        return <CircularProgress />;
                                                                }
                                                            })()}

                                                            <button
                                                                type="button"
                                                                className="btn btn-xs transparent pull-right"
                                                                onClick={() => this.handleDeleteCompany(company)}>
                                                                <span className="glyphicon glyphicon-trash" />
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    handleDeleteCompany(company) {
        // remove the deleted value from the foreignCompanies array
        this.props.array.remove(
            'foreignCompanies',
            this.state.selectedCompanies.findIndex(_company => _company.id === company.id),
        );

        if (this.props.initialValues.foreignCompanies.length === 0) {
            // remove the deleted item from the list and set submit button disabled if no company selected
            this.setState({
                selectedCompanies: _.without(this.state.selectedCompanies, company),
                isDisabled: true,
            });
        }
        // remove the deleted item from the list
        else
            this.setState({
                selectedCompanies: _.without(this.state.selectedCompanies, company),
            });
    }

    setSelectedCompanyStatus(status, selectedCompanyId) {
        const selectedCompanies = this.state.selectedCompanies;

        // find index
        const index = selectedCompanies.findIndex(company => company.id === selectedCompanyId);

        selectedCompanies[index].status = status;

        this.setState({
            selectedCompanies: selectedCompanies,
            isDisabled: false,
        });
    }

    handleAddCompany() {
        if (this.state.value) {
            const { task, busCompanies } = this.props;

            const currentSelectedCompany = busCompanies.find(busCompany => busCompany.id === this.state.value);

            if (currentSelectedCompany) {
                // check if foreign company has buses available for this date range
                asyncRequest(
                    `${TOMCAT_URL}api/${Entities.BUS.repository}/findAvailableBusInDateRange?from=${task.from.time}&to=${task.to.time}&companyId=${currentSelectedCompany.id}`,
                )
                    .then(() => this.setSelectedCompanyStatus('FOUND_BUS', currentSelectedCompany.id))
                    .catch(() => this.setSelectedCompanyStatus('NO_BUS', currentSelectedCompany.id));

                // add the selected company to the foreignCompanies array
                this.props.array.push('foreignCompanies', currentSelectedCompany.id);

                this.setState({
                    selectedCompanies: this.state.selectedCompanies.concat({
                        id: currentSelectedCompany.id,
                        label: this.companyNameCityPostCode(currentSelectedCompany),
                        status: 'FETCHING',
                    }),
                    value: undefined,
                });
            }
        }
    }

    companyNameCityPostCode(company) {
        if (company.contactData && company.contactData.postCode)
            return `${company.companyName}, ${company.contactData.postCode} ${company.contactData.city}`;
        else return company.companyName;
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        price: {
            amount: undefined,
        },
    };

    if (!isMoneyAmount(values.price)) errors.price.amount = t('search.fill_in_price');
    else {
        if (values.price.amount < 0) errors.price.amount = t('error_hint.error_negative');
        else if (validatePrice(values.price.amount))
            errors.price.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
    }

    return errors;
};

export default reduxForm({
    form: 'specificSearchForm',
    validate,
})(SpecificSearchForm);
