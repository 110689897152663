import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Price from './price';
import busPNG from 'static/bus.png';
import { ButtonColors, EventType, TaskExchangeState, TaskType } from 'src/utils/constants';
import { Entities, SanitizedInnerHTML, TOMCAT_URL } from 'src/common/index';
import TaskActionButtonHelper from 'src/utils/task-action-buttons';
import composeTaskActions from 'src/utils/task-action-wrapper';
import EventModal from 'src/components/events/event-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import DriverDetails from 'src/components/operatingtool/busdrivers/driver-details';
import { withTranslation } from 'react-i18next';
import PaymentLogsButton from 'src/components/operatingtool/payment-logs-button.js';
import PriceLogButton from 'src/components/operatingtool/price-log-button.js';
import LocalBusButton from 'src/components/operatingtool/local-bus-button';
import { DriverRooms } from './DriverRooms';

class Details extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            confirmedRemoveDrivers: false,
        };
    }

    componentDidMount() {
        this.taskActionButtonHelper = new TaskActionButtonHelper(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const eventDetails = nextProps.eventDetails;

        if (eventDetails && eventDetails.event) {
            $('.fc-event').removeClass('selected-event');
            $(`.fc-event#event${eventDetails.event.id}`).addClass('selected-event');

            $('.fc-list-item').removeClass('selected-list-item');
            $(`.fc-list-item#event${eventDetails.event.id}`).addClass('selected-list-item');
        }
    }

    render() {
        const { showActions, eventDetails, t } = this.props;

        if (eventDetails && eventDetails.event) {
            if (
                eventDetails.type !== TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED &&
                eventDetails.type !== TaskType.MY_ASSIGNED_EXCHANGED_TASK
            ) {
                let busAvatar, busName, plateNumber;
                let taskName,
                    bookedAt,
                    createdAt,
                    bookingIdentifier,
                    taskIdentifier,
                    passengers,
                    fromLocation,
                    toLocation,
                    start,
                    taskStartTime,
                    taskStartLocation,
                    taskEndLocation,
                    taskEndTime,
                    end,
                    percentage,
                    firstDriver,
                    companyProvidesDriverRooms,
                    driverRoomsNeeded,
                    secondDriver,
                    ratioTransferNumber;
                let customerName, customerAddress, customerPhone, customerGroupType;
                let customerComment, sentDriverDetailsToCustomer;
                let routes = [];

                let offerValidUntil, offerReminderSendUntil;

                if (eventDetails && eventDetails.event) {
                    taskName = eventDetails.event.name;
                    bookedAt = eventDetails.event.bookedAt && moment(eventDetails.event.bookedAt).format();
                    createdAt = moment(eventDetails.event.createdAt).format();
                    taskIdentifier = eventDetails.event.identifier;
                    bookingIdentifier = eventDetails.event.bookingIdentifier;
                    start = moment(eventDetails.event.from.time).format();
                    end = moment(eventDetails.event.to.time).format();
                    companyProvidesDriverRooms = eventDetails.event.companyProvidesDriverRooms;
                    driverRoomsNeeded = eventDetails.event.driverRoomsNeeded;
                    ratioTransferNumber = eventDetails.event.ratioTransferNumber;

                    if (eventDetails.event.taskFrom) {
                        taskStartTime = moment(eventDetails.event.taskFrom.time);
                        taskStartLocation = eventDetails.event.taskFrom.location.address;
                    }

                    if (eventDetails.event.taskTo) {
                        taskEndTime = moment(eventDetails.event.taskTo.time);
                        taskEndLocation = eventDetails.event.taskTo.location.address;
                    }

                    fromLocation = eventDetails.event.from.location || '';
                    toLocation = eventDetails.event.to.location || '';

                    if (eventDetails.event.firstDriver) {
                        firstDriver = eventDetails.event.firstDriver;
                    }

                    if (eventDetails.event.secondDriver) {
                        secondDriver = eventDetails.event.secondDriver;
                    }

                    if (eventDetails.event.customer) customerGroupType = eventDetails.event.customer.customerGroup.type;

                    if (eventDetails.event.customerContactData) {
                        customerName = eventDetails.event.customerContactData.fullName;
                        customerAddress = eventDetails.event.customerContactData.address;
                        customerPhone = eventDetails.event.customerContactData.phone;
                    }

                    if (eventDetails.event.customerComment) {
                        customerComment = eventDetails.event.customerComment;
                    }

                    if (eventDetails.type !== EventType.BLOCKAGE && eventDetails.type !== EventType.RELEASE) {
                        passengers = eventDetails.event.passengers || '';
                        routes = eventDetails.event.intermediates;
                    }

                    if (eventDetails.type === EventType.RELEASE) {
                        percentage = eventDetails.event.percentage + '%' || '';
                    }

                    if (eventDetails.bus) {
                        busAvatar = eventDetails.bus.avatar || '';
                        busName = eventDetails.bus.name || '';
                        plateNumber = eventDetails.bus.plateNumber || '';
                    }

                    if (eventDetails.event.offerDetails) {
                        if (eventDetails.event.offerDetails.validUntil)
                            offerValidUntil = moment(eventDetails.event.offerDetails.validUntil).format('DD.MM.YYYY');

                        if (eventDetails.event.offerDetails.timeOfReminderEmail)
                            offerReminderSendUntil = moment(eventDetails.event.offerDetails.timeOfReminderEmail).format(
                                'DD.MM.YYYY',
                            );
                    }

                    if (eventDetails.event.sentDriverDetailsToCustomer)
                        sentDriverDetailsToCustomer = eventDetails.event.sentDriverDetailsToCustomer;
                }

                const avatarURL = busAvatar ? `${TOMCAT_URL}public/documents/${busAvatar.filename}` : `${busPNG}`;

                const notesButton =
                    this.taskActionButtonHelper &&
                    this.taskActionButtonHelper.getTaskNotesButton({
                        task: eventDetails.event,
                        withText: true,
                        t,
                    });

                return (
                    <div className="details">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                {eventDetails && eventDetails.event && eventDetails.type && (
                                    <table className="table table-details">
                                        <thead>
                                            {eventDetails.type === EventType.BLOCKAGE ||
                                            eventDetails.type === EventType.RELEASE ? (
                                                <tr>
                                                    <th>
                                                        {eventDetails.type === EventType.BLOCKAGE
                                                            ? t('events.blockage')
                                                            : t('events.release')}
                                                    </th>
                                                    <th>{t('bus.car')}</th>
                                                    <th>{t('booked_tasks.from')}</th>
                                                    <th>{t('booked_tasks.to')}</th>
                                                    <th>% {t('booked_tasks.price')}</th>
                                                    <th>{t('common_phrases.actions')}</th>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <th width="11%">
                                                        {[TaskType.OPEN_OFFER, TaskType.DECLINED_OFFER].includes(
                                                            eventDetails.type,
                                                        )
                                                            ? t('events.offer')
                                                            : t('events.task')}
                                                    </th>
                                                    <th width="12%" />
                                                    <th width="11%">{t('bus.car')}</th>
                                                    <th>{t('administration.driver')}</th>
                                                    <th width="8%">{t('booked_tasks.price')}</th>
                                                    <th>{t('booked_tasks.customer')}</th>
                                                    <th width="9%">{t('booked_tasks.journey')}</th>
                                                    <th width="18%">{t('task_details.routes')}</th>
                                                    {(eventDetails.type === TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK ||
                                                        eventDetails.type ===
                                                            TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                                        eventDetails.type ===
                                                            TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING) && (
                                                        <th>{t('events.released_from')}</th>
                                                    )}

                                                    {(eventDetails.type === TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                                        eventDetails.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING ||
                                                        eventDetails.type === TaskType.MY_ASSIGNED_EXCHANGED_TASK) && (
                                                        <th>{t('events.released_to')}</th>
                                                    )}
                                                    {showActions && <th width="12%">{t('common_phrases.actions')}</th>}
                                                </tr>
                                            )}
                                        </thead>
                                        <tbody>
                                            {eventDetails.type === EventType.BLOCKAGE ||
                                            eventDetails.type === EventType.RELEASE ? (
                                                <tr>
                                                    <td>
                                                        <p>
                                                            {eventDetails.event.csBusDateId && (
                                                                <span>
                                                                    <span
                                                                        className="glyphicon glyphicon-link text-info"
                                                                        title={t('task_details.external_id', {
                                                                            id: eventDetails.event.csBusDateId,
                                                                        })}
                                                                    />
                                                                    &nbsp;
                                                                </span>
                                                            )}
                                                            {taskName}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <img width="25" height="14" src={avatarURL} alt={busName} />{' '}
                                                            {busName}
                                                        </p>
                                                        <p>{plateNumber}</p>
                                                    </td>
                                                    <td>
                                                        <p>{start}</p>
                                                        <p>{fromLocation.address}</p>
                                                    </td>
                                                    <td>
                                                        <p>{end}</p>
                                                        <p>{toLocation.address}</p>
                                                    </td>
                                                    <td>
                                                        <p>{percentage}</p>
                                                    </td>
                                                    <td>
                                                        {notesButton != null && <p>{notesButton}</p>}
                                                        <p>
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs transparent"
                                                                onClick={() =>
                                                                    this.openEventModal({
                                                                        title: eventDetails.event.name,
                                                                        id: eventDetails.event.id,
                                                                        eventType: eventDetails.type,
                                                                    })
                                                                }>
                                                                <span className="glyphicon glyphicon-pencil" />
                                                                &nbsp; {t('user_functionality.edit')}
                                                            </button>
                                                        </p>
                                                        <p>
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs transparent"
                                                                onClick={() => {
                                                                    let eventTypeName = t('events.this_release');
                                                                    let callback = this.onProceedDeleteRelease;
                                                                    if (eventDetails.type === EventType.BLOCKAGE) {
                                                                        eventTypeName = t('events.this_blockage');
                                                                        callback = this.onProceedDeleteBlockage;
                                                                    }

                                                                    this.openConfirmDeleteDialog(
                                                                        callback,
                                                                        eventDetails.event,
                                                                        eventTypeName,
                                                                    );
                                                                }}>
                                                                <span className="glyphicon glyphicon-trash" />
                                                                &nbsp; {t('user_functionality.delete')}
                                                            </button>
                                                        </p>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    {this.renderTaskIdentifiers(
                                                        t,
                                                        eventDetails,
                                                        taskName,
                                                        taskIdentifier,
                                                        bookingIdentifier,
                                                        bookedAt,
                                                        createdAt,
                                                        offerValidUntil,
                                                        offerReminderSendUntil,
                                                        driverRoomsNeeded,
                                                        companyProvidesDriverRooms,
                                                        ratioTransferNumber,
                                                    )}

                                                    <td>
                                                        <p>
                                                            <img width="25" height="14" src={avatarURL} alt={busName} />{' '}
                                                            {busName}
                                                        </p>
                                                        <p>{plateNumber}</p>
                                                    </td>
                                                    <td>
                                                        <DriverDetails
                                                            t={t}
                                                            driver={firstDriver}
                                                            taskId={eventDetails.event.id}
                                                            firstDriver={true}
                                                            taskType={eventDetails.type}
                                                            patch={this.props.patch}
                                                            selectedCompany={this.props.busCompany.entity.id}
                                                            openModal={this.props.openModal}
                                                        />
                                                        <DriverDetails
                                                            t={t}
                                                            driver={secondDriver}
                                                            taskId={eventDetails.event.id}
                                                            firstDriver={false}
                                                            taskType={eventDetails.type}
                                                            patch={this.props.patch}
                                                            selectedCompany={this.props.busCompany.entity.id}
                                                            openModal={this.props.openModal}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Price eventDetails={eventDetails} />
                                                        {eventDetails.event.priceLog !== null && (
                                                            <PriceLogButton
                                                                priceLog={eventDetails.event.priceLog}
                                                                t={t}
                                                                openModal={this.props.openModal}
                                                            />
                                                        )}
                                                        {eventDetails.event.priceLogPTV !== null && (
                                                            <PriceLogButton
                                                                priceLog={eventDetails.event.priceLogPTV}
                                                                t={t}
                                                                openModal={this.props.openModal}
                                                            />
                                                        )}
                                                        {eventDetails.event.paymentLogs !== null &&
                                                            eventDetails.event.paymentLogs.length > 0 && (
                                                                <PaymentLogsButton
                                                                    paymentLogs={eventDetails.event.paymentLogs}
                                                                    t={t}
                                                                    openModal={this.props.openModal}
                                                                />
                                                            )}
                                                    </td>
                                                    <td>
                                                        <p>{customerName}</p>
                                                        <p>{customerAddress}</p>
                                                        <p>{customerPhone}</p>
                                                        {sentDriverDetailsToCustomer && (
                                                            <p>
                                                                <span
                                                                    className="glyphicon glyphicon-ok text-success"
                                                                    title={t('task_details.sent_at', {
                                                                        time: moment(
                                                                            sentDriverDetailsToCustomer,
                                                                        ).format('DD.MM.YYYY HH:mm'),
                                                                    })}
                                                                />
                                                                &nbsp;{t('task_details.sent_details')}
                                                            </p>
                                                        )}

                                                        {customerComment && (
                                                            <p>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs transparent"
                                                                    onClick={() => {
                                                                        this.props.openModal({
                                                                            component: ConfirmDialog,
                                                                            componentProps: {
                                                                                bodyText: (
                                                                                    <SanitizedInnerHTML
                                                                                        html={
                                                                                            customerComment
                                                                                                ? customerComment.replace(
                                                                                                      /\n/g,
                                                                                                      '<br/>',
                                                                                                  )
                                                                                                : ''
                                                                                        }
                                                                                        sanitizeHtmlOptions={{
                                                                                            allowedTags: ['br'],
                                                                                        }}
                                                                                    />
                                                                                ),
                                                                                proceedText: t('common_phrases.ok'),
                                                                                proceedButtonColor: ButtonColors.NOTIFY,
                                                                                isNotification: true,
                                                                            },
                                                                            title: t('task_details.clients_notes'),
                                                                            noButtons: true,
                                                                        });
                                                                    }}>
                                                                    <span
                                                                        className="glyphicon glyphicon-info-sign text-info"
                                                                        title={t('task_details.notes')}
                                                                    />
                                                                    &nbsp;{t('task_details.notes')}
                                                                </button>
                                                            </p>
                                                        )}
                                                        {customerGroupType && customerGroupType === 'RESELLER' && (
                                                            <p>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs transparent"
                                                                    onClick={() => {
                                                                        this.props.openModal({
                                                                            component: ConfirmDialog,
                                                                            componentProps: {
                                                                                bodyText: (
                                                                                    <div className="col-md-12">
                                                                                        <div className="row">
                                                                                            <div className="col-md-2">
                                                                                                <strong>Name:</strong>
                                                                                            </div>
                                                                                            <div className="col-md-10">
                                                                                                {
                                                                                                    eventDetails.event
                                                                                                        .customer
                                                                                                        .contactData
                                                                                                        .fullName
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <strong>
                                                                                                    {t(
                                                                                                        'contact_data.address',
                                                                                                    )}
                                                                                                    :
                                                                                                </strong>
                                                                                            </div>
                                                                                            <div className="col-md-10">
                                                                                                {
                                                                                                    eventDetails.event
                                                                                                        .customer
                                                                                                        .contactData
                                                                                                        .address
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <strong>
                                                                                                    {t(
                                                                                                        'schools.telephone',
                                                                                                    )}
                                                                                                    :
                                                                                                </strong>
                                                                                            </div>
                                                                                            <div className="col-md-10">
                                                                                                {
                                                                                                    eventDetails.event
                                                                                                        .customer
                                                                                                        .contactData
                                                                                                        .phone
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <strong>
                                                                                                    {t(
                                                                                                        'contact_data.email',
                                                                                                    )}
                                                                                                    :
                                                                                                </strong>
                                                                                            </div>
                                                                                            <div className="col-md-10">
                                                                                                {
                                                                                                    eventDetails.event
                                                                                                        .customer
                                                                                                        .contactData
                                                                                                        .email
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ),
                                                                                proceedText: t('common_phrases.ok'),
                                                                                proceedButtonColor: ButtonColors.NOTIFY,
                                                                                isNotification: true,
                                                                            },
                                                                            title: t('task_details.reseller'),
                                                                            noButtons: true,
                                                                        });
                                                                    }}>
                                                                    <span
                                                                        className="glyphicon glyphicon-info-sign text-info"
                                                                        title={t('task_details.reseller')}
                                                                    />
                                                                    &nbsp;{t('task_details.reseller')}
                                                                </button>
                                                            </p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <strong>{t('task_details.pax')}</strong>
                                                        <p>{passengers}</p>
                                                        <strong>{t('booked_tasks.from')}</strong>
                                                        <p style={{ margin: 0, display: 'block' }}>{start}</p>
                                                        <p>{fromLocation.address}</p>
                                                        <strong>{t('booked_tasks.to')}</strong>
                                                        <p style={{ margin: 0, display: 'block' }}>{end}</p>
                                                        <p>{toLocation.address}</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {taskStartTime.format()} {taskStartLocation}
                                                        </p>
                                                        {routes &&
                                                            routes.length > 0 &&
                                                            routes.map((value, key) => (
                                                                <p key={key}>
                                                                    {moment(value.time).format()}{' '}
                                                                    {value.location.address}{' '}
                                                                    {value.localBus && (
                                                                        <LocalBusButton
                                                                            t={t}
                                                                            openModal={this.props.openModal}
                                                                            stop={value}
                                                                        />
                                                                    )}
                                                                </p>
                                                            ))}
                                                        <p>
                                                            {taskEndTime.format()} {taskEndLocation}
                                                        </p>
                                                    </td>
                                                    {(eventDetails.type === TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK ||
                                                        eventDetails.type ===
                                                            TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                                        eventDetails.type ===
                                                            TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING) && (
                                                        <td>
                                                            <p>
                                                                {
                                                                    eventDetails.event.exchangedTasks[0]
                                                                        .ownCompanyDetails.companyName
                                                                }
                                                            </p>
                                                            <p>
                                                                {moment(
                                                                    eventDetails.event.exchangedTasks[0].created,
                                                                ).format()}
                                                            </p>
                                                        </td>
                                                    )}

                                                    {(eventDetails.type === TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                                        eventDetails.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING ||
                                                        eventDetails.type === TaskType.MY_ASSIGNED_EXCHANGED_TASK) && (
                                                        <td>
                                                            {eventDetails.event.exchangedTasks[0] === -1 ? (
                                                                <p>{t('search.global_release')}</p>
                                                            ) : (
                                                                eventDetails.event.exchangedTasks.map(taskExchange => (
                                                                    <p key={taskExchange.foreignCompanyDetails.id}>
                                                                        {taskExchange.state ===
                                                                            TaskExchangeState.DECLINED && (
                                                                            <span
                                                                                title={t(
                                                                                    'task_details.company_has_declined',
                                                                                )}
                                                                                className="fa fa-ban text-danger"
                                                                            />
                                                                        )}
                                                                        {taskExchange.state ===
                                                                            TaskExchangeState.PENDING && (
                                                                            <span
                                                                                title={t('task_details.answer_pending')}
                                                                                className="glyphicon glyphicon-refresh text-info"
                                                                            />
                                                                        )}
                                                                        <span>
                                                                            &nbsp;
                                                                            {
                                                                                taskExchange.foreignCompanyDetails
                                                                                    .companyName
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                ))
                                                            )}
                                                        </td>
                                                    )}
                                                    {showActions && (
                                                        <td>
                                                            {this.taskActionButtonHelper &&
                                                                this.taskActionButtonHelper
                                                                    .getTaskActionButtonsByTask({
                                                                        t,
                                                                        task: eventDetails.event,
                                                                        withText: true,
                                                                        reviews: this.props.reviews,
                                                                        externTasks:
                                                                            this.props.busCompany.entity.externTasks,
                                                                        csBusCompany:
                                                                            this.props.busCompany.entity.ftpCompanyId !=
                                                                                null &&
                                                                            this.props.busCompany.entity.ftpCompanyId.indexOf(
                                                                                'CSBUS',
                                                                            ) === 0,
                                                                    })
                                                                    .map((actionButton, index) => (
                                                                        <p key={index}>{actionButton}</p>
                                                                    ))}
                                                        </td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="details">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <table className="table table-details">
                                    <thead>
                                        <tr>
                                            <th>{t('events.task')}</th>
                                            <th>{t('events.released_to')}</th>
                                            <th>{t('events.released_at')}</th>
                                            <th>{t('events.accepted_at')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>{eventDetails.event.name}</p>
                                            </td>
                                            <td>
                                                <p>
                                                    <span
                                                        title={t('task_details.accepted_task')}
                                                        className="glyphicon glyphicon-ok text-success"
                                                    />
                                                    <span>
                                                        &nbsp;
                                                        {
                                                            eventDetails.event.exchangedTasks[0].foreignCompanyDetails
                                                                .companyName
                                                        }
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <p>{moment(eventDetails.event.exchangedTasks[0].createdAt).format()}</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {moment(eventDetails.event.exchangedTasks[0].modifiedAt).format()}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            }
        } else return <div />;
    }

    onProceedDeleteBlockage(blockage) {
        this.props.deleteById(blockage.id, Entities.BLOCKAGE, this.props.busCompany.entity.id);
    }

    onProceedDeleteRelease(release) {
        this.props.deleteById(release.id, Entities.RELEASE, this.props.busCompany.entity.id);
    }

    openConfirmDeleteDialog(callback, event, eventTypeName) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: event,
                onProceedDialog: callback,
                bodyText: t('user_functionality.confirm_delete', { entity: eventTypeName }),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: event.name,
            noButtons: true,
        });
    }

    openEventModal(props) {
        this.props.openModal({
            component: EventModal,
            componentProps: {
                id: props.id,
                eventType: props.eventType,
                isCreate: false,
                busAvailableSeats: props.busAvailableSeats,
            },
            title: props.title,
        });
    }

    renderEventType(eventDetails) {
        const { t } = this.props;

        if (
            eventDetails.event &&
            eventDetails.event.calendarProps &&
            eventDetails.type !== EventType.BLOCKAGE &&
            eventDetails.type !== EventType.RELEASE
        )
            return (
                <>
                    <strong>{t('task_details.type')}</strong>
                    <p>
                        {getEventTypeDescription(eventDetails.event, t)}
                        {eventDetails.event.cancelled && <span>{` (${t('booked_tasks.cancelled')})`}</span>}
                        {eventDetails.event.externTaskId && (
                            <div className="pull-right">{t('task_details.disposed')}</div>
                        )}
                    </p>
                </>
            );
    }

    renderTaskIdentifiers(
        t,
        eventDetails,
        taskName,
        taskIdentifier,
        bookingIdentifier,
        bookedAt,
        createdAt,
        offerValidUntil,
        offerReminderSendUntil,
        driverRoomsNeeded,
        companyProvidesDriverRooms,
        ratioTransferNumber,
    ) {
        if (
            [TaskType.BOOKED_BY_CUSTOMER_ASSIGNED, TaskType.BOOKED_BY_CUSTOMER_OPEN].includes(eventDetails.event.type)
        ) {
            return (
                <>
                    <td style={{ overflowWrap: 'break-word' }}>
                        <strong>{t('task_details.booking_number')}</strong>
                        <p>{bookingIdentifier}</p>
                        <strong>{t('task_details.task_number')}</strong>
                        <p>{taskIdentifier}</p>
                        {ratioTransferNumber && (
                            <>
                                <strong>{t('tasks.ratio_transfer_number')}</strong>
                                <p>{ratioTransferNumber} </p>
                            </>
                        )}
                        <strong>{t('task_details.booking_name')}</strong>
                        <p>{taskName}</p>
                    </td>
                    <td>
                        <strong>{t('task_details.booked_at')}</strong>
                        <p>{bookedAt}</p>
                        {this.renderEventType(eventDetails)}
                        <DriverRooms
                            driverRoomsNeeded={driverRoomsNeeded}
                            companyProvidesDriverRooms={companyProvidesDriverRooms}
                            openModal={this.props.openModal}
                            t={this.props.t}
                        />
                    </td>
                </>
            );
        }

        if ([TaskType.OPEN_OFFER, TaskType.DECLINED_OFFER].includes(eventDetails.event.type)) {
            return (
                <>
                    <td style={{ overflowWrap: 'break-word' }}>
                        <strong>{t('task_details.offer_number')}</strong>
                        <p>{bookingIdentifier}</p>
                        <strong>{t('task_details.task_number')}</strong>
                        <p>{taskIdentifier}</p>
                        <strong>{t('task_details.booking_name')}</strong>
                        <p>{taskName}</p>
                        <strong>{t('task_details.generated_at')}</strong>
                        <p>{createdAt}</p>
                    </td>
                    <td>
                        {offerValidUntil && (
                            <React.Fragment>
                                <strong>{t('task_details.valid_until')}</strong>
                                <p>{offerValidUntil}</p>
                            </React.Fragment>
                        )}
                        {offerReminderSendUntil && (
                            <React.Fragment>
                                <strong>{t('task_details.time_of_reminder_email')}</strong>
                                <p>{offerReminderSendUntil}</p>
                            </React.Fragment>
                        )}
                        {this.renderEventType(eventDetails)}
                        <DriverRooms
                            driverRoomsNeeded={driverRoomsNeeded}
                            companyProvidesDriverRooms={companyProvidesDriverRooms}
                            openModal={this.props.openModal}
                            t={this.props.t}
                        />
                    </td>
                </>
            );
        }

        return (
            <>
                <td style={{ overflowWrap: 'break-word' }}>
                    <strong>{t('task_details.booking_name')}</strong>
                    <p>{taskName}</p>
                    <strong>{t('task_details.task_number')}</strong>
                    <p>{taskIdentifier}</p>
                    <strong>{t('task_details.generated_at')}</strong>
                    <p>{createdAt}</p>
                </td>
                <td>
                    {this.renderEventType(eventDetails)}
                    <DriverRooms
                        driverRoomsNeeded={driverRoomsNeeded}
                        companyProvidesDriverRooms={companyProvidesDriverRooms}
                        openModal={this.props.openModal}
                        t={this.props.t}
                    />
                </td>
            </>
        );
    }
}

export default withTranslation()(composeTaskActions(Details));

const getEventTypeDescription = (event, t) => {
    switch (event.type) {
        case TaskType.MY_OPEN_TASK:
            if (event.offerStatus != null) return t('task_details.offer_accepted');
            return t('task_details.offer_no_driver');
        case TaskType.MY_ASSIGNED_TASK:
            return t('task_details.generated_offer_driver');
        case TaskType.MY_OPEN_EXCHANGED_TASK_PENDING:
            return t('task_details.released_offer_not_accepted');
        case TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED:
        case TaskType.MY_ASSIGNED_EXCHANGED_TASK:
            return t('task_details.released_offer_accepted');
        case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING:
            return t('task_details.companies_not_accepted');
        case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED:
            return t('task_details.companies_accepted');
        case TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK:
            return t('task_details.companies_accepted_and_assigned');
        case TaskType.BOOKED_BY_CUSTOMER_OPEN:
            if (event.offerStatus != null) return t('task_details.generated_offer_booked_no_car');
            return t('task_details.offer_booked_no_car');
        case TaskType.BOOKED_BY_CUSTOMER_ASSIGNED:
            if (event.offerStatus != null) return t('task_details.generated_offer_booked_car');
            return t('task_details.offer_booked_car');
        case TaskType.OPEN_OFFER:
            return t('task_details.open_offer');
        case TaskType.DECLINED_OFFER:
            return t('task_details.declined_offer');
        case TaskType.DELETED_OFFER:
            return t('task_details.deleted_offer');
        default:
            return t('task_details.without_category');
    }
};
