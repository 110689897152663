import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';

import {
    formatHours,
    formatMinutes,
    pluralizeDays,
    renderCheckbox,
    renderInput,
    renderSlider,
} from 'src/components/misc/redux-form-helpers';
import { Tab, Tabs } from 'material-ui/Tabs';

import SwipeableViews from 'react-swipeable-views';
import CompanyPaymentSettings, {
    validateCashback,
} from 'src/components/administration/company-settings/CompanyPaymentSettings';
import { connect } from 'react-redux';

class CompanySettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yieldManagementEnabled: this.props.yieldManagementEnabled,
        };
    }

    render() {
        const {
            handleSubmit,
            selectedSliderIndex,
            freeCancellationDays,
            freeCancellation,
            enableOnlinePayment,
            daysBeforeStartMandatoryOnlinePayment,
            company,
            yieldManagementEnabled,
            t,
        } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <input type="hidden" name="id" />

                <Tabs onChange={this.props.setSliderIndex} value={selectedSliderIndex}>
                    <Tab label={t('companies.settings_tabs.general')} value={0} />
                    <Tab label={t('companies.settings_tabs.offers')} value={1} />
                    <Tab label={t('companies.settings_tabs.transfer_driver_details')} value={2} />
                    <Tab label={t('companies.settings_tabs.online_payment')} value={3} />
                </Tabs>

                <SwipeableViews
                    ref={ref => (this.swipeableViewsRef = ref)}
                    animateHeight
                    onChangeIndex={this.props.setSliderIndex}
                    index={selectedSliderIndex}>
                    <div className="company-settings-view">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="freeCancellation"
                                            label={t('companies.free_cancellation')}
                                            component={renderCheckbox}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        {t('companies.free_cancellation_condition')}
                                        <br />
                                        <br />
                                    </div>
                                    {freeCancellation && (
                                        <>
                                            <div className="col-md-12">
                                                <Field
                                                    name="freeCancellationDays"
                                                    label="companies.free_cancellation_days_before_departure"
                                                    component={renderSlider}
                                                    labelValueFormat={pluralizeDays}
                                                    t={t}
                                                    min={0}
                                                    max={90}
                                                    step={1}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                {/*TODO: BUF-1135: translate*/}
                                                <Field
                                                    name="cashbackNoRefund"
                                                    label="Skonto wenn der Kunde auf die kostenlose Stornierung verzichtet (%)"
                                                    component={renderInput}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6 col-md-offset-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="defaultTaskPostProcessingTime"
                                            label="companies.default_task_post_processing_time"
                                            component={renderSlider}
                                            labelValueFormat={formatMinutes}
                                            t={t}
                                            min={0}
                                            max={720}
                                            step={1}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <Field
                                            name="bookingLeadTime"
                                            label="companies.booking_lead_time"
                                            component={renderSlider}
                                            labelValueFormat={formatHours}
                                            t={t}
                                            min={48}
                                            max={480}
                                            step={1}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="yieldManagementEnabled"
                                            value={'yieldManagementEnabled'}
                                            component={renderCheckbox}
                                            checked={true}
                                            label={t('yield_management.activate_global_yield_management')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="company-settings-view">
                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="offerDefaultDaysValidBeforeStart"
                                    label="companies.offer_default_days_valid_before_start"
                                    component={renderSlider}
                                    labelValueFormat={pluralizeDays}
                                    t={t}
                                    min={2}
                                    max={90}
                                    step={1}
                                />
                            </div>

                            <div className="col-md-6">
                                <Field
                                    name="offerDefaultDaysRemindBeforeOfferEnds"
                                    label="companies.offer_default_days_remind_before_offer_ends"
                                    component={renderSlider}
                                    labelValueFormat={pluralizeDays}
                                    t={t}
                                    min={1}
                                    max={30}
                                    step={1}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="company-settings-view">
                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="sendDriverDetailsXDaysBeforeJourney"
                                    label="companies.send_driver_details_x_days_before_journey"
                                    component={renderSlider}
                                    labelValueFormat={pluralizeDays}
                                    t={t}
                                    min={0}
                                    max={30}
                                    step={1}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="company-settings-view">
                        {company.onlinePaymentModuleEnabled ? (
                            <CompanyPaymentSettings
                                t={t}
                                daysBeforeStartMandatoryOnlinePayment={daysBeforeStartMandatoryOnlinePayment}
                                isOnlinePaymentEnabled={enableOnlinePayment}
                                freeCancellationDays={freeCancellationDays}
                                freeCancellation={freeCancellation}
                                yieldManagementEnabled={company.yieldManagementEnabled}
                                fieldPrefix="onlinePaymentSettings"
                                swipeableViewRef={this.swipeableViewsRef}
                            />
                        ) : (
                            <div>{t('search_requests.module_deactivated')}</div>
                        )}
                    </div>
                </SwipeableViews>

                <div className="row voffset40">
                    <div className="col-md-12">
                        <RaisedButton
                            primary={true}
                            type="submit"
                            label={t('user_functionality.save')}
                            className="pull-right"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;

    const errors = {
        onlinePaymentSettings: {
            cashbackOnlinePayment: undefined,
        },
        cashbackNoRefund: undefined,
    };

    errors.onlinePaymentSettings.cashbackOnlinePayment = validateCashback(
        t,
        values.onlinePaymentSettings.cashbackOnlinePayment,
    );
    errors.cashbackNoRefund = validateCashback(t, values.cashbackNoRefund);

    if (!errors.onlinePaymentSettings.cashbackOnlinePayment && !errors.cashbackNoRefund) {
        const cashbackOnlinePayment = Number(values.onlinePaymentSettings.cashbackOnlinePayment);
        const cashbackNoRefund = Number(values.cashbackNoRefund);

        if (cashbackOnlinePayment + cashbackNoRefund > 100) {
            errors.cashbackNoRefund = errors.onlinePaymentSettings.cashbackOnlinePayment =
                t('error_hint.max_cashback_sum');
        }
    }

    if (
        values.onlinePaymentSettings.denyOnlinePaymentDuringFreeCancellation &&
        values.onlinePaymentSettings.daysBeforeStartMandatoryOnlinePayment > values.freeCancellationDays
    ) {
        errors.onlinePaymentSettings.daysBeforeStartMandatoryOnlinePayment = t(
            'error_hint.mandatory_online_payment_free_cancellation_condition',
        );
    }

    return errors;
};

CompanySettingsForm = reduxForm({
    form: 'companySettingsForm',
    validate,
})(CompanySettingsForm);

const selector = formValueSelector('companySettingsForm');

const mapStateToProps = (state, ownProps) => {
    const freeCancellation = selector(state, 'freeCancellation');
    const freeCancellationDays = selector(state, 'freeCancellationDays');
    const enableOnlinePayment = selector(state, 'onlinePaymentSettings.enableOnlinePayment');

    const daysBeforeStartMandatoryOnlinePayment = selector(
        state,
        'onlinePaymentSettings.daysBeforeStartMandatoryOnlinePayment',
    );

    const yieldManagementEnabled = selector(state, 'yieldManagementEnabled');
    // console.log(yieldManagementEnabled, 'myState');
    return {
        freeCancellation,
        freeCancellationDays,
        enableOnlinePayment,
        daysBeforeStartMandatoryOnlinePayment,
        yieldManagementEnabled,
    };
};

CompanySettingsForm = connect(mapStateToProps, null, null, { withRef: true })(CompanySettingsForm);

export default CompanySettingsForm;
