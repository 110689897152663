import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';

import driverPNG from 'static/driver.png';
import trashPNG from 'static/font-awesome_4-6-3_trash_64_8_000000_none.png';
import { ButtonColors, ItemTypes, TaskType } from 'src/utils/constants';
import { Entities, TOMCAT_URL } from 'src/common/index';
import ConfirmDialog from 'src/components/misc/confirm-dialog';

const style = {
    height: '25px',
    width: '25px',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    color: 'white',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
};

const driverTarget = {
    drop(props) {
        return {
            taskId: props.taskId,
            firstDriver: props.firstDriver,
        };
    },
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    };
}

class DriverDetails extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        const { driver } = props;

        let avatarURL;
        if (driver)
            avatarURL = driver.avatar ? `${TOMCAT_URL}public/documents/${driver.avatar.filename}` : `${driverPNG}`;

        this.state = {
            avatarURL: avatarURL,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { driver } = nextProps;

        this.setDriverAvatar(driver);
    }

    render() {
        const { driver, taskType, canDrop, isOver, connectDropTarget, t } = this.props;
        const isActive = canDrop && isOver;

        let backgroundColor = 'gray';
        let border = 'none';
        if (isActive) {
            backgroundColor = '#6FAFCE';
            border = 'solid #6FAFCE';
        } else if (canDrop) backgroundColor = 'darkkhaki';

        // driver has been assigned to task
        if (driver) {
            // forbid driver dropping for special task types
            if (
                _.includes(
                    [TaskType.MY_OPEN_EXCHANGED_TASK_PENDING, TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING],
                    taskType,
                )
            )
                return (
                    <div className="row">
                        <div style={{ ...style, backgroundColor }}>
                            <img
                                style={{ border }}
                                alt={driver.contactData.firstName}
                                src={this.state.avatarURL}
                                width="25"
                                height="25"
                            />
                        </div>
                        {driver.contactData.firstName}
                    </div>
                );
            // create drop area for drivers and display appropriate avatars
            else
                return connectDropTarget(
                    <div className="row">
                        <div style={{ ...style, backgroundColor }}>
                            <a href="#">
                                <img
                                    style={{ border }}
                                    alt={driver.contactData.firstName}
                                    onMouseOver={() => this.setState({ avatarURL: trashPNG })}
                                    onMouseOut={() => this.setDriverAvatar(driver)}
                                    onClick={() => this.openConfirmDeleteDriverDialog(driver)}
                                    src={this.state.avatarURL}
                                    width="25"
                                    height="25"
                                />
                            </a>
                        </div>
                        {driver.contactData.firstName}
                    </div>,
                );

            // no driver has been assigned
        } else {
            // forbid driver dropping for special task types
            if (
                _.includes(
                    [TaskType.MY_OPEN_EXCHANGED_TASK_PENDING, TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING],
                    taskType,
                )
            )
                return (
                    <div className="row">
                        <div style={{ ...style, backgroundColor }} />
                        {t('driver.no_driver')}
                    </div>
                );
            // show empty driver slot
            else
                return connectDropTarget(
                    <div className="row">
                        <div style={{ ...style, backgroundColor }} />
                        {t('driver.no_driver')}
                    </div>,
                );
        }
    }

    setDriverAvatar(driver) {
        if (driver) {
            if (driver.avatar)
                this.setState({
                    avatarURL: `${TOMCAT_URL}public/documents/${driver.avatar.filename}`,
                });
            else this.setState({ avatarURL: driverPNG });
        }
    }

    openConfirmDeleteDriverDialog(driver) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: driver,
                onProceedDialog: this.onProceedDeleteDriver,
                bodyText: t('driver.confirm_delete_driver'),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: `${driver.contactData.firstName} ${driver.contactData.lastName}`,
            noButtons: true,
        });
    }

    onProceedDeleteDriver() {
        const { firstDriver, taskId, selectedCompany } = this.props;

        if (firstDriver) this.props.patch({ id: taskId, firstDriver: null }, Entities.TASK, selectedCompany);
        else this.props.patch({ id: taskId, secondDriver: null }, Entities.TASK, selectedCompany);
    }
}

export default DropTarget(ItemTypes.DRIVER, driverTarget, collect)(DriverDetails);
