import React, { Component } from 'react';
import { change, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderPaxPrices, validatePaxPrices } from 'src/components/trips/administration/product/ProductForm';
import { assignProduct } from 'src/actions/seasons';
import store from 'src/store/store';
import SelectField from 'material-ui/SelectField';
import { asyncRequest, TOMCAT_URL } from 'src/common/index';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';

class SeasonProductAssignmentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProduct: undefined,
            products: {
                isFetching: true,
                error: undefined,
                items: [],
            },
        };
    }

    componentDidMount() {
        const { isCreate } = this.props;

        if (isCreate) this.fetchProductsNotAssignedBySeason();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedProduct } = prevState;

        // set current selected product fields if isCreate
        if (this.props.isCreate && selectedProduct !== this.state.selectedProduct) {
            const currentProduct = this.state.products.items.find(p => p.id === this.state.selectedProduct);

            if (currentProduct) {
                this.props.change('paxPrices', currentProduct.paxPrices);
                this.props.change('productId', currentProduct.id);
            }
        }
    }

    render() {
        const { handleSubmit, isCreate, t } = this.props;

        if (isCreate) {
            if (this.state.products.isFetching) return <CircularProgress />;

            if (!this.state.products.items || this.state.products.items.length === 0)
                return (
                    <div className="alert alert-info voffset40">
                        <h4>{t('products.no_products')}</h4>
                        <span>{t('products.no_available_products')}</span>
                    </div>
                );
        }

        const productMenuItems = this.state.products.items.map(product => {
            return <MenuItem key={product.id} value={product.id} primaryText={product.name} />;
        });

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <input hidden name="productId" />
                {isCreate && (
                    <div className="row">
                        <div className="col-md-6">
                            <SelectField
                                value={this.state.selectedProduct}
                                onChange={(event, index, value) => {
                                    this.setState({
                                        selectedProduct: value,
                                    });
                                }}
                                floatingLabelText={t('products.products')}
                                fullWidth={true}
                                hintText={t('products.select_product')}
                                floatingLabelFixed={true}>
                                {productMenuItems}
                            </SelectField>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-12">
                        <div className="view">
                            <FieldArray name="paxPrices" t={t} component={renderPaxPrices} />
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    fetchProductsNotAssignedBySeason = () => {
        const { season, companyId } = this.props;

        asyncRequest(
            `${TOMCAT_URL}api/products/findByCompanyNotAssignedToSeason?companyId=${companyId}&seasonId=${season.id}`,
        )
            .then(response => {
                const products = response.json;

                this.setState({
                    products: {
                        isFetching: false,
                        error: undefined,
                        items: products,
                    },
                    selectedProduct: products && products.length > 0 ? products[0].id : undefined,
                });
            })
            .catch(error =>
                this.setState({
                    products: {
                        isFetching: false,
                        error: error,
                        items: [],
                    },
                }),
            );
    };
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (values.paxPrices) errors.paxPrices = validatePaxPrices(values.paxPrices, t);

    return errors;
};

const onSubmit = (data, ownProps) => {
    const { companyId } = ownProps;

    console.log('Submit SeasonProductAssignmentForm: ', data);

    if (data.productId) store.dispatch(assignProduct(data, companyId));

    ownProps.handleClose();
};

SeasonProductAssignmentForm = reduxForm({
    form: 'SeasonProductAssignmentForm',
    validate,
})(SeasonProductAssignmentForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, productId, season } = ownProps;

    const initialValues = {
        seasonId: season.id,
        isCreate,
    };

    if (!isCreate && season) {
        initialValues.paxPrices = season.products.find(sp => sp.product.id === productId).paxPrices;
        initialValues.productId = productId;
    } else initialValues.paxPrices = [];

    return {
        initialValues: initialValues,
        onSubmit: data => onSubmit(data, ownProps),
    };
};

SeasonProductAssignmentForm = connect(mapStateToProps, { change }, null, { withRef: true })(
    SeasonProductAssignmentForm,
);

export default SeasonProductAssignmentForm;
