import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminCouponAdministration from 'src/components/admin/coupons/admin-coupon-administration';

const mapStateToProps = state => {
    return {
        coupons: getEntities(state, Entities.COUPON),
    };
};

const AdminCouponAdministrationContainer = connect(mapStateToProps, { fetchAll, patch })(AdminCouponAdministration);

export default AdminCouponAdministrationContainer;
