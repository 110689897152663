import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
    dashIfNull,
    deleteById,
    DeleteById,
    Entities,
    EntityCollectionState,
    FetchByCompanyId,
    fetchByCompanyId,
    getEntities,
} from 'src/common/index';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { priceFormatter, toggleFormatter } from 'src/utils/formatters';
import ParentForm from 'src/components/misc/parent-form';
import ProductForm from 'src/components/trips/administration/product/ProductForm';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import { InjectedTranslateProps, StoreState } from 'src/types';
import Product from 'src/common/entity/trip/product/Product';
import PaxPrice from 'src/common/entity/basic-types/PaxPrice';

interface MapStateToProps {
    selectedCompany: number;
    products: EntityCollectionState<Product>;
}

interface MapDispatchToProps extends FetchByCompanyId, DeleteById {}

interface TableDataRow {
    id: number;
    name: string;
    visible: boolean;
    freeCompanionPerPax: number;
    schoolOffer: boolean;
    cheapestPrice: number;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedTranslateProps;

class ProductAdministration extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    public componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchByCompanyId(Entities.PRODUCT, selectedCompany);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { products } = nextProps;

        if (!this.state.startedFetching && products.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(products.items.length);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.products, this.props.products, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    public render() {
        const { products, selectedCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !products.isFetching;

        const tableData: TableData = [];

        if (finishFetching && products.items.length > 0) {
            products.items.map((product: Product) => {
                tableData.push({
                    id: product.id,
                    name: product.name,
                    visible: product.visible,
                    freeCompanionPerPax: product.freeCompanionPerPax,
                    schoolOffer: product.schoolOffer,
                    cheapestPrice: Math.min.apply(
                        Math,
                        product.paxPrices.map((paxPrice: PaxPrice) => paxPrice.price),
                    ),
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('products.products')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: ParentForm,
                                componentProps: {
                                    reducerIndex: selectedCompany,
                                    isCreate: true,
                                    mainEntityProps: {
                                        entity: Entities.PRODUCT,
                                    },
                                    childForm: {
                                        component: ProductForm,
                                        props: {
                                            companyId: selectedCompany,
                                        },
                                    },
                                },
                                title: t('products.new_product'),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('products.new_product')}
                    </button>
                </legend>

                {(() => {
                    if (products.error) return <ErrorMessage object={products} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="freeCompanionPerPax"
                                    dataAlign="right"
                                    dataFormat={cell => dashIfNull(cell)}
                                    width="250"
                                    dataSort={true}>
                                    {t('products.free_companion')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="cheapestPrice"
                                    dataAlign="right"
                                    width="120"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    dataSort={true}>
                                    {t('products.cheapest_price')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="schoolOffer"
                                    width="150"
                                    dataSort={true}
                                    dataFormat={(cell, row) => toggleFormatter(row, Entities.PRODUCT, 'schoolOffer')}>
                                    {t('products.offer_school')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="visible"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={(cell, row) => toggleFormatter(row, Entities.PRODUCT, 'visible')}>
                                    {t('companies.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    private tableActionsFormatter = (cell: any, product: TableDataRow) => {
        const { selectedCompany, t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                reducerIndex: selectedCompany,
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.PRODUCT,
                                    id: product.id,
                                },
                                childForm: {
                                    component: ProductForm,
                                    props: {
                                        companyId: selectedCompany,
                                    },
                                },
                            },
                            title: product.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => this.openConfirmDeleteDialog(product)}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    };

    private onProceedDelete = (product: TableDataRow) => {
        this.props.deleteById(product.id, Entities.PRODUCT, this.props.selectedCompany);
    };

    private openConfirmDeleteDialog = (product: TableDataRow) => {
        // TODO: consider related entities (has this product already been booked, etc)

        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: product,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('products.confirm_delete_product'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: product.name,
            noButtons: true,
        });
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedCompany: state.selectedCompany,
        products: getEntities(state, Entities.PRODUCT, state.selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        fetchByCompanyId,
        deleteById,
    })(composeModalActions(ProductAdministration)),
);
