import { connect } from 'react-redux';

import { patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { addNotification } from 'src/actions/notifications';
import { getOwnCompany } from 'src/selectors/bus-companies';
import CompanySettings from 'src/components/administration/company-settings/company-settings';
import { setSliderIndex } from 'src/actions/form';

const mapStateToProps = state => {
    return {
        selectedSliderIndex: state.selectedSliderIndex,
        selectedCompany: state.selectedCompany,
        company: getOwnCompany(state),
    };
};

const CompanySettingsContainer = connect(mapStateToProps, {
    fetchOwnBusCompany,
    patch,
    addNotification,
    setSliderIndex,
})(CompanySettings);

export default CompanySettingsContainer;
