import { createSelector } from 'reselect';

import { Entities, getEntities } from 'src/common/index';

const getDrivers = state => getEntities(state, Entities.DRIVER, state.selectedCompany);

export const getActiveDrivers = createSelector([getDrivers], drivers => {
    if (drivers && drivers.items) drivers.items = drivers.items.filter(driver => driver.active === true);
    return drivers;
});
