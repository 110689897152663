import BaseEntity from '../BaseEntity';
import Document from '../basic-types/Document';
import Location from '../basic-types/Location';
import ContactData from '../basic-types/ContactData';

export default abstract class Company extends BaseEntity {
    public companyName: string;
    public registeredCompanyName: string;
    public contactData: ContactData;
    public location: Location;
    public logo: Document;
    public visible: boolean;
    public freeCancellation: boolean;
    public freeCancellationDays: number;
    public agb?: string;
    public uidNumber?: string;
    public description?: string;
    public paymentConditions?: string;
    public reversalConditions?: string;
    public confirmationConclusion?: string;

    constructor(json: any) {
        super(json);

        this.companyName = json.companyName;
        this.registeredCompanyName = json.registeredCompanyName;
        this.contactData = ContactData.fromJson(json.contactData);
        this.location = json.location;
        this.logo = json.logo;
        this.visible = json.visible;
        this.freeCancellation = json.freeCancellation;
        this.freeCancellationDays = json.freeCancellationDays;
        this.agb = json.agb;
        this.uidNumber = json.uidNumber;
        this.description = json.description;
        this.paymentConditions = json.paymentConditions;
        this.reversalConditions = json.reversalConditions;
        this.confirmationConclusion = json.confirmationConclusion;
    }

    public toString(): string {
        return `${this.companyName} - ${this.registeredCompanyName}`;
    }
}

export enum CompanyOrigin {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ONLINE_REGISTRATION = 'ONLINE_REGISTRATION',
    DAIMLER_IMPORT = 'DAIMLER_IMPORT',
}
