import React, { Component } from 'react';
import autoBind from 'react-autobind';

import composeModalActions from 'src/utils/modal-action-wrapper';
import BusDriverList from 'src/components/operatingtool/busdrivers/busdriver-list';
import Calendar from 'src/components/operatingtool/calendar';
import Details from 'src/components/operatingtool/details';

class OperatingTool extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {
        this.props.setFetchMethodOnError(this.fetchOnError);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        return (
            <div className="operatingwrapper">
                <div id="calendarcontainer">
                    <Calendar
                        selectedCompany={this.props.selectedCompany}
                        eventDetails={this.props.eventDetails}
                        resources={this.props.resources}
                        events={this.props.events}
                        drivers={this.props.drivers}
                        busCompany={this.props.busCompany}
                        fetchCalendarRelatedEntities={this.props.fetchCalendarRelatedEntities}
                        patch={this.props.patch}
                        selectEvent={this.props.selectEvent}
                        addNotification={this.props.addNotification}
                        openModal={this.props.openModal}
                        modal={this.props.modal}
                    />
                </div>
                <div>
                    <div className="busdrivercontainer">
                        <BusDriverList
                            eventDetails={this.props.eventDetails}
                            tasks={this.props.tasks}
                            selectedCompany={this.props.selectedCompany}
                            drivers={this.props.drivers}
                            fetchByCompanyId={this.props.fetchByCompanyId}
                            addNotification={this.props.addNotification}
                            patch={this.props.patch}
                            openModal={this.props.openModal}
                            modal={this.props.modal}
                        />
                    </div>
                    <div className="detailscontainer-calendar">
                        <Details
                            reviews={this.props.reviews}
                            eventDetails={this.props.eventDetails}
                            busCompany={this.props.busCompany}
                            addNotification={this.props.addNotification}
                            deleteById={this.props.deleteById}
                            patch={this.props.patch}
                            openModal={this.props.openModal}
                            showActions={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchOnError() {
        this.props.fetchTasksByCompany(this.props.selectedCompany);
    }
}

export default composeModalActions(OperatingTool);
