import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, save } from 'src/common/index';
import SeasonCalculationAdministration from 'src/components/administration/season-calculation-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        seasonCalculations: getEntities(state, Entities.SEASON_CALCULATION, state.selectedCompany),
    };
};

const SeasonCalculationAdministrationContainer = connect(mapStateToProps, {
    fetchByCompanyId,
    save,
    deleteById,
})(SeasonCalculationAdministration);

export default SeasonCalculationAdministrationContainer;
