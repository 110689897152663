import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import Cookies from 'js-cookie';
import _ from 'lodash';
import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { externalIdFormatter } from 'src/components/admin/accounts/admin-account-administration';
import BusEditModal from 'src/components/buses/bus-edit-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import Stars from 'src/components/misc/stars';
import { isIntegration } from 'src/selectors/integrations';
import { ButtonColors, ReleaseMode } from 'src/utils/constants';
import { dateFormatter } from 'src/utils/formatters';
import { checkIfSold } from 'src/utils/helpers';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';

class BusAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            showBusId: Cookies.get('showBusId') ? Cookies.get('showBusId') === 'true' : false,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;
        this.props.fetchOwnBusCompany(selectedCompany);
        this.props.fetchByCompanyId(Entities.BUS, this.props.selectedCompany);
        this.props.fetchByCompanyId(Entities.BUS_CATEGORY, this.props.selectedCompany);
        this.props.fetchByCompanyId(Entities.CALCULATION, this.props.selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { buses } = nextProps;

        if (buses.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(buses.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.buses, this.props.buses, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { buses, brands, categories, engines, calculations, busCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !buses.isFetching && !busCompany.isFetching;

        const tableData = [];
        if (finishFetching && buses.items.length > 0) {
            buses.items
                .filter(bus => !checkIfSold(bus))
                .map(bus => {
                    tableData.push({
                        id: bus.id,
                        name: bus.name,
                        active: bus.active,
                        releaseMode: bus.releaseMode,
                        virtual: bus.virtual,
                        plateNumber: bus.plateNumber,
                        type: bus.type,
                        seatsTotal: bus.seatsFacingFront + bus.seatsFacingBack + bus.seatsForGuides,
                        seatsFacingFront: bus.seatsFacingFront,
                        seatsFacingBack: bus.seatsFacingBack,
                        seatsForGuides: bus.seatsForGuides,
                        category: categories[bus.category].name,
                        brand: brands[bus.brand].name,
                        engine: engines[bus.engine].name,
                        calculation: calculations[bus.calculation].name,
                        stars: bus.stars,
                        calculatedRating: bus.calculatedRating,
                        integrationOnly: bus.integrationOnly,
                        externalBusId: bus.externalBusId,
                        expiry: bus.expiry,
                        order: bus.order,
                        busHeightInCm: bus.busHeightInCm,
                        busLengthInCm: bus.busLengthInCm,
                        numberOfAxles: bus.numberOfAxles,
                        totalPermittedWeightInKg: bus.totalPermittedWeightInKg,
                    });
                });
        }

        return (
            <div>
                <legend className="legend">
                    {t('companies.buses')}
                    <div className="pull-right">
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                if (
                                    !categories ||
                                    _.size(categories) < 1 ||
                                    !calculations ||
                                    _.size(calculations) < 1
                                ) {
                                    let warningText;
                                    if (
                                        (!categories || _.size(categories) < 1) &&
                                        (!calculations || _.size(calculations) < 1)
                                    )
                                        warningText = t('bus.error_both_first');
                                    else if (!calculations || _.size(calculations) < 1)
                                        warningText = t('bus.error_rate_first');
                                    else if (!categories || _.size(categories) < 1)
                                        warningText = t('bus.error_category_first');

                                    this.props.openModal({
                                        component: ConfirmDialog,
                                        componentProps: {
                                            bodyText: warningText,
                                            proceedText: t('common_phrases.ok'),
                                            proceedButtonColor: ButtonColors.NOTIFY,
                                            isNotification: true,
                                        },
                                        title: t('common_phrases.attention'),
                                        noButtons: true,
                                    });
                                } else {
                                    this.table.reset();
                                    this.props.openModal({
                                        component: BusEditModal,
                                        componentProps: {
                                            isCreate: true,
                                            type: 'bus',
                                            rating: undefined,
                                            duplicate: false,
                                        },
                                        title: t('bus.new_bus'),
                                        mandatoryFields: true,
                                    });
                                }
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('bus.new_bus')}
                        </button>
                    </div>
                    <div className="pull-right">
                        <Toggle
                            label="ID"
                            labelStyle={{
                                fontSize: '16px',
                                color: '#0c66ab',
                                paddingRight: '5px',
                            }}
                            style={{
                                paddingRight: '20px',
                            }}
                            toggled={this.state.showBusId}
                            onToggle={() => {
                                Cookies.set('showBusId', !this.state.showBusId, {
                                    expires: 10 * 365,
                                    secure: true,
                                    sameSite: 'none',
                                });
                                this.setState({ showBusId: !this.state.showBusId });
                            }}
                        />
                    </div>
                </legend>

                {(() => {
                    if (buses.error) return <ErrorMessage object={buses} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={!this.state.showBusId}
                                    dataAlign="right"
                                    width="50"
                                    dataSort={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="plateNumber"
                                    width="125"
                                    dataSort={true}>
                                    {t('bus.plate_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="type" width="90" dataSort={true}>
                                    {t('common_phrases.type')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="seatsTotal"
                                    width="100"
                                    dataFormat={(cell, row) => (
                                        <div
                                            title={t('administration.seats_info', {
                                                seatsFacingFront: row.seatsFacingFront,
                                                seatsFacingBack: row.seatsFacingBack,
                                                seatsForGuides: row.seatsForGuides,
                                            })}>
                                            {row.seatsFacingFront} / {row.seatsFacingBack} / {row.seatsForGuides}
                                        </div>
                                    )}
                                    csvFormat={(cell, row) =>
                                        `${row.seatsFacingFront} / ${row.seatsFacingBack} / ${row.seatsForGuides}`
                                    }
                                    sortFunc={this.seatsSort}
                                    dataSort={true}>
                                    {t('bus.seats')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="category" dataSort={true}>
                                    {t('common_phrases.category')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="brand" dataSort={true}>
                                    {t('brands.brand')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="engine"
                                    width="110"
                                    dataSort={true}
                                    hidden={true}>
                                    {t('engines.euronorm')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="stars"
                                    width="100"
                                    dataSort={true}
                                    hidden={true}>
                                    {t('star_rating.stars')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="calculation"
                                    width="160"
                                    dataSort={true}>
                                    {t('bus.calculation')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="calculatedRating"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={cell => <Stars rating={cell} t={t} />}>
                                    {t('common_phrases.rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="order" width="80" dataSort={true}>
                                    {t('bus.group_order')}
                                </TableHeaderColumn>
                                {isIntegration() && (
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        headerText={t('bus.expiry_hint')}
                                        dataField="expiry"
                                        width="90"
                                        dataSort={true}
                                        dataFormat={dateFormatter}>
                                        {t('bus.expiry')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    headerText={t('bus.import_hint')}
                                    dataAlign="center"
                                    dataFormat={id => externalIdFormatter(id, t)}
                                    width="80"
                                    dataField="externalBusId"
                                    dataSort={true}>
                                    {t('common_phrases.import')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText={t('bus.mode')}
                                    dataAlign="center"
                                    dataField="releaseMode"
                                    width="80"
                                    dataSort={true}
                                    dataFormat={cell => this.releaseModeFormatter(cell)}>
                                    {t('bus.mode')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerText={t('bus.active_hint')}
                                    dataField="active"
                                    width="80"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'active')}>
                                    {t('common_phrases.active')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="virtual"
                                    width="80"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'virtual')}
                                    hidden={!busCompany.entity.virtual}>
                                    {t('bus.virtual')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="integrationOnly"
                                    width="140"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'integrationOnly')}
                                    hidden={!busCompany.entity.integrationOnlyBuses}>
                                    {t('bus.integration_only')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    seatsSortAsc(a, b) {
        if (a.seatsTotal < b.seatsTotal) return -1;
        if (a.seatsTotal > b.seatsTotal) return 1;
        if (a.seatsFacingFront < b.seatsFacingFront) return -1;
        if (a.seatsFacingFront > b.seatsFacingFront) return 1;
        if (a.seatsFacingBack < b.seatsFacingBack) return -1;
        if (a.seatsFacingBack > b.seatsFacingBack) return 1;
        if (a.seatsForGuides < b.seatsForGuides) return -1;
        if (a.seatsForGuides > b.seatsForGuides) return 1;
        return 0;
    }

    seatsSort(a, b, order) {
        return order === 'desc' ? this.seatsSortAsc(b, a) : this.seatsSortAsc(a, b);
    }

    openEditModal(bus, duplicate = false) {
        const { t } = this.props;
        this.props.openModal({
            component: BusEditModal,
            componentProps: {
                id: bus.id,
                isCreate: duplicate,
                type: 'bus',
                rating: bus.calculatedRating,
                duplicate,
            },
            title: duplicate ? t('bus.new_bus') : bus.name,
            mandatoryFields: true,
        });
    }

    releaseModeFormatter(cell) {
        const { t } = this.props;
        return cell === ReleaseMode.RELEASED ? (
            <span title={t('bus.free')} className="glyphicon glyphicon-ok-circle text-success" />
        ) : (
            <span title={t('bus.blocked')} className="glyphicon glyphicon-ban-circle text-danger" />
        );
    }

    tableActionsFormatter(cell, bus) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.openEditModal(bus);
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.BUS.repository}/${bus.id}/deletable`)
                            .then(() => this.openConfirmDeleteDialog(bus))
                            .catch(() => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.duplicate')}
                    onClick={() => {
                        const duplicate = true;
                        this.openEditModal(bus, duplicate);
                    }}>
                    <span className="fa fa-files-o text-warning" />
                </button>
            </div>
        );
    }

    toggleFormatter(cell, bus, field) {
        return (
            <Toggle
                toggled={bus[field]}
                onToggle={() => {
                    if (field === 'active' && !bus.active) this.openEditModal(bus);
                    else
                        this.props.patch(
                            {
                                [field]: !bus[field],
                                id: bus.id,
                            },
                            Entities.BUS,
                            this.props.selectedCompany,
                        );
                }}
            />
        );
    }

    openConfirmDeleteDialog(bus) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: bus,
                onProceedDialog: bus => this.props.deleteById(bus.id, Entities.BUS, this.props.selectedCompany),
                bodyText: t('bus.confirmation_delete'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: bus.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('bus.error_events'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }
}

export default withTranslation()(composeModalActions(BusAdministration));
