import Slider from 'material-ui/Slider';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { formatHours, formatMinutes } from 'src/components/misc/redux-form-helpers';

class OtherSettings extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            defaultTaskPostProcessingTime: props.defaultTaskPostProcessingTime,
            bookingLeadTime: props.bookingLeadTime,
        };
    }

    handleDefaultTaskPostProcessingTimeSlider(event, value) {
        this.setState({ defaultTaskPostProcessingTime: value });
    }

    handleBookingLeadTimeSlider(event, value) {
        this.setState({ bookingLeadTime: value });
    }

    componentDidUpdate(prevProps, prevState) {
        this.props.updateSettings(this.state);
    }

    render() {
        const { defaultTaskPostProcessingTime, bookingLeadTime, t } = this.props;

        return (
            <div className="other-settings">
                <div>
                    <p>
                        {t('others.postprocessing_time', {
                            time: formatMinutes(t, this.state.defaultTaskPostProcessingTime),
                        })}
                    </p>
                    <Slider
                        min={0}
                        max={720}
                        step={5}
                        defaultValue={defaultTaskPostProcessingTime}
                        value={this.state.defaultTaskPostProcessingTime}
                        onChange={this.handleDefaultTaskPostProcessingTimeSlider}
                    />
                </div>
                <div>
                    <p>{t('others.lead_time', { time: formatHours(t, this.state.bookingLeadTime) })}</p>
                    <Slider
                        min={48}
                        max={240}
                        step={1}
                        defaultValue={bookingLeadTime}
                        value={this.state.bookingLeadTime}
                        onChange={this.handleBookingLeadTimeSlider}
                    />
                </div>
            </div>
        );
    }
}

export default OtherSettings;
