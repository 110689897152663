import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { asyncRequest, TOMCAT_URL } from 'src/common/index';
import CustomPieChart from 'src/components/superadmin/charts/custom-pie-chart';
import CustomBarChart from 'src/components/superadmin/charts/custom-bar-chart';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class Overview extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            entityCounts: undefined,
        };
    }

    componentDidMount() {
        asyncRequest(`${TOMCAT_URL}super/statistics/entity-counts`).then(response =>
            this.setState({
                entityCounts: response.json,
            }),
        );
    }

    render() {
        const { t } = this.props;
        const entityCounts = this.state.entityCounts;

        if (entityCounts) {
            return (
                <div>
                    <legend className="legend">{t('overview')}</legend>

                    <div className="row">
                        <div className="col-md-6">
                            <CustomPieChart
                                data={entityCounts.events.subEntityCounts}
                                title={t('events.events')}
                                sum={entityCounts.events.count}
                            />
                        </div>
                        <div className="col-md-6">
                            <CustomPieChart
                                data={entityCounts.accounts.subEntityCounts}
                                title={t('fleets.accounts')}
                                sum={entityCounts.accounts.count}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <CustomPieChart
                                data={entityCounts.buses.subEntityCounts}
                                title={t('companies.buses')}
                                sum={entityCounts.buses.count}
                            />
                        </div>
                        <div className="col-md-6">
                            <h3>{t('entities')}</h3>
                            <br />
                            <CustomBarChart data={getDataForBarChart(entityCounts)} />
                        </div>
                    </div>
                </div>
            );
        }

        return <div />;
    }
}

export default withTranslation()(Overview);

const getDataForBarChart = entityCounts => {
    return _.map(entityCounts, (entityCount, name) => {
        return {
            name: name,
            count: entityCount.count,
        };
    }).sort((a, b) => a.count - b.count);
};
