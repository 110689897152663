import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Entities,
    fetchByCompanyId,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    Projection,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import DiscountGroupEditForm from 'src/components/administration/discount-group-edit-form';
import autoBind from 'react-autobind';
import { getOwnCompany } from 'src/selectors/bus-companies';

class DiscountGroupEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany, fetchById } = this.props;
        this.props.fetchByCompanyId(Entities.CALCULATION, selectedCompany);
        this.props.fetchByCompanyId(Entities.BUS, selectedCompany);

        if (!isCreate) fetchById(id, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { discountGroup } = nextProps;

        if (!this.state.startedFetching && discountGroup.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { discountGroup, isCreate, selectedCompany, t, calculations, buses, busCompany } = this.props;
        console.log(discountGroup, 'discount group');

        const finishFetching =
            this.state.startedFetching && !discountGroup.isFetching && !buses.isFetching && !calculations.isFetching;
        if (finishFetching) {
            // check for errors
            if (discountGroup.error) return <ErrorMessage object={discountGroup} />;
            // if everything is ok populate form
            else {
                let initialValues, calculation;
                if (!isCreate && discountGroup.content) {
                    initialValues = {
                        id: discountGroup.content.id,
                        name: discountGroup.content.name,
                        discount: discountGroup.content.discount,
                        selectedBuses: discountGroup.content.buses,
                        calculation: calculation ? calculation._links.self.href : undefined,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        busDiscountCalculations: discountGroup.content.busDiscountCalculations,
                        yieldManagementForDiscountGroupDeactivated:
                            discountGroup.content.yieldManagementForDiscountGroupDeactivated,
                    };
                } else {
                    initialValues = {
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }
                return (
                    <DiscountGroupEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        ref={ref => (this.childForm = ref)}
                        selectedCompany={selectedCompany}
                        calculations={calculations}
                        buses={buses.items}
                        discountGroup={discountGroup}
                        isBusDiscountGroupEnabled={busCompany.entity.busDiscountGroupEnabled}
                        isGlobalYieldManagementEnabled={busCompany.entity.yieldManagementEnabled}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose, patch, save, selectedCompany, busCompany } = this.props;

        const isBusDiscountGroupEnabled = busCompany.entity.busDiscountGroupEnabled;

        console.log(data, 'submit data');

        if (isBusDiscountGroupEnabled) {
            if (!isCreate) {
                patch(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL, null, 'addBusDiscount');
                patch(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL);
            } else {
                save(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL, res => {
                    if (data.busDiscountCalculations) {
                        data.busDiscountCalculations.map(key => (key.discountGroupId = res.id));
                        data.id = res.id;
                        patch(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL, null, 'addBusDiscount');
                    }
                });
            }
        } else {
            if (!isCreate) {
                patch(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL);
            } else {
                save(data, Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL, null);
            }
        }
        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        discountGroup: getUpdatedEntity(state, Entities.DISCOUNT_GROUP, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        calculations: getEntities(state, Entities.CALCULATION, state.selectedCompany),
        buses: getEntities(state, Entities.BUS, state.selectedCompany),
        busCompany: getOwnCompany(state),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        fetchByCompanyId,
        patch,
        save,
    },
    null,
    { withRef: true },
)(DiscountGroupEditModal);
