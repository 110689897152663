import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Entities, TOMCAT_URL } from 'src/common/index';

import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';

class EventCategoryForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-12">
                        <Field
                            name="description"
                            label={t('common_phrases.description')}
                            hintText={t('category.description_hint')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.TICKET_EVENT_CATEGORY.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'eventCategoryForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(EventCategoryForm);
