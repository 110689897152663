import { connect } from 'react-redux';

import { Entities, fetchByCompanyId, getEntities } from 'src/common/index';
import DiscountGroupAdministration from 'src/components/administration/discount-group-administration';
import { getOwnCompany } from 'src/selectors/bus-companies';

const mapStateToProps = state => {
    return {
        discountGroups: getEntities(state, Entities.DISCOUNT_GROUP, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        buses: getEntities(state, Entities.BUS, state.selectedCompany),
        busCompany: getOwnCompany(state),
    };
};

const DiscountGroupAdministrationContainer = connect(mapStateToProps, { fetchByCompanyId })(
    DiscountGroupAdministration,
);

export default DiscountGroupAdministrationContainer;
