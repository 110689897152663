import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { asyncCheckDuplicate, renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class EquipmentEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="rating"
                            label={t('common_phrases.rating')}
                            hintText={t('equipment.rating_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Field name="bestOption" label={t('equipment.best_option')} component={renderCheckbox} />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isInteger(values.rating)) errors.rating = t('error_hint.error_integer');
    if (values.rating < 0 || values.rating > 100) errors.rating = t('equipment.error_rating');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.BUS_EQUIPMENT.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'equipmentEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(EquipmentEditForm);
