import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import AdminEngineAdministration from 'src/components/admin/engines/admin-engine-administration';

const mapStateToProps = state => {
    return {
        engines: getEntities(state, Entities.BUS_ENGINE),
    };
};

const AdminEngineAdministrationContainer = connect(mapStateToProps, { fetchAll })(AdminEngineAdministration);

export default AdminEngineAdministrationContainer;
