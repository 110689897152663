import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, patch } from 'src/common/index';
import ModuleTable from 'src/components/superadmin/modules/module-table';

const mapStateToProps = (state, props) => {
    return {
        modules: getEntities(state, Entities.MODULE, props.selectedCompany),
    };
};

const ModuleTableContainer = connect(mapStateToProps, {
    fetchByCompanyId,
    patch,
    deleteById,
})(ModuleTable);

export default ModuleTableContainer;
