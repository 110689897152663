import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import _ from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import CompanyTable from 'src/components/admin/buslocations/company-table';
import LegendStats from 'src/components/admin/buslocations/legend-stats';
import ErrorMessage from 'src/components/misc/error-message';
import BusLocationMap from './bus-location-map';

class BusLocations extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            // requested backend data
            companiesWithBuses: {
                entities: [],
                isFetching: true,
                error: undefined,
            },

            // containing selected companies with buses
            filteredCompaniesWithBuses: [],

            // count of all unique bus and company locations
            busLocationCount: undefined,
            companyLocationCount: undefined,

            // determines if the markers should be clustered or not
            clustering: false,

            // determines whether we should show the locations of buses OR companies
            showBuses: true,
            showCompanies: false,

            // the company you individually selected
            companySelected: null,

            // toggles for the map filter
            filters: {
                active: false,
                onlinePayment: false,
                hasAnyAPI: false,
                omniPlusON: false,
            },
        };
    }

    componentWillMount() {
        this.loadCompaniesWithBuses();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.filters, this.state.filters)) {
            this.filterByActiveFilters();
        }
    }

    render() {
        const { t, i18n } = this.props;

        if (!this.state.companiesWithBuses.isFetching) {
            if (this.state.companiesWithBuses.error) return <ErrorMessage object={this.state.companiesWithBuses} />;

            return (
                <div>
                    {/*Legend with summary statistics*/}
                    <div id="maps-legend">
                        <LegendStats
                            t={t}
                            companiesWithBuses={this.state.companiesWithBuses.entities}
                            busLocationCount={this.state.busLocationCount}
                            companyLocationCount={this.state.companyLocationCount}
                            showBuses={this.state.showBuses}
                            showCompanies={this.state.showCompanies}
                            clustering={this.state.clustering}
                            toggleClustering={() => this.setState({ clustering: !this.state.clustering })}
                            toggleShowBuses={() =>
                                this.setState({ showBuses: !this.state.showBuses, showCompanies: false })
                            }
                            toggleShowCompanies={() =>
                                this.setState({ showCompanies: !this.state.showCompanies, showBuses: false })
                            }
                        />
                    </div>

                    {/*Google Map with Marks of Bus Locations*/}
                    <div id="busLocationMap">
                        <BusLocationMap
                            t={t}
                            companiesWithBuses={
                                this.state.companySelected
                                    ? this.state.companySelected
                                    : this.state.filteredCompaniesWithBuses
                            }
                            omniPlusON={this.state.filters.omniPlusON}
                            setBusLocationCount={locations => this.setState({ busLocationCount: locations })}
                            setCompanyLocationCount={locations => this.setState({ companyLocationCount: locations })}
                            clustering={this.state.clustering}
                            showBuses={this.state.showBuses}
                            showCompanies={this.state.showCompanies}
                        />
                    </div>

                    {/*List of all companies with buses*/}
                    <div id="busMapCompanyTable">
                        <CompanyTable
                            t={t}
                            i18n={i18n}
                            companies={
                                this.isFilterActive()
                                    ? this.state.filteredCompaniesWithBuses
                                    : this.state.companiesWithBuses.entities
                            }
                            onSelectCompany={companyId => {
                                this.setState({
                                    companySelected: companyId
                                        ? this.state.companiesWithBuses.entities.filter(
                                              company => company.id === companyId,
                                          )
                                        : null,
                                });
                            }}
                            companySelected={this.state.companySelected}
                            onToggle={category => {
                                this.setState(state => ({
                                    filters: Object.assign({}, state.filters, { [category]: !state.filters[category] }),
                                }));
                            }}
                            filters={this.state.filters}
                        />
                    </div>
                </div>
            );
        }

        return <div />;
    }

    loadCompaniesWithBuses() {
        asyncRequest({
            method: 'GET',
            endpoint: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/search/findAllCompaniesJoinFetchBuses?visible=true&projection=with-buses`,
        })
            .then(response => {
                const entities = response.json['_embedded']['bus-companies'];
                this.setState({
                    companiesWithBuses: {
                        entities: entities,
                        isFetching: false,
                        error: undefined,
                    },
                    filteredCompaniesWithBuses: entities,
                });
            })
            .catch(error =>
                this.setState({
                    companiesWithBuses: {
                        entities: [],
                        isFetching: false,
                        error: error.status,
                    },
                    filteredCompaniesWithBuses: [],
                }),
            );
    }

    filterByActiveFilters() {
        if (this.isFilterActive()) {
            this.setState({
                filteredCompaniesWithBuses: this.state.companiesWithBuses.entities
                    .filter(company => (this.state.filters.hasAnyAPI ? company.apis.hasAny : true))
                    .filter(company => (this.state.filters.onlinePayment ? company.onlinePaymentModuleEnabled : true))
                    .filter(company => (this.state.filters.omniPlusON ? company.externalCompanyId : true))
                    .filter(company => (this.state.filters.active ? company.buses.some(bus => bus.active) : true)),
                companySelected: null,
            });
        } else {
            this.setState({
                filteredCompaniesWithBuses: this.state.companiesWithBuses.entities,
            });
        }
    }

    isFilterActive() {
        return Object.values(this.state.filters).some(filter => filter);
    }
}

export default withTranslation()(BusLocations);
