import { connect } from 'react-redux';

import {
    deleteById,
    Entities,
    fetchAll,
    fetchByCompanyId,
    fetchOrCreateByCompanyId,
    getEntities,
    patch,
    Projection,
} from 'src/common/index';
import CustomerGroupSettingsAdministration from 'src/components/administration/customer-group-settings-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        customerGroupSettings: getEntities(
            state,
            Entities.CUSTOMER_GROUP_SETTINGS,
            state.selectedCompany,
            Projection.FULL,
        ),
    };
};

const CustomerGroupSettingsAdministratorContainer = connect(mapStateToProps, {
    fetchAll,
    fetchByCompanyId,
    fetchOrCreateByCompanyId,
    deleteById,
    patch,
})(CustomerGroupSettingsAdministration);

export default CustomerGroupSettingsAdministratorContainer;
