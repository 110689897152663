import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Error extends Component {
    render() {
        const { t } = this.props;
        const query = this.props.location.query;
        const statusCode = query.error;

        return statusCode ? (
            <div className="alert alert-danger">
                {t('error_occurred_code')} {statusCode}
            </div>
        ) : (
            <div className="alert alert-danger">{t('error_occurred')}</div>
        );
    }
}

export default withTranslation()(Error);
