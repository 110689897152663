import { BigNumber } from 'bignumber.js';
import BasicType from './BasicType';
import moment from 'moment';

// Keys as delivered by backend
export enum Currency {
    EUR = 'EUR',
    CHF = 'CHF',
    HUF = 'HUF',
    CZK = 'CZK',
    HRK = 'HRK',
    PLN = 'PLN',
    SEK = 'SEK',
}

export default class Money implements BasicType {
    public amount: BigNumber;
    public currency: Currency;

    constructor(amount: BigNumber, currency: Currency) {
        this.amount = amount;
        this.currency = currency;
    }

    /**
     * Format the money with Intl.NumberFormat.
     */
    public format(): string {
        const maximumFractionDigits =
            this.currency === 'HUF' || this.currency === 'CZK' || this.currency === 'SEK' ? 0 : 2;
        return new Intl.NumberFormat(moment.locale(), {
            style: 'currency',
            currency: this.currency,
            maximumFractionDigits,
        }).format(this.amount.toNumber());
    }

    public toString(): string {
        return this.format();
    }

    public equals(other: Money): boolean {
        return this.amount === other.amount && this.currency === other.currency;
    }

    public asAbs(): Money {
        return new Money(this.amount.abs(), this.currency);
    }

    public isNegative(): boolean {
        return this.amount.isNegative();
    }

    public static fromJson(json: any): Money {
        return new Money(new BigNumber(json.amount), json.currency);
    }

    /**
     * Money sort function
     */
    public static sort(priceA: Money, priceB: Money, order: 'asc' | 'desc'): number {
        let comparison = 0;
        if (priceA.amount.isLessThan(priceB.amount)) comparison = -1;
        if (priceA.amount.isGreaterThan(priceB.amount)) comparison = 1;

        return order === 'asc' ? comparison : -comparison;
    }
}
