import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Cookies from 'js-cookie';
import { asyncRequest, TOMCAT_URL } from 'src/common';
import FileSaver from 'file-saver';

class BusYieldCalendar extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.selectedRow = [];
    }

    componentDidUpdate() {
        if (this.props.availableBuses.entities.resources) this.populateCalendar();
    }
    populateCalendar() {
        const { i18n, t } = this.props;
        const calendar = this.calendar;

        $(calendar).fullCalendar({
            locale: i18n.language,
            navLinks: true,
            nowIndicator: true,
            header: {
                left: 'prev,next,today',
                center: 'title',
                right: 'timelineMonth',
            },
            buttonText: {
                month: t('calendar.month'),
            },
            firstDay: 1,
            timeFormat: 'HH:mm',
            views: {
                timelineMonth: {
                    slotDuration: '24:00:00',
                },
            },
            displayEventEnd: true,
            resourceOrder: 'position',
            resourceAreaWidth: () => {
                const resourceWidth = Cookies.get('calendarResourceWidth');
                return resourceWidth ? resourceWidth + 'px' : '15%';
            },
            contentHeight: () => window.innerHeight - 280,
            windowResize: () => {
                const width = $('.fc-time-area').width();
                if (width > 0) $('.tripdetailscontainer-calendar').css({ width: width });
            },
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //TODO: replace with commercial license key
            resourceGroupField: 'groupId',
            resources: this.props.availableBuses.entities.resources,
            events: (start, end, timezone, callback) => {
                callback(this.props.availableBuses.entities.events);
            },
            eventLimit: true,
            defaultView: 'timelineMonth',
            defaultDate: Cookies.get('tripCalendarCurrentDate') || null,
            editable: false,
            selectable: false,
            resourceColumns: [
                {
                    labelText: t('bus_yield.calculated_at'),
                    text: res => {
                        return res.title;
                    },
                    render: (res, el) => {
                        const field = $(el)[0];
                        const download = $('<span>')
                            .addClass('glyphicon')
                            .addClass('glyphicon-download-alt')
                            .attr('id', res.title);
                        download.data('date', res.title);
                        $(field).append(download);

                        download.on('click', el => {
                            const date = $(el.currentTarget).attr('id');
                            asyncRequest({
                                endpoint: `${TOMCAT_URL}api/bus-yield-requests/getDetailedInformationCsv/${date}`,
                                headers: { Accept: 'text/csv' },
                            })
                                .then(response => {
                                    response.blob().then(blob => {
                                        FileSaver.saveAs(blob, `stats_calculated_on_${date}.csv`);
                                    });
                                })
                                .catch(error => {
                                    console.error('download error', error);
                                });
                        });
                    },
                },
            ],
        });
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div ref={ref => (this.calendar = ref)} />
            </div>
        );
    }
}

export default BusYieldCalendar;
