import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors } from 'src/utils/constants';
import { deleteById, Entities, fetchAll, getEntities, patch, Projection } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import CompanyEditModal from 'src/components/company/company-edit-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Toggle from 'material-ui/Toggle';

class AdminTripCompanyAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            renderSelection: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.TRIP_COMPANY, 0, Projection.FULL);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { companies } = nextProps;

        if (!this.state.startedFetching && companies.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(companies.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.companies, this.props.companies, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { companies, t } = this.props;
        const finishFetching = this.state.startedFetching && !companies.isFetching;

        const tableData = [];
        if (finishFetching && companies.items.length > 0) {
            companies.items.map(company => {
                tableData.push({
                    id: company.id,
                    name: company.companyName,
                    address: company.contactData.address,
                    visible: company.visible,
                    bookableOnline: company.bookableOnline,
                    top: company.top,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('trip_operator.destinations')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CompanyEditModal,
                                componentProps: {
                                    isCreate: true,
                                    isAdmin: true,
                                    isTripCompany: true,
                                    readonly: true,
                                },
                                title: t('trip_operator.new_destination'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('trip_operator.new_destination')}
                    </button>
                </legend>

                {(() => {
                    if (companies.error) return <ErrorMessage object={companies} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign(
                                    {},
                                    this.tableHelper.getOptions(finishFetching, this.state.page, tableData.length),
                                )}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps(this.state.selected))}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                    {t('contact_data.address')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="visible"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={(cell, row) => <Toggle toggled={row['visible']} />}>
                                    {t('companies.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="bookableOnline"
                                    width="150"
                                    dataSort={true}
                                    dataFormat={(cell, row) => <Toggle toggled={row['bookableOnline']} />}>
                                    {t('bus.active_hint')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="top"
                                    width="80"
                                    dataSort={true}
                                    dataFormat={(cell, row) => <Toggle toggled={row['top']} />}>
                                    {t('trip_operator.top')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onProceedDeleteCompany(company) {
        this.props.deleteById(company.id, Entities.TRIP_COMPANY);
    }

    openConfirmDeleteDialog(company) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: company,
                onProceedDialog: this.onProceedDeleteCompany,
                bodyText: t('trip_operator.confirm_delete_destination'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: company.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('trip_operator.hint_delete_resource'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, company) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: CompanyEditModal,
                            componentProps: {
                                id: company.id,
                                isCreate: false,
                                isAdmin: true,
                                isTripCompany: true,
                                readonly: true,
                            },
                            title: company.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="btn btn-xs transparent"*/}
                {/*    title={t('user_functionality.delete')}*/}
                {/*    onClick={() =>*/}
                {/*        asyncRequest(`${TOMCAT_URL}api/${Entities.TRIP_COMPANY.repository}/${company.id}/deletable`)*/}
                {/*            .then(response => this.openConfirmDeleteDialog(company))*/}
                {/*            .catch(error => this.openCannotDeleteDialog(company))*/}
                {/*    }>*/}
                {/*    <span className="glyphicon glyphicon-trash text-danger" />*/}
                {/*</button>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        companies: getEntities(state, Entities.TRIP_COMPANY),
    };
};

export default connect(mapStateToProps, { fetchAll, deleteById, patch })(
    withTranslation()(composeModalActions(AdminTripCompanyAdministration)),
);
