import { TFunction } from 'i18next';
import React from 'react';
import { blue700 } from 'material-ui/styles/colors';
import CompanyIcon from 'material-ui/svg-icons/communication/business';
import ProductIcon from 'material-ui/svg-icons/action/credit-card';
import CalendarIcon from 'material-ui/svg-icons/action/alarm';
import { withTranslation } from 'react-i18next';
import Navigation, { ComponentRoutes } from 'src/components/nav/Navigation';

/**
 * Compose correct trip operator path
 */
const composeTripPath = (route: string) => `/trips/administration/${route}`;

/**
 * Defines all trip operator menu items. The order matters
 */
const tripRoutes = (t: TFunction): ComponentRoutes => [
    {
        path: composeTripPath('company'),
        icon: <CompanyIcon color={blue700} />,
        primaryText: t('products.my_destination'),
        secondaryText: t('companies.company_data'),
    },
    {
        path: composeTripPath('products'),
        icon: <ProductIcon color={blue700} />,
        primaryText: t('products.products'),
        secondaryText: t('products.tickets_and_more'),
    },
    {
        path: composeTripPath('seasons'),
        icon: <CalendarIcon color={blue700} />,
        primaryText: t('calculation.seasons'),
        secondaryText: t('calculation.opening_hours_pax'),
    },
];

/**
 * Renders the trip operator's  administration sidebar on the left hand side
 * and the related content on the right
 */
class TripNavigation extends React.Component<any, {}> {
    render() {
        const { t } = this.props;
        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <Navigation routes={tripRoutes(t)} />
                </div>
                <div className="col-md-10">{this.props.children}</div>
            </div>
        );
    }
}

export default withTranslation()(TripNavigation);
