import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities } from 'src/common/index';

import EventCategoryAdministration from 'src/components/admin/event-tickets/categories/event-category-administration';

const mapStateToProps = state => {
    return {
        eventCategories: getEntities(state, Entities.TICKET_EVENT_CATEGORY),
    };
};

const EventCategoryAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    deleteById,
})(EventCategoryAdministration);

export default EventCategoryAdministrationContainer;
