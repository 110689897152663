import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { asyncRequest, TOMCAT_URL } from 'src/common/index';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CustomPieChart from 'src/components/superadmin/charts/custom-pie-chart';
import CircularProgress from 'material-ui/CircularProgress';
import { withTranslation } from 'react-i18next';
import { priceFormatter } from 'src/utils/formatters';
import FileSaver from 'file-saver';

class Companies extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            entityCountsByCompanies: undefined,
            selectedRows: [],
        };
    }

    componentDidMount() {
        asyncRequest(`${TOMCAT_URL}super/statistics/companies-entity-counts`).then(response =>
            this.setState({
                entityCountsByCompanies: response.json,
            }),
        );
    }

    render() {
        const { t } = this.props;
        const entityCountsByCompanies = this.state.entityCountsByCompanies;

        let tableData = [];

        if (entityCountsByCompanies && entityCountsByCompanies.length > 0) {
            tableData = entityCountsByCompanies.map(entityCountsByCompany => {
                return {
                    id: entityCountsByCompany.companyId,
                    companyName: entityCountsByCompany.companyName,
                    sumPriceBookedTasks: entityCountsByCompany.sumPriceBookedTasks,
                    events: entityCountsByCompany.entityCounts.events.count,
                    drivers: getSubEntityCountsByEntity(entityCountsByCompany.entityCounts.accounts, 'drivers'),
                    bookings: getSubEntityCountsByEntity(entityCountsByCompany.entityCounts.events, 'booked tasks'),
                    provision: entityCountsByCompany.provision,
                    calculations: entityCountsByCompany.entityCounts.calculations.count,
                    buses: entityCountsByCompany.entityCounts.buses.count,
                };
            });
        }

        const selectRowProps = {
            mode: 'radio',
            hideSelectColumn: true,
            clickToSelect: true,
            bgColor: 'rgb(173, 216, 230)',
            onSelect: this.onRowSelect,
            selected: this.state.selectedRows,
        };

        return (
            <div>
                <legend className="legend">
                    {t('contact_data.company')}
                    <button
                        type="button"
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={this.downloadBusCompaniesCSV}>
                        {t('user_functionality.export_onboarding_csv')}
                    </button>
                </legend>

                <div id="companylist-panel">
                    <BootstrapTable
                        ref={ref => (this.table = ref)}
                        data={tableData}
                        exportCSV={true}
                        striped={true}
                        hover={true}
                        condensed={true}
                        pagination={true}
                        searchPlaceholder={t('common_phrases.search')}
                        options={{
                            sizePerPageList: [10, 50, 100, 500],
                            exportCSVText: t('common_phrases.export_list'),
                            noDataText: <CircularProgress />,
                            clearSearch: true,
                            sizePerPage: 50,
                            page: 1,
                        }}
                        selectRow={selectRowProps}
                        search={true}>
                        <TableHeaderColumn columnTitle={true} dataField="id" isKey={true} export={true} hidden={true}>
                            {t('common_phrases.id')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="companyName" dataSort={true}>
                            {t('contact_data.company')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="events" dataSort={true}>
                            {t('events.events')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="buses" dataSort={true}>
                            {t('companies.buses')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="drivers" dataSort={true}>
                            {t('administration.driver')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="bookings" dataSort={true}>
                            {t('booked_tasks.bookings')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="sumPriceBookedTasks"
                            dataFormat={priceFormatter}
                            dataAlign="center"
                            dataSort={true}>
                            {t('booked_tasks.booking_sales')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="provision"
                            dataFormat={priceFormatter}
                            dataAlign="center"
                            dataSort={true}>
                            {t('booked_tasks.provision')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} dataField="calculations" dataSort={true}>
                            {t('administration.calculation')}
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
                {this.state.selectedRows.length === 1 && this.renderSelectedCompanyCharts(this.state.selectedRows[0])}
            </div>
        );
    }

    renderSelectedCompanyCharts(companyId) {
        const index = this.state.entityCountsByCompanies.findIndex(company => company.companyId === companyId);

        if (index !== -1) {
            const companyEntityCounts = this.state.entityCountsByCompanies[index];

            return (
                <div className="row">
                    <div className="col-md-3">
                        {this.renderCompanyPieChart(
                            companyEntityCounts.entityCounts.events.subEntityCounts,
                            companyEntityCounts.entityCounts.events.count,
                        )}
                    </div>
                    <div className="col-md-3 col-md-offset-1">
                        {this.renderCompanyPieChart(
                            companyEntityCounts.entityCounts.buses.subEntityCounts,
                            companyEntityCounts.entityCounts.buses.count,
                        )}
                    </div>
                    <div className="col-md-3 col-md-offset-1">
                        {this.renderCompanyPieChart(
                            companyEntityCounts.entityCounts.accounts.subEntityCounts,
                            companyEntityCounts.entityCounts.accounts.count,
                        )}
                    </div>
                </div>
            );
        }
    }

    renderCompanyPieChart(data, sum) {
        return (
            <CustomPieChart
                data={data}
                sum={sum}
                cx={250}
                cy={110}
                innerRadius={60}
                outerRadius={75}
                width={500}
                height={250}
            />
        );
    }

    onRowSelect(row, isSelected) {
        this.setState({ selectedRows: [row.id] });
    }

    downloadBusCompaniesCSV() {
        asyncRequest({ endpoint: `${TOMCAT_URL}super/statistics/bus-companies.csv`, headers: { Accept: 'text/csv' } })
            .then(response => {
                response.blob().then(blob => {
                    FileSaver.saveAs(blob, 'bus-companies.csv');
                });
            })
            .catch(error => {
                console.error('Error downloading intermediates', error);
            });
    }
}

export default withTranslation()(Companies);

const getSubEntityCountsByEntity = (entities, subEntityName) => {
    if (entities.subEntityCounts.length > 0) {
        const subEntity = entities.subEntityCounts.find(subEntityCount => subEntityCount.name === subEntityName);
        return subEntity ? subEntity.value : 0;
    }

    return 0;
};
