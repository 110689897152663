import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { ButtonColors } from 'src/utils/constants';
import Stars from 'src/components/misc/stars';
import { withTranslation } from 'react-i18next';

const styles = {
    dialogRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
    },
    dialogContent: {
        position: 'relative',
        minHeight: '400px',
        width: '80vw',
        transform: '',
    },
    dialogBody: {
        paddingBottom: 0,
        overflowX: 'hidden',
    },
};

class ComponentDialog extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            alertDialog: {
                open: false,
            },
        };
    }

    handleOpenAlertDialog() {
        //if the form changed: ask whether to discard changes
        if (this.childComponent.getWrappedInstance().didFormChange())
            this.setState({
                alertDialog: {
                    open: true,
                },
            });
        //otherwise, just close
        else this.handleCancel();
    }

    handleCloseAlertDialog() {
        this.setState({
            alertDialog: {
                open: false,
            },
        });
    }

    // call the cancel callback and close
    handleCancel() {
        const { handleClose, cancelCallback } = this.props;
        if (cancelCallback) cancelCallback();
        handleClose();
    }

    render() {
        const {
            component,
            componentProps,
            title,
            open,
            handleClose,
            successButtonLabel,
            cancelButtonLabel,
            noButtons,
            singleButton,
            mandatoryFields,
            documents,
            formSubmit,
            t,
        } = this.props;

        const dialogTitle = (
            <div id="openModal">
                {componentProps.type === 'bus' && <Stars t={t} rating={componentProps.rating} className="pull-right" />}
                {title}
            </div>
        );

        const actions = [];

        if (singleButton) {
            actions.push(
                <FlatButton key="cancelButton" label={cancelButtonLabel} default={true} onClick={this.handleCancel} />,
            );
        } else if (!noButtons) {
            if (mandatoryFields) {
                actions.push(
                    <span key="mandatoryFieldsLabel" className="mandatoryFieldLabel">
                        * {t('mandatory_field')}
                    </span>,
                );
            }

            actions.push(
                <FlatButton
                    key="cancelButton"
                    label={cancelButtonLabel ? cancelButtonLabel : t('common_phrases.abort')}
                    default={true}
                    onClick={this.handleOpenAlertDialog}
                />,
                <RaisedButton
                    key="successButton"
                    label={successButtonLabel ? successButtonLabel : t('user_functionality.save')}
                    primary={true}
                    type="submit"
                    onClick={() => this.childComponent.getWrappedInstance().callSubmit()}
                    labelColor="#ffffff"
                    disabled={documents.uploading || formSubmit.disabled}
                />,
            );
        }

        const alertDialogButtons = [
            <FlatButton
                key="noButton"
                label={t('common_phrases.no')}
                default={true}
                onClick={this.handleCloseAlertDialog}
            />,
            <RaisedButton
                key="discardButton"
                label={t('user_functionality.discard')}
                onClick={this.handleCancel}
                backgroundColor={ButtonColors.DANGER}
                labelColor="#ffffff"
            />,
        ];

        // needs to be upper case, so that jsx recognizes it as a react component
        const Component = component;

        if (componentProps.dialogStyle) {
            styles.dialogContent.width = componentProps.dialogStyle.width;
            styles.dialogContent.maxWidth = componentProps.dialogStyle.maxWidth;
        } else {
            styles.dialogContent.width = '80vw';
            delete styles.dialogContent.maxWidth;
        }

        return (
            <Dialog
                title={dialogTitle}
                modal={true}
                open={open}
                autoScrollBodyContent={true}
                contentStyle={styles.dialogContent}
                bodyStyle={styles.dialogBody}
                style={styles.dialogRoot}
                repositionOnUpdate={false}
                actions={actions}>
                <Component
                    {...componentProps}
                    t={t}
                    ref={ref => (this.childComponent = ref)}
                    handleClose={handleClose}
                />
                {this.state.alertDialog.open && (
                    <Dialog actions={alertDialogButtons} modal={true} open={this.state.alertDialog.open}>
                        {t('confirm_discard_changes')}
                    </Dialog>
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
        formSubmit: state.formSubmit,
    };
};

export default connect(mapStateToProps)(withTranslation()(ComponentDialog));
