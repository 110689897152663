import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ErrorMessage extends Component {
    render() {
        const { t, object } = this.props;

        return (
            <div className="alert alert-danger">
                <h2>{t('error', { error: object.error.status })}</h2>
                <h4>
                    {t('error_message', {
                        message: object.error.statusText,
                        interpolation: { escapeValue: false },
                    })}
                </h4>
            </div>
        );
    }
}

export default withTranslation()(ErrorMessage);
