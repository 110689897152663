import { connect } from 'react-redux';

import { patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { getOwnCompany } from 'src/selectors/bus-companies';
import CompanyAdministration from 'src/components/administration/company-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        company: getOwnCompany(state),
    };
};

const CompanyAdministrationContainer = connect(mapStateToProps, { fetchOwnBusCompany, patch })(CompanyAdministration);

export default CompanyAdministrationContainer;
