import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import createLogger from 'redux-logger';
import reduxReset from 'redux-reset';
import rootReducer from 'src/reducers/root-reducer';

export default function configureStore(history, initialState) {
    let middleware = [apiMiddleware, thunkMiddleware, routerMiddleware(history)];

    // only use redux-logger in dev environment
    if (process.env.NODE_ENV !== 'production') {
        const reduxLogger = createLogger({
            collapsed: true,
            //don't log third-party actions:
            predicate: (getState, action) =>
                action.type &&
                !action.type.startsWith('@@redux-form') &&
                !action.type.startsWith('@react-redux-modal') &&
                !action.type.startsWith('@@router'),
            colors: {
                title: () => 'inherit',
                prevState: () => '#9E9E9E',
                action: () => '#03A9F4',
                nextState: () => '#4CAF50',
                error: () => '#F20404',
            },
        });
        middleware = [...middleware, reduxLogger];
    }

    let composed = compose(applyMiddleware(...middleware), reduxReset());

    // https://github.com/zalmoxisus/redux-devtools-extension#usage
    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        composed = compose(composed, window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(rootReducer, initialState, composed);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/root-reducer', () => {
            const nextRootReducer = require('../reducers/root-reducer').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
