const interpolate = (start: number, end: number, steps: number, count: number) => {
    return Math.floor(start + ((end - start) / steps) * count);
};

class Color {
    public r: number;
    public g: number;
    public b: number;

    constructor(r: number, g: number, b: number) {
        this.r = r;
        this.g = g;
        this.b = b;
    }
}

export const getInterpolatedColorByValue = (value: number) => {
    value = 100 - value;

    const red = new Color(255, 0, 0);
    const green = new Color(6, 130, 60);
    const orange = new Color(255, 100, 0);

    let start = green,
        end = orange;
    if (value > 50) {
        start = orange;
        end = red;
        value = value % 51;
    }

    const r = interpolate(start.r, end.r, 40, value);
    const g = interpolate(start.g, end.g, 40, value);
    const b = interpolate(start.b, end.b, 40, value);

    return 'rgb(' + r + ',' + g + ',' + b + ')';
};
