import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { getMenuItemsByArray } from 'src/utils/helpers';
import { renderInput, renderRichTextEditor, renderSelectField } from 'src/components/misc/redux-form-helpers';

class HelpEditForm extends Component {
    render() {
        const { handleSubmit, isCreate, helpCategories, t } = this.props;
        const categoryMenuItems = getMenuItemsByArray(helpCategories.items, 'name');

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="category" label={t('common_phrases.category')} component={renderSelectField}>
                            {categoryMenuItems}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="question"
                            label={`${t('help.question')} *`}
                            component={renderInput}
                            multiline={true}
                            rows={3}
                            rowsMax={3}
                        />
                    </div>
                </div>
                <div className="row voffset">
                    <div className="col-md-12">
                        <label>{t('help.answer')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="answer"
                            placeholder={t('help.answer')}
                            isCreate={isCreate}
                            component={renderRichTextEditor}
                            t={t}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!values.question) errors.question = t('help.fill_in_question');

    return errors;
};

export default reduxForm({
    form: 'helpEditForm',
    validate,
})(HelpEditForm);
