import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { Entities, patch, Projection, search } from 'src/common/index';
import {
    getTripCalendarEvents,
    tripCalendarResources,
    TripEventResource,
    TripEventType,
} from 'src/selectors/trip-calendar-events/trip-calendar-events';
import _ from 'lodash';
import { setCalendarDateRange } from 'src/actions/calendar';
import moment from 'moment';
import { selectEvent } from 'src/actions/events';
import { openBlockageModal } from 'src/components/trips/administration/blockage/TripBlockageForm';
import { addAvailabilityEvents } from 'src/selectors/trip-calendar-events/availability-events';

class TripCalendar extends Component {
    constructor(props) {
        super(props);

        this.events = [];
    }

    componentDidMount() {
        this.populateCalendar();
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const calendar = this.calendar;
        const { events } = nextProps;

        if (
            events.action !== 'REQUEST' &&
            events.action !== 'FAILURE' &&
            events.action !== 'NONE' &&
            !_.isEqual(this.props.events, events)
        ) {
            if (events.action === 'FETCH') {
                this.events = events.items;
            } else if (events.action === 'SAVE') {
                this.events = addAvailabilityEvents(this.events.slice().concat(events.updatedEvent));
                nextProps.selectEvent(events.updatedEvent);
            } else if (events.action === 'UPDATE') {
                const existingIndex = this.events.findIndex(event => event.id === events.updatedEvent.id);
                if (existingIndex !== -1 && events.updatedEvent) {
                    const existingEvents = this.events.slice();
                    existingEvents[existingIndex] = events.updatedEvent;
                    this.events = addAvailabilityEvents(existingEvents);
                    nextProps.selectEvent(events.updatedEvent);
                }
            } else if (events.action === 'DELETE') {
                const existingEvents = this.events.slice();
                _.remove(existingEvents, event => event.id === events.deletedEvent);
                this.events = addAvailabilityEvents(existingEvents);
                nextProps.selectEvent(null);
            }

            console.log('event action: ', events.action, this.events);
            $(calendar).fullCalendar('refetchEvents');
        }
    }

    componentWillUnmount() {
        // destroy full calendar
        $(this.calendar).fullCalendar('destroy');

        // clear selected event
        this.props.selectEvent(null);
    }

    render() {
        return <div ref={ref => (this.calendar = ref)} />;
    }

    populateCalendar() {
        const { selectedCompany, t, i18n } = this.props;
        const calendar = this.calendar;
        let resourceIdDragStart;

        $(calendar).fullCalendar({
            locale: i18n.language,
            navLinks: true,
            nowIndicator: true,
            header: {
                left: 'prev,next,today',
                center: 'title',
                right: 'timelineDay,timelineWeek,timelineMonth,listWeek, settings',
            },
            buttonText: {
                today: t('calendar.today'),
                day: t('calendar.day'),
                week: t('calendar.week'),
                month: t('calendar.month'),
                list: t('calendar.list'),
            },
            firstDay: 1,
            slotDuration: '00:15:00', // the default
            timeFormat: 'HH:mm',
            views: {
                timelineDay: {
                    slotDuration: '01:00:00',
                    snapDuration: '00:15:00',
                },
                timelineWeek: {
                    slotDuration: '24:00:00',
                },
                timelineMonth: {
                    slotDuration: '24:00:00',
                },
            },
            displayEventEnd: true,
            resourceOrder: 'position',
            resourceAreaWidth: () => {
                const resourceWidth = Cookies.get('calendarResourceWidth');
                return resourceWidth ? resourceWidth + 'px' : '15%';
            },
            contentHeight: () => window.innerHeight - 280,
            windowResize: () => {
                const width = $('.fc-time-area').width();
                if (width > 0) $('.tripdetailscontainer-calendar').css({ width: width });
            },
            resourceColumns: [
                {
                    group: true,
                    field: 'groups',
                    render: function (resource, el) {
                        resource.css({
                            '-ms-transform': 'rotate(-90deg)',
                            transform: 'rotate(-90deg)',
                            overflow: 'visible',
                            visibility: 'visible',
                            margin: '0px 0 0 0',
                        });
                        resource.addClass('header_group');
                    },
                },
                {
                    field: 'title',
                },
            ],
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', //TODO: replace with commercial license key
            resourceGroupField: 'groupId',
            resources: tripCalendarResources(t),
            events: (start, end, timezone, callback) => {
                callback(this.events);
            },
            eventLimit: true,
            defaultView: Cookies.get('tripCalendarDefaultView') || 'timelineWeek',
            defaultDate: Cookies.get('tripCalendarCurrentDate') || null,
            editable: true,
            selectable: true,
            select: (start, end, jsEvent, view, resource) => {
                if (resource.id !== TripEventResource.BOOKING) return;

                end.subtract(1, 'minutes');
                openBlockageModal({
                    t,
                    openModal: this.props.openModal,
                    start: start,
                    end: end,
                    selectedCompany: selectedCompany,
                });
            },
            eventClick: (event, jsEvent) => {
                this.props.selectEvent(event);
            },
            eventResize: (event, delta, revertFunc) => {
                if (event.type === TripEventType.BLOCKAGE) this.handlePatchEventTimeRange(event, revertFunc);
                else revertFunc();
            },
            eventDragStart: event => {
                resourceIdDragStart = event.resourceId;
            },
            eventDrop: (event, delta, revertFunc) => {
                if (
                    event.type === TripEventType.BLOCKAGE &&
                    event.resourceId === TripEventResource.BOOKING &&
                    resourceIdDragStart === event.resourceId
                )
                    this.handlePatchEventTimeRange(event, revertFunc);
                else revertFunc();
            },
            eventRender: (event, eventElement, view) => {
                if (event.type === TripEventType.BLOCKAGE) {
                    // open edit blockage modal on double click
                    eventElement.bind('dblclick', () => {
                        openBlockageModal({
                            t,
                            openModal: this.props.openModal,
                            existingBlockage: event.entity,
                            selectedCompany: selectedCompany,
                        });
                    });
                }

                // event title as a tooltip
                eventElement.attr('title', event.title);
                eventElement.attr('id', `event${event.id}`);
            },
            resourceRender: (resourceObj, labelTds, bodyTds) => {},
            viewRender: (view, element) => {
                this.props.search(
                    Entities.SEASON,
                    `findSeasonBetweenPeriodFromAndPeriodTill?periodFrom=${view.start.format(
                        'YYYY-MM-DD',
                    )}&periodTill=${view.end.format('YYYY-MM-DD')}&companyId=${selectedCompany}`,
                    selectedCompany,
                );

                this.props.search(
                    Entities.TRIP_BLOCKAGE,
                    `findBetweenPeriodFromAndPeriodTill?periodFrom=${view.start.format(
                        'YYYY-MM-DD[T]HH:mm',
                    )}&periodTill=${view.end.format('YYYY-MM-DD[T]HH:mm')}&companyId=${selectedCompany}`,
                    selectedCompany,
                );
                this.props.search(
                    Entities.TRIP_BOOKING,
                    `findBetweenPeriodFromAndPeriodTill?periodFrom=${view.start.format(
                        'YYYY-MM-DD[T]HH:mm',
                    )}&periodTill=${view.end.format('YYYY-MM-DD[T]HH:mm')}&companyId=${selectedCompany}`,
                    selectedCompany,
                    Projection.BOOKING,
                );
                this.props.setCalendarDateRange(view.start, view.end);
                this.props.selectEvent(null);

                Cookies.set('tripCalendarDefaultView', view.name, {
                    expires: 10 * 365,
                    secure: true,
                    sameSite: 'none',
                });
                Cookies.set('tripCalendarCurrentDate', view.intervalStart.format(), { secure: true, sameSite: 'none' });
            },
        });
    }

    /**
     * Patches the given event with the new date period
     */
    handlePatchEventTimeRange(event, revertFunc) {
        const { selectedCompany } = this.props;

        const blockagePatch = {
            id: event.id,
            from: moment(event.start).format('YYYY-MM-DD[T]HH:mm'),
            to: moment(event.end).format('YYYY-MM-DD[T]HH:mm'),
        };

        // TODO: consider bookings in future
        this.props.patch(blockagePatch, Entities.TRIP_BLOCKAGE, selectedCompany);
    }
}

const mapStateToProps = state => {
    const selectedCompany = state.selectedCompany;

    return {
        events: getTripCalendarEvents(state),
        selectedCompany,
    };
};

export default connect(mapStateToProps, {
    search,
    setCalendarDateRange,
    patch,
    selectEvent,
})(TripCalendar);
