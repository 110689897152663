import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import IntegrationAdministration from 'src/components/admin/integration/booking/integration-administration';

const mapStateToProps = state => {
    return {
        integrations: getEntities(state, Entities.BOOKING_INTEGRATION),
    };
};

const IntegrationAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    patch,
    push,
})(IntegrationAdministration);

export default IntegrationAdministrationContainer;
