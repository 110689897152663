import { connect } from 'react-redux';
import { addErrorNotification } from 'src/actions/notifications';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminSchoolAdministration from 'src/components/admin/schools/admin-school-administration';

const mapStateToProps = state => {
    return {
        schools: getEntities(state, Entities.SCHOOL),
    };
};

const AdminSchoolAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    patch,
    addErrorNotification,
})(AdminSchoolAdministration);

export default AdminSchoolAdministrationContainer;
