import { createSelector } from 'reselect';
import _ from 'lodash';
import { defaultState, Entities, getEntities } from 'src/common/index';
import { getContingentEvents } from 'src/selectors/trip-calendar-events/contingent-events';
import { createBlockageEvent, createBlockageEvents } from 'src/selectors/trip-calendar-events/blockage-events';
import { getAvailabilityEvents } from 'src/selectors/trip-calendar-events/availability-events';
import { createBookingEvent, createBookingEvents } from 'src/selectors/trip-calendar-events/booking-events';

/**
 * Describes all available Trip Calendar Resource Types
 */
export const TripEventResource = {
    BOOKING: 'BOOKING',
    CONTINGENT: 'CONTINGENT',
    AVAILABILITY: 'AVAILABILITY',
};

/**
 * Describes all available Trip Calendar Event Types
 */
export const TripEventType = {
    BLOCKAGE: 'BLOCKAGE',
    BOOKING: 'BOOKING',
    CONTINGENT: 'CONTINGENT',
    AVAILABILITY: 'AVAILABILITY',
};

/**
 * An array of trip resources for populating the calendar
 */
export const tripCalendarResources = t => [
    {
        id: TripEventResource.CONTINGENT,
        title: t('trip_calendar_resources.contingent'),
    },
    {
        id: TripEventResource.BOOKING,
        title: t('trip_calendar_resources.bookings'),
    },
    {
        id: TripEventResource.AVAILABILITY,
        title: t('trip_calendar_resources.availability'),
    },
];

const getStateOfTripBlockagesByCompany = state =>
    state[Entities.TRIP_BLOCKAGE.reducer][state.selectedCompany] || defaultState();
const getStateOfTripBookingsByCompany = state =>
    state[Entities.TRIP_BOOKING.reducer][state.selectedCompany] || defaultState();
const getTripBlockageEntities = state => state.entities[Entities.TRIP_BLOCKAGE.repository] || [];
const getTripBookingEntities = state => state.entities[Entities.TRIP_BOOKING.repository] || [];
const getSeasons = state => getEntities(state, Entities.SEASON, state.selectedCompany);
const getCalendarDateRange = state => state.calendarDateRange;

/**
 * Generates events for the trip calendar.
 * The result is an object with the shape of:
 *
 * {
    isFetching: true/false,
    error: any,
    action: FETCH/SAVE/UPDATE/DELETE,
   }
 *
 * and an additional property holding the content of the event, depending on the current action type:
 *
 *  - FETCH:            all events will be generated and returned under the property: "items"
 *  - SAVE / UPDATE:    the new/updated event will be returned under the property: "updatedEvent"
 *  - DELETE:           the id of the deleted event will be returned under the property: "deletedEvent"
 */
export const getTripCalendarEvents = createSelector(
    [
        getSeasons,
        getStateOfTripBlockagesByCompany,
        getTripBlockageEntities,
        getStateOfTripBookingsByCompany,
        getTripBookingEntities,
        getCalendarDateRange,
    ],
    (seasons, stateOfBlockages, blockageEntities, stateOfBookings, bookingEntities, calendarDateRange) => {
        if (
            seasons.action.type === 'REQUEST' &&
            stateOfBlockages.action.type === 'REQUEST' &&
            stateOfBookings.action.type === 'REQUEST'
        )
            return {
                isFetching: true,
                error: undefined,
                action: 'REQUEST',
                items: [],
            };

        if (
            seasons.action.type === 'FETCH' &&
            stateOfBlockages.action.type === 'FETCH' &&
            stateOfBookings.action.type === 'FETCH'
        ) {
            const blockages = _.filter(blockageEntities, blockage =>
                stateOfBlockages.action.payload.includes(blockage.id),
            );
            const bookings = _.filter(bookingEntities, booking => stateOfBookings.action.payload.includes(booking.id));

            const blockageEvents = createBlockageEvents(blockages);
            const bookingEvents = createBookingEvents(bookings);
            const contingentEvents = getContingentEvents(seasons.items, calendarDateRange);
            const availabilityEvents = getAvailabilityEvents(blockageEvents.concat(bookingEvents), contingentEvents);

            return {
                isFetching: false,
                error: undefined,
                action: 'FETCH',
                items: blockageEvents.concat(bookingEvents).concat(contingentEvents).concat(availabilityEvents),
            };
        }

        // determine current processing event type
        let processedEventType = TripEventType.BOOKING;
        if (
            stateOfBlockages.lastUpdated &&
            stateOfBookings.lastUpdated &&
            stateOfBlockages.lastUpdated > stateOfBookings.lastUpdated
        ) {
            processedEventType = TripEventType.BLOCKAGE;
        }

        // process booking
        if (processedEventType === TripEventType.BOOKING) {
            if (['SAVE', 'UPDATE'].includes(stateOfBookings.action.type)) {
                return {
                    isFetching: false,
                    error: undefined,
                    action: stateOfBookings.action.type,
                    updatedEvent: createBookingEvent(bookingEntities[stateOfBookings.action.payload]),
                };
            }
        }

        // process blockage
        if (processedEventType === TripEventType.BLOCKAGE) {
            if (['SAVE', 'UPDATE'].includes(stateOfBlockages.action.type, stateOfBookings.action.type)) {
                return {
                    isFetching: false,
                    error: undefined,
                    action: stateOfBlockages.action.type,
                    updatedEvent: createBlockageEvent(blockageEntities[stateOfBlockages.action.payload]),
                };
            }

            if (stateOfBlockages.action.type === 'DELETE') {
                return {
                    isFetching: false,
                    error: undefined,
                    action: stateOfBlockages.action.type,
                    deletedEvent: stateOfBlockages.action.payload,
                };
            }
        }

        return {
            isFetching: false,
            error: undefined,
            action: 'NONE',
            items: [],
        };
    },
);
