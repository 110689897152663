import { createSelector } from 'reselect';
import { defaultState, Entities, UserRole } from 'src/common/index';
import { OWN_REDUCER_INDEX } from 'src/actions/accounts';
import { isLoggedInWithRole } from 'src/components/account/login';
import store from 'src/store/store';

const getStateOfOwnAccount = state => state.accounts[OWN_REDUCER_INDEX] || defaultState();
const getAccountEntities = state => state.entities[Entities.ACCOUNT.repository];

export const getOwnAccount = createSelector([getStateOfOwnAccount, getAccountEntities], (state, entities) => {
    return {
        isFetching: state.isFetching,
        lastUpdated: state.lastUpdated,
        error: state.error,
        action: state.action,
        entity: state.result && state.result.length === 1 && !state.isFetching ? entities[state.result[0]] : undefined,
    };
});

export const isLoggedInAsEvoAdmin = () => {
    const ownAccount = getOwnAccount(store.getState());
    return isLoggedInWithRole(ownAccount, [UserRole.SUPER_ADMIN]) && ownAccount.entity.email === 'evoadmin';
};
