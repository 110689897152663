import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class EngineEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="rank"
                            label={`${t('engines.environmental_factor')} *`}
                            hintText={t('engines.environmental_factor_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.rank) errors.rank = t('error_missing.fill_in_factor');
    if (!isInteger(values.rank)) errors.rank = t('error_hint.error_integer');
    if (values.rank < 1 || values.rank > 10) errors.rank = t('error_hint.error_factor');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.BUS_ENGINE.repository}/search/findByName?name=${encodeURIComponent(values.name)}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'engineEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(EngineEditForm);
