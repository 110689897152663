import RaisedButton from 'material-ui/RaisedButton';
import React from 'react';
import { Entities, Patch, Projection, SanitizedInnerHTML } from 'src/common/index';
import { InjectedTranslateProps } from 'src/types';
import { ButtonColors } from 'src/utils/constants';

interface Props extends Patch, InjectedTranslateProps {
    task: any;
    handleClose?: () => void;
    closeDrawer?: () => void;
}

const AdminSelectedBookedTaskDetails: React.FC<Props> = props => {
    const { handleClose, closeDrawer, patch, task, t } = props;

    const close = () => {
        if (closeDrawer) closeDrawer();
        if (handleClose) handleClose();
    };

    const confirmChangeBookingToPaymentOnInvoice = (preserveCashback: boolean) => {
        patch(
            {
                id: task.id,
                preserveCashback,
            },
            Entities.TASK,
            0,
            Projection.FULL,
            undefined,
            'changeBookingToPaymentOnInvoice',
        );
        close();
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12 voffset">
                    <SanitizedInnerHTML html={t('booked_tasks.admin_pay_on_invoice.info_text')} />

                    <div className="alert alert-warning">
                        <p>{t('booked_tasks.admin_pay_on_invoice.cannot_undo')}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 voffset">
                    <RaisedButton onClick={close} label={t('common_phrases.abort')} />
                    <div className="form-group pull-right">
                        <RaisedButton
                            onClick={() => confirmChangeBookingToPaymentOnInvoice(false)}
                            label={t('booked_tasks.admin_pay_on_invoice.preserveCashback_no')}
                            backgroundColor={ButtonColors.NOTIFY}
                            labelColor="#ffffff"
                            className="confirm-dialog-2ndButton"
                        />
                        <RaisedButton
                            onClick={() => confirmChangeBookingToPaymentOnInvoice(true)}
                            label={t('booked_tasks.admin_pay_on_invoice.preserveCashback_yes')}
                            backgroundColor={ButtonColors.NOTIFY}
                            className="pull-right"
                            labelColor="#ffffff"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSelectedBookedTaskDetails;
