import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

class TaskDetails extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { task, onSpecificExchange, onGlobalExchange, t } = this.props;

        return (
            <div className="details task-exchange-modal">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <table className="table table-details">
                            <thead>
                                <tr>
                                    <th width="5%">{t('task_details.pax')}</th>
                                    <th width="5%">{t('task_details.price')}</th>
                                    <th width="15%">{t('booked_tasks.customer')}</th>
                                    <th width="17.5%">{t('booked_tasks.from')}</th>
                                    <th width="17.5%">{t('booked_tasks.to')}</th>
                                    <th width="25%">{t('task_details.routes')}</th>
                                    <th width="15%">{t('common_phrases.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <p>{task.passengers}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{task.price}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{t('task_details.company_club')}</p>
                                            <p>{t('task_details.contact_person')}</p>
                                            <p>{t('contact_data.tel_number')}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{moment(task.from.time).format()}</p>
                                            <p>{task.from.location.address}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{moment(task.to.time).format()}</p>
                                            <p>{task.to.location.address}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>
                                                {moment(task.taskFrom.time).format()} {task.taskFrom.location.address}
                                            </p>
                                            {task.intermediates && task.intermediates.length > 0
                                                ? task.intermediates.map(function (value, key) {
                                                      return (
                                                          <p key={key}>
                                                              {moment(value.time).format()} {value.location.address}
                                                          </p>
                                                      );
                                                  })
                                                : null}
                                            <p>
                                                {moment(task.taskTo.time).format()} {task.taskTo.location.address}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>
                                                <button
                                                    type="button"
                                                    className="btn btn-xs transparent"
                                                    onClick={() => onGlobalExchange()}>
                                                    <span className="glyphicon glyphicon-globe" />
                                                    &nbsp;{t('search.global_release')}
                                                </button>
                                            </p>
                                            <p>
                                                <button
                                                    type="button"
                                                    className="btn btn-xs transparent"
                                                    onClick={() => onSpecificExchange()}>
                                                    <span className="glyphicon glyphicon-user" />
                                                    &nbsp;{t('task_details.search_company')}
                                                </button>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaskDetails;
