import { asyncRequest, Entities, isIntegerPercent, isOneDecimalPlace, TOMCAT_URL } from 'src/common/index';
import HttpStatus from 'http-status-codes';
import { CircularProgress, Tab, Tabs } from 'material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SwipeableViews from 'react-swipeable-views';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { setSliderIndex } from 'src/actions/form';
import {
    asyncCheckDuplicate,
    countryMenuItemsForCalculation,
    renderCheckbox,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';

class CountryCalculationEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isFetching: false,
        };
    }

    componentDidMount() {
        this.props.setSliderIndex(0);
    }

    render() {
        const { handleSubmit, t, selectedSliderIndex } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                    <Tab label={t('calculation.surcharge_title')} value={1} />
                    <Tab label={t('calculation.vat_title')} value={2} />
                </Tabs>
                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    <div className="view">
                        <div id="topSection" className="row">
                            <div className="col-md-6">
                                <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                            </div>
                        </div>
                    </div>
                    <div className="view">
                        <FieldArray
                            name="countrySurcharges"
                            buttonLabel={`+ ${t('calculation.surcharge')}`}
                            t={t}
                            component={renderCountrySurcharges}
                        />
                    </div>
                    <div className="view">
                        {!this.state.isFetching ? (
                            <FieldArray
                                name="countryVatRates"
                                buttonLabel={`+ ${t('calculation.vat')}`}
                                t={t}
                                component={renderCountryVatRates}
                                props={{
                                    fetchDefaultVatRates: this.fetchDefaultVatRates,
                                    setFetchingFinished: this.setFetchingFinished,
                                }}
                            />
                        ) : (
                            <CircularProgress />
                        )}
                    </div>
                </SwipeableViews>
            </form>
        );
    }

    async fetchDefaultVatRates() {
        const { t } = this.props;
        this.setState({ isFetching: true });

        try {
            const res = await asyncRequest(`${TOMCAT_URL}api/country-calculations/vat-rates`);

            if (res.status === HttpStatus.NO_CONTENT) {
                this.props.addNotification(t('common_phrases.error'), t('calculation.error_vat_fetch'), 'error', 'tr');
                this.setFetchingFinished();
                return [];
            }

            return res.json;
        } catch (error) {
            this.props.addNotification(t('common_phrases.error'), t('error_occurred'), 'error', 'tr');
            return [];
        }
    }

    setFetchingFinished() {
        this.setState({ isFetching: false });
    }
}

const renderCountrySurcharges = ({ fields, buttonLabel, t }) => (
    <div>
        <div className="row">
            <div className="col-md-6">
                <RaisedButton
                    secondary={true}
                    label={buttonLabel}
                    onClick={() =>
                        fields.push({
                            country: undefined,
                            surcharge: 0,
                            forbidTransit: false,
                            forbidDestination: false,
                        })
                    }
                />
            </div>
        </div>

        {fields.map((field, index) => (
            <div key={index}>
                <div className="row">
                    <div className="col-md-5">
                        <Field name={`${field}.country`} label="Land *" component={renderSelectField}>
                            {countryMenuItemsForCalculation()}
                        </Field>
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={`${field}.surcharge`}
                            label={`${t('calculation.surcharge')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                    <div className="col-md-2 voffset">
                        <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${field}.forbidTransit`}
                            label={t('calculation.forbidTransit')}
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-7">
                        <Field
                            name={`${field}.forbidDestination`}
                            label={t('calculation.forbidDestination')}
                            component={renderCheckbox}
                        />
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const renderCountryVatRates = ({ fields, buttonLabel, t, fetchDefaultVatRates, setFetchingFinished }) => {
    const getVatRates = async () => {
        const vatRatesObj = await fetchDefaultVatRates();
        return transformVatRatesObjToArray(vatRatesObj);
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <RaisedButton
                        secondary={true}
                        label={buttonLabel}
                        onClick={() =>
                            fields.push({
                                country: undefined,
                                vat: 0,
                            })
                        }
                    />
                </div>
                <div className="col-md-6">
                    <RaisedButton
                        primary={true}
                        className="pull-right"
                        label={t('calculation.import_vat_rates')}
                        onClick={async () => {
                            const vatRates = await getVatRates();

                            fields.removeAll();
                            vatRates.forEach(vatRate => fields.push(vatRate));

                            setFetchingFinished();
                        }}
                    />
                </div>
            </div>

            {fields.map((field, index) => {
                return (
                    <div key={index}>
                        <div className="row">
                            <div className="col-md-5">
                                <Field name={`${field}.country`} label="Land *" component={renderSelectField}>
                                    {countryMenuItemsForCalculation()}
                                </Field>
                            </div>
                            <div className="col-md-5">
                                <Field
                                    name={`${field}.vat`}
                                    label={`${t('calculation.vat')} *`}
                                    component={renderInput}
                                    type="number"
                                />
                            </div>
                            <div className="col-md-2 voffset">
                                <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                                    <span className="glyphicon glyphicon-trash" />
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const transformVatRatesObjToArray = vatRatesObj => {
    const vatRatesArray = [];

    for (const country in vatRatesObj) {
        if (vatRatesObj[country]) {
            vatRatesArray.push({ country, vat: vatRatesObj[country] });
        }
    }

    return vatRatesArray;
};

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (values.countrySurcharges)
        errors.countrySurcharges = validateCountryFields({
            fields: values.countrySurcharges,
            valueKey: 'surcharge',
            t,
        });

    if (values.countryVatRates)
        errors.countryVatRates = validateCountryFields({
            fields: values.countryVatRates,
            valueKey: 'vat',
            t,
        });

    return errors;
};

/**
 * Validate country related fields such as surcharges and VAT.
 */
const validateCountryFields = ({ fields, valueKey, t }) => {
    const errors = [];

    fields.forEach((field, index) => {
        const error = {
            country: undefined,
            [valueKey]: undefined,
        };

        if (!field.country) {
            error.country = t('calculation.fill_in_country');
            errors[index] = error;
        } else if (field[valueKey] == null) {
            error[valueKey] = t(`calculation.fill_in_${valueKey}`);
            errors[index] = error;
        } else if (valueKey === 'surcharge' && !isIntegerPercent(field[valueKey])) {
            error[valueKey] = t('calculation.fill_in_surcharge_criteria');
            errors[index] = error;
        } else if (valueKey === 'vat' && !isOneDecimalPlace(field[valueKey])) {
            // VAT can be a float as well, e.g. Lichtenstein has 7.7% VAT
            error[valueKey] = t('error_missing.fill_in_one_decimal_place_percent');
            errors[index] = error;
        }
    });

    if (errors.length > 0) return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${
            Entities.COUNTRY_CALCULATION.repository
        }/search/findByNameAndCompanyId?name=${encodeURIComponent(values.name)}&companyId=${props.selectedCompany}`,
        values.id,
        'name',
    );
};

const onSubmitFail = (errors, dispatch) => {
    if (errors.name) {
        dispatch(setSliderIndex(0));
    } else if (errors.countrySurcharges) {
        dispatch(setSliderIndex(1));
    } else if (errors.countryVatRates) {
        dispatch(setSliderIndex(2));
    } else {
        dispatch(setSliderIndex(0));
    }
};

export default reduxForm({
    form: 'countryCalculationEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
    returnRejectedSubmitPromise: true,
})(CountryCalculationEditForm);
