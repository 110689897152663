import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { asyncCheckDuplicate, renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import {
    Entities,
    getEntityLinkById,
    isInteger,
    isMoneyAmount,
    setMoneyCurrency,
    TOMCAT_URL,
    validatePrice,
} from 'src/common/index';
import { Tab, Tabs } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import RaisedButton from 'material-ui/RaisedButton';
import { scrollToAnchor } from 'src/utils/helpers';
import { setSliderIndex } from 'src/actions/form';
import { isBlank } from 'src/components/misc/validations';

class ProductForm extends Component {
    render() {
        const { handleSubmit, selectedSliderIndex, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                    <Tab label={t('products.progression_of_prices')} value={1} />
                </Tabs>
                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    {/* General Tab */}
                    <div className="view">
                        <div className="row">
                            <div className="col-md-6">
                                <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                            </div>
                            <div className="col-md-6">
                                <Field name="subtitle" label={`${t('companies.subtitle')} *`} component={renderInput} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="freeCompanionPerPax"
                                    label={`${t('products.free_companion')} *`}
                                    type="number"
                                    component={renderInput}
                                />
                            </div>
                            <div className="col-md-6 voffset">
                                <Field
                                    name="schoolOffer"
                                    label={t('products.offer_school')}
                                    component={renderCheckbox}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Graduation of Price Tab */}
                    <div className="view">
                        <FieldArray name="paxPrices" t={t} component={renderPaxPrices} />
                    </div>
                </SwipeableViews>
            </form>
        );
    }
}

export const renderPaxPrices = ({ fields, t }) => (
    <div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('products.new_price_scale')}`}
                        onClick={() =>
                            fields.push({
                                fromPax: 1,
                                price: 0,
                            })
                        }
                    />
                </div>
            </div>
        </div>

        {fields.map((paxPrice, index) => (
            <div key={index} className="row">
                <div className="col-md-5">
                    <div className="form-group">
                        <Field
                            name={`${paxPrice}.fromPax`}
                            label={`${t('products.from_pax')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name={`${paxPrice}.price.amount`}
                            label={`${t('products.price_pax')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="col-md-1 voffset">
                    {fields.length > 1 && (
                        <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    )}
                </div>
            </div>
        ))}
    </div>
);

export const validatePaxPrices = (paxPrices, t) => {
    const errors = [];
    const seen = new Set();
    paxPrices.forEach((paxPrice, index) => {
        const error = {
            fromPax: undefined,
            price: {
                amount: undefined,
            },
        };

        if (!paxPrice.fromPax || paxPrice.fromPax <= 0) {
            error.fromPax = t('products.fill_in_from_pax');
            errors[index] = error;
        } else if (seen.has(paxPrice.fromPax)) {
            error.fromPax = t('error_hint.error_unique');
            errors[index] = error;
        } else if (!isMoneyAmount(paxPrice.price)) {
            error.price.amount = t('products.fill_in_price_pax');
            errors[index] = error;
        } else if (validatePrice(paxPrice.price.amount)) {
            error.price.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
            errors[index] = error;
        }

        seen.add(paxPrice.fromPax);
    });

    if (errors.length > 0) return errors;
};

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (isBlank(values.subtitle)) errors.subtitle = t('companies.fill_in_subtitle');

    if (!isInteger(values.freeCompanionPerPax)) errors.freeCompanionPerPax = t('error_hint.error_integer');

    if (values.freeCompanionPerPax && values.freeCompanionPerPax < 0)
        errors.freeCompanionPerPax = t('products.error_positive');

    if (values.paxPrices) errors.paxPrices = validatePaxPrices(values.paxPrices, t);

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.PRODUCT.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
            values.name,
        )}&companyId=${props.companyId}`,
        values.id,
        'name',
    );
};

const onSubmit = (data, ownProps) => {
    Object.keys(data.paxPrices).forEach(key => {
        setMoneyCurrency(data.paxPrices[key].price, 'EUR');
    });
    ownProps.onSubmit(data);
};

const onSubmitFail = (errors, dispatch) => {
    if (errors.paxPrices) dispatch(setSliderIndex(1));
    else {
        scrollToAnchor('topSection');
        dispatch(setSliderIndex(0));
    }
};

ProductForm = reduxForm({
    form: 'ProductForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(ProductForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, product, companyId } = ownProps;

    const companyLink = getEntityLinkById(Entities.TRIP_COMPANY, companyId);

    let initialValues;

    if (!isCreate && product)
        initialValues = {
            id: product.id,
            name: product.name,
            subtitle: product.subtitle,
            visible: product.visible,
            freeCompanionPerPax: product.freeCompanionPerPax,
            schoolOffer: product.schoolOffer,
            paxPrices: product.paxPrices,
            company: companyLink,
        };
    else
        initialValues = {
            paxPrices: [{ fromPax: 1, price: undefined }],
            company: companyLink,
        };

    return {
        initialValues: initialValues,
        onSubmit: data => onSubmit(data, ownProps),
    };
};

ProductForm = connect(mapStateToProps, null, null, { withRef: true })(ProductForm);

export default ProductForm;
