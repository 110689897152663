import { arrayOf, Schema } from 'normalizr';
import { Entities } from 'src/common/index';

// create a new normalizr schema for each entity
export default function createEntitySchemas() {
    Object.keys(Entities).forEach(key => (Entities[key].schema = new Schema(Entities[key].repository)));

    Entities.ACCOUNT.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.SUPER_ADMIN.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.ADMIN.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.OPERATOR.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.TRIP_OPERATOR.schema.define({
        company: Entities.TRIP_COMPANY.schema,
    });

    Entities.DISPATCHER.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.DRIVER.schema.define({
        company: Entities.BUS_COMPANY.schema,
        regularBuses: arrayOf(Entities.BUS.schema),
        firstDriverTasks: arrayOf(Entities.TASK.schema),
        secondDriverTasks: arrayOf(Entities.TASK.schema),
    });

    Entities.CUSTOMER.schema.define({
        company: Entities.BUS_COMPANY.schema,
        //customerGroup is not normalized
    });

    Entities.BUS.schema.define({
        company: Entities.BUS_COMPANY.schema,
        brand: Entities.BUS_BRAND.schema,
        category: Entities.BUS_CATEGORY.schema,
        engine: Entities.BUS_ENGINE.schema,
        equipments: arrayOf(Entities.BUS_EQUIPMENT.schema),
        calculation: Entities.CALCULATION.schema,
        regularDriver: Entities.DRIVER.schema,
    });

    Entities.BUS_CATEGORY.schema.define({
        company: Entities.BUS_COMPANY.schema,
    });

    Entities.CALCULATION.schema.define({
        buses: arrayOf(Entities.BUS.schema),
        seasonCalculation: Entities.SEASON_CALCULATION.schema,
        countryCalculation: Entities.COUNTRY_CALCULATION.schema,
    });

    Entities.TASK.schema.define({
        bus: Entities.BUS.schema,
        firstDriver: Entities.DRIVER.schema,
        secondDriver: Entities.DRIVER.schema,
        review: Entities.REVIEW.schema,
        //customer is not normalized
    });

    Entities.RELEASE.schema.define({
        bus: Entities.BUS.schema,
    });

    Entities.BLOCKAGE.schema.define({
        bus: Entities.BUS.schema,
    });

    Entities.HELP.schema.define({
        category: Entities.HELP_CATEGORY.schema,
    });

    Entities.TRIP_COMPANY.schema.define({
        attributes: arrayOf(Entities.TRIP_ATTRIBUTE.schema),
        categories: arrayOf(Entities.TRIP_CATEGORY.schema),
    });
}
