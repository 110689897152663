import { Entities, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export function searchRatioCustomers(keyword) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.RATIO_CUSTOMER.repository}/search?keyword=${keyword}`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${Entities.RATIO_CUSTOMER.action}`,
                    `SUCCESS_FETCH_${Entities.RATIO_CUSTOMER.action}`,
                    `FAILURE_${Entities.RATIO_CUSTOMER.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.RATIO_CUSTOMER.schema,
            }),
        );
    };
}
