import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchAll, getEntities, patch, TOMCAT_URL } from 'src/common/index';
import { disableFormSubmit } from 'src/actions/form-submit';
import AddCompaniesForm from 'src/components/admin/integration/booking/add-companies-form';
import CircularProgress from 'material-ui/CircularProgress';

class AddCompaniesModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.BUS_COMPANY);
    }

    componentWillReceiveProps(nextProps) {
        const { busCompanies } = nextProps;

        if (!this.state.startedFetching && busCompanies.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return true;
    }

    render() {
        const { integration, busCompanies, t } = this.props;

        const finishFetching = this.state.startedFetching && !busCompanies.isFetching;

        if (finishFetching) {
            const initialValues = {
                id: integration.id,
                subCompanies: integration.subCompanies.map(subCompany => subCompany.id),
            };

            return (
                <div>
                    <AddCompaniesForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        busCompanies={busCompanies.items}
                        ownCompanyId={integration.company.id}
                        disableFormSubmit={this.props.disableFormSubmit}
                        t={t}
                        subCompanies={integration.subCompanies.map(subCompany => {
                            return {
                                id: subCompany.id,
                                label: subCompany.companyName,
                            };
                        })}
                        ref={ref => (this.childForm = ref)}
                    />
                </div>
            );
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, closeDrawer } = this.props;

        // map entities to bus company href links in order to save the relation in the backend
        data.subCompanies = data.subCompanies.map(
            subCompany => `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${subCompany}`,
        );

        this.props.patch(data, Entities.BOOKING_INTEGRATION);

        if (closeDrawer) closeDrawer();
        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchAll,
        patch,
        disableFormSubmit,
    },
    null,
    { withRef: true },
)(AddCompaniesModal);
