import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import BrandEditForm from 'src/components/admin/brands/brand-edit-form';

class BrandEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.BUS_BRAND);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { brand } = nextProps;

        if (!this.state.startedFetching && brand.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { brand, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !brand.isFetching;

        if (finishFetching) {
            // check for errors
            if (brand.error) return <ErrorMessage object={brand} />;
            // if everything is ok populate form
            else {
                let initialValues = {};

                // populate form for editing an existing brand
                if (!isCreate && brand.content)
                    initialValues = {
                        id: brand.content.id,
                        name: brand.content.name,
                        rating: brand.content.rating || 0,
                    };

                return (
                    <BrandEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        brand={brand.content}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose } = this.props;

        if (!isCreate) this.props.patch(data, Entities.BUS_BRAND);
        else this.props.save(data, Entities.BUS_BRAND);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        brand: getUpdatedEntity(state, Entities.BUS_BRAND),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(BrandEditModal);
