import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { deleteById, Entities, fetchById, getEntities, getUpdatedEntity } from 'src/common/index';

import { fetchTicketCollectionsByEventId } from 'src/actions/event-tickets';

import TicketCollectionAdministration from 'src/components/admin/event-tickets/ticket-collections/ticket-collection-administration';

const mapStateToProps = state => {
    return {
        ticketCollections: getEntities(state, Entities.TICKET_COLLECTION),
        event: getUpdatedEntity(state, Entities.TICKET_EVENT),
    };
};

const TicketCollectionAdministrationContainer = connect(mapStateToProps, {
    fetchTicketCollectionsByEventId,
    fetchById,
    deleteById,
    push,
})(TicketCollectionAdministration);

export default TicketCollectionAdministrationContainer;
