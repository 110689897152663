import Money from './entity/basic-types/Money';
import { TOMCAT_URL } from './index';

export const scrollToAnchor = (anchor: string): void => {
    const url = location.href; //Save down the URL without hash.
    location.href = '#' + anchor; //Go to the target element.
    history.replaceState(null, '', url); //Don't like hashes. Changing it back.
};

export const dashIfNull = (value?: any, suffix?: any): any => {
    if (!value) return '-';
    else if (suffix) return `${value} ${suffix}`;
    else return value;
};

export const formatMoney = (value?: { amount: number; currency: string }): any => {
    if (!value) return '-';
    else return Money.fromJson(value).toString();
};

export const checkCookies = () => {
    document.cookie = 'cookie_test=1;secure;SameSite=None';
    const result = document.cookie.indexOf('cookie_test=') !== -1;
    // Delete cookie
    document.cookie = 'cookie_test=1;secure;SameSite=None;expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return result;
};

export const validatePrice = (value: any): boolean => {
    return !/^[0-9]\d{0,5}(\.\d{1,2})?$/i.test(value);
};

const regInteger = /^-?\d+$/;
const regOneDecimalPlace = /^\d+(?:\.\d)?$/;
const regTwoDecimalPlaces = /^\d+(?:\.\d\d?)?$/;

export const isInteger = (value: any): boolean => regInteger.test(value);
export const isOneDecimalPlace = (value: any): boolean => regOneDecimalPlace.test(value);
export const isTwoDecimalPlaces = (value: any): boolean => regTwoDecimalPlaces.test(value);

export const isMoneyAmount = (value: any): boolean => value != null && value.amount != null && value.amount !== '';

export const setMoneyCurrency = (value: any, currency: string) => {
    if (isMoneyAmount(value)) value.currency = currency;
    else value = null;
};

/**
 * Check whether the value is an integer between -100 and 1000.
 */
export const isIntegerPercent = (value: any, values: any, props: any) =>
    value == null || (isInteger(value) && Number(value) >= -100 && Number(value) <= 1000);

/**
 * Check whether the value is an integer between 0 and 1000.
 */
export const isNonNegativeIntegerPercent = (value: any, values: any, props: any) =>
    value == null || (isInteger(value) && Number(value) >= 0 && Number(value) <= 1000);

/**
 * Check whether the value is an integer or float between 0 and 100, with at most one decimal place.
 */
export const isPercentOneDecimalPlace = (value: any) =>
    value == null || (isOneDecimalPlace(value) && Number(value) >= 0 && Number(value) <= 100);

/**
 * Check whether the value is an integer or float between 0 and 100, with at most two decimal places.
 */
export const isPercentTwoDecimalPlaces = (value: any) =>
    value == null || (isTwoDecimalPlaces(value) && Number(value) >= 0 && Number(value) <= 100);

export const getEntityLinkById = (entity: any, id: number) => {
    if (!id || !entity) return null;
    else return `${TOMCAT_URL}api/${entity.repository}/${id}`;
};

export const getFileNameFromResponse = (response: Response): string | undefined => {
    const disposition = response.headers.get('Content-Disposition');

    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }

    return undefined;
};
