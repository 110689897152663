import TextField from 'material-ui/TextField';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

class DateTimePicker extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        const field = this.props;

        $(this.textField.input).datetimepicker({
            ...field.input,
            onChangeDateTime: value => {
                if (field.input.onChange)
                    field.input.onChange(value ? moment(value, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : null);
            },
            format: 'DD.MM.YYYY HH:mm',
            value: field.input.value ? moment(field.input.value, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : null,
            formatDate: 'DD.MM.YYYY',
            formatTime: 'HH:mm',
            minDate: field.minDate && moment(field.minDate).format('DD.MM.YYYY'),
            maxDate: field.maxDate && moment(field.maxDate).format('DD.MM.YYYY'),
            step: 30,
            mask: '39.19.2999 29:59',
            dayOfWeekStart: 1, // start with Monday
        });
    }

    componentWillUnmount() {
        $(this.textField.input).datetimepicker('destroy');
    }

    render() {
        const field = this.props;

        return (
            <TextField
                {...field.input}
                id={field.name}
                type="text"
                ref={ref => (this.textField = ref)}
                floatingLabelText={field.label}
                floatingLabelFixed={true}
                errorText={field.meta.error}
            />
        );
    }
}

export default DateTimePicker;
