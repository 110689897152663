import Badge from 'material-ui/Badge';
import IconButton from 'material-ui/IconButton';
import NotificationsIcon from 'material-ui/svg-icons/social/notifications';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import { IndexLink, Link } from 'react-router';
import _ from 'lodash';
import { asyncRequest, Entities, TOMCAT_URL, UserRole } from 'src/common/index';
import { ButtonColors } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import Logout from 'src/components/account/logout';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import TaskNews from 'src/components/misc/task-news';
import logo from 'static/Busfinder_Pin_240.png';
import { TripBookingFilter } from 'src/components/trips/bookings/booking-administration-filter';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import HttpStatus from 'http-status-codes';

class Header extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.didNewTasksFetch = false;
    }

    componentWillReceiveProps(nextProps) {
        const { ownAccount, busCompany } = nextProps;

        if (
            this.isLoggedInWithRole(ownAccount, [UserRole.OPERATOR, UserRole.DISPATCHER]) &&
            !this.didNewTasksFetch &&
            ownAccount.entity.previousLogin
        ) {
            const previousLogin = moment(ownAccount.entity.previousLogin).format('YYYY-MM-DD HH:mm:ss');
            nextProps.fetchNewTasksSinceLastLogin(ownAccount.entity.company, previousLogin);
            nextProps.fetchByCompanyId(Entities.BUS, busCompany.entity.id);
            this.didNewTasksFetch = true;
        } else if (!this.isLoggedIn(ownAccount) && this.didNewTasksFetch) this.didNewTasksFetch = false;
    }

    render() {
        const { t, ownAccount, busCompany, notFound } = this.props;

        return (
            <nav className="navbar navbar-transparent">
                <div>
                    {/* Home Image */}
                    {(notFound || this.isLoggedIn(ownAccount)) && (
                        <div className="navbar-header">
                            <IndexLink
                                className="navbar-brand"
                                style={{
                                    paddingBottom: 0,
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    paddingTop: 5,
                                }}
                                to="/">
                                <img src={`${logo}`} width="40" alt="busfinder-Logo" />
                            </IndexLink>
                        </div>
                    )}

                    {/* Operator / Dispatcher Links */}
                    {this.isLoggedInWithRole(ownAccount, [UserRole.OPERATOR, UserRole.DISPATCHER]) &&
                        this.renderOperatorDispatcherLinks(ownAccount, busCompany.entity.partner, t)}

                    {/* Admin Links */}
                    {this.isLoggedInWithRole(ownAccount, [UserRole.ADMIN]) && this.renderLinks(this.getAdminLinks(t))}

                    {/* Super Admin Links */}
                    {this.isLoggedInWithRole(ownAccount, [UserRole.SUPER_ADMIN]) &&
                        this.renderLinks(this.getSuperAdminLinks(ownAccount, t))}

                    {this.isLoggedInWithRole(ownAccount, [UserRole.TRIP_OPERATOR]) &&
                        this.renderLinks(this.getTripOperatorLinks(t))}

                    {/* Notifications, Account, Logout */}
                    {this.isLoggedIn(ownAccount) && this.renderRightNavSection(ownAccount)}

                    {notFound && !this.isLoggedIn(ownAccount) && (
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/login" className="nav-link" activeClassName="active">
                                    <span className="glyphicon glyphicon-user" />
                                    &nbsp;
                                    {t('header_login')}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>
            </nav>
        );
    }

    renderOperatorDispatcherLinks(ownAccount, isPartner, t) {
        if (isPartner)
            return (
                <ul className="nav navbar-nav">
                    {this.isLoggedInWithRole(ownAccount, [UserRole.OPERATOR]) && (
                        <li>
                            <Link to="/administration" className="nav-link" activeClassName="active">
                                <span className="glyphicon glyphicon-briefcase" />
                                &nbsp;
                                {t('header_administration')}
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link to="/operating-tool" className="nav-link" activeClassName="active">
                            <span className="glyphicon glyphicon-calendar" />
                            &nbsp;
                            {t('header_operating_tool')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/task-administration" className="nav-link" activeClassName="active">
                            <span className="glyphicon glyphicon-list-alt" />
                            &nbsp;
                            {t('header_task_administration')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/task-exchange" className="nav-link" activeClassName="active">
                            <span className="glyphicon glyphicon-list-alt" />
                            &nbsp;
                            {t('header_task_exchange')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/offer-administration" className="nav-link" activeClassName="active">
                            <span className="glyphicon glyphicon-list-alt" />
                            &nbsp;
                            {t('header_offer_administration')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/help" className="nav-link" activeClassName="active">
                            <span className="glyphicon glyphicon-info-sign" />
                            &nbsp;{t('help.help')}
                        </Link>
                    </li>
                </ul>
            );

        return this.renderLinks(this.getNonEBAPartnerLinks(t));
    }

    renderRightNavSection(ownAccount) {
        const { newTasksSinceLastLogin, busCompany, t } = this.props;

        return (
            <ul className="nav navbar-nav navbar-right">
                {ownAccount.entity.fleet && <li>{this.getFleetInformation(busCompany.entity, ownAccount.entity)}</li>}
                <li>
                    {this.isLoggedInWithRole(ownAccount, [UserRole.OPERATOR, UserRole.DISPATCHER]) &&
                        busCompany.entity.partner &&
                        newTasksSinceLastLogin.action.type === 'FETCH' && (
                            <Badge
                                className="nav-link notification-badge"
                                badgeContent={newTasksSinceLastLogin.count}
                                primary={newTasksSinceLastLogin.count > 0}
                                secondary={newTasksSinceLastLogin.count === 0}
                                style={{ padding: 0 }}
                                badgeStyle={{ top: 8, right: 26 }}>
                                <IconButton
                                    style={{ paddingRight: '60px' }}
                                    tooltip="Notifications"
                                    onClick={() => {
                                        this.props.openModal({
                                            component: ConfirmDialog,
                                            componentProps: {
                                                bodyText: <TaskNews t={t} />,
                                                proceedText: t('common_phrases.ok'),
                                                proceedButtonColor: ButtonColors.NOTIFY,
                                                isNotification: true,
                                            },
                                            title: t('news_since_last_login'),
                                            noButtons: true,
                                        });
                                    }}>
                                    <NotificationsIcon />
                                </IconButton>
                            </Badge>
                        )}
                </li>
                <li>
                    <Link to="/account" className="nav-link" activeClassName="active">
                        <span className="glyphicon glyphicon-user" />
                        &nbsp;
                        {ownAccount.entity.contactData.firstName}
                        {!ownAccount.entity.fleet && this.getCompanyInformation(busCompany)}
                    </Link>
                </li>

                <li>
                    <Logout />
                </li>
            </ul>
        );
    }

    renderLinks(links) {
        return (
            <ul className="nav navbar-nav">
                {links.map((link, index) => (
                    <li key={index}>
                        <Link to={link.path} className="nav-link" activeClassName="active">
                            <span className={link.icon} />
                            &nbsp;
                            {link.label}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }

    getFleetInformation(busCompany, account) {
        const fleet = account.fleet;

        const companyMenuItems = fleet.companies
            .filter(company => company.active)
            .map(company => {
                return <MenuItem key={company.id} value={company.id} primaryText={company.companyName} />;
            });

        return (
            <SelectField
                floatingLabelStyle={{ top: 28 }}
                menuStyle={{ marginTop: 5, width: '300px' }}
                underlineStyle={{ display: 'none' }}
                value={busCompany.id}
                onChange={(event, index, value) => {
                    asyncRequest({
                        method: 'PATCH',
                        endpoint: `${TOMCAT_URL}api/accounts/changeCompany?companyId=${value}`,
                    })
                        .then(response => {
                            if (response.status === HttpStatus.OK) window.location.reload();
                            else console.log('error changing company', response.status);
                        })
                        .catch(error => console.log('error changing company', error));
                }}
                floatingLabelText={fleet.name}
                fullWidth={true}
                selectionRenderer={value => {
                    if (busCompany.logo)
                        return (
                            <div>
                                {busCompany.companyName}
                                &nbsp;
                                <img
                                    src={`${TOMCAT_URL}public/documents/${busCompany.logo.filename}`}
                                    width="42"
                                    height="18"
                                    alt={`${busCompany.companyName} Logo`}
                                />
                            </div>
                        );

                    return <div>{busCompany.companyName}</div>;
                }}>
                {companyMenuItems}
            </SelectField>
        );
    }

    getCompanyInformation(busCompany) {
        if (busCompany && busCompany.entity && busCompany.entity.companyName) {
            if (busCompany.entity.logo)
                return (
                    <span>
                        &nbsp;|&nbsp;
                        {busCompany.entity.companyName}
                        &nbsp;
                        <img
                            src={`${TOMCAT_URL}public/documents/${busCompany.entity.logo.filename}`}
                            width="42"
                            height="18"
                            alt={`${busCompany.entity.companyName} Logo`}
                        />
                    </span>
                );
            else
                return (
                    <span>
                        &nbsp;|&nbsp;
                        {busCompany.entity.companyName}
                    </span>
                );
        }

        return '';
    }

    getTripOperatorLinks(t) {
        return [
            {
                path: '/trips/administration',
                label: t('header_administration'),
                icon: 'glyphicon glyphicon-briefcase',
            },
            {
                path: `/trips/bookings?filter=${TripBookingFilter.FUTURE}`,
                label: t('booked_tasks.bookings'),
                icon: 'glyphicon glyphicon-flag',
            },
            {
                path: '/trips/calendar',
                label: t('calendar.calendar'),
                icon: 'glyphicon glyphicon-calendar',
            },
        ];
    }

    getAdminLinks(t) {
        return [
            {
                path: '/admin',
                label: t('header_administration'),
                icon: 'glyphicon glyphicon-briefcase',
            },
            {
                path: '/admin/trips',
                label: t('az_administration'),
                icon: 'glyphicon glyphicon-flag',
            },
            {
                path: '/admin/event-tickets',
                label: t('ticket.event_tickets'),
                icon: 'glyphicon glyphicon-tags',
            },
        ];
    }

    getSuperAdminLinks(ownAccount, t) {
        if (ownAccount.entity.email === 'evoadmin')
            return [
                {
                    path: '/admin',
                    label: t('header_administration'),
                    icon: 'glyphicon glyphicon-briefcase',
                },
            ];

        return this.getAdminLinks(t).concat([
            {
                path: '/superadmin',
                label: t('header_superadmin'),
                icon: 'fa fa-user-plus',
            },
        ]);
    }

    getNonEBAPartnerLinks(t) {
        return [
            {
                path: '/event-tickets',
                label: t('ticket.event_tickets'),
                icon: 'glyphicon glyphicon-tags',
            },
        ];
    }

    isLoggedIn(account) {
        return account && !account.isFetching && account.entity;
    }

    isLoggedInWithRole(account, roles) {
        return this.isLoggedIn(account) && _.includes(roles, account.entity.role);
    }
}

export default composeModalActions(withTranslation()(Header));
