import { Projection } from 'src/common/index';
import TextField from 'material-ui/TextField';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

class ReadOnlyTextField extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <TextField
                {...this.props}
                value={this.props.value || ''}
                floatingLabelText={this.props.label}
                floatingLabelFixed={true}
                type="text"
                fullWidth={true}
                disabled={true}
                style={{ cursor: Projection.DEFAULT }}
                inputStyle={{ cursor: Projection.DEFAULT, color: 'black' }}
                underlineDisabledStyle={{ cursor: Projection.DEFAULT }}
            />
        );
    }
}

export default ReadOnlyTextField;
