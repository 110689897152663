import { createSelector } from 'reselect';
import _ from 'lodash';

import { getAllFilteredTasks } from 'src/selectors/tasks';
import { EventType } from 'src/utils/constants';
import { getSelectedCompany } from 'src/selectors/bus-companies';
import {
    getBlockageEvent,
    getBlockageEvents,
    getReleaseEvent,
    getReleaseEvents,
    getTaskEvent,
} from 'src/selectors/calendar-helpers';
import { defaultState, Entities } from 'src/common/index';

const getStateOfReleasesByCompany = state => state[Entities.RELEASE.reducer][state.selectedCompany] || defaultState();
const getStateOfBlockagesByCompany = state => state[Entities.BLOCKAGE.reducer][state.selectedCompany] || defaultState();

export const getReleaseEntities = state => state.entities[Entities.RELEASE.repository] || [];
export const getBlockageEntities = state => state.entities[Entities.BLOCKAGE.repository] || [];

const getReleasesByCompany = createSelector(
    [getStateOfReleasesByCompany, getReleaseEntities],
    (releasesByCompany, releases) => {
        let releaseEntities = [];

        if (releasesByCompany.action.type !== 'REQUEST') {
            if (releasesByCompany.action.type === 'UPDATE' || releasesByCompany.action.type === 'SAVE') {
                releaseEntities.push(releases[releasesByCompany.action.payload]);
            } else if (releasesByCompany.action.type === 'DELETE') {
                releaseEntities.push(releasesByCompany.action.payload);
            } else if (releasesByCompany.action.type === 'FETCH') {
                releaseEntities = _.filter(releases, release => releasesByCompany.action.payload.includes(release.id));
            }

            return {
                isFetching: releasesByCompany.isFetching,
                lastUpdated: releasesByCompany.lastUpdated,
                error: releasesByCompany.error,
                action: releasesByCompany.action,
                items: releaseEntities,
            };
        }
    },
);

const getBlockagesByCompany = createSelector(
    [getStateOfBlockagesByCompany, getBlockageEntities],
    (blockagesByCompany, blockages) => {
        let blockageEntities = [];

        if (blockagesByCompany.action.type !== 'REQUEST') {
            if (blockagesByCompany.action.type === 'UPDATE' || blockagesByCompany.action.type === 'SAVE') {
                blockageEntities.push(blockages[blockagesByCompany.action.payload]);
            } else if (blockagesByCompany.action.type === 'DELETE') {
                blockageEntities.push(blockagesByCompany.action.payload);
            } else if (blockagesByCompany.action.type === 'FETCH') {
                blockageEntities = _.filter(blockages, blockage =>
                    blockagesByCompany.action.payload.includes(blockage.id),
                );
            }

            return {
                isFetching: blockagesByCompany.isFetching,
                lastUpdated: blockagesByCompany.lastUpdated,
                error: blockagesByCompany.error,
                action: blockagesByCompany.action,
                items: blockageEntities,
            };
        }
    },
);

export const getEvents = createSelector(
    [getAllFilteredTasks, getReleasesByCompany, getBlockagesByCompany, getSelectedCompany],
    (allFilteredTasks, releases, blockages, selectedCompany) => {
        let events = [];

        // calendar is only interested in actions != REQUEST
        if (
            allFilteredTasks &&
            allFilteredTasks.action &&
            allFilteredTasks.action.type !== 'REQUEST' &&
            releases &&
            releases.action.type !== 'REQUEST' &&
            blockages &&
            blockages.action.type !== 'REQUEST'
        ) {
            // update is not related to change of company settings (such as change task type colors)
            if (!allFilteredTasks.didCompanySettingsUpdate) {
                // CHECK WHICH TYPE OF EVENT PROCESSED THE ACTION
                let processedEventType = EventType.TASK;
                if (releases.lastUpdated && blockages.lastUpdated && allFilteredTasks.lastUpdated) {
                    if (
                        releases.lastUpdated > blockages.lastUpdated &&
                        releases.lastUpdated > allFilteredTasks.lastUpdated
                    )
                        processedEventType = EventType.RELEASE;
                    else if (
                        blockages.lastUpdated > releases.lastUpdated &&
                        blockages.lastUpdated > allFilteredTasks.lastUpdated
                    )
                        processedEventType = EventType.BLOCKAGE;
                }

                // CHECK FAILURES
                if (allFilteredTasks.action.type === 'FAILURE') {
                    return {
                        action: allFilteredTasks.action.type,
                        error: allFilteredTasks.error,
                    };
                }
                if (releases.action.type === 'FAILURE')
                    return {
                        action: releases.action.type,
                        error: releases.error,
                    };
                if (blockages.action.type === 'FAILURE')
                    return {
                        action: blockages.action.type,
                        error: blockages.error,
                    };

                // PROCESS SAVE/UPDATE/DELETE OPERATIONS
                if (processedEventType === EventType.TASK) {
                    if (allFilteredTasks.action.type === 'UPDATE' || allFilteredTasks.action.type === 'SAVE') {
                        if (allFilteredTasks.items[0].calendarProps)
                            return {
                                updatedEvent: getTaskEvent(allFilteredTasks.items[0]),
                                action: allFilteredTasks.action.type,
                            };
                        else
                            return {
                                deletedEvent: allFilteredTasks.items[0].id,
                                action: 'DELETE',
                            };
                    }

                    if (allFilteredTasks.action.type === 'DELETE')
                        return {
                            deletedEvent: allFilteredTasks.items[0],
                            action: allFilteredTasks.action.type,
                        };
                }
                if (processedEventType === EventType.RELEASE) {
                    if (releases.action.type === 'UPDATE' || releases.action.type === 'SAVE')
                        return {
                            updatedEvent: getReleaseEvent(releases.items[0]),
                            action: releases.action.type,
                        };

                    if (releases.action.type === 'DELETE')
                        return {
                            deletedEvent: releases.items[0],
                            action: releases.action.type,
                        };
                }
                if (processedEventType === EventType.BLOCKAGE) {
                    if (blockages.action.type === 'UPDATE' || blockages.action.type === 'SAVE')
                        return {
                            updatedEvent: getBlockageEvent(blockages.items[0]),
                            action: blockages.action.type,
                        };

                    if (blockages.action.type === 'DELETE')
                        return {
                            deletedEvent: blockages.items[0],
                            action: blockages.action.type,
                        };
                }
            }

            // PROCESS FETCH OPERATIONS
            if (
                allFilteredTasks.didCompanySettingsUpdate ||
                (allFilteredTasks.action.type === 'FETCH' &&
                    releases.action.type === 'FETCH' &&
                    blockages.action.type === 'FETCH')
            ) {
                if (allFilteredTasks.items && allFilteredTasks.items.length > 0)
                    allFilteredTasks.items
                        .filter(task => {
                            return !(
                                task.exchangedTasks &&
                                task.exchangedTasks.length > 0 &&
                                task.exchangedTasks[0].foreignCompanyDetails.id === -1 &&
                                task.exchangedTasks[0].ownCompanyDetails.id !== selectedCompany
                            );
                        })
                        .forEach(task => {
                            if (task.calendarProps) events.push(getTaskEvent(task));
                        });

                Array.prototype.push.apply(events, getBlockageEvents(blockages.items));
                Array.prototype.push.apply(events, getReleaseEvents(releases.items));

                return {
                    action: 'FETCH',
                    items: events,
                };
            }
        }
    },
);
