import React from 'react';

export const ErrorBoundaryHandler = ({ componentStack, error }: { componentStack?: string; error?: Error }) => (
    <div style={{ padding: '1em' }}>
        <div>Error caught by error boundary</div>
        <hr />
        <div>
            {error != null && (
                <>
                    <p>
                        {error.name}: {error.message}
                    </p>
                    <p style={{ whiteSpace: 'pre-line' }}>{error.stack}</p>
                </>
            )}
            {componentStack != null && (
                <>
                    <hr />
                    <p style={{ whiteSpace: 'pre-line' }}>{componentStack}</p>
                </>
            )}
        </div>
    </div>
);
