export const FORM_SUBMIT_DISABLE = 'FORM_SUBMIT_DISABLE';

export function disableFormSubmit(disabled) {
    return {
        type: FORM_SUBMIT_DISABLE,
        payload: {
            disabled: disabled,
        },
    };
}
