import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
    asyncRequest,
    Entities,
    fetchById,
    getFileNameFromResponse,
    getUpdatedEntity,
    patch,
    TOMCAT_URL,
} from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import ErrorMessage from 'src/components/misc/error-message';
import PrintOfferForm from 'src/components/taskadministration/print-offer-form';
import FileSaver from 'file-saver';

class PrintOfferModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany } = this.props;

        this.props.fetchById(id, Entities.TASK, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { task } = nextProps;

        if (!this.state.startedFetching && task.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    async downloadOfferPDF(bookingToken) {
        const { t, addNotification } = this.props;
        try {
            const response = await asyncRequest({
                endpoint: `${TOMCAT_URL}public/generateOperatorOfferPDF/?token=${bookingToken}`,
                headers: { Accept: 'application/pdf' },
            });

            const blob = await response.blob();
            FileSaver.saveAs(blob, getFileNameFromResponse(response));
        } catch (error) {
            addNotification(t('common_phrases.error'), t('error_occurred'), 'error', 'tr');
        }
    }

    render() {
        const { task, t } = this.props;

        const finishFetching = this.state.startedFetching && !task.isFetching;

        if (finishFetching) {
            if (task.error) return <ErrorMessage object={task} />;
            else {
                const initialValues = {
                    id: task.content.id,
                    customerEmail: task.content.customerContactData
                        ? task.content.customerContactData.email
                        : undefined,
                    printNotes: task.content.printNotes,
                    sendMail: false,
                };

                return (
                    <PrintOfferForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        customerContactData={task.content.customerContactData}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    async handleSubmit(formData) {
        const { handleClose, id, selectedCompany, task, addNotification, t } = this.props;
        const bookingToken = task.content.offerDetails.bookingToken;

        const data = {
            id: formData.id,
            printNotes: formData.printNotes,
        };

        this.props.patch(data, Entities.TASK, selectedCompany).then(async () => {
            addNotification(t('common_phrases.loading'), t('print.offer_downloading'), 'info', 'tr');
            await this.downloadOfferPDF(bookingToken);

            addNotification(t('common_phrases.success'), t('print.offer_downloaded'), 'success', 'tr');
        });

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        task: getUpdatedEntity(state, Entities.TASK, state.selectedCompany),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(mapStateToProps, { fetchById, patch, addNotification }, null, { withRef: true })(
    PrintOfferModal,
);
