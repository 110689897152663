import React, { Component } from 'react';
import { openBlockageModal } from 'src/components/trips/administration/blockage/TripBlockageForm';
import { ButtonColors } from 'src/utils/constants';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { Entities } from 'src/common/index';
import { formatMinutes } from 'src/components/misc/redux-form-helpers';
import moment from 'moment';

class BlockageDetails extends Component {
    render() {
        const { event, selectedCompany, t } = this.props;

        return (
            <table className="table table-details">
                <thead style={{ backgroundColor: event.color }}>
                    <tr
                        style={{
                            //white semitransparent overlay to match calendar event colors
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        }}>
                        <th>{t('events.blockage')}</th>
                        <th>{t('booked_tasks.from')}</th>
                        <th>{t('booked_tasks.to')}</th>
                        <th>{t('calendar.duration')}</th>
                        <th>{t('task_details.pax')}</th>
                        <th>{t('common_phrases.actions')}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <p>{event.entity.name}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.start.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.end.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{formatMinutes(t, moment.duration(event.end.diff(event.start)).asMinutes())}</p>
                        </td>
                        <td>
                            <p>{event.entity.fullBlock ? t('calendar.full_blockage') : event.entity.reducedPax}</p>
                        </td>
                        <td>
                            <p>
                                <button
                                    type="button"
                                    className="btn btn-xs transparent"
                                    onClick={() =>
                                        openBlockageModal({
                                            t: t,
                                            openModal: this.props.openModal,
                                            existingBlockage: event.entity,
                                            selectedCompany: selectedCompany,
                                        })
                                    }>
                                    <span className="glyphicon glyphicon-pencil text-warning" />
                                    &nbsp; {t('user_functionality.edit')}
                                </button>
                            </p>
                            <p>
                                <button
                                    type="button"
                                    className="btn btn-xs transparent"
                                    onClick={() => this.openConfirmDeleteDialog(event.entity)}>
                                    <span className="glyphicon glyphicon-trash text-danger" />
                                    &nbsp; {t('user_functionality.delete')}
                                </button>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    openConfirmDeleteDialog(blockage) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: blockage,
                onProceedDialog: () =>
                    this.props.deleteById(blockage.id, Entities.TRIP_BLOCKAGE, this.props.selectedCompany),
                bodyText: t('calendar.confirm_delete_blockage'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: blockage.name,
            noButtons: true,
        });
    }
}

export default BlockageDetails;
