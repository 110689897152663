import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import ContactDataInputFields from 'src/components/misc/ContactDataInputFields';
import { Location, renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import { CustomerGroupType } from 'src/utils/constants';
import TextField from 'material-ui/TextField';
import { isBlank } from 'src/components/misc/validations';

class AdminCustomerContactForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, customer, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <legend className="legend">{t('customer_details.customer_group')}</legend>

                <div className="row">
                    <div className="col-md-12">
                        <TextField
                            fullWidth={true}
                            disabled={true}
                            value={t(`customer_groups.id.${customer.customerGroup.id}`)}
                            floatingLabelText={t('customer_details.customer_group')}
                        />
                    </div>
                    {customer.customerGroup.type !== CustomerGroupType.PRIVATE && (
                        <>
                            <div className="col-md-12">
                                <Field
                                    name="organizationName"
                                    label={t('customer_details.organisation_name')}
                                    component={renderInput}
                                />
                            </div>
                            <div className="col-md-12">
                                <Field
                                    name="organizationLocation"
                                    component={Location}
                                    label={t('common_phrases.location')}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="row">
                    <div className="col-md-12 voffset40">
                        <ContactDataInputFields t={t} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 voffset40">
                        <Field
                            name="apiCustomer"
                            label={t('customer_details.api_customer')}
                            component={renderCheckbox}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { customer, t } = props;
    const errors = {
        contactData: {},
    };

    if (isBlank(values.contactData.firstName)) errors.contactData.firstName = t('error_missing.fill_in_first_name');

    if (isBlank(values.contactData.lastName)) errors.contactData.lastName = t('error_missing.fill_in_last_name');

    if (isBlank(values.contactData.street)) errors.contactData.street = t('error_missing.fill_in_street');

    if (isBlank(values.contactData.postCode)) errors.contactData.postCode = t('error_missing.fill_in_postal_code');

    if (isBlank(values.contactData.city)) errors.contactData.city = t('error_missing.fill_in_city');

    if (isBlank(values.contactData.country)) errors.contactData.country = t('error_missing.fill_in_country');

    if (isBlank(values.contactData.phone)) errors.contactData.phone = t('error_missing.fill_in_tel_number');

    if (customer.customerGroup.type !== CustomerGroupType.PRIVATE) {
        if (isBlank(values.organizationName)) errors.organizationName = t('error_missing.fill_in_company_name');

        if (isBlank(values.organizationLocation)) errors.organizationLocation = t('error_missing.fill_in_location');
    }

    return errors;
};

AdminCustomerContactForm = reduxForm({
    form: 'AdminCustomerContactForm',
    validate,
})(AdminCustomerContactForm);

const mapStateToProps = (state, ownProps) => {
    const { customer } = ownProps;

    return {
        initialValues: {
            id: customer.id,
            contactData: customer.contactData,
            organizationName: customer.organizationName,
            organizationLocation: customer.organizationLocation,
            apiCustomer: customer.apiCustomer,
        },
    };
};

AdminCustomerContactForm = connect(mapStateToProps, null, null, { withRef: true })(AdminCustomerContactForm);

export default AdminCustomerContactForm;
