import React from 'react';

export const renderSelectedTicketCollection = (t, ticketCollection) => {
    if (ticketCollection === undefined) return null;

    return (
        <div id="ticketCollectionDetails" className="row">
            <div className="header">{ticketCollection.category}</div>
            <div className="row content">
                <div className="col-md-6 description">
                    <legend>{t('common_phrases.description')}</legend>

                    {ticketCollection.description ? ticketCollection.description : t('ticket.no_description')}
                </div>

                <div className="col-md-6">
                    <legend>{t('ticket.conditions')}</legend>

                    {ticketCollection.conditions ? ticketCollection.conditions : t('ticket.no_conditions')}
                </div>
            </div>
        </div>
    );
};

export const renderEventInformation = (key, value) => {
    return (
        <div className="row">
            <h5>
                <div className="col-md-3">
                    <strong>{key}:</strong>
                </div>
                <div className="col-md-9">{value}</div>
            </h5>
        </div>
    );
};
