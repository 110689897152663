import MenuItem from 'material-ui/MenuItem';
import { RadioButton } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import { Tab, Tabs } from 'material-ui/Tabs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { setSliderIndex } from 'src/actions/form';
import { Currency, Entities, isMoneyAmount, setMoneyCurrency, validatePrice } from 'src/common/index';
import {
    renderCheckbox,
    renderInput,
    renderSelectField,
    supportedRegionItems,
    validateJson,
    validatePercentage,
} from 'src/components/misc/redux-form-helpers';
import { integrationAsyncValidate, isBlank, isUrl } from 'src/components/misc/validations';
import { FINDER_LANGUAGES } from 'src/i18n';
import { IntegrationFeeType } from 'src/utils/constants';
import { isNullOrUndefined } from 'src/utils/helpers';

class IntegrationEditForm extends Component {
    componentWillMount() {
        this.props.setSliderIndex(0);
    }

    render() {
        const { handleSubmit, feeType, selectedSliderIndex, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('integration.tabs.general')} value={0} />
                    <Tab label={t('integration.tabs.fees')} value={1} />
                    <Tab label={t('integration.tabs.settings')} value={2} />
                    <Tab label={t('integration.tabs.overrides')} value={3} />
                    <Tab label={t('integration.tabs.payment')} value={4} />
                </Tabs>

                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    <div className="view">{this.renderPrimaryFields()}</div>
                    <div className="view">{this.renderFees()}</div>
                    <div className="view">{this.renderSettings()}</div>
                    <div className="view">{this.renderOverrides()}</div>
                    <div className="view">{this.renderPaymentOptions()}</div>
                </SwipeableViews>
            </form>
        );
    }

    renderPrimaryFields() {
        const { feeType, busCompanies, t } = this.props;

        const companyMenuItems = [<MenuItem key={-1} value="" primaryText="-" />];

        busCompanies
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .forEach((value, index) => {
                companyMenuItems.push(
                    <MenuItem key={index} value={value['_links']['self']['href']} primaryText={value.companyName} />,
                );
            });

        return (
            <>
                <legend className="legend legend-event-form ">{t('common_phrases.common')}</legend>
                <div className="row voffset">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field name="url" label={`${t('booked_tasks.partner_url')} *`} component={renderInput} />
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-6">
                        <Field name="company" label={t('common_phrases.company')} component={renderSelectField}>
                            {companyMenuItems}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field name="allCompanies" label={t('booked_tasks.all_companies')} component={renderCheckbox} />
                    </div>
                </div>
            </>
        );
    }

    renderFees() {
        const { feeType, t } = this.props;
        return (
            <>
                <legend className="legend legend-event-form">{t('integration.fee_type')}</legend>
                <div className="row">
                    <div className="col-md-12 voffset">
                        <Field
                            name="feeType"
                            label={`${t('integration.fee_type')} *`}
                            defaultSelected={this.props.initialValues.feeType}
                            component={RadioButtonGroup}>
                            <RadioButton
                                value={IntegrationFeeType.PROVISION}
                                label={t('integration.fee_type_option.provision')}
                            />
                            <RadioButton
                                value={IntegrationFeeType.PRICE_PER_REQUEST}
                                label={t('integration.fee_type_option.price_per_request')}
                            />
                        </Field>
                    </div>
                </div>
                <div className="row" hidden={feeType !== IntegrationFeeType.PROVISION}>
                    <div className="col-md-6 voffset" title={t('integration.fee_type_option.provision')}>
                        {/*TODO: BUF-1135: translate*/}
                        <Field
                            name="fee"
                            disabled={feeType === IntegrationFeeType.PRICE_PER_REQUEST}
                            label={`${t('integration.fee_type_option.provision')} (in %) *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <FieldArray
                    name="requestFees"
                    component={renderRequestFees}
                    hidden={feeType !== IntegrationFeeType.PRICE_PER_REQUEST}
                    {...{
                        t,
                    }}
                />
            </>
        );
    }

    /*TODO: BUF-1135: translate*/
    renderSettings() {
        const { settings, t } = this.props;
        const requiredCustomerMail = settings && settings.searchResults && settings.searchResults.requireCustomerMail;

        return (
            <>
                <div className="row voffset">
                    <div className="col-md-12">
                        <legend className="legend">Authentication</legend>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.authentication.disableAuthentication"
                            label="Authentication deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-12">
                        <legend className="legend">Tutorial</legend>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.tutorial.disableSearchFormTour"
                            label="Form-Tour deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-12">
                        <legend className="legend">Buchungen</legend>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.booking.enableReservation"
                            label="Reservierungen aktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.booking.disableDriverRoomsChoice"
                            label="Fahrerzimmer deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-6">
                        <Field
                            name="settings.booking.disableCustomerBookingAmend"
                            label="Buchung ändern deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-12">
                        <legend className="legend">Suchergebnisse</legend>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.disableResultFilter"
                            label="Filter deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.disableTripCompanies"
                            label="Ausflugsziele deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                </div>
                <div className="row voffset">
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.requireCustomerMail"
                            label="Abfrage Kunden-E-mail"
                            component={renderCheckbox}
                        />
                    </div>
                    {requiredCustomerMail && (
                        <div className="col-md-6">
                            <Field
                                name="settings.searchResults.requireCustomerName"
                                label="Abfrage Kunden-Name"
                                component={renderCheckbox}
                            />
                        </div>
                    )}
                </div>
                {requiredCustomerMail && (
                    <div className="row voffset">
                        <div className="col-md-12">
                            <Field
                                name="settings.searchResults.sendSearchResultsReminderMail"
                                label="Erinnerungsmail senden"
                                component={renderCheckbox}
                            />
                        </div>
                    </div>
                )}
                <div className="row voffset">
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.applicableSchool"
                            label="Checkbox 'Schule' aktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.ignoreArrivalAndReturnKm"
                            label="Maximale Anfahrtszeit ignorieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.searchResults.limitedBusFormThreshold"
                            label="Schwellenwert für eingeschränkte Busform"
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>

                <div className="row offset">
                    <div className="col-md-12">
                        <legend className="legend">Lokalisierung</legend>
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.localization.disableLanguageSwitcher"
                            label="Sprache-Wechseln deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.localization.defaultLanguage"
                            label="Standard-Sprache"
                            component={renderSelectField}>
                            {[<MenuItem key={-1} value="" primaryText="-" />].concat([
                                FINDER_LANGUAGES.map(language => (
                                    <MenuItem key={language.code} value={language.code} primaryText={language.label} />
                                )),
                            ])}
                        </Field>
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.localization.languageDetectionOrder"
                            label="Spracherkennungsreihenfolge"
                            component={renderSelectField}
                            multiple>
                            <MenuItem value="htmlTag" primaryText="HTML-Tag" />
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="settings.localization.disableRegionSwitcher"
                            label="Region-Wechseln deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.localization.defaultRegion"
                            label="Standard-Region"
                            component={renderSelectField}>
                            {supportedRegionItems()}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="settings.localization.disableCurrencySwitcher"
                            label="Währung-Wechseln deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="settings.localization.defaultCurrency"
                            label="Standard-Währung"
                            component={renderSelectField}>
                            {[<MenuItem key={-1} value="" primaryText="-" />].concat(
                                Object.keys(Currency).map(currency => (
                                    <MenuItem key={currency} value={currency} primaryText={currency} />
                                )),
                            )}
                        </Field>
                    </div>
                </div>

                <div className="row voffset">
                    <div className="col-md-12">
                        <legend className="legend">Branding</legend>
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.branding.disableLogo"
                            label="Logo deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.branding.disableCookieBanner"
                            label="Cookie-Banner deaktivieren"
                            component={renderCheckbox}
                        />
                    </div>
                    <div className="col-md-4">
                        <Field
                            name="settings.branding.useBusName"
                            label="Verwende Bus-Name (statt Marke+Typ)"
                            component={renderCheckbox}
                        />
                    </div>
                </div>

                <div className="row offset">
                    <div className="col-md-12">
                        <legend className="legend">Theme</legend>
                    </div>
                    <div className="col-md-12">
                        <Field
                            name="settings.theme.forceColorScheme"
                            label="Farbschema erzwingen"
                            component={renderSelectField}>
                            <MenuItem value="" primaryText="-" />
                            <MenuItem value="light" primaryText="Helles Farbschema" />
                            <MenuItem value="dark" primaryText="Dunkles Farbschema" />
                        </Field>
                    </div>
                </div>
            </>
        );
    }

    /*TODO: BUF-1135: translate*/
    renderOverrides() {
        return (
            <>
                <div className="row voffset">
                    <div className="col-md-12">
                        <Field
                            name="themeOverrides"
                            label="Theme-Anpassungen (JSON)"
                            component={renderInput}
                            multiline
                            rows={4}
                        />
                    </div>
                </div>
                <div className="row voffset">
                    <div className="col-md-12">
                        <Field
                            name="translationOverrides"
                            label="Translation-Anpassungen (JSON)"
                            component={renderInput}
                            multiline
                            rows={4}
                        />
                    </div>
                </div>
            </>
        );
    }

    /*TODO: BUF-1135: translate*/
    renderPaymentOptions() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <Field name="settings.onlinePayment.merchantId" label="Konto-ID" component={renderInput} />
                    </div>
                    <div className="col-md-12">
                        <Field name="settings.onlinePayment.apiKey" label="API-Schlüssel" component={renderInput} />
                    </div>
                </div>
            </>
        );
    }
}

const renderRequestFees = ({ hidden, fields, t }) => (
    <div className="col-md-12 voffset" style={hidden ? { display: 'none' } : {}}>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('calculation.new_rate')}`}
                        onClick={() =>
                            fields.push({
                                fromRequest: 1,
                                price: 0,
                            })
                        }
                    />
                </div>
            </div>
        </div>

        {fields.map((requestFee, index) => (
            <div key={index} className="row">
                <div className="col-md-5">
                    <div className="form-group">
                        <Field
                            name={`${requestFee}.fromRequest`}
                            label={`${t('booked_tasks.from_request')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name={`${requestFee}.price.amount`}
                            label={`${t('integration.fee_type_option.price_per_request')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="col-md-1 voffset">
                    <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                        <span className="glyphicon glyphicon-trash" />
                    </button>
                </div>
            </div>
        ))}
    </div>
);

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (isBlank(values.url)) errors.url = t('error_missing.fill_in_url');
    else if (!isUrl(values.url)) errors.url = t('error_missing.fill_in_valid_url');

    if (values.company.length === 0) errors.company = t('error_missing.select_company');

    if (values.feeType === IntegrationFeeType.PROVISION) {
        if (isNullOrUndefined(values.fee) || values.fee.length === 0) errors.fee = t('error_missing.fill_in_provision');
        else if (!validatePercentage(values.fee)) errors.fee = t('error_hint.provision_criteria');
        else if (!/^\d{1,3}(?:\.\d)?$/i.test(values.fee))
            errors.fee = `${t('error_hint.error_invalid_format')} (######.00)`;
    }

    if (values.requestFees) errors.requestFees = validateRequestFees(values.requestFees, t);

    if (values.themeOverrides && !validateJson(values.themeOverrides))
        errors.themeOverrides = t('error_hint.invalid_json');
    if (values.translationOverrides && !validateJson(values.translationOverrides))
        errors.translationOverrides = t('error_hint.invalid_json');

    return errors;
};

const validateRequestFees = (requestFees, t) => {
    const errors = [];
    const seen = new Set();

    requestFees.forEach((requestFee, index) => {
        const error = {
            fromRequest: undefined,
            price: {
                amount: undefined,
            },
        };

        if (requestFee.fromRequest < 1) {
            error.fromRequest = t('error_hint.notNegativeOrZero');
            errors[index] = error;
        } else if (seen.has(requestFee.fromRequest)) {
            error.fromRequest = t('error_hint.error_unique');
            errors[index] = error;
        } else if (!isMoneyAmount(requestFee.price)) {
            error.price.amount = t('error_missing.fill_in_price');
            errors[index] = error;
        } else if (validatePrice(requestFee.price.amount)) {
            error.price.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
            errors[index] = error;
        }

        seen.add(requestFee.fromRequest);
    });

    if (errors.length > 0) return errors;
};

const asyncValidate = (values, dispatch, props, blurredField) => {
    return integrationAsyncValidate(props.t, Entities.BOOKING_INTEGRATION.repository, values, blurredField);
};

const onSubmit = (data, ownProps) => {
    const selectedCompany = ownProps.busCompanies.find(company => company._links.self.href === data.company);
    if (selectedCompany != null) {
        Object.keys(data.requestFees).forEach(key => {
            setMoneyCurrency(data.requestFees[key].price, selectedCompany.currency);
        });
    }

    console.log('data: ', data);
    ownProps.onSubmit(data);
};

const onSubmitFail = (errors, dispatch) => {
    if (!errors) return;

    if (errors.requestFees || errors.fee) dispatch(setSliderIndex(1));
    else if (errors.themeOverrides || errors.translationOverrides) dispatch(setSliderIndex(3));
    else dispatch(setSliderIndex(0));
};

const selector = formValueSelector('integrationEditForm');

const mapStateToProps = (state, ownProps) => {
    return {
        feeType: selector(state, 'feeType'),
        settings: selector(state, 'settings'),
        company: selector(state, 'company'),
        onSubmit: data => onSubmit(data, ownProps),
    };
};

IntegrationEditForm = reduxForm({
    form: 'integrationEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name', 'url'],
})(IntegrationEditForm);

IntegrationEditForm = connect(mapStateToProps, null, null, { withRef: true })(IntegrationEditForm);

export default IntegrationEditForm;
