import React, { Component } from 'react';
import { ButtonColors } from 'src/utils/constants';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { Entities, formatMoney, Projection } from 'src/common/index';
import { formatMinutes } from 'src/components/misc/redux-form-helpers';
import moment from 'moment';
import CustomTooltip from 'src/components/misc/CustomTooltip';

class BookingDetails extends Component {
    render() {
        const { event, selectedCompany, patch, openModal, t } = this.props;

        const customerContactData = event.entity.customerContactData;

        return (
            <table className="table table-details">
                <thead style={{ backgroundColor: event.color }}>
                    <tr
                        style={{
                            //white semitransparent overlay to match calendar event colors
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        }}>
                        <th>{`${t('task_details.booking')} ${
                            event.entity.cancelled ? t('task_details.cancelled_brackets') : ''
                        }`}</th>
                        <th>{t('booked_tasks.from')}</th>
                        <th>{t('booked_tasks.to')}</th>
                        <th>{t('calendar.duration')}</th>
                        <th>{t('customer_details.customer_data')}</th>
                        <th width="20%">{t('products.products')}</th>
                        <th>{t('ticket.sum')}</th>
                        <th>{t('common_phrases.actions')}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <p>{event.entity.name}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.start.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{t('administration.time', { time: event.end.format('DD.MM.YYYY HH:mm') })}</p>
                        </td>
                        <td>
                            <p>{formatMinutes(t, moment.duration(event.end.diff(event.start)).asMinutes())}</p>
                        </td>
                        <td>
                            <p>{customerContactData.fullName}</p>
                            <p>{customerContactData.address}</p>
                            <p>{customerContactData.phone}</p>
                        </td>
                        <td>
                            {event.entity.items.map(item => {
                                return (
                                    <p key={item.id}>
                                        {/*TODO: BUF-1135: translate*/}
                                        {`${item.pax} x ${item.seasonProduct.product.name} a' ${formatMoney(
                                            item.price,
                                        )} `}
                                        {item.freeCompanions > 0 && (
                                            <CustomTooltip
                                                text={t('booked_tasks.free_companions', {
                                                    companions: item.freeCompanions,
                                                })}
                                                id={`tooltip-freeCompanions-${item.id}`}
                                                place="right"
                                                offset={{ right: 10, bottom: 0 }}>
                                                <span className="fa fa-info-circle text-info" />
                                            </CustomTooltip>
                                        )}
                                    </p>
                                );
                            })}
                        </td>
                        <td>
                            {/*TODO: BUF-1135: translate*/}
                            <p>{`${event.pax} PAX`}</p>
                            <p>{formatMoney(event.entity.price)}</p>
                        </td>
                        <td>
                            <p>
                                {renderCancelTripBookingButton(
                                    t,
                                    event.entity,
                                    selectedCompany,
                                    patch,
                                    openModal,
                                    true,
                                )}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default BookingDetails;

export const renderCancelTripBookingButton = (t, booking, companyId, patch, openModal, withText) => {
    if (moment(booking.to).isSameOrAfter(moment()))
        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                title={booking.cancelled ? t('calendar.cancel_cancellation') : t('calendar.cancel')}
                onClick={() => openConfirmCancelTripBooking(t, booking, companyId, patch, openModal)}>
                <span className={`fa fa-ban ${booking.cancelled ? 'text-default' : 'text-danger'}`} />
                &nbsp;
                {withText ? (booking.cancelled ? t('calendar.cancel_cancellation') : t('calendar.cancel')) : ''}
            </button>
        );

    return null;
};

export const openConfirmCancelTripBooking = (t, booking, companyId, patch, openModal) => {
    openModal({
        component: ConfirmDialog,
        componentProps: {
            event: booking,
            onProceedDialog: () =>
                patch(
                    {
                        id: booking.id,
                        cancelled: !booking.cancelled,
                    },
                    Entities.TRIP_BOOKING,
                    companyId,
                    Projection.BOOKING,
                ),
            bodyText: booking.cancelled
                ? t('calendar.confirm_delete_cancellation')
                : t('calendar.confirm_cancellation'),
            proceedText: booking.cancelled ? t('calendar.abrogate') : t('calendar.cancel'),
            cancelText: t('common_phrases.no'),
            cancelButtonColor: ButtonColors.NOTIFY,
            proceedButtonColor: ButtonColors.DANGER,
        },
        title: booking.name,
        noButtons: true,
    });
};
