import Toggle from 'material-ui/Toggle';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { ButtonColors, genderName } from 'src/utils/constants';
import { deleteById, Entities, fetchAll, getEntities, patch, Projection, UserRole } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import { withTranslation } from 'react-i18next';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import PasswordEditModal from 'src/components/account/password-edit-modal';
import AccountEditModal from 'src/components/account/account-edit-modal';
import ErrorMessage from 'src/components/misc/error-message';

class AdminTripOperatorAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.TRIP_OPERATOR, 0, Projection.FULL);
        this.props.fetchAll(Entities.TRIP_COMPANY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { tripOperators } = nextProps;

        if (!this.state.startedFetching && tripOperators.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(tripOperators.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(prevProps.tripOperators, this.props.tripOperators, this.table)
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { tripOperators, companies, t } = this.props;
        const finishFetching = this.state.startedFetching && !tripOperators.isFetching && !companies.isFetching;

        const tableData = [];

        if (finishFetching) {
            if (finishFetching && tripOperators.items.length > 0) {
                tripOperators.items.map(account => {
                    const company = account.company
                        ? companies.items.find(company => company.id === account.company)
                        : undefined;
                    tableData.push({
                        id: account.id,
                        email: account.email,
                        company: company,
                        companyName: company !== undefined ? company.companyName : '-',
                        role: account.role,
                        firstName: account.contactData.firstName,
                        lastName: account.contactData.lastName,
                        gender: account.contactData.gender,
                        genderName: genderName(account.contactData.gender),
                        address: account.contactData.address,
                        readOnly: account.readOnly,
                    });
                });
            }
        }
        return (
            <div>
                <legend className="legend">
                    {t('trip_operator.operator')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: AccountEditModal,
                                componentProps: {
                                    role: UserRole.TRIP_OPERATOR,
                                    isCreate: true,
                                    isAdmin: true,
                                },
                                title: t('trip_operator.new_operator'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('trip_operator.new_operator')}
                    </button>
                </legend>

                {(() => {
                    if (tripOperators.error) return <ErrorMessage object={tripOperators} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="email" width="300" dataSort={true}>
                                    {t('contact_data.username')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="companyName" dataSort={true}>
                                    {t('common_phrases.company')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="gender"
                                    dataSort={true}
                                    dataFormat={genderName}
                                    csvFormat={genderName}>
                                    {t('contact_data.gender')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="firstName" dataSort={true}>
                                    {t('contact_data.first_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="lastName" dataSort={true}>
                                    {t('contact_data.last_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                    {t('contact_data.address')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="readOnly"
                                    width="140"
                                    dataSort={true}
                                    dataFormat={this.readOnlyFormatter}>
                                    {t('common_phrases.read_permission')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    readOnlyFormatter(cell, account) {
        return (
            <Toggle
                toggled={account.readOnly}
                onToggle={() =>
                    this.props.patch(
                        {
                            id: account.id,
                            role: account.role,
                            readOnly: !account.readOnly,
                        },
                        Entities[account.role],
                        0,
                        Projection.FULL,
                    )
                }
            />
        );
    }

    tableActionsFormatter(cell, account) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: AccountEditModal,
                            componentProps: {
                                id: account.id,
                                role: account.role,
                                isCreate: false,
                                isAdmin: true,
                            },
                            title: `${account.firstName} ${account.lastName}`,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.change_password')}
                    onClick={() => {
                        this.props.openModal({
                            component: PasswordEditModal,
                            componentProps: {
                                id: account.id,
                                role: account.role,
                                isAdmin: true,
                            },
                            title: t('customer_details.change_password_first_last', {
                                firstName: account.firstName,
                                lastName: account.lastName,
                            }),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-lock text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: account,
                                onProceedDialog: account => this.props.deleteById(account.id, Entities[account.role]),
                                bodyText: t('customer_details.delete_confirmation'),
                                proceedText: t('user_functionality.delete'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: account.email,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tripOperators: getEntities(state, Entities.TRIP_OPERATOR),
        companies: getEntities(state, Entities.TRIP_COMPANY),
    };
};

export default AdminTripOperatorAdministration = withTranslation()(
    connect(mapStateToProps, { fetchAll, deleteById, patch })(composeModalActions(AdminTripOperatorAdministration)),
);
