import FileSaver from 'file-saver';
import HttpStatus from 'http-status-codes';
import _, { debounce, isNaN, isNumber } from 'lodash';
import { TextField } from 'material-ui';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import Toggle from 'material-ui/Toggle';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { asyncRequest, dashIfNull, Entities, getFileNameFromResponse, Projection, TOMCAT_URL } from 'src/common/index';
import AdminChangeBookingToPaymentOnInvoice from 'src/components/admin/booked-tasks/AdminChangeBookingToPaymentOnInvoice';
import AdminSelectedBookedTaskDetails from 'src/components/admin/booked-tasks/AdminSelectedBookedTaskDetails';
import CustomTooltip from 'src/components/misc/CustomTooltip';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import { getBookedPrice } from 'src/components/operatingtool/price';
import { ButtonColors } from 'src/utils/constants';
import { integrationFormatter, priceFormatter } from 'src/utils/formatters';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';

class AdminBookedTasksAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            selectedMonth: false,
            drawer: {
                open: false,
                selectedRow: undefined,
            },
            isFetching: false,
            isSuccess: false,
            taskFroms: [],
            tasks: [],
            errorMessage: null,
        };

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });
    }

    // componentWillMount() {
    //     this.props.fetchBookedTasks(this.props.location.query.filter);
    // }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { tasks, location } = nextProps;

        if (this.props.location.query.filter !== location.query.filter) {
            nextProps.fetchBookedTasks(location.query.filter);
            this.setState({ selectedMonth: false });
        }

        this.tableHelper.processPagination(tasks.items.length);
        this.setState({
            tasks: tasks.items,
            taskFroms: this.getTasksFroms(tasks.items),
            isFetching: tasks.isFetching,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.tasks, this.props.tasks, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { tasks, reviews, location, t } = this.props;
        const finishFetching = this.state.isSuccess && !this.state.isFetching;
        const tableData = [];

        if (finishFetching && this.state.tasks.length > 0) {
            this.state.tasks
                .sort((a, b) => (moment(a.bookedAt).isBefore(moment(b.bookedAt)) ? 1 : -1))
                .forEach(task => {
                    const taskFromMoment = moment(task.taskFrom.time);

                    if (
                        !this.state.selectedMonth ||
                        moment.unix(this.state.selectedMonth).isSame(taskFromMoment, 'month')
                    )
                        this.populateTableData(task, reviews, tableData);
                });

            if (location.query.filter === 'withReview')
                tableData.sort((t1, t2) => {
                    if (
                        (t1.reviewStatus === false && (t2.reviewStatus === undefined || t2.reviewStatus === true)) ||
                        (t1.reviewStatus === true && t2.reviewStatus === undefined)
                    )
                        return -1;

                    return 1;
                });
        }

        return (
            <div id="adminTaskAdministration">
                <Drawer
                    openSecondary={true}
                    docked={false}
                    width={900}
                    onRequestChange={open =>
                        this.setState({
                            drawer: {
                                open: false,
                                selectedRow: undefined,
                            },
                        })
                    }
                    open={this.state.drawer.open}>
                    {this.state.drawer.open && this.state.drawer.selectedRow ? (
                        <AdminSelectedBookedTaskDetails
                            selectedBookedTask={this.state.drawer.selectedRow}
                            account={this.props.account}
                            openModal={this.props.openModal}
                            handleCloseDrawer={this.handleCloseDrawer}
                        />
                    ) : null}
                </Drawer>

                <h4>{t('booked_tasks.all_booked_tasks')}</h4>
                <hr />

                {(() => {
                    if (tasks.error) return <ErrorMessage object={tasks} />;
                    else
                        return (
                            <div>
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={{
                                        toolBar: this.createCustomToolbar,
                                        ...this.tableHelper.getCustomOptions(
                                            finishFetching,
                                            this.state.isFetching,
                                            t('booked_tasks.hint'),
                                        ),
                                    }}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="bookingIdentifier"
                                        width="90"
                                        dataSort={true}>
                                        {t('booked_tasks.booking_number')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="identifier"
                                        width="80"
                                        dataSort={true}>
                                        {t('booked_tasks.task_number')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="bookedAt"
                                        width="120"
                                        dataFormat={this.dateFormatter}
                                        csvFormat={this.dateFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.booked_at')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="companyName"
                                        width="160"
                                        dataSort={true}>
                                        {t('common_phrases.company')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="contact_data.country"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.company.contactDataCountry
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="taskFrom"
                                        width="120"
                                        dataFormat={this.dateFormatter}
                                        csvFormat={this.dateFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.from')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="taskTo"
                                        width="120"
                                        dataFormat={this.dateFormatter}
                                        csvFormat={this.dateFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.to')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="customerEmail"
                                        width="160"
                                        dataSort={true}>
                                        {t('booked_tasks.customer')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="price"
                                        dataAlign="right"
                                        width="120"
                                        dataFormat={(cell, row) => getBookedPrice(row.entity, t, true)}
                                        csvFormat={priceFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.price')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="provision"
                                        width="100"
                                        dataFormat={priceFormatter}
                                        csvFormat={priceFormatter}
                                        dataAlign="right"
                                        dataSort={true}>
                                        {t('booked_tasks.provision')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="bookingIntegration"
                                        width="60"
                                        dataFormat={cell => integrationFormatter(t, cell)}
                                        csvFormat={cell => !!cell}
                                        dataAlign="center"
                                        dataSort={true}>
                                        {t('booked_tasks.booking_integration')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="reviewScore"
                                        width="40"
                                        dataFormat={cell => dashIfNull(cell)}
                                        dataSort={true}>
                                        {t('common_phrases.rating')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="reviewStatus"
                                        width="80"
                                        dataAlign="center"
                                        dataFormat={this.statusFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.status')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="reviewComment" hidden={true}>
                                        {t('comment')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cancelled"
                                        width="90"
                                        dataFormat={this.cancelledFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.cancelled')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="110"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                    {/*Additional data for CSV export (width=0 to hide from table, but to include in CSV) */}
                                    <TableHeaderColumn
                                        dataField="customer.customerGroup"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customer && row.entity.customer.customerGroup
                                                ? t(`customer_groups.id.${row.entity.customer.customerGroup.id}`)
                                                : '-'
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customerContactData.street"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customerContactData.street
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customerContactData.postCode"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customerContactData.postCode
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customerContactData.city"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customerContactData.city
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customerContactData.country"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customerContactData.country
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customerContactData.phone"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customerContactData.phone
                                        }></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customer.organizationName"
                                        width="0"
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) =>
                                            row.entity.customer ? row.entity.customer.organizationName : '-'
                                        }></TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    getTasksFroms(tasks) {
        if (tasks.length === 0) return;
        return Array.from(new Set(tasks.map(task => moment(task.taskFrom.time).format('YYYY-MM')))).map(yearMonth =>
            moment(yearMonth),
        );
    }

    findBookedTasksByBookingId(id) {
        const url = `${TOMCAT_URL}api/${Entities.TASK.repository}/findBookedTasksByBookingId/${id}`;
        this.findTasksByIdentifier(id, url);
    }

    findBookedTaskByTaskId(id) {
        const url = `${TOMCAT_URL}api/${Entities.TASK.repository}/findBookedTasksByTaskId/${id}`;
        this.findTasksByIdentifier(id, url);
    }

    findTasksByIdentifier(id, url) {
        this.setState({ isFetching: true });
        asyncRequest(url)
            .then(response => {
                const result = response.json;
                const tasks = Array.isArray(result) ? result : [result];

                this.setState({
                    tasks,
                    taskFroms: this.getTasksFroms(tasks),
                    errorMessage: null,
                    isSuccess: true,
                });
            })
            .catch(error => {
                let errorMessage;
                if (error.status === HttpStatus.NOT_FOUND) {
                    this.setState({ errorMessage: this.props.t('booked_tasks.task_not_found') });
                } else this.setState({ errorMessage: this.props.t('booked_tasks.invalid_identifier') });
            })
            .finally(() => {
                this.setState({ isFetching: false });
            });
    }

    createCustomToolbar(props) {
        const { t } = this.props;

        const onChange = debounce((event, newValue) => {
            const v = newValue.toUpperCase();
            if (v.length === 0) return this.setState({ errorMessage: null });

            const trimmedId = this.trimIdentifier(v);

            if (isNaN(trimmedId)) return this.setState({ errorMessage: t('booked_tasks.invalid_identifier') });

            if (v.startsWith('BK')) this.findBookedTasksByBookingId(trimmedId);
            else if (v.startsWith('A')) this.findBookedTaskByTaskId(trimmedId);
        }, 850);

        return (
            <div className="col-md-12">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <div style={{ flexGrow: 1 }}>{props.components.exportCSVBtn}</div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <div style={{ width: 200 }}>
                                <SelectField
                                    value={this.state.selectedMonth}
                                    onChange={(event, index, value) => {
                                        this.setState({
                                            selectedMonth: value,
                                        });
                                    }}
                                    floatingLabelText={t('booked_tasks.month')}
                                    fullWidth={true}
                                    hintText={t('booked_tasks.choose_month')}
                                    floatingLabelFixed={true}>
                                    {[<MenuItem key={-1} value={false} primaryText={t('common_phrases.all')} />].concat(
                                        this.getMonthMenuItems(),
                                    )}
                                </SelectField>
                            </div>

                            <div style={{ width: 400, marginTop: 24 }}>{props.components.searchPanel}</div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
                        <div>
                            <RaisedButton
                                primary
                                onClick={this.fetchAllBookedTasks.bind(this)}
                                label={t('booked_tasks.load_all_booked_tasks')}
                                labelColor="#ffffff"
                                className="pull-left"
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField
                                inputStyle={{ textTransform: 'uppercase' }}
                                style={{ width: 400 }}
                                title={t('booked_tasks.load_single_task_placeholder')}
                                hintText={t('booked_tasks.load_single_task_placeholder')}
                                errorText={this.state.errorMessage}
                                onChange={onChange}
                            />
                            <CustomTooltip
                                text={<span style={{ maxWidth: 200 }}>{t('booked_tasks.load_single_task_tt')}</span>}>
                                <span className="fa fa fa-info-circle text-info" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async fetchAllBookedTasks() {
        const { t, fetchBookedTasks, location, addNotification } = this.props;

        this.setState({ isFetching: true });
        try {
            await fetchBookedTasks(location.query.filter);
            this.setState({ isFetching: false, isSuccess: true });
        } catch (error) {
            this.setState({ isFetching: false, isSuccess: false });
            addNotification(t('common_phrases.error'), t('error_occurred'), 'error', 'tr');
        }
    }

    trimIdentifier(id) {
        const prefix = id.startsWith('BK') ? 'BK' : id.startsWith('A') ? 'A' : '';

        if (prefix) {
            const regex = new RegExp(`^${prefix}0+|^${prefix}`);
            const trimmed = id.replace(regex, '');
            return /^\d+$/.test(trimmed) ? parseInt(trimmed) : NaN;
        }

        return NaN;
    }

    getMonthMenuItems() {
        return _.sortBy(this.state.taskFroms)
            .reverse()
            .map((taskFrom, index) => {
                return <MenuItem key={index} value={taskFrom.format('X')} primaryText={taskFrom.format('MMMM YYYY')} />;
            });
    }

    populateTableData(task, reviews, tableData) {
        const { t } = this.props;
        let reviewScore = 0;
        let reviewEntries, reviewId, reviewVisible, reviewComment;

        if (task.review) {
            const reviewData = isNumber(task.review) ? reviews[task.review] : task.review;

            if (reviewData) {
                reviewScore = reviewData.totalScore.toFixed(2);
                reviewEntries = reviewData.entries;
                reviewVisible = reviewData.visible;
                reviewComment = reviewData.comment;
                reviewId = reviewData.id || task.review.id;
            }
        }

        tableData.push({
            id: task.id,
            name: `${task.identifier}  ${task.customerContactData.email ? `- ${task.customerContactData.email} ` : ''}`,
            identifier: task.identifier,
            bookingIdentifier: task.bookingIdentifier,
            bookingIntegration: task.bookingIntegration,
            price: task.price,
            reducedPrice: task.reducedPrice,
            provision: task.provision,
            bookedAt: task.bookedAt,
            from: task.from.time,
            to: task.to.time,
            taskFrom: task.taskFrom.time,
            taskTo: task.taskTo.time,
            customerEmail: task.customerContactData.email || (task.customer ? task.customer.email : ''),
            reviewScore: reviewScore,
            companyName: task.company.companyName,
            reviewEntries: reviewEntries,
            reviewId: reviewId,
            reviewStatus: reviewVisible,
            cancelled: task.cancelled,
            received: task.received,
            reviewComment: reviewComment,
            latestOnlinePaymentLog: task.latestOnlinePaymentLog,
            paymentPending: task.paymentPending,
            entity: task,
        });
    }

    onRowClick(row, col) {
        // Only open the drawer if the user clicks on the first or second column.
        // It would be better to allow on any column except for the storno or action buttons column,
        // but some columns return NaN for the col parameter for some reason,
        // so it's difficult to distinguish the columns.
        if ([0, 1].includes(col)) {
            this.setState({
                drawer: {
                    open: !this.state.drawer.open,
                    selectedRow: row,
                },
            });
        }
    }

    handleCloseDrawer() {
        this.setState({
            drawer: {
                open: false,
                selectedRow: undefined,
            },
        });
    }

    dateFormatter(cell, row) {
        return cell ? `${moment(cell).format()}` : '-';
    }

    statusFormatter(cell, task) {
        const { t } = this.props;
        switch (task.reviewStatus) {
            case false:
                return (
                    <span title={t('booked_tasks.rating_not_verified')} className="glyphicon glyphicon-star-empty" />
                );
            case true:
                return <span title={t('booked_tasks.rating_verified')} className="glyphicon glyphicon-star" />;
            default:
                return <span title={t('booked_tasks.no_rating')} className="glyphicon glyphicon-remove" />;
        }
    }

    onProceedReleaseReview(task) {
        const updatedReview = {
            id: task.reviewId,
            visible: true,
        };

        this.handleCloseDrawer();
        this.props.patch(updatedReview, Entities.REVIEW);
    }

    onProceedDeleteReview(task) {
        this.handleCloseDrawer();
        this.props.deleteReview(task.id);
    }

    onProceedCancelTask(task) {
        this.handleCloseDrawer();
        this.props.patch({ id: task.id, cancelled: !task.cancelled }, Entities.TASK, 0, Projection.FULL);
    }

    openConfirmReleaseReviewDialog(task) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: task,
                onProceedDialog: this.onProceedReleaseReview,
                closeDrawer: this.handleCloseDrawer,
                bodyText: t('booked_tasks.confirm_rating'),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
            },
            title: task.identifier,
            noButtons: true,
        });
    }

    openConfirmDeleteReviewDialog(task) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: task,
                onProceedDialog: this.onProceedDeleteReview,
                closeDrawer: this.handleCloseDrawer,
                bodyText: t('booked_tasks.confirm_rating_delete'),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: task.identifier,
            noButtons: true,
        });
    }

    openConfirmCancelTaskDialog(task) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: task,
                onProceedDialog: this.onProceedCancelTask,
                closeDrawer: this.handleCloseDrawer,
                bodyText: t('booked_tasks.confirm_cancellation'),
                proceedText: t('common_phrases.yes'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: task.identifier,
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, task) {
        const { t } = this.props;
        return (
            <div>
                {task.reviewId && !task.reviewStatus && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        title={t('booked_tasks.verify_rating')}
                        onClick={() => this.openConfirmReleaseReviewDialog(task)}>
                        <span className="glyphicon glyphicon-transfer text-warning" />
                    </button>
                )}
                {task.reviewId && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        title={t('booked_tasks.delete_rating')}
                        onClick={() => this.openConfirmDeleteReviewDialog(task)}>
                        <span className="glyphicon glyphicon-trash text-danger" />
                    </button>
                )}
                {!task.cancelled && !task.received && moment(task.from).isAfter(moment()) && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        onClick={() => {
                            this.props.openModal({
                                component: ConfirmDialog,
                                componentProps: {
                                    event: task,
                                    onProceedDialog: this.onProceedReceivedTask,
                                    closeDrawer: this.handleCloseDrawer,
                                    bodyText: t('booked_tasks.received_information'),
                                    proceedText: t('booked_tasks.mark_received'),
                                    cancelText: t('common_phrases.no'),
                                    cancelButtonColor: ButtonColors.NOTIFY,
                                    proceedButtonColor: ButtonColors.SUCCESS,
                                },
                                title: task.name + t('booked_tasks.received'),
                                noButtons: true,
                            });
                        }}>
                        <span
                            className="glyphicon glyphicon-ok text-success"
                            title={t('booked_tasks.mark_booking_received')}
                        />
                    </button>
                )}
                {task.paymentPending && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        onClick={() => {
                            this.props.openModal({
                                component: AdminChangeBookingToPaymentOnInvoice,
                                componentProps: {
                                    task,
                                    patch: this.props.patch,
                                    closeDrawer: this.handleCloseDrawer,
                                },
                                title: task.name,
                                noButtons: true,
                            });
                        }}>
                        <span
                            className="glyphicon glyphicon-list-alt text-info"
                            title={t('booked_tasks.admin_pay_on_invoice.action')}
                        />
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.TASK.repository}/${task.id}/printBookingConfirmationPdf`,
                        )
                            .then(response => {
                                response.blob().then(blob => {
                                    FileSaver.saveAs(blob, getFileNameFromResponse(response));
                                });
                            })
                            .catch(error => {
                                console.error('download error', error);
                            });
                    }}>
                    <span
                        className="glyphicon glyphicon-download-alt text-success"
                        title={t('booked_tasks.download_booking_confirmation')}
                    />
                </button>
            </div>
        );
    }

    onProceedReceivedTask(task) {
        this.props.patch(
            {
                id: task.id,
                received: true,
            },
            Entities.TASK,
            0,
            Projection.FULL,
            undefined,
            'receivedBooking',
        );
        this.handleCloseDrawer();
    }

    onSuccessReceivedTask(task) {
        const { t } = this.props;
        this.props.addNotification(
            t('common_phrases.success'),
            t('booked_tasks.notification_success', { name: task.identifier, email: task.customerContactData.email }),
            'success',
            'tr',
        );
    }

    cancelledFormatter(cell, task) {
        const { t } = this.props;
        const isTaskInPast = moment().isAfter(moment(task.fromTime));
        const title = task.cancelled ? t('booked_tasks.cancelled') : t('booked_tasks.not_cancelled');
        return (
            <Toggle
                disabled={isTaskInPast || task.cancelled}
                toggled={task.cancelled}
                title={isTaskInPast ? t('booked_tasks.past_booking', { title: title }) : title}
                onToggle={() => this.openConfirmCancelTaskDialog(task)}
            />
        );
    }
}

export default composeModalActions(withTranslation()(AdminBookedTasksAdministration));
