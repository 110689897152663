import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, Projection, UserRole } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import PasswordEditForm from 'src/components/account/password-edit-form';

class PasswordEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, role } = this.props;

        if (!isCreate)
            this.props.fetchById(id, Entities[role], 0, role === UserRole.CUSTOMER ? 'verification' : Projection.FULL);
    }

    componentWillReceiveProps(nextProps) {
        const { account } = nextProps;

        if (!this.state.startedFetching && account.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { account, t } = this.props;

        const finishFetching = this.state.startedFetching && !account.isFetching;

        if (finishFetching) {
            if (account.error) return <ErrorMessage object={account} />;
            else {
                const initialValues = {
                    id: account.content.id,
                    role: account.content.role,
                };

                return (
                    <PasswordEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { patch, handleClose, closeDrawer } = this.props;

        patch(data, Entities[data.role], 0, data.role === UserRole.CUSTOMER ? 'verification' : Projection.FULL);

        if (closeDrawer) closeDrawer();

        handleClose();
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        account: getUpdatedEntity(state, Entities[ownProps.role]),
    };
};

export default connect(mapStateToProps, { fetchById, patch }, null, { withRef: true })(PasswordEditModal);
