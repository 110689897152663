import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import AdminGlobalSettingsAdministration from 'src/components/admin/global-settings/admin-global-settings-administration';

const mapStateToProps = state => {
    return {
        globalSettings: getEntities(state, Entities.GLOBAL_SETTINGS),
    };
};

const AdminGlobalSettingsAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    patch,
    addNotification,
})(AdminGlobalSettingsAdministration);

export default AdminGlobalSettingsAdministrationContainer;
