import React, { Component } from 'react';

class CalculationBuses extends Component {
    render() {
        const { buses } = this.props;
        return (
            <ul className="list-group">
                {buses.map(bus => (
                    <li className="list-group-item" key={bus.id}>
                        {bus.name}
                    </li>
                ))}
            </ul>
        );
    }
}

export default CalculationBuses;
