import { TFunction } from 'i18next';
import moment from 'moment';

export enum TripBookingFilter {
    FUTURE = 'FUTURE',
    PAST = 'PAST',
    CANCELLED = 'CANCELLED',
    ALL = 'ALL',
}

export interface BookingFilterState {
    name: TripBookingFilter;
    condition: (booking: any) => boolean;
    tableHeader: string;
    fetchQuery: string;
}

export const getTripBookingFilterState = (filter: TripBookingFilter, t: TFunction): BookingFilterState => {
    const futureFilterState: BookingFilterState = {
        name: TripBookingFilter.FUTURE,
        condition: (booking: any) => moment(booking.to).isSameOrAfter(moment()) && !booking.cancelled,
        tableHeader: t('booked_tasks.current_bookings'),
        fetchQuery: 'findByCompanyIdInFuture',
    };

    switch (filter) {
        case TripBookingFilter.FUTURE:
            return futureFilterState;
        case TripBookingFilter.PAST:
            return {
                name: TripBookingFilter.PAST,
                condition: (booking: any) => moment(booking.to).isBefore(moment()) && !booking.cancelled,
                tableHeader: t('booked_tasks.past_bookings'),
                fetchQuery: 'findByCompanyIdInPast',
            };
        case TripBookingFilter.CANCELLED:
            return {
                name: TripBookingFilter.CANCELLED,
                condition: (booking: any) => booking.cancelled,
                tableHeader: t('booked_tasks.cancelled_bookings'),
                fetchQuery: 'findByCompanyIdCancelled',
            };
        case TripBookingFilter.ALL:
            return {
                name: TripBookingFilter.ALL,
                condition: (booking: any) => true,
                tableHeader: t('booked_tasks.all_bookings'),
                fetchQuery: 'findByCompanyId',
            };
        default:
            return futureFilterState;
    }
};
