import { connect } from 'react-redux';

import { deleteById, Entities, fetchAll, getEntities } from 'src/common/index';
import AdminHelpCategoryAdministration from 'src/components/admin/help/categories/admin-help-category-administration';

const mapStateToProps = state => {
    return {
        helpCategories: getEntities(state, Entities.HELP_CATEGORY),
    };
};

const AdminHelpCategoryAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    deleteById,
})(AdminHelpCategoryAdministration);

export default AdminHelpCategoryAdministrationContainer;
