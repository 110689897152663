import React from 'react';
import { Field } from 'redux-form';
import {
    countryMenuItems,
    genderMenuItems,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { InjectedTranslateProps } from 'src/types';

interface OwnProps {
    readOnly?: boolean;
    hideLegend?: boolean;
    readOnlyTripCompany?: boolean;
}

type Props = OwnProps & InjectedTranslateProps;

const ContactDataInputFields = (props: Props) => {
    const { readOnly, readOnlyTripCompany, hideLegend, t } = props;

    const isReadonly = readOnly || readOnlyTripCompany ? true : false;
    return (
        <>
            {!hideLegend && <legend className="legend">{t('customer_details.contact_data')}</legend>}
            <div className="form-group">
                <div className="col-md-12">
                    <Field
                        name="contactData.gender"
                        label={`${t('contact_data.gender')} *`}
                        component={renderSelectField}
                        disabled={isReadonly}>
                        {genderMenuItems(t)}
                    </Field>
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.firstName"
                        label={`${t('contact_data.first_name')} *`}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.lastName"
                        label={`${t('contact_data.last_name')} *`}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.street"
                        label={`${t('contact_data.street')} *`}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.postCode"
                        label={`${t('contact_data.post_code')} *`}
                        hintText={t('contact_data.post_code_hint')}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.city"
                        label={`${t('contact_data.city')} *`}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.country"
                        label={`${t('contact_data.country')} *`}
                        component={renderSelectField}
                        disabled={isReadonly}>
                        {countryMenuItems()}
                    </Field>
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.phone"
                        label={t('contact_data.tel_number')}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.email"
                        label={`${t('contact_data.email')} *`}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
                <div className="col-md-12">
                    <Field
                        name="contactData.homepage"
                        label={t('contact_data.homepage')}
                        component={renderInput}
                        disabled={isReadonly}
                    />
                </div>
            </div>
        </>
    );
};

export default ContactDataInputFields;
