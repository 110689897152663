import { TFunction } from 'i18next';
import React from 'react';
import { blue700 } from 'material-ui/styles/colors';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import ArrowForward from 'material-ui/svg-icons/navigation/arrow-forward';
import ListIcon from 'material-ui/svg-icons/action/list';
import BanIcon from 'material-ui/svg-icons/navigation/cancel';
import { withTranslation } from 'react-i18next';
import Navigation, { ComponentRoutes } from 'src/components/nav/Navigation';
import TripDetails from 'src/components/trips/calendar/details/TripDetails';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { TripBookingFilter } from 'src/components/trips/bookings/booking-administration-filter';

/**
 * Compose correct trip booking path
 */
const composeTripBookingPath = (route: string) => `/trips/bookings?filter=${route}`;

/**
 * Defines all trip booking menu items. The order matters
 */
const bookingRoutes = (t: TFunction): ComponentRoutes => [
    {
        path: composeTripBookingPath(TripBookingFilter.FUTURE),
        icon: <ArrowForward color={blue700} />,
        primaryText: t('trip_operator.current'),
    },
    {
        path: composeTripBookingPath(TripBookingFilter.PAST),
        icon: <ArrowBack color={blue700} />,
        primaryText: t('trip_operator.past'),
    },
    {
        path: composeTripBookingPath(TripBookingFilter.CANCELLED),
        icon: <BanIcon color={blue700} />,
        primaryText: t('booked_tasks.cancelled'),
    },
    {
        path: composeTripBookingPath(TripBookingFilter.ALL),
        icon: <ListIcon color={blue700} />,
        primaryText: t('common_phrases.all'),
    },
];

/**
 * Renders the trip operator's  booking administration sidebar on the left hand side
 * and the related content on the right
 */
class TripBookingNavigation extends React.Component<any, {}> {
    render() {
        const { t } = this.props;

        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <Navigation routes={bookingRoutes(t)} />
                </div>

                <div id="tasklist-panel" className="col-md-10">
                    {this.props.children}

                    {/* Details */}
                    <div className="row">
                        <div className="tripdetailscontainer">
                            <TripDetails t={t} openModal={this.props.openModal} modal={this.props.modal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(TripBookingNavigation));
