import moment from 'moment';
import { TripEventResource, TripEventType } from 'src/selectors/trip-calendar-events/trip-calendar-events';

/**
 * Creates trip blockage events of the given blockages
 */
export const createBlockageEvents = blockages => {
    return blockages.map(blockage => createBlockageEvent(blockage));
};

/**
 * Creates a single trip blockage calendar event with the given blockage
 */
export const createBlockageEvent = blockage => {
    return {
        id: blockage.id,
        title: `${blockage.name}${blockage.fullBlock ? '' : ` - ${blockage.reducedPax} PAX`}`,
        resourceId: TripEventResource.BOOKING,
        start: moment(blockage.from),
        end: moment(blockage.to),
        color: blockage.fullBlock ? '#e64a19' : '#ff5722',
        borderColor: 'lightgrey',
        type: TripEventType.BLOCKAGE,
        pax: blockage.reducedPax,
        fullBlock: blockage.fullBlock,
        entity: blockage,
    };
};
