import { defaultState, Entities, entitiesByResult } from 'src/common/index';
import { TFunction } from 'i18next';
import { createSelector } from 'reselect';
import store from 'src/store/store';

const getStateOfBookingIntegrations = (state: any) => state.bookingIntegrations[0] || defaultState();
const getBookingIntegrationEntities = (state: any) => state.entities[Entities.BOOKING_INTEGRATION.repository];

export const getOwnBookingIntegration = createSelector(
    [getStateOfBookingIntegrations, getBookingIntegrationEntities],
    (stateOfIntegrations, integrationEntities) => {
        const bookingIntegrationId = stateOfIntegrations.result[0];

        let content;
        if (integrationEntities && bookingIntegrationId) content = integrationEntities[bookingIntegrationId];

        return {
            isFetching: stateOfIntegrations.isFetching,
            lastUpdated: stateOfIntegrations.lastUpdated,
            error: stateOfIntegrations.error,
            action: stateOfIntegrations.action,
            content: content,
        };
    },
);

export const getOwnBookingIntegrations = createSelector(
    [getStateOfBookingIntegrations, getBookingIntegrationEntities],
    (stateOfIntegrations, integrationEntities) => {
        return {
            isFetching: stateOfIntegrations.isFetching,
            lastUpdated: stateOfIntegrations.lastUpdated,
            error: stateOfIntegrations.error,
            action: stateOfIntegrations.action,
            items: entitiesByResult(stateOfIntegrations.result, integrationEntities),
        };
    },
);

/**
 * Checks if the company of the logged-in user
 * is related to an operating tool integration
 * @returns {boolean}
 */
export const isIntegration = (): boolean => {
    const integration = store.getState().integration;

    return integration !== undefined && integration !== null;
};

export const getIntegrationFieldReadOnlyText = (t: TFunction): string =>
    isIntegration() ? t('synchronized_field') : '';
