import { Entities, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export function assignFleetCompanies(fleetId, companies) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.FLEET.repository}/${fleetId}/assignCompanies`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.FLEET.action}`,
                    `SUCCESS_UPDATE_${Entities.FLEET.action}`,
                    `FAILURE_${Entities.FLEET.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.FLEET.schema,
                body: JSON.stringify(companies),
            }),
        );
    };
}

export function assignFleetAccounts(fleetId, accounts) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.FLEET.repository}/${fleetId}/assignAccounts`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.FLEET.action}`,
                    `SUCCESS_UPDATE_${Entities.FLEET.action}`,
                    `FAILURE_${Entities.FLEET.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.FLEET.schema,
                body: JSON.stringify(accounts),
            }),
        );
    };
}
