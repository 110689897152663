import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Entities,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    Projection,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import CustomerGroupDiscountEditForm from 'src/components/administration/customer-group-discount-edit-form';
import autoBind from 'react-autobind';
import moment from 'moment';

class CustomerGroupDiscountEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, fetchById, selectedCompany } = this.props;

        if (!isCreate) fetchById(id, Entities.CUSTOMER_GROUP_DISCOUNT, selectedCompany, Projection.FULL);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { customerGroupDiscount } = nextProps;

        if (!this.state.startedFetching && customerGroupDiscount.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { isCreate, customerGroupDiscount, customerGroups, selectedCompany, t } = this.props;

        const finishFetching =
            this.state.startedFetching && !customerGroups.isFetching && !customerGroupDiscount.isFetching;

        if (finishFetching) {
            // check for errors
            if (customerGroupDiscount.error) return <ErrorMessage object={customerGroupDiscount} />;
            // if everything is ok populate form
            else {
                let initialValues;

                if (!isCreate && customerGroupDiscount.content) {
                    let customerGroup;
                    if (customerGroupDiscount.content.customerGroup)
                        customerGroup = customerGroups.items.find(
                            customerGroup => customerGroup.id === customerGroupDiscount.content.customerGroup.id,
                        );

                    initialValues = {
                        id: customerGroupDiscount.content.id,
                        customerGroup: customerGroup ? customerGroup._links.self.href : undefined,
                        discount: customerGroupDiscount.content.discount,
                        effectiveFrom:
                            customerGroupDiscount.content.effectiveFrom &&
                            moment(customerGroupDiscount.content.effectiveFrom).format('DD.MM.YYYY'),
                        effectiveUntil:
                            customerGroupDiscount.content.effectiveUntil &&
                            moment(customerGroupDiscount.content.effectiveUntil).format('DD.MM.YYYY'),
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                } else {
                    initialValues = {
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }

                return (
                    <CustomerGroupDiscountEditForm
                        t={t}
                        initialValues={initialValues}
                        customerGroups={customerGroups}
                        onSubmit={this.handleSubmit}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose, selectedCompany } = this.props;

        if (data.effectiveFrom) data.effectiveFrom = moment(data.effectiveFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
        if (data.effectiveUntil) data.effectiveUntil = moment(data.effectiveUntil, 'DD.MM.YYYY').format('YYYY-MM-DD');

        if (!isCreate) this.props.patch(data, Entities.CUSTOMER_GROUP_DISCOUNT, selectedCompany, Projection.FULL);
        else this.props.save(data, Entities.CUSTOMER_GROUP_DISCOUNT, selectedCompany, Projection.FULL);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        customerGroupDiscount: getUpdatedEntity(state, Entities.CUSTOMER_GROUP_DISCOUNT, state.selectedCompany),
        customerGroups: getEntities(state, Entities.CUSTOMER_GROUP),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(CustomerGroupDiscountEditModal);
