import React, { Component } from 'react';
import autoBind from 'react-autobind';
import CircularProgress from 'material-ui/CircularProgress';
import { cleanHtml } from 'src/utils/helpers';

class RichEditor extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            richTextEditor: undefined,
            editorState: undefined,
        };
    }

    componentDidMount() {
        require.ensure([], () => {
            const RichTextEditor = require('react-rte').default;
            this.setState({
                richTextEditor: RichTextEditor,
                editorState: this.props.isCreate
                    ? RichTextEditor.createEmptyValue()
                    : RichTextEditor.createValueFromString(this.props.value, 'html'),
            });
        });
    }

    render() {
        const { t } = this.props;
        if (this.state.richTextEditor) {
            const RichTextEditor = this.state.richTextEditor;
            const toolbarConfig = {
                display: this.props.noLinks
                    ? ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS']
                    : [
                          'INLINE_STYLE_BUTTONS',
                          'BLOCK_TYPE_BUTTONS',
                          'LINK_BUTTONS',
                          'BLOCK_TYPE_DROPDOWN',
                          'HISTORY_BUTTONS',
                      ],
                INLINE_STYLE_BUTTONS: [
                    { label: t('editor.bold'), style: 'BOLD', className: 'custom-css-class' },
                    { label: t('editor.italic'), style: 'ITALIC' },
                    { label: t('editor.underline'), style: 'UNDERLINE' },
                ],
                BLOCK_TYPE_DROPDOWN: [
                    { label: t('editor.unstyled'), style: 'unstyled' },
                    { label: t('editor.headline'), style: 'header-four' },
                ],
                BLOCK_TYPE_BUTTONS: [
                    { label: t('editor.unordered_list'), style: 'unordered-list-item' },
                    { label: t('editor.ordered_list'), style: 'ordered-list-item' },
                ],
            };

            return (
                <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={this.state.editorState}
                    placeholder={this.props.placeholder}
                    onChange={editorState => {
                        this.setState({ editorState: editorState });
                        this.props.onChange(cleanHtml(editorState.toString('html')));
                    }}
                    disabled={this.props.disabled}
                />
            );
        } else {
            return <CircularProgress />;
        }
    }
}

export default RichEditor;
