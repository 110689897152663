import React, { Component } from 'react';
import autoBind from 'react-autobind';
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

import { ButtonColors } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ImageCropper from 'src/components/images/image-cropper';
import { TOMCAT_URL } from 'src/common/index';

class Gallery extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    onClick(e) {
        e.preventDefault();

        const { type, handleGalleryImages, multiple, value, t } = this.props;

        this.props.openModal({
            component: ImageCropper,
            componentProps: {
                multiple: multiple,
                type: type,
                handleGalleryImages: handleGalleryImages,
                value: value,
                translationFunction: t,
            },
            title: t('images.upload_pictures'),
            successButtonLabel: t('images.upload'),
        });
    }

    onDelete() {
        const { handleGalleryImages, value } = this.props;
        value.splice(this._imageGallery.getCurrentIndex(), 1);
        handleGalleryImages(value);
    }

    render() {
        const { value, documents, t } = this.props;

        const items = [];
        if (value.length !== 0) {
            value.map(document => {
                const imageURL = `${TOMCAT_URL}public/documents/${document.filename}`;
                const thumbnailURL = `${TOMCAT_URL}public/documents/${document.filename}`;
                items.push({
                    original: imageURL,
                    thumbnail: thumbnailURL,
                });
            });
        }

        if (documents.uploading) return <CircularProgress />;
        else
            return (
                <div>
                    <div id="further-bus-pictures" className="row">
                        {t('images.add_more_pictures')}
                        <button onClick={this.onClick} className="btn btn-md transparent">
                            <em className="fa fa-camera" />
                        </button>
                    </div>
                    <div>
                        {value.length !== 0 && (
                            <button
                                type="button"
                                className="btn btn-xs transparent pull-right"
                                title={t('user_functionality.delete')}
                                onClick={() => {
                                    this.props.openModal({
                                        component: ConfirmDialog,
                                        componentProps: {
                                            event: value,
                                            onProceedDialog: this.onDelete,
                                            bodyText: t('images.confirm_delete_picture'),
                                            proceedText: t('user_functionality.delete'),
                                            cancelText: t('common_phrases.no'),
                                            cancelButtonColor: ButtonColors.NOTIFY,
                                            proceedButtonColor: ButtonColors.DANGER,
                                        },
                                        title: t('images.delete_picture'),
                                        noButtons: true,
                                    });
                                }}>
                                <span className="glyphicon glyphicon-trash text-danger" />
                            </button>
                        )}
                        <ImageGallery
                            ref={i => (this._imageGallery = i)}
                            items={items}
                            showThumbnails={true}
                            slideOnThumbnailHover={true}
                            showBullets={true}
                        />
                    </div>
                </div>
            );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
    };
};

export default composeModalActions(connect(mapStateToProps)(Gallery));
