export const calculateAvailableTicketsPercentages = (quantity, countSold, countExpired) => {
    if (quantity === 0) return 0;

    return (100 * calculateAvailableTickets(quantity, countSold, countExpired)) / quantity;
};

export const calculateAvailableTickets = (quantity, countSold, countExpired) => {
    return quantity - countSold - countExpired;
};

export const getExpiredTickets = ticketCollection => {
    if (!ticketCollection.valid) return ticketCollection.quantity - ticketCollection.countSold;

    return 0;
};
