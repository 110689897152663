import React, { Component } from 'react';
import { TaskType } from 'src/utils/constants';
import CustomTooltip from 'src/components/misc/CustomTooltip';
import { withTranslation } from 'react-i18next';
import { formatMoney } from 'src/common/index';

class Price extends Component {
    render() {
        const { eventDetails, t } = this.props;

        // task is in exchange
        if (eventDetails.event.exchangedTasks && eventDetails.event.exchangedTasks.length > 0)
            return this.getPriceFromExchangedTask();
        // booked task
        else if (eventDetails.event.customer !== null) return getBookedPrice(eventDetails.event, t);
        // self created task
        else return <p>{formatMoney(eventDetails.event.price)}</p>;
    }

    getPriceFromExchangedTask() {
        const { eventDetails, t } = this.props;

        if (eventDetails.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING)
            return (
                <div className="row">
                    <div className="col-md-12">
                        <span className="pull-right">{formatMoney(eventDetails.event.reducedPrice)}</span>
                    </div>
                    <div className="col-md-12">
                        <span
                            className="glyphicon glyphicon-transfer text-warning"
                            title={t('task_details.released_price')}
                        />
                        <span className="pull-right">{formatMoney(eventDetails.event.exchangedTasks[0].price)}</span>
                    </div>
                </div>
            );
        else return <p>{formatMoney(eventDetails.event.exchangedTasks[0].price)}</p>;
    }
}

export default withTranslation()(Price);

export const getBookedPrice = (task, t) => {
    const tooltipContent = renderPriceDetails(task, t);
    if (!tooltipContent)
        // booking without any discounts
        return <span>{formatMoney(task.price)}</span>;

    // task with discounts
    return (
        <div>
            <CustomTooltip text={tooltipContent} id={`tooltip-price-${task.id}`} place="right">
                <span className="fa fa-money text-success" />
                <span style={{ marginLeft: '5px' }}>{formatMoney(task.reducedPrice)}</span>
            </CustomTooltip>
        </div>
    );
};

function renderPriceDetails(task, t) {
    const { couponPriceDisplayRows } = task;

    if (couponPriceDisplayRows.length > 1)
        return (
            <div className="col-md-12">
                {couponPriceDisplayRows.map((priceDisplayRow, index) => {
                    return priceRowRender(
                        index,
                        t(`priceDetails.${priceDisplayRow.type}`),
                        priceDisplayRow.discount != null ? priceDisplayRow.discount : priceDisplayRow.price,
                        priceDisplayRow.type === 'FINAL' ? 'sum-price-divider' : '',
                        priceDisplayRow.info,
                    );
                })}
            </div>
        );
    else return null;
}

function priceRowRender(key, label, value, classNames, adornment) {
    return (
        <div className="row" key={key}>
            <div className={`col-md-6 text-align-left ${classNames}`}>
                <strong>
                    {label}&nbsp;{`${adornment ? `(${adornment})` : ''}`}
                </strong>
            </div>
            <div className={`col-md-6 text-align-right ${classNames}`}>
                <strong>{formatMoney(value)}</strong>
            </div>
        </div>
    );
}
