import React, { Component } from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import Toggle from 'material-ui/Toggle';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors } from 'src/utils/constants';
import { asyncRequest, CompanyOrigin, Entities, Projection, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import AdminCompanyStats from 'src/components/admin/companies/admin-company-stats';
import InternalRatingModal from 'src/components/admin/companies/internal-rating-modal';
import CompanyEditModal from 'src/components/company/company-edit-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import {
    apisFormatter,
    countryFlag,
    countryFormatter,
    integrationFormatter,
    validFormatter,
} from 'src/utils/formatters';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

class AdminCompanyAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            renderSelection: false,
            showCompanyIds: Cookies.get('showCompanyIds') ? Cookies.get('showCompanyIds') === 'true' : false,
            busCompanyTosLoaded: false,
            busCompanyTos: [],
        };
    }

    componentWillMount() {
        const { location, search } = this.props;
        this.fetchCompanies(location, search);
        this.getBusCompanyTos();
    }

    componentDidMount() {
        this.getBusCompanyTos();
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { busCompanies, location, search } = nextProps;

        if (this.props.location.query.filter !== location.query.filter) {
            this.fetchCompanies(location, search);
        }

        if (!this.state.startedFetching && busCompanies.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(busCompanies.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.busCompanies, this.props.busCompanies, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { busCompanies, location, t, i18n } = this.props;
        const { busCompanyTosLoaded } = this.state;

        const finishFetching = this.state.startedFetching && !busCompanies.isFetching;
        const isOnlineRegistrationFilter =
            location.query && location.query.filter === CompanyOrigin.ONLINE_REGISTRATION;

        const tableData = [];
        if (finishFetching && busCompanies.items.length > 0) {
            busCompanies.items.map(busCompany => {
                tableData.push({
                    id: busCompany.id,
                    companyName: busCompany.companyName,
                    registeredCompanyName: busCompany.registeredCompanyName,
                    address: busCompany.contactData.address,
                    internalRating: busCompany.internalRating,
                    reviewScores: busCompany.reviewScores,
                    dummy: busCompany.dummy,
                    partner: busCompany.partner,
                    virtual: busCompany.virtual,
                    visible: busCompany.visible,
                    active: busCompany.active,
                    fleet: busCompany.fleet ? busCompany.fleet.name : undefined,
                    externalCompanyId: busCompany.externalCompanyId,
                    origin: busCompany.origin,
                    token: busCompany.verificationToken,
                    createdAt: busCompany.createdAt,
                    onlinePaymentStatus: busCompany.onlinePaymentStatus,
                    onlinePaymentModuleEnabled: busCompany.onlinePaymentModuleEnabled,
                    country: busCompany.contactData.country,
                    hasAnyAPI: busCompany.apis.hasAny,
                    apis: busCompany.apis,
                    omniPlusON: Boolean(busCompany.externalCompanyId),
                    bookingIntegration: busCompany.bookingIntegration,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('companies.bus_company')}

                    {busCompanyTosLoaded && (
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                this.table.reset();
                                this.props.openModal({
                                    component: CompanyEditModal,
                                    componentProps: {
                                        isCreate: true,
                                        isAdmin: true,
                                        busCompanyTos: this.state.busCompanyTos,
                                    },
                                    title: t('companies.new_company'),
                                    mandatoryFields: true,
                                });
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('companies.new_company')}
                        </button>
                    )}

                    <div className="pull-right">
                        <Toggle
                            label="ID"
                            labelStyle={{
                                fontSize: '16px',
                                color: '#0c66ab',
                                paddingRight: '5px',
                            }}
                            style={{
                                paddingRight: '20px',
                            }}
                            toggled={this.state.showCompanyIds}
                            onToggle={() => {
                                Cookies.set('showCompanyIds', !this.state.showCompanyIds, {
                                    expires: 10 * 365,
                                    secure: true,
                                    sameSite: 'none',
                                });
                                this.setState({ showCompanyIds: !this.state.showCompanyIds });
                            }}
                        />
                    </div>
                </legend>

                {(() => {
                    if (busCompanies.error) return <ErrorMessage object={busCompanies} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign(
                                    {},
                                    this.tableHelper.getOptions(finishFetching, this.state.page, tableData.length),
                                )}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps(this.state.selected))}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={!this.state.showCompanyIds}
                                    dataAlign="right"
                                    width="50"
                                    dataSort={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="companyName"
                                    width="220"
                                    dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    hidden={isOnlineRegistrationFilter}
                                    columnTitle={true}
                                    width="140"
                                    dataField="fleet"
                                    dataSort={true}>
                                    {t('contact_data.fleet')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="country"
                                    width="120"
                                    dataFormat={cell => countryFormatter(cell, i18n.language)}
                                    csvFormat={cell => cell}
                                    dataSort={true}>
                                    {t('contact_data.country')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="reviewScores"
                                    width="80"
                                    dataFormat={this.reviewsFormatter}
                                    csvFormat={this.reviewsFormatter}
                                    dataSort={false}>
                                    {t('companies.customer_rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="70"
                                    hidden={isOnlineRegistrationFilter}
                                    dataAlign="center"
                                    dataFormat={this.formatOnlinePaymentStatus}
                                    dataField="onlinePaymentStatus"
                                    dataSort={true}>
                                    {t('payment.online')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="70"
                                    dataAlign="center"
                                    dataFormat={this.originFormatter}
                                    dataField="origin"
                                    dataSort={true}>
                                    {t('companies.onboarding')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="70"
                                    dataAlign="center"
                                    dataFormat={cell =>
                                        validFormatter(
                                            t,
                                            !cell,
                                            t('companies.initial_activation_done'),
                                            t('companies.initial_activation_pending'),
                                        )
                                    }
                                    dataField="token"
                                    hidden={!isOnlineRegistrationFilter}
                                    dataSort={true}>
                                    {t('companies.initial_activation')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="hasAnyAPI"
                                    width="70"
                                    dataAlign="center"
                                    dataFormat={(cell, row) => apisFormatter(t, row.apis)}
                                    dataSort={true}>
                                    {/*TODO: BUF-1135: translate*/}
                                    {'APIs'}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="omniPlusON"
                                    width="70"
                                    dataAlign="center"
                                    dataFormat={cell => validFormatter(t, cell)}
                                    dataSort={true}>
                                    {/*TODO: BUF-1135: translate*/}
                                    {'OMNIplus ON'}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="bookingIntegration"
                                    width="70"
                                    dataAlign="center"
                                    dataFormat={cell => integrationFormatter(t, cell)}
                                    dataSort={true}>
                                    {t('administration.integration')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="dummy"
                                    width="70"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'dummy')}>
                                    {t('companies.dummy')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="virtual"
                                    width="70"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'virtual')}>
                                    {t('companies.virtual_buses')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="active"
                                    headerText={t('companies.hint_activated')}
                                    width="70"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleBusCompanyActive(row)}>
                                    {t('common_phrases.activated')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="visible"
                                    headerText={t('companies.hint_visible')}
                                    width="70"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'visible')}>
                                    {t('companies.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="partner"
                                    width="70"
                                    dataSort={true}
                                    dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'partner')}>
                                    {t('companies.partner')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="70"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    formatOnlinePaymentStatus(onlinePaymentStatus, company) {
        const { t } = this.props;
        switch (onlinePaymentStatus) {
            case 'ACTIVE':
                return (
                    <span
                        title={t('companies.status_online_payment.active')}
                        className="fa fa-check-circle-o text-success fa-lg"
                    />
                );
            case 'MISSING_DATA':
                return (
                    <span
                        title={t('companies.status_online_payment.missing_data')}
                        className="fa fa-wrench fa-lg text-warning"
                    />
                );
            default:
                if (company.onlinePaymentModuleEnabled)
                    return (
                        <span
                            title={t('companies.status_online_payment.deactivated_by_company')}
                            className="fa fa-building-o text-warning fa-lg"
                        />
                    );

                return (
                    <span
                        title={t('companies.status_online_payment.deactivated_by_admin')}
                        className="fa fa-times-circle-o text-danger fa-lg"
                    />
                );
        }
    }

    fetchCompanies(location, search) {
        const filter = location.query && location.query.filter;

        search(
            Entities.BUS_COMPANY,
            `findAllByOriginOrderByCreatedAt${filter ? `?origin=${filter}` : ''}`,
            0,
            Projection.FULL,
        );
    }

    getBusCompanyTos() {
        asyncRequest({
            method: 'GET',
            endpoint: `${TOMCAT_URL}api/admin/getCompanyTos`,
        })
            .then(response => {
                this.setState({ busCompanyTos: response.json });
                this.setState({ busCompanyTosLoaded: true });
            })
            .catch(error => {
                console.error('Error sending confirmation email', error);
            });
    }

    onProceedDeleteBusCompany(busCompany) {
        this.props.deleteById(busCompany.id, Entities.BUS_COMPANY);
    }

    openConfirmDeleteDialog(busCompany) {
        const { t } = this.props;

        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: busCompany,
                onProceedDialog: this.onProceedDeleteBusCompany,
                bodyText: t('companies.confirm_delete_company'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: busCompany.companyName,
            noButtons: true,
        });
    }

    getCannotDeleteText(entitiesCount, error) {
        const { t } = this.props;

        if (error || !entitiesCount) return t('error_hint.cannot_delete');

        const getFormattedEntitiesCount = (label, count) => {
            if (count > 0)
                return (
                    <li>
                        <span>{`${label}: `}</span>
                        <strong>{count}</strong>
                    </li>
                );
            return null;
        };

        return (
            <div>
                <span>{t('companies.cannot_delete_info')}</span>
                <br />
                <br />
                <ul>
                    {getFormattedEntitiesCount(t('events_enumeration'), entitiesCount.events)}
                    {getFormattedEntitiesCount(t('booked_tasks.integrations'), entitiesCount.integrations)}
                </ul>
            </div>
        );
    }

    openCannotDeleteDialog(message) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: message,
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    openStatsDialog(stats, busCompany) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: <AdminCompanyStats stats={stats} t={this.props.t} />,
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: busCompany.companyName,
            noButtons: true,
        });
    }

    reviewsFormatter(cell) {
        return cell.count ? `${cell.totalScore.toFixed(2)} (${cell.count})` : '-';
    }

    originFormatter(cell, busCompany) {
        const { t } = this.props;
        switch (cell) {
            case CompanyOrigin.ADMIN_CREATE:
                return <span title={t('admin_company.created_by_admin')} className="fa fa-user text-info fa-lg" />;
            case CompanyOrigin.ONLINE_REGISTRATION:
                return (
                    <span
                        title={`${t('admin_company.registered_online')} ${moment(busCompany.createdAt).format(
                            'DD.MM.YYYY',
                        )}`}
                        className="fa fa-address-card text-success fa-lg"
                    />
                );
            case CompanyOrigin.DAIMLER_IMPORT:
                return (
                    <span
                        title={`${t('admin_company.daimler_import')} (${busCompany.externalCompanyId})`}
                        className="fa fa-download text-warning fa-lg"
                    />
                );
            default:
                return <span />;
        }
    }

    internalRatingFormatter(cell, busCompany) {
        return (
            <div>
                {cell}
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: InternalRatingModal,
                            componentProps: {
                                id: busCompany.id,
                            },
                            title: busCompany.companyName,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }

    tableActionsFormatter(cell, busCompany) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CompanyEditModal,
                            componentProps: {
                                id: busCompany.id,
                                isCreate: false,
                                isAdmin: true,
                            },
                            title: (
                                <div>
                                    {countryFlag(busCompany.country)}&nbsp;{busCompany.companyName}
                                </div>
                            ),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('companies.statistics')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${busCompany.id}/stats`).then(
                            response => this.openStatsDialog(response.json, busCompany),
                        )
                    }>
                    <span className="glyphicon glyphicon-info-sign text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${busCompany.id}/deletable`)
                            .then(response => {
                                const entitiesCount = response.json;
                                if (entitiesCount.sum > 0)
                                    this.openCannotDeleteDialog(this.getCannotDeleteText(entitiesCount));
                                else this.openConfirmDeleteDialog(busCompany);
                            })
                            .catch(error => this.openCannotDeleteDialog(this.getCannotDeleteText(undefined, error)))
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }

    toggleFormatter(cell, busCompany, field) {
        return (
            <Toggle
                toggled={busCompany[field]}
                onToggle={() =>
                    this.props.patch(
                        {
                            [field]: !busCompany[field],
                            id: busCompany.id,
                        },
                        Entities.BUS_COMPANY,
                        0,
                        Projection.FULL,
                    )
                }
            />
        );
    }

    getToggleBusCompanyActiveText(busCompany) {
        const { t } = this.props;

        if (busCompany.active) return t('companies.message_deactivate_company');

        if (busCompany.token)
            return (
                <>
                    <div className="alert alert-warning">
                        <h4>{t('companies.initial_activation')}</h4>
                        <span>{t('companies.initial_activation_info')}</span>
                    </div>
                </>
            );

        return t('companies.message_activate_company');
    }

    toggleBusCompanyActive(busCompany) {
        const { t } = this.props;
        return (
            <Toggle
                toggled={busCompany.active}
                onToggle={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: busCompany,
                            onProceedDialog: () => {
                                this.props.patch(
                                    {
                                        active: !busCompany.active,
                                        id: busCompany.id,
                                    },
                                    Entities.BUS_COMPANY,
                                    0,
                                    Projection.FULL,
                                    this.props.fetchAdminNews,
                                );
                            },
                            closeDrawer: this.handleCloseDrawer,
                            bodyText: this.getToggleBusCompanyActiveText(busCompany),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: busCompany.active ? ButtonColors.DANGER : ButtonColors.SUCCESS,
                        },
                        title: busCompany.companyName,
                        noButtons: true,
                    });
                }}
            />
        );
    }
}

export default withTranslation()(composeModalActions(AdminCompanyAdministration));
