import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { Entities, fetchById, getEntities, getUpdatedEntity } from 'src/common/index';

import { fetchTicketCollectionsByEventId } from 'src/actions/event-tickets';

import TicketCollectionAdministration from 'src/components/administration/event-tickets/ticket-collections/ticket-collection-administration';
import { getOwnCompany } from 'src/selectors/bus-companies';

const mapStateToProps = state => {
    return {
        ticketCollections: getEntities(state, Entities.TICKET_COLLECTION),
        event: getUpdatedEntity(state, Entities.TICKET_EVENT),
        company: getOwnCompany(state),
    };
};

const TicketCollectionAdministrationContainer = connect(mapStateToProps, {
    fetchTicketCollectionsByEventId,
    fetchById,
    push,
})(TicketCollectionAdministration);

export default TicketCollectionAdministrationContainer;
