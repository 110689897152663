import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
    asyncRequest,
    Entities,
    fetchByCompanyId,
    fetchById,
    getFileNameFromResponse,
    getUpdatedEntity,
    patch,
    TOMCAT_URL,
} from 'src/common/index';
import { addNotification } from 'src/actions/notifications';
import { getActiveDrivers } from 'src/selectors/drivers';
import ErrorMessage from 'src/components/misc/error-message';
import PrintTaskForm from 'src/components/taskadministration/print-task-form';
import FileSaver from 'file-saver';

class PrintTaskModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany, fetchByCompanyId, fetchById } = this.props;

        fetchByCompanyId(Entities.DRIVER, selectedCompany);
        fetchById(id, Entities.TASK, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { task, drivers } = nextProps;

        if (!this.state.startedFetching && (drivers.isFetching || task.isFetching))
            this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    downloadTaskPDF(id) {
        asyncRequest({
            endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/${id}/printTask`,
            headers: { Accept: 'application/pdf' },
        })
            .then(response => {
                response.blob().then(blob => {
                    FileSaver.saveAs(blob, getFileNameFromResponse(response));
                });
            })
            .catch(error => {
                console.error('Error downloading offer PDF', error);
            });
    }

    sendTaskToDrivers(id, firstDriverEmail, secondDriverEmail) {
        const { t } = this.props;
        const formData = new FormData();
        formData.append('firstDriverEmail', firstDriverEmail);
        formData.append('secondDriverEmail', secondDriverEmail);

        asyncRequest({
            endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/${id}/sendTaskToDrivers`,
            method: 'POST',
            body: formData,
        })
            .then(response =>
                this.props.addNotification(t('common_phrases.success'), t('print.pdf_sent'), 'success', 'tr'),
            )
            .catch(error =>
                this.props.addNotification(t('common_phrases.error'), t('print.error_while_sending'), 'error', 'tr'),
            );
    }

    render() {
        const { task, drivers, t } = this.props;

        const finishFetching = this.state.startedFetching && !drivers.isFetching && !task.isFetching;

        if (finishFetching) {
            if (drivers.error) return <ErrorMessage object={drivers} />;
            else if (task.error) return <ErrorMessage object={task} />;
            else {
                const firstDriver = task.content.firstDriver
                    ? drivers.items.find(driver => driver.id === task.content.firstDriver)
                    : undefined;
                const secondDriver = task.content.secondDriver
                    ? drivers.items.find(driver => driver.id === task.content.secondDriver)
                    : undefined;

                const initialValues = {
                    id: task.content.id,
                    firstDriverEmail: firstDriver ? firstDriver.email : '',
                    secondDriverEmail: secondDriver ? secondDriver.email : '',
                    printNotes: task.content.printNotes,
                    sendMail: false,
                };

                return (
                    <PrintTaskForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        bus={task.content.bus}
                        firstDriver={firstDriver}
                        secondDriver={secondDriver}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(formData) {
        const { handleClose, id, selectedCompany, patch } = this.props;

        const data = {
            id: formData.id,
            printNotes: formData.printNotes,
        };

        patch(data, Entities.TASK, selectedCompany).then(() => {
            if (formData.sendMail) this.sendTaskToDrivers(id, formData.firstDriverEmail, formData.secondDriverEmail);

            this.downloadTaskPDF(id);
        });

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        task: getUpdatedEntity(state, Entities.TASK, state.selectedCompany),
        drivers: getActiveDrivers(state),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(mapStateToProps, { fetchByCompanyId, fetchById, patch, addNotification }, null, {
    withRef: true,
})(PrintTaskModal);
