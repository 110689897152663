import BasicType from './BasicType';

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    OTHER = 'OTHER',
}

export default class ContactData implements BasicType {
    public gender: Gender;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public street: string;
    public postCode: string;
    public city: string;
    public country: string;
    public phone: string;
    public email: string;
    public homepage: string;

    constructor(
        gender: Gender,
        firstName: string,
        lastName: string,
        fullName: string,
        street: string,
        postCode: string,
        city: string,
        country: string,
        phone: string,
        email: string,
        homepage: string,
    ) {
        this.gender = gender;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName = fullName;
        this.street = street;
        this.postCode = postCode;
        this.city = city;
        this.country = country;
        this.phone = phone;
        this.email = email;
        this.homepage = homepage;
    }

    public toString(): string {
        return this.fullName;
    }

    public equals(other: ContactData): boolean {
        return (
            this.gender === other.gender &&
            this.firstName === other.firstName &&
            this.lastName === other.lastName &&
            this.email === other.email &&
            this.phone === other.phone
        );
    }

    public static fromJson(json: any): ContactData {
        return new ContactData(
            json.gender,
            json.firstName,
            json.lastName,
            json.fullName,
            json.street,
            json.postCode,
            json.city,
            json.country,
            json.phone,
            json.email,
            json.homepage,
        );
    }

    public static getDevContactData(): ContactData {
        return new ContactData(
            Gender.MALE,
            'Rechner',
            'Herz',
            'Rechnerherz',
            'Hetzgasse 12/6',
            '1030',
            'Wien',
            'AT',
            '0123456789',
            'test@rechnerherz.at',
            'https://www.rechnerherz.at',
        );
    }
}
