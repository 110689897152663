import moment from 'moment';
import {
    CalendarResourceGroup,
    CalendarResourceType,
    EventType,
    OfferStatus,
    ReleaseMode,
    TaskType,
} from 'src/utils/constants';
import { isNullOrUndefined } from 'src/utils/helpers';

export default class EventRules {
    static allowPatchEvent(event, resource, resourceIdDragStart) {
        if (
            event.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING ||
            event.type === TaskType.MY_ASSIGNED_EXCHANGED_TASK ||
            event.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING ||
            event.type === TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED
        )
            return false;

        if (
            resourceIdDragStart === CalendarResourceType.TASK_INTERN &&
            (event.resourceId === CalendarResourceType.VEHICLE_EXTERN ||
                event.resourceId === CalendarResourceType.TASK_EXTERN_OWN ||
                event.resourceId === CalendarResourceType.OFFER ||
                event.resourceId === CalendarResourceType.TASK_EXTERN_FOREIGN)
        )
            return false;

        if (
            resourceIdDragStart === CalendarResourceType.TASK_EXTERN_FOREIGN &&
            (event.resourceId === CalendarResourceType.VEHICLE_EXTERN ||
                event.resourceId === CalendarResourceType.TASK_EXTERN_OWN ||
                event.resourceId === CalendarResourceType.OFFER ||
                event.resourceId === CalendarResourceType.TASK_INTERN)
        )
            return false;

        if (
            (resourceIdDragStart !== CalendarResourceType.OFFER && event.resourceId === CalendarResourceType.OFFER) ||
            (resourceIdDragStart === CalendarResourceType.OFFER && event.resourceId !== CalendarResourceType.OFFER)
        )
            return false;

        if (resource.groups === CalendarResourceGroup.VEHICLES) return false;

        if (!isNullOrUndefined(event.externTaskId)) return false;

        return true;
    }

    static allowResizeWithModal(event) {
        return (
            event.type !== TaskType.MY_OPEN_EXCHANGED_TASK_PENDING &&
            event.type !== TaskType.MY_ASSIGNED_EXCHANGED_TASK &&
            event.type !== TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED &&
            event.type !== TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING &&
            isNullOrUndefined(event.externTaskId)
        );
    }

    static allowBlockageReleaseDrop(event, busResource) {
        return !(
            (event.type === EventType.BLOCKAGE && busResource.releaseMode === ReleaseMode.BLOCKED) ||
            ((event.type === EventType.BLOCKAGE || event.type === EventType.RELEASE) && busResource.groups)
        );
    }

    static isTask(event) {
        return event.type !== EventType.RELEASE && event.type !== EventType.BLOCKAGE;
    }

    static busHasEnoughSeatsForTask(event, busResource) {
        return (
            busResource.seatsFacingBack + busResource.seatsFacingFront + busResource.seatsForGuides >= event.passengers
        );
    }

    static isTaskEditableCalendar(task) {
        return (
            isNullOrUndefined(task.externTaskId) &&
            (task.type === TaskType.OPEN_OFFER ||
                task.type === TaskType.MY_OPEN_TASK ||
                task.type === TaskType.BOOKED_BY_CUSTOMER_OPEN ||
                task.type === OfferStatus.OPEN ||
                task.type === TaskType.MY_ASSIGNED_TASK ||
                task.type === TaskType.BOOKED_BY_CUSTOMER_ASSIGNED ||
                task.type === TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK ||
                task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED) &&
            moment().isBefore(moment(task.start))
        );
    }
}
