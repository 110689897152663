import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { ButtonColors, genderName, roleName } from 'src/utils/constants';
import { Entities, Projection, UserRole } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import AccountEditModal from 'src/components/account/account-edit-modal';
import PasswordEditModal from 'src/components/account/password-edit-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import { withTranslation } from 'react-i18next';

class AdminAccountAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.OPERATOR, 0, Projection.FULL);
        this.props.fetchAll(Entities.DISPATCHER, 0, Projection.FULL);
        this.props.fetchAll(Entities.BUS_COMPANY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { operators, dispatchers } = nextProps;

        if (!this.state.startedFetching && (operators.isFetching || dispatchers.isFetching))
            this.setState({ startedFetching: true });

        this.tableHelper.processPagination(operators.items.length + dispatchers.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.props.operators.lastUpdated > this.props.dispatchers.lastUpdated) {
            if (this.tableHelper.processPaginationAfterUpdate(prevProps.operators, this.props.operators, this.table))
                this.setState({ renderSelection: !this.state.renderSelection });
        } else {
            if (
                this.tableHelper.processPaginationAfterUpdate(prevProps.dispatchers, this.props.dispatchers, this.table)
            )
                this.setState({ renderSelection: !this.state.renderSelection });
        }
    }

    render() {
        const { operators, dispatchers, busCompanies, t } = this.props;
        const finishFetching =
            this.state.startedFetching && !operators.isFetching && !dispatchers.isFetching && !busCompanies.isFetching;

        const tableData = [];

        if (finishFetching) {
            const accounts = [];
            accounts.push(...operators.items);
            accounts.push(...dispatchers.items);

            if (accounts.length > 0) {
                accounts.map(account => {
                    const company = account.company
                        ? busCompanies.items.find(busCompany => busCompany.id === account.company)
                        : undefined;
                    tableData.push({
                        id: account.id,
                        email: account.email,
                        company: company,
                        companyName: company !== undefined ? company.companyName : '-',
                        firstName: account.contactData.firstName,
                        lastName: account.contactData.lastName,
                        gender: account.contactData.gender,
                        genderName: genderName(account.contactData.gender),
                        address: account.contactData.address,
                        role: account.role,
                        roleName: roleName(account.role),
                        readOnly: account.readOnly,
                        fleet: account.fleet ? account.fleet.name : undefined,
                        externalAccountId: account.externalAccountId,
                        active: account.active,
                    });
                });
            }
        }

        return (
            <div>
                <legend className="legend">
                    {t('header_account')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: AccountEditModal,
                                componentProps: {
                                    role: UserRole.OPERATOR,
                                    isCreate: true,
                                    isAdmin: true,
                                },
                                title: t('account.new_operator'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('account.new_operator')}
                    </button>
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: AccountEditModal,
                                componentProps: {
                                    role: UserRole.DISPATCHER,
                                    isCreate: true,
                                    isAdmin: true,
                                },
                                title: t('account.new_dispatcher'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('account.new_dispatcher')}
                    </button>
                </legend>

                {(() => {
                    if (operators.error) return <ErrorMessage object={operators} />;
                    else if (dispatchers.error) return <ErrorMessage object={dispatchers} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="email" width="200" dataSort={true}>
                                    {t('contact_data.username')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="fleet" width="150" dataSort={true}>
                                    {t('contact_data.fleet')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="companyName"
                                    width="150"
                                    dataSort={true}>
                                    {t('contact_data.bus_company')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="gender"
                                    hidden={true}
                                    dataFormat={genderName}
                                    csvFormat={genderName}>
                                    {t('contact_data.gender')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="firstName" width="120" dataSort={true}>
                                    {t('contact_data.first_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="lastName" width="120" dataSort={true}>
                                    {t('contact_data.last_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="address" width="120" dataSort={true}>
                                    {t('contact_data.address')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} width="120" dataField="roleName" dataSort={true}>
                                    {t('contact_data.role')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="center"
                                    dataFormat={id => externalIdFormatter(id, t)}
                                    width="90"
                                    dataField="externalAccountId"
                                    dataSort={true}>
                                    {t('common_phrases.import')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="readOnly"
                                    width="140"
                                    dataSort={true}
                                    dataFormat={this.readOnlyFormatter}>
                                    {t('common_phrases.read_permission')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="active"
                                    width="90"
                                    dataSort={true}
                                    dataFormat={this.activeFormatter}>
                                    {t('common_phrases.activated')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    activeFormatter(cell, account) {
        const { t } = this.props;

        return (
            <Toggle
                toggled={account.active}
                onToggle={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: account,
                            onProceedDialog: () => {
                                this.props.patch(
                                    {
                                        id: account.id,
                                        role: account.role,
                                        active: !account.active,
                                    },
                                    Entities[account.role],
                                    0,
                                    Projection.FULL,
                                );
                            },
                            closeDrawer: this.handleCloseDrawer,
                            bodyText: account.active
                                ? t('customer_details.delete_information')
                                : t('customer_details.activate_information'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: account.email,
                        noButtons: true,
                    });
                }}
            />
        );
    }

    readOnlyFormatter(cell, account) {
        return (
            <Toggle
                toggled={account.readOnly}
                onToggle={() =>
                    this.props.patch(
                        {
                            id: account.id,
                            role: account.role,
                            readOnly: !account.readOnly,
                        },
                        Entities[account.role],
                        0,
                        Projection.FULL,
                    )
                }
            />
        );
    }

    tableActionsFormatter(cell, account) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: AccountEditModal,
                            componentProps: {
                                id: account.id,
                                role: account.role,
                                isCreate: false,
                                isAdmin: true,
                            },
                            title: `${account.firstName} ${account.lastName}`,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.change_password')}
                    onClick={() => {
                        this.props.openModal({
                            component: PasswordEditModal,
                            componentProps: {
                                id: account.id,
                                role: account.role,
                                isAdmin: true,
                            },
                            title: t('user_functionality.password_change_information', {
                                firstName: account.firstName,
                                lastName: account.lastName,
                            }),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-lock text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: account,
                                onProceedDialog: account => this.props.deleteById(account.id, Entities[account.role]),
                                bodyText: t('customer_details.delete_confirmation'),
                                proceedText: t('user_functionality.delete'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: account.email,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default composeModalActions(withTranslation()(AdminAccountAdministration));

export const externalIdFormatter = (id, t) => {
    // TODO: BUF-1135: translate
    if (id) return <span title={`External ID: ${id}`} className="glyphicon glyphicon-ok text-success" />;
    return <span title={t('common_phrases.not_imported')} className="glyphicon glyphicon-remove text-danger" />;
};
