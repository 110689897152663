import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
    Entities,
    fetchAll,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    Projection,
    save,
    UserRole,
} from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { getOwnCompany } from 'src/selectors/bus-companies';
import ErrorMessage from 'src/components/misc/error-message';
import AccountEditForm from 'src/components/account/account-edit-form';
import { fetchOwnAccount } from 'src/actions/accounts';
import { getOwnAccount } from 'src/selectors/accounts';

class AccountEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, isAdmin, id, role, selectedCompany, fetchById, fetchAll, fetchOwnBusCompany } = this.props;

        if (!isCreate) {
            fetchById(id, Entities[role], 0, Projection.FULL);
        }

        if (isAdmin) {
            if (role === UserRole.TRIP_OPERATOR) fetchAll(Entities.TRIP_COMPANY);
            else fetchAll(Entities.BUS_COMPANY);
        } else fetchOwnBusCompany(selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { account, companies } = nextProps;

        if (!this.state.startedFetching && (account.isFetching || companies.isFetching))
            this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { account, companies, ownCompany, isCreate, isAdmin, role, t } = this.props;

        const finishFetching = this.state.startedFetching && !account.isFetching && !companies.isFetching;

        if (finishFetching) {
            if (account.error) return <ErrorMessage object={account} />;
            else if (isAdmin && companies.error) return <ErrorMessage object={companies} />;
            else if (!isAdmin && ownCompany.error) return <ErrorMessage object={ownCompany} />;
            else {
                let filteredCompanies = companies.items;

                let initialValues;

                if (!isCreate && account.content) {
                    // if fleet exist -> filter only companies of fleet
                    const companiesOfFleet = account.content.fleet
                        ? account.content.fleet.companies.map(company => company.id)
                        : [];
                    if (companiesOfFleet.length > 0)
                        filteredCompanies = companies.items.filter(company => companiesOfFleet.includes(company.id));

                    let companyOfAccount;
                    if (isAdmin) {
                        if (account.content.company)
                            companyOfAccount = companies.items.find(company => company.id === account.content.company);
                    } else {
                        companyOfAccount = ownCompany.entity;
                    }

                    if (
                        account.content.role !== UserRole.SUPER_ADMIN &&
                        account.content.role !== UserRole.ADMIN &&
                        !companyOfAccount
                    )
                        console.warn('Account has no company', account);

                    initialValues = {
                        id: account.content.id,
                        email: account.content.email,
                        role: account.content.role,
                        company: companyOfAccount ? companyOfAccount._links.self.href : null,
                        contactData: {
                            gender: account.content.contactData.gender,
                            firstName: account.content.contactData.firstName,
                            lastName: account.content.contactData.lastName,
                            country: account.content.contactData.country,
                            city: account.content.contactData.city,
                            postCode: account.content.contactData.postCode,
                            street: account.content.contactData.street,
                            phone: account.content.contactData.phone,
                            email: account.content.contactData.email,
                            homepage: account.content.contactData.homepage,
                        },
                        language: account.content.language,
                        apiOperator: account.content.apiOperator,
                        ssoOperator: account.content.ssoOperator,
                    };
                } else {
                    initialValues = {
                        role: role,
                    };
                }

                return (
                    <AccountEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        companies={filteredCompanies}
                        isAdmin={isAdmin}
                        isCreate={isCreate}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { patch, save, isCreate, handleClose, ownAccount } = this.props;

        if (isCreate) save(data, Entities[data.role], 0, Projection.FULL);
        else
            patch(data, Entities[data.role], 0, Projection.FULL, () => {
                if (ownAccount.entity && ownAccount.entity.id === data.id) this.props.fetchOwnAccount();
            });

        handleClose();
    }
}

const mapStateToProps = (state, ownProps) => {
    const companyEntity = ownProps.role === UserRole.TRIP_OPERATOR ? Entities.TRIP_COMPANY : Entities.BUS_COMPANY;
    return {
        ownAccount: getOwnAccount(state),
        account: getUpdatedEntity(state, Entities[ownProps.role]),
        companies: getEntities(state, companyEntity),
        ownCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(
    mapStateToProps,
    { fetchById, patch, save, fetchAll, fetchOwnBusCompany, fetchOwnAccount },
    null,
    {
        withRef: true,
    },
)(AccountEditModal);
