import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import AdminStarRatingAdministration from 'src/components/admin/star-rating/admin-star-rating-administration';

const mapStateToProps = state => {
    return {
        starRatings: getEntities(state, Entities.STAR_RATING),
    };
};

const AdminStarRatingAdministrationContainer = connect(mapStateToProps, { fetchAll })(AdminStarRatingAdministration);

export default AdminStarRatingAdministrationContainer;
