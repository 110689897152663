import _ from 'lodash';
import { Entity } from './Entities';
import { createSelector } from 'reselect';
import BaseEntity from './entity/BaseEntity';

export interface EntityCollectionState<G> {
    isFetching: boolean;
    lastUpdated?: any;
    error?: any;
    action?: object;
    items: G[];
}

export interface SingleEntityState<G> {
    isFetching: boolean;
    lastUpdated?: any;
    error?: any;
    action?: object;
    content?: G;
}

export interface EntityState {
    isFetching: boolean;
    result: number[];
    action?: object;
    error?: any;
    lastUpdated?: any;
    updatedEntity?: number;
}

export const stateOfEntity = (state: any, entity: Entity, reducerIndex: number | string) =>
    state[entity.reducer][reducerIndex || 0] || defaultState();
export const entities = (state: any, entity: Entity) => state.entities[entity.repository];

export const getEntities = createSelector(
    [stateOfEntity, entities],
    (stateOfEntity: EntityState, entities: any): EntityCollectionState<BaseEntity> => {
        return {
            isFetching: stateOfEntity.isFetching,
            lastUpdated: stateOfEntity.lastUpdated,
            error: stateOfEntity.error,
            action: stateOfEntity.action,
            items: entitiesByResult(stateOfEntity.result, entities),
        };
    },
);

export const getUpdatedEntity = createSelector(
    [stateOfEntity, entities],
    (stateOfEntity: EntityState, entities: any): SingleEntityState<BaseEntity> => {
        let content;
        if (entities && stateOfEntity.updatedEntity) content = entities[stateOfEntity.updatedEntity];

        return {
            isFetching: stateOfEntity.isFetching,
            lastUpdated: stateOfEntity.lastUpdated,
            error: stateOfEntity.error,
            action: stateOfEntity.action,
            content: content,
        };
    },
);

export function entitiesByResult(result: number[], entities: any): any[] {
    const items = [];

    if (Array.isArray(result)) {
        if (result.length > 0)
            result.forEach(id => {
                if (_.has(entities, id)) items.push(entities[id]);
            });
    } else items.push(entities[result]);

    return items;
}

export function defaultState(): EntityState {
    return {
        isFetching: false,
        action: {
            type: 'NONE',
        },
        result: [],
    };
}
