import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Pie, PieChart, Sector } from 'recharts';

class CustomPieChart extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            activeIndex: 0,
        };
    }

    onPieEnter(data, index) {
        this.setState({
            activeIndex: index,
        });
    }

    render() {
        const { data, title, sum, width, height, cx, cy, innerRadius, outerRadius, color } = this.props;

        return (
            <div>
                {title && (
                    <h3>
                        {title}: {sum}
                    </h3>
                )}

                <PieChart width={width ? width : 600} height={height ? height : 350}>
                    <Pie
                        dataKey="value"
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx={cx ? cx : 270}
                        cy={cy ? cy : 150}
                        innerRadius={innerRadius ? innerRadius : 70}
                        outerRadius={outerRadius ? outerRadius : 90}
                        fill={color ? color : '#0c66ab'}
                        onMouseEnter={this.onPieEnter}
                    />
                </PieChart>
            </div>
        );
    }
}

export default CustomPieChart;

const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333">{`Count: ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
