import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TableHelper from 'src/utils/table-helper';
import { asyncRequest, Entities, getFileNameFromResponse, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import moment from 'moment';
import FileSaver from 'file-saver';
import { createMonthMenuItems } from 'src/utils/helpers';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import URI from 'urijs';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import SelectAndConfirmDialog from 'src/components/misc/confirm-select-dialog';
import { ButtonColors } from 'src/utils/constants';
import { periodFormatter, priceFormatter } from 'src/utils/formatters';
import { sortMoney } from 'src/utils/currency';

class AdminInvoiceAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            selectedMonth: moment().subtract(1, 'month').startOf('month').format('X'),
            tableData: [],
            availableInvoiceFormats: ['pdf', 'csv-bmd'],
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.INVOICE);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { invoices } = nextProps;

        if (invoices.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(invoices.items.length);
    }

    componentDidUpdate(prevProps, prevState) {
        const { invoices } = prevProps;
        const finishFetching = this.state.startedFetching && !this.props.invoices.isFetching;

        if (this.tableHelper.processPaginationAfterUpdate(prevProps.invoices, this.props.invoices, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });

        if (!_.isEqual(invoices, this.props.invoices) || prevState.selectedMonth !== this.state.selectedMonth) {
            this.setState({
                tableData: this.props.invoices.items
                    .filter(
                        invoice =>
                            !this.state.selectedMonth ||
                            moment.unix(this.state.selectedMonth).isSame(moment(invoice.periodFrom), 'month') ||
                            moment.unix(this.state.selectedMonth).isSame(moment(invoice.periodTill), 'month'),
                    )
                    .map(invoice => {
                        return Object.assign({}, invoice, {
                            companyName: invoice.company.companyName,
                        });
                    }),
            });
        }
    }

    render() {
        const { invoices, t } = this.props;
        const finishFetching = this.state.startedFetching && !invoices.isFetching;

        return (
            <div>
                <legend className="legend">{t('invoice.invoices')}</legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (invoices.error) return <ErrorMessage object={invoices} />;
                    else
                        return (
                            <div>
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={this.state.tableData}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    exportCSV={true}
                                    options={Object.assign(
                                        {
                                            toolBar: this.createCustomToolBar,
                                        },
                                        this.tableHelper.getOptions(finishFetching),
                                    )}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="invoiceDate"
                                        width="180"
                                        dataFormat={this.dateFormatter}
                                        dataSort={true}>
                                        {t('invoice.invoice_date')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="periodFrom"
                                        width="180"
                                        dataFormat={periodFormatter}
                                        dataSort={true}>
                                        {t('invoice.period')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="formattedInvoiceNumber"
                                        width="180"
                                        dataSort={true}>
                                        {t('invoice.invoice_number')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="companyName" dataSort={true}>
                                        {t('common_phrases.company')}
                                    </TableHeaderColumn>
                                    {/* Country code is hidden as a column on the table but visible in the exported CSV */}
                                    <TableHeaderColumn
                                        columnTitle={false}
                                        dataField="contactData.country"
                                        hidden={true}
                                        export={true}
                                        dataFormat={() => ''}
                                        csvFormat={(cell, row) => row.contactData.country}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalNetPrice"
                                        dataFormat={priceFormatter}
                                        csvFormat={priceFormatter}
                                        sortFunc={sortMoney}
                                        dataAlign="right"
                                        width="150"
                                        dataSort={true}>
                                        {t('invoice.net')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalVATPrice"
                                        dataFormat={this.vatFormatter}
                                        csvFormat={this.vatFormatter}
                                        sortFunc={sortMoney}
                                        dataAlign="right"
                                        width="150"
                                        dataSort={true}>
                                        {t('invoice.vat')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalGrossPrice"
                                        dataFormat={priceFormatter}
                                        csvFormat={priceFormatter}
                                        sortFunc={sortMoney}
                                        dataAlign="right"
                                        width="150"
                                        dataSort={true}>
                                        {t('invoice.gross')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalBasicSystemFee"
                                        dataFormat={this.systemFeeFormatter}
                                        csvFormat={this.systemFeeFormatter}
                                        hidden={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalAdvertisingSubsidy"
                                        dataFormat={this.systemFeeFormatter}
                                        csvFormat={this.systemFeeFormatter}
                                        hidden={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalRedeemedCouponValue"
                                        dataFormat={priceFormatter}
                                        csvFormat={priceFormatter}
                                        sortFunc={sortMoney}
                                        dataAlign="right"
                                        width="150"
                                        dataSort={true}>
                                        {t('coupons.coupons')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="totalReducedPrice"
                                        dataFormat={this.sumFormatter}
                                        csvFormat={priceFormatter}
                                        sortFunc={sortMoney}
                                        dataAlign="right"
                                        width="150"
                                        dataSort={true}>
                                        {t('invoice.sum')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                    {/* The following columns are only visible in the exported CSV file */}
                                    <TableHeaderColumn
                                        columnTitle={false}
                                        dataField="totalBookedTasksPrice"
                                        csvFormat={priceFormatter}
                                        hidden={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={false}
                                        dataField="totalModulesPrice"
                                        csvFormat={priceFormatter}
                                        hidden={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn
                                        columnTitle={false}
                                        dataField="totalIntegrationRequestsPrice"
                                        csvFormat={priceFormatter}
                                        hidden={true}
                                        export={true}
                                    />
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    createCustomToolBar = props => {
        const { t } = this.props;

        return (
            <div className="col-md-12">
                <div className="admin-invoice__header" style={{ display: 'flex' }}>
                    <div
                        style={{ display: 'flex', flexFlow: 'row wrap', marginTop: 34, alignItems: 'center', gap: 10 }}>
                        {props.components.exportCSVBtn}
                        <div>
                            <RaisedButton
                                key="downloadInvoices"
                                label={t('invoice.download_month')}
                                primary={true}
                                onClick={this.onDownloadInvoices}
                                labelColor="#ffffff"
                            />
                        </div>
                        <div>
                            <RaisedButton
                                key="sendInvoices"
                                label={t('invoice.send_month.action')}
                                primary={true}
                                onClick={this.openConfirmSendMonthToAll}
                                labelColor="#ffffff"
                            />
                        </div>
                        <div>
                            <RaisedButton
                                disabled={this.state.selectedMonth === false}
                                key="generateInvoices"
                                label={t('invoice.generate.action')}
                                primary={true}
                                onClick={this.openGenerateInvoicesModal}
                                labelColor="#ffffff"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                        <div style={{ width: 200, marginRight: 10 }}>
                            <SelectField
                                value={this.state.selectedMonth}
                                onChange={(event, index, value) => {
                                    this.setState({
                                        selectedMonth: value,
                                    });
                                }}
                                floatingLabelText={t('invoice.month')}
                                fullWidth={true}
                                hintText={t('invoice.select_month')}
                                floatingLabelFixed={true}>
                                {[<MenuItem key={-1} value={false} primaryText={t('common_phrases.all')} />].concat(
                                    createMonthMenuItems(),
                                )}
                            </SelectField>
                        </div>
                        {/*)}*/}
                        <div style={{ width: 400, marginRight: 6, marginTop: 34 }}>{props.components.searchPanel}</div>
                    </div>
                </div>
            </div>
        );
    };

    sumFormatter(price) {
        let color;
        if (price && price.amount) color = price.amount >= 0 ? 'green' : 'red';

        return <span style={{ color: color }}>{priceFormatter(price)}</span>;
    }

    dateFormatter(date) {
        return date ? `${moment(date).format('DD.MM.YYYY')}` : '-';
    }

    vatFormatter(vat, invoice) {
        if (invoice.reverseCharge) return '-';
        return priceFormatter(vat);
    }

    systemFeeFormatter(price) {
        if (price.amount === 0) return '-';
        return priceFormatter(price);
    }

    tableActionsFormatter(cell, invoice) {
        const { t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.INVOICE.repository}/printInvoice?invoiceId=${invoice.id}`,
                        )
                            .then(response => {
                                response.blob().then(blob => {
                                    FileSaver.saveAs(
                                        blob,
                                        `${invoice.formattedInvoiceNumber}_${invoice.companyName}_${moment(
                                            invoice.invoiceDate,
                                        ).format('DD-MM-YYYY')}.pdf`,
                                    );
                                });
                            })
                            .catch(error => {
                                console.error('download error', error);
                            });
                    }}>
                    <span className="glyphicon glyphicon-download-alt text-success" title={t('download_pdf')} />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        asyncRequest({
                            endpoint: `${TOMCAT_URL}api/${Entities.INVOICE.repository}/printInvoiceBmdCsv?invoiceId=${invoice.id}`,
                            headers: { Accept: 'text/csv' },
                        })
                            .then(response => {
                                response.blob().then(blob => {
                                    FileSaver.saveAs(
                                        blob,
                                        `${invoice.totalNetPrice.currency}_bmd_${invoice.formattedInvoiceNumber}_${invoice.companyName}_${moment(
                                            invoice.invoiceDate,
                                        ).format('DD-MM-YYYY')}.csv`,
                                    );
                                });
                            })
                            .catch(error => {
                                console.error('download error', error);
                            });
                    }}>
                    <span className="glyphicon glyphicon-download-alt text-success" title={t('download_csv')} />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => this.openConfirmSendMonthToCompany(invoice.id, invoice.company.companyName)}>
                    <span className="fa fa-envelope text-info" title={t('invoice.send_company.action')} />
                </button>
            </div>
        );
    }

    generateInvoices(periodFrom, periodTill) {
        const { t } = this.props;
        asyncRequest(
            `${TOMCAT_URL}api/${Entities.INVOICE.repository}/generateInvoices?periodFrom=${periodFrom}&periodTill=${periodTill}`,
        )
            .then(() => {
                this.props.fetchAll(Entities.INVOICE);
                this.props.addNotification(t('common_phrases.success'), t('invoice.generate.success'), 'success', 'tr');
            })
            .catch(error => {
                console.error('Error generating invoices', error);
                this.props.addErrorNotification({ code: error.status, message: error.statusText });
            });
    }

    openGenerateInvoicesModal() {
        const { t } = this.props;

        const selectedMonth = moment.unix(this.state.selectedMonth);

        const formattedMonth = selectedMonth.format('YYYY-MM');
        const periodFrom = selectedMonth.format('YYYY-MM-DD');
        const periodTill = selectedMonth.endOf('month').format('YYYY-MM-DD');

        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                onProceedDialog: () => this.generateInvoices(periodFrom, periodTill),
                proceedText: t('invoice.generate.action'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
                bodyText: t('invoice.generate.content', {
                    periodFrom,
                    periodTill,
                }),
            },
            title: t('invoice.generate.title', { formattedMonth }),
            noButtons: true,
        });
    }

    openConfirmSendMonthToAll = () => {
        this.openConfirmSendMonth(this.state.tableData.map(invoice => invoice.id));
    };

    openConfirmSendMonthToCompany = (invoiceId, company) => {
        this.openSelectConfirmSendMonth([invoiceId], company);
    };

    openSelectConfirmSendMonth = (invoiceId, company) => {
        if (this.state.tableData.length === 0) return;

        const { t } = this.props;
        const formattedMonth = moment.unix(this.state.selectedMonth).format('MMMM YYYY');

        const bodyText = company
            ? t('invoice.send_company.content', { formattedMonth, company })
            : t('invoice.send_month.content', { formattedMonth });
        const title = company
            ? t('invoice.send_company.title', { formattedMonth, company })
            : t('invoice.send_month.title', { formattedMonth });

        this.props.openModal({
            component: SelectAndConfirmDialog,
            componentProps: {
                onProceedDialog: event => this.onProceedSendInvoices([invoiceId], company, event),
                bodyText,
                proceedText: t('invoice.send_month.proceed'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
                availableInvoiceFormats: this.state.availableInvoiceFormats,
            },
            title,
            noButtons: true,
        });
    };

    openConfirmSendMonth = (invoiceIds, company) => {
        if (this.state.tableData.length === 0) return;

        const { t } = this.props;
        const formattedMonth = moment.unix(this.state.selectedMonth).format('MMMM YYYY');
        const bodyText = company
            ? t('invoice.send_company.content', { formattedMonth, company })
            : t('invoice.send_month.content', { formattedMonth });
        const title = company
            ? t('invoice.send_company.title', { formattedMonth, company })
            : t('invoice.send_month.title', { formattedMonth });

        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                onProceedDialog: () => this.onProceedSendInvoices(invoiceIds, company),
                bodyText,
                proceedText: t('invoice.send_month.proceed'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
            },
            title,
            noButtons: true,
        });
    };

    onProceedSendInvoices = (invoiceIds, company, invoiceTypes = []) => {
        if (this.state.tableData.length === 0) return;
        const { t } = this.props;

        // build / populate endpoint query
        const endpoint = new URI(`${TOMCAT_URL}api/${Entities.INVOICE.repository}/sendInvoices`);
        endpoint.addSearch('invoiceIds', invoiceIds);
        endpoint.addSearch('invoiceTypes', invoiceTypes);
        endpoint.addSearch('monthYear', moment.unix(this.state.selectedMonth).format('MM-YYYY'));

        asyncRequest(endpoint.toString())
            .then(response => {
                const text = company ? t('invoice.send_company.success', { company }) : t('invoice.send_month.success');
                this.props.addNotification(t('common_phrases.success'), text, 'success', 'tr');
            })
            .catch(error => {
                console.error('Error sending invoices', error);
                this.props.addErrorNotification({ code: error.status, message: error.statusText });
            });
    };

    onDownloadInvoices = () => {
        if (this.state.tableData.length === 0) return;

        const endpoint = new URI(`${TOMCAT_URL}api/${Entities.INVOICE.repository}/printInvoices`);

        endpoint.addSearch(
            'invoiceIds',
            this.state.tableData.map(invoice => invoice.id),
        );
        endpoint.addSearch('monthYear', moment.unix(this.state.selectedMonth).format('MM-YYYY'));

        asyncRequest(endpoint.toString())
            .then(response => {
                response.blob().then(blob => {
                    FileSaver.saveAs(blob, getFileNameFromResponse(response));
                });
            })
            .catch(error => {
                console.error('Error downloading invoices', error);
            });
    };
}

export default withTranslation()(composeModalActions(AdminInvoiceAdministration));
