import Cookies from 'js-cookie';
import CircularProgress from 'material-ui/CircularProgress';
import React from 'react';
import _ from 'lodash';
import i18next from 'i18next';

export default class TableHelper {
    constructor(props) {
        // options for react bootstrap table
        const pageSize = props && props.pageSize ? props.pageSize : Cookies.get('administration-pageSize');
        this.options = {
            sizePerPageList: [10, 20, 50, 100],
            exportCSVText: ' Liste exportieren', // TODO: BUF-1135: translate
            noDataText: <CircularProgress />,
            clearSearch: true,
            sizePerPage: pageSize ? parseInt(pageSize) : 10,
            page: 1,
            onRowClick: props && props.onRowClick,
            onPageChange: (page, size) => {
                this.options.sizePerPage = size;
                this.options.page = page;
                Cookies.set('administration-pageSize', size, { expires: 10 * 365, secure: true, sameSite: 'none' });
            },
        };

        this.selectRowProp = {
            mode: 'radio',
            hideSelectColumn: true,
            clickToSelect: true,
            bgColor: '#BBDEFB',
            selected: [],
        };
    }

    getOptions(finishFetching) {
        if (finishFetching) this.options.noDataText = i18next.t('no_entries');
        else this.options.noDataText = <CircularProgress />;

        return this.options;
    }

    getCustomOptions(isSuccess, isFetching, customNoDataText) {
        if (isSuccess) this.options.noDataText = i18next.t('no_entries');
        else if (isFetching) this.options.noDataText = <CircularProgress />;
        else this.options.noDataText = customNoDataText;

        return this.options;
    }

    getRowProps() {
        return this.selectRowProp;
    }

    setSelectedRow(id) {
        if (id) this.selectRowProp.selected = [id];
        else this.selectRowProp.selected = [];
    }

    // if the current page is out of range (after deleting the last item on a page), reduce the page by one
    processPagination(tableSize) {
        if (tableSize / this.options.sizePerPage < this.options.page)
            this.options.page = this.options.page > 1 ? this.options.page - 1 : 1;
    }

    // after a save or update action, select the modified or new entity and change to the correct page
    processPaginationAfterUpdate(currentEntities, nextEntities, tableRef) {
        if (
            !nextEntities.error &&
            (nextEntities.action.type === 'SAVE' || nextEntities.action.type === 'UPDATE') &&
            !_.isEqual(currentEntities, nextEntities)
        ) {
            const entity = nextEntities.items.find(entity => entity.id === nextEntities.action.payload);

            if (entity) {
                this.options.page = tableRef.getPageByRowKey(entity.id);
                this.selectRowProp.selected = [entity.id];

                return true; // trigger a state change to re-render
            }
        }

        return false;
    }

    /*
     * Only use this if there was a save or update action, but the backend doesn't return the modified entity,
     * but still have to paginate the table
     * (e.g. when a Ratio customer is created)
     */
    forcePaginationAfterUpdate(currentEntities, nextEntities, tableRef) {
        const difference = _.differenceWith(nextEntities.items, currentEntities.items, _.isEqual);
        const isThereADifference = difference.length === 1 && currentEntities.items.length !== 0;

        if (!nextEntities.error && !_.isEqual(currentEntities, nextEntities) && isThereADifference && tableRef) {
            const entity = difference[0];

            if (entity) {
                this.options.page = tableRef.getPageByRowKey(entity.id);
                this.selectRowProp.selected = [entity.id];

                return true; // trigger a state change to re-render
            }
        }

        return false;
    }
}
