import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
    Entities,
    fetchById,
    getUpdatedEntity,
    ModuleType,
    patch,
    save,
    setMoneyCurrency,
    TOMCAT_URL,
    update,
} from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import _ from 'lodash';
import ModuleEditForm from 'src/components/superadmin/modules/module-edit-form';
import { formValueSelector } from 'redux-form';

class ModuleEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.MODULE);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { module } = nextProps;

        if (!this.state.startedFetching && module.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { modules, module, isCreate, selectedCompany, selectedType, t } = this.props;

        const finishFetching = this.state.startedFetching && !module.isFetching;

        if (finishFetching) {
            // check for errors
            if (module.error) return <ErrorMessage object={module} />;
            // if everything is ok populate form
            else {
                let initialValues;

                console.log('module:', module);
                // populate form for editing an existing module
                if (!isCreate && module.content)
                    initialValues = {
                        id: module.content.id,
                        type: module.content.type,
                        fee: module.content.fee,
                        transactionFee: module.content.transactionFee,
                        active: module.content.active,
                        config: parseConfigByModule(module.content), // convert config for form
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                else {
                    initialValues = {
                        active: true,
                        // the currency is ignored in the backend, the company currency is used instead
                        fee: { amount: 0, currency: 'EUR' },
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                }

                return (
                    <ModuleEditForm
                        modules={modules.items}
                        module={module.content}
                        initialValues={initialValues}
                        isCreate={isCreate}
                        selectedType={selectedType}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose, closeDrawer, selectedCompany } = this.props;

        // convert config for backend
        replaceCharacterInConfig(data.config, '-', '.');
        // the currency is ignored in the backend, the company currency is used instead
        setMoneyCurrency(data.fee, 'EUR');
        setMoneyCurrency(data.transactionFee, 'EUR');

        if (!isCreate) {
            delete data.company;
            if (data.type === ModuleType.CUSTOM_MAIL_SERVER)
                this.props.patch(
                    data,
                    Entities.MODULE,
                    selectedCompany,
                    Entities.MODULE.projection,
                    undefined,
                    'update-mail-config',
                );
            else this.props.update(data, Entities.MODULE, selectedCompany);
        } else this.props.save(data, Entities.MODULE, selectedCompany);

        closeDrawer();
        handleClose();
    }
}

/**
 * Replaces all key name characters by the given replace character
 */
const replaceCharacterInConfig = (config, charToReplace, replaceCharacter) => {
    if (!_.isEmpty(config)) {
        Object.keys(config).forEach(key => {
            if (key.indexOf(charToReplace) !== -1) {
                config[key.split(charToReplace).join(replaceCharacter)] = config[key];
                delete config[key];
            }
        });
    }

    return config;
};

/**
 * Parses the config object depending on the module type
 */
const parseConfigByModule = module => {
    module.config = replaceCharacterInConfig(module.config, '.', '-');

    if (module.type === ModuleType.CUSTOM_MAIL_SERVER) {
        parseBoolean(module, 'mail-smtp-auth');
        parseBoolean(module, 'mail-smtp-starttls-enable');
        parseBoolean(module, 'mail-smtp-ssl-enable');
    }

    return module.config;
};

const parseBoolean = (module, setting) => {
    if (setting in module.config)
        module.config[setting] = module.config[setting] === true || module.config[setting] === 'true';
};

const selector = formValueSelector('moduleEditForm');

const mapStateToProps = state => {
    return {
        module: getUpdatedEntity(state, Entities.MODULE),
        selectedType: selector(state, 'type'),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
        update,
    },
    null,
    { withRef: true },
)(ModuleEditModal);
