import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import AssociationEditForm from 'src/components/admin/associations/association-edit-form';
import OrganizationUsersModal from 'src/components/admin/schools/organization-users-modal';
import ErrorMessage from 'src/components/misc/error-message';
import ParentForm from 'src/components/misc/parent-form';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';

class AdminAssociationAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            customerCount: [],
        };
    }

    componentDidMount() {
        this.props.fetchAll(Entities.ASSOCIATION, 0, '');
        this.fetchCustomerCount();
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { associations } = nextProps;
        this.tableHelper.processPagination(associations.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.associations, this.props.associations, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { associations, t } = this.props;

        const tableData = [];
        if (!associations.isFetching && associations.items.length > 0) {
            associations.items.map(association => {
                const associationCustomerCount = this.state.customerCount.find(c => c.id === association.id);
                tableData.push({
                    id: association.id,
                    associationName: association.associationName,
                    registrationNumber: association.registrationNumber,
                    address: association.contactData.address,
                    email: association.contactData.email,
                    phone: association.contactData.phone,
                    busPoints: association.busPoints,
                    customerCount: associationCustomerCount ? associationCustomerCount.customerCount : 0,
                });
            });
        }

        return (
            <div>
                {(() => {
                    if (associations.error) return <ErrorMessage object={associations} />;
                    else
                        return (
                            <div>
                                <legend className="legend">
                                    {t('associations.associations')}
                                    <button
                                        className="btn btn-xs btn-link nav-link pull-right"
                                        onClick={() => {
                                            this.table.reset();
                                            this.props.openModal({
                                                component: ParentForm,
                                                componentProps: {
                                                    isCreate: true,
                                                    mainEntityProps: {
                                                        entity: Entities.ASSOCIATION,
                                                        id: undefined,
                                                    },
                                                    childForm: {
                                                        component: AssociationEditForm,
                                                    },
                                                },
                                                title: t('associations.new_association'),
                                            });
                                        }}>
                                        <span className="glyphicon glyphicon-plus" />
                                        &nbsp;{t('associations.new_association')}
                                    </button>
                                </legend>
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(!associations.isFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="associationName" dataSort={true}>
                                        {t('associations.association')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="registrationNumber"
                                        dataSort={true}>
                                        {t('associations.registration_number')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                        {t('contact_data.address')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="email" dataSort={true}>
                                        {t('contact_data.email')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="phone" width="160" dataSort={true}>
                                        {t('contact_data.tel')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="busPoints"
                                        width="110"
                                        dataSort={true}>
                                        {t('schools.bus_points')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="customerCount"
                                        width="60"
                                        dataSort={true}>
                                        {t('customer_details.customer')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    fetchCustomerCount() {
        asyncRequest(`${TOMCAT_URL}api/associations/search/countCustomers`)
            .then(response => {
                this.setState({ customerCount: response.json });
            })
            .catch(error => {
                this.props.addErrorNotification({ code: error.status, message: error.statusText });
            });
    }

    tableActionsFormatter(cell, association) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.ASSOCIATION,
                                    id: association.id,
                                },
                                projection: 'bus-points',
                                childForm: {
                                    component: AssociationEditForm,
                                },
                            },
                            title: association.associationName,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: OrganizationUsersModal,
                            componentProps: {
                                organizationName: association.associationName,
                                dialogStyle: {
                                    width: '75%',
                                    maxWidth: 'none',
                                },
                            },
                            cancelButtonLabel: t('common_phrases.ok'),
                            title: association.associationName,
                            singleButton: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-user text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminAssociationAdministration));
