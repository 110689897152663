/**
 * Entities holding information about name of reducer, action name, repository and projection
 * in order to communicate with backend and administrate local state (store)
 *
 * To use a new entity only define these 4 options and you're done
 *
 *   ENTITY: {
 *       name: "name of variable"
 *       repository: "path of repository",
 *       projection: "name of projection",
 *       reducer: "name of reducer",
 *       action: "name of action types"
 *   }
 *
 *  Additionally, the normalizr schema will be created in ENTITY.schema (in schemas.js)
 */

export interface Entity {
    name: string;
    repository: string;
    projection?: string;
    reducer: string;
    action: string;
    schema?: any;
}

enum EntityName {
    BUS_BRAND = 'BUS_BRAND',
    BUS_ENGINE = 'BUS_ENGINE',
    BUS_CATEGORY = 'BUS_CATEGORY',
    STAR_RATING = 'STAR_RATING',
    AGE_RATING = 'AGE_RATING',
    BUS_EQUIPMENT = 'BUS_EQUIPMENT',
    BUS = 'BUS',
    SEASON_CALCULATION = 'SEASON_CALCULATION',
    COUNTRY_CALCULATION = 'COUNTRY_CALCULATION',
    CALCULATION = 'CALCULATION',
    BUS_COMPANY = 'BUS_COMPANY',
    TRIP_COMPANY = 'TRIP_COMPANY',
    GLOBAL_SETTINGS = 'GLOBAL_SETTINGS',
    TASK = 'TASK',
    RELEASE = 'RELEASE',
    BLOCKAGE = 'BLOCKAGE',
    REVIEW_CATEGORY = 'REVIEW_CATEGORY',
    REVIEW = 'REVIEW',
    HELP = 'HELP',
    HELP_CATEGORY = 'HELP_CATEGORY',
    TEMPLATE = 'TEMPLATE',
    COUPON = 'COUPON',
    CUSTOMER_GROUP = 'CUSTOMER_GROUP',
    CUSTOMER_GROUP_DISCOUNT = 'CUSTOMER_GROUP_DISCOUNT',
    DISCOUNT_GROUP = 'DISCOUNT_GROUP',
    ACCOUNT = 'ACCOUNT',
    DRIVER = 'DRIVER',
    OPERATOR = 'OPERATOR',
    TRIP_OPERATOR = 'TRIP_OPERATOR',
    DISPATCHER = 'DISPATCHER',
    CUSTOMER = 'CUSTOMER',
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    TRAVEL = 'TRAVEL',
    BOOKING_INTEGRATION = 'BOOKING_INTEGRATION',
    OPERATINGTOOL_INTEGRATION = 'OPERATINGTOOL_INTEGRATION',
    MODULE = 'MODULE',
    INVOICE = 'INVOICE',
    TICKET_EVENT = 'TICKET_EVENT',
    TICKET_EVENT_CATEGORY = 'TICKET_EVENT_CATEGORY',
    TICKET_COLLECTION = 'TICKET_COLLECTION',
    SCHOOL = 'SCHOOL',
    ASSOCIATION = 'ASSOCIATION',
    TRIP_CATEGORY = 'TRIP_CATEGORY',
    TRIP_ATTRIBUTE = 'TRIP_ATTRIBUTE',
    PRODUCT = 'PRODUCT',
    SEASON = 'SEASON',
    TRIP_BLOCKAGE = 'TRIP_BLOCKAGE',
    TRIP_BOOKING = 'TRIP_BOOKING',
    OPERATING_TOOL_DATA_IMPORT_REPORT = 'OPERATING_TOOL_DATA_IMPORT_REPORT',
    FLEET = 'FLEET',
    GLOBAL_TEMPLATE = 'GLOBAL_TEMPLATE',
    SEARCH_BUSES_REQUEST = 'SEARCH_BUSES_REQUEST',
    MAIN_COMPANY_BRANCH_DATA = 'MAIN_COMPANY_BRANCH_DATA',
    RATIO_CUSTOMER = 'RATIO_CUSTOMER',
    BUS_DISCOUNT_CALCULATION = 'BUS_DISCOUNT_CALCULATION',
    CUSTOMER_GROUP_SETTINGS = 'CUSTOMER_GROUP_SETTINGS',
    REFERRALS = 'REFERRALS',
}

export const Entities: { [E in EntityName]: Entity } = {
    [EntityName.BUS_BRAND]: {
        name: 'busBrand',
        repository: 'bus-brands',
        projection: undefined,
        reducer: 'busBrands',
        action: 'BUS_BRANDS',
    },
    [EntityName.BUS_ENGINE]: {
        name: 'busEngine',
        repository: 'bus-engines',
        projection: undefined,
        reducer: 'busEngines',
        action: 'BUS_ENGINES',
    },
    [EntityName.BUS_CATEGORY]: {
        name: 'busCategory',
        repository: 'bus-categories',
        projection: 'default',
        reducer: 'busCategories',
        action: 'BUS_CATEGORIES',
    },
    [EntityName.STAR_RATING]: {
        name: 'starRating',
        repository: 'star-ratings',
        projection: undefined,
        reducer: 'starRatings',
        action: 'STAR_RATINGS',
    },
    [EntityName.AGE_RATING]: {
        name: 'ageRating',
        repository: 'age-ratings',
        projection: undefined,
        reducer: 'ageRatings',
        action: 'AGE_RATINGS',
    },
    [EntityName.BUS_EQUIPMENT]: {
        name: 'busEquipment',
        repository: 'bus-equipments',
        projection: undefined,
        reducer: 'busEquipments',
        action: 'BUS_EQUIPMENTS',
    },
    [EntityName.BUS]: {
        name: 'bus',
        repository: 'buses',
        projection: 'default',
        reducer: 'buses',
        action: 'BUSES',
    },
    [EntityName.SEASON_CALCULATION]: {
        name: 'seasonCalculation',
        repository: 'season-calculations',
        projection: 'default',
        reducer: 'seasonCalculations',
        action: 'SEASON_CALCULATIONS',
    },
    [EntityName.COUNTRY_CALCULATION]: {
        name: 'countryCalculation',
        repository: 'country-calculations',
        projection: 'default',
        reducer: 'countryCalculations',
        action: 'COUNTRY_CALCULATIONS',
    },
    [EntityName.CALCULATION]: {
        name: 'calculation',
        repository: 'calculations',
        projection: 'default',
        reducer: 'calculations',
        action: 'CALCULATIONS',
    },
    [EntityName.BUS_COMPANY]: {
        name: 'busCompany',
        repository: 'bus-companies',
        projection: 'default',
        reducer: 'busCompanies',
        action: 'BUS_COMPANIES',
    },
    [EntityName.TRIP_COMPANY]: {
        name: 'tripCompany',
        repository: 'trip-companies',
        projection: 'default',
        reducer: 'tripCompanies',
        action: 'TRIP_COMPANIES',
    },
    [EntityName.GLOBAL_SETTINGS]: {
        name: 'globalSettings',
        repository: 'global-settings',
        projection: undefined,
        reducer: 'globalSettings',
        action: 'GLOBAL_SETTINGS',
    },
    [EntityName.TASK]: {
        name: 'task',
        repository: 'tasks',
        projection: 'default',
        reducer: 'tasks',
        action: 'TASKS',
    },
    [EntityName.RELEASE]: {
        name: 'release',
        repository: 'releases',
        projection: 'default',
        reducer: 'releases',
        action: 'RELEASES',
    },
    [EntityName.BLOCKAGE]: {
        name: 'blockage',
        repository: 'blockages',
        projection: 'default',
        reducer: 'blockages',
        action: 'BLOCKAGES',
    },
    [EntityName.REVIEW_CATEGORY]: {
        name: 'reviewCategory',
        repository: 'review-categories',
        projection: 'default',
        reducer: 'reviewCategories',
        action: 'REVIEW_CATEGORIES',
    },
    [EntityName.REVIEW]: {
        name: 'review',
        repository: 'reviews',
        projection: 'default',
        reducer: 'reviews',
        action: 'REVIEWS',
    },
    [EntityName.HELP]: {
        name: 'help',
        repository: 'helps',
        projection: 'default',
        reducer: 'helps',
        action: 'HELPS',
    },
    [EntityName.HELP_CATEGORY]: {
        name: 'helpCategory',
        repository: 'help-categories',
        projection: 'default',
        reducer: 'helpCategories',
        action: 'HELP_CATEGORIES',
    },
    [EntityName.TEMPLATE]: {
        name: 'template',
        repository: 'templates',
        projection: 'default',
        reducer: 'templates',
        action: 'TEMPLATES',
    },
    [EntityName.COUPON]: {
        name: 'coupon',
        repository: 'coupons',
        projection: undefined,
        reducer: 'coupons',
        action: 'COUPON',
    },
    [EntityName.CUSTOMER_GROUP]: {
        name: 'customerGroup',
        repository: 'customer-groups',
        projection: undefined,
        reducer: 'customerGroups',
        action: 'CUSTOMER_GROUPS',
    },
    [EntityName.CUSTOMER_GROUP_DISCOUNT]: {
        name: 'customerGroupDiscount',
        repository: 'customer-group-discounts',
        projection: undefined,
        reducer: 'customerGroupDiscounts',
        action: 'CUSTOMER_GROUP_DISCOUNTS',
    },
    [EntityName.DISCOUNT_GROUP]: {
        name: 'discountGroup',
        repository: 'discount-groups',
        projection: undefined,
        reducer: 'discountGroups',
        action: 'DISCOUNT_GROUPS',
    },
    [EntityName.ACCOUNT]: {
        name: 'account',
        repository: 'accounts',
        projection: undefined,
        reducer: 'accounts',
        action: 'ACCOUNTS',
    },
    [EntityName.DRIVER]: {
        name: 'driver',
        repository: 'drivers',
        projection: 'default',
        reducer: 'drivers',
        action: 'DRIVERS',
    },
    [EntityName.OPERATOR]: {
        name: 'operator',
        repository: 'operators',
        projection: 'default',
        reducer: 'operators',
        action: 'OPERATORS',
    },
    [EntityName.TRIP_OPERATOR]: {
        name: 'tripOperator',
        repository: 'trip-operators',
        projection: 'default',
        reducer: 'tripOperators',
        action: 'TRIP_OPERATORS',
    },
    [EntityName.DISPATCHER]: {
        name: 'dispatcher',
        repository: 'dispatchers',
        projection: 'default',
        reducer: 'dispatchers',
        action: 'DISPATCHERS',
    },
    [EntityName.CUSTOMER]: {
        name: 'customer',
        repository: 'customers',
        projection: 'default',
        reducer: 'customers',
        action: 'CUSTOMERS',
    },
    [EntityName.SUPER_ADMIN]: {
        name: 'superAdmin',
        repository: 'super-admins',
        projection: 'default',
        reducer: 'superAdmins',
        action: 'SUPER_ADMINS',
    },
    [EntityName.ADMIN]: {
        name: 'admin',
        repository: 'admins',
        projection: 'default',
        reducer: 'admins',
        action: 'ADMINS',
    },
    [EntityName.TRAVEL]: {
        name: 'travel',
        repository: 'travels',
        projection: 'default',
        reducer: 'travels',
        action: 'TRAVELS',
    },
    [EntityName.BOOKING_INTEGRATION]: {
        name: 'bookingIntegration',
        repository: 'booking-integrations',
        projection: 'default',
        reducer: 'bookingIntegrations',
        action: 'BOOKING_INTEGRATIONS',
    },
    [EntityName.OPERATINGTOOL_INTEGRATION]: {
        name: 'operatingtoolIntegration',
        repository: 'operatingtool-integrations',
        projection: 'default',
        reducer: 'operatingtoolIntegrations',
        action: 'OPERATINGTOOL_INTEGRATIONS',
    },
    [EntityName.MODULE]: {
        name: 'module',
        repository: 'modules',
        projection: 'default',
        reducer: 'modules',
        action: 'MODULES',
    },
    [EntityName.INVOICE]: {
        name: 'invoice',
        repository: 'invoices',
        projection: 'default',
        reducer: 'invoices',
        action: 'INVOICES',
    },
    [EntityName.TICKET_EVENT]: {
        name: 'ticketEvent',
        repository: 'ticket-events',
        projection: 'default',
        reducer: 'ticketEvents',
        action: 'TICKET_EVENTS',
    },
    [EntityName.TICKET_EVENT_CATEGORY]: {
        name: 'ticketEventCategory',
        repository: 'ticket-event-categories',
        projection: 'default',
        reducer: 'ticketEventCategories',
        action: 'TICKET_EVENT_CATEGORIES',
    },
    [EntityName.TICKET_COLLECTION]: {
        name: 'ticketCollection',
        repository: 'ticket-collections',
        projection: 'default',
        reducer: 'ticketCollections',
        action: 'TICKET_COLLECTIONS',
    },
    [EntityName.SCHOOL]: {
        name: 'school',
        repository: 'schools',
        projection: 'default',
        reducer: 'schools',
        action: 'SCHOOLS',
    },
    [EntityName.ASSOCIATION]: {
        name: 'association',
        repository: 'associations',
        projection: 'default',
        reducer: 'associations',
        action: 'ASSOCIATIONS',
    },
    [EntityName.TRIP_CATEGORY]: {
        name: 'tripCategory',
        repository: 'trip-categories',
        projection: 'default',
        reducer: 'tripCategories',
        action: 'TRIP_CATEGORIES',
    },
    [EntityName.TRIP_ATTRIBUTE]: {
        name: 'tripAttribute',
        repository: 'trip-attributes',
        projection: 'default',
        reducer: 'tripAttributes',
        action: 'TRIP_ATTRIBUTES',
    },
    [EntityName.PRODUCT]: {
        name: 'product',
        repository: 'products',
        projection: 'default',
        reducer: 'products',
        action: 'PRODUCTS',
    },
    [EntityName.SEASON]: {
        name: 'season',
        repository: 'seasons',
        projection: 'default',
        reducer: 'seasons',
        action: 'SEASONS',
    },
    [EntityName.TRIP_BLOCKAGE]: {
        name: 'tripBlockage',
        repository: 'trip-blockages',
        projection: 'default',
        reducer: 'tripBlockages',
        action: 'TRIP_BLOCKAGES',
    },
    [EntityName.TRIP_BOOKING]: {
        name: 'tripBooking',
        repository: 'trip-bookings',
        projection: 'default',
        reducer: 'tripBookings',
        action: 'TRIP_BOOKINGS',
    },
    [EntityName.OPERATING_TOOL_DATA_IMPORT_REPORT]: {
        name: 'operatingToolDataImportReport',
        repository: 'ot-integration-reports',
        projection: 'default',
        reducer: 'operatingToolDataImportReports',
        action: 'OPERATING_TOOL_DATA_IMPORT_REPORTS',
    },
    [EntityName.FLEET]: {
        name: 'fleet',
        repository: 'fleets',
        projection: 'default',
        reducer: 'fleets',
        action: 'FLEETS',
    },
    [EntityName.GLOBAL_TEMPLATE]: {
        name: 'globalTemplate',
        repository: 'global-templates',
        projection: 'default',
        reducer: 'globalTemplates',
        action: 'GLOBAL_TEMPLATES',
    },
    [EntityName.SEARCH_BUSES_REQUEST]: {
        name: 'searchBusesRequest',
        repository: 'search-buses-requests',
        projection: 'default',
        reducer: 'searchBusesRequests',
        action: 'SEARCH_BUSES_REQUESTS',
    },
    [EntityName.MAIN_COMPANY_BRANCH_DATA]: {
        name: 'mainCompanyBranchData',
        repository: 'main-company-branch-data',
        projection: 'default',
        reducer: 'mainCompanyBranchData',
        action: 'MAIN_COMPANY_BRANCH_DATA',
    },
    [EntityName.RATIO_CUSTOMER]: {
        name: 'ratioCustomer',
        repository: 'ratio-customer',
        projection: 'default',
        reducer: 'ratioCustomer',
        action: 'RATIO_CUSTOMER',
    },
    [EntityName.BUS_DISCOUNT_CALCULATION]: {
        name: 'busDiscountCalculation',
        repository: 'discount-group-calculation',
        projection: 'default',
        reducer: 'discountGroupCalculation',
        action: 'BUS_DISCOUNT_CALCULATION',
    },
    [EntityName.CUSTOMER_GROUP_SETTINGS]: {
        name: 'customerGroupSettings',
        repository: 'customer-group-settings',
        projection: 'default',
        reducer: 'customerGroupSettings',
        action: 'CUSTOMER_GROUP_SETTINGS',
    },
    [EntityName.REFERRALS]: {
        name: 'customerReferral',
        repository: 'customer-referrals',
        projection: 'default',
        reducer: 'customerReferral',
        action: 'CUSTOMER_REFERRAL_SETTINGS',
    },
};
