import {
    asyncRequest,
    Entities,
    EntityCollectionState,
    FetchBySearch,
    getEntities,
    search,
    TOMCAT_URL,
} from 'src/common/index';
import Money from 'src/common/entity/basic-types/Money';
import DatePicker from 'material-ui/DatePicker';
import Drawer from 'material-ui/Drawer';
import moment from 'moment';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn, ToolBarProps } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { addErrorNotification, addNotification } from 'src/actions/notifications';
import ErrorMessage from 'src/components/misc/error-message';
import { InjectedTranslateProps, StoreState } from 'src/types';
import { priceFormatter, validFormatter } from 'src/utils/formatters';
import { copyToClipboard } from 'src/utils/helpers';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import RaisedButton from 'material-ui/RaisedButton';

interface MapStateToProps {
    requests: EntityCollectionState<any>;
}

interface OwnProps {
    integrationId?: number;
}

interface TableDataRow {
    id: number;
    createdAt: string;
    email: string;
    pax: number;
    fromTime: string;
    fromLocation: string;
    toTime: string;
    toLocation: string;
    intermediateCount: number;
    intermediateBreakSumInMinutes: number;
    distance: number;
    results: number;
    cheapestPrice?: Money;
    bookingToken?: string;
    bookingIntegrationUrl?: string;
    bookingIntegrationCountry?: string;
    entity: any;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
    drawerOpen: boolean;
    selectedRow?: TableDataRow;
    fromDate: Date;
    toDate: Date;
}

interface MapDispatchToProps extends FetchBySearch {
    addNotification: (title: string, message: string, level: string, position: string) => void;
    addErrorNotification: (error: any) => void;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedTranslateProps & OwnProps;

class SearchRequestsTable extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper({
            onRowClick: this.onRowClick,
            pageSize: 100,
        });
        this.state = {
            startedFetching: false,
            drawerOpen: false,
            fromDate: moment().startOf('month').toDate(),
            toDate: moment().toDate(),
        };
    }

    public componentWillMount() {
        this.fetchSearchRequests(this.props.integrationId);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { requests, integrationId } = nextProps;
        if (!this.state.startedFetching && requests.isFetching) this.setState({ startedFetching: true });
        if (this.props.integrationId && this.props.integrationId !== integrationId)
            this.fetchSearchRequests(integrationId);
        this.tableHelper.processPagination(requests.items.length);
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.requests, this.props.requests, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });

        if (
            prevState.fromDate.getTime() !== this.state.fromDate.getTime() ||
            prevState.toDate.getTime() !== this.state.toDate.getTime()
        ) {
            this.fetchSearchRequests(this.props.integrationId);
        }
    }

    public render() {
        const { requests, integrationId, t } = this.props;
        const finishFetching = this.state.startedFetching && !requests.isFetching;

        if (requests.error) return <ErrorMessage object={requests} />;

        const tableData: TableData = [];
        if (!requests.isFetching && requests.items.length > 0) {
            requests.items.map((request: any) => {
                const route = request.route;

                tableData.push({
                    id: request.id,
                    createdAt: moment(request.createdAt).utc().format('DD.MM.YYYY HH:mm:ss'),
                    email: request.contactData != null ? request.contactData.email : null,
                    fromTime: moment(route.taskFrom.time).format('DD.MM.YYYY HH:mm'),
                    toTime: moment(route.taskTo.time).format('DD.MM.YYYY HH:mm'),
                    fromLocation: route.taskFrom.location.address,
                    toLocation: route.taskTo.location.address,
                    pax: route.pax,
                    intermediateCount: route.intermediates.length / 2,
                    intermediateBreakSumInMinutes: route.intermediateBreakSumInMinutes,
                    // Show request.distanceInMeters for pre-redesign requests
                    distance:
                        request.distanceInMeters != null
                            ? request.distanceInMeters
                            : route.calculatedTravelWithBreaks.distanceInMeters,
                    cheapestPrice: request.cheapestPrice,
                    results: request.amountSearchResults,
                    bookingToken: request.bookingToken,
                    bookingIntegrationUrl: request.bookingIntegrationUrl,
                    bookingIntegrationCountry: request.bookingIntegrationCountry,
                    entity: request,
                });
            });
        }

        return (
            <div>
                {this.renderDrawer()}
                <div id="searchRequestsTable">
                    <BootstrapTable
                        ref={ref => (this.table = ref)}
                        data={tableData}
                        striped={true}
                        hover={true}
                        exportCSV={true}
                        condensed={true}
                        pagination={true}
                        options={Object.assign(
                            { toolBar: this.createCustomToolBar },
                            this.tableHelper.getOptions(finishFetching),
                        )}
                        selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                        searchPlaceholder={t('common_phrases.search')}
                        search={true}>
                        <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true}>
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="140" dataField="createdAt" dataSort={true}>
                            {t('search_requests.created_at')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            hidden={integrationId != null}
                            width="140"
                            dataField="bookingIntegrationUrl"
                            dataSort={true}>
                            Integration
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            width="60"
                            dataField="bookingIntegrationCountry"
                            dataSort={true}
                            hidden={integrationId != null}>
                            Land
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="160"
                            dataFormat={this.emailFormatter}
                            dataField="email"
                            dataSort={true}>
                            {t('search_requests.email')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            width="40"
                            dataField="pax"
                            dataAlign="center"
                            dataSort={true}>
                            {t('search_requests.pax')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="120" dataField="fromTime" dataSort={true}>
                            {t('search_requests.from.time')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="120" dataField="fromLocation" dataSort={true}>
                            {t('search_requests.from.location')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="120" dataField="toLocation" dataSort={true}>
                            {t('search_requests.to.location')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="120" dataField="toTime" dataSort={true}>
                            {t('search_requests.to.time')}
                        </TableHeaderColumn>
                        <TableHeaderColumn columnTitle={true} width="40" dataField="intermediateCount" dataSort={true}>
                            {t('search_requests.intermediateCount')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            width="40"
                            dataField="intermediateBreakSumInMinutes"
                            dataSort={true}>
                            {t('search_requests.intermediateBreakSum')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="distance"
                            width="80"
                            dataAlign="right"
                            dataFormat={this.distanceFormatter}
                            dataSort={true}>
                            {t('search_requests.distance')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="results"
                            width="60"
                            dataAlign="right"
                            dataSort={true}>
                            {t('search_requests.buses')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            columnTitle={true}
                            dataField="cheapestPrice"
                            width="100"
                            dataAlign="right"
                            dataFormat={priceFormatter}
                            csvFormat={priceFormatter}
                            dataSort={true}>
                            {t('search_requests.min_price')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="bookingToken"
                            width="60"
                            dataAlign="center"
                            dataFormat={(cell: any) => validFormatter(t, cell != null)}
                            dataSort={true}>
                            {t('search_requests.booked')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="actions"
                            width="60"
                            dataFormat={this.tableActionsFormatter}
                            export={false}>
                            {t('common_phrases.actions')}
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }

    private fetchSearchRequests = (integrationId?: number) => {
        const formattedFromDate = moment(this.state.fromDate).format('YYYY-MM-DD');
        const formattedToDate = moment(this.state.toDate).format('YYYY-MM-DD');

        this.props.search(
            Entities.SEARCH_BUSES_REQUEST,
            `range?fromDate=${formattedFromDate}&toDate=${formattedToDate}${
                integrationId ? `&integrationId=${integrationId}` : ''
            }`,
            0,
            undefined,
            true,
            () => {},
        );
    };

    private createCustomToolBar = (props: ToolBarProps) => {
        const { t } = this.props;
        const { isFetching } = this.props.requests;

        return (
            <div className="col-md-12">
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                        <DatePicker
                            value={this.state.fromDate}
                            container="inline"
                            disabled={isFetching}
                            formatDate={date => moment(date).format('DD.MM.YYYY')}
                            floatingLabelText={t('search_requests.from.title')}
                            onChange={(_event: any, value: Date) => this.setState({ fromDate: value })}
                            maxDate={this.state.toDate}
                            autoOk={true}
                            textFieldStyle={{ width: 130, marginLeft: 7 }}
                        />
                        <DatePicker
                            value={this.state.toDate}
                            container="inline"
                            disabled={isFetching}
                            formatDate={date => moment(date).format('DD.MM.YYYY')}
                            floatingLabelText={t('search_requests.to.title')}
                            onChange={(_event: any, value: Date) => this.setState({ toDate: value })}
                            minDate={this.state.fromDate}
                            autoOk={true}
                            textFieldStyle={{ width: 130, marginLeft: 30 }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                        {/* TODO: costly requests: allow to select yearMonth */}
                        {this.props.integrationId && (
                            <div style={{ height: 34, marginTop: 'auto' }}>
                                <RaisedButton
                                    style={{ height: 34 }}
                                    label={t('search_requests.calculate_costly_requests')}
                                    primary={true}
                                    labelColor="#ffffff"
                                    onClick={() => {
                                        asyncRequest(
                                            `${TOMCAT_URL}api/booking-integrations/costlyRequests?integrationId=${
                                                this.props.integrationId
                                            }&fromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.toDate).format('YYYY-MM-DD')}`,
                                        )
                                            .then(result =>
                                                this.props.addNotification(
                                                    t('search_requests.calculate_costly_requests'),
                                                    JSON.stringify(result['json']['costlyRequests']),
                                                    'success',
                                                    'tr',
                                                ),
                                            )
                                            .catch(error =>
                                                this.props.addErrorNotification({
                                                    code: error.status,
                                                    message: error.statusText,
                                                }),
                                            );
                                    }}
                                />
                            </div>
                        )}
                        <div style={{ width: 100, marginRight: 60, marginTop: 'auto' }}>
                            {props.components.exportCSVBtn}
                        </div>
                        <div style={{ width: 400, marginRight: 6, marginTop: 'auto' }}>
                            {props.components.searchPanel}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderDrawer = () => {
        return (
            <Drawer
                openSecondary={true}
                docked={false}
                width={500}
                onRequestChange={this.handleCloseDrawer}
                open={this.state.drawerOpen}>
                {this.renderRequestDetails()}
            </Drawer>
        );
    };

    private renderRequestDetails = () => {
        const { t } = this.props;
        const row = this.state.selectedRow;
        if (!row || !this.state.drawerOpen) return null;

        const route = row.entity.route;
        return (
            <div>
                <div className="calculactionsdetails-header">
                    <h5>{t('search_requests.details')}</h5>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('task_details.route')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-md-9">
                                <p>
                                    {moment(route.taskFrom.time).format()} {route.taskFrom.location.address}
                                </p>
                                {route.intermediates &&
                                    route.intermediates.length > 0 &&
                                    route.intermediates.map((value: any, key: any) => (
                                        <p key={key}>
                                            {moment(value.time).format()} {value.location.address}
                                        </p>
                                    ))}
                                <p>
                                    {moment(route.taskTo.time).format()} {route.taskTo.location.address}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    private onRowClick = (row: TableDataRow) => {
        this.tableHelper.setSelectedRow(row.id);
        this.setState({
            selectedRow: row,
        });
    };

    private handleCloseDrawer = () => {
        this.setState({
            drawerOpen: false,
        });
    };

    private distanceFormatter = (distance: number) => {
        const distanceInKM = (distance / 1000).toFixed(0);
        return <span title={distanceInKM}>{`${distanceInKM} km`}</span>;
    };

    private emailFormatter = (email?: string) => {
        const { t } = this.props;
        if (!email || email.length === 0) return '';
        return (
            <div title={email}>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.handleCloseDrawer();
                        copyToClipboard(t, email, this.props.addNotification);
                    }}>
                    <span className="fa fa-clone text-info" title={t('common_phrases.copy')} />
                </button>
                {email}
            </div>
        );
    };

    private tableActionsFormatter = (_cell: any, request: TableDataRow) => {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.open_in_route_planner')}
                    onClick={() => {
                        asyncRequest(`${TOMCAT_URL}api/${Entities.SEARCH_BUSES_REQUEST.repository}/${request.id}/link`)
                            .then(response => {
                                const json = response.json;
                                if (json && json.url) {
                                    const win = window.open(json.url, '_blank');
                                    if (win != null) win.focus();
                                }
                            })
                            .catch(error => console.error('Error creating Link', error));
                    }}>
                    <span className="glyphicon glyphicon-new-window text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('search_requests.display_details')}
                    onClick={() => {
                        this.setState({
                            drawerOpen: !this.state.drawerOpen,
                        });
                    }}>
                    <span className="fa fa-info-circle text-info" />
                </button>
            </div>
        );
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        requests: getEntities(state, Entities.SEARCH_BUSES_REQUEST),
    };
};

export default connect<any, any, any>(mapStateToProps, {
    push,
    search,
    addNotification,
    addErrorNotification,
})(composeModalActions(SearchRequestsTable));
