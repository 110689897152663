import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import Cookies from 'js-cookie';

import { login } from 'src/common/index';
import { getOwnAccount } from 'src/selectors/accounts';
import LoginForm from 'src/components/account/login-form';
import { fetchOwnAccount } from 'src/actions/accounts';
import logoRound from 'static/Busfinder_Kreis_100.png';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { withTranslation } from 'react-i18next';

class Login extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.redirectIfLoggedIn(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.redirectIfLoggedIn(nextProps);
    }

    redirectIfLoggedIn(props) {
        const { ownAccount, push } = props;
        const loggedIn = ownAccount && ownAccount.entity && !ownAccount.isFetching;

        //redirect after successful login, or when already logged in
        if (loggedIn) {
            const redirectUrl = Cookies.get('redirectURLAfterLogin') ? Cookies.get('redirectURLAfterLogin') : '/';
            if (redirectUrl) push(redirectUrl);
            else push('/');
            Cookies.remove('redirectURLAfterLogin');
        }
    }

    componentWillMount() {
        $('body').addClass('background-image');
    }

    componentWillUnmount() {
        $('body').removeClass('background-image');
    }

    handleSubmit(data) {
        this.props.login(data.username, data.password, fetchOwnAccount, undefined, true);
    }

    render() {
        const { t } = this.props;
        const query = this.props.location.query;
        const hasError = query && query.error;
        const hasExpired = query && query.expired;
        const hasLogout = query && query.logout;

        let devLoginSelection = null;
        if (window.location.hostname === 'localhost' && Array.isArray(Config.ACCOUNTS)) {
            const accountMenuItems = Config.ACCOUNTS.map(account => {
                return <MenuItem key={account.username} value={account} primaryText={account.username} />;
            });

            devLoginSelection = (
                <SelectField
                    floatingLabelStyle={{ top: 28 }}
                    menuStyle={{ marginTop: 5, width: '400px' }}
                    underlineStyle={{ display: 'none' }}
                    onChange={(event, index, account) => {
                        this.props.login(account.username, account.password, fetchOwnAccount, undefined, true);
                    }}
                    floatingLabelText={t('click_here_account')}
                    fullWidth={true}>
                    {accountMenuItems}
                </SelectField>
            );
        }

        return (
            <div id="login-div-outer">
                <div id="login-div-inner">
                    <div id="login-form">
                        <div id="login-form-logo">
                            <img width="100px" src={logoRound} />
                        </div>
                        <LoginForm
                            onSubmit={this.handleSubmit}
                            hasError={hasError}
                            hasExpired={hasExpired}
                            hasLogout={hasLogout}
                            devLoginSelection={devLoginSelection}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ownAccount: getOwnAccount(state),
    };
};

export default withTranslation()(connect(mapStateToProps, { login, push })(Login));

export const isLoggedIn = account => {
    return account && !account.isFetching && account.entity;
};

export const isLoggedInWithRole = (account, roles) => {
    return isLoggedIn(account) && _.includes(roles, account.entity.role);
};
