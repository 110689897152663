import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, save } from 'src/common/index';
import CountryCalculationAdministration from 'src/components/administration/country-calculation-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        countryCalculations: getEntities(state, Entities.COUNTRY_CALCULATION, state.selectedCompany),
    };
};

const CountryCalculationAdministrationContainer = connect(mapStateToProps, {
    fetchByCompanyId,
    save,
    deleteById,
})(CountryCalculationAdministration);

export default CountryCalculationAdministrationContainer;
