// @ts-ignore
import { apiRequest } from 'src/actions/api/api';
import { Entity } from './Entities';
import { TOMCAT_URL } from './index';

/**
 * Fetch all available data from the given entity and map it in the given reducer index
 * @param entity        entity of interest
 * @param reducerIndex  reducer index (store)
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @returns {function(*)}
 */
export function fetchAll(
    entity: Entity,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
) {
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}${projection ? `?projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_FETCH_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: entity.schema,
            }),
        );
    };
}

/**
 * Fetch all entries related to the given company id = reducer index
 * @param entity        entity of interest
 * @param companyId  reducer index (store) = company id
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @returns {function(*)}
 */
// @ts-ignore
export function fetchByCompanyId(
    entity: Entity,
    companyId?: number,
    projection: string | undefined = entity.projection,
) {
    if (companyId)
        return (dispatch: any) => {
            return dispatch(
                // @ts-ignore
                apiRequest({
                    endpoint: `${TOMCAT_URL}api/${entity.repository}/search/findByCompanyId?companyId=${companyId}${
                        projection ? `&projection=${projection}` : ''
                    }`,
                    method: 'GET',
                    secondaryActionTypes: [
                        `REQUEST_${entity.action}`,
                        `SUCCESS_FETCH_${entity.action}`,
                        `FAILURE_${entity.action}`,
                    ],
                    reducerIndex: companyId,
                    schema: entity.schema,
                }),
            );
        };
}

/**
 * Fetch all entries related to the given company id = reducer index
 * @param entity        entity of interest
 * @param companyId  reducer index (store) = company id
 * @param customEndpoint
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @returns {function(*)}
 */
// @ts-ignore
export function fetchOrCreateByCompanyId(
    entity: Entity,
    companyId: number,
    customEndpoint: string,
    projection: string | undefined = entity.projection,
) {
    if (companyId)
        return (dispatch: any) => {
            return dispatch(
                // @ts-ignore
                apiRequest({
                    endpoint: `${TOMCAT_URL}api/${entity.repository}/${companyId}/${customEndpoint}`,
                    method: 'GET',
                    secondaryActionTypes: [
                        `REQUEST_${entity.action}`,
                        `SUCCESS_FETCH_${entity.action}`,
                        `FAILURE_${entity.action}`,
                    ],
                    reducerIndex: companyId,
                    schema: entity.schema,
                }),
            );
        };
}

/**
 * Fetch the given entity by id and store it in the given reducer index
 * @param entity        entity of interest
 * @param id            id of entity
 * @param reducerIndex  reducer index (store)
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @returns {function(*)}
 */
export function fetchById(
    id: number,
    entity: Entity,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
) {
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}/${id}${projection ? `?projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_UPDATE_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                parameter: 'UPDATE',
                schema: entity.schema,
            }),
        );
    };
}

/**
 * Fetch all available data from the given entity with a custom search method endpoint, and map it in the given reducer index
 * @param entity        entity of interest
 * @param searchMethod  the name of the method to access
 * @param reducerIndex  reducer index (store)
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @param controller    determines if we request a controller or a repository
 * @param errorCallback
 * @returns {function(*)}
 */
export function search(
    entity: Entity,
    searchMethod: string,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
    controller: boolean = false,
    errorCallback?: (error: any) => void,
) {
    return (dispatch: any) => {
        const projectionString = controller
            ? ''
            : searchMethod.includes('?')
              ? `&projection=${projection}`
              : `?projection=${projection}`;
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}${
                    controller ? '' : '/search'
                }/${searchMethod}${projectionString}`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_FETCH_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: entity.schema,
                errorCallback: errorCallback,
            }),
        );
    };
}

/**
 * Saves the body to the given entity in backend. Also save returned value in given reducer index (store)
 * @param entity        entity entity of interest
 * @param reducerIndex  reducerIndex reducer index (store)
 * @param body          content to save
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @param successCallback   callback called after successful patch, can be undefined
 * @param method        a custom method to call, can be undefined
 * @returns {function(*)}
 */
export function save(
    body: any,
    entity: Entity,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
    successCallback?: any,
    method?: string,
) {
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}${method ? `/${method}` : ''}${
                    projection ? `?projection=${projection}` : ''
                }`,
                method: 'POST',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_SAVE_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: entity.schema,
                body: JSON.stringify(body),
                successCallback: successCallback,
            }),
        );
    };
}

/**
 * Update an entity with the given body and saves the result in the given reducer index (store)
 * @param entity        entity of interest
 * @param reducerIndex  reducer index (store)
 * @param body          updated content
 * @param projection    the projection to use, defaults to entity.projection, can be undefined
 * @param method        a custom method to call, can be undefined
 * @returns {function(*)}
 */
export function update(
    body: any,
    entity: Entity,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
    method?: string,
) {
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}/${body.id}${method ? `/${method}` : ''}${
                    projection ? `?projection=${projection}` : ''
                }`,
                method: 'PUT',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_UPDATE_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: entity.schema,
                body: JSON.stringify(body),
            }),
        );
    };
}

/**
 * Patches an entity with the given body and saves the result in the given reducer index (store)
 * @param entity            entity of interest
 * @param reducerIndex      reducer index (store)
 * @param body              updated content
 * @param projection        the projection to use, defaults to entity.projection, can be undefined
 * @param successCallback   callback called after successful patch, can be undefined
 * @param method            a custom method to call, can be undefined
 * @returns {function(*)}
 */
export function patch(
    body: any,
    entity: Entity,
    reducerIndex: number | string = 0,
    projection: string | undefined = entity.projection,
    successCallback?: any,
    method?: string,
) {
    console.log(body, 'body');
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore

            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}/${body.id}${method ? `/${method}` : ''}${
                    projection ? `?projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_UPDATE_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: entity.schema,
                body: JSON.stringify(body),
                successCallback: successCallback,
            }),
        );
    };
}

/**
 * Deletes the an entity by the given id and also removes it from the reducer index
 * @param entity        entity of interest
 * @param reducerIndex  reducer index (store)
 * @param id            id of entity to delete
 * @param method        a custom method to call, can be undefined
 * @returns {function(*)}
 */
export function deleteById(id: number, entity: Entity, reducerIndex: number | string = 0, method?: string) {
    return (dispatch: any) => {
        return dispatch(
            // @ts-ignore
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${entity.repository}/${id}${method ? `/${method}` : ''}`,
                method: 'DELETE',
                secondaryActionTypes: [
                    `REQUEST_${entity.action}`,
                    `SUCCESS_DELETE_${entity.action}`,
                    `FAILURE_${entity.action}`,
                ],
                schema: entity.schema,
                reducerIndex: reducerIndex,
                parameter: id,
            }),
        );
    };
}

export function resetEntity(entity: Entity, reducerIndex: number | string = 0) {
    return (dispatch: any) => {
        dispatch({
            type: `RESET_${entity.action}`,
            payload: {
                reducerIndex: reducerIndex,
            },
        });
    };
}

export interface FetchById {
    fetchById: (id: number, entity: Entity, reducerIndex?: number, projection?: string) => void;
}

export interface FetchBySearch {
    search: (
        entity: Entity,
        searchMethod: string,
        reducerIndex?: number,
        projection?: string,
        controller?: boolean,
        errorCallback?: (error: any) => void,
    ) => void;
}

export interface FetchAll {
    fetchAll: (entity: Entity, reducerIndex?: number | string, projection?: string) => void;
}

export interface FetchByCompanyId {
    fetchByCompanyId: (entity: Entity, companyId?: number, projection?: string) => void;
}

export interface DeleteById {
    deleteById: (id: number, entity: Entity, reducerIndex: number | string, method?: string) => void;
}

export interface Patch {
    patch: (
        body: any,
        entity: Entity,
        reducerIndex: number | string,
        projection?: string,
        successCallback?: any,
        method?: string,
    ) => void;
}
