import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Entities } from 'src/common/index';

import composeModalActions from 'src/utils/modal-action-wrapper';
import { get21x9Image } from 'src/utils/helpers';
import {
    dateTimeFormatter,
    expiredFormatter,
    percentageFormatter,
    priceFormatter,
    validFormatter,
} from 'src/utils/formatters';
import {
    calculateAvailableTickets,
    calculateAvailableTicketsPercentages,
} from 'src/components/event-tickets/calculations';
import { renderEventInformation, renderSelectedTicketCollection } from 'src/components/event-tickets/rendering';

import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import TicketEventModal from 'src/components/admin/event-tickets/events/ticket-event-modal';
import ParentForm from 'src/components/misc/parent-form';
import TicketCollectionForm from 'src/components/admin/event-tickets/ticket-collections/ticket-collection-form';
import TicketSellForm from 'src/components/admin/event-tickets/ticket-collections/ticket-sell-form';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import { withTranslation } from 'react-i18next';

class TicketCollectionAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({
            onRowClick: this.onSelectTicketCollection,
        });
        this.state = {
            startedFetching: false,
            selectedTicketCollectionIndex: -1,
        };

        this.eventId = props.params.id;
    }

    onSelectTicketCollection(ticketCollection) {
        this.tableHelper.setSelectedRow(ticketCollection.id);
        this.setState({
            selectedTicketCollectionIndex: this.props.ticketCollections.items.findIndex(
                tc => tc.id === ticketCollection.id,
            ),
        });
    }

    componentWillMount() {
        this.props.fetchTicketCollectionsByEventId(this.eventId);
        this.props.fetchById(this.eventId, Entities.TICKET_EVENT);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { ticketCollections } = nextProps;

        if (ticketCollections.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(ticketCollections.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.ticketCollections,
                this.props.ticketCollections,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { ticketCollections, event, t } = this.props;
        const finishFetching = this.state.startedFetching && !ticketCollections.isFetching && !event.isFetching;

        if (!finishFetching) return <span>Fetching</span>;

        if (ticketCollections.error) return <ErrorMessage object={ticketCollections} />;

        const tableData = [];
        let totalTickets = 0,
            soldTickets = 0,
            expiredTickets = 0;
        if (ticketCollections.items.length > 0) {
            ticketCollections.items.map(ticketCollection => {
                totalTickets += ticketCollection.quantity;
                soldTickets += ticketCollection.countSold;

                let expiredTicketsOfSingleCollection = 0;
                if (!ticketCollection.valid) {
                    expiredTicketsOfSingleCollection = ticketCollection.quantity - ticketCollection.countSold;
                    expiredTickets += expiredTicketsOfSingleCollection;
                }

                tableData.push({
                    id: ticketCollection.id,
                    category: ticketCollection.category,
                    validUntil: ticketCollection.validUntil,
                    valid: ticketCollection.valid,
                    sellPrice: ticketCollection.sellPrice,
                    internSellPrice: ticketCollection.internSellPrice,
                    buyPrice: ticketCollection.buyPrice,
                    totalTickets: ticketCollection.quantity,
                    soldTickets: ticketCollection.countSold,
                    description: ticketCollection.description,
                    conditions: ticketCollection.conditions,
                    expiredTickets: expiredTicketsOfSingleCollection,
                    availableTickets: calculateAvailableTickets(
                        ticketCollection.quantity,
                        ticketCollection.countSold,
                        expiredTicketsOfSingleCollection,
                    ),
                    availablePercentage: calculateAvailableTicketsPercentages(
                        ticketCollection.quantity,
                        ticketCollection.countSold,
                        expiredTicketsOfSingleCollection,
                    ),
                });
            });
        }

        return (
            <div id="ticketCollectionAdministration">
                {this.renderLegend(event)}
                {this.renderEventSummary(totalTickets, soldTickets, expiredTickets)}

                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={tableData}
                    exportCSV={true}
                    striped={true}
                    hover={true}
                    condensed={true}
                    pagination={true}
                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                    searchPlaceholder={t('common_phrases.search')}
                    search={true}>
                    <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true}>
                        {t('common_phrases.id')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="category" dataSort={true}>
                        {t('common_phrases.category')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="validUntil"
                        dataFormat={dateTimeFormatter}
                        width="170"
                        dataSort={true}>
                        {t('ticket.valid_until')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="valid"
                        width="80"
                        dataAlign="center"
                        dataFormat={cell => validFormatter(t, cell)}
                        dataSort={true}>
                        {t('ticket.valid')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="buyPrice"
                        width="110"
                        dataAlign="right"
                        dataFormat={priceFormatter}
                        csvFormat={priceFormatter}
                        dataSort={true}>
                        {t('ticket.buy_price')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="sellPrice"
                        width="110"
                        dataAlign="right"
                        dataFormat={priceFormatter}
                        csvFormat={priceFormatter}
                        dataSort={true}>
                        {t('ticket.sell_price')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="internSellPrice"
                        width="110"
                        dataAlign="right"
                        dataFormat={priceFormatter}
                        csvFormat={priceFormatter}
                        dataSort={true}>
                        {t('ticket.internal_sell_price')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="totalTickets"
                        width="120"
                        dataAlign="right"
                        dataSort={true}>
                        {t('ticket.sum')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="soldTickets"
                        dataAlign="right"
                        width="120"
                        dataSort={true}>
                        {t('ticket.sold')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="expiredTickets"
                        dataAlign="right"
                        width="120"
                        dataSort={true}>
                        {t('ticket.expired')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="availableTickets"
                        dataAlign="right"
                        width="120"
                        dataSort={true}>
                        {t('ticket.available')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="availablePercentage"
                        dataAlign="right"
                        width="120"
                        dataFormat={percentageFormatter}
                        dataSort={true}>
                        {t('ticket.available_percentage')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="actions"
                        width="90"
                        dataFormat={this.tableActionsFormatter}
                        export={false}>
                        {t('common_phrases.actions')}
                    </TableHeaderColumn>
                </BootstrapTable>

                {this.state.selectedTicketCollectionIndex !== -1 &&
                    renderSelectedTicketCollection(
                        t,
                        ticketCollections.items[this.state.selectedTicketCollectionIndex],
                    )}
            </div>
        );
    }

    renderLegend(event) {
        const { t } = this.props;
        const isEventExpired = event.content.expired;

        return (
            <legend className="legend">
                {event.content.name}
                <button
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.props.push('/admin/event-tickets/events');
                    }}>
                    <span className="glyphicon glyphicon-circle-arrow-left" />
                    &nbsp;{t('common_phrases.back')}
                </button>
                <button
                    disabled={isEventExpired}
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.table.reset();
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: true,
                                mainEntityProps: {
                                    entity: Entities.TICKET_COLLECTION,
                                    id: undefined,
                                },
                                childForm: {
                                    component: TicketCollectionForm,
                                    props: {
                                        event: event.content,
                                    },
                                },
                            },
                            title: t('ticket.new_packet'),
                        });
                    }}>
                    <span className="glyphicon glyphicon-plus" />
                    &nbsp;{t('ticket.new_packet')}
                </button>
                <button
                    className="btn btn-xs btn-link nav-link pull-right"
                    onClick={() => {
                        this.props.openModal({
                            component: TicketEventModal,
                            componentProps: {
                                id: event.content.id,
                                isCreate: false,
                            },
                            title: event.content.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil" />
                    &nbsp;{t('ticket.edit_event')}
                </button>
            </legend>
        );
    }

    renderEventSummary(totalTickets, soldTickets, expiredTickets) {
        const { event, t } = this.props;

        return (
            <div id="eventTicketSummary" className="row">
                <div className="col-md-4">
                    <img alt={event.name} src={get21x9Image(event.content.logo)} width="100%" />
                </div>
                <div className="col-md-3">
                    {renderEventInformation(t('ticket.status'), expiredFormatter(t, event.content.expired))}
                    {renderEventInformation(t('ticket.beginning'), dateTimeFormatter(event.content.beginDateTime))}
                    {renderEventInformation(t('common_phrases.location'), event.content.location.address)}
                    <hr />
                    {this.renderTicketStatistics(totalTickets, soldTickets, expiredTickets)}
                </div>
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-md-11">
                            <h5>
                                <strong>{t('common_phrases.description')}:</strong>
                            </h5>
                            {event.content.description ? event.content.description : t('ticket.no_description')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTicketStatistics(totalTickets, soldTickets, expiredTickets) {
        const { t } = this.props;
        return (
            <div>
                {renderEventInformation(t('ticket.tickets'), t('ticket.pieces_amount', { pieces: totalTickets }))}
                {renderEventInformation(
                    t('ticket.available'),
                    <span>
                        {t('ticket.pieces_amount', { pieces: totalTickets - soldTickets - expiredTickets })} (
                        {percentageFormatter(
                            calculateAvailableTicketsPercentages(totalTickets, soldTickets, expiredTickets),
                        )}
                        )
                    </span>,
                )}
            </div>
        );
    }

    tableActionsFormatter(cell, ticketCollection) {
        const isEventExpired = this.props.event.content.expired;
        const { t } = this.props;

        return (
            <div>
                <button
                    disabled={isEventExpired}
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.TICKET_COLLECTION,
                                    id: ticketCollection.id,
                                },
                                childForm: {
                                    component: TicketCollectionForm,
                                    props: {
                                        event: this.props.event.content,
                                    },
                                },
                            },
                            title: ticketCollection.category,
                        });
                    }}>
                    <span
                        title={isEventExpired ? t('ticket.error_event_expired') : t('user_functionality.edit')}
                        className="glyphicon glyphicon-pencil text-info"
                    />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: ticketCollection,
                                onProceedDialog: ticketCollection =>
                                    this.props.deleteById(ticketCollection.id, Entities.TICKET_COLLECTION),
                                bodyText: t('ticket.confirm_delete'),
                                proceedText: t('user_functionality.delete'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: ticketCollection.category,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
                <button
                    disabled={isEventExpired || ticketCollection.availableTickets === 0}
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.TICKET_COLLECTION,
                                    id: ticketCollection.id,
                                },
                                childForm: {
                                    component: TicketSellForm,
                                },
                                dialogStyle: {
                                    width: '40vw',
                                },
                            },
                            title: ticketCollection.category,
                            successButtonLabel: t('ticket.sell'),
                        });
                    }}>
                    <span
                        title={
                            isEventExpired
                                ? t('ticket.error_event_expired')
                                : ticketCollection.availableTickets === 0
                                  ? t('ticket.no_tickets')
                                  : t('ticket.sell')
                        }
                        className="glyphicon glyphicon-euro text-success"
                    />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(TicketCollectionAdministration));
