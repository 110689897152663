import { apiRequest } from 'src/actions/api/api';
import { Entities, TOMCAT_URL } from 'src/common/index';

export function assignProduct(data, reducerIndex = 0) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.SEASON.repository}/assignProduct`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.SEASON.action}`,
                    `SUCCESS_UPDATE_${Entities.SEASON.action}`,
                    `FAILURE_${Entities.SEASON.action}`,
                ],
                body: JSON.stringify(data),
                reducerIndex: reducerIndex,
                schema: Entities.SEASON.schema,
            }),
        );
    };
}

export function removeProductFromSeason(productId, seasonId, reducerIndex = 0) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.SEASON.repository}/removeProductFromSeason?productId=${productId}&seasonId=${seasonId}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.SEASON.action}`,
                    `SUCCESS_UPDATE_${Entities.SEASON.action}`,
                    `FAILURE_${Entities.SEASON.action}`,
                ],
                reducerIndex: reducerIndex,
                schema: Entities.SEASON.schema,
            }),
        );
    };
}
