import { apiRequest } from 'src/actions/api/api';
import { Entities, login, TOMCAT_URL } from 'src/common/index';

export const OWN_REDUCER_INDEX = 'own';

export function fetchOwnAccount() {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}public/account`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${Entities.ACCOUNT.action}`,
                    `SUCCESS_FETCH_${Entities.ACCOUNT.action}`,
                    `FAILURE_${Entities.ACCOUNT.action}`,
                ],
                reducerIndex: OWN_REDUCER_INDEX,
                schema: Entities.ACCOUNT.schema,

                /**
                 * Actually this is the error callback!
                 * If the account could not be received from the backend due to no authentication -> we return 204 (No Content)
                 * Because of the returned status code 204, the apiRequest function triggers the onSuccess callback.
                 * But we have special treatment for the account fetching in this function. So if the fetching failed
                 * with a status code 204, the success callback is transformed to an error callback
                 */
                successCallback: () => {
                    // auto-login on localhost
                    if (
                        window.location.hostname === 'localhost' &&
                        Config.AUTO_LOGIN.enabled &&
                        (window.location.pathname !== '/login' ||
                            (!window.location.search.includes('logout=true') &&
                                !window.location.search.includes('expired=true') &&
                                !window.location.search.includes('error=true')))
                    ) {
                        console.log('Auto-login: ', Config.AUTO_LOGIN.username);
                        dispatch(login(Config.AUTO_LOGIN.username, Config.AUTO_LOGIN.password, fetchOwnAccount));
                    }
                },
            }),
        );
    };
}
