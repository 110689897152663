import { Entities, Projection, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export function fetchTicketEventsJoinFetchTickets() {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TICKET_EVENT.repository}/search/findAllTicketEventsJoinFetchTicketCollection?projection=event-quantity`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${Entities.TICKET_EVENT.action}`,
                    `SUCCESS_FETCH_${Entities.TICKET_EVENT.action}`,
                    `FAILURE_${Entities.TICKET_EVENT.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.TICKET_EVENT.schema,
            }),
        );
    };
}

export function fetchTicketCollectionsByEventId(eventId, projection) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TICKET_COLLECTION.repository
                }/search/findByEventId?eventId=${eventId}&projection=${projection ? projection : Projection.DEFAULT}`,
                method: 'GET',
                secondaryActionTypes: [
                    `REQUEST_${Entities.TICKET_COLLECTION.action}`,
                    `SUCCESS_FETCH_${Entities.TICKET_COLLECTION.action}`,
                    `FAILURE_${Entities.TICKET_COLLECTION.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.TICKET_COLLECTION.schema,
            }),
        );
    };
}

export function sellTickets(ticketCollectionId, quantity) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TICKET_COLLECTION.repository}/sell?id=${ticketCollectionId}&quantity=${quantity}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.TICKET_COLLECTION.action}`,
                    `SUCCESS_UPDATE_${Entities.TICKET_COLLECTION.action}`,
                    `FAILURE_${Entities.TICKET_COLLECTION.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.TICKET_COLLECTION.schema,
            }),
        );
    };
}
