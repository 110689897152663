import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { renderInput } from 'src/components/misc/redux-form-helpers';
import CircularProgress from 'material-ui/CircularProgress';

class LoginForm extends Component {
    render() {
        const { t, handleSubmit, hasError, hasExpired, hasLogout, statusOnly, devLoginSelection } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="col-xs-12">
                    <div className="form-group">
                        <div className="col-xs-12">
                            {statusOnly && !hasError && !hasExpired && !hasLogout && (
                                <div className="alert alert-info">
                                    <CircularProgress />
                                    &nbsp;{t('login_information.login_accepted')}{' '}
                                </div>
                            )}
                            {hasError && <div className="alert alert-danger">{t('login_error')}</div>}
                            {hasExpired && <div className="alert alert-danger">{t('login_expired')}</div>}
                            {hasLogout && <div className="alert alert-info">{t('login_logout')}</div>}
                        </div>
                    </div>
                    {!statusOnly && (
                        <>
                            {devLoginSelection}
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <Field name="username" label={t('contact_data.username')} component={renderInput} />
                                </div>
                                <div className="col-xs-12">
                                    <Field
                                        name="password"
                                        type="password"
                                        label={t('contact_data.password')}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <RaisedButton
                                        primary={true}
                                        type="submit"
                                        label={t('login_information.login')}
                                        className="pull-right"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!values.username) errors.username = t('error_missing.fill_in_username');

    return errors;
};

export default withTranslation()(
    reduxForm({
        form: 'loginForm',
        validate,
    })(LoginForm),
);
