import React, { Component } from 'react';
import Helmet from 'react-helmet';
// icons generated with https://realfavicongenerator.net
import appleTouchIcon from 'static/favicon/apple-touch-icon.png';
import browserconfig from 'static/favicon/browserconfig.xml';
import favicon16 from 'static/favicon/favicon-16x16.png';
import favicon32 from 'static/favicon/favicon-32x32.png';
import favicon from 'static/favicon/favicon.ico';
import manifest from 'static/favicon/site.webmanifest.json';
import maskIcon from 'static/favicon/safari-pinned-tab.svg';
import 'static/favicon/android-chrome-192x192.png';
import 'static/favicon/android-chrome-256x256.png';
import 'static/favicon/mstile-150x150.png';

export default class FaviconLoader extends Component {
    render() {
        return (
            <Helmet
                link={[
                    {
                        rel: 'apple-touch-icon',
                        href: `${appleTouchIcon}`,
                        sized: '180x180',
                    },
                    {
                        rel: 'icon',
                        type: 'image/png',
                        href: `${favicon32}`,
                        sized: '32x32',
                    },
                    {
                        rel: 'icon',
                        type: 'image/png',
                        href: `${favicon16}`,
                        sized: '16x16',
                    },
                    { rel: 'manifest', href: `${manifest}` },
                    { rel: 'mask-icon', href: `${maskIcon}`, color: '#5bbad5' },
                    { rel: 'shortcut icon', href: `${favicon}` },
                ]}
                meta={[
                    { name: 'msapplication-TileColor', content: '#224679' },
                    { name: 'msapplication-config', content: '#ffffff' },
                    { name: 'theme-color', content: `${browserconfig}` },
                ]}
            />
        );
    }
}
