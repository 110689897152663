import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { Entities, fetchById, getUpdatedEntity, patch, save } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import HelpCategoryEditForm from 'src/components/admin/help/categories/help-category-edit-form';

class HelpCategoryModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.HELP_CATEGORY);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { helpCategory } = nextProps;

        if (!this.state.startedFetching && helpCategory.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { isCreate, helpCategory, t } = this.props;

        const finishFetching = this.state.startedFetching && !helpCategory.isFetching;

        if (finishFetching) {
            // check for errors
            if (helpCategory.error) return <ErrorMessage object={helpCategory} />;
            else {
                let initialValues = {};

                if (!isCreate && helpCategory.content) {
                    initialValues = {
                        id: helpCategory.content.id,
                        name: helpCategory.content.name,
                    };
                }

                return (
                    <HelpCategoryEditForm
                        t={t}
                        initialValues={initialValues}
                        isCreate={isCreate}
                        onSubmit={this.handleSubmit}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate } = this.props;

        if (!isCreate) this.props.patch(data, Entities.HELP_CATEGORY);
        else this.props.save(data, Entities.HELP_CATEGORY);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        helpCategory: getUpdatedEntity(state, Entities.HELP_CATEGORY),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(HelpCategoryModal);
