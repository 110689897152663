import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { Entities, fetchById, getUpdatedEntity, patch, save, TOMCAT_URL } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import CategoryEditForm from 'src/components/administration/category-edit-form';
import { getOwnCompany } from 'src/selectors/bus-companies';

class CategoryEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany } = this.props;

        if (!isCreate) this.props.fetchById(id, Entities.BUS_CATEGORY, selectedCompany);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { category } = nextProps;

        if (!this.state.startedFetching && category.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { category, isCreate, selectedCompany, ownCompany, t } = this.props;

        const finishFetching = this.state.startedFetching && !category.isFetching;

        if (finishFetching) {
            // check for errors
            if (category.error) return <ErrorMessage object={category} />;
            // if everything is ok populate form
            else {
                let initialValues;

                // populate form for editing an existing category
                if (!isCreate && category.content)
                    initialValues = {
                        id: category.content.id,
                        name: category.content.name,
                        position: category.content.position || 0,
                        group: category.content.group || false,
                        groupResultLimit: category.content.groupResultLimit || 1,
                        turistaVehicleGroupId: category.content.turistaVehicleGroupId,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                    };
                // populate form to create a new category
                else
                    initialValues = {
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        groupResultLimit: 1,
                    };

                return (
                    <CategoryEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        category={category.content}
                        selectedCompany={selectedCompany}
                        ownCompany={ownCompany}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, selectedCompany, handleClose } = this.props;

        if (!isCreate) this.props.patch(data, Entities.BUS_CATEGORY, selectedCompany);
        else this.props.save(data, Entities.BUS_CATEGORY, selectedCompany);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        category: getUpdatedEntity(state, Entities.BUS_CATEGORY, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        ownCompany: getOwnCompany(state),
    };
};

export default connect(mapStateToProps, { fetchById, patch, save }, null, { withRef: true })(CategoryEditModal);
