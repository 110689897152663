import { arrayOf, normalize } from 'normalizr';
import { CALL_API } from 'redux-api-middleware';
import _ from 'lodash';
import { OperationType } from 'src/utils/constants';
import HttpStatus from 'http-status-codes';
import { addErrorNotification } from 'src/actions/notifications';

import {
    asyncRequest,
    AUTHENTICATION_TOKEN_HEADER,
    AUTHENTICATION_TOKEN_STORAGE_KEY,
    TOMCAT_URL,
    UI_URL,
} from 'src/common/index';
import { SET_INTEGRATION } from 'src/actions/integrations';

export const API_SUCCESS_FETCH = 'API_SUCCESS_FETCH';
export const API_SUCCESS_DELETE = 'API_SUCCESS_DELETE';
export const API_SUCCESS_UPDATE = 'API_SUCCESS_UPDATE';
export const API_FAILURE = 'API_FAILURE';

const JSON_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export function apiRequest({
    endpoint,
    method = 'GET',
    secondaryActionTypes,
    reducerIndex,
    parameter,
    schema,
    body,
    errorCallback,
    successCallback,
    headers = JSON_HEADERS,
}) {
    headers[AUTHENTICATION_TOKEN_HEADER] = localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY);

    const config = {};
    config.endpoint = endpoint;
    config.method = method;
    config.headers = headers;
    config.credentials = 'include';

    if (body) config.body = body;

    console.log(config.method, config.endpoint, 'config:', config);

    return dispatch => {
        if (!Array.isArray(secondaryActionTypes) || secondaryActionTypes.length !== 3) {
            console.error('secondaryActionTypes must be an array of three elements');
        }

        return dispatch({
            [CALL_API]: {
                ...config,
                types: [
                    {
                        type: secondaryActionTypes[0],
                        payload: (action, state) => onRequest(reducerIndex),
                    },
                    {
                        type: secondaryActionTypes[1],
                        payload: (action, state, res) =>
                            onSuccess(
                                dispatch,
                                res,
                                schema,
                                reducerIndex,
                                method,
                                parameter,
                                endpoint,
                                successCallback,
                            ),
                    },
                    {
                        type: secondaryActionTypes[2],
                        payload: (action, state, res) =>
                            onFailure(dispatch, res, reducerIndex, endpoint, method, parameter, schema, errorCallback),
                    },
                ],
            },
        });
    };
}

function onRequest(reducerIndex) {
    return { reducerIndex: reducerIndex };
}

function processNormalize(method, json, schema) {
    let data;

    // spring data returns a single resource without a parent embedded tag.
    // BUT if we return a collection without spring data there is also no embedded tag, so we further check if it is an array
    if (method === 'POST' || method === 'PATCH' || method === 'PUT' || (method === 'GET' && !json._embedded)) {
        extractEmbedded(json);

        if (Array.isArray(json)) data = normalize(json, arrayOf(schema));
        else data = normalize(json, schema);
    }
    // spring data returns multiple resource with parent embedded tag
    else {
        json._embedded[schema.getKey()].forEach(entity => {
            extractEmbedded(entity);
        });

        data = normalize(json._embedded[schema.getKey()], arrayOf(schema));
    }

    return data;
}

// if nested resources have embedded tag -> extract them for normalizer
function extractEmbedded(json) {
    if (json._embedded) {
        const embedded = json._embedded;
        delete json._embedded;
        _.forEach(embedded, function (value, key) {
            json[`${key}`] = value;
        });
    }
}

function processDelete(dispatch, schema, parameter, reducerIndex, successCallback) {
    dispatch({
        type: API_SUCCESS_DELETE,
        payload: {
            entityName: schema.getKey(),
            entityValue: parameter,
        },
    });

    if (successCallback) successCallback();

    return {
        reducerIndex: reducerIndex,
        entityValue: parameter,
        receivedAt: Date.now(),
    };
}

function processFetchOrUpdate(dispatch, res, schema, reducerIndex, method, endpoint, parameter, successCallback) {
    return res.json().then(json => {
        // normalize nested json response depending on method
        const data = processNormalize(method, json, schema);

        if (method === 'GET' && parameter !== OperationType.UPDATE) {
            // dispatch the api action (entities will be merged by api reducer)
            dispatch({
                type: API_SUCCESS_FETCH,
                payload: {
                    entities: data.entities,
                    mainEntity: schema.getKey(),
                },
            });
        } else {
            // if action was update -> replace old entity with new one
            dispatch({
                type: API_SUCCESS_UPDATE,
                payload: {
                    entityName: schema.getKey(),
                    entityIndex: data.result,
                    updatedEntity: data.entities[schema.getKey()][data.result],
                },
            });
        }

        // Payload for the secondary action type (result will be merged by related reducer)
        if (method === 'GET') {
            let returnValue;

            // if api call was GET update "receivedAt" for caching
            if (Array.isArray(data.result))
                returnValue = {
                    result: data.result,
                    receivedAt: Date.now(),
                    reducerIndex: reducerIndex,
                };
            else
                returnValue = {
                    result: Array.of(data.result),
                    receivedAt: Date.now(),
                    reducerIndex: reducerIndex,
                };

            // if fetching own account:
            // also request the related operating tool integration, if there is one
            // also return the company in the result
            if (endpoint === `${TOMCAT_URL}public/account`) {
                asyncRequest(`${TOMCAT_URL}api/operatingtool-integrations/find-authenticated`)
                    .then(response => {
                        if (response.status === HttpStatus.OK)
                            dispatch({
                                type: SET_INTEGRATION,
                                integration: response.json,
                            });
                    })
                    .catch(error => {
                        console.error('Error fetching authenticated Integration', error);
                    });
                returnValue.company = data.entities.accounts[data.result].company;
            }

            return returnValue;
        } else {
            if (successCallback) successCallback(data.entities[schema.getKey()][data.result], res.status);

            // else only return result with appropriate index
            return {
                result: data.result,
                receivedAt: Date.now(),
                reducerIndex: reducerIndex,
            };
        }
    });
}

function onSuccess(dispatch, res, schema, reducerIndex, method, parameter, endpoint, successCallback) {
    // not logged in
    if (res.status === HttpStatus.NO_CONTENT && endpoint === `${TOMCAT_URL}public/account`) {
        return onFailure(dispatch, res, reducerIndex, endpoint, method, parameter, schema, successCallback);
    }

    if (method === 'DELETE') {
        return processDelete(dispatch, schema, parameter, reducerIndex, successCallback);
    } else {
        return processFetchOrUpdate(dispatch, res, schema, reducerIndex, method, endpoint, parameter, successCallback);
    }
}

function onFailure(dispatch, res, reducerIndex, endpoint, method, parameter, schema, errorCallback) {
    if (res.status !== HttpStatus.NO_CONTENT) {
        console.error('API Failure:', res.status, res.statusText, 'errorCallback:', errorCallback !== undefined);
    }

    // call callback if defined
    if (errorCallback) {
        errorCallback({
            status: res.status,
            statusText: res.statusText,
        });

        // else show error message from server
    } else {
        dispatch(addErrorNotification({ code: res.status, message: res.statusText }));
    }

    // if unauthorized: redirect to login page
    if (res.status === HttpStatus.UNAUTHORIZED) {
        // cannot use dispatch(replace); results in an infinite redirect loop between login?expired and operating-tool
        window.location.replace(`${UI_URL}login?expired=true`);
    }

    // if action was patch, but the object does not exist: delete it from the state
    else if (method === 'PATCH' && res.status === HttpStatus.NOT_FOUND) {
        dispatch({
            type: API_FAILURE,
            payload: {
                entityName: schema.getKey(),
                entityValue: parameter,
            },
        });
    }

    return {
        reducerIndex: reducerIndex,
        receivedAt: Date.now(),
        error: {
            status: res.status,
            statusText: res.statusText,
        },
    };
}
