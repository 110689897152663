import IconButton from 'material-ui/IconButton';
import PhotoIcon from 'material-ui/svg-icons/image/photo-camera';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

import { ButtonColors } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ImageCropper, { ImageCropperTypes } from 'src/components/images/image-cropper';
import { TOMCAT_URL } from 'src/common/index';

class Avatar extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    onClick(e) {
        e.preventDefault();

        const { type, handleCroppedImage, t } = this.props;

        this.props.openModal({
            component: ImageCropper,
            componentProps: {
                type: type,
                handleCroppedImage: handleCroppedImage,
                translationFunction: t,
            },
            title:
                (type === ImageCropperTypes.COMPANY ? t('images.upload_logo') : t('images.upload_picture')) +
                t('images.zoom'),
            successButtonLabel: t('images.upload'),
            cancelButtonLabel: t('common_phrases.abort'),
        });
    }

    onDelete() {
        this.props.handleCroppedImage(null);
    }

    render() {
        const { value, disabled, type, documents, t } = this.props;

        const imageURL = value ? `${TOMCAT_URL}public/documents/${value.filename}` : '';

        let title = '';
        let bodyText = '';

        if (type === ImageCropperTypes.COMPANY) {
            title = t('images.delete_logo');
            bodyText = t('images.confirm_delete_logo');
        } else {
            title = t('images.delete_picture');
            bodyText = t('images.confirm_delete_picture');
        }

        if (documents.uploading) return <CircularProgress />;
        else
            return (
                <div id="avatar">
                    <div id="image-div-outer">
                        <img src={imageURL} alt="Logo" />

                        {!disabled && (
                            <div id="image-div-inner">
                                <IconButton
                                    onClick={this.onClick}
                                    className="avatar-upload-button"
                                    tooltip={t('images.upload_picture')}
                                    tooltipPosition="bottom-right">
                                    <PhotoIcon />
                                </IconButton>
                                {value.length !== 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-xs transparent avatar-delete-button"
                                        title={t('user_functionality.delete')}
                                        onClick={() => {
                                            this.props.openModal({
                                                component: ConfirmDialog,
                                                componentProps: {
                                                    event: value,
                                                    onProceedDialog: this.onDelete,
                                                    bodyText: bodyText,
                                                    proceedText: t('user_functionality.delete'),
                                                    cancelText: t('common_phrases.no'),
                                                    cancelButtonColor: ButtonColors.NOTIFY,
                                                    proceedButtonColor: ButtonColors.DANGER,
                                                },
                                                title: title,
                                                noButtons: true,
                                            });
                                        }}>
                                        <span>{title}</span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
    };
};

export default composeModalActions(connect(mapStateToProps)(Avatar));
