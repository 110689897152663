import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { InfoWindow, Marker } from 'react-google-maps';
import busPin from 'static/Busfinder_Pin_50_02.png';

class CustomMapMarker extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isInfoWindowOpen: false,
        };
    }

    onToggleInfoWindowOpen(isOpen) {
        this.setState({ isInfoWindowOpen: isOpen });
    }

    render() {
        const { marker } = this.props;

        return (
            <Marker
                onClick={() => this.onToggleInfoWindowOpen(true)}
                icon={{
                    url: busPin,
                }}
                position={marker.position}>
                {marker.infoWindowContent && this.state.isInfoWindowOpen && (
                    <InfoWindow onCloseClick={() => this.onToggleInfoWindowOpen(false)}>
                        {marker.infoWindowContent}
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}

export default CustomMapMarker;
