import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';

import { Entities, TOMCAT_URL } from 'src/common/index';

import { getMenuItemsByArray } from 'src/utils/helpers';
import {
    asyncCheckDuplicate,
    Location,
    renderImageSelector,
    renderInput,
    renderSelectField,
    validateLocation,
} from 'src/components/misc/redux-form-helpers';
import DateTimePicker from 'src/components/misc/DateTimePicker';
import { ImageCropperTypes } from 'src/components/images/image-cropper';
import { isBlank } from 'src/components/misc/validations';

class TicketEventForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { eventCategories, handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="logo" component={renderImageSelector} t={t} type={ImageCropperTypes.COMPANY} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            label={`${t('common_phrases.name')} *`}
                            hintText={t('events.name_hint')}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="eventCategory"
                            label={`${t('common_phrases.category')} *`}
                            component={renderSelectField}>
                            {getMenuItemsByArray(eventCategories.items, 'name')}
                        </Field>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field name="location" label={`${t('common_phrases.location')} *`} component={Location} />
                    </div>
                    <div className="col-md-6">
                        <Field name="beginDateTime" label={t('events.beginning')} component={DateTimePicker} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Field
                            name="description"
                            label={t('common_phrases.description')}
                            hintText={t('events.description_hint')}
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.location) errors.location = t('error_missing.fill_in_location');
    else if (validateLocation(values.location)) errors.location = t('error_missing.select_address');

    if (!values.beginDateTime) errors.beginDateTime = t('error_missing.fill_in_date');
    else if (moment(values.beginDateTime, 'DD.MM.YYYY HH:mm').isSameOrBefore(moment()))
        errors.beginDateTime = t('events.error_date');
    else if (!props.isCreate && props.ticketEvent && props.ticketEvent.ticketCollections.length > 0) {
        if (
            props.ticketEvent.ticketCollections.some(ticketCollection =>
                moment(ticketCollection.validUntil)
                    .startOf('day')
                    .isAfter(moment(values.beginDateTime, 'DD.MM.YYYY HH:mm')),
            )
        ) {
            errors.beginDateTime = t('events.error_ticket_validity');
        }
    }

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    const { t } = props;
    return asyncCheckDuplicate(
        t,
        `${TOMCAT_URL}api/${Entities.TICKET_EVENT.repository}/checkDuplicateEvent?name=${encodeURIComponent(
            values.name,
        )}&beginDateTime=${moment(values.beginDateTime, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm')}`,
        values.id,
        'name',
        t('events.error_overlapping_event'),
    );
};

export default reduxForm({
    form: 'ticketEventForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name', 'beginDateTime'],
})(TicketEventForm);
