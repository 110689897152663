import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Entities, Projection } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import ModuleEditModal from 'src/components/superadmin/modules/module-edit-modal';
import Toggle from 'material-ui/Toggle';
import Drawer from 'material-ui/Drawer';
import { withTranslation } from 'react-i18next';
import { priceFormatter } from 'src/utils/formatters';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';

class ModuleTable extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });

        this.state = {
            startedFetching: false,
            drawer: {
                open: false,
                selectedRow: undefined,
            },
        };
    }

    componentWillMount() {
        this.props.fetchByCompanyId(Entities.MODULE, this.props.selectedCompany, Projection.DEFAULT);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { modules, selectedCompany } = nextProps;

        if (modules.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        if (selectedCompany !== undefined && this.props.selectedCompany !== selectedCompany)
            this.props.fetchByCompanyId(Entities.MODULE, selectedCompany, Projection.DEFAULT);

        this.tableHelper.processPagination(modules.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.modules, this.props.modules, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { modules, selectedCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !modules.isFetching;

        const tableData = [];
        if (finishFetching && modules.items.length > 0) {
            modules.items.map(module => {
                tableData.push({
                    id: module.id,
                    type: module.type,
                    fee: module.fee,
                    active: module.active,
                });
            });
        }

        return (
            <div>
                <Drawer
                    openSecondary={true}
                    docked={false}
                    width={400}
                    onRequestChange={() =>
                        this.setState({
                            drawer: {
                                open: false,
                                selectedRow: undefined,
                            },
                        })
                    }
                    // don't open drawer (until renderModulConfiguration is implemented)
                    open={false /*this.state.drawer.open*/}>
                    {this.renderModuleConfiguration()}
                </Drawer>

                <button
                    className="btn btn-xs btn-link nav-link"
                    onClick={() => {
                        this.props.openModal({
                            component: ModuleEditModal,
                            componentProps: {
                                isCreate: true,
                                modules: modules,
                                selectedCompany: selectedCompany,
                                closeDrawer: this.handleCloseDrawer,
                            },
                            title: t('user_functionality.create', { entity: t('module') }),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-plus" />
                    &nbsp;{t('user_functionality.create', { entity: t('module') })}
                </button>

                {(() => {
                    if (modules.error) return <ErrorMessage object={modules} />;
                    else
                        return (
                            <div id="companylist-panel">
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="type" dataSort={true}>
                                        {t('common_phrases.type')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="fee"
                                        width="130"
                                        dataAlign="right"
                                        dataFormat={priceFormatter}
                                        csvFormat={priceFormatter}
                                        dataSort={true}>
                                        {t('booked_tasks.fee')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="active"
                                        width="90"
                                        dataFormat={(cell, row) => this.toggleFormatter(cell, row, 'active')}
                                        dataSort={true}>
                                        {t('common_phrases.active')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    renderModuleConfiguration() {
        const { t } = this.props;
        return (
            <div>
                {/*Hier kommt mal die Modul-Konfiguration hin (:*/}
                <h5>&nbsp;{t('modules')}</h5>
            </div>
        );
    }

    onRowClick(row) {
        this.setState({
            drawer: {
                open: !this.state.drawer.open,
                selectedRow: row,
            },
        });

        this.tableHelper.setSelectedRow(row.id);
    }

    handleCloseDrawer() {
        this.setState({
            drawer: {
                open: !this.state.drawer.open,
                selectedRow: undefined,
            },
        });
    }

    toggleFormatter(cell, module, field) {
        return (
            <Toggle
                toggled={module[field]}
                onToggle={() => this.props.patch({ id: module.id, [field]: !module[field] }, Entities.MODULE)}
            />
        );
    }

    onProceedDelete(module) {
        const { selectedCompany } = this.props;

        this.props.deleteById(module.id, Entities.MODULE, selectedCompany);
    }

    openConfirmDeleteDialog(module) {
        const { t } = this.props;

        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: module,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('user_functionality.confirm_delete_s', { entity: t('module') }),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: module.type,
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, module) {
        const { modules, selectedCompany, t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ModuleEditModal,
                            componentProps: {
                                id: module.id,
                                isCreate: false,
                                modules: modules,
                                selectedCompany: selectedCompany,
                                closeDrawer: this.handleCloseDrawer,
                            },
                            title: t('user_functionality.edit'),
                            cancelCallback: this.handleCloseDrawer,
                            mandatoryFields: true,
                        });
                    }}>
                    <span title={t('user_functionality.edit')} className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => this.openConfirmDeleteDialog(module)}>
                    <span title={t('user_functionality.delete')} className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(ModuleTable));
