import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, save } from 'src/common/index';
import CalculationAdministration from 'src/components/administration/calculation-administration';
import { getOwnCompany } from 'src/selectors/bus-companies';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        calculations: getEntities(state, Entities.CALCULATION, state.selectedCompany),
        seasonCalculations: state.entities[Entities.SEASON_CALCULATION.repository],
        countryCalculations: state.entities[Entities.COUNTRY_CALCULATION.repository],
        buses: state.entities[Entities.BUS.repository],
        ownCompany: getOwnCompany(state),
    };
};

const CalculationAdministrationContainer = connect(mapStateToProps, {
    fetchByCompanyId,
    save,
    deleteById,
})(CalculationAdministration);

export default CalculationAdministrationContainer;
