import RaisedButton from 'material-ui/RaisedButton';
import { Tab, Tabs } from 'material-ui/Tabs';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SwipeableViews from 'react-swipeable-views';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { setSliderIndex } from 'src/actions/form';
import { scrollToAnchor } from 'src/utils/helpers';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import DatePicker from 'src/components/misc/DatePicker';
import {
    Entities,
    isIntegerPercent,
    isMoneyAmount,
    isNonNegativeIntegerPercent,
    TOMCAT_URL,
    validatePrice,
} from 'src/common/index';
import { isBlank, notValidTime, validTime } from 'src/components/misc/validations';

class SeasonCalculationEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {
        this.props.setSliderIndex(0);
    }

    render() {
        const { handleSubmit, selectedSliderIndex, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div>
                    <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                        <Tab label={t('common_phrases.common')} value={0} />
                        <Tab label={t('calculation.weekdays')} value={1} />
                        <Tab label={t('calculation.seasons')} value={2} />
                        <Tab label={t('calculation.special_dates')} value={3} />
                        <Tab label={t('driver.driver')} value={4} />
                    </Tabs>
                    <SwipeableViews
                        index={selectedSliderIndex}
                        onChangeIndex={value => this.props.setSliderIndex(value)}>
                        <div className="view">
                            <div id="topSection" className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        label={`${t('common_phrases.name')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="nightStart"
                                        label={`${t('calculation.night_start')} *`}
                                        hintText="HH:mm"
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="nightEnd"
                                        label={`${t('calculation.night_end')} *`}
                                        hintText="HH:mm"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="driverNightSurcharge"
                                        label={`${t('calculation.night_surcharge')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="view">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="mondayRate.amount"
                                        label={`${t('calculation.monday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="mondaySurcharge"
                                        label={t('calculation.monday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="tuesdayRate.amount"
                                        label={`${t('calculation.tuesday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="tuesdaySurcharge"
                                        label={t('calculation.tuesday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="wednesdayRate.amount"
                                        label={`${t('calculation.wednesday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="wednesdaySurcharge"
                                        label={t('calculation.wednesday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="thursdayRate.amount"
                                        label={`${t('calculation.thursday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="thursdaySurcharge"
                                        label={t('calculation.thursday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="fridayRate.amount"
                                        label={`${t('calculation.friday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="fridaySurcharge"
                                        label={t('calculation.friday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="saturdayRate.amount"
                                        label={`${t('calculation.saturday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="saturdaySurcharge"
                                        label={t('calculation.saturday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="sundayRate.amount"
                                        label={`${t('calculation.sunday_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="sundaySurcharge"
                                        label={t('calculation.sunday_percent')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="view">
                            <FieldArray
                                name="seasonSurcharges"
                                buttonLabel={`+ ${t('calculation.new_season')}`}
                                t={t}
                                component={renderSeasonSurcharges}
                            />
                        </div>
                        <div className="view">
                            <FieldArray
                                name="specialSurcharges"
                                t={t}
                                buttonLabel={`+ ${t('calculation.new_special_date')}`}
                                component={renderSpecialSurcharges}
                            />
                        </div>
                        <div className="view">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="holidayDriverSurcharge"
                                        label={`${t('calculation.surcharge_holiday')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="sundayDriverSurcharge"
                                        label={`${t('calculation.surcharge_sunday')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <FieldArray
                                name="holidays"
                                buttonLabel={`+ ${t('calculation.new_holiday')}`}
                                t={t}
                                component={renderHolidays}
                            />
                        </div>
                    </SwipeableViews>
                </div>
            </form>
        );
    }
}

const renderSeasonSurcharges = ({ fields, buttonLabel, t }) => (
    <div>
        <div className="row">
            <div className="col-md-6">
                <RaisedButton
                    secondary={true}
                    label={buttonLabel}
                    onClick={() =>
                        fields.push({
                            name: '',
                            startDate: undefined,
                            endDate: undefined,
                            surcharge: 0,
                        })
                    }
                />
            </div>
        </div>

        {fields.map((seasonSurcharge, index) => (
            <div key={index}>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.name`}
                            label={`${t('common_phrases.name')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.surcharge`}
                            label={t('calculation.surcharge')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.startDate`}
                            label={`${t('calculation.start')} *`}
                            component={DatePicker}
                        />
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.endDate`}
                            label={`${t('calculation.end')} *`}
                            component={DatePicker}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.minimumRate`}
                            label={`${t('calculation.minimum_rate')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-2 voffset">
                        <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const renderSpecialSurcharges = ({ fields, buttonLabel, t }) => (
    <div>
        <div className="row">
            <div className="col-md-6">
                <RaisedButton
                    secondary={true}
                    label={buttonLabel}
                    onClick={() =>
                        fields.push({
                            name: '',
                            startDate: undefined,
                            endDate: undefined,
                            surcharge: 0,
                        })
                    }
                />
            </div>
        </div>

        {fields.map((seasonSurcharge, index) => (
            <div key={index}>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.name`}
                            label={`${t('common_phrases.name')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.surcharge`}
                            label={t('calculation.surcharge')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.startDate`}
                            label={`${t('calculation.start')} *`}
                            component={DatePicker}
                        />
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={`${seasonSurcharge}.endDate`}
                            label={`${t('calculation.end')} *`}
                            component={DatePicker}
                        />
                    </div>
                    <div className="col-md-2 voffset">
                        <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const renderHolidays = ({ fields, buttonLabel, t }) => (
    <div className="voffset">
        <div className="row">
            <div className="col-md-6">
                <RaisedButton secondary={true} label={buttonLabel} onClick={() => fields.splice(0, 0, undefined)} />
            </div>
        </div>

        {fields.map((holiday, index) => (
            <div key={index}>
                <div className="row">
                    <div className="col-md-4">
                        <Field name={holiday} label={t('events.date')} component={DatePicker} />
                    </div>
                    <div className="col-md-2 voffset">
                        <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        mondayRate: {
            amount: undefined,
        },
        tuesdayRate: {
            amount: undefined,
        },
        wednesdayRate: {
            amount: undefined,
        },
        thursdayRate: {
            amount: undefined,
        },
        fridayRate: {
            amount: undefined,
        },
        saturdayRate: {
            amount: undefined,
        },
        sundayRate: {
            amount: undefined,
        },
    };

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isNonNegativeIntegerPercent(values.driverNightSurcharge))
        errors.driverNightSurcharge = t('calculation.fill_in_surcharge_criteria_non_negative');

    if (!isMoneyAmount(values.mondayRate)) errors.mondayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.mondayRate.amount))
        errors.mondayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.tuesdayRate)) errors.tuesdayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.tuesdayRate.amount))
        errors.tuesdayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.wednesdayRate)) errors.wednesdayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.wednesdayRate.amount))
        errors.wednesdayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.thursdayRate)) errors.thursdayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.thursdayRate.amount))
        errors.thursdayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.fridayRate)) errors.fridayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.fridayRate.amount))
        errors.fridayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.saturdayRate)) errors.saturdayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.saturdayRate.amount))
        errors.saturdayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.sundayRate)) errors.sundayRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.sundayRate.amount))
        errors.sundayRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isIntegerPercent(values.mondaySurcharge)) errors.mondaySurcharge = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.tuesdaySurcharge))
        errors.tuesdaySurcharge = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.wednesdaySurcharge))
        errors.wednesdayRate = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.thursdaySurcharge)) errors.thursdayRate = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.fridaySurcharge)) errors.fridayRate = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.saturdaySurcharge)) errors.saturdayRate = t('calculation.fill_in_surcharge_criteria');
    if (!isIntegerPercent(values.sundaySurcharge)) errors.sundayRate = t('calculation.fill_in_surcharge_criteria');

    if (!isNonNegativeIntegerPercent(values.sundayDriverSurcharge))
        errors.sundayDriverSurcharge = t('calculation.fill_in_surcharge_criteria_non_negative');
    if (!isNonNegativeIntegerPercent(values.holidayDriverSurcharge))
        errors.holidayDriverSurcharge = t('calculation.fill_in_surcharge_criteria_non_negative');

    if (values.seasonSurcharges) errors.seasonSurcharges = validateSeasonSurcharges(t, values.seasonSurcharges);

    if (values.specialSurcharges) errors.specialSurcharges = validateSeasonSurcharges(t, values.specialSurcharges);

    if (values.nightStart == null) errors.nightStart = t('calculation.fill_in_start');
    else if (notValidTime(values.nightStart)) errors.nightStart = `${t('error_hint.error_invalid_format')} (HH:mm)`;

    if (values.nightEnd == null) errors.nightEnd = t('calculation.fill_in_end');
    else if (notValidTime(values.nightEnd)) errors.nightEnd = `${t('error_hint.error_invalid_format')} (HH:mm)`;

    if (validTime(values.nightStart) && validTime(values.nightEnd)) {
        const nightDiff = moment(values.nightEnd, 'HH:mm').diff(moment(values.nightStart, 'HH:mm'), 'hours');
        const nightHours = nightDiff < 0 ? nightDiff + 24 : nightDiff;

        if (nightHours < 1 || nightHours > 12) errors.nightEnd = t('calculation.night_duration');
    }

    return errors;
};

const validateSeasonSurcharges = (t, surcharges) => {
    const errors = [];
    surcharges.forEach((surcharge, index) => {
        const error = {
            name: undefined,
            surcharge: undefined,
            startDate: undefined,
            endDate: undefined,
            minimumRate: undefined,
        };

        if (!surcharge.name) {
            error.name = t('calculation.fill_in_name');
            errors[index] = error;
        } else if (!surcharge.startDate) {
            error.startDate = t('calculation.fill_in_start');
            errors[index] = error;
        } else if (!surcharge.endDate) {
            error.endDate = t('calculation.fill_in_end');
            errors[index] = error;
        } else if (moment(surcharge.startDate, 'DD.MM.YYYY').isAfter(moment(surcharge.endDate, 'DD.MM.YYYY'))) {
            error.startDate = t('calculation.error_the_end_before_start');
            error.endDate = t('calculation.error_the_end_before_start');
            errors[index] = error;
        } else if (!isIntegerPercent(surcharge.surcharge)) {
            error.surcharge = t('calculation.fill_in_surcharge_criteria');
            errors[index] = error;
        } else if (surcharge.minimumRate && validatePrice(surcharge.minimumRate)) {
            error.minimumRate = `${t('error_hint.error_invalid_format')} (######.00)`;
            errors[index] = error;
        }
    });

    if (errors.length > 0) return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${
            Entities.SEASON_CALCULATION.repository
        }/search/findByNameAndCompanyId?name=${encodeURIComponent(values.name)}&companyId=${props.selectedCompany}`,
        values.id,
        'name',
    );
};

const onSubmitFail = (errors, dispatch) => {
    if (
        (errors.mondayRate && errors.mondayRate.amount) ||
        (errors.tuesdayRate && errors.tuesdayRate.amount) ||
        (errors.wednesdayRate && errors.wednesdayRate.amount) ||
        (errors.thursdayRate && errors.thursdayRate.amount) ||
        (errors.fridayRate && errors.fridayRate.amount) ||
        (errors.saturdayRate && errors.saturdayRate.amount) ||
        (errors.sundayRate && errors.sundayRate.amount) ||
        errors.mondaySurcharge ||
        errors.tuesdaySurcharge ||
        errors.wednesdaySurcharge ||
        errors.thursdaySurcharge ||
        errors.fridaySurcharge ||
        errors.saturdaySurcharge ||
        errors.sundaySurcharge
    )
        dispatch(setSliderIndex(1));
    else if (errors.seasonSurcharges) dispatch(setSliderIndex(2));
    else if (errors.specialSurcharges) dispatch(setSliderIndex(3));
    else if (errors.sundayDriverSurcharge || errors.holidayDriverSurcharge) dispatch(setSliderIndex(4));
    else {
        scrollToAnchor('topSection');
        dispatch(setSliderIndex(0));
    }
};

export default reduxForm({
    form: 'seasonCalculationEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
    returnRejectedSubmitPromise: true,
})(SeasonCalculationEditForm);
