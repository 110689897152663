import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { reduxForm } from 'redux-form';

class PrintOfferForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div></div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'printTaskForm',
})(PrintOfferForm);
