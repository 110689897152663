import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { asyncCheckDuplicate, renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class CategoryEditForm extends Component {
    render() {
        const { handleSubmit, ownCompany, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="position"
                            label={t('review_categories.position_hint')}
                            hintText={t('category.order_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 voffset">
                        <Field name="group" label={t('category.group_cars')} component={renderCheckbox} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="groupResultLimit"
                            label={t('category.group_result_limit')}
                            hintText={t('category.limit_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                {ownCompany.entity.turistaCompanyId != null && (
                    <div className="row">
                        <div className="col-md-6">
                            <Field
                                name="turistaVehicleGroupId"
                                label={t('category.turista')}
                                component={renderInput}
                                type="number"
                            />
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isInteger(values.position)) errors.position = t('error_hint.error_integer');
    if (values.position < -100 || values.position > 100) errors.position = t('bus.error_order');

    if (!isInteger(values.groupResultLimit)) errors.groupResultLimit = t('error_hint.error_integer');
    if (!values.groupResultLimit || values.groupResultLimit < 1) errors.groupResultLimit = t('category.fill_in_max');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.BUS_CATEGORY.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
            values.name,
        )}&companyId=${props.selectedCompany}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'categoryEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(CategoryEditForm);
