import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import StarRatingEditModal from 'src/components/admin/star-rating/star-rating-edit-modal';
import { withTranslation } from 'react-i18next';

class AdminStarRatingAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.STAR_RATING);
    }

    componentWillReceiveProps(nextProps) {
        const { starRatings } = nextProps;

        if (starRatings.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { starRatings, t } = this.props;

        const finishFetching = this.state.startedFetching && !starRatings.isFetching;

        // options for react bootstrap table
        const options = {
            exportCSVText: t('common_phrases.export_list'),
            noDataText: t('star_rating.no_rating'),
            clearSearch: true,
        };

        const tableData = [];

        if (finishFetching && starRatings.items.length > 0) {
            starRatings.items.map(starRating => {
                tableData.push({
                    id: starRating.id,
                    stars: starRating.stars,
                    rating: starRating.rating,
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('star_rating.ratings')}</legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (starRatings.error) return <ErrorMessage object={starRatings} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={false}
                                options={options}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="stars" dataSort={true}>
                                    {t('star_rating.stars')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="rating" dataSort={true}>
                                    {t('common_phrases.rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, starRating) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: StarRatingEditModal,
                            componentProps: {
                                id: starRating.id,
                            },
                            title:
                                starRating.stars === 1
                                    ? `${starRating.stars} ${t('star_rating.star')}`
                                    : `${starRating.stars} ${t('star_rating.stars')}`,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminStarRatingAdministration));
