import Fleet from 'src/common/entity/fleet/Fleet';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { externalIdFormatter } from 'src/components/admin/accounts/admin-account-administration';
import FleetAccountForm from 'src/components/admin/fleets/FleetAccountForm';
import FleetCompanyForm from 'src/components/admin/fleets/FleetCompanyForm';
import FleetForm from 'src/components/admin/fleets/FleetForm';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { InjectedTranslateProps, StoreState } from 'src/types';
import {
    asyncRequest,
    deleteById,
    DeleteById,
    Entities,
    EntityCollectionState,
    FetchAll,
    fetchAll,
    getEntities,
    Projection,
    TOMCAT_URL,
} from 'src/common/index';
import { ButtonColors } from 'src/utils/constants';
import { toggleFormatter } from 'src/utils/formatters';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { connect } from 'react-redux';
import ParentForm from 'src/components/misc/parent-form';

interface MapStateToProps {
    selectedCompany: number;
    fleets: EntityCollectionState<Fleet>;
}

interface MapDispatchToProps extends FetchAll, DeleteById {}

interface TableDataRow {
    id: number;
    name: string;
    active: boolean;
    companiesCount: number;
    accountsCount: number;
    externalFleetId?: string;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

type Props = MapStateToProps & MapDispatchToProps & InjectedModalProps & InjectedTranslateProps;

class AdminFleetAdministration extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            renderSelection: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.FLEET, 0, Projection.FULL);
    }

    shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps: Props) {
        const { fleets } = nextProps;

        if (!this.state.startedFetching && fleets.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(fleets.items.length);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.fleets, this.props.fleets, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { fleets, t } = this.props;
        const finishFetching = this.state.startedFetching && !fleets.isFetching;

        const tableData: TableData = [];
        if (finishFetching && fleets.items.length > 0) {
            fleets.items.map(fleet => {
                tableData.push({
                    id: fleet.id,
                    name: fleet.name,
                    active: fleet.active,
                    companiesCount: fleet.companies.length,
                    accountsCount: fleet.accounts.length,
                    externalFleetId: fleet.externalFleetId,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('fleets.fleets')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: ParentForm,
                                componentProps: {
                                    isCreate: true,
                                    projection: Projection.FULL,
                                    mainEntityProps: {
                                        entity: Entities.FLEET,
                                        id: undefined,
                                    },
                                    childForm: {
                                        component: FleetForm,
                                    },
                                },
                                title: t('fleets.new_fleet'),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('fleets.new_fleet')}
                    </button>
                </legend>

                {(() => {
                    if (fleets.error) return <ErrorMessage object={fleets} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="companiesCount" dataSort={true}>
                                    {t('common_phrases.company')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="accountsCount" dataSort={true}>
                                    {t('fleets.accounts')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="center"
                                    dataFormat={id => externalIdFormatter(id, t)}
                                    width="90"
                                    dataField="externalFleetId"
                                    dataSort={true}>
                                    {t('common_phrases.import')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="active"
                                    headerText={t('fleets.hint_deactivate')}
                                    width="90"
                                    dataSort={true}
                                    dataFormat={(cell, row) => toggleFormatter(row, Entities.FLEET, 'active')}>
                                    {t('common_phrases.activated')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="130"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    private tableActionsFormatter = (cell: any, fleet: TableDataRow) => {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('fleets.admin_company')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                projection: Projection.FULL,
                                mainEntityProps: {
                                    entity: Entities.FLEET,
                                    id: fleet.id,
                                },
                                childForm: {
                                    component: FleetCompanyForm,
                                },
                                dialogStyle: {
                                    width: '40vw',
                                },
                            },
                            title: `${fleet.name} - ${t('fleets.administration')}`,
                        });
                    }}>
                    <span className="glyphicon glyphicon-home text-success" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('fleets.admin_accounts')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                projection: Projection.FULL,
                                mainEntityProps: {
                                    entity: Entities.FLEET,
                                    id: fleet.id,
                                },
                                childForm: {
                                    component: FleetAccountForm,
                                },
                                dialogStyle: {
                                    width: '40vw',
                                },
                            },
                            title: `${fleet.name} - ${t('fleets.account_admin')}`,
                        });
                    }}>
                    <span className="glyphicon glyphicon-user text-warning" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                projection: Projection.FULL,
                                mainEntityProps: {
                                    entity: Entities.FLEET,
                                    id: fleet.id,
                                },
                                childForm: {
                                    component: FleetForm,
                                },
                            },
                            title: fleet.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.FLEET.repository}/${fleet.id}/deletable`)
                            .then(response => this.openConfirmDeleteDialog(fleet))
                            .catch(error => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    };

    private onProceedDelete = (fleet: TableDataRow) => {
        this.props.deleteById(fleet.id, Entities.FLEET, 0);
    };

    private openConfirmDeleteDialog = (fleet: TableDataRow) => {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: fleet,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('fleets.confirm_delete'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: fleet.name,
            noButtons: true,
        });
    };

    private openCannotDeleteDialog = () => {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('fleets.hint_related_accounts'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        fleets: getEntities(state, Entities.FLEET),
    };
};

export default withTranslation()(
    connect(mapStateToProps, { fetchAll, deleteById })(composeModalActions(AdminFleetAdministration)),
);
