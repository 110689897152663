import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { logout } from 'src/common/index';

class Logout extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.logout();
    }

    render() {
        const { t } = this.props;
        return (
            <form id="form" onSubmit={this.handleSubmit}>
                <button className="btn btn-link nav-link" type="submit">
                    <span className="glyphicon glyphicon-log-in" />
                    &nbsp;&nbsp;
                    {t('header_logout')}
                </button>
            </form>
        );
    }
}

export default withTranslation()(connect(null, { logout })(Logout));
