import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import {
    pluralizeDays,
    renderCheckbox,
    renderInput,
    renderSlider,
    validatePercentage,
} from 'src/components/misc/redux-form-helpers';
import { InjectedTranslateProps } from 'src/types';
import { isNullOrUndefined } from 'src/utils/helpers';

interface Props extends InjectedTranslateProps {
    fieldPrefix?: string;
    isOnlinePaymentEnabled?: boolean;
    freeCancellation?: boolean;
    freeCancellationDays: number;
    daysBeforeStartMandatoryOnlinePayment?: number;
    swipeableViewRef?: any;
}

const CompanyPaymentSettings: React.FC<Props> = props => {
    const {
        fieldPrefix,
        freeCancellation,
        freeCancellationDays,
        isOnlinePaymentEnabled,
        daysBeforeStartMandatoryOnlinePayment = 0,
        swipeableViewRef,
        t,
    } = props;
    const formattedPrefix = fieldPrefix ? `${fieldPrefix}.` : '';

    useEffect(() => {
        // re-compute height manually until issue is solved:
        // https://github.com/oliviertassinari/react-swipeable-views/issues/535
        if (swipeableViewRef) swipeableViewRef.updateHeight();
    }, [isOnlinePaymentEnabled]);

    let freeCancellationHint = freeCancellation
        ? t('companies.free_cancellation_hint_active', { days: freeCancellationDays })
        : t('companies.free_cancellation_hint_inactive');

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <Field
                        name={`${formattedPrefix}enableOnlinePayment`}
                        label={t('companies.online_payment_activated')}
                        component={renderCheckbox}
                    />
                </div>
                {isOnlinePaymentEnabled && (
                    <div className="col-md-6">
                        <Field
                            name={`${formattedPrefix}daysBeforeStartMandatoryOnlinePayment`}
                            label={
                                daysBeforeStartMandatoryOnlinePayment
                                    ? 'companies.days_before_mandatory_payment_hint'
                                    : 'companies.online_payment_not_mandatory'
                            }
                            component={renderSlider}
                            labelValueFormat={pluralizeDays}
                            t={t}
                            min={0}
                            max={365}
                            step={1}
                        />
                    </div>
                )}
            </div>
            {isOnlinePaymentEnabled && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <Field
                                name={`${formattedPrefix}mandatoryOnlinePaymentForForeignCustomers`}
                                label={t('companies.online_payment_mandatory_for_foreign_customers')}
                                component={renderCheckbox}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name={`${formattedPrefix}mandatoryOnlinePaymentForNewCustomers`}
                                label={t('companies.online_payment_mandatory_for_new_customers')}
                                component={renderCheckbox}
                            />
                        </div>
                    </div>
                    <div className="row voffset">
                        <div className="col-md-6">
                            <Field
                                name={`${formattedPrefix}preferOnlinePayment`}
                                label={t('companies.prefer_online_payment')}
                                component={renderCheckbox}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name={`${formattedPrefix}denyOnlinePaymentDuringFreeCancellation`}
                                label={t('companies.deny_online_payment_during_free_cancellation', {
                                    hint: freeCancellationHint,
                                })}
                                component={renderCheckbox}
                            />
                        </div>
                    </div>
                    <div className="row voffset">
                        <div className="col-md-6">
                            <Field
                                name={`${formattedPrefix}cashbackOnlinePayment`}
                                label={t('companies.cashbackOnlinePayment')}
                                component={renderInput}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CompanyPaymentSettings;

export const validateCashback = (t: any, cashback: any): string | undefined => {
    if (isNullOrUndefined(cashback) || cashback.length === 0) return t('error_missing.fill_in_cashback');
    if (!/^\d{1,3}(?:\.\d)?$/i.test(cashback)) return `${t('error_hint.error_invalid_format')} (###.0)`;
    if (!validatePercentage(cashback)) return t('error_hint.cashback_criteria');

    return undefined;
};
