import React from 'react';
import sanitizeHtml from 'sanitize-html';

interface Props {
    element?: any;
    html: any;
    className?: string;
    omitBreaks?: boolean;
    newTabLinks?: boolean;
    sanitizeHtmlOptions?: any;
}

/**
 * Default options from https://github.com/apostrophecms/sanitize-html.
 * Also allow 'rel' attribute on 'a'.
 */
const defaultOptions = {
    allowedTags: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'blockquote',
        'p',
        'a',
        'ul',
        'ol',
        'nl',
        'li',
        'b',
        'i',
        'strong',
        'em',
        'strike',
        'abbr',
        'code',
        'hr',
        'br',
        'div',
        'table',
        'thead',
        'caption',
        'tbody',
        'tr',
        'th',
        'td',
        'pre',
        'iframe',
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
        a: ['href', 'name', 'target', 'rel'],
        // We don't currently allow img itself by default, but this
        // would make sense if we did. You could add srcset here,
        // and if you do the URL is checked for safety
        img: ['src'],
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false,
};

export const SanitizedInnerHTML = (props: Props) => {
    const { html, className, omitBreaks, newTabLinks, sanitizeHtmlOptions } = props;
    const sanitizedHtml = sanitizeHtml(html ? html : '', sanitizeHtmlOptions ? sanitizeHtmlOptions : defaultOptions);

    let finalHtml = sanitizedHtml;

    if (omitBreaks) finalHtml = finalHtml.replace(/<br ?\/>/g, ' ');

    if (newTabLinks) finalHtml = finalHtml.replace(/<a/g, '$& target="_blank"');

    const element = typeof props.element === 'string' ? props.element : 'div';

    return React.createElement(element, {
        dangerouslySetInnerHTML: { __html: finalHtml },
        className: className,
    });
};
