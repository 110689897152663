import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import moment from 'moment';
import 'moment-duration-format';
import 'canvas-toBlob';
import routes from 'src/routes';
import store from 'src/store/store';
import 'src/sentry';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryHandler } from './components/error/ErrorBoundaryHandler';
// third-party css
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-image-gallery/build/image-gallery.css';
import 'cropperjs/dist/cropper.css';
// fullcalendar
import 'fullcalendar';
import 'fullcalendar-scheduler';
// import 'node_modules/fullcalendar/dist/locale/de-at';
import 'node_modules/fullcalendar/dist/fullcalendar.css';
import 'node_modules/fullcalendar-scheduler/dist/scheduler.css';
// jquery plugins
import 'node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import 'jquery-datetimepicker';
import 'jquery-mousewheel';
// global css
import '../css/styles.pcss';
import 'flag-icon-css/css/flag-icon.min.css';
// initialized i18next instance
import { I18nextProvider } from 'react-i18next';
import i18next from 'src/i18n';
// create schemas
import createEntitySchemas from 'src/actions/api/schemas';

// TODO: eventually we will have to update React and deal with this,
//  but for now, hide these annoying React deprecation warnings
const consoleWarn = console.warn;
console.warn = function filterWarnings(warning) {
    const suppressedWarnings = [
        'Warning: componentWillMount has been renamed, and is not recommended for use.',
        'Warning: componentWillReceiveProps has been renamed, and is not recommended for use.',
        'Warning: componentWillUpdate has been renamed, and is not recommended for use.',
        'react-i18next:: It seems you are still using the old wait option, you may migrate to the new useSuspense behaviour.',
    ];
    if (warning.length && !suppressedWarnings.some(entry => warning.includes(entry))) {
        consoleWarn.apply(console, arguments);
    }
};

createEntitySchemas();

// namespace for global variables
window.bus = {};

// set moment locale and default format
moment.locale('de');
moment.defaultFormat = 'L LT'; // date followed by time; for 'de' this is 'DD.MM.YYYY HH:mm'

// datetimepicker: set locale and moment date time formatter
$.datetimepicker.setLocale('de');
$.datetimepicker.setDateFormatter({
    parseDate: function (date, format) {
        const d = moment(date, format);
        return d.isValid() ? d.toDate() : false;
    },

    formatDate: function (date, format) {
        return moment(date).format(format);
    },
});

const history = syncHistoryWithStore(browserHistory, store);

render(
    <Sentry.ErrorBoundary fallback={ErrorBoundaryHandler}>
        <I18nextProvider i18n={i18next}>
            <Provider store={store}>
                <Suspense fallback={<div />}>
                    <Router history={history} routes={routes} />
                </Suspense>
            </Provider>
        </I18nextProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
);
