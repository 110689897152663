import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { isMoneyAmount, ModuleType, validatePrice } from 'src/common/index';
import { renderCheckbox, renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import MailConfigForm, { validateMailServerConfig } from 'src/components/superadmin/modules/mail-config-form';
import { getMenuItemsByEnum } from 'src/utils/helpers';

class ModuleEditForm extends Component {
    render() {
        const { handleSubmit, modules, isCreate, t } = this.props;

        const moduleTypeMenuItems = getMenuItemsByEnum(
            Object.keys(ModuleType).sort((a, b) => a.localeCompare(b)),
            modules.map(module => module.type),
        );

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="type"
                            label={`${t('common_phrases.type')} *`}
                            component={renderSelectField}
                            disabled={!isCreate}>
                            {moduleTypeMenuItems}
                        </Field>
                    </div>
                    <div className="col-md-6 voffset40">
                        <Field name="active" label={t('common_phrases.active')} component={renderCheckbox} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="fee.amount" label={`${t('booked_tasks.fee')} *`} component={renderInput} />
                    </div>
                </div>
                {this.renderModuleConfigByType()}
            </form>
        );
    }

    /**
     * Render module config input fields depending on module type
     */
    renderModuleConfigByType() {
        const { module, isCreate, t } = this.props;
        const moduleType = !isCreate && module != null ? module.type : this.props.selectedType;
        switch (moduleType) {
            case ModuleType.CUSTOM_MAIL_SERVER:
                return <MailConfigForm config={module != null ? module.config : null} t={t} />;
            case ModuleType.ONLINE_PAYMENT:
                return (
                    <div className="row">
                        <div className="col-md-6">
                            <Field name="transactionFee.amount" label={t('transaction_fee')} component={renderInput} />
                        </div>
                    </div>
                );
            default:
                return <div />;
        }
    }
}

/**
 * Validates the module form
 * In addition, depending on the module type, the related config gets also validated
 */
const validate = (values, props) => {
    const { t, isCreate } = props;
    const errors = {
        fee: {
            amount: undefined,
        },
        transactionFee: {
            amount: undefined,
        },
    };

    if (isCreate && values.type == null) errors.type = t('error_missing.fill_in');

    if (!isMoneyAmount(values.fee)) errors.fee.amount = t('error_missing.fill_in_fee');
    else if (validatePrice(values.fee.amount))
        errors.fee.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
    else if (values.fee.amount < 0 || values.fee.amount > 1000) errors.fee.amount = t('error_hint.fee_criteria');

    if (isMoneyAmount(values.transactionFee)) {
        if (validatePrice(values.transactionFee.amount))
            errors.transactionFee.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
        else if (values.transactionFee.amount < 0 || values.transactionFee.amount > 1000)
            errors.transactionFee.amount = t('error_hint.fee_criteria');
    }

    // validate config depending on module type
    switch (values.type) {
        case ModuleType.CUSTOM_MAIL_SERVER:
            errors.config = validateMailServerConfig(values.config, t);
            break;
        default:
            errors.config = undefined;
            break;
    }

    return errors;
};

export default reduxForm({
    form: 'moduleEditForm',
    validate,
})(ModuleEditForm);
