import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import { deleteBusEquipment } from 'src/actions/bus-equipments';
import AdminEquipmentAdministration from 'src/components/admin/equipments/admin-equipment-administration';

const mapStateToProps = state => {
    return {
        equipments: getEntities(state, Entities.BUS_EQUIPMENT),
    };
};

const AdminEquipmentAdministrationContainer = connect(mapStateToProps, { fetchAll, deleteBusEquipment })(
    AdminEquipmentAdministration,
);

export default AdminEquipmentAdministrationContainer;
