import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import CustomMapMarker from 'src/components/maps/custom-map-marker';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

class CustomGoogleMap extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { markers, clustering } = this.props;

        return (
            <GoogleMap defaultZoom={7} defaultCenter={{ lat: 47.6783215, lng: 13.5032254 }}>
                {clustering ? (
                    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
                        {markers &&
                            Array.isArray(markers) &&
                            markers.map((marker, index) => <CustomMapMarker key={index} marker={marker} />)}
                    </MarkerClusterer>
                ) : (
                    markers &&
                    Array.isArray(markers) &&
                    markers.map((marker, index) => <CustomMapMarker key={index} marker={marker} />)
                )}
            </GoogleMap>
        );
    }
}

export default withGoogleMap(CustomGoogleMap);
