import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities, patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { getOwnCompany } from 'src/selectors/bus-companies';
import BusAdministration from 'src/components/administration/bus-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        buses: getEntities(state, Entities.BUS, state.selectedCompany),
        brands: state.entities[Entities.BUS_BRAND.repository],
        categories: state.entities[Entities.BUS_CATEGORY.repository],
        engines: state.entities[Entities.BUS_ENGINE.repository],
        calculations: state.entities.calculations,
        busCompany: getOwnCompany(state),
    };
};

const BusAdministrationContainer = connect(mapStateToProps, {
    fetchOwnBusCompany,
    fetchByCompanyId,
    deleteById,
    patch,
})(BusAdministration);

export default BusAdministrationContainer;
