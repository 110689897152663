import { connect } from 'react-redux';

import { deleteById, Entities, fetchByCompanyId, getEntities } from 'src/common/index';
import CategoryAdministration from 'src/components/administration/category-administration';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        categories: getEntities(state, Entities.BUS_CATEGORY, state.selectedCompany),
        buses: getEntities(state, Entities.BUS, state.selectedCompany),
    };
};

const CategoryAdministrationContainer = connect(mapStateToProps, { fetchByCompanyId, deleteById })(
    CategoryAdministration,
);

export default CategoryAdministrationContainer;
