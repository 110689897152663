import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderInput } from 'src/components/misc/redux-form-helpers';

class InternalRatingForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="internalRating"
                            label={t('companies.internal_rating')}
                            hintText={t('companies.rating_hint')}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (values.internalRating < 0 || values.internalRating > 100)
        errors.internalRating = t('companies.rating_error_hint');

    return errors;
};

export default reduxForm({
    form: 'internalRatingForm',
    validate,
})(InternalRatingForm);
