import 'moment-range';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import BusDriver from 'src/components/operatingtool/busdrivers/bus-driver';
import DriverRules from 'src/components/operatingtool/busdrivers/driver-rules';
import { withTranslation } from 'react-i18next';

class BusDriverList extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
            availableDrivers: [],
        };
    }

    componentWillMount() {
        const { fetchByCompanyId, selectedCompany } = this.props;

        fetchByCompanyId(Entities.DRIVER, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { drivers, eventDetails, selectedCompany, tasks } = nextProps;

        if (drivers.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        if (!tasks.isFetching && !drivers.isFetching) {
            if (eventDetails && eventDetails.event && !_.isEqual(this.props.eventDetails, eventDetails)) {
                asyncRequest(
                    `${TOMCAT_URL}api/${Entities.DRIVER.repository}/range?fromTime=${eventDetails.event.from.time}&toTime=${eventDetails.event.to.time}&taskId=${eventDetails.event.id}&companyId=${selectedCompany}`,
                ).then(response => this.receiveDriversInRange(response.json, drivers, eventDetails));
            } else if (!eventDetails) {
                const driversTableData = drivers.items.map(driver => {
                    return {
                        id: driver.id,
                        name: driver.contactData.fullName,
                        busdriver: driver,
                    };
                });

                this.setState({ availableDrivers: driversTableData });
            }
        }
    }

    render() {
        const { drivers, tasks, t } = this.props;
        const finishFetching = this.state.startedFetching && !drivers.isFetching && !tasks.isFetching;

        // options for react bootstrap table
        const options = {
            noDataText: t('driver.no_available_drivers'),
        };

        return (
            <div className="details">
                {(() => {
                    if (drivers.error) return <ErrorMessage object={drivers} />;
                    else
                        return (
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <table
                                        className="table table-details"
                                        style={{
                                            /* tableLayout: 'fixed', */
                                            boxSizing: 'border-box',
                                            fontSize: '14px',
                                        }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div
                                                        id="header_buslenker"
                                                        style={{
                                                            transform: 'rotate(-90deg)',
                                                            WebkitTransform: 'rotate(-90deg)',
                                                            marginTop: '90px',
                                                            position: 'relative',
                                                            overflow: 'visible',
                                                            width: '10px',
                                                            top: '0px',
                                                            left: '0px',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                        }}>
                                                        {t('driver.bus_driver')}
                                                    </div>
                                                </td>
                                                <td id="td-bus-driverlist">
                                                    {finishFetching ? (
                                                        <div id="bus-driverlist">
                                                            <BootstrapTable
                                                                data={this.state.availableDrivers}
                                                                striped={true}
                                                                hover={true}
                                                                options={options}
                                                                height={200}
                                                                tableBodyClass={'busdrivertable'}
                                                                tableHeaderClass={'busdriverheadertable'}>
                                                                <TableHeaderColumn
                                                                    hidden={true}
                                                                    dataField="id"
                                                                    isKey={true}
                                                                />
                                                                <TableHeaderColumn
                                                                    dataField="name"
                                                                    dataFormat={this.driverFormatter}
                                                                    dataAlign="left"
                                                                    filter={{
                                                                        type: 'TextFilter',
                                                                        placeholder: t('driver.search_driver'),
                                                                    }}
                                                                />
                                                            </BootstrapTable>
                                                        </div>
                                                    ) : (
                                                        <CircularProgress />
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                })()}
            </div>
        );
    }

    driverFormatter(cell, row) {
        const { t } = this.props;

        return (
            <BusDriver
                t={t}
                busdriver={row.busdriver}
                patch={this.props.patch}
                addNotification={this.props.addNotification}
                selectedCompany={this.props.selectedCompany}
            />
        );
    }

    receiveDriversInRange(driversInRange, drivers, eventDetails) {
        const driversTableData = DriverRules.getPopulatedDriverProps(drivers.items, driversInRange)
            .filter(driver => {
                return (
                    !(eventDetails.event.firstDriver && eventDetails.event.firstDriver.id === driver.id) &&
                    !(eventDetails.event.secondDriver && eventDetails.event.secondDriver.id === driver.id)
                );
            })
            .map(driver => {
                return {
                    id: driver.id,
                    name: driver.contactData.fullName,
                    busdriver: driver,
                };
            });

        this.setState({ availableDrivers: driversTableData });
    }
}

export default withTranslation()(BusDriverList);
