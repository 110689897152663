import { connect } from 'react-redux';

import { Entities, fetchAll, getEntities } from 'src/common/index';
import ModuleAdministration from 'src/components/superadmin/modules/module-administration';

const mapStateToProps = state => {
    return {
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
    };
};

const ModuleAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
})(ModuleAdministration);

export default ModuleAdministrationContainer;
