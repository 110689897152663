import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import AgeRatingEditModal from 'src/components/admin/age-rating/age-rating-edit-modal';
import { withTranslation } from 'react-i18next';

class AdminAgeRatingAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.AGE_RATING);
    }

    componentWillReceiveProps(nextProps) {
        const { ageRatings } = nextProps;

        if (ageRatings.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { ageRatings, t } = this.props;

        const finishFetching = this.state.startedFetching && !ageRatings.isFetching;

        // options for react bootstrap table
        const options = {
            exportCSVText: t('common_phrases.export_list'),
            noDataText: t('age_rating.no_age_rating'),
            clearSearch: true,
        };

        const tableData = [];

        if (finishFetching && ageRatings.items.length > 0) {
            ageRatings.items.map(ageRating => {
                tableData.push({
                    id: ageRating.id,
                    age: ageRating.age,
                    rating: ageRating.rating,
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('age_rating.age_ratings')}</legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (ageRatings.error) return <ErrorMessage object={ageRatings} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={false}
                                options={options}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="age" dataSort={true}>
                                    {t('age_rating.age_in_year')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="rating" dataSort={true}>
                                    {t('common_phrases.rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, ageRating) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: AgeRatingEditModal,
                            componentProps: {
                                id: ageRating.id,
                            },
                            title:
                                ageRating.age === 0
                                    ? t('age_rating.less_one_year')
                                    : ageRating.age === 1
                                      ? t('age_rating.one_year')
                                      : t('age_rating.years_old', { years: ageRating.age }),
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminAgeRatingAdministration));
