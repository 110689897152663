import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Entities, fetchAll, getEntities, getUpdatedEntity, patch, save } from 'src/common/index';

import { fetchTicketEventsJoinFetchTickets } from 'src/actions/event-tickets';
import ErrorMessage from 'src/components/misc/error-message';
import TicketEventForm from 'src/components/admin/event-tickets/events/ticket-event-form';
import moment from 'moment/moment';

class TicketEventModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (!isCreate) this.props.fetchTicketEventsJoinFetchTickets(id);

        this.props.fetchAll(Entities.TICKET_EVENT_CATEGORY);
    }

    componentWillReceiveProps(nextProps) {
        const { eventCategories } = nextProps;

        if (eventCategories.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { ticketEvent, eventCategories, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !eventCategories.isFetching && !ticketEvent.isFetching;

        if (finishFetching) {
            if (ticketEvent.error) return <ErrorMessage object={ticketEvent} />;
            else {
                let initialValues;

                // if form is used to edit a ticket event -> load initial values
                if (!isCreate && ticketEvent.content) {
                    const eventCategory =
                        ticketEvent.content.eventCategory &&
                        eventCategories.items.find(
                            eventCategory => eventCategory.id === ticketEvent.content.eventCategory.id,
                        );

                    initialValues = {
                        id: ticketEvent.content.id,
                        name: ticketEvent.content.name,
                        eventCategory: eventCategory ? eventCategory._links.self.href : undefined,
                        location: ticketEvent.content.location,
                        beginDateTime: moment(ticketEvent.content.beginDateTime).format('DD.MM.YYYY HH:mm'),
                        description: ticketEvent.content.description,
                        logo: ticketEvent.content.logo,
                    };
                } else {
                    initialValues = {
                        eventCategory: eventCategories.items[_.findIndex(eventCategories.items)]._links.self.href || '',
                        beginDateTime: moment().add(1, 'day').hour(12).minute(0).format('DD.MM.YYYY HH:mm'),
                    };
                }

                return (
                    <TicketEventForm
                        initialValues={initialValues}
                        ticketEvent={ticketEvent.content}
                        isCreate={isCreate}
                        eventCategories={eventCategories}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, isCreate } = this.props;

        data.beginDateTime = moment(data.beginDateTime, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');

        if (isCreate) this.props.save(data, Entities.TICKET_EVENT);
        else this.props.patch(data, Entities.TICKET_EVENT, 0, 'event-quantity');

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        ticketEvent: getUpdatedEntity(state, Entities.TICKET_EVENT),
        eventCategories: getEntities(state, Entities.TICKET_EVENT_CATEGORY),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchTicketEventsJoinFetchTickets,
        fetchAll,
        patch,
        save,
    },
    null,
    { withRef: true },
)(TicketEventModal);
