import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors } from 'src/utils/constants';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import CalculationEditModal from 'src/components/administration/calculation-edit-modal';
import CalculationBuses from 'src/components/administration/calculation-buses';
import { withTranslation } from 'react-i18next';
import { priceFormatter } from 'src/utils/formatters';

class CalculationAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            drawer: {
                open: false,
                selectedRow: undefined,
            },
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchByCompanyId(Entities.CALCULATION, selectedCompany);
        this.props.fetchByCompanyId(Entities.SEASON_CALCULATION, selectedCompany);
        this.props.fetchByCompanyId(Entities.COUNTRY_CALCULATION, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { calculations } = nextProps;

        if (calculations.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(calculations.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.calculations, this.props.calculations, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { calculations, seasonCalculations, countryCalculations, ownCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !calculations.isFetching;

        const tableData = [];

        if (finishFetching && calculations.items.length > 0) {
            calculations.items.map(calculation => {
                tableData.push({
                    id: calculation.id,
                    name: calculation.name,
                    kilometreRate: calculation.kilometreRate,
                    driverRate: calculation.driverRate,
                    splitOperation: calculation.splitOperation,
                    forbidSecondDriver: calculation.forbidSecondDriver,
                    specialVehicle: calculation.specialVehicle,
                    onlyForDailyTrips: calculation.onlyForDailyTrips,
                    onlyForDailyFlatRate: calculation.onlyForDailyFlatRate,
                    skipAvailabilityCheck: calculation.skipAvailabilityCheck,
                    seasonCalculation: seasonCalculations[calculation.seasonCalculation],
                    seasonCalculationName: seasonCalculations[calculation.seasonCalculation].name,
                    countryCalculation: countryCalculations[calculation.countryCalculation],
                    countryCalculationName: countryCalculations[calculation.countryCalculation].name,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('administration.calculation')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CalculationEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('calculation.new_norm_rate'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('calculation.new_norm_rate')}
                    </button>
                </legend>

                {(() => {
                    if (calculations.error) return <ErrorMessage object={calculations} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}
                                trClassName="table-calculation-administration">
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" width="200" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="kilometreRate"
                                    dataFormat={priceFormatter}
                                    dataSort={true}
                                    width="100">
                                    {t('calculation.km_rate')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="driverRate"
                                    dataFormat={priceFormatter}
                                    dataSort={true}
                                    width="90">
                                    {t('calculation.driver_rate')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="forbidSecondDriver"
                                    dataFormat={this.statusFormatter}
                                    dataSort={true}>
                                    {t('calculation.forbid_second_driver')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="splitOperation"
                                    dataFormat={this.statusFormatter}
                                    dataSort={true}>
                                    {t('calculation.split_operation')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="specialVehicle"
                                    dataFormat={this.statusFormatter}
                                    dataSort={true}>
                                    {t('calculation.specialVehicle')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="onlyForDailyTrips"
                                    dataFormat={this.statusFormatter}
                                    dataSort={true}>
                                    {t('calculation.onlyForDailyTrips')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="onlyForDailyFlatRate"
                                    dataFormat={this.statusFormatter}
                                    dataSort={true}>
                                    {t('calculation.onlyForDailyFlatRate')}
                                </TableHeaderColumn>
                                {ownCompany.entity.calculationSkipAvailabilityCheck && (
                                    <TableHeaderColumn
                                        dataField="skipAvailabilityCheck"
                                        dataFormat={this.statusFormatter}
                                        dataSort={true}>
                                        {t('calculation.skipAvailabilityCheck')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn columnTitle={true} dataField="seasonCalculationName" dataSort={true}>
                                    {t('calculation.season_calculation')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="countryCalculationName"
                                    dataSort={true}>
                                    {t('calculation.country_calculation')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="120"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    statusFormatter(cell) {
        const { t } = this.props;
        if (cell) return <span title={t('common_phrases.yes')} className="fa fa-check-circle-o text-success fa-lg" />;
        else return <span title={t('common_phrases.no')} className="fa fa-circle-o text-danger fa-lg" />;
    }

    openConfirmCopyDialog(copyName, calculationCopy, name) {
        const { t } = this.props;
        calculationCopy.name = copyName;

        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: calculationCopy,
                onProceedDialog: this.onProceedCopy,
                bodyText: t('calculation.confirm_duplicate_norm'),
                proceedText: t('user_functionality.duplicate'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
            },
            title: name,
            noButtons: true,
        });
    }

    openConfirmDeleteDialog(calculation) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: calculation,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('calculation.confirm_delete_norm'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: calculation.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('calculation.error_related_buses'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    onProceedCopy(calculation) {
        this.props.save(calculation, Entities.CALCULATION, this.props.selectedCompany);
    }

    onProceedDelete(calculation) {
        this.props.deleteById(calculation.id, Entities.CALCULATION, this.props.selectedCompany);
    }

    openBusDialog(buses, calculation) {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: <CalculationBuses buses={buses} />,
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('calculation.assigned_buses', { name: calculation.name }),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, calculation) {
        const { selectedCompany, calculations, t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('calculation.show_buses')}
                    onClick={() =>
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.CALCULATION.repository}/${calculation.id}/buses`,
                        ).then(response => this.openBusDialog(response.json, calculation))
                    }>
                    <span className="glyphicon glyphicon-list text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CalculationEditModal,
                            componentProps: {
                                isCreate: false,
                                id: calculation.id,
                            },
                            title: calculation.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span title={t('user_functionality.edit')} className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        // get selected calculation from state and crop props for saving a calculation copy
                        const calculationCopy = Object.assign(
                            {},
                            calculations.items.find(entity => entity.id === calculation.id),
                        );
                        delete calculationCopy.id;
                        delete calculationCopy._links;
                        delete calculationCopy.buses;

                        calculationCopy.company = `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`;
                        calculationCopy.seasonCalculation = `${TOMCAT_URL}api/${Entities.SEASON_CALCULATION.repository}/${calculation.seasonCalculation.id}`;
                        calculationCopy.countryCalculation = `${TOMCAT_URL}api/${Entities.COUNTRY_CALCULATION.repository}/${calculation.countryCalculation.id}`;

                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.CALCULATION.repository}/copyName?name=${encodeURIComponent(
                                calculation.name,
                            )}&companyId=${selectedCompany}`,
                        ).then(response => this.openConfirmCopyDialog(response.json.copyName, calculationCopy, name));
                    }}>
                    <span title={t('user_functionality.duplicate')} className="fa fa-files-o text-warning" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() =>
                        asyncRequest(`${TOMCAT_URL}api/${Entities.CALCULATION.repository}/${calculation.id}/deletable`)
                            .then(() => this.openConfirmDeleteDialog(calculation))
                            .catch(() => this.openCannotDeleteDialog())
                    }>
                    <span title={t('user_functionality.delete')} className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

CalculationAdministration.propTypes = {
    selectedCompany: PropTypes.number.isRequired,
    calculations: PropTypes.shape({
        items: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired,
    }).isRequired,
};

export default withTranslation()(composeModalActions(CalculationAdministration));
