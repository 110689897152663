import { CalendarResourceGroup, CalendarResourceType, EventType } from 'src/utils/constants';
import _ from 'lodash';

export function getBusesInternResourcesCategorized(categoryEntities, busResults, busEntities, driverEntities) {
    const busResources = categoryEntities.map(category => {
        return {
            groups: CalendarResourceGroup.VEHICLES,
            title: category.name,
            children:
                busResults.length > 0
                    ? getBusResourcesByCategory(category, busEntities, busResults, driverEntities)
                    : [],
            position: category.position,
            id: 'cat' + category.id,
        };
    });

    const inactiveBuses = _.filter(busEntities, bus => busResults.find(busId => busId === bus.id) && !bus.active);
    return busResources.concat([
        {
            groups: CalendarResourceGroup.VEHICLES,
            title: CalendarResourceType.VEHICLE_INACTIVE,
            children: generateBusResources(inactiveBuses, driverEntities),
            position: 999,
            id: CalendarResourceType.VEHICLE_INACTIVE,
        },
    ]);
}

function getBusResourcesByCategory(category, busEntities, busResults, driverEntities) {
    const busesForCategory = _.filter(busEntities, bus => {
        return busResults.find(busId => busId === bus.id) && bus.category === category.id && bus.active === true;
    });

    return generateBusResources(busesForCategory, driverEntities);
}

function generateBusResources(busesForCategory, driverEntities) {
    return busesForCategory.sort(compareBusNames).map(bus => ({
        id: bus.id.toString(),
        title: bus.name,
        releaseMode: bus.releaseMode,
        selfLink: bus._links.self.href,
        busAvatar: bus.avatar,
        seatsFacingBack: bus.seatsFacingBack,
        seatsFacingFront: bus.seatsFacingFront,
        seatsForGuides: bus.seatsForGuides,
        regularDriver: driverEntities ? driverEntities[bus.regularDriver] : undefined,
        stars: bus.stars,
        calculatedRating: bus.calculatedRating,
        homeBase: bus.homeBase,
    }));
}

function compareBusNames(bus1, bus2) {
    const bus1Name = bus1.name.toUpperCase();
    const bus2Name = bus2.name.toUpperCase();

    if (bus1Name < bus2Name) return -1;
    if (bus1Name > bus2Name) return 1;

    return 0;
}

export function getReleaseEvents(entities) {
    const items = [];
    let releaseColor = '#007E48';

    entities.forEach(entity => {
        if (entity.percentage > 0) {
            releaseColor = '#004B2B';
        } else if (entity.percentage < 0) {
            releaseColor = '#00AD63 ';
        }

        if (entity.id)
            items.push({
                id: entity.id,
                title: entity.name,
                resourceId: entity.bus,
                start: entity.from.time,
                end: entity.to.time,
                percentage: entity.percentage,
                color: releaseColor,
                type: EventType.RELEASE,
                location: {
                    from: entity.from.location,
                    to: entity.to.location,
                },
            });
    });

    return items;
}

export function getBlockageEvents(entities) {
    const items = [];

    entities.forEach(entity => {
        if (entity.id)
            items.push({
                id: entity.id,
                title: entity.name,
                resourceId: entity.bus,
                start: entity.from.time,
                end: entity.to.time,
                color: '#f37474',
                borderColor: 'lightgrey',
                type: EventType.BLOCKAGE,
                location: {
                    from: entity.from.location,
                    to: entity.to.location,
                },
            });
    });

    return items;
}

export function getReleaseEvent(release) {
    let releaseColor = '#007E48';
    if (release.percentage > 0) releaseColor = '#004B2B';
    else if (release.percentage < 0) releaseColor = '#00AD63 ';

    return {
        id: release.id,
        title: release.name,
        resourceId: release.bus,
        start: release.from.time,
        end: release.to.time,
        percentage: release.percentage,
        color: releaseColor,
        type: EventType.RELEASE,
        location: {
            from: release.from.location,
            to: release.to.location,
        },
    };
}

export function getBlockageEvent(blockage) {
    return {
        id: blockage.id,
        title: blockage.name,
        resourceId: blockage.bus,
        start: blockage.from.time,
        end: blockage.to.time,
        color: '#f37474',
        borderColor: 'lightgrey',
        type: EventType.BLOCKAGE,
        location: {
            from: blockage.from.location,
            to: blockage.to.location,
        },
    };
}

export function getTaskEvent(task) {
    return {
        id: task.id,
        title: task.name,
        resourceId: task.calendarProps.resourceId,
        start: task.from.time,
        taskFrom: task.taskFrom,
        intermediates: task.intermediates,
        taskTo: task.taskTo,
        end: task.to.time,
        type: task.type,
        color: task.calendarProps.color,
        state: task.state,
        location: {
            from: task.from.location,
            to: task.to.location,
        },
        firstDriver: task.firstDriver,
        secondDriver: task.secondDriver,
        passengers: task.passengers,
        detailsSentToDrivers: task.detailsSentToDrivers,
        customer: task.customer,
        externTaskId: task.externTaskId,
    };
}
