import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import { ButtonColors } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import CountryCalculationEditModal from 'src/components/administration/country-calculation-edit-modal';
import { withTranslation } from 'react-i18next';

class CountryCalculationAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchByCompanyId(Entities.COUNTRY_CALCULATION, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { countryCalculations } = nextProps;

        if (countryCalculations.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(countryCalculations.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.countryCalculations,
                this.props.countryCalculations,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { countryCalculations, t } = this.props;
        const finishFetching = this.state.startedFetching && !countryCalculations.isFetching;

        const tableData = [];

        if (finishFetching && countryCalculations.items.length > 0) {
            countryCalculations.items.map(countryCalculation => {
                tableData.push({
                    id: countryCalculation.id,
                    name: countryCalculation.name,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('administration.country_calculation')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CountryCalculationEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('calculation.new_country_rate'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('calculation.new_country_rate')}
                    </button>
                </legend>

                {(() => {
                    if (countryCalculations.error) return <ErrorMessage object={countryCalculations} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" width="300" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onProceedCopy(countryCalculation) {
        const { selectedCompany } = this.props;

        this.props.save(countryCalculation, Entities.COUNTRY_CALCULATION, selectedCompany);
    }

    onProceedDelete(countryCalculation) {
        const { selectedCompany } = this.props;

        this.props.deleteById(countryCalculation.id, Entities.COUNTRY_CALCULATION, selectedCompany);
    }

    openConfirmCopyDialog(copyName, countryCalculationCopy, name) {
        const { t } = this.props;
        countryCalculationCopy.name = copyName;

        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: countryCalculationCopy,
                onProceedDialog: this.onProceedCopy,
                bodyText: t('calculation.confirm_duplicate_country'),
                proceedText: t('user_functionality.duplicate'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.SUCCESS,
            },
            title: name,
            noButtons: true,
        });
    }

    openConfirmDeleteDialog(countryCalculation) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: countryCalculation,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('calculation.confirm_delete_country'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: countryCalculation.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('calculation.error_related_rate'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, countryCalculation) {
        const { selectedCompany, countryCalculations, t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CountryCalculationEditModal,
                            componentProps: {
                                isCreate: false,
                                id: countryCalculation.id,
                            },
                            title: countryCalculation.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span title={t('user_functionality.edit')} className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        // get selected countryCalculation from state and crop props for saving a countryCalculation copy
                        const countryCalculationCopy = Object.assign(
                            {},
                            countryCalculations.items.find(entity => entity.id === countryCalculation.id),
                        );
                        delete countryCalculationCopy.id;
                        delete countryCalculationCopy._links;
                        delete countryCalculationCopy.buses;
                        countryCalculationCopy.company = `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`;

                        asyncRequest(
                            `${TOMCAT_URL}api/${
                                Entities.COUNTRY_CALCULATION.repository
                            }/copyName?name=${encodeURIComponent(countryCalculation.name)}&companyId=${selectedCompany}`,
                        ).then(response =>
                            this.openConfirmCopyDialog(
                                response.json.copyName,
                                countryCalculationCopy,
                                countryCalculation.name,
                            ),
                        );
                    }}>
                    <span title={t('user_functionality.duplicate')} className="fa fa-files-o text-warning" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() =>
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.COUNTRY_CALCULATION.repository}/${countryCalculation.id}/deletable`,
                        )
                            .then(response => this.openConfirmDeleteDialog(countryCalculation))
                            .catch(error => this.openCannotDeleteDialog())
                    }>
                    <span title={t('user_functionality.delete')} className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

CountryCalculationAdministration.propTypes = {
    selectedCompany: PropTypes.number.isRequired,
    countryCalculations: PropTypes.shape({
        items: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired,
    }).isRequired,
};

export default withTranslation()(composeModalActions(CountryCalculationAdministration));
