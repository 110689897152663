import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import { Entities, fetchById, getUpdatedEntity, patch } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import TemplateEditForm from 'src/components/administration/template-edit-form';

class TemplateEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany } = this.props;

        this.props.fetchById(id, Entities.TEMPLATE, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { template } = nextProps;

        if (!this.state.startedFetching && template.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { template, t } = this.props;

        const finishFetching = this.state.startedFetching && !template.isFetching;

        if (finishFetching) {
            if (template.error) return <ErrorMessage object={template} />;
            else {
                const initialValues = {
                    id: template.content.id,
                    name: template.content.name,
                    text: template.content.text,
                };

                return (
                    <TemplateEditForm
                        t={t}
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        this.props.patch(data, Entities.TEMPLATE);

        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        template: getUpdatedEntity(state, Entities.TEMPLATE, state.selectedCompany),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
    },
    null,
    { withRef: true },
)(TemplateEditModal);
