import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

class Overview extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { data } = this.props;

        return (
            <div>
                <BarChart width={600} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    {/*<Legend />*/}
                    <Bar dataKey="count" fill="#0c66ab" />
                </BarChart>
            </div>
        );
    }
}

export default Overview;
