import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';

import { genderName } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import CompanyEditModal from 'src/components/company/company-edit-modal';
import Avatar from 'src/components/images/avatar';
import ErrorMessage from 'src/components/misc/error-message';
import ReadOnlyField from 'src/components/misc/ReadOnlyField';
import { pluralizeDays } from 'src/components/misc/redux-form-helpers';
import { withTranslation } from 'react-i18next';
import { getFinderLanguageLabel } from 'src/i18n';

class CompanyAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchOwnBusCompany(this.props.selectedCompany, true);
    }

    componentWillReceiveProps(nextProps) {
        const { company } = nextProps;

        if (!this.state.startedFetching && company.isFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { company, t } = this.props;

        const finishFetching = this.state.startedFetching && !company.isFetching;

        if (finishFetching) {
            if (company.error) return <ErrorMessage object={company} />;
            else {
                return (
                    <div>
                        <form className="form-horizontal">
                            <div className="row">
                                <div className="col-md-6">
                                    <legend className="legend">{t('companies.company_data')}</legend>
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <div id="company-fileinput">
                                                <Avatar value={company.entity.logo} t={t} disabled={true} />
                                            </div>
                                        </div>
                                        <div id="topSection" className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.companyName}
                                                label={t('common_phrases.name')}
                                            />
                                        </div>
                                        <div id="topSection" className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.registeredCompanyName}
                                                label={t('companies.registered_name')}
                                            />
                                        </div>
                                        <div id="topSection" className="col-md-12">
                                            <ReadOnlyField
                                                value={getFinderLanguageLabel(company.entity.language)}
                                                label={t('language')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.location.address}
                                                label={t('common_phrases.location')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.uidNumber}
                                                label={t('companies.uid_number')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.registrationNumber}
                                                label={t('companies.registration_number')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.socialCapital}
                                                label={t('companies.social_capital')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.invoiceEmail}
                                                label={t('companies.invoice_email')}
                                            />
                                        </div>
                                        {company.entity.freeCancellation ? (
                                            <div className="col-md-12">
                                                <ReadOnlyField
                                                    value={t('companies.days_earlier', {
                                                        days: pluralizeDays(t, company.entity.freeCancellationDays),
                                                    })}
                                                    label={t('companies.free_cancelling')}
                                                />
                                            </div>
                                        ) : (
                                            <div className="col-md-12">
                                                <ReadOnlyField
                                                    value={t('companies.no_free_cancelling')}
                                                    label={t('companies.free_cancelling')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <legend className="legend">{t('customer_details.contact_data')}</legend>
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={genderName(company.entity.contactData.gender)}
                                                label={t('contact_data.gender')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.firstName}
                                                label={t('contact_data.first_name')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.lastName}
                                                label={t('contact_data.last_name')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.street}
                                                label={t('contact_data.street')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.postCode}
                                                label={t('contact_data.post_code')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.city}
                                                label={t('contact_data.city')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.country}
                                                label={t('contact_data.country')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.phone}
                                                label={t('contact_data.tel_number')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.email}
                                                label={t('contact_data.email')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <ReadOnlyField
                                                value={company.entity.contactData.homepage}
                                                label={t('contact_data.homepage')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="pull-right">
                                    <RaisedButton
                                        primary={true}
                                        label={t('user_functionality.edit')}
                                        onClick={() =>
                                            this.props.openModal({
                                                component: CompanyEditModal,
                                                componentProps: {
                                                    id: company.entity.id,
                                                    isCreate: false,
                                                    isAdmin: false,
                                                    isTripCompany: company.entity.className === 'TripCompany',
                                                },
                                                title: company.entity.companyName,
                                                mandatoryFields: true,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else return <CircularProgress />;
    }
}

export default withTranslation()(composeModalActions(CompanyAdministration));
