import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import CircularProgress from 'material-ui/CircularProgress';
import { renderSelectField } from 'src/components/misc/redux-form-helpers';
import MenuItem from 'material-ui/MenuItem';
import { assignFleetAccounts } from 'src/actions/fleets';

class FleetAccountForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            accounts: {
                isFetching: true,
                error: undefined,
                items: [],
            },
        };
    }

    componentDidMount() {
        asyncRequest(
            `${TOMCAT_URL}api/${Entities.ACCOUNT.repository}/search/findAvailableByFleet?fleetId=${this.props.fleet.id}&projection=id-name`,
        )
            .then(response => {
                const json = response.json;
                if (!json || !json._embedded || (!json._embedded['operators'] && !json._embedded['dispatchers']))
                    throw Error('Invalid Response not containing any accounts');

                this.setState({
                    accounts: {
                        isFetching: false,
                        error: undefined,
                        items: this.getAccountsFromResponse(json._embedded['operators'], 'Operator').concat(
                            this.getAccountsFromResponse(json._embedded['dispatchers'], 'Dispatcher'),
                        ),
                    },
                });
            })
            .catch(error => {
                this.setState({
                    accounts: {
                        isFetching: false,
                        error: error.message ? error.message : error,
                        items: [],
                    },
                });
            });
    }

    render() {
        const { handleSubmit, selectedAccounts, t } = this.props;

        if (this.state.accounts.isFetching) return <CircularProgress />;

        const accountMenuItems = this.state.accounts.items.map(value => {
            return (
                <MenuItem
                    key={value.id}
                    value={value.id}
                    checked={selectedAccounts && selectedAccounts.indexOf(value.id) > -1}
                    primaryText={value.email}
                    secondaryText={value.role}
                />
            );
        });

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="alert alert-info">
                    <span>{t('fleets.info_account')}</span>
                </div>
                {accountMenuItems.length === 0 ? (
                    <div className="alert alert-danger">
                        <span>{t('fleets.info_no_account')}</span>
                    </div>
                ) : (
                    <Field
                        name="accounts"
                        label="Accounts"
                        multiple
                        selectionRenderer={this.accountSelectionRender}
                        component={renderSelectField}>
                        {accountMenuItems}
                    </Field>
                )}
            </form>
        );
    }

    accountSelectionRender(accountIds) {
        return (
            <span>
                {accountIds.length} {this.props.t('fleets.selected_accounts')}
            </span>
        );
    }

    getAccountsFromResponse(accounts, role) {
        if (!accounts) return [];
        return accounts.map(operator => ({
            ...operator,
            role,
        }));
    }
}

const validate = (values, props) => {
    const errors = {};

    return errors;
};

FleetAccountForm = reduxForm({
    form: 'FleetAccountForm',
    validate,
})(FleetAccountForm);

const mapStateToProps = (state, ownProps) => {
    const { fleet } = ownProps;

    return {
        initialValues: {
            id: fleet.id,
            accounts: fleet.accounts.map(account => account.id),
        },
        selectedAccounts: selector(state, 'accounts'),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: data => {
            console.log('submit', data);
            dispatch(assignFleetAccounts(data.id, data.accounts));
            ownProps.handleClose();
        },
    };
};

const selector = formValueSelector('FleetAccountForm');
FleetAccountForm = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FleetAccountForm);

export default FleetAccountForm;
