import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import { dashIfNull, Entities, fetchById, getUpdatedEntity } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ParentForm from 'src/components/misc/parent-form';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';
import ErrorMessage from 'src/components/misc/error-message';
import SeasonProductAssignmentForm from 'src/components/trips/administration/season-product/SeasonProductAssignmentForm';
import { removeProductFromSeason } from 'src/actions/seasons';
import { withTranslation } from 'react-i18next';

class SeasonProductAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { selectedCompany, params } = this.props;

        this.props.fetchById(params.id, Entities.SEASON, selectedCompany, 'products');
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { season } = nextProps;

        if (!this.state.startedFetching && season.isFetching) this.setState({ startedFetching: true });
    }

    render() {
        const { season, selectedCompany, t } = this.props;
        const finishFetching = this.state.startedFetching && !season.isFetching;

        if (!finishFetching) return <CircularProgress />;

        const tableData = [];

        if (season.content && season.content.products) {
            season.content.products.map(seasonProduct => {
                tableData.push({
                    id: seasonProduct.product.id,
                    name: seasonProduct.product.name,
                    freeCompanionPerPax: seasonProduct.product.freeCompanionPerPax,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('products.products_for', { name: season.content && season.content.name })}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: ParentForm,
                                componentProps: {
                                    reducerIndex: selectedCompany,
                                    isCreate: true,
                                    mainEntityProps: {
                                        entity: Entities.SEASON,
                                    },
                                    childForm: {
                                        component: SeasonProductAssignmentForm,
                                        props: {
                                            companyId: selectedCompany,
                                        },
                                    },
                                },
                                title: t('administration.product_for', { name: season.content.name }),
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('products.add_product')}
                    </button>
                </legend>

                {(() => {
                    if (season.error) return <ErrorMessage object={season} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="freeCompanionPerPax"
                                    dataAlign="right"
                                    dataFormat={cell => dashIfNull(cell)}
                                    width="250"
                                    dataSort={true}>
                                    {t('products.free_companion')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, product) {
        const { selectedCompany, season, t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                reducerIndex: selectedCompany,
                                projection: 'products',
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.SEASON,
                                    id: season.content.id,
                                },
                                childForm: {
                                    component: SeasonProductAssignmentForm,
                                    props: {
                                        companyId: selectedCompany,
                                        productId: product.id,
                                    },
                                },
                            },
                            title: t('administration.product_season', {
                                productName: product.name,
                                seasonName: season.content.name,
                            }),
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() => this.openConfirmDeleteDialog(product)}>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }

    onProceedRemove(product) {
        const { season, selectedCompany } = this.props;
        this.props.removeProductFromSeason(product.id, season.content.id, selectedCompany);
    }

    openConfirmDeleteDialog(product) {
        const { season, t } = this.props;

        // TODO: consider related entities (has this product already been booked, etc)
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: product,
                onProceedDialog: this.onProceedRemove,
                bodyText: t('task_details.confirm_remove_product', { name: season.content.name }),
                proceedText: t('task_details.remove'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: product.name,
            noButtons: true,
        });
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        season: getUpdatedEntity(state, Entities.SEASON, state.selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        fetchById,
        removeProductFromSeason,
    })(composeModalActions(SeasonProductAdministration)),
);
