import { TFunction } from 'i18next';
import React from 'react';
import { blue700 } from 'material-ui/styles/colors';
import { withTranslation } from 'react-i18next';
import Navigation, { ComponentRoutes } from 'src/components/nav/Navigation';
import CompanyIcon from 'material-ui/svg-icons/communication/business';
import StatisticsIcon from 'material-ui/svg-icons/editor/insert-chart';
import CustomerGroupIcon from 'material-ui/svg-icons/social/group';
import ModuleIcon from 'material-ui/svg-icons/action/view-module';
import ExternalIcon from 'material-ui/svg-icons/action/launch';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { TOMCAT_URL } from 'src/common/index';
import BusIcon from 'material-ui/svg-icons/maps/directions-bus';

/**
 * Compose correct super admin path
 */
const composeSuperAdminPath = (route: string) => `/superadmin/${route}`;
const composeMonitoringPath = (route: string) => `${composeSuperAdminPath('monitoring')}/${route}`;
const composeSuperAdminStatisticsPath = (route: string) => composeSuperAdminPath(`statistics/${route}`);

/**
 * Defines all admin menu items. The order matters
 */

const superAdminRoutes = (t: TFunction): ComponentRoutes => [
    {
        path: composeSuperAdminPath('monitoring'),
        icon: <StatisticsIcon color={blue700} />,
        primaryText: t('monitoring'),
        secondaryText: t('companies.statistics'),
        initiallyOpen: true,
        disabled: true,
        children: [
            {
                path: composeMonitoringPath('entities'),
                icon: <StatisticsIcon color={blue700} />,
                primaryText: t('entities'),
            },
            {
                path: composeMonitoringPath('companies'),
                icon: <CompanyIcon color={blue700} />,
                primaryText: t('common_phrases.company'),
            },
            {
                path: composeMonitoringPath('logins'),
                icon: <CustomerGroupIcon color={blue700} />,
                primaryText: t('logins'),
            },
            {
                path: composeMonitoringPath('java-melody'),
                icon: <ExternalIcon color={blue700} />,
                primaryText: t('java_melody'),
                link: {
                    target: '_blank',
                    href: `${TOMCAT_URL}monitoring`,
                },
            },
        ],
    },
    {
        path: composeSuperAdminPath('modules'),
        icon: <ModuleIcon color={blue700} />,
        primaryText: t('modules'),
    },
    {
        path: composeSuperAdminPath('statistics'),
        icon: <StatisticsIcon color={blue700} />,
        primaryText: t('statistics'),
        disabled: true,
        children: [
            {
                path: composeSuperAdminStatisticsPath('bus-yield'),
                icon: <BusIcon color={blue700} />,
                primaryText: t('bus_yield.title'),
            },
        ],
    },
];

/**
 * Renders the super admins administration sidebar on the left hand side
 * and the related content on the right
 */
class AdminTripNavigation extends React.Component<any, {}> {
    public render() {
        const { t } = this.props;
        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <Navigation routes={superAdminRoutes(t)} />
                </div>

                <div id="tasklist-panel" className="col-md-10">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminTripNavigation));
