import { TFunction } from 'i18next';
import React from 'react';
import { blue700 } from 'material-ui/styles/colors';
import CompanyIcon from 'material-ui/svg-icons/communication/business';
import AccountIcon from 'material-ui/svg-icons/action/supervisor-account';
import ListIcon from 'material-ui/svg-icons/action/list';
import BusinessCenterIcon from 'material-ui/svg-icons/places/business-center';
import { withTranslation } from 'react-i18next';
import Navigation, { ComponentRoutes } from 'src/components/nav/Navigation';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import ArrowForward from 'material-ui/svg-icons/navigation/arrow-forward';
import BanIcon from 'material-ui/svg-icons/navigation/cancel';
import { TripBookingFilter } from 'src/components/trips/bookings/booking-administration-filter';
import TripDetails from 'src/components/trips/calendar/details/TripDetails';
import composeModalActions from 'src/utils/modal-action-wrapper';
import DescriptionIcon from 'material-ui/svg-icons/action/description';

/**
 * Compose correct admin trip path
 */
const composeAdminTripPath = (route: string) => `/admin/trips/${route}`;
const composeAdminTripBookingPath = (route: string) => composeAdminTripPath(`bookings?filter=${route}`);

/**
 * Defines all admin menu items. The order matters
 */
const adminTripRoutes = (t: TFunction): ComponentRoutes => [
    {
        path: composeAdminTripPath('companies'),
        icon: <CompanyIcon color={blue700} />,
        primaryText: t('trip_operator.destinations'),
        secondaryText: t('common_phrases.company'),
        initiallyOpen: true,
        children: [
            {
                path: composeAdminTripPath('attributes'),
                icon: <BusinessCenterIcon color={blue700} />,
                primaryText: t('companies.properties'),
            },
            {
                path: composeAdminTripPath('categories'),
                icon: <ListIcon color={blue700} />,
                primaryText: t('common_phrases.categories'),
            },
        ],
    },
    {
        path: composeAdminTripPath('accounts'),
        icon: <AccountIcon color={blue700} />,
        primaryText: t('trip_operator.operator'),
        secondaryText: t('header_account'),
    },
    {
        path: composeAdminTripPath('bookings'),
        icon: <DescriptionIcon color={blue700} />,
        primaryText: t('booked_tasks.bookings'),
        disabled: true,
        initiallyOpen: true,
        children: [
            {
                path: composeAdminTripBookingPath(TripBookingFilter.FUTURE),
                icon: <ArrowForward color={blue700} />,
                primaryText: t('trip_operator.current'),
            },
            {
                path: composeAdminTripBookingPath(TripBookingFilter.PAST),
                icon: <ArrowBack color={blue700} />,
                primaryText: t('trip_operator.past'),
            },
            {
                path: composeAdminTripBookingPath(TripBookingFilter.CANCELLED),
                icon: <BanIcon color={blue700} />,
                primaryText: t('booked_tasks.cancelled'),
            },
            {
                path: composeAdminTripBookingPath(TripBookingFilter.ALL),
                icon: <ListIcon color={blue700} />,
                primaryText: t('common_phrases.all'),
            },
        ],
    },
];

/**
 * Renders the admins trip administration sidebar on the left hand side
 * and the related content on the right
 */
class AdminTripNavigation extends React.Component<any, {}> {
    public render() {
        const { t } = this.props;
        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <Navigation routes={adminTripRoutes(t)} />
                </div>

                <div id="tasklist-panel" className="col-md-10">
                    {this.props.children}

                    {/* Details */}
                    <div className="row">
                        <div className="tripdetailscontainer">
                            <TripDetails t={t} openModal={this.props.openModal} modal={this.props.modal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminTripNavigation));
