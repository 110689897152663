import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TableHelper from 'src/utils/table-helper';
import { validFormatter } from 'src/utils/formatters';
import { Entities, formatMoney } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { CouponType } from 'src/utils/constants';
import ErrorMessage from 'src/components/misc/error-message';
import moment from 'moment';
import CouponEditModal from 'src/components/admin/coupons/coupon-edit-modal';
import Toggle from 'material-ui/Toggle';
import { withTranslation } from 'react-i18next';

class AdminCouponAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.COUPON);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { coupons } = nextProps;

        if (coupons.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(coupons.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.coupons, this.props.coupons, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { coupons, t } = this.props;
        const finishFetching = this.state.startedFetching && !coupons.isFetching;

        const tableData = [];
        if (finishFetching && coupons.items.length > 0) {
            coupons.items.map(coupon => {
                tableData.push({
                    id: coupon.id,
                    name: coupon.name,
                    type: coupon.type,
                    code: coupon.code,
                    validFrom: coupon.validFrom,
                    validTo: coupon.validTo,
                    quantity: coupon.quantity,
                    redeemCount: coupon.redeemCount,
                    discount: coupon.discount,
                    active: coupon.active,
                    valid: coupon.valid,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('coupons.coupons')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CouponEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('coupons.new_coupon'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('coupons.new_coupon')}
                    </button>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (coupons.error) return <ErrorMessage object={coupons} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="type"
                                    width="90"
                                    dataFormat={this.typeFormatter}
                                    dataSort={true}>
                                    {t('common_phrases.type')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="code" width="200" dataSort={true}>
                                    {t('coupons.code')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="validFrom"
                                    dataFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('coupons.valid_from')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="validTo"
                                    dataFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('coupons.valid_until')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="valid"
                                    width="80"
                                    dataAlign="center"
                                    dataFormat={cell => validFormatter(t, cell)}
                                    dataSort={true}>
                                    {t('coupons.valid')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="quantity" width="130" dataSort={true}>
                                    {t('coupons.quantity_sum')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="redeemCount"
                                    width="90"
                                    dataSort={true}>
                                    {t('coupons.redeem')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="discount"
                                    width="90"
                                    dataFormat={this.discountFormatter}
                                    csvFormat={this.discountFormatter}
                                    dataSort={true}>
                                    {t('coupons.discount')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="active"
                                    width="80"
                                    dataAlign="center"
                                    dataSort={true}
                                    dataFormat={this.toggleFormatter}>
                                    {t('common_phrases.active')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    toggleFormatter(active, coupon) {
        return (
            <Toggle
                toggled={active}
                onToggle={() => this.props.patch({ id: coupon.id, active: !active }, Entities.COUPON)}
            />
        );
    }

    discountFormatter(discount, coupon) {
        switch (coupon.type) {
            case CouponType.ABSOLUTE:
                return formatMoney({ amount: coupon.discount, currency: 'EUR' });
            case CouponType.RELATIVE:
                return `${coupon.discount} %`;
            default:
                return coupon.discount;
        }
    }

    dateFormatter(date) {
        const { t } = this.props;
        return date ? t('administration.time', { time: moment(date).format() }) : '-';
    }

    typeFormatter(type) {
        const { t } = this.props;
        switch (type) {
            case CouponType.ABSOLUTE:
                return t('coupons.value_absolute');
            case CouponType.RELATIVE:
                return t('coupons.value_relative');
            default:
                return '-';
        }
    }

    tableActionsFormatter(cell, coupon) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CouponEditModal,
                            componentProps: {
                                id: coupon.id,
                                isCreate: false,
                            },
                            title: coupon.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminCouponAdministration));
