import RaisedButton from 'material-ui/RaisedButton';
import { Tab, Tabs } from 'material-ui/Tabs';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SwipeableViews from 'react-swipeable-views';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { setSliderIndex } from 'src/actions/form';
import { getMenuItemsByArray, scrollToAnchor } from 'src/utils/helpers';
import {
    asyncCheckDuplicate,
    renderCheckbox,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, isIntegerPercent, isMoneyAmount, TOMCAT_URL, validatePrice } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';
import { connect } from 'react-redux';

class CalculationEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {
        this.props.setSliderIndex(0);
    }

    render() {
        const {
            handleSubmit,
            selectedSliderIndex,
            seasonCalculations,
            countryCalculations,
            arrivalReturnFlatRateCalculation,
            calculationSkipAvailabilityCheck,
            occupancySurcharges,
            forbidSecondDriver,
            specialVehicle,
            t,
        } = this.props;

        const seasonCalculationsMenuItems = getMenuItemsByArray(seasonCalculations.items, 'name');
        const countryCalculationsMenuItems = getMenuItemsByArray(countryCalculations.items, 'name');

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div>
                    <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                        <Tab label={t('common_phrases.common')} value={0} />
                        <Tab label={t('calculation.arrival')} value={1} />
                        <Tab label={t('calculation.travel')} value={2} />
                        <Tab label={t('calculation.standing_hour_rates')} value={3} />
                        <Tab label={t('calculation.operating_hour_rates')} value={4} />
                        <Tab label={t('calculation.capacity')} value={5} />
                    </Tabs>
                    <SwipeableViews
                        index={selectedSliderIndex}
                        onChangeIndex={value => this.props.setSliderIndex(value)}>
                        <div className="view">
                            <div id="topSection" className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        label={`${t('common_phrases.name')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="seasonCalculation"
                                        label={`${t('calculation.season_calculation')} *`}
                                        component={renderSelectField}>
                                        {seasonCalculationsMenuItems}
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="countryCalculation"
                                        label={`${t('calculation.country_calculation')} *`}
                                        component={renderSelectField}>
                                        {countryCalculationsMenuItems}
                                    </Field>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="kilometreRate.amount"
                                        label={`${t('calculation.kilometre_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="driverRate.amount"
                                        label={`${t('calculation.hour_rate')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="extraHourRate.amount"
                                        label={`${t('calculation.additional_hour')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="extraKilometreRate.amount"
                                        label={`${t('calculation.additional_km')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="driverRoomPrice.amount"
                                        label={`${t('calculation.driver_room_price')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row voffset">
                                <div className="col-md-12">
                                    <Field
                                        name="forbidSecondDriver"
                                        label={t('calculation.forbid_second_driver')}
                                        component={renderCheckbox}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="secondDriverStandingRateFactor"
                                        label="Faktor 2. Fahrer Stehzeit *"
                                        component={renderInput}
                                        disabled={forbidSecondDriver}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="secondDriverOperatingRateFactor"
                                        label="Faktor 2. Fahrer Einsatzzeit *"
                                        component={renderInput}
                                        disabled={forbidSecondDriver}
                                    />
                                </div>
                            </div>
                            <div className="row voffset">
                                <div className="col-md-6">
                                    <Field
                                        name="splitOperation"
                                        label={t('calculation.split_operation')}
                                        component={renderCheckbox}
                                    />
                                </div>
                            </div>
                            <div className="row voffset">
                                <div className="col-md-6">
                                    <Field
                                        name="specialVehicle"
                                        label={t('calculation.specialVehicle')}
                                        component={renderCheckbox}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="specialVehicleMaxKilometres"
                                        label={t('calculation.specialVehicleMaxKilometres')}
                                        component={renderInput}
                                        disabled={!specialVehicle}
                                    />
                                </div>
                            </div>
                            <div className="row voffset40">
                                <div className="col-md-6">
                                    <Field
                                        name="onlyForDailyTrips"
                                        label={t('calculation.onlyForDailyTrips')}
                                        component={renderCheckbox}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="onlyForDailyFlatRate"
                                        label={t('calculation.onlyForDailyFlatRate')}
                                        component={renderCheckbox}
                                    />
                                </div>
                            </div>
                            <div className="row voffset">
                                <div className="col-md-6">
                                    <Field
                                        name="halfDayTripMaxKilometres"
                                        label={t('calculation.halfDayTripMaxKilometres')}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="halfDayTripMaxHours"
                                        label={t('calculation.halfDayTripMaxHours')}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="halfDayTripFlatRate.amount"
                                        label={t('calculation.halfDayTripFlatRate')}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="oneDayTripMaxKilometres"
                                        label={t('calculation.oneDayTripMaxKilometres')}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="oneDayTripMaxHours"
                                        label={t('calculation.oneDayTripMaxHours')}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="oneDayTripFlatRate.amount"
                                        label={t('calculation.oneDayTripFlatRate')}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            {calculationSkipAvailabilityCheck && (
                                <div className="row voffset">
                                    <div className="col-md-6">
                                        <Field
                                            name="skipAvailabilityCheck"
                                            label={t('calculation.skipAvailabilityCheck')}
                                            component={renderCheckbox}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="view">
                            {arrivalReturnFlatRateCalculation && (
                                <>
                                    <div className="alert alert-info">
                                        {t('calculation.arrivalReturnFlatRateCalculation_info')}
                                    </div>
                                    <h4>{t('calculation.arrivalReturnFlatRateCalculation_header')}</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                name="arrivalReturnFlatRate.amount"
                                                label={`${t('calculation.arrivalReturnFlatRate')} *`}
                                                component={renderInput}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <h4>{t('calculation.breaks')}</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="arrivalBreakFee.amount"
                                        label={`${t('calculation.additional_arrival_break')} *`}
                                        component={renderInput}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="returnBreakFee.amount"
                                        label={`${t('calculation.additional_return_break')} *`}
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <br />
                            <h4>{t('calculation.arrival_rates')}</h4>
                            <FieldArray name="arrivalFees" t={t} component={renderKilometreFees} />
                        </div>
                        <div className="view">
                            <FieldArray name="travelFees" t={t} component={renderKilometreFees} />
                        </div>
                        <div className="view">
                            <FieldArray name="standingHourRates" t={t} component={renderHourRates} />
                        </div>
                        <div className="view">
                            <FieldArray name="operatingHourRates" t={t} component={renderHourRates} />
                        </div>
                        <div className="view">
                            <h4>{t('calculation.capacity')}</h4>
                            {(occupancySurcharges && (
                                <FieldArray name="occupancySurcharges" t={t} component={renderOccupancySurcharges} />
                            )) || <div>{t('search_requests.module_deactivated')}</div>}
                            <hr />
                            <h4>{t('calculation.overall_capacity')}</h4>
                            {(occupancySurcharges && (
                                <FieldArray
                                    name="globalOccupancySurcharges"
                                    t={t}
                                    component={renderOccupancySurcharges}
                                />
                            )) || <div>{t('search_requests.module_deactivated')}</div>}
                        </div>
                    </SwipeableViews>
                </div>
            </form>
        );
    }
}

const renderKilometreFees = ({ fields, t }) => (
    <>
        <div className="row" style={{ margin: 0 }}>
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('calculation.new_rate')}`}
                        onClick={() =>
                            fields.push({
                                toKilometre: 0,
                                price: 0,
                            })
                        }
                    />
                </div>
            </div>
        </div>

        {fields.map((arrivalFee, index) => (
            <div key={index} className="row" style={{ margin: 0 }}>
                <div className="col-md-5">
                    <div className="form-group">
                        <Field
                            name={`${arrivalFee}.toKilometre`}
                            label={`${t('calculation.to_km')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name={`${arrivalFee}.price.amount`}
                            label={`${t('calculation.kilometre_rate')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="col-md-1 voffset">
                    <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                        <span className="glyphicon glyphicon-trash" />
                    </button>
                </div>
            </div>
        ))}
    </>
);

const renderHourRates = ({ fields, t }) => (
    <>
        <div className="row" style={{ margin: 0 }}>
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('calculation.new_rate')}`}
                        onClick={() =>
                            fields.push({
                                fromHours: 0,
                                rate: 0,
                            })
                        }
                    />
                </div>
            </div>
        </div>

        {fields.map((multiDayDiscount, index) => (
            <div key={index} className="row" style={{ margin: 0 }}>
                <div className="col-md-5">
                    <div className="form-group">
                        <Field
                            name={`${multiDayDiscount}.fromHours`}
                            label={`${t('calculation.from_hours')} *`}
                            step="1"
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name={`${multiDayDiscount}.rate.amount`}
                            label={`${t('calculation.rate_price')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="col-md-1 voffset">
                    <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                        <span className="glyphicon glyphicon-trash" />
                    </button>
                </div>
            </div>
        ))}
    </>
);

const renderOccupancySurcharges = ({ fields, t }) => (
    <>
        <div className="row" style={{ margin: 0 }}>
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('calculation.occupancy_surcharges')}`}
                        onClick={() =>
                            fields.push({
                                occupancy: 0,
                                surcharge: 0,
                            })
                        }
                    />
                </div>
            </div>
        </div>

        {fields.map((occupancy, index) => (
            <div key={index} className="row" style={{ margin: 0 }}>
                <div className="col-md-5">
                    <div className="form-group">
                        <Field
                            name={`${occupancy}.occupancy`}
                            label={`${t('calculation.from_capacity')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name={`${occupancy}.surcharge`}
                            label={`${t('calculation.surcharge')} *`}
                            component={renderInput}
                            type="number"
                        />
                    </div>
                </div>
                <div className="col-md-1 voffset">
                    <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                        <span className="glyphicon glyphicon-trash" />
                    </button>
                </div>
            </div>
        ))}
    </>
);

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        kilometreRate: {
            amount: undefined,
        },
        driverRate: {
            amount: undefined,
        },
        driverRoomPrice: {
            amount: undefined,
        },
        extraHourRate: {
            amount: undefined,
        },
        extraKilometreRate: {
            amount: undefined,
        },
        arrivalBreakFee: {
            amount: undefined,
        },
        returnBreakFee: {
            amount: undefined,
        },
        arrivalReturnFlatRate: {
            amount: undefined,
        },
        halfDayTripFlatRate: {
            amount: undefined,
        },
        oneDayTripFlatRate: {
            amount: undefined,
        },
    };

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.seasonCalculation) errors.seasonCalculation = t('calculation.fill_in_special_rate');

    if (!values.countryCalculation) errors.countryCalculation = t('calculation.fill_in_country_rate');

    if (!isMoneyAmount(values.kilometreRate)) errors.kilometreRate.amount = t('calculation.fill_in_km_rate');
    else if (validatePrice(values.kilometreRate.amount))
        errors.kilometreRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.driverRate)) errors.driverRate.amount = t('calculation.fill_in_rate');
    else if (validatePrice(values.driverRate.amount))
        errors.driverRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.driverRoomPrice)) errors.driverRoomPrice.amount = t('calculation.fill_in_hour_rate');
    else if (validatePrice(values.driverRoomPrice.amount))
        errors.driverRoomPrice.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (values.secondDriverStandingRateFactor == null)
        errors.secondDriverStandingRateFactor = t('calculation.fill_in_rate');
    else if (validatePrice(values.secondDriverStandingRateFactor))
        errors.secondDriverStandingRateFactor = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (values.secondDriverOperatingRateFactor == null)
        errors.secondDriverOperatingRateFactor = t('calculation.fill_in_rate');
    else if (validatePrice(values.secondDriverOperatingRateFactor))
        errors.secondDriverOperatingRateFactor = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.extraHourRate)) errors.extraHourRate.amount = t('calculation.fill_in_additional_hour');
    else if (validatePrice(values.extraHourRate.amount))
        errors.extraHourRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.extraKilometreRate))
        errors.extraKilometreRate.amount = t('calculation.fill_in_additional_km');
    else if (validatePrice(values.extraKilometreRate.amount))
        errors.extraKilometreRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.arrivalBreakFee)) errors.arrivalBreakFee.amount = t('calculation.fill_in_surcharge');
    else if (validatePrice(values.arrivalBreakFee.amount))
        errors.arrivalBreakFee.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.returnBreakFee)) errors.returnBreakFee.amount = t('calculation.fill_in_surcharge');
    else if (validatePrice(values.returnBreakFee.amount))
        errors.returnBreakFee.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (!isMoneyAmount(values.arrivalReturnFlatRate))
        errors.arrivalReturnFlatRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.arrivalReturnFlatRate.amount))
        errors.arrivalReturnFlatRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (
        values.specialVehicleMaxKilometres &&
        (!isInteger(values.specialVehicleMaxKilometres) || values.specialVehicleMaxKilometres < 0)
    ) {
        errors.specialVehicleMaxKilometres = t('calculation.fill_in_max_km');
    }

    if (
        values.halfDayTripMaxKilometres &&
        (!isInteger(values.halfDayTripMaxKilometres) || values.halfDayTripMaxKilometres < 0)
    ) {
        errors.halfDayTripMaxKilometres = t('calculation.fill_in_max_km');
    }

    if (values.halfDayTripMaxHours && (!isInteger(values.halfDayTripMaxHours) || values.halfDayTripMaxHours < 0)) {
        errors.halfDayTripMaxHours = t('calculation.fill_in_max_km');
    }

    if (!isMoneyAmount(values.halfDayTripFlatRate))
        errors.halfDayTripFlatRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.halfDayTripFlatRate.amount))
        errors.halfDayTripFlatRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (
        values.oneDayTripMaxKilometres &&
        (!isInteger(values.oneDayTripMaxKilometres) || values.oneDayTripMaxKilometres < 0)
    ) {
        errors.oneDayTripMaxKilometres = t('calculation.fill_in_max_km');
    }

    if (values.oneDayTripMaxHours && (!isInteger(values.oneDayTripMaxHours) || values.oneDayTripMaxHours < 0)) {
        errors.oneDayTripMaxHours = t('calculation.fill_in_max_km');
    }

    if (!isMoneyAmount(values.oneDayTripFlatRate))
        errors.oneDayTripFlatRate.amount = t('calculation.fill_in_rate_flat_rate');
    else if (validatePrice(values.oneDayTripFlatRate.amount))
        errors.oneDayTripFlatRate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;

    if (values.arrivalFees) errors.arrivalFees = validateKilometreFees(t, values.arrivalFees, 70);

    if (values.travelFees) errors.travelFees = validateKilometreFees(t, values.travelFees, 10000);

    if (values.standingHourRates) errors.standingHourRates = validateHourRates(t, values.standingHourRates);

    if (values.operatingHourRates) errors.operatingHourRates = validateHourRates(t, values.operatingHourRates);

    if (values.occupancySurcharges)
        errors.occupancySurcharges = validateOccupancySurcharges(t, false, values.occupancySurcharges);

    if (values.globalOccupancySurcharges)
        errors.globalOccupancySurcharges = validateOccupancySurcharges(t, true, values.globalOccupancySurcharges);

    return errors;
};

const validateKilometreFees = (t, kilometreFees, max) => {
    const errors = [];
    const seen = new Set();

    kilometreFees.forEach((kilometreFee, index) => {
        const error = {
            toKilometre: undefined,
            price: {
                amount: undefined,
            },
        };

        if (!kilometreFee.toKilometre || kilometreFee.toKilometre < 1) {
            error.toKilometre = t('error_hint.error_min', { min: 1 });
            errors[index] = error;
        } else if (kilometreFee.toKilometre > max) {
            error.toKilometre = t('error_hint.error_max', { max: max });
            errors[index] = error;
        } else if (!isInteger(kilometreFee.toKilometre)) {
            error.toKilometre = t('error_hint.error_integer');
            errors[index] = error;
        } else if (seen.has(kilometreFee.toKilometre)) {
            error.toKilometre = t('error_hint.error_unique');
            errors[index] = error;
        } else if (!isMoneyAmount(kilometreFee.price)) {
            error.price.amount = t('calculation.fill_in_km_rate');
            errors[index] = error;
        } else if (validatePrice(kilometreFee.price.amount)) {
            error.price.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
            errors[index] = error;
        }

        seen.add(kilometreFee.toKilometre);
    });

    if (errors.length > 0) return errors;
};

const validateHourRates = (t, hourRates) => {
    const errors = [];
    const seen = new Set();
    hourRates.forEach((hourRate, index) => {
        const error = {
            fromHours: undefined,
            rate: {
                amount: undefined,
            },
        };

        if (!isInteger(hourRate.fromHours)) {
            error.fromHours = t('error_hint.error_integer');
            errors[index] = error;
        } else if (hourRate.fromHours < 0) {
            error.fromHours = t('error_hint.error_negative');
            errors[index] = error;
        } else if (hourRate.fromHours > 100) {
            error.fromHours = t('error_hint.error_max', { max: 100 });
            errors[index] = error;
        } else if (seen.has(hourRate.fromHours)) {
            error.fromHours = t('error_hint.error_unique');
            errors[index] = error;
        } else if (!isMoneyAmount(hourRate.rate)) {
            error.rate.amount = t('calculation.fill_in_rate');
            errors[index] = error;
        } else if (validatePrice(hourRate.rate.amount)) {
            error.rate.amount = `${t('error_hint.error_invalid_format')} (######.00)`;
            errors[index] = error;
        }

        seen.add(hourRate.fromHours);
    });

    if (errors.length > 0) return errors;
};

const validateOccupancySurcharges = (t, global, surcharges) => {
    const errors = [];
    const seen = new Set();
    surcharges.forEach((surcharge, index) => {
        const error = {
            occupancy: undefined,
            surcharge: undefined,
        };

        if (!surcharge.occupancy || !isInteger(surcharge.occupancy) || surcharge.occupancy <= 0) {
            error.occupancy = t('calculation.fill_in_capacity');
            errors[index] = error;
        } else if (seen.has(surcharge.occupancy)) {
            error.occupancy = t('error_hint.error_unique');
            errors[index] = error;
        } else if (!surcharge.surcharge) {
            error.surcharge = t('calculation.fill_in_surcharge');
            errors[index] = error;
        } else if (!isIntegerPercent(surcharge.surcharge)) {
            error.surcharge = t('calculation.fill_in_surcharge_criteria');
            errors[index] = error;
        }
        // a positive surcharge is only allowed for global occupancy equal to or greater than 50 percent
        else if (global && surcharge.surcharge > 0 && surcharge.occupancy < 50) {
            error.surcharge = t('calculation.fill_in_occupancy_surcharge_criteria');
            errors[index] = error;
        }

        seen.add(surcharge.occupancy);
    });

    if (errors.length > 0) return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.CALCULATION.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
            values.name,
        )}&companyId=${props.selectedCompany}`,
        values.id,
        'name',
    );
};

const onSubmitFail = (errors, dispatch) => {
    if (!errors) return;

    if (
        errors.arrivalFees ||
        (errors.arrivalBreakFee && errors.arrivalBreakFee.amount) ||
        (errors.returnBreakFee && errors.returnBreakFee.amount) ||
        (errors.arrivalReturnFlatRate && errors.arrivalReturnFlatRate.amount)
    )
        dispatch(setSliderIndex(1));
    else if (errors.travelFees) dispatch(setSliderIndex(2));
    else if (errors.standingHourRates) dispatch(setSliderIndex(3));
    else if (errors.operatingHourRates) dispatch(setSliderIndex(4));
    else if (errors.occupancySurcharges || errors.globalOccupancySurcharges) dispatch(setSliderIndex(5));
    else {
        scrollToAnchor('topSection');
        dispatch(setSliderIndex(0));
    }
};

CalculationEditForm = reduxForm({
    form: 'calculationEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(CalculationEditForm);

const selector = formValueSelector('calculationEditForm');
CalculationEditForm = connect(
    state => ({
        forbidSecondDriver: selector(state, 'forbidSecondDriver'),
        specialVehicle: selector(state, 'specialVehicle'),
    }),
    null,
    null,
    { withRef: true },
)(CalculationEditForm);

export default CalculationEditForm;
